import styled from "styled-components";
import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";
import React, { useState } from "react";
import moment from "moment/moment";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Cell } from "../../../components/Table/CellComponent";

const Row = styled(Table.Row)`
  cursor: pointer;
  height: 50px !important;
  @media only screen and (min-width: 100px) and (max-width: 768px) {
    height: auto !important;
  }
`;

const ContainerErrorReportsHigh = styled.div`
  width: max-content;
  padding: 0px 4px 0px 4px;
  border-radius: 4px;
  border: 1px solid #BA3555;
  color:  #BA3555;
  font-weight: 500;
  font-size: 12px;
`;

const ContainerErrorReports = styled.div`
  width: max-content;
  padding: 0px 4px 0px 4px;
  border-radius: 4px;
  border: 1px solid #B2B2B2;
  color:  #B2B2B2;
  font-weight: 500;
  font-size: 12px;
`;

const StylePorcent = styled.span`
  font-weight: 900;
`;

const ContainerAuditReview = styled.span`
`;

const StyleAuditReviewLink = styled.span`
  font-weight: 400;
  color: #22C1D0;
  text-decoration: underline;
  cursor: pointer !important;
`;

const RowTableSuperAdminImproperClients = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [Isvisible, setIsVisible] = useState(false);
  const formatDate = (dateToTransform ) => {
    return moment(dateToTransform).utc().format('DD/MM/YYYY')
  }

  const redirectToImproperClientsDetail = () => {
    sessionStorage.setItem('improperClientID', props.data.companySerialID )
    props.history.push({ pathname: '/improperClientsDetail/' + props.data._id})
  };

  return (
    <Row onMouseEnter={() => setIsVisible(true)} onMouseLeave={() => setIsVisible(false)} 
     >
      <Cell width={2} columnName={props.headerTable[0].name} text={props.data.clientName}/>
      <Cell width={2} columnName={props.headerTable[1].name} text={props.data.numberDocumentCompany}/>
      <Cell width={2} columnName={props.headerTable[2].name} text={props.data.phone}/>
      <Cell width={3} columnName={props.headerTable[3].name} text={props.data.email}/>
      <Cell width={3} columnName={props.headerTable[4].name} text={props.data.managerName}/>
      <Cell width={2} columnName={props.headerTable[5].name} text={''} 
      children={props.data.statusReviewAuditor &&
        <ContainerAuditReview>
          {props.data.statusLetterReviewAuditor} <StyleAuditReviewLink onClick={redirectToImproperClientsDetail}>Ver detalle</StyleAuditReviewLink>
        </ContainerAuditReview>} />
      <Cell width={3} columnName={props.headerTable[6].name} text={''}
        children={parseInt(props.data.realPercentage.substring(0,(props.data.realPercentage.search("%")))) >= 80 && !props.data.statusReviewAuditor ?
            <ContainerErrorReportsHigh>
              <StylePorcent>{props.data.realPercentage}</StylePorcent>
              {props.data.calculatePercentage}
            </ContainerErrorReportsHigh>
          :
            <ContainerErrorReports>
              <StylePorcent>{props.data.realPercentage}</StylePorcent>
              {props.data.calculatePercentage}
            </ContainerErrorReports>
          }
      />
    </Row>
  );
};

RowTableSuperAdminImproperClients.propTypes = {
  data: PropTypes.object.isRequired,
};

export default RowTableSuperAdminImproperClients;