import React from 'react'
import {Redirect, Route} from 'react-router-dom'
import {connect} from 'react-redux'
import Write from '../views/write/Write'

export const PrivateComponent = ({
  view: Component,
  pathObject,
  ...rest
}) => {

  const modalReducer = rest.modalReducer
  const selectComponent = props => {
    if (sessionStorage.getItem('jwtToken')) {
      if (modalReducer.writeNotification){
        return <Write {...props} pathObject={pathObject} />
      } else {
        return <Component {...props} pathObject={pathObject} />
      }
    } else {
      return <Redirect to='/dashboard' />
    }
  }

  return (
    <>
    <Route {...rest} render={selectComponent} />
    </>
  )
}

const mapStateToProps = (state) => ({
  modalReducer: state.modalReducer
})

export default connect(mapStateToProps, {})(PrivateComponent)
