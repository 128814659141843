import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { actionUpdateTabFilter } from '../../../../actions/filterDetailActions'


import Convetions from './Convetions'
import { SearchBarDetailGenericButton } from '../../../../components/Search/SearchBarGeneric'

const AllConvetions = (props) => {

    const dispatch = useDispatch()

    const [searchBarState, setSearchBarState] = useState("");
    const [popUpOrderState, setPopUpOrderState] = useState("");

    useEffect(() => {
        dispatch(actionUpdateTabFilter('Agreements'))
    }, [dispatch])
    return (
        <>
            <SearchBarDetailGenericButton showButton={false} textPlaceHolder={'Código de la vinculación'} popUpOrderState={popUpOrderState}
            searchBarState={searchBarState} showAZ={true} filterPosition={props.filterPosition}/>
            <Convetions />
        </>
    )
}

AllConvetions.propTypes = {
}

export default AllConvetions 