import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import './modalLoader.css'
import { Modal } from 'semantic-ui-react'

const Divider = styled.div`
  width: 518px !important;
  height: 446px;
  position: absolute;
  z-index: 999;
  top: 62px;
  left: 0;
  right: 0; 
  bottom: 0;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background: #FFFFFF;
  margin: auto;
  -webkit-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
  @media only screen and (max-width: 1200px)  {
    top: 80px;
    height: 450px !important;
  }

  @media only screen and (max-width: 480px)  {
    width: 100% !important;
    top: 0px;
    height: 450px !important;
  }
`
const DividerContent = styled.div`  
  padding-top: 50px;
`
const Text = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #133F4F;
  margin-top: 150px;
`

const ModalLoaderFile = () => { 
  
  const {status, type} = useSelector((state) => state.modalReducer.loginModalFileMasive)

    return (
      <Modal
      open={status}
      >
      <Divider>
          <DividerContent>
            <div class="lds-spinner-fileMasive"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          </DividerContent>
          <Text>Cargando plantilla</Text>
      </Divider>
      </Modal>
    )
}
export default ModalLoaderFile