import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import GeneralTable from '../../../../components/Table/GeneralTable';
import { RowTableConvetionsPrivate } from './RowTableConvetionsPrivate';
import RowTableConvetionsPublic from './RowTableConvetionsPublic';
import {actionClearFilter,actionInitialPagination, activePagination } from '../../../../actions/filterDetailActions'
import {makeClientAgreementRequestV2} from "../../../../actions/agreementActions";
import _ from 'lodash'



class Convetions extends Component {

  state = {
    loader: true,
    init: true,
  };
  
  componentDidMount() {
    if(this.props.filterReducer.tabFilter !== 'Agreements'){
      this.setState({init:false})
      this.props.actionClearFilter()
    }else{
      this.makeAgreementsRequest(this.props.filterReducer);
    }
  };

  makeAgreementsRequest =  (filterReducer) => {
      const body={
      clientSerialID: this.props.clientDatail.companySerialID,
      pag: filterReducer.pag,
      count: !!filterReducer.count ? filterReducer.count : 20,
      sort: this.orderTranslator(filterReducer.sort),
      textToSearch: filterReducer.searchBar[0],
      status:filterReducer.agreementFilter.agreementStatus
    }
      this.props.makeClientAgreementRequestV2(body).then(() => {
        this.props.activePagination(false)
        this.setState({init: false, loader: false })
      }) 
  };

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.filterReducer, this.props.filterReducer) || (prevProps.clientDatail !== this.props.clientDatail && !this.state.init)) {
      
      this.makeAgreementsRequest(this.props.filterReducer); 
      this.setState({ loader: true, init: true });
    }
  }


  orderTranslator = (order) => {
    const options = {
      'masReciente': {"_id": -1},
      'masAntiguo': {"_id": 1},
      'A-Z': {"agreementCode": 1},
      'Z-A': {"agreementCode": -1}
    }
    return options[order]
  };


   columnDataPrivate =[
    { name: "Código vinculación", width: 2, arrow: false },
    { name: "Operador", width: 2, arrow: false },
    { name: "Fecha inicio", width: 2, arrow: false },
    { name: "Fecha Vencimiento", width: 2, arrow: false },
    { name: "Notificaciones enviadas", width: 2, arrow: false },
    { name: "Notificaciones por consumir", width: 2, arrow: false },
    { name: "Estado", width: 2, arrow: false },
  ]
  
  
  columnDataPublic =[
    { name: "Código vinculación", width: 2, arrow: false },
    { name: "Operador", width: 2, arrow: false },
    { name: "Fecha inicio", width: 2, arrow: false },
    { name: "Fecha Vencimiento", width: 2, arrow: false },
    { name: "Notificaciones enviadas", width: 2, arrow: false },
    { name: "Estado", width: 2, arrow: false },
  ]

  getHeaderTable = () => {
    if (this.props.clientDatail.definition === 'PRIVATE') {
      if (this.props.userRole !== 'Operador') {
        return this.columnDataPrivate;
      } else {
        // Eliminar el objeto con nombre 'Operador' del array
        return this.columnDataPrivate.filter(item => item.name !== 'Operador');
      }
    } else {
      if (this.props.userRole !== 'Operador') {
        return this.columnDataPublic;
      } else {
        // Eliminar el objeto con nombre 'Operador' del array
        return this.columnDataPublic.filter(item => item.name !== 'Operador');
      }
    }
  }

  render() {
    return (
      <GeneralTable
        list={this.props.agreementListArray}
        typeList="convetions"
        headerTable={this.getHeaderTable()}
        rowComponent={this.props.clientDatail.definition === 'PRIVATE' ? RowTableConvetionsPrivate : RowTableConvetionsPublic}
        history={this.props.history}
        loader={this.state.loader}
        heigthHeader ={'68px'}
        noWrap={false}
      />
    )
  }
}

Convetions.propTypes = {
  
}

const mapStateToProps = (state) => ({
  filterReducer: state.filterDetailReducer,
  clientDatail: state.clientReducer.detailClient,
  agreementListArray: state.agreementReducer.agreementListArray,
  userRole: state.authReducer.user.roleName,
});

const mapDispatchToProps = {
  actionClearFilter,
  makeClientAgreementRequestV2,
  actionInitialPagination,
  activePagination
};

export default connect(mapStateToProps, mapDispatchToProps)(Convetions)