import {
  GET_USER_DOCUMENTS,
  DETAIL_OF_ATTACHMENT,
  CHANGE_TYPE_LIST,
  ACTIVE_VIEW_GRID,
  ACTIVE_VIEW_LIST,
  INACTIVE_VIEW_GRID,
  INACTIVE_VIEW_LIST,
  CHANGE_DOCUMENT_CURRENT,
  VIEW_PDF,
  USER_DOCUMENT_STARRED,
  SET_DATA_PDF,
  SET_USER_CASE_NOT_FOUND,
  UPDATE_LIST_TO_DELETE_DOCUMENTS,
  DELETE_FROM_LIST_TO_DELETE_DOCUMENTS,
  ADD_ALL_TO_LIST_TO_DELETE_DOCUMENTS,
  DELETE_ALL_FROM_LIST_TO_DELETE_DOCUMENTS,
  LOGOUT,
  CLEAR_ATTACHMENT_DETAIL,
} from '../actions/actionsUtilities/types.js'

const initialState = {
  userDocuments: [],
  detailAttachment: [],
  elementsCount: 0,
  countAttachments: 0,
  typeIcon: 1,
  userData: [],
  viewList: true,
  viewGrid: false,
  documentCurrent: '',
  dataViewPdf: {},
  stateUserDocumentStarred: {},
  listToDelete: [],
  hasSlectedAllAttachmentsToDownload:false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_DOCUMENTS:
      return {
        ...state,
        userDocuments: action.payload.finalResponse,
        elementsCount: action.payload.elementsCount,
      }
    case DETAIL_OF_ATTACHMENT:
      return {
        ...state,
        detailAttachment: action.payload.listAttachmentsFixed,
        countAttachments: action.payload.countAttachments,
        userData: (!!action.payload.userData) ? action.payload.userData : state.userData,
      }
    case CHANGE_TYPE_LIST:
      return {
        ...state,
        typeIcon: action.payload,
      }
    case ACTIVE_VIEW_LIST:
      return {
        ...state,
        viewList: true
      }
    case ACTIVE_VIEW_GRID:
      return {
        ...state,
        viewGrid: true
      }
    case INACTIVE_VIEW_LIST:
      return {
        ...state,
        viewList: false
      }
    case INACTIVE_VIEW_GRID:
      return {
        ...state,
        viewGrid: false
      }
    case CHANGE_DOCUMENT_CURRENT:
      return {
        ...state,
        documentCurrent: action.payload
      }
    case VIEW_PDF:
      return {
        ...state,
        dataViewPdf: action.payload,
      }
    case SET_DATA_PDF:
      return {
        ...state,
        dataViewPdf: { ...state.dataViewPdf, base64: action.payload },
      }
    case USER_DOCUMENT_STARRED:
      return {
        ...state,
        stateUserDocumentStarred: action.payload
      }
    case SET_USER_CASE_NOT_FOUND:
      return {
        ...state,
        userData: {
          to: action.payload
        }
      }
    case UPDATE_LIST_TO_DELETE_DOCUMENTS:
      return {
        ...state,
        listToDelete: [...state.listToDelete, action.payload],
        hasSlectedAllAttachmentsToDownload: [...state.listToDelete, action.payload].length === state.detailAttachment.length
      }
    case DELETE_FROM_LIST_TO_DELETE_DOCUMENTS:
      return {
        ...state,
        listToDelete: state.listToDelete.filter(item => item !== action.payload),
        hasSlectedAllAttachmentsToDownload:false
      }
    case ADD_ALL_TO_LIST_TO_DELETE_DOCUMENTS:
      return {
        ...state,
        listToDelete: state.detailAttachment.map(item => item.documentId),
        hasSlectedAllAttachmentsToDownload:true
      }
    case DELETE_ALL_FROM_LIST_TO_DELETE_DOCUMENTS:
      return {
        ...state,
        listToDelete: [],
        hasSlectedAllAttachmentsToDownload:false
      }
      case CLEAR_ATTACHMENT_DETAIL:
        return {
          ...state,
          dataViewPdf: {
            viewPDF:false
          }
        }

    case LOGOUT:
      return initialState
    default:
      return state
  }
}
