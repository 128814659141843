import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Segment, Table, TableHeaderCell } from 'semantic-ui-react'
import styled, { css } from 'styled-components'
import { CustomComponent } from '../CustomComponent'
import { Dimmer, Loader } from 'semantic-ui-react'
import { ReactComponent as NotFoundNotification } from '../../assets/icons/sent/not_found.svg'
import { ReactComponent as NotFoundClient } from '../../assets/icons/clients/NotFoundClients.svg'
import { ReactComponent as ArrowFilter } from '../../assets/icons/clients/arrow-filter.svg'
import { TableHead } from '@material-ui/core'
import './styles.css'

const CustomTable = styled(Table)`
 padding-left: 25px !important;
  width: 100% !important;
  float: right !important;
  margin: 0 !important;
  border-bottom: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: unset !important;
  overflow: scroll;
  @media only screen and (max-width: 768px) {
    padding-left: 0px !important;
    max-width: 100% !important;
  }
`

const TableBody = styled(Table.Body)`
${props => !!props.children[0] && !props.children[0].props.noHover &&
  css`
  & tr:hover {
  }
  `}
@media only screen and (max-width: 768px) {
  max-width: 100% !important;
}
`

const Message = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #133F4F;
  margin-bottom: 15px;
`

const MessageClients = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #133F4F;
  margin-top: 25px;
  margin-bottom: 15px;
`

const HeaderCell = styled(TableHeaderCell)`
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  max-width: 1px !important;
  padding: 5px !important;
  color: #133F4F !important;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.92), rgba(255, 255, 255, 0.92)), #000000 !important;
`

const Arrow = styled(ArrowFilter)`
  margin-left: 5px;
  width: 10px
`
const ContainerScroll = styled.div`
  width: 100%;
  height: 64vh;
  overflow-y: scroll;
  overflow-x: none;
  max-width: 100%;
  ::-webkit-scrollbar-track {
    background: #CCCCCC;
  }

  ::-webkit-scrollbar-thumb {
    background: #9D9D9C !important;
  }

  ::-webkit-scrollbar:vertical {
    width: 5px;
  }
  @media only screen and (max-width: 1024px) {
    height: 71vh;
  }
`
const DivMessage = styled.div`
  width: 100%;
  height: 100%;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const NotFoundCell = styled.tr`
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(54px * 10);
  &:hover {
    border: none !important;
    box-shadow: unset !important;
  }
`
const CustomHeadTable = styled(Table)`
  position: relative;
  width: 100% !important;
  padding-left: 25px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.92), rgba(255, 255, 255, 0.92)), #000000 !important;
  margin: 0 !important;
  border: 0 !important;
  border-radius: 0 !important;
  @media only screen and (max-width: 1024px) {
    padding-left: 0px;
  }
  @media only screen and (max-width: 768px) {
    display: none;
  }
`

const GeneralTableTwo = (props) => {

  const containerRef =  useRef(null)


  const listData = () => {
    const { list, loader } = props
    if (!list.length && !loader) {
      switch (props.typeList) {
        case "notifications":
          return <NotFoundCell >
            <td>
              <DivMessage>
                <Message>¡No existen notificaciones enviadas!</Message>
                <NotFoundNotification />
              </DivMessage>
            </td>
          </NotFoundCell>
        case "clients":
          return <NotFoundCell>
            <td>
              <DivMessage>
                <NotFoundClient />
                <MessageClients>¡Aún no hay entidades creadas!</MessageClients>
              </DivMessage>
            </td>
          </NotFoundCell>
        case "providers":
          return <NotFoundCell>
            <td>
              <DivMessage>
                <NotFoundClient />
                <MessageClients>¡Aún no hay operadores creados!</MessageClients>
              </DivMessage>
            </td>
          </NotFoundCell>
        case "users":
          return <>
            <NotFoundCell>
              <td>
                <DivMessage>
                  <NotFoundClient />
                  <MessageClients>¡Aún no hay usuarios creados!</MessageClients>
                </DivMessage>
              </td>
            </NotFoundCell></>
        case "convetions":
          return <NotFoundCell>
            <td>
              <DivMessage>
                <NotFoundClient />
                <MessageClients>¡Aún no hay vinculaciones creadas!</MessageClients>
              </DivMessage>
            </td>
          </NotFoundCell>
        case "bags":
          return <NotFoundCell>
            <td>
              <DivMessage>
                <NotFoundClient />
                <MessageClients>¡Aún no hay bolsas creadas!</MessageClients>
              </DivMessage>
            </td>
          </NotFoundCell>
        case "userDocuments":
          return <NotFoundCell>
            <td>
              <DivMessage>
                <Message>¡No existen documentos enviados!</Message>
                <NotFoundNotification />
              </DivMessage>
            </td>
          </NotFoundCell>
          case "reports":
            return <NotFoundCell>
              <td>
                <DivMessage>
                  <Message>¡Aún no hay reportes generados!</Message>
                  <NotFoundNotification />
                </DivMessage>
              </td>
            </NotFoundCell>
        default:
          return <tr >
            <td>
              <DivMessage>
                <Message>¡No existen coincidencias para esta búsqueda!</Message>
                <NotFoundNotification />
              </DivMessage>
            </td>
          </tr>
      }
    } else {
      return list
        .map((v, index) =>
          <CustomComponent
            key={index}
            data={{attribute:v, index:index}}
            component={props.rowComponent}
            {...props}
          />)
    }
  }

  const listColumn = () => {
    const { list, loader } = props
    if (!!list.length && !loader) {
      if (props.headerTable !== undefined && !!props.headerTable.length || props.headerTable.isArray() && !!props.headerTable.length === false) {
        return (
          <CustomHeadTable>
            <TableHead>
              <Table.Row>
                {props.headerTable.map((column, index) => <HeaderCell key={index} width={column.width}>{column.name}{column.arrow === true ? <Arrow /> : ''}</HeaderCell>)}
              </Table.Row>
            </TableHead>
          </CustomHeadTable>

        )
      }
    }
  }

  return (
    <Segment style={{ padding: '0px', margin:'0px' }}>
      <Dimmer.Dimmable active={props.loader.toString()}>
        <Dimmer active={props.loader} inverted>
          <Loader size='medium'>Cargando...</Loader>
        </Dimmer>
        {listColumn()}
          <CustomTable id='table' className={!!props.striped ? 'alter-table' : ''}>
            <TableBody >
              {listData()}
            </TableBody>
          </CustomTable>
      </Dimmer.Dimmable>
    </Segment>
  )
}

GeneralTableTwo.propTypes = {
  list: PropTypes.array.isRequired,
  rowComponent: PropTypes.object.isRequired,
  loader: PropTypes.bool
}

export default GeneralTableTwo
