import React from 'react'
import { Table } from 'semantic-ui-react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { ReactComponent as EnabledButton } from '../../../../assets/icons/clients/enabled-button.svg'

const SentCell = styled(Table.Cell)`
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  max-width: 1px !important;
  padding: 10px !important;
`

const Row = styled(Table.Row)`
`

const Enabled = styled(EnabledButton)`
  margin: 4px 0 0 0 !important;
`

export const RowTableBags = (props) => {
    return (
        <Row>
            <SentCell width={2}>
                {props.data.nombreBolsa}
            </SentCell>
            <SentCell width={3}>
                {props.data.serviciosDeNotificacion}
            </SentCell>
            <SentCell width={2}>
                {props.data.cantidadTransacciones}
            </SentCell>
            <SentCell width={2}>
                {props.data.fechaVencimiento}
            </SentCell>
            <SentCell width={1}>
                <Enabled />
            </SentCell>
            <SentCell width={1}>
            </SentCell>
        </Row>
    )
}

RowTableBags.propTypes = {
    data: PropTypes.object.isRequired
};

export default RowTableBags