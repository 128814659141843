import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  isSameDay,
  addMonths
} from 'date-fns';

const today = new Date()

const defineds = {
  startOfWeek: startOfDay(addDays(today, -7)),
  endOfWeek: endOfDay(today),
  startOfLastWeek15: startOfDay(addDays(today, -14)),
  endOfLastWeek15: endOfDay(today),
  startOfLastWeek: startOfWeek(addDays(today, -7)),
  endOfLastWeek: endOfWeek(addDays(today, -7)),
  startOfToday: startOfDay(today),
  endOfToday: endOfDay(today),
  startOfYesterday: startOfDay(addDays(today, -1)),
  endOfYesterday: endOfDay(addDays(today, -1)),
  startOfMonth: startOfMonth(today),
  endOfMonth: endOfMonth(today),
  startOfLastMonth: startOfDay(addDays(today, -365)),
  endOfLastMonth: endOfDay(today),
  oneMonthAgo: endOfDay(addMonths(today, -1)),
  oneHundred: startOfDay(addDays(today, -36500)),
  totalDate: new Date("2021-08-01T05:00:00.000Z"),
};

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range();
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    );
  },
};

export function createStaticRanges(ranges) {
  return ranges.map(range => ({ ...staticRangeHandler, ...range }));
}

export const defaultStaticRanges = createStaticRanges([
  {
    label: 'Hoy',
    range: () => ({
      startDate: defineds.startOfToday,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: 'Última semana',
    range: () => ({
      startDate: defineds.startOfWeek,
      endDate: defineds.endOfWeek,
    }),
  },
  {
    label: 'Últimos 15 días',
    range: () => ({
      startDate: defineds.startOfLastWeek15,
      endDate: defineds.endOfLastWeek15
    }),
  },
  {
    label: 'Último mes',
    range: () => ({
      startDate: defineds.oneMonthAgo,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: 'Último año',
    range: () => ({
      startDate: defineds.startOfLastMonth,
      endDate: defineds.endOfLastMonth,
    }),
  },
  {
    label: 'Todo',
    range: () => ({
      startDate: defineds.totalDate,
      endDate: today,
    }),
  }
])
