import React, { Component } from 'react'
import { MainSegment } from '../../../components/Sengment/MainSegment'
import CreateClients from './CreateClients'

export class AddClients extends Component {

  render() {
    return (
      <MainSegment>
        <CreateClients />
      </MainSegment>
    )
  }
}
export default AddClients