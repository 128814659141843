import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import { connect } from "react-redux"
import { Popup, Image, Grid } from "semantic-ui-react"
import styled from "styled-components"
import cogoToast from "cogo-toast"
import { toastOptions } from "../../helpers/globalHelpers"
import { logoutUser, changeRole, showChangeRoleModal } from "../../actions/authActions"
import ButtonWhite from "./menu/ButtonWhite"
//Iconos
import user from "../../assets/icons/user.png"
import singnOff from "../../assets/icons/sign-off.png"
import { deleteLoadNotificationItemV2 } from '../../actions/writerActions'
import { ReactComponent as Reviewed } from '../../assets/icons/reviewed.svg'
import { Cookies } from 'react-cookie';

const Divider = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media only screen and (max-width: 768px) {
    display:none;
  }
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-right: 16px;
`
const Name = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 16px;
  color: #FFFFFF;
  padding-top: 8px;
`
const NameCompany = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
  text-transform: capitalize !important;
`
const PopupUser = styled(Popup)`
  background: #fff !important;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.25) !important;
  border: none !important;
  border-radius: 8px !important;
  padding: 0 !important;
  :before{
    z-index: 0 !important;
  }
`

const LineBorder = styled.hr`
  width:92%;
  height:0px;
  margin: auto;
  background-color: #B2B2B;
  margin-top:10px;
`

const DropDownName = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #133F4F;
  text-align: center;
  padding-top: 20px;
  padding-left: 5px;
  padding-right: 5px;
  margin: auto;
  overflow: hidden;
  text-overflow: ellipsis;
`

const DropDownText = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #6D6E71;
  text-align: center;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  margin: auto;
  overflow: hidden;
  text-overflow: ellipsis;
`
const FullEmailtext = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  margin: auto;
  box-shadow: 0px 4px 16px 0px #00000040;
  background: white;
  border-radius:4px;
  height: 34px;
  padding: 10px;
  position: absolute;
  top: -50px;
`

const ButtonOption = styled.button`
  color: #7C7C74;
  cursor: pointer;
  border: 0;
  background: #fff;
  font-weight: normal;
  font-size: 16px;
  height: 24px;
  text-align: left;
  width: 100%;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  margin-top: 6px
`
const Text = styled.p`
  color: #7C7C74;
  font-weight: normal;
  font-size: 16px;
  padding-left: 24px
`
const ContainerOption = styled.div`
  padding: 16px 16px !important;
`

class MenuUser extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      showFullEmail:false,
      emailisCut:false,
    }
    this.popupRef = React.createRef()
  }

  componentDidMount() {
    document.addEventListener('click', this.handleOutsideClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false);
  }

  handleOutsideClick = e => {
    //obtener los elementos del menu y el popup
    const buttonElement = ReactDOM.findDOMNode(this);
    const popUpElement = ReactDOM.findDOMNode(this.popupRef.current);

    //si el click se hace en el menu no se realiza ninguna accion 
    if (buttonElement.contains(e.target)) return;


    if (!popUpElement.contains(e.target)) {
      this.setState({ show: false });
    }

  };
  onLogout = async e => {
    e.preventDefault()
    this.deleteNotificationSentRecords()
    const myCookie= new Cookies()
    await this.props.logoutUser(this.props.history, myCookie)
    cogoToast.info('Sesión finalizada exitosamente', toastOptions)

  }

  deleteNotificationSentRecords = async () => {
    const statusList = ['Completado', 'Incompleto', 'No cargado', 'Error']
    const body = {
      fileSerialIDList: this.props.resultNotificationsProgress.progress.filter((item) => statusList.includes(item.state)).map(item => item.fileSerialID),
      newVisibility: false
    }
    if (body.fileSerialIDList.length > 0) {
      await this.props.deleteLoadNotificationItemV2(body)
    }

  }

  cutName = (name) => {
    let [beforeAtSign, afterAtSign] = name.split("@");
    if (beforeAtSign.length > 18) {
        beforeAtSign = beforeAtSign.substring(0, 15) + "...";
        this.state.emailisCut == false && this.setState({ emailisCut: true });
    }
    if (afterAtSign.length > 13) {
        afterAtSign = afterAtSign.substring(0, 13) + "...";
        this.state.emailisCut == false && this.setState({ emailisCut: true });
    }
    return beforeAtSign + '@' + afterAtSign;
}

  handleHoverEmail = () => {
    this.setState({ showFullEmail: true });
  }
  handleHoverExitEmail = () => {
    this.setState({ showFullEmail: false });
  }

  changeRole = (id, role) => {
    if(this.props.actionsRunning.length > 0){
        this.props.showChangeRoleModal(this.props.actionsRunning.length > 1)      
    }else{
      if(this.props.user.role !== role){
        this.props.changeRole(this.props.history, { userSerialID: id })
        this.setState({ show: false });
      } 
    }
  }

  render() {
    
    return (
      <Divider>
        <Container>
          {
            this.props.user.firstName !== undefined && this.props.user.lastName !== undefined
            && <Name>{this.props.user.firstName} {this.props.user.lastName}</Name>
          }
          {
            this.props.companyID !== undefined && <NameCompany>{this.props.companyID.name}</NameCompany>
          }
          {
            this.props.user.roleName !== undefined && <NameCompany>{this.props.user.roleName}</NameCompany>
          }
        </Container>
        <PopupUser ref={this.popupRef} trigger={<Image src={user} style={{ width: '40.77px', paddingTop: '4px', cursor: 'pointer' }}
          onClick={() => this.setState({ show: !this.state.show })} />}
          position='bottom right' on='click' open={this.state.show} >
          <Grid style={{ width: '270px' }}>
            <Grid.Column style={{ padding: '10px 11.5px 10px 11.7px', borderRadius: '10px' }}>
              {
                this.props.user.firstName !== undefined && this.props.user.lastName !== undefined ?
                  (this.props.documentNumber !== undefined) ?
                    <>
                      <DropDownName>{this.props.user.firstName} {this.props.user.lastName}</DropDownName>
                      <DropDownText>C.C {this.props.user.documentNumber}</DropDownText>
                      <DropDownText>{this.cutName(this.props.user.email)}</DropDownText>
                      {this.state.showFullEmail && this.state.emailisCut &&
                      <div style={{position:'relative',display:'flex',justifyContent:'center'}}>
                        <FullEmailtext >
                          {this.props.user.email}
                        </FullEmailtext>
                      </div>}
                    </>
                    :
                    <>
                      <DropDownName>{this.props.user.firstName} {this.props.user.lastName}</DropDownName>
                      <DropDownText onMouseOver={this.handleHoverEmail} onMouseLeave={this.handleHoverExitEmail} >
                          {this.cutName(this.props.user.email)}
                        </DropDownText>
                      {this.state.showFullEmail && this.state.emailisCut &&
                        <div style={{position:'relative',display:'flex',justifyContent:'center'}}>
                          <FullEmailtext >
                            {this.props.user.email}
                          </FullEmailtext>
                        </div>}
                        
                    </>
                  : ''
              }
              <LineBorder />
              {/* {!!this.props.user.roles && this.props.user.roles.length >= 1 
              && this.props.typeOfLogin === 'withCertificate' 
              &&
                <>
                  <ContainerOption>
                    <DropDownName style={{ justifyContent: 'flex-start', display: 'flex', padding: '0px 0px', fontSize: '16px', 
                    fontWeight: '400' }}>Tus roles</DropDownName>
                    {this.props.user.roles.map(({ userSerialID, role, roleName }) =>
                      <ButtonOption  key={userSerialID} className='buttonOptionHover' 
                      onClick={() => this.changeRole(userSerialID, role)}>
                        {this.props.user.role === role && <Reviewed style={{ margin: '1px 6px 0 0' }} />}
                        {this.props.user.role !== role ? <Text>{roleName}</Text> : roleName}
                      </ButtonOption>
                    )}
                  </ContainerOption>
                  <LineBorder style={{ marginTop: '0px' }} />
                </>
              } */}
              <ButtonWhite icon={singnOff} value={{ title: 'Cerrar sesión' }} onClick={this.onLogout} endLine={true}
                color={'#22C1D0'} />
            </Grid.Column>
          </Grid>
        </PopupUser>
      </Divider>
    )
  }
}

const mapStateToProps = state => ({
  userPermissions: state.authReducer.user.userPermission,
  actionsRunning: state.authReducer.actionsRunning,
  user: state.authReducer.user,
  typeOfLogin: state.authReducer.typeOfLogin,
  companyID: state.authReducer.user.companyID,
  resultNotificationsProgress: state.writerReducer.resultNotificationsProgress
})

const mapDispatchToProps = {
  logoutUser,
  deleteLoadNotificationItemV2,
  changeRole,
  showChangeRoleModal
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuUser)
