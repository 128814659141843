import React, { useEffect, useState } from 'react'
import { Dimmer, Grid, Loader, Segment } from 'semantic-ui-react';
import DashBoardLabel from '../../components/Label/DashBoardLabel';
import { ContainerSearchBar } from '../../components/Search/ContainerSearchBar';
import { useDispatch, useSelector } from 'react-redux';
import { getDashboardAgreementInfo, getDashboardAgrements } from '../../actions/dashboardActions';
import styled from 'styled-components';
import { DashBoardLabelSearch } from '../../components/Label/DashBoardLabelSearch';
import DashBoardLabelClient from '../../components/Label/DashBoardLabelClient';
import { REMOVE_FILTER } from '../../actions/actionsUtilities/types';

const CustomPTitle = styled.p`
font-family: 'Roboto';
font-style: normal;
font-weight: 600;
font-size: 24px;
text-align: left;
color: #133F4F;
padding: 2% 2% 0 2%;
@media only screen and (max-height: 1024px) {
  font-size: 18px;
}
`
const DivLabel = styled.div`
max-width: 100%;
max-height: 100%;
height: 100%;
.row1{
  columns: 2;
  gap: 31px;
}
.column1{
  width: 410px !important;
}
@media only screen and (max-height: 900px) {
  .row1{
    padding-top: 0px !important;
  }
}
@media only screen and (max-width: 1024px){
  .row1{
    columns: 2;
    gap: 2%;
  }
  .column1{
    width: 49% !important;
  }
}
`

export const DashBoardOperator = ({ result = {} }) => {

  const dispatch = useDispatch();
  const { listAgreementsSearched, agreementInfo = {}, showAgreementInfo } = useSelector(state => state.dashboardReducer)
  const { agreements = 0, totalClients = 0, uniqueIds = 0 } = result
  const [loader, setLoader] = useState(true);
  const [textToSearch, setTextToSearch] = useState('')
  const [agreementName, setAgreementName] = useState('')
  const [showAgreement, setShowAgreement] = useState(false)
  const [showMoreBoard, setShowMoreBoard] = useState(false)
  const [options, setOptions] = useState([])
  const [agreementSerialID, setAgreementSerialID] = useState('')
  const [valueSearch, setValueSearch] = useState('')

  const { agreementCode, agreementNotifications, companyProvider, expirationDate, notificationType, numberSent,
    status, startDate } = agreementInfo
  
  useEffect(() => {
    let timer1 = setTimeout(() => setLoader(false), 2 * 1000);
    if (Object.keys(result).length !== 0) {
      clearTimeout(timer1);
    }
  }, [result])

  useEffect(() => {
    setShowAgreement(showAgreementInfo)
  }, [showAgreementInfo])

  useEffect(() => {
    if (textToSearch.length >= 4) {
      const body = {textToSearch: textToSearch}
      dispatch(getDashboardAgrements(body))
    }
  }, [textToSearch,dispatch])

  const handleAutocompleteSearch = (e) => {
    dispatch({
      type: REMOVE_FILTER,
      payload: false
    })
    if (e.target.value.length >= 4) {
      setTextToSearch(e.target.value)
    }
  }
  const onChangeHandler = (e, data) => {
    setAgreementSerialID(data.value)
    setAgreementName(e.target.textContent)
    setValueSearch(data.value)
  }

  const onApply = () => {
    if (agreementSerialID !== '') {
      const body = {agreementSerialID: agreementSerialID}
      dispatch(getDashboardAgreementInfo(body))
    }
  }

  useEffect(() => {
    var optionsList = []
    if (listAgreementsSearched) {
      listAgreementsSearched.map(({ agreementSerialID, formattedAgreement }) => {
        optionsList.push({ text: formattedAgreement, value: agreementSerialID, key: agreementSerialID })
      })
      const slicedArray = optionsList.slice(0, 5);
      setOptions(slicedArray)
    }
  }, [listAgreementsSearched])

  
  useEffect(()=>{
    handleClickRemove()
  },[])

  const handleClickRemove = ()=>{
    setValueSearch('')
    setTextToSearch('')
    setAgreementSerialID('')
    dispatch({
      type: REMOVE_FILTER,
      payload: false
    })
  }


  return (
    <Segment style={{ padding: '0px', border: '0px', boxShadow: '0 0px 0px 0 rgba(34,36,38,.15)' }}>
      <Dimmer.Dimmable>
        <Dimmer active={loader} inverted page={false} >
          <Loader size='medium'>Cargando...</Loader>
        </Dimmer>
        <ContainerSearchBar buttonTitle='Aplicar' textPlaceHolder='Vinculación o cliente' enableDeleteButton={true}
          textDeleteButton='Borrar filtro' handleAutocompleteSearch={handleAutocompleteSearch} options={options} onApply={onApply}
          onChangeHandler={onChangeHandler} valueSearch={valueSearch} setValueSearch={setValueSearch} 
          setTextToSearch={setTextToSearch} handleClickRemove={handleClickRemove}/>
        {showAgreement ?
          <DivLabel>
            <div>
              <CustomPTitle>Vinculación: {agreementName}</CustomPTitle>
            </div>

            <Grid style={{ padding: '0.755%', margin: '0px' }} spacing={2} columns={3} doubling>
              <Grid.Row >
                <Grid.Column style={{ padding: '1.245%' }} className='columnSearch' key={agreementCode}>
                  <DashBoardLabelSearch
                    agreementCode={agreementCode}
                    agreementStatus={status}
                    startDate={startDate}
                    expirationDate={expirationDate}
                    notificationType={notificationType}
                    operatorName={companyProvider}
                    aproveedNotifications={agreementNotifications}
                    companyDefinition={agreementNotifications !== null ? 'PRIVATE' : 'PUBLIC'}
                    setShowMoreBoard={setShowMoreBoard}
                  />
                </Grid.Column>
                
                {status === "ACTIVE" &&
                <>
                  <Grid.Column style={{ padding: '1.245%' }} className='columnSearch' >
                    <DashBoardLabelClient total={true} maxW={true} search={true} text='Total notificaciones de la vinculación'
                      number={agreementNotifications !== null ? agreementNotifications : 0} />
                  </Grid.Column>
                  <Grid.Column style={{ padding: '1.245%' }} className='columnSearch' >
                    <DashBoardLabelClient search={true} text='Notificaciones enviadas vinculación en curso'
                      number={numberSent} totalNotifications={agreementNotifications !== null ? agreementNotifications : 0} sent={true} maxW={true} />
                  </Grid.Column>
                </>
                }
              </Grid.Row>
            </Grid>
          </DivLabel>
          :
          <Grid style={{ padding: '2%' }} columns={3} doubling>
            <Grid.Row>
              <Grid.Column >
                <DashBoardLabel text='Vinculaciones' number={agreements}></DashBoardLabel>
              </Grid.Column>
              
              <Grid.Column >
                <DashBoardLabel text='Total clientes' number={totalClients}></DashBoardLabel>
              </Grid.Column>

              <Grid.Column >
                <DashBoardLabel text='Cédulas únicas con notificaciones enviadas ' number={uniqueIds}></DashBoardLabel>
              </Grid.Column>
            </Grid.Row>
          </Grid>}
      </Dimmer.Dimmable>
    </Segment>
  )
}