import React, { Component } from 'react'
import {SearchBarGenericButtonType} from '../SearchBarGeneric'
import PopUpFilterClientsAndProviders from '../SearchClientsAndProviders/PopUpFilterClientsAndProviders';

class ContainerSearchClientsAndProviders extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchBarState: "",
      popUpOrderState: "",
    };
  }
  render() {
    return (
      <SearchBarGenericButtonType 
      buttonType={this.props.buttonType} 
      text={this.props.text} 
      showButton={this.props.showButton} 
      showFilters= {this.props.showFilters}
      textPlaceHolder={this.props.textPlaceHolder} 
      popUpOrderState={this.popUpOrderState} 
      searchBarState={this.searchBarState} 
      showAZ={this.props.showAZ} 
      dataFilter={<PopUpFilterClientsAndProviders reportType={this.props.reportType} providerType={this.props.providerType}/>} 
      showOldestNewest={false} 
      showExportButton={this.props.showExportButton}
      disabledExportButton={this.props.disabledExportButton}
      customWidthDiv2={this.props.customWidthDiv2}
      showAdvancedSearch={this.props.showAdvancedSearch}
      />
    )
  }
}

export default ContainerSearchClientsAndProviders
