import axios from 'axios'
import {
    AGREEMENT_LIST,
    SAVE_AGREEMENT,
    ACTIVE_STATE_FORM_AGREEMENT,
    INACTIVE_STATE_FORM_AGREEMENT,
    ACTIVE_FORM_EDIT_AGREEMENT,
    INACTIVE_FORM_EDIT_AGREEMENT,
    GET_PROVIDERS_LIST,
    GET_CLIENTS_LIST,
    CHANGE_STATE_MESSAGE_MODAL,
    CHANGE_STATE_REFRESH_MODULE,
    DETAIL_AGREEMENT,
    CHANGE_EDIT_AGREEMENT,
    GET_AGREEMENT_EDIT,
    EDIT_AGREEMENT,
    CLEAR_AGREEMENT_EDIT,
    CLEAR_AGREEMENT_DETAIL,
    VIEW_PAGINATION,
    VIEW_PAGINATION_INTERNAL,
    SET_LOGS_LIST_AGREEMENTS
} from './actionsUtilities/types.js'
import { API_ENDPOINT } from "../config/config"
import moment from 'moment/moment.js'
import {
    GET_AGREEMENT_DETAIL_ROUTE, PUT_AGREEMENT_DETAIL_ROUTE, GET_LIST_CLIENTS,
    CANCELE_AGREEMENT_ROUTE, POST_CREATE_AGREEMENT_V2, AGREEMENT_LIST_ROUTE_V2, GET_LIST_PROVIDERS_V2,
    GET_CLIENT_AGREEMENT_V2,
    LOGS_LIST_AGREEMENTS
} from './actionsUtilities/actionsRoutes.js'
import { deleteFromListActions, reasonDispatch, updateListActions } from './DispatchGeneric.js'
import { decrypt, encrypt } from '../helpers/cypher.js'
import { getHeaders } from '../helpers/globalHelpers.js'

export const agreementListV2 = (body) => async (dispatch) => {
    const encryptedBody = await encrypt(body);
    await axios.post(
        `${API_ENDPOINT + AGREEMENT_LIST_ROUTE_V2}`, { data: encryptedBody }, { headers: getHeaders() }
    ).then(async res => {
        const decryptedData = await decrypt(res.data.result);
        dispatch({
            type: AGREEMENT_LIST,
            payload: decryptedData
        })
        dispatch({
            type: VIEW_PAGINATION,
            payload: {
                total: decryptedData.elementsCount,
            }
        })
    }).catch(async reason => {
        await reasonDispatch(dispatch, reason, false)
    })
}

export const activeFormAgreement = () => async dispatch => {
    dispatch({
        type: ACTIVE_STATE_FORM_AGREEMENT
    })
}

export const inactiveFormAgreement = () => async dispatch => {
    dispatch({
        type: INACTIVE_STATE_FORM_AGREEMENT
    });
}

export const getProviderList2V2 = () => async (dispatch) => {
    await axios.get(`${API_ENDPOINT + GET_LIST_PROVIDERS_V2}`, { headers: getHeaders() }
    ).then(async (res) => {
        const decryptedData = await decrypt(res.data.result);
        dispatch({
            type: GET_PROVIDERS_LIST,
            payload: decryptedData.companies
        })
    }).catch(async reason => {
        await reasonDispatch(dispatch, reason, false)
    })
}

export const getClientList2 = () => async (dispatch) => {
    await axios.get(`${API_ENDPOINT + GET_LIST_CLIENTS}`, { headers: getHeaders() }
    ).then(async (res) => {
        if (res.status === 200) {
            const decryptedData = await decrypt(res.data.result);
            dispatch({
                type: GET_CLIENTS_LIST,
                payload: decryptedData.companies
            })
        }
    }).catch(async reason => {
        await reasonDispatch(dispatch, reason, false)
    })
}

export const postCreateAgreementV2 = (body) => async (dispatch) => {
    dispatch(updateListActions('CREATE_AGREEMENT'))
    const encryptedBody = await encrypt(body);
    await axios.post(`${API_ENDPOINT + POST_CREATE_AGREEMENT_V2}`, { data: encryptedBody }, { headers: getHeaders() }
    ).then(async res => {
        var decryptedData = await decrypt(res.data.result);

        dispatch({
            type: SAVE_AGREEMENT,
            payload: decryptedData
        })
        dispatch({
            type: CHANGE_STATE_MESSAGE_MODAL,
            payload: { type: 'agreementCreate' }
        },
            setTimeout(() => {
                dispatch({
                    type: CHANGE_STATE_MESSAGE_MODAL,
                    payload: { type: 'agreementCreate' }
                })

                dispatch({
                    type: CHANGE_STATE_REFRESH_MODULE,
                    payload: true
                })
            }, 2000)
        )
        dispatch({ type: INACTIVE_STATE_FORM_AGREEMENT, })
        dispatch(deleteFromListActions('CREATE_AGREEMENT'))

    }).catch(async reason => {
        await reasonDispatch(dispatch, reason, false)
        dispatch({
            type: CHANGE_STATE_MESSAGE_MODAL,
            payload: { type: 'errorCreateEntities' }
        })
        dispatch(deleteFromListActions('CREATE_AGREEMENT'))
    })
}

export const getAgreementDetail = (id) => async dispatch => {
    await axios.get(
        `${API_ENDPOINT + GET_AGREEMENT_DETAIL_ROUTE}${id}`,
        { headers: getHeaders() }
    ).then(async res => {
        var decryptedData = await decrypt(res.data.result);
        dispatch({
            type: DETAIL_AGREEMENT,
            payload: decryptedData.agreement
        })
    }).catch(async reason => {
        await reasonDispatch(dispatch, reason, false)
    })
}

export const changeFormAgreement = (key, value) => async (dispatch) => {
    dispatch({
        type: CHANGE_EDIT_AGREEMENT,
        payload: {
            key,
            value
        }
    })
    dispatch({
        type: ACTIVE_FORM_EDIT_AGREEMENT,
    })
    return Promise.resolve();
}

export const getAgreementEdit = (id) => async dispatch => {
    await axios.get(
        `${API_ENDPOINT + GET_AGREEMENT_DETAIL_ROUTE}${id}`, { headers: getHeaders() }
    ).then(async res => {
        const { agreement } = await decrypt(res.data.result);
        dispatch({
            type: GET_AGREEMENT_EDIT,
            payload: {
                id: agreement.id,
                companyProviderID: agreement.providerData.companySerialID.replace(/[ '"]+/g, ' '),
                companyClientID: agreement.clientData.companySerialID.replace(/[ '"]+/g, ' '),
                startDate: moment(agreement.startDate).utc().format("YYYY-MM-DD"),
                endDate: moment(agreement.endDate).utc().format("YYYY-MM-DD"),
                agreementCode: agreement.agreementCode,
                subscriptionDate: moment(agreement.subscriptionDate).utc().format("YYYY-MM-DD"),
                tradeNumber: agreement.tradeNumber,
                tradeDate: moment(agreement.tradeDate).utc().format("YYYY-MM-DD"),
                tradeDetails: agreement.tradeDetails,
                tradeSupport: agreement.tradeSupport,
                agreementsFiles: agreement.agreements,
                bagName: agreement.nameBag,
                quantity: agreement.quantity,
                services: agreement.services,
                sendNotification: agreement.servicesList.sendNotification,
                pushNotification: agreement.servicesList.pushNotification,
                notificationReading: agreement.servicesList.notificationReading,
                notificationSignature: agreement.servicesList.notificationSignature,
                documentSignature: agreement.servicesList.documentSignature,
                status: agreement.status
            }
        })
    }).catch(async reason => {
        await reasonDispatch(dispatch, reason, false)
    })
}

export const putAgreementEditV2 = (body, id) => async dispatch => {
    dispatch(updateListActions('EDIT_AGREEMENT'))
    const encryptedBody = await encrypt(body);
    await axios.put(`${API_ENDPOINT + PUT_AGREEMENT_DETAIL_ROUTE}${id}`, { data: encryptedBody }, { headers: getHeaders() }
    ).then(async res => {
        const decryptedData = await decrypt(res.data.result);

        if (!!decryptedData.success) {
            dispatch({
                type: CHANGE_STATE_MESSAGE_MODAL,
                payload: { type: 'agreementEdit' }
            },
                setTimeout(() => {
                    dispatch({
                        type: CHANGE_STATE_MESSAGE_MODAL,
                        payload: { type: 'agreementEdit' }
                    })
                    dispatch({
                        type: CHANGE_STATE_REFRESH_MODULE,
                        payload: true
                    })
                    dispatch({ type: CLEAR_AGREEMENT_EDIT })


                }, 2000)
            )
            dispatch({
                type: INACTIVE_STATE_FORM_AGREEMENT
            })
            dispatch({
                type: INACTIVE_FORM_EDIT_AGREEMENT
            })
        } else {
            let reason = res
            reasonDispatch(dispatch, reason, false)
            dispatch({
                type: CHANGE_STATE_MESSAGE_MODAL,
                payload: { type: 'unknownError' }
            })
        }
        dispatch(deleteFromListActions('EDIT_AGREEMENT'))

    }).catch(async reason => {
        await reasonDispatch(dispatch, reason, false)
        dispatch(deleteFromListActions('EDIT_AGREEMENT'))
    })
}

export const clearActiveAgreementEdit = () => async dispatch => {
    dispatch({ type: CLEAR_AGREEMENT_EDIT })
}

export const clearActiveAgreementDetail = () => async dispatch => {
    dispatch({ type: CLEAR_AGREEMENT_DETAIL })
}

export const canceleAgreementReason = (body) => async dispatch => {
    const encryptedBody = await encrypt(body);
    await axios.post(
        `${API_ENDPOINT + CANCELE_AGREEMENT_ROUTE}`, { data: encryptedBody }, { headers: getHeaders() }
    ).then(async res => {
        const decryptedData = await decrypt(res.data.result);
        if (!!decryptedData.success) {
            dispatch({
                type: EDIT_AGREEMENT,
                payload: decryptedData.updatedAgreement
            })
            dispatch({
                type: CHANGE_STATE_MESSAGE_MODAL,
                payload: { type: 'agreementCanceleSucces' }
            },
                setTimeout(() => {
                    dispatch({
                        type: CHANGE_STATE_MESSAGE_MODAL,
                        payload: { type: 'agreementCanceleSucces' }
                    })
                    dispatch({
                        type: CHANGE_STATE_REFRESH_MODULE,
                        payload: true
                    })
                    dispatch({ type: CLEAR_AGREEMENT_EDIT })

                }, 2000)
            )
            dispatch({
                type: INACTIVE_STATE_FORM_AGREEMENT
            })
            dispatch({
                type: INACTIVE_FORM_EDIT_AGREEMENT
            })
        } else {
            let reason = res
            reasonDispatch(dispatch, reason, false)
            dispatch({
                type: CHANGE_STATE_MESSAGE_MODAL,
                payload: { type: 'unknownError' }
            })
        }
    }).catch(async reason => {
        await reasonDispatch(dispatch, reason, false)
    })
}

export const makeClientAgreementRequestV2 = (body, stateInitial) => async dispatch => {
    const encryptedBody = await encrypt(body);
    await axios.post(
        `${API_ENDPOINT + GET_CLIENT_AGREEMENT_V2}`, { data: encryptedBody }, { headers: getHeaders() }
    ).then(async res => {
        const decryptedData = await decrypt(res.data.result);

        dispatch({
            type: AGREEMENT_LIST,
            payload: decryptedData
        })
        dispatch({
            type: VIEW_PAGINATION_INTERNAL,
            payload: {
                count: 20,
                total: decryptedData.elementsCount,
            }
        })
    }).catch(async reason => {
        dispatch({
            type: VIEW_PAGINATION_INTERNAL,
            payload: {
                total: 0,
            }
        })
        await reasonDispatch(dispatch, reason, false)
    })
}

export const getLogsList = (body) => async (dispatch) => {
    const encryptedBody = await encrypt(body)
    await axios.post(
        `${API_ENDPOINT + LOGS_LIST_AGREEMENTS}`, { data: encryptedBody }, { headers: getHeaders() }
    ).then(async (res) => {
        const decryptedData = await decrypt(res.data.result);
        dispatch({
            type: SET_LOGS_LIST_AGREEMENTS,
            payload: { logsList: decryptedData.logs, logsCount: decryptedData.elementsCount }
        })
        dispatch({
            type: VIEW_PAGINATION_INTERNAL,
            payload: {
                count: 20,
                total: decryptedData.elementsCount,
            }
        })
    }).catch(async reason => {
        await reasonDispatch(dispatch, reason, false)
        dispatch({
            type: SET_LOGS_LIST_AGREEMENTS,
            payload: { logsList: [], logsCount: 0 }
        })
        dispatch({
            type: VIEW_PAGINATION_INTERNAL,
            payload: {
                count: 20,
                total: 0,
            }
        })
    })
}

