import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const MainButton = styled.button`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0;
  margin: 0 0 0 0;
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor: pointer;
`

const IconButton = (props) => {
  return (
    <MainButton
      onClick={props.onClick}
    >
      {props.children}
    </MainButton>
  )
}

IconButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default IconButton
