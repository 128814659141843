import React, { Component } from 'react'
import { SearchBarGenericSent } from './SearchBarGeneric'

class ContainerSearch extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchBarState: "",
      popUpFilterState: "",
      popUpOrderState: "",
    };
  }
  render() {
    return (
      <SearchBarGenericSent searchBarState={this.searchBarState} popUpOrderState={this.popUpOrderState}
      popUpFilterState={this.popUpFilterState} showAdvancedSearch={this.props.showAdvancedSearch} />
    )
  }
}

export default ContainerSearch
