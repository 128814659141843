import React from 'react'
import moment from 'moment'
import { useDispatch, connect } from 'react-redux'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import '../../index.css'
import {changeDocumentCurrent} from '../../../../actions/seeAttachmentAction'
import Label from '../../../../components/Label/MainLabel'
import {ReactComponent as IconFile} from '../../../../assets/icons/userDocuments/fileIcon.svg'
import OrderInitialLetter from '../../../../components/Search/OrderInitialLetter'
import { actionClearFilter } from '../../../../actions/filterDetailActions'

const ContainerGrid = styled.div`
  width: 300px;
  height: 62px;
  margin: 12px 10px;
  float: left;
  border: 1px solid #DADADA;
  border-radius: 4px;
  padding: 14px 10px;
  cursor: pointer;

&:hover {
  background: #FFFFFF 0 0 no-repeat padding-box !important;
  box-shadow: 0 2px 2px #00000059 !important;
  border: 1px solid #B2B2B2 !important;
  opacity: 1 !important;
}
`

const SourceIcon= styled(IconFile)`
  float: left;
`
const ContainerText = styled.div`
  width: 85%;
  float: left;
  padding: 0px 10px;
`
const ContainerDocument = styled.div`
  float: left;
  width: 100%;
  margin-top: 3px;
  padding: 0px 30px;
`
const ContainerDate = styled.div`
  float: left;
  width: 100%;
  padding: 0px 30px;
`

const Text = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #3C3C3B;
`

const TextTitle = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  color: #3C3C3B;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
`

const TextDate = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #3C3C3B;
`

const StatusContainer = styled.div`
  float: right!important;
  padding-left: 10% !important;
  position: relative;
  top: -28px;
`

const RowTableUserDocuments = (props) => {
  const dispatch = useDispatch()

  const redirectToAttachments = () => {
    props.changeDocumentCurrent(props.data.documentNumberRecipient)
    props.history.push({pathname: '/userDocuments-detail/' + props.data.documentNumberRecipient})
    dispatch(actionClearFilter())
  }

  const formatDate = (userDocumentDate) => moment(userDocumentDate).format('DD/MM/YYYY')
  return (
    <>
      {props.isOrder === "A-Z" || props.isOrder === "Z-A"
      ?
        <OrderInitialLetter
          letter= {props.letter}
          initial={props.isInitial} 
        />
        : ''
      }
      <ContainerGrid onClick={redirectToAttachments}>
      <SourceIcon/>
      <ContainerText>
        <TextTitle>{props.data.nameElement}</TextTitle>
      </ContainerText>
      <ContainerDate>
        <TextDate>Fecha modific: {formatDate(props.data.date)}</TextDate>
      </ContainerDate>
      <StatusContainer>
          {
            props.data.status === 'Pendiente'
              ?
              <Label
                className={'labelDocument'}
                text={'Pendiente'}
                icon={3}
                color={'#E35F00'}
              />
              : ''
          }
        </StatusContainer>
    </ContainerGrid>
    </>
  )
};

RowTableUserDocuments.propTypes = {
  data: PropTypes.object.isRequired,
  changeDocumentCurrent: PropTypes.func,
};

const mapStateToProps = () => ({})

const mapDispatchToProps = {
  changeDocumentCurrent,
}

export default connect(mapStateToProps, mapDispatchToProps)(RowTableUserDocuments)