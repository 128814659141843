import {
    CLEAR_DASHBOARD_SEARCH,
    DASHBOARD_LIST_AGREEMENTS,
    GET_DASHBOARD_AGREEMENT_INFO,
    GET_DASHBOARD_INFO, HAS_AGREEMENTS, LOGOUT, REMOVE_FILTER, RESET_DASHBOARD, SHOW_AGREEMENT_INFO,
} from '../actions/actionsUtilities/types.js'

const initialState = {
    info: [],
    hasAgreements: true,
    listAgreementsSearched: [],
    agreementInfo: {},
    showAgreementInfo: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_DASHBOARD_INFO:
            return {
                ...state,
                info: action.payload
            }
        case HAS_AGREEMENTS:
            return {
                ...state,
                hasAgreements: action.payload
            }
        case RESET_DASHBOARD:
            return {
                ...initialState, hasAgreements: state.hasAgreements
            }
        case CLEAR_DASHBOARD_SEARCH:
            return {
                ...state, agreementInfo: {}, listAgreementsSearched: []
            }

        case LOGOUT:
            return {
                initialState
            }
        case DASHBOARD_LIST_AGREEMENTS:
            return {
                ...state,
                listAgreementsSearched: action.payload
            }
        case GET_DASHBOARD_AGREEMENT_INFO:
            return {
                ...state,
                agreementInfo: action.payload
            }
        case SHOW_AGREEMENT_INFO:
            return {
                ...state,
                showAgreementInfo: action.payload
            }
        case REMOVE_FILTER:
            return {
                ...state,
                listAgreementsSearched: [],
                agreementInfo: {},
                showAgreementInfo: false
            }
        default:
            return state
    }
}