import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'

import RowTableSent from './RowTableSent'
import GeneralTable from '../../../components/Table/GeneralTable'
import { getNotificationsv2 } from '../../../actions/sentActions'
import { actionUpdateTabFilter, actionInitialPagination, activePagination, actionPagination } from '../../../actions/filterActions'
import { actionClearFilter } from '../../../actions/filterDetailActions'
import {closePDFModal} from '../../../actions/modalActions'
import {saveHiredV2} from '../../../actions/writerActions'
import {clearStarred} from '../../../actions/starredAction'
class AllNotifications extends Component {
  static propTypes = {
    history: PropTypes.any.isRequired,
  }

  state = {
    loader: true,
    init: true,
    tempPag: this.props.tempPag,
  }

  componentDidMount() {
    this.props.actionClearFilter()
    this.makeNotificationsRequest(this.props.filterReducer)
    this.props.closePDFModal()
    this.props.saveHiredV2()
  }

  componentDidUpdate(prevProps, prevState) {
    
   if (!_.isEqual(prevProps.filterReducer, this.props.filterReducer) && !this.state.init) {
      switch (this.props.filterReducer.tabFilter) {
        case 'all':
          this.props.filterReducer.requiredType = { key: "", value: "" }
          break;
        case 'readConfirmation':
          this.props.filterReducer.requiredType = { key: "requiredConfirmationRead", value: true }
          break;
        case 'notificationSignature':
          this.props.filterReducer.requiredType = { key: "requiredSignature", value: true }
          break;
        case 'docSignature':
          this.props.filterReducer.requiredType = { key: "requiredSignatureAttachments", value: true }
          break;
        case 'push':
          this.props.filterReducer.requiredType = { key: "isPushNotification", value: true }
          break;
        default:
          break;
      }
      this.makeNotificationsRequest(this.props.filterReducer)
      this.setState({init:true, loader: true })     
    }

    if (prevProps.tabFilter !== this.props.tabFilter) {
      this.props.updateTabFilter(this.props.tabFilter)
    }

    if (prevProps.stateNotificationStarred !== this.props.stateNotificationStarred) {
      this.makeNotificationsRequest(this.props.filterReducer)
    }
  }

  makeNotificationsRequest = (filterReducer) => {
    const body = {
      count: filterReducer.count,
      pag: filterReducer.pag,
      sort: this.orderTranslator(filterReducer.sort),
      status: filterReducer.searchAdvanced.notificationState === "MASSIVE" ? '':filterReducer.searchAdvanced.notificationState,
      initDate: !!filterReducer.searchAdvanced.date.startDate
        ? moment(filterReducer.searchAdvanced.date.startDate).format('YYYY-MM-DD') : '',
      endDate: !!filterReducer.searchAdvanced.date.endDate
        ? moment(filterReducer.searchAdvanced.date.endDate).format('YYYY-MM-DD') : '',
      starred: filterReducer.starred,
      [filterReducer.requiredType.key]: filterReducer.requiredType.value,
      haveAttachments: filterReducer.searchAdvanced.attachedFile,
      textToSearch: filterReducer.searchBar[0],
      massiveDeliveryType:filterReducer.searchAdvanced.notificationState === "MASSIVE"
      ? true: ''
    }

    this.props.getNotificationsv2(body)
    .then(() => {
      this.props.activePagination(false)
      this.setState({init:false, loader: false, tempPag: 1})
  })
}


  orderTranslator = (order) => {
    const options = {
      'masReciente': { "_id": -1 },
      'masAntiguo': { "_id": 1 },
      'A-Z': { "recipient.firstName": 1 },
      'Z-A': { "recipient.firstName": -1 }
    }
    return options[order]
  }

  render() {
    return (
        <GeneralTable
          list={this.props.notifications === undefined ? [] : this.props.notifications}        
          typeList={this.props.filterReducer.isSearch === true ? 'default' : 'notifications'}
          headerTable={[]}
          rowComponent={RowTableSent}
          history={this.props.history}
          loader={this.state.loader} 
          listHeight={'notifications'}
          />
    )
  }
}

AllNotifications.propTypes = {
  notifications: PropTypes.array,
  filterReducer: PropTypes.object,
  stateNotificationStarred: PropTypes.object,
  elementsCount: PropTypes.number,
  getNotificationsv2: PropTypes.func,
  updateTabFilter: PropTypes.func,
  actionInitialPagination: PropTypes.func,
  actionClearFilter: PropTypes.func,
  saveHiredV2: PropTypes.func,
  clearStarred: PropTypes.func
}


const mapStateToProps = (state) => ({
  notifications: state.sentReducer.notifications,
  filterReducer: state.filterReducer,
  stateNotificationStarred: state.starredReducer.stateNotificationStarred,
  elementsCount: state.sentReducer.elementsCount,
  tempPag: state.menuReducer.tempPag,
})

const mapDispatchToProps = {
  getNotificationsv2,
  updateTabFilter: actionUpdateTabFilter,
  actionInitialPagination,
  closePDFModal,
  saveHiredV2,
  activePagination,
  clearStarred,
  actionPagination,
  actionClearFilter,
}

export default connect(mapStateToProps, mapDispatchToProps)(AllNotifications)