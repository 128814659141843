import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from "prop-types";
import styled from 'styled-components'
import ErrorMessageModalSendMassive from "./ErrorMessageModalSendMassive";

const Text = styled.p`
  color: #000000;
  font-family: Roboto;
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
  text-align: justify;
  margin: 0 42px 0 42px;
  @media screen and (max-width:792px){
    margin: 0;
    margin-top: 24px;
  }
`
const Text2 = styled.p`
  color: #000000;
  font-family: Roboto;
  font-size: 16px;
  line-height: 21px;
  text-align: justify;
  padding: 10px 0 10px 0;
  @media screen and (max-width:792px){
    padding: 0;
  }
`
const Text3 = styled.p`
padding: 30px 0px;
ont-family: Roboto;
color: #000000;
font-size: 16px;
font-weight: 700;
line-height: 30px;
letter-spacing: -0.3px;
text-align: left;
  @media screen and (max-width:1280px){
    padding: 15px 0px;
  }
`
const Number = styled.span`
  color: #22C1D0;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  text-align: justify;
  padding: 10px 0 10px 0;
  @media screen and (max-width:792px){
  }
`

const StepsContent = styled.div`
  margin: 18px 40px 0 40px;
  @media screen and (max-width:792px){
    margin: 0;
    margin-top: 24px;
    margin-bottom: 32px;
  }
`
class ListDescription extends Component {
  render() {
    return (
      <>
        <Text> Bienvenido al cargue de envío masivo, aquí podrás cargar varios usuarios al mismo tiempo, solo debes seguir estas instrucciones:</Text>
        <StepsContent>
            <Text2><Number>1.</Number>Descarga la plantilla, deberás completarla con la información que desees subir; por favor no modifiques la estructura del archivo ni el formato, ten en cuenta que la cantidad de registros por envío es desde 100 mil hasta 1 millón, el formato debe ser .CSV y el tamaño de los archivos no puede superar los 10MB.</Text2>
            <Text2><Number>2.</Number>Sube la plantilla con la información diligenciada dando clic en el botón “Cargar Plantilla Masiva”, nosotros haremos el resto… ¡tú, tranquilo!</Text2>
            <Text2><Number>3.</Number>¡Listo!, La plataforma te mostrará el resultado del cargue, por favor verifica que todo quede en orden.</Text2>
            <Text3>Nota: Si en la plantilla cargada hay información incompleta del ciudadano, esa notificación no será enviada.</Text3>
    
            <ErrorMessageModalSendMassive/>
        </StepsContent>
      </>
    )
  }
}

ListDescription.propTypes = {
  errorSendMassiveFile: PropTypes.string,
}

const mapStateToProps = (state) => ({
  errorSendMassiveFile: state.modalReducer.sendMassiveModal.showErrorSendMassiveFile
})

export default connect(mapStateToProps, null)(ListDescription)