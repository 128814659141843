import { createReportInterceptor, getBase64ReportInterceptor, createExportPDFInterceptor, createExportCSVInterceptor } from "../../actions/reportsActions"

export const createReportService = (body) =>{
    return createReportInterceptor(body)
}

export const getBase64ReportService = (body) =>{
    return getBase64ReportInterceptor(body)
} 

export const createExportPDFService = (param) =>{
    return createExportPDFInterceptor(param)
}

export const createExportCSVService = (body) =>{
    return createExportCSVInterceptor(body)
}