import React from 'react'
import styled from 'styled-components'

import { ReactComponent as LeftArrowIcon } from '../../assets/icons/sent/leftArrow.svg'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

const Container = styled.div`
  margin: 30px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
`
const Arrow = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
`
const TitleContainer = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
`
const Title = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const LeftArrow = styled.button`
  padding: 0;
  margin: 0 16px 0 0;
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor: pointer;
`

const HeaderDetailClientYProvider = () => {

  const { detailClient } = useSelector(state => state.clientReducer)
  const { detailProvider } = useSelector(state => state.providerReducer)


  const history = useHistory()
  const ruta = history.location.pathname

  const handleClick = () => {
    if (ruta === `/clients-detail/${detailClient.companySerialID}`) {
      history.push('/clients')
    } else {
      history.push('/providers')
    }
  }
  return (
    <Container>
      <Arrow>
        <LeftArrow
          onClick={handleClick}
        >
          <LeftArrowIcon />
        </LeftArrow>
      </Arrow>
      <TitleContainer>
        {
          ruta === `/clients-detail/${detailClient.companySerialID}`
            ? <Title>
              {detailClient.name}
            </Title>
            : <Title>
              {detailProvider.name}
            </Title>
        }
      </TitleContainer>
    </Container>
  )
}


export default HeaderDetailClientYProvider