import React from 'react'
import PropTypes from 'prop-types'
import ContainerSearchAgreementsClient from '../../../components/Search/SearchAgreements/clientAgreements/ContainerSearchAgreementsClient'
import AllAgreements from "./allAgreements/AllAgreementsClient";
import {useSelector } from 'react-redux';

const ClientAgreements = (props) => {
  const agreements = useSelector(state => state.agreementReducer.agreementListArray )
  return (
    <>
      <ContainerSearchAgreementsClient 
        showAZ={true}
        reportType={"LIST_AGREEMENTS"} 
        providerType={"AGREEMENT"}   
        disabledExportButton={!agreements.length > 0}
        customWidthDiv2={'50vw'}
        showAdvancedSearch={false}
      />
      <AllAgreements history={props.history} />
    </>
  )
}

ClientAgreements.propTypes = {
  history: PropTypes.any.isRequired,
}
export default ClientAgreements
