import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useDispatch, connect } from 'react-redux'
import { Table } from 'semantic-ui-react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import '../../index.css'
import { changeDocumentCurrent,viewPDF, getPDF } from '../../../../actions/seeAttachmentAction'
import { changeMessageModal } from '../../../../actions/modalActions'
import { Cell, CellIconMix, CellIconStarred, CellStatus } from '../../../../components/Table/CellComponent'
import { actionClearFilter } from '../../../../actions/filterDetailActions'
import { userDocumentStarred } from '../../../../actions/userDocumentsActions'
import StarredButton from '../../../../components/Starred/StarredButton'

const Row = styled(Table.Row)`
  cursor: pointer !important;
  height: 50px;
  @media only screen and (min-width: 100px) and (max-width: 768px) {
    display: flex !important;
    flex-direction: column;
    border-bottom: 1px solid #979E9F !important;
    padding: 0.5em 0em!important;
    box-sizing: content-box;
  }
`

const TextRequiredSignature = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;

  @media only screen and (min-width: 100px) and (max-width: 768px) {
    padding-left: 14px;
  }
`

const RowTableUserDocuments = (props) => {

  const dispatch = useDispatch()
  const [stateLocalStarred,  setStateLocalStarred] = useState(props.data.typeSearch !== '1'? props.data.starred : false)
  const redirectToAttachments = () => {
    if (props.data.typeSearch === '1') {
      props.changeDocumentCurrent(props.data.documentNumberRecipient)
      props.history.push({ pathname: '/userDocuments-detail/' + props.data.documentNumberRecipient })
      dispatch(actionClearFilter())
    }else{
      viewPdf()
    }
  }

  const validateUrl = (url) => {
    if (url === undefined) {
      props.changeMessageModal({
        type: 'errorDownloadReport'
      })
      return false
    } else {
      return true
    }
  }

  const viewPdf = () => {
    if (validateUrl(props.data.fileURL) === true) {
      props.viewPDF({ "urlPDF": props.data.fileURL, "viewPDF": true, "documentName": props.data.nameElement})
      props.getPDF({
        fileURL: props.data.fileUrlEncrypted,
        fileName: props.data.fileName
      })
    }
  }

  const hadleStarred = () => {
    setStateLocalStarred(!stateLocalStarred)
    const body = { "attachmentSerialID": props.data.attachmentSerialID }
    dispatch(userDocumentStarred(body))
  }

  useEffect(() => {
    if(props.data.typeSearch !== '1'){
      setStateLocalStarred(props.data.starred)
    }
  }, [props.data])

  const formatDate = (userDocumentDate) => moment(userDocumentDate).format('DD/MM/YYYY')
  return (
    <Row onClick={redirectToAttachments}>
      {<CellIconStarred 
        typeSearch ={props.data.typeSearch} 
        onClick={hadleStarred}
        active={stateLocalStarred}
    />}
      <CellIconMix width={4} typeSearch ={props.data.typeSearch} columnName={props.headerTable[1].name} 
        fullNameRecipient={`${props.data.nameElement}`} nameElement= {props.data.nameElement}>
      </CellIconMix>
      {props.data.owner ? <Cell width={3} columnName={props.headerTable[2].name} text={props.data.owner} /> :
      <Table.Cell width={3}></Table.Cell>}
      <Cell width={3} columnName={props.headerTable[3].name} text={formatDate(props.data.date)} />
      <Table.Cell width={3}>{props.data.requiredSignature === true ? <TextRequiredSignature>Con solicitud de firma</TextRequiredSignature> : <p> </p>}</Table.Cell>
      <CellStatus typeSearch ={props.data.typeSearch} status={props.data.status} width={2}/>
    </Row>
  )
};

RowTableUserDocuments.propTypes = {
  data: PropTypes.object.isRequired,
  changeDocumentCurrent: PropTypes.func,
};

const mapStateToProps = () => ({})

const mapDispatchToProps = {
  changeDocumentCurrent,
  viewPDF,
  changeMessageModal,
  getPDF
}

export default connect(mapStateToProps, mapDispatchToProps)(RowTableUserDocuments)