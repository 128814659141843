import React, { Component } from 'react'
import { SearchBarGenericButtonType } from '../../SearchBarGeneric'
import PopUpFilterAgreements from '../../../Search/SearchAgreements/PopUpFilterAgreements';

class ContainerSearchAgreementsOperator extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchBarState: "",
      popUpOrderState: "",
    };
  }
  render() {
    return (
      <SearchBarGenericButtonType 
        showButton={false} 
        textPlaceHolder={'Código de la vinculación'} 
        popUpOrderState={this.popUpOrderState} 
        searchBarState={this.searchBarState} 
        showAZ={this.props.showAZ} 
        dataFilter={<PopUpFilterAgreements reportType={this.props.reportType} providerType={this.props.providerType} />} 
        showExportButton={true}
        disabledExportButton={this.props.disabledExportButton}
        customWidthDiv2={this.props.customWidthDiv2}
        showAdvancedSearch={this.props.showAdvancedSearch}
      />
    )
  }
}

export default ContainerSearchAgreementsOperator
