import axios from "axios"
import { API_ENDPOINT } from "../config/config"
import { DELTE_DRAFT_LIST_ROUTE_V2, GET_DRAFT_ROUTE_V2, GET_DRAFTS_LIST_ROUTE_V2, SAVE_DRAFT_V2 } from "./actionsUtilities/actionsRoutes"
import { DELETE_ALL_FROM_LIST_TO_DELETE, DELETE_FROM_LIST_TO_DELETE, CLEAR_DATA_OF_DRAFT, DRAFTS_LIST,  UPDATE_LIST_TO_DELETE, SAVE_DATA_OF_DRAFT, DELETE_DRAFT_RECIPIENT, UPDATE_FILE_SERIAL_ID, ACTIVE_STATE_FORM_NOTIFICATION, ADD_MASSIVE_RECIPIENTS_LIST, DRAFT_TO_WRITE, SET_DRAFT_SERIAL_ID, SET_DRAFT_SERIAL_ID_DRAFTS, CHANGE_WRITE_NOTIFICATION_FORM, IS_MASSIVE_RECIPIENT, VIEW_PAGINATION, CHANGE_STATE_MESSAGE_MODAL, SPECIFIC_DOCUMENTS, DELETE_DRAFT_TAGS_AND_SPECIFIC_FILES } from "./actionsUtilities/types"
import { reasonDispatch } from "./DispatchGeneric"
import { changeDeleteDraftListModal, changeDeleteDraftModal } from "./modalActions"
import { actionPagination } from "./filterActions"
import { createNewParametrizationTags } from "./writerActions"
import { decrypt, encrypt } from "../helpers/cypher"
import { getHeaders } from "../helpers/globalHelpers"

function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const draftsListV2 = (body) => async (dispatch) => {
    const encryptedBody = await encrypt(body);
    await axios.post(
        `${API_ENDPOINT + GET_DRAFTS_LIST_ROUTE_V2}`,
        { data: encryptedBody },
        { headers: getHeaders() }
    ).then(async res => {
        const decryptedData = await decrypt(res.data.result);
        dispatch({
            type: DRAFTS_LIST,
            payload: decryptedData
        })
        dispatch({
            type: VIEW_PAGINATION,
            payload: {
                total: decryptedData.elementsCount,
            }
        })
    }).catch(async reason => {
        await reasonDispatch(dispatch, reason, false)
    })
}

export const getDraftV2 = (id) => async (dispatch) => {
    await axios.get(
        `${API_ENDPOINT + GET_DRAFT_ROUTE_V2 + '/' + `${id}`}`,
        { headers: getHeaders() }
    ).then(async res => {
        const decryptedData = await decrypt(res.data.result);
        const draft = decryptedData.draft
        const arrayAttachments = draft.arrayAttachments;
        const obj = {}
        if (Array.isArray(arrayAttachments)) {
            arrayAttachments.forEach(file => Object.assign(obj, {
                [file.attachmentSerialID]: ({
                    name: file.fileOriginalName,
                    fileOriginalName: file.fileOriginalName,
                    type: 'application/pdf',
                    fileExtension: 'pdf',
                    size: file.size,
                    sign: !file.requiredSignature ? false : file.requiredSignature,
                    fileUrl: file.urlEncrypted,
                    id: file.attachmentSerialID
                })
            }));
        }
        if (!!draft.listTagsChargeMassive && draft.listTagsChargeMassive.length) dispatch(createNewParametrizationTags(
            draft.listTagsChargeMassive.map((item) => ({ 'type': 'TAG', 'tagName': item }))))
        const recipients = !!draft.recipients ? draft.recipients : []
        dispatch({ type: SPECIFIC_DOCUMENTS, payload: draft.listDocumentsSpecific })
        dispatch({
            type: DRAFT_TO_WRITE,
            payload: {
                recipient: recipients.map(r => ({ key: r.recipient.split(' ')[0], value: r.recipient })),
                subject: draft.subject,
                textEditor: draft.description,
                files: obj,
                notificationSignature: draft.requiredSignature == null ? false : draft.requiredSignature,
                readReceipt: draft.requiredConfirmationRead == null ? false : draft.requiredConfirmationRead,
                pushNotification: draft.isPushNotification == null ? false : draft.isPushNotification,
                fileSerialID: !!draft.fileSerialID ? draft.fileSerialID : '',
                draftSerialID: !!draft.draftSerialID && draft.draftSerialID
            }
        })

        if (!!draft.fileSerialID) {
            dispatch({
                type: IS_MASSIVE_RECIPIENT,
                payload: true
            })
            dispatch({
                type: CHANGE_WRITE_NOTIFICATION_FORM,
                payload: {
                    key: 'recipient',
                    value: draft.fieldTo
                }
            })
        }
        dispatch({
            type: SAVE_DATA_OF_DRAFT,
            payload: {
                requiredSignature: draft.requiredSignature == null ? false : draft.requiredSignature,
                requiredConfirmationRead: draft.requiredConfirmationRead == null ? false : draft.requiredConfirmationRead,
                isPushNotification: draft.isPushNotification == null ? false : draft.isPushNotification,
                recipients: recipients.map(r => (r.recipient.split(' ')[0])),
                subject: draft.subject,
                description: draft.description,
                attachments: Object.values(obj),
                fileSerialID: draft.fileSerialID,
                draftSerialID: draft.draftSerialID
            }
        })

        dispatch({ type: ACTIVE_STATE_FORM_NOTIFICATION })
        dispatch({
            type: ADD_MASSIVE_RECIPIENTS_LIST,
            payload: {
                ids: recipients.map(r => r.recipient.split(' ')[0]),
                fullData: recipients.map(r => ({ key: r.recipient.split(' ')[0], value: r.recipient })),
                backendRequest: recipients.map(r => ({ documentNumber: r.recipient.split(' ')[0], firstName: r.recipient.split(' ')[2], surname: r.recipient.split(' ')[2] })),
            }
        })
    }).catch(async reason => {
        await reasonDispatch(dispatch, reason, false)
    })
}

export const deleteDraft = (body, filterR) => async (dispatch) => {
    var deleteDrft = false;
    var filterReducer = filterR
    const encryptedBody = await encrypt(body);
    await axios.post(
        `${API_ENDPOINT + DELTE_DRAFT_LIST_ROUTE_V2}`,
        { data: encryptedBody },
        { headers: getHeaders() }
    ).then(res => {
        var bodyCurrent = {
            count: filterReducer.count,
            pag: filterReducer.pag,
            status: "",
            textToSearch: "",
            sort: { "_id": -1 }
        }
        var activeBack = false
        dispatch(changeDeleteDraftModal())
        if (filterReducer.count * filterReducer.pag > filterReducer.total) {
            const totalTemp = filterReducer.total
            const pagBack = filterReducer.pag - 1
            if (totalTemp - 1 === filterReducer.count * pagBack) {
                bodyCurrent.pag = pagBack
                activeBack = true

            }
        }

        if (!!activeBack) {
            if (filterReducer.total === 1) {
                var bodyInit = {
                    count: filterReducer.count,
                    pag: 1,
                    status: "",
                    textToSearch: "",
                    sort: { "_id": -1 }
                }
                dispatch(draftsListV2(bodyInit))
            }
            dispatch(actionPagination(bodyCurrent.pag))
        } else {

            dispatch(draftsListV2(bodyCurrent))
        }
        dispatch({ type: DELETE_ALL_FROM_LIST_TO_DELETE })
        deleteDrft = true
    }).catch(async reason => {
        await reasonDispatch(dispatch, reason, false)
    })

    if (deleteDrft) {
        await timeout(2000);
        dispatch({
            type: CHANGE_STATE_MESSAGE_MODAL,
            payload: { type: 'deleteDraft' }
        })
    }
}


export const deleteDraftListV2 = (body, filterR) => async (dispatch) => {
    var deleteDrft = false;
    var filterReducer = filterR
    var serialIDCount = body.arrayDraftSerialID.length
    const encryptedBody = await encrypt(body);
    await axios.post(
        `${API_ENDPOINT + DELTE_DRAFT_LIST_ROUTE_V2}`,
        { data: encryptedBody },
        { headers: getHeaders() }
    ).then(async res => {
        const decryptedData = await decrypt(res.data.result);
        dispatch(changeDeleteDraftListModal())
        var bodyCurrent = {
            count: filterReducer.count,
            pag: filterReducer.pag,
            status: "",
            textToSearch: "",
            sort: { "_id": -1 }
        }
        var activeBack = false
        if (filterReducer.count * filterReducer.pag > filterReducer.total) {
            const totalTemp = filterReducer.total
            const pagBack = filterReducer.pag - 1
            if (totalTemp - serialIDCount === filterReducer.count * pagBack) {
                bodyCurrent.pag = pagBack
                activeBack = true
            }
        }
        if (!!activeBack) {
            if (filterReducer.total === 1) {
                var bodyInit = {
                    count: filterReducer.count,
                    pag: 1,
                    status: "",
                    textToSearch: "",
                    sort: { "_id": -1 }
                }
                dispatch(draftsListV2(bodyInit))
            }

            dispatch(actionPagination(bodyCurrent.pag))
        } else {
            dispatch(draftsListV2(bodyCurrent))
        }
        dispatch({ type: DELETE_ALL_FROM_LIST_TO_DELETE })
        deleteDrft = true;
    }).catch(async reason => {
        await reasonDispatch(dispatch, reason, false)
    })
    if (deleteDrft) {
        await timeout(2000);
        dispatch({
            type: CHANGE_STATE_MESSAGE_MODAL,
            payload: { type: 'deleteDraft' }
        })
    }
}

export const updateList = (id) => (dispatch) => {
    dispatch({
        type: UPDATE_LIST_TO_DELETE,
        payload: id
    })
}

export const removeFromList = (id) => (dispatch) => {
    dispatch({
        type: DELETE_FROM_LIST_TO_DELETE,
        payload: id
    })
}


export const saveDraftData = (info) => (dispatch) => {
    dispatch({
        type: SAVE_DATA_OF_DRAFT,
        payload: { ...info }
    })
}
export const setDraftSerialID = (id) => (dispatch) => {
    dispatch({
        type: SET_DRAFT_SERIAL_ID,
        payload: id
    })
}

export const deleteDraftRecipients = () => (dispatch) => {
    dispatch({
        type: DELETE_DRAFT_RECIPIENT
    }); 
};

export const deleteDraftTagsAndSpecificFile = () =>  (dispatch) => {
    dispatch({
        type: DELETE_DRAFT_TAGS_AND_SPECIFIC_FILES
    }); 
};



export const saveDraftV2 = (state, fromX) => async (dispatch) => {
    const body = getBody(state)
    const encryptedBody = await encrypt(body);
   
    axios.post(`${API_ENDPOINT + SAVE_DRAFT_V2}`, {data : encryptedBody}, { headers: getHeaders() }).then(async res => {
        const { result } = res.data
        const decryptedData = await decrypt(res.data.result);
        if (state.draftSerialID === '' || state.draftSerialID === null) {
            dispatch({
                type: SET_DRAFT_SERIAL_ID_DRAFTS,
                payload: decryptedData.draft.draftSerialID,
            })
            dispatch({
                type: SET_DRAFT_SERIAL_ID,
                payload: decryptedData.draft.draftSerialID
            })
        }
        return res.status;
    }).catch(async reason => {
        await reasonDispatch(dispatch, reason, false)
    })
}

export const clearDraftData = () => (dispatch) => {
    dispatch({
        type: CLEAR_DATA_OF_DRAFT,
    })
}

export const deleteAllFromList = () => (dispatch) => {
    dispatch({ type: DELETE_ALL_FROM_LIST_TO_DELETE })
}

const getBody = (state) => {
    return state.draftSerialID === '' ? {
        notificationTypeVisualization: state.notificationTypeVisualization,
        requiredSignature: state.requiredSignature,
        requiredConfirmationRead: state.requiredConfirmationRead,
        isPushNotification: state.isPushNotification,
        recipients: !state.fileSerialID && state.recipients,
        subject: state.subject,
        description: state.description,
        attachments: state.attachments,
        listDocumentsSpecific: state.listDocumentsSpecific,
        listTagsChargeMassive: state.listTagsChargeMassive,
        fileSerialID: !!state.fileSerialID ? state.fileSerialID : null,
    } : {
        notificationTypeVisualization: state.notificationTypeVisualization,
        requiredSignature: state.requiredSignature,
        requiredConfirmationRead: state.requiredConfirmationRead,
        isPushNotification: state.isPushNotification,
        recipients: !state.fileSerialID && state.recipients,
        subject: state.subject,
        description: state.description,
        attachments: state.attachments,
        draftSerialID: state.draftSerialID,
        listDocumentsSpecific: state.listDocumentsSpecific,
        listTagsChargeMassive: state.listTagsChargeMassive,
        fileSerialID: !!state.fileSerialID ? state.fileSerialID : null,
    }
}

export const validateHyperlinks = (state) => async (dispatch) => {
    const body = getBody(state)
    const encryptedBody = await encrypt(body);

    await axios.post(`${API_ENDPOINT + SAVE_DRAFT_V2}`, { data: encryptedBody },
        { headers: getHeaders() }).then(async res => {
            const decryptedData = await decrypt(res.data.result);
            if (state.draftSerialID === '') {
                dispatch({
                    type: SET_DRAFT_SERIAL_ID_DRAFTS,
                    payload: decryptedData.draft.draftSerialID,
                })
                dispatch({
                    type: SET_DRAFT_SERIAL_ID,
                    payload: decryptedData.draft.draftSerialID
                })
            }
            return res.status;
        }).catch(async reason => {
            await reasonDispatch(dispatch, reason, false)
        })
}
