import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { activeViewList, inactiveViewGrid } from '../../actions/userDocumentsActions'
import { setTempPag } from '../../actions/filterActions'
import { useSelector } from 'react-redux'
import { ReactComponent as IconList } from '../../assets/icons/userDocuments/File-icon.svg'

const ContainerTypeList = styled.div`
  margin-top: 5px;
  cursor: pointer;
  @media only screen and (min-width: 1024px) and (max-width: 1245px) {
    margin-top: 0px !important;
  }
`
export const ButtonTypeGrid = () => {

    const dispatch = useDispatch()
    const filterReducer = useSelector((state) => state.filterReducer)
    const handleClick = () => {
        dispatch(activeViewList())
        dispatch(inactiveViewGrid())
        dispatch(setTempPag(filterReducer.pag))
    }

    return (
        <ContainerTypeList
            onClick={handleClick}
        >
            <IconList />
        </ContainerTypeList>
    )
}
