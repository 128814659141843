import React, { Component } from 'react'
import { connect } from "react-redux"
import MenuConfig from './MenuConfig'

class Configuration extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div align={"right"}>
        <MenuConfig />
      </div>
    )
  }
}

const mapStateToProps = state => ({})

export default connect(mapStateToProps, {})(Configuration)
