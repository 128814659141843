import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: inline-block;
  margin-bottom:32px;
  border: 1px solid #E35F00;
  background: rgba(227, 95, 0, 0.1);
  border-radius: 5px;
  padding: 5px;
  color:#E35F00;
`

export const AlertLimitFilesMassiveLoad = ({text1='Solo puedes adjuntar hasta 5 archivos, los demás debes marcarlos con el atributo ',text2='“No importar”'}) => {
  return (
    <Container>
        <p>{text1}<strong>{text2}</strong></p>
    </Container>
  )
}
