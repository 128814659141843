import React from 'react'
import styled from 'styled-components'
import {
  Divider as SemanticDivider,
  Checkbox,
  Form
} from 'semantic-ui-react'
import Attachment from './Attachment'
import { useSelector } from 'react-redux'
import Label from "../../../components/Label/MainLabel";


const ContainerFather = styled.div`
  display:flex;
  flex-direction:column;
  position: relative;
`
const Container2 = styled.div`
  position: relative;
  top: 20px;
  display:flex;
  flex-direction:column;
  @media only screen and (max-width: 1200px)  {
    position: relative;
    top:0;
  }
`
const StatusContainer = styled.div`
  display: inline-flex;
  margin-left: 10px;
  margin-top: 0;
  @media only screen and (max-width: 1200px)  {
    margin-left: 15px;
    margin-top: 1rem;
  }
  @media only screen and (max-width: 500px)  {
    display: flex;
    justify-content: space-between;
  }
`
const StatusTextContainer = styled.div`
  display: inline;
`
const StatusTitle = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-right: 5px;
`
const StatusChipContainer = styled.div`
  display: inline;
  padding-left: 5px;
  float: left !important;
`
const SubjectContainer = styled.div`
  display: flex;
  position: relative;
  margin-left: 10px;
  max-width:765px;
  max-heigth:72px;
  @media only screen and (max-width: 1200px)  {
    margin-left: 15px;
    max-width: 645px;
  }
  @media only screen and (max-width: 1800px)  {
    max-width: 635px;
  }
`
const SubjectTitle = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #979E9F;
  margin-right: 5px;
`
const Subject = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;   
  -ms-hyphens: auto;          
  -moz-hyphens: auto;         
  -webkit-hyphens: auto;
  hyphens: auto;
  display: inline-flex;
  @media only screen and (max-width: 1200px)  {
    display: block;
  }
`
const Divider2 = styled(SemanticDivider)`
  margin: 5px 0 5px 0px !important;
  width: 100% !important;
  position: relative;
  display:flex;
  
  @media only screen and (max-width: 1200px)  {
    display: none;
  }
`
const Divider3 = styled(SemanticDivider)`
  display: none;

  @media only screen and (max-width: 1200px)  {  
    margin: 5px 0 5px 8px !important;
    width: 100% !important;
    display: block;
  }
`
const FormCheck = styled(Form)`
  margin-left: 5px;

  @media only screen and (max-width: 1200px)  {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    margin-left: 8px;
  }
`
const CustomCheckbox = styled(Checkbox)`
  cursor: default !important; 
& label, & + label {
    cursor: default !important;
    text-align: left !important;
    letter-spacing: 0 !important;
    color: #000000 !important;
    opacity: 1 !important;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    margin: 5px;
  }
  ui.checkbox .box, .ui.checkbox label {
    cursor: default !important;
    position: relative;
    display: block;
    padding-left: 1.85714em;
    outline: 0;
    font-size: 1em;
    color: red !important;
}

  input:checked ~ .box:after, input:checked ~ label:after {
    color: #FFFFFF !important;
    background: #22C1D0 0 0 no-repeat padding-box !important;
    box-shadow: 0 2px 4px #0000001A !important;
    border-radius: 2px !important;
    border: 2px solid #22C1D0 !important;
    width: 18px;
    height: 18px;
    cursor: default !important; 
 

  }

  & label:before {
    border: 2px solid #133F4F !important;
    border-radius: 2px !important;
  cursor: default !important; 

  }

  input[disabled] ~ label{
    opacity: 0.2 !important;
  }
  .ui.checkbox input.hidden+label {
    cursor: default !important;
}
  padding-right: 10px !important;

  .ui.checkbox .box:after, .ui.checkbox label:after {
    size: 12px
  cursor: default !important; 

}
`
const NotificationContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const Description = styled.div`
  text-align: justify;
  margin-top: 35px;
  margin-left: 8px;
  font-family: Roboto !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #3C3C3B;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 1200px)  {  
    margin-top: 0px;
  }
`
const Attachments = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;

  @media only screen and (max-width: 1200px)  {  
    margin-top: 0px;
  }
`

const NotificationContent = () => {

  const { detailNotification } = useSelector((state) => state.sentReducer)

  const HtmlToReactParser = require('html-to-react').Parser;
  const htmlToReactParser = new HtmlToReactParser();
  const reactElement = (htmlInput) => {
    return htmlToReactParser.parse(htmlInput);
  }

  return (
    <>
      <NotificationContainer>
        <ContainerFather>
          <FormCheck>

            <CustomCheckbox
              label='Con firma de notificación'
              type='checkbox'
              checked={detailNotification.reqSignature}
              id="reqSignature"
              disabled={detailNotification.reqSignature === false ? true : false}
            />
            <CustomCheckbox
              label='Confirmación de lectura '
              type='checkbox'
              checked={detailNotification.reqReadEvent}
              id="reqReadEvent"
              disabled={detailNotification.reqReadEvent === false ? true : false}
            />
            <CustomCheckbox
              label='Notificación push '
              type='checkbox'
              checked={!!detailNotification.reqPushNotification}
              id="reqPushNotification"
              disabled={!!detailNotification.reqPushNotification ? false : true}
            />
          </FormCheck>
          <Container2>
            <StatusContainer>
              <StatusTextContainer>
                <StatusTitle>Estado de la notificación:</StatusTitle>
              </StatusTextContainer>
              <StatusChipContainer>
                {!!detailNotification.currentStatus && !!detailNotification.currentStatus.name ?
                  <Label
                    text={detailNotification.currentStatus.name}
                    icon={detailNotification.currentStatus.id}
                    color={detailNotification.currentStatus.color}
                  /> : ''
                }
              </StatusChipContainer>
            </StatusContainer>
            <div><br /></div>
            <SubjectContainer>
              <SubjectTitle>Asunto:</SubjectTitle>
              {
                detailNotification
                && <Subject>{detailNotification.subject}</Subject>
              }
            </SubjectContainer>
            <Divider3 />
            <Divider2 />
          </Container2>
        </ContainerFather>
        {
          detailNotification
          && <Description>
            {reactElement(`${detailNotification.description}`)}
          </Description>
        }
        {
          detailNotification
          && <Attachment />
        }
      </NotificationContainer>
    </>
  )
}

export default NotificationContent
