import {
  STATE_FILTER_SEARCH_BAR,
  STATE_FILTER_POPUP_FILTER,
  STATE_FILTER_POPUP_ORDER,
  STATE_FILTER_PAGINACION,
  STATE_FILTER_ADVANCED_SEARCH_DATE,
  STATE_FILTER_ADVANCED_SEARCH,
  STATE_STARRED_FILTER,
  STATE_TAB_FILTER,
  STATE_CLEAR_FILTER,
  STATE_INITIAL_PAGINATION,
  ACTIVE_TABLE_MIX,
  INACTIVE_TABLE_MIX,
  STATE_FILTER_POPUP_FILTER_AGREEMENTS,
  STATE_FILTER_POPUP_FILTER_IMPROPER_CLIENTS,
  STATE_FILTER_POPUP_FILTER_DRAFTS,
  STATE_FILTER_POPUP_FILTER_REPORTS,
  STATE_FILTER_POPUP_FILTER_CLIENTS_AND_PROVIDERS,
  STATE_INITIAL_FILTER,
  ACTIVE_PAGINATION,
  STATE_FILTER_MASSIVE_DETAIL,
  STATE_TEMP_PAG,
  STATE_FILTER_REQUIRED_SIGNATURE,
} from "./actionsUtilities/types.js"

export const actionSearchBar = (text, cancelFlag, state) => (dispatch) => {
  dispatch({
    type: STATE_FILTER_SEARCH_BAR,
    payload: [
      text
    ],
    cancelFlag: cancelFlag
  })
}

export const actionFilter = (filter) => (dispatch) => {
  dispatch({
    type: STATE_FILTER_POPUP_FILTER,
    payload: filter
  })
}

export const actionFilterDraft = (status) => (dispatch) => {
  dispatch({
    type: STATE_FILTER_POPUP_FILTER_DRAFTS,
    payload: status
  })
}

export const actionFilterAgreements = (optionSelected) => (dispatch) => {
  dispatch({
    type: STATE_FILTER_POPUP_FILTER_AGREEMENTS,
    payload: optionSelected
  })
}

export const actionFilterImproperClients = (optionSelected) => (dispatch) => {
  
  dispatch({
    type: STATE_FILTER_POPUP_FILTER_IMPROPER_CLIENTS,
    payload: optionSelected
  })
  
}

export const actionFilterReports = (optionSelected) => (dispatch) => {
  dispatch({
    type: STATE_FILTER_POPUP_FILTER_REPORTS,
    payload: optionSelected
  })
}

export const actionFilterClientsAndProviders = (optionSelected) => (dispatch) => {
  dispatch({
    type: STATE_FILTER_POPUP_FILTER_CLIENTS_AND_PROVIDERS,
    payload: optionSelected
  })
}

export const actionOrder = (order) => (dispatch) => {
  dispatch({
    type: STATE_FILTER_POPUP_ORDER,
    payload: order
  })
}

export const actionPagination = (pag) => (dispatch) => {
  dispatch({
    type: STATE_FILTER_PAGINACION,
    payload: pag
  })
}

export const actionSearchAdvancedDate = (date) => (dispatch) => {
  dispatch({
    type: STATE_FILTER_ADVANCED_SEARCH_DATE,
    payload: date
  })
}

export const actionSearchAdvanced = (state) => (dispatch) => {
  dispatch({
    type: STATE_FILTER_ADVANCED_SEARCH,
    payload: state
  })
}

export const actionStarredFilter = (state) => (dispatch) => {
  dispatch({
    type: STATE_STARRED_FILTER,
    payload: state
  })
}

export const actionUpdateTabFilter = (state) => (dispatch) => {
  dispatch({
    type: STATE_TAB_FILTER,
    payload: state
  })
}

export const actionClearFilter = (state) => (dispatch) => {
  dispatch({
    type: STATE_CLEAR_FILTER,
    payload: state
  })
}

export const actionSetInitialState = (state) => (dispatch) => {
  dispatch({
    type: STATE_INITIAL_FILTER,
    payload: state
  })
}

export const actionInitialPagination = (state) => (dispatch) => {
  dispatch({
    type: STATE_INITIAL_PAGINATION,
    payload: state
  })
}

export const activeViewMix = () => async (dispatch) => {
  dispatch({
    type: ACTIVE_TABLE_MIX,
  })
}

export const inactiveViewMix = () => async (dispatch) => {
  dispatch({
    type: INACTIVE_TABLE_MIX,
  })
}

export const activePagination = (state) => (dispatch) => {
  dispatch({
    type: ACTIVE_PAGINATION,
    payload: state
  })
}

export const actionMassiveDetailFilter = () => (dispatch) =>{
  dispatch({type: STATE_FILTER_MASSIVE_DETAIL})
}

export const setTempPag = (pag) => (dispatch) => {
  dispatch({
    type: STATE_TEMP_PAG,
    payload: pag
  })
}

export const setrequiredSignatureAttatchments = (signature) => (dispatch) => {
  dispatch({
    type: STATE_FILTER_REQUIRED_SIGNATURE,
    payload: signature
  })
}