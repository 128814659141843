import React, {Component} from 'react'
import PreviewMessage from './PreviewMessage'
import PreviewNotification from './PreviewNotification'
import PreviewList from './PreviewList'
import './preview.css'


export class Preview extends Component {

  showPreview = () => {
    const {activePreview} = this.props
    const previews = {
      'previewMessage': <PreviewMessage/>,
      'previewList': <PreviewList/>,
      'previewNotification': <PreviewNotification/>,
      'default': <PreviewMessage/>
    }
    return !!previews[activePreview] ? previews[activePreview] : previews['default']
  }

  render() {
    return (
      <>
      <div className='responsive'>
        <div className="ContainerTypeViewRes">
                    {this.showPreview()}
                  <div>
                    <div className={this.props.classViewArrow}>
                      {this.props.leftArrowPreview}
                      {this.props.rightArrowPreview}
                    </div>
                  </div>
        </div>
         
      </div>
      </>
    )
  }
}

export default Preview
