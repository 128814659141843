import {
  CHANGE_STYLES_MENU, LOGOUT,
  STATE_INITIAL_FILTER,
  ACTIVE_PAGINATION,
  STATE_TEMP_PAG,
  CHANGE_SET_PATHNAME_CURRENT,
} from '../actions/actionsUtilities/types.js'

const initialState = {
  menuStyles: false,
  stateInitial: true,
  activePagination: true,
  tempPag: 1,
  pathnameCurrent:'/dashboard'
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT:
      return initialState
    case CHANGE_STYLES_MENU:
      return {
        ...state,
        menuStyles: action.payload
      }
    case STATE_INITIAL_FILTER:
      return {
        ...state,
        stateInitial: action.payload
      }
    case ACTIVE_PAGINATION:
      return {
        ...state,
        activePagination: action.payload
      }
    case STATE_TEMP_PAG:
      return {
        ...state,
        tempPag: action.payload
      }
    case CHANGE_SET_PATHNAME_CURRENT:
      return {
        ...state,
        pathnameCurrent: action.payload
      }
    default:
      return state
  }
}
