import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Label } from 'semantic-ui-react'
import { NumericFormat } from 'react-number-format'
import { ReactComponent as NotFoundAgreements } from '../../assets/icons/dashboard/notFoundAgreements.svg'

const Divider = styled.div`
align-items: center;
display: flex;
width; 100%;
justify-content: center;
`
const DividerList = styled.div`
align-items: center;
`
const CustomLabel = styled(Label)`
display: flex !important;
justify-content: flex-start;
flex-direction: column;
width: 100%;

box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.15);
border-radius: 4px;
align-items: center;
@media only screen and  (min-height: 900px) {
    height: 201px;
}
@media only screen and  (max-height: 900px) {
    height: 130px;
}
`
const CustomLabelNotAgremments = styled(Label)`
display: flex !important;
justify-content: center;
flex-direction: column;
width: 100%;
min-height: 340px;
max-width: 901px;
margin-top: 0px !important;
margin-left: 0px !important;
box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.15);
border-radius: 4px;
align-items: center;
@media only screen and  (min-height: 1024px) {
    margin-left: 0px !important;
}
`
const CustomNumber = styled.p`
font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 48px;
color: #22C1D0;
line-height: 0.8;
margin: 0px;
@media only screen and (max-width: 700px) {
    font-size: 30px;
}
@media only screen and (max-width: 400px) {
    font-size: 20px;
}
`
const CustomNumberPublicClient = styled.p`
font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 48px;
color: #22C1D0;
line-height: 0.8;
margin: 0px;
@media only screen and (max-width: 900px) {
    font-size: 35px;
}
@media only screen and (max-width: 500px) {
    font-size: 25px;
}
`
const CustomDate = styled.p`
font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 48px;
color: #22C1D0;
line-height: 0.8;
margin: 0px;
@media only screen and (max-width: 900px) {
    font-size: 35px;
}
@media only screen and (max-width: 500px) {
    font-size: 25px;
}
`
const CustomNumberSmall = styled.p`
padding-top:10px;
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 20px;
text-align: left;
color: #22C1D0;
@media only screen and (max-width: 700px) {
    font-size: 16px;
}
`
const CustomPSmall = styled.p`
width: 100%;
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 14px;
text-align: center;
color: #22C1D0;
margin: 0px;
@media only screen and (max-width: 700px) {
    font-size: 12px;
}
`
const CustomP = styled.p`
font-family: 'Roboto';
font-style: normal;
font-weight: 600;
font-size: 18px;
text-align: center;
color: #133F4F;
margin: 9px 0px 37px 0px !important; 
@media only screen and (max-width: 700px) {
    margin: 0 0 10px !important;
}
`
const DivMessage = styled.div`
  width: 48.3%;
  height: 100%;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 768px){
    width: 80%;
  }
`

const Message = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: #133F4F;
  margin-bottom: 15px;
  padding-top: 15px;
`

export const DashBoardLabelClient = ({ text, number, totalNotifications, total = false, sent = false, maxW}) => {
    return (
        <CustomLabel style={{maxWidth: `${maxW && '410px'}`, height: `${maxW && '231.58px'}`, backgroundColor: '#FFFFFF', 
           }}>
           <CustomP style={{ backgroundColor: '#FFFFFF'}}>{text}</CustomP>
            {sent ? <Divider>
                <CustomNumber >
                    <NumericFormat
                        thousandSeparator="."
                        decimalSeparator=","
                        displayType="text"
                        value={number}
                    />
                </CustomNumber>
                <CustomNumberSmall>/{totalNotifications === 0 ? 'ilimitadas' : <NumericFormat
                    thousandSeparator="."
                    decimalSeparator=","
                    displayType="text"
                    value={totalNotifications}
                />}</CustomNumberSmall>
            </Divider>
                : null}
            {total ?
                <DividerList>
                    <CustomNumber style={{ textAlign: 'center' }}>
                        {number === 0 ? 'ilimitadas' : <NumericFormat
                            thousandSeparator="."
                            decimalSeparator=","
                            displayType="text"
                            value={number}
                        />}
                    </CustomNumber>
                </DividerList>
                : null}
        </CustomLabel>
    )
}
export const DashBoardLabel = ({ text, sentNotifications }) => {
    return (
        <CustomLabel align="center" style={{ backgroundColor: '#FFFFFF', textAlign: 'right' }}>
            <CustomP style={{ backgroundColor: '#FFFFFF' }}>{text}</CustomP>
            <DividerList>
                <CustomNumberPublicClient style={{ textAlign: 'center' }}>
                    <NumericFormat
                        thousandSeparator="."
                        decimalSeparator=","
                        displayType="text"
                        value={sentNotifications}
                    />
                </CustomNumberPublicClient>
            </DividerList>
        </CustomLabel>
    )
}
export const DashBoardLabelDateClient = ({ text, date, }) => {
    return (
        <CustomLabel align="center" style={{ backgroundColor: '#FFFFFF', textAlign: 'right' }}>
            <CustomP style={{ backgroundColor: '#FFFFFF' }}>{text}</CustomP>
            <Divider>
                <CustomDate >
                    {date}
                </CustomDate>
            </Divider>
        </CustomLabel>
    )
}

export const DashBoardLabelNotFoundAgremments = () => {
    return (
        <CustomLabelNotAgremments align="center" style={{ backgroundColor: '#FFFFFF', textAlign: 'right' }}>
            <DivMessage>
                <NotFoundAgreements />
                <Message>Actualmente no cuentas con una vinculación en curso para enviar notificaciones</Message>
                <div style={{ color: '#000000',fontSize: '16px',fontWeight: '500' }}>Comunícate con tu administrador</div>
            </DivMessage>
        </CustomLabelNotAgremments>
    )
}
DashBoardLabelClient.propTypes = {
    text: PropTypes.string.isRequired,
}

export default DashBoardLabelClient
