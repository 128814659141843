import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SimpleReactValidator from 'simple-react-validator'
import { Form, Message } from 'semantic-ui-react'
import styled from 'styled-components'

import { resetPassword } from '../../actions/authActions'
import { cleanErrorsAction } from '../../actions/errorsActions'
import { reactValidatorOptions } from '../../helpers/simpleReactValidator'
import ImageSegment from '../../components/Sengment/ImageSegment'
import PrimaryButton from '../../components/PrimaryButton'
import { withRouter } from 'react-router-dom'
import InputFormMaterial from '../../components/InputForm/InputFormMaterial'
import ModalGeneral from '../../components/ModalLogin/ModalGeneral'

const CustomButton = styled(PrimaryButton)`
  background: #FFFFFF !important;
  border-radius: 4px !important;
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  text-align: center !important;
  border: 1px solid #22C1D0 !important;
  color: #22C1D0 !important;
  padding: 16px 32px !important;;
  width: 141px !important;
  margin-right: 28px !important; 
  cursor: pointer;
`
const CustomPrimaryButton = styled(PrimaryButton)`
  background: #22C1D0 !important;
  border-radius: 4px !important;
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  text-align: center !important;
  color: #FFFFFF !important;
  padding: 16px 32px !important;;
  width: 141px !important;
  margin-left: 28px !important; 
`
const LoginForm = styled.div`
  height: 80%;
`
const CustomForm = styled(Form)`
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  & > * {
    margin: 10px 0 !important;
  }
`
const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #133F4F;
`
const Text = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #000000;
  margin: 35px 8px 0 8px; 
`
const MessageLogin = styled(Message)`
  width: 100% !important;
  text-align: center !important;
  letter-spacing: 0 !important;
  background: rgba(186, 53, 85, 0.1) !important;
  border: 1px solid #BA3555 !important;
  box-sizing: border-box !important;
  border-radius: 4px;
  color: #BA3555 !important;
  margin: 10px !important;
`
export class ForgotPassword extends Component {

  constructor(props) {
    super(props)
    this.state = {
      email: '',
      errors: {
        error: '',
        message: '',
        email: 'El usuario no esta registrado'
      }
    }
    this.loginValidator = new SimpleReactValidator(reactValidatorOptions)
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value })
  }

  handleBack = () => {
    this.props.history.push('/login-user')
  } 

  setEmail = async (e) => {
    e.preventDefault()
    if (this.loginValidator.allValid()) {
      const { email } = this.state
      const body = {
        "email":  email
      }
      await this.props.resetPassword(body)
      if (this.props.errorReducer.showError && this.props.errorReducer.errors.result.email) {
      } else {
      }
    } else {
      this.loginValidator.showMessages()
      this.forceUpdate()
    }
  }

  render() {
    return (
      <>
      <ImageSegment>
        <LoginForm>
          <Title>Olvidé mi contraseña</Title>
          <Text>Ingresa tu email y recibirás un correo con las instrucciones para restablecer tu contraseña</Text>
          <CustomForm className='ui form' onSubmit={this.setLogin}>
            <InputFormMaterial
                id={'email'}
                label={'Email'}
                type={'text'}
                onChange={this.onChange}
                value={this.state.email}
                validator={this.loginValidator}
                validateOptions={'required'}
                autocomplete="email"
              />
            <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '20px' }}>
              <CustomButton
                fluid={true}
                txtBtn='Cancelar'
                onPress={this.handleBack}
              />
              <CustomPrimaryButton
                fluid={true}
                txtBtn='Enviar'
                onPress={this.setEmail}
              />
            </div>
            {this.props.errorReducer.showError && <MessageLogin content={this.state.errors} />}
          </CustomForm>
        </LoginForm>
      </ImageSegment>
      <ModalGeneral type='sendEmail'/>
      </>
    )
  }
}

ForgotPassword.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  cleanErrorsAction: PropTypes.func,
}

const mapStateToProps = (state) => ({
  errorReducer: state.errorReducer
})

const mapDispatchToProps = {
  resetPassword,
  cleanErrorsAction
}


const ShowTheLocationWithRouter = withRouter(ForgotPassword)
export default connect(mapStateToProps, mapDispatchToProps)(ShowTheLocationWithRouter)
