import axios from 'axios'
import { DASHBOARD_LIST_AGREEMENTS, GET_DASHBOARD_AGREEMENT_INFO, GET_DASHBOARD_INFO, HAS_AGREEMENTS, SHOW_AGREEMENT_INFO, RESET_DASHBOARD, CLEAR_DASHBOARD_SEARCH} from './actionsUtilities/types.js'
import {API_ENDPOINT, API_ENDPOINT_PROVIDER_DEV} from "../config/config"
import {GET_DASHBOARD_INFO_ROUTE, GET_DASHBOARD_INFO_ROUTE_V2, GET_DASHBOARD_LIST_AGREEMENTS} from './actionsUtilities/actionsRoutes.js'
import { reasonDispatch } from './DispatchGeneric.js'
import { decrypt, encrypt } from '../helpers/cypher.js'
import { getHeaders } from '../helpers/globalHelpers.js'


export const getDashboardInfoV2 = () => async (dispatch) => {
  const headers = getHeaders();
  await axios.post(
    `${API_ENDPOINT+GET_DASHBOARD_INFO_ROUTE_V2}`, null,
    { headers }
  ).then(async res => {
    const decryptedData = await decrypt(res.data.result);
    dispatch({
      type: GET_DASHBOARD_INFO,
      payload: decryptedData
    })
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
  })
}

export const getDashboardAgreementInfo = (body) => async (dispatch) => {
  const headers = getHeaders();
  const encryptedBody = await encrypt(body);
  await axios.post(
    `${API_ENDPOINT+GET_DASHBOARD_INFO_ROUTE_V2}`, 
    {data: encryptedBody },
    { headers }
  ).then(async res => {
    const decryptedData = await decrypt(res.data.result);
    dispatch({
      type: GET_DASHBOARD_AGREEMENT_INFO,
      payload: decryptedData.dataAgreement
    })
    dispatch({
      type: SHOW_AGREEMENT_INFO,
      payload: true
    })
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
  })
}

export const getDashboardAgrements = (body) => async (dispatch) => {
  const headers = getHeaders();
  const encryptedBody = await encrypt(body);
  await axios.post(
    `${API_ENDPOINT+GET_DASHBOARD_LIST_AGREEMENTS}`,
    {data: encryptedBody },
    { headers }
  ).then(async res => {
    const decryptedData = await decrypt(res.data.result);
    dispatch({
      type: DASHBOARD_LIST_AGREEMENTS,
      payload: decryptedData.dataAgreement
    })
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
  })
}

export const haveAgreementsAction = (value) => async (dispatch) => {
  dispatch({
    type: HAS_AGREEMENTS,
    payload: value
  })

}

export const refreshDashboard = () => async (dispatch) => {
  dispatch({
    type: RESET_DASHBOARD
  })
}
export const clearDashboardSearch = () => async (dispatch) => {
  dispatch({
    type: CLEAR_DASHBOARD_SEARCH
  })
}