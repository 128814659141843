import React from "react";
import styled from "styled-components";
import { ReactComponent as LeftArrowIcon } from "../../../assets/icons/sent/leftArrow.svg";
import { Checkbox, Form } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { clearReportMassiveData } from "../../../actions/seeNotificationAction";
import MainLabelWithQuantity from "../../../components/Label/MainLabelWithQuantity";

const Divider = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
`;
const Container = styled.div`
  margin: 30px 30px 10px 30px !important;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  @media only screen and (max-height: 900px) {
    margin: 10px 30px 10px 30px !important;
  }
  @media only screen and (max-width: 600px) {
    margin: 3% !important;
  }
`;
const Arrow = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
`;
const Title = styled.p`
  color: #133f4f;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
`;
const LeftArrow = styled.button`
  padding: 0;
  margin: 0 16px 0 0;
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor: pointer;
  @media only screen and (max-width: 600px)  {
    8px
  }
`;
const DateTitle = styled.p`
  color: #000000;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-right: 40px;
  @media only screen and (max-width: 768px) {
    margin-inline: 20px;
    margin-bottom:10px !important;
  }
`;
const Divider2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: start;
  margin: 1.75%;
  gap:  16px;
  flex-wrap:wrap;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    margin-left:unset;
    margin:7px;
  }
`;
const Divider3 = styled.div`
  width: 332px;
  min-height: 144px;
  background: rgba(227, 95, 0, 0.05);
  outline: 1px solid #e35f00;
  border-radius: 4px;
  padding: 16px;
  padding-inline: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:center;
  @media only screen and (max-width: 820px) {
    width: 48%;
  }
  @media only screen and (max-height: 750px) {
    min-height: 50px;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    padding:1%;
    min-height: unset;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;
const Divider4 = styled.div`

  width: 45%;
  min-height: 144px;
  border-radius: 4px;
  outline: 1px solid var(--CD-GRIS-A, #979E9F);
  background: #FFF; 
  padding: 16px;
  display: flex;
  flex-direction: column;
  @media only screen and (max-height: 950px) {
    min-height: 100px;
  }
  @media only screen and (max-height: 750px) {
    min-height: 50px;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    padding:16px;
    min-height: unset;
    display: flex;
    flex-direction: column;
  }
`;
const Divider5 = styled.div`
  display: flex;
  flexDirection: row; 
  gap: 0 16px; 
  flex-wrap: wrap; 
  @media only screen and (max-width: 1715px) {
    flex-wrap: wrap; 
  }
`;
const Divider6 = styled.div`
  display: flex;
  flex-direction: column; 
  gap: 2%; 
  @media only screen and (max-width: 1715px) {
    // flex-direction: row; 
    // flex-wrap:wrap;
  }
`;
const TitleContent2 = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #133F4F;
  padding: 0px 0px 0px 0px;
  margin-bottom:0px;
  @media only screen and (max-width: 768px) {
    text-align:left;
    display:flex;
    align-self: center;
  }
`;
const TitleContent = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #e35f00;
  white-space: nowrap;
  @media only screen and (max-width: 768px) {
    text-align:left;
  }
`;
const TextContent = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #e35f00;
  @media only screen and (max-width: 768px) {
    text-align:left;
  }
`;
const ButtonContent = styled.button`
  width: 195px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #22c1d0;
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #22c1d0;
  cursor: pointer;
  margin: 8px 0 0 0;
  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    margin:0;
    padding-block: 8px;
  }
`;
const FormCheck = styled(Form)`
  margin-left: 25px;
  @media only screen and (max-width: 1200px) {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    margin-left: 25px;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
   margin-left: 1%;
 
  }
`;
const CustomCheckbox = styled(Checkbox)`
  & label,
  & + label {
    text-align: left !important;
    letter-spacing: 0 !important;
    color: #000000 !important;
    opacity: 1 !important;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    margin: 5px;
    cursor: default !important;
  }
  
  input:checked ~ .box:after, input:checked ~ label:after {
    color: #FFFFFF !important;
    background: #22C1D0 0 0 no-repeat padding-box !important;
    box-shadow: 0 2px 4px #0000001A !important;
    border-radius: 2px !important;
    border: 2px solid #22c1d0 !important;
    width: 18px;
    height: 18px;
    cursor: default !important;
  }

  & label:before {
    color: #133f4f !important;
    border: 2px solid #133f4f !important;
    border-radius: 2px !important;
  }

  input[disabled] ~ label {
    opacity: 0.3 !important;
    color: #000000 !important;
  }

  padding-right: 10px !important;

  .ui.checkbox .box:after,
  .ui.checkbox label:after {
    size: 12px;
  }
`;

const HearderMasive = ({ nameCampaing, dateCampaing, bouncedCount, isConfirmationRead, isSignNotification, isPushNotification, statusCount }) => {
  const { INITIALIZED = 0, FINALIZED = 0, UNREAD = 0, READ = 0, PENDING = 0, REJECTED = 0, 
    SIGNED = 0, BOUNCED = 0, RELEASE = 0, ERROR = 0 } = statusCount
  const formatDate = moment(dateCampaing).format("DD MMM YYYY, h:mm a");
  const history = useHistory();
  const dispatch = useDispatch()
  const closeView = () => {
    history.goBack();
    setTimeout(() => {
      dispatch(clearReportMassiveData())
    }, 200)
  };
  const citizenReportData = useSelector(
    (state) => state.sentReducer.citizenReportData
  );

  const urlCitizenErrorFileName = citizenReportData.urlCitizenError ? citizenReportData.urlCitizenError.split('/').pop() : '';
  const urlBouncedNotificationsFileName = citizenReportData.urlBouncedNotifications ? citizenReportData.urlBouncedNotifications.split('/').pop() : '';

  return (
    <>
      <Divider>
        <Container>
          <Arrow>
            <LeftArrow onClick={closeView}>
              <LeftArrowIcon />
            </LeftArrow>
          </Arrow>
          <Title>Envío masivo - {nameCampaing} </Title>
        </Container>
        <DateTitle> {formatDate}</DateTitle>
      </Divider>
      <Divider2>
        <Divider3>
          <div>
            <TitleContent>Ciudadanos no cargados</TitleContent>
            <TextContent>
              {!!citizenReportData.totalCitizenVerifiedWithError && citizenReportData.totalCitizenVerifiedWithError !== 0 ?
                '(' + citizenReportData.totalCitizenVerifiedWithError + ' de ' + citizenReportData.totalCitizen + ')'
                :
                '(0)'
              }
            </TextContent>
          </div>
          {(!!citizenReportData.totalCitizenVerifiedWithError && citizenReportData.totalCitizenVerifiedWithError !== 0) && (
            <a href={citizenReportData.base64CitizenError} download={urlCitizenErrorFileName}>
              <ButtonContent>Descargar archivo</ButtonContent>
            </a>
          )}
        </Divider3>
        <Divider3 >
          <div>
            <TitleContent>Notificaciones con reportes erróneos</TitleContent>
            <TextContent>
              {!!citizenReportData.totalBouncedNotificationsLogs ? '(' + citizenReportData.totalBouncedNotificationsLogs + ')' : '(0)'}
            </TextContent>
          </div>
          {(!!citizenReportData.totalBouncedNotificationsLogs && citizenReportData.totalBouncedNotificationsLogs !== 0) && (
            <a href={citizenReportData.base64LogsBouncedNotifications} download={urlBouncedNotificationsFileName}>
              <ButtonContent>Descargar archivo</ButtonContent>
            </a>
          )}
        </Divider3>
        <Divider4>
            <TitleContent2 >Notificaciones por estado</TitleContent2>
            <Divider6 >       
              <Divider5 >
                <MainLabelWithQuantity iconMargin={'-2px'} icon={1} quantity={UNREAD} />
                <MainLabelWithQuantity iconMargin={'-5px'} icon={2} quantity={READ} />
                <MainLabelWithQuantity icon={5} quantity={FINALIZED} />
                <MainLabelWithQuantity icon={3} quantity={PENDING} />
                <MainLabelWithQuantity icon={4} quantity={REJECTED} />
                <MainLabelWithQuantity icon={9} quantity={RELEASE} />
                <MainLabelWithQuantity iconMargin={'-2px'} icon={8} quantity={ERROR} />
              </Divider5>
            </Divider6>
        </Divider4>
      </Divider2>
      <FormCheck>
        <CustomCheckbox
          label="Con firma de notificación"
          type="checkbox"
          checked={isSignNotification}
          id="reqSignature"
          disabled={!isSignNotification}
        />
        <CustomCheckbox
          label="Confirmación de lectura "
          type="checkbox"
          checked={isConfirmationRead}
          id="reqReadEvent"
          disabled={!isConfirmationRead}
        />
        <CustomCheckbox
          label="Notificación push "
          type="checkbox"
          checked={isPushNotification}
          id="reqPushNotification"
          disabled={!isPushNotification}
        />
      </FormCheck>
    </>
  );
};

export default HearderMasive;
