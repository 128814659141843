import React from 'react'
import PropTypes from 'prop-types'
import ContainerSearchAgreementsSuperAdmin from '../../../components/Search/SearchAgreements/superAdminAgreements/ContainerSearchAgreementsSuperAdmin'
import AllAgreements from "./allAgreements/AllAgreementsSuperAdmin";
import { useSelector } from 'react-redux';

const OperatorAgreements = (props) => {

  const agreements = useSelector(state => state.agreementReducer.agreementListArray)

  return (
    <>
      <ContainerSearchAgreementsSuperAdmin 
        showAZ={true}
        reportType={"LIST_AGREEMENTS"} 
        providerType={"AGREEMENT"} 
        disabledExportButton={!agreements.length > 0}
      />
      <AllAgreements
        history={props.history}
      />
      
    </>
  )
}

OperatorAgreements.propTypes = {
  history: PropTypes.any.isRequired,
}
export default OperatorAgreements
