import React, { useEffect, useState } from 'react'
import { Table } from 'semantic-ui-react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Label from '../../../../components/Label/MainLabel'
import moment from 'moment/moment'
import { Cell, CellDownload, CellIconAttachment, CellIconSelect } from '../../../../components/Table/CellComponent'
import { useDispatch, useSelector } from 'react-redux'
import { downloadMassiveAttachments } from '../../../../actions/seeNotificationAction'
import { UseWindowDimensions } from '../../../../utils/UseWindowsDimentions'
import { CheckBoxGeneric } from '../../../../components/CheckboxForm/CheckBoxGeneric'
import { ReactComponent as Attached } from '../../../../assets/icons/sent/attached.svg'
import { ReactComponent as Download } from '../../../../assets/icons/download-attachment.svg'

const Row = styled(Table.Row)`
  cursor: default;
  @media only screen and (max-width: 768px) {
    display:flex!important;
    flex-direction: row !important;
  }
`

const SentCellIcon = styled(Table.Cell)`
  display: flex;
  width: 100% !important;
  height:52.44px !important;
  .ui.basic.label{
    min-width: 75.5px;
  }
  @media only screen and (max-width: 1024px) {
    height: 53.35px  !important;
  }
`
const StatusContainer = styled.div`
  float: left!important;
`
const SentCellResponsive = styled(Table.Cell)`
  width: 100%;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  display block;
  border-top: 0px solid rgba(34,36,38,.1) !important;
  padding: 0.25em 0em!important;
  heigth:52.44px !important;
  @media only screen and (max-width: 1024px) {
    .ui.basic.label{
      min-width: 18px;
      padding-bottom: 0px !important;
      height: 53.44px  !important;
    }
  }
  @media only screen and (max-width: 768px) {
    width: 100% !important;
    line-height: 18px;
    .ui.basic.label{
      padding-bottom: 0px !important;
      height: 28px !important;
      max-height: 20px;
    }
  }
`
const Divider0 = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  `

const Divider1 = styled.div`
  display: flex;
  flex-direction: column;
  width: 10%;
  padding: 0 0 0.5rem 1rem !important;
  `

const Divider2 = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 1rem;
  padding-left:${props => props.haveAttachments ? '0.5rem' : '2%'};
  width: ${props => props.haveAttachments ? '90%' : '100%'};
`;

const Divider3 = styled.div`
  display: flex;
  flex-direction: row;
  color:#000;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  padding-bottom:0.5rem;
`;

const Divider4 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between
 
`;

const SentTitle = styled.p`
  color:#133F4F;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin-right: 0.5rem
  `

export const RowTableSentMasive = (props) => {

  const formatDate = moment(props.data.lastModification).format('DD/MM/YYYY')
  const handleClick = () => {
    props.history.push({ pathname: '/sent-notification-detail/' + props.data.notificationID })
  }
  const [selectDocument, setSelectDocument] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const { haveAttachments } = useSelector(state => state.sentReducer.detailMassiveNotification)
  const dispatch = useDispatch()

  useEffect(() => {
    setSelectDocument(props.activeAll)
  }, [props.activeAll])

  useEffect(() => {
    setSelectDocument(false)
  }, [props.data])

  const handleSelectDocument = (e) => {
    e.stopPropagation()
    setSelectDocument(!selectDocument)
    if (!selectDocument) {
      props.addCheckedRow(props.data.notificationID)
    } else {
      props.removeCheckedRow(props.data.notificationID)
    }
  }
  const handleClickDownload = (e) => {
    e.stopPropagation()
    dispatch(downloadMassiveAttachments({ notificationSerialIDList: [props.data.notificationID] }))
  }

  const windowDimensions = UseWindowDimensions();

  return (
    windowDimensions.width > 768 ?
      <Row key={props.data.notificationID} onClick={handleClick} onMouseEnter={() => setIsVisible(true)} onMouseLeave={() => setIsVisible(false)}>
        {haveAttachments ? <CellIconSelect width={1} onChange={handleSelectDocument} checked={selectDocument} /> : <Cell width={1} ></Cell>}
        <Cell width={3} columnName={props.headerTable[1].name} text={props.data.nameRecipient}></Cell>
        <CellIconAttachment width={2} haveAttachments={haveAttachments} />
        <Cell width={4} columnName={props.headerTable[3].name} text={formatDate}></Cell>
        <SentCellIcon width={3} textAlign='center'>
          <StatusContainer>
            {props.data.recipient !== null ?
              <Label style={{ minWidth: '75px' }} text={props.data.currentStatus.name} icon={props.data.currentStatus.id} color={props.data.currentStatus.color} />
              : ''}
          </StatusContainer>
        </SentCellIcon>

        <CellDownload haveAttachments={haveAttachments} width={3} onChange={handleSelectDocument} checked={selectDocument}
          isVisible={isVisible && !selectDocument && haveAttachments} handleClickDownload={handleClickDownload} />
      </Row>
      :
      <Row onClick={handleClick}>
        <Divider0>
          {haveAttachments && <Divider1>
            <CheckBoxGeneric onChange={handleSelectDocument} checked={selectDocument} />
          </Divider1>}
          <Divider2 haveAttachments={haveAttachments}>
            <Divider4>
              <Divider3><SentTitle>No. documento:</SentTitle> <p>{props.data.nameRecipient}</p></Divider3>
              {haveAttachments ? <Attached /> : <div></div>}
            </Divider4>
            <Divider3><SentTitle>Última modificación</SentTitle> <p>{formatDate}</p></Divider3>
            <Divider4>
              {props.data.recipient !== null ?
                <Label style={{ minWidth: '75px' }} text={props.data.currentStatus.name} icon={props.data.currentStatus.id} color={props.data.currentStatus.color} />
                : ''
              }

              {!selectDocument && haveAttachments && <div onClick={handleClickDownload} style={{ display: 'flex', cursor: 'pointer', fontSize: '14px' }}>
                <Download style={{ margin: '0px 6px 0px 0px' }} />
                <div style={{ color: '#22C1D0' }}>Descargar adjuntos</div>
              </div>}
            </Divider4>
          </Divider2>
        </Divider0>
      </Row>
  )
}

RowTableSentMasive.propTypes = {
  data: PropTypes.object.isRequired
};

export default RowTableSentMasive