import React, { Component } from 'react'
import { SearchBarGenericSentMassiveDetail } from './SearchBarGeneric'

class  ContainerSearchMassive extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchBarState: "",
      popUpFilterState: "",
      popUpOrderState: "",
    };
  }
  render() {
    return (
      <SearchBarGenericSentMassiveDetail searchBarState={this.searchBarState} popUpOrderState={this.popUpOrderState}
      popUpFilterState={this.popUpFilterState} showAdvancedSearch={this.props.showAdvancedSearch} textPlaceHolder={'Número de identificación del destinatario'} showAZ={false} filterSmall={this.props.filterSmall}/>
    )
  }
}

export default ContainerSearchMassive
