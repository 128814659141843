import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Modal } from 'semantic-ui-react'
import styled from 'styled-components'

import { closeWarningModal, warningFormModal } from '../../../actions/modalActions'
import { ReactComponent as LeaveIcon } from '../../../assets/icons/leaveIcon.svg'
import '../../Modal/messageModal.css'
import PropTypes from "prop-types"
import { getUsers, inactiveFormUser, inactiveFormUserEdit, putStateActive, putStateInactive } from '../../../actions/usersActions'
import { deleteDraft, deleteDraftListV2 } from '../../../actions/draftsActions'
import { getProvidersV2, putStateActiveProvider, putStateInactiveProvider } from '../../../actions/providerActions'
import { getClients, putStateActiveClient, putStateInactiveClient, getEmployees } from '../../../actions/clientActions'

const ModalElement = styled(Modal)`
  padding: 40px !important;
  width: 496px !important;
`
const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const ModalMessage = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin-top: 32px;
`
const ModalActions = styled.div`
  margin-top: 32px;
`
const Texto = styled.p`
  color: #133F4F;
  font-family: Roboto;
  font-weight: 700;
  font-size: 20px;
  font-style: normal;
  line-height: 23px;
  text-align: center;
  margin-bottom: 15px !important;
`
const Button = styled.button`
  background: #FFFFFF;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  color: #22C1D0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  width: 71px;
  height: 42px;
  margin-Right: 16px;
  cursor: pointer;
`
const Button2 = styled.button`
  background: #22C1D0;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  color: #FFFFFF;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  width: 71px;
  height: 42px;
  margin-Right: 16px;
  cursor: pointer;
`

const WarningModal = (props) => {
    const modalData = (type) => {
        const data = {
            warningLeave: {
                icon: <LeaveIcon />,
                InitialMessage: "¡Vas a abandonar!",
                message: "Si lo haces, esta nueva entidad no quedará guardada",
                message2: '¿Realmente deseas hacerlo?',
                buttonMessage: 'Si',
                buttonMessage2: 'No',
            },
            warningLeaveUser: {
                icon: <LeaveIcon />,
                InitialMessage: "¡Vas a abandonar!",
                message: "Si lo haces, este nuevo usuario no quedará guardado",
                message2: '¿Realmente deseas hacerlo?',
                buttonMessage: 'Si',
                buttonMessage2: 'No',
            },
            warningLeaveUpdate: {
                icon: <LeaveIcon />,
                InitialMessage: "¡Vas a abandonar!",
                message: "Si lo haces, las modificaciones no quedarán actualizadas.",
                message2: '¿Realmente deseas hacerlo?',
                buttonMessage: 'Si',
                buttonMessage2: 'No',
            },
            warningUpdateUserActive: {
                icon: <LeaveIcon />,
                InitialMessage: "¿Estas seguro de activar usuario?",
                buttonMessage: 'Si',
                buttonMessage2: 'No',
            },
            warningUpdateUserInactive: {
                icon: <LeaveIcon />,
                InitialMessage: "¿Estas seguro de inactivar usuario?",
                buttonMessage: 'Si',
                buttonMessage2: 'No',
            },
            warningUpdateUserActiveProviderAndClient: {
                icon: <LeaveIcon />,
                InitialMessage: "¿Estas seguro de activar usuario?",
                buttonMessage: 'Si',
                buttonMessage2: 'No',
            },
            warningUpdateUserInactiveProviderAndClient: {
                icon: <LeaveIcon />,
                InitialMessage: "¿Estas seguro de inactivar usuario?",
                buttonMessage: 'Si',
                buttonMessage2: 'No',
            },
            warningDeleteDraft: {
                icon: <LeaveIcon />,
                InitialMessage: "!Vas a eliminar este borrador!",
                message2: '¿Realmente deseas hacerlo?',
                buttonMessage: 'Si',
                buttonMessage2: 'No',
            },
            warningDeleteDraftList: {
                icon: <LeaveIcon />,
                InitialMessage: "!Vas a eliminar este borrador!",
                message2: '¿Realmente deseas hacerlo?',
                buttonMessage: 'Si',
                buttonMessage2: 'No',
            },
            warningUpdateProviderActive: {
                icon: <LeaveIcon />,
                InitialMessage: "¿Estas seguro de activar este operador?",
                buttonMessage: 'Si',
                buttonMessage2: 'No',
            },
            warningUpdateProviderInactive: {
                icon: <LeaveIcon />,
                InitialMessage: "¿Estas seguro de inactivar este operador?",
                buttonMessage: 'Si',
                buttonMessage2: 'No',
            },
            warningUpdateClientActive: {
                icon: <LeaveIcon />,
                InitialMessage: "¿Estas seguro de activar este cliente?",
                buttonMessage: 'Si',
                buttonMessage2: 'No',
            },
            warningUpdateClientInactive: {
                icon: <LeaveIcon />,
                InitialMessage: "¿Estas seguro de inactivar este cliente?",
                buttonMessage: 'Si',
                buttonMessage2: 'No',
            },
            warningLeaveProvider: {
                icon: <LeaveIcon />,
                InitialMessage: "¡Vas a abandonar!",
                message: "Si lo haces, este nuevo operador no quedará guardado",
                message2: '¿Realmente deseas hacerlo?',
                buttonMessage: 'Si',
                buttonMessage2: 'No',
            }, 
            warningLeaveClient: {
                icon: <LeaveIcon />,
                InitialMessage: "¡Vas a abandonar!",
                message: "Si lo haces, este nuevo cliente no quedará guardado",
                message2: '¿Realmente deseas hacerlo?',
                buttonMessage: 'Si',
                buttonMessage2: 'No',
            }, 
        }
        return data[type]
    }

    const customEqual = (oldValue, newValue) => oldValue === newValue
    const userSerialID = sessionStorage.getItem("userSerialId")
    const idUser = sessionStorage.getItem("idUser")
    const idProvider = sessionStorage.getItem("idProvider")
    const idClient = sessionStorage.getItem("idClient")
    const dispatch = useDispatch()
    var listToDelete = useSelector((state) => state.draftReducer.listToDelete, customEqual)
    const  filterReducer  = useSelector((state) => state.filterReducer)
    const { count, pag, searchBar, sort } = useSelector((state) => state.filterReducer)
    const filterDetailReducer = useSelector((state) => state.filterDetailReducer)
    const companySerialID = useSelector((state) => state.clientReducer.detailClient.companySerialID)
    const orderTranslator = (order) => {
        const options = {
          'masReciente': {"_id": -1},
          'masAntiguo': {"_id": 1},
          'A-Z': {"name": 1},
          'Z-A': {"name": -1}
        }
        return options[order]
      }
      
      const body= {
        count: count,
        pag: pag,
        sort: orderTranslator(sort),
        textToSearch: searchBar[0]
      }
      const bodyDetail ={
        count: filterDetailReducer.count,
        pag: filterDetailReducer.pag,
        sort: orderTranslator(filterDetailReducer.sort),
        companySerialID: companySerialID,
        textToSearch: filterDetailReducer.searchBar[0]
      } 

    const onClose = () => {
        if (props.modalState.type === 'warningDeleteDraft' || props.modalState.type === 'warningDeleteDraftList') {
            dispatch(closeWarningModal({ type: 'warningDeleteDraft'}))
        }else  {
            dispatch(warningFormModal({ type: 'warningLeaveUser' }))
        }
    }

    const handleOnLoad = () => {
        if (props.modalState.type === 'warningUpdateUserActive') {
            dispatch(putStateActive({ "status": 'ACTIVE' }, idUser)).then(()=>{
                dispatch(getUsers(body))
            })
        } else if (props.modalState.type === 'warningUpdateUserInactive') {
            dispatch(putStateInactive({ "status": 'INACTIVE' }, idUser)).then(()=>{
                dispatch(getUsers(body))
            })
        }else if (props.modalState.type === 'warningUpdateUserActiveProviderAndClient') {
            dispatch(putStateActive({ "status": 'ACTIVE' }, userSerialID)).then(()=>{
                dispatch(getEmployees(bodyDetail))
            })
        }else if (props.modalState.type === 'warningUpdateUserInactiveProviderAndClient') {
            dispatch(putStateInactive({ "status": 'INACTIVE' }, userSerialID)).then(()=>{
                dispatch(getEmployees(bodyDetail))
            })
        } else if (props.modalState.type === 'warningDeleteDraft') {
            const body = {
                arrayDraftSerialID: [props.id]
            }
            dispatch(deleteDraft(body, filterReducer))
        } else if (props.modalState.type === 'warningDeleteDraftList') {
            const body = {
                arrayDraftSerialID: listToDelete
            }
            dispatch(deleteDraftListV2(body, filterReducer))
        }else if (props.modalState.type === 'warningUpdateProviderActive') {
            dispatch(putStateActiveProvider({ "status": 'ENABLE' }, idProvider)).then(()=>{
                dispatch(getProvidersV2(body))
            })
        } else if (props.modalState.type === 'warningUpdateProviderInactive') {
            dispatch(putStateInactiveProvider({ "status": 'DISABLE' }, idProvider)).then(()=>{
                dispatch(getProvidersV2(body))
            })
        } else if (props.modalState.type === 'warningUpdateClientActive') {
            dispatch(putStateActiveClient({ "status": 'ENABLE' }, idClient)).then(()=>{
                dispatch(getClients(body))
            })
        } else if (props.modalState.type === 'warningUpdateClientInactive') {
            dispatch(putStateInactiveClient({ "status": 'DISABLE' }, idClient)).then(()=>{
                dispatch(getClients(body))
            })
        }else {
            props.history.goBack()
            dispatch(inactiveFormUserEdit())
            dispatch(inactiveFormUser())
        }
        
        if (props.activeForm === false || props.activeFormProvider === false ) {
            dispatch(warningFormModal({ type: 'warningLeaveUser' }))
        }
    }


    const data = modalData(props.modalState.type)
    return (
        <ModalElement
            open={props.modalState.status}
            onClose={onClose}
            className={props.modalState.type === 'warningUpdateUserActive' || props.modalState.type === 'warningUpdateUserInactive' ? 'statusUser' : 'generalClass'}
        >
            <ModalContent>
                {!!data.icon
                    && data.icon}
                {data.multipleMessages && !!props.modalState.message && !!props.modalState.message.length &&
                    props.modalState.message.map((message) => (
                        <>
                            <ModalMessage>
                                {message}
                            </ModalMessage>
                            <br />
                        </>
                    ))
                }
                {!data.multipleMessages &&
                    <ModalMessage>
                        <Texto>{data.InitialMessage}</Texto>
                        {!props.modalState.message && props.modalState.type !== 'warningUpdateUserActive' && props.modalState.type !== 'warningUpdateUserInactive'
                            ? data.message
                            : props.modalState.message}
                        {
                            props.modalState.type !== 'warningUpdateUserActive' &&
                            props.modalState.type !== 'warningUpdateUserInactive' &&
                            props.modalState.type !== 'warningUpdateClientInactive' &&
                            props.modalState.type !== 'warningUpdateClientActive'  &&
                            props.modalState.type !== 'warningUpdateProviderInactive' &&
                            props.modalState.type !== 'warningUpdateProviderActive'  &&
                            props.modalState.type !== 'warningUpdateUserActiveProviderAndClient'  &&
                            props.modalState.type !== 'warningUpdateUserInactiveProviderAndClient'  &&
                            <>
                                <br />
                                {props.modalState.type !== 'warningDeleteDraftList'  && props.modalState.type !== 'warningDeleteDraft'   && 
                                <br />}
                            </>
                        }
                        {!props.modalState.message && props.modalState.type !== 'warningUpdateUserActive' && props.modalState.type !== 'warningUpdateUserInactive'
                            ? data.message2
                            : props.modalState.message}
                    </ModalMessage>
                }
                {
                    <ModalActions>
                        <Button
                            onClick={handleOnLoad}
                        >
                            {data.buttonMessage}
                        </Button>
                        <Button2
                            onClick={onClose}
                        >
                            {data.buttonMessage2}
                        </Button2>
                    </ModalActions>
                }
            </ModalContent>
        </ModalElement>
    )
}
WarningModal.propTypes = {
    modalState: PropTypes.object,
    warningFormModal: PropTypes.func,
    activeForm: PropTypes.bool
}
const mapStateToProps = (state) => ({
    modalState: state.modalReducer.warningModal,
    id: state.modalReducer.id,
    activeForm: state.userReducer.activeForm,
    activeFormProvider: state.providerReducer.activeForm,
    activeFormAgreements: state.agreementReducer.activeForm
})

const mapDispatchToProps = {
    warningFormModal
}
const ShowTheLocationWithRouter = withRouter(WarningModal);

export default connect(mapStateToProps, mapDispatchToProps)(ShowTheLocationWithRouter);
