import React from 'react'
import styled from 'styled-components'
import { ReactComponent as NotFound } from '../assets/icons/notFound/notFoundIcon.svg'

const Container = styled.div`
    width: 100%;
    height:100%;
    display: flex;
    align-items: center;
    justify-content: center;
 ` 
const NotFoundIcon = styled(NotFound)`
`

const routesWithNotFound = () =>{
    return (
        <Container>
            <NotFoundIcon/>
        </Container>
    )
}

export default routesWithNotFound