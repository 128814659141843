import React, { Component } from 'react'
import Paginacion from '../../Paginacion'
import SearchBar from '../../SearchBar'
import styled from 'styled-components'
import PopUpOrder from '../../PopUpOrder'
import ButtonNewConvetion from '../Buttons/ButtonNewConvetion'
import PopUpFilterAgreements from '../../SearchAgreements/PopUpFilterAgreements'

const Divider = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 32px 0px;
  margin-left: 15px;
  margin-right: 5px;
`

class ContainerConvetionSearch extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchBarState: "",
            popUpOrderState: "",
        };
    }
    render() {
        return (
            <Divider>
                <SearchBar searchBarState={this.searchBarState} />
                <PopUpFilterAgreements/>
                <PopUpOrder popUpOrderState={this.popUpOrderState} />
                <Paginacion />
            </Divider>
        )
    }
}

export default ContainerConvetionSearch