import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import './styles.css'
import { ReactComponent as Complete } from '../../assets/icons/sent/complete.svg'
import { ReactComponent as ErrorReport } from '../../assets/icons/sent/errorReport.svg'
import { ReactComponent as Rejected } from '../../assets/icons/sent/rejected.svg'
import { ReactComponent as Loading } from '../../assets/icons/sent/loading.svg'
import { ReactComponent as Close } from '../../assets/icons/closeDark.svg'
import { ReactComponent as LoadingHeader } from '../../assets/icons/sent/loadingHeader.svg'
import { ReactComponent as ArrowDown } from '../../assets/icons/sent/arrowDown.svg'
import { ReactComponent as ArrowUp } from '../../assets/icons/sent/arrowUp.svg'
import { ReactComponent as CloseWhite } from '../../assets/icons/sent/iconCloseWhite.svg'
import PrimaryButton from '../../components/PrimaryButton'
import { useDispatch, useSelector } from 'react-redux'
import { changeMessageModalTwo, changeStateMassiveIncompleteModal, openwriteNotificationModal, recordsMassiveFileV2 } from '../../actions/modalActions'
import { API_ENDPOINT } from '../../config/config'
import styled, { keyframes } from 'styled-components'
import { chageStateLoadCitizens, chageStateLoadCitizensList, chageStateLoadNotificationsList, changeWriteNotificationForm, createNewParametrizationTags, deleteLoadCitizenItem, isMassiveRecipient, setFileSerialId } from '../../actions/writerActions'
import { CHANGE_STATE_CITIZENS_CHARGING, SPECIFIC_DOCUMENTS } from '../../actions/actionsUtilities/types'
import { saveDraftData, saveDraftV2 } from '../../actions/draftsActions'

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const RotatingSvg = styled.svg`
  animation: ${rotateAnimation} 2s infinite steps(8);
  max-width:30px;
  max-height:30px;
`;

const SendCitizens = (props) => {

    const [Isvisible, setIsVisible] = useState(false);
    const isVisibleLoadCitiziens = useSelector(state => state.writerReducer.loadCitizensList);
    const IsVisibleSendNotification = useSelector(state => state.writerReducer.loadNotificationsList);
    const isVisibleAll = useSelector(state => state.writerReducer.loadCitizens)
    const companyID = useSelector(state => state.authReducer.user.companyID._id)
    const resultRecordFileMassive = useSelector(state => state.writerReducer.resultRecordFileMassive.listFileSearchMassive)
    const dispatch = useDispatch()
    const [width, setWidth] = useState(window.innerWidth);
    const [recordsExist, setRecordExist] = useState(false)
    const { citizenIsCharging } = useSelector(state => state.writerReducer)
    const { toSaveDraft } = useSelector(state => state.draftReducer)
   

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);
    const handleResize = () => {
        setWidth(window.innerWidth);
    };

    useEffect(()=>{
        dispatch(recordsMassiveFileV2({ companyID: companyID }))
    },[])

    useEffect(() => {
        const intervalId = setInterval(() => {
            if(isVisibleLoadCitiziens){
                dispatch(recordsMassiveFileV2({ companyID: companyID }))
                
            }
            }, 3000); 
        return () => {
            clearInterval(intervalId);
        }
    }, [isVisibleLoadCitiziens])

    useEffect(()=>{
        if(resultRecordFileMassive.length > 0){
            setRecordExist(true)
            for (let index = 0; index < resultRecordFileMassive.length; index++) {
                const element = resultRecordFileMassive[index];
                if(element.status === 'En espera' || element.status ==='En progreso' ){
                    dispatch({type: CHANGE_STATE_CITIZENS_CHARGING, payload: true})
                    dispatch(chageStateLoadCitizens(true))
                    break;
                }else{
                    dispatch({type: CHANGE_STATE_CITIZENS_CHARGING, payload: false})
                }
            }
        }else{
            setRecordExist(false)
        }
    },[resultRecordFileMassive, dispatch])

    const onClick = (item, key) => {
        if (item.status === 'Completo') {
            const list = resultRecordFileMassive
            list.splice(key,1)
            dispatch(deleteLoadCitizenItem(list))
        } else if (item.status === 'Incompleto') {
            dispatch(changeStateMassiveIncompleteModal(item.urlFileError))
        }
        dispatch(setFileSerialId(item.fileSerialID))
        dispatch(changeWriteNotificationForm('recipient',item.fieldTo))
        dispatch(changeWriteNotificationForm('notificationCount',item.citizenValidate))
        dispatch(isMassiveRecipient(true))
        dispatch({type: SPECIFIC_DOCUMENTS, payload: item.listDocumentsSpecific})
        dispatch(createNewParametrizationTags(item.listTagsChargeMassive.map((item) => ({'type': 'TAG', 'tagName': item}))))
        dispatch(saveDraftData({fileSerialID: item.fileSerialID}))
        dispatch(saveDraftV2({...toSaveDraft,fileSerialID: item.fileSerialID}))
        dispatch(openwriteNotificationModal())
    }
    const closeCourtain = () =>{
        if(width <= 768){
            if (IsVisibleSendNotification) {
                dispatch(chageStateLoadNotificationsList(!IsVisibleSendNotification))  
            }else{
                dispatch(chageStateLoadCitizensList(!isVisibleLoadCitiziens))
            }
        }else{
            dispatch(chageStateLoadCitizensList(!isVisibleLoadCitiziens))
        }
    }
    const deleteItem = (item, key) => {
        dispatch(changeMessageModalTwo({
            type: 'deleteCitizenItem',
            message: ['¡Vas a eliminar este registro de envío! ','Recuerda descargar el archivo de ciudadanos no cargados antes de hacerlo, no podrás hacerlo en ningún otro momento.'],
            fileSerialID: item.fileSerialID,
            urlFileError: item.urlFileError
        }))
    }

    useEffect(()=>{
        if(resultRecordFileMassive.length === 0){
            dispatch(chageStateLoadCitizens(false))
        }else {
            dispatch(chageStateLoadCitizens(true))
        }
    },[resultRecordFileMassive, dispatch])

    return (
        <>
            <div className={
                (!!isVisibleAll) ?
                    (!isVisibleLoadCitiziens) ? "parentSendCitizens initial" : "parentSendCitizens"
                    :
                    "close"
            }>
                <header className="headerSendCitizens">
                    {citizenIsCharging &&
                    <span className='centerTag iconHeader'>
                        <div className='loading-container'>
                            <div class="lds-spinner-reportLoading-header" ><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        </div>
                    </span>
                    }
                    <span className={!citizenIsCharging? 'centerTag headerTextFinalized': 'centerTag headerText'} >{citizenIsCharging? 'Cargando ciudadanos':'Ciudadanos cargados'}</span>
                    <span className='centerTag iconHeader' onClick={() => closeCourtain()}>
                        {(width <= 768 ?(!isVisibleLoadCitiziens || IsVisibleSendNotification) : !isVisibleLoadCitiziens) ? <ArrowUp style={{ margin: '-8px 0px' }} /> : <ArrowDown style={{ margin: '-8px 0px' }} />}
                    </span>
                    {!citizenIsCharging && !recordsExist &&
                    <span className='centerTag iconHeaderClose'>
                        <CloseWhite onClick={() => dispatch(chageStateLoadCitizens(false))} style={{ margin: '-8px 0px' }} />
                    </span>
                    }
                </header>
                <div className="contentSendCitizens">
                    {resultRecordFileMassive.map((item, index) => {
                         
                        return (
                            <>
                                {!!item && item.status === 'Completo' &&

                                    <div className='sendNotificationsData'>
                                        <div className='textSendNotification'>
                                            <p>{item.nameCampaign}</p>
                                            <p className='loadSendNotification'>{item.citizenValidate} de {item.totalRecordsToVerify}</p>
                                        </div>
                                        <div className='stateNotificationCitizen withButtonCitizen'>
                                            <Complete style={{ width: '20px', height: '20px', margin: '-4px 0px' }} />
                                        
                                            <span className='contentButton'>
                                                <PrimaryButton
                                                    className='styleButton'
                                                    txtBtn={'Continuar'}
                                                    onPress={() => onClick(item, index)}
                                                />
                                            </span>
                                        </div>
                                    </div>

                                }
                                {!!item && item.status === 'Incompleto' &&

                                    <div className='sendNotificationsData'>
                                        <div className='textSendNotification'>
                                            <p>{item.nameCampaign}</p>
                                            <p className='loadSendNotification'>{item.citizenValidate} de {item.totalRecordsToVerify}</p>
                                            <a className='downloadAttachment' href={item.urlFileError}>Descargar archivo de ciudadanos no cargados</a>
                                        </div>
                                        <div className='stateNotificationCitizen withButtonCitizen'>
                                            <ErrorReport style={{ width: '20px', height: '20px', margin: '-4px 0px' }} />
                                            
                                            <span className='contentButton'>
                                                <PrimaryButton
                                                    className='styleButton'
                                                    txtBtn={'Continuar'}
                                                    onPress={() => onClick(item, index)}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                }
                                {!!item && item.status === 'No Cargado' &&
                                    <div className='sendNotificationsData'>
                                        <div className='textSendNotification'>
                                            <p>{item.nameCampaign}</p>
                                            <p className='loadSendNotification'>{item.citizenValidate} de {item.totalRecordsToVerify}</p>
                                            <a className='downloadAttachment' href={item.urlFileError}>Descargar archivo de ciudadanos no cargados</a>
                                        </div>
                                        <div className='stateNotificationCitizen withoutButtonCitizen'>
                                            <Rejected style={{ width: '20px', height: '20px', margin: '-4px 0px' }} />
                                            <span className='closeIconNotification' id={index} onClick={()=>deleteItem(item, index)} ><Close /></span>
                                        </div>
                                    </div>
                                }
                                {!!item && item.status === 'En progreso' && 
                                    <div className='sendNotificationsData'>
                                        <div className='textSendNotification'>
                                            <p>{item.nameCampaign}</p>
                                            <p className='loadSendNotification'>{item.totalRecordsVerified} de {item.totalRecordsToVerify}</p>
                                        </div>
                                        <div className='stateNotificationCitizen withoutButton'>
                                        <div className='loading-container'>
                                            <div class="lds-spinner-citizenLoading" ><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                        </div>
                                            
                                        </div>
                                    </div>
                                }
                                {!!item && item.status === 'En espera' && 
                                    <div className='sendNotificationsData'
                                        
                                    >
                                        <div className='textSendNotification'>
                                            <p>{item.nameCampaign}</p>
                                            <p className='loadSendNotification'>{item.totalRecordsVerified} de {item.totalRecordsToVerify}</p>
                                        </div>
                                        <div className='stateNotificationCitizenText'>
                                            <span className='textOnHold'>En espera...</span>
                                            
                                        </div>
                                    </div>
                                }

                            </>
                        )
                    })

                    }

                </div>
            </div>
        </>
    )
}

SendCitizens.propTypes = {
}
export default SendCitizens
