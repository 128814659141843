import {useEffect, useRef, useState} from 'react'
import _ from "lodash"

export const getHeaders = ()=> {
  return { 
    'Authorization': sessionStorage.getItem('jwtToken'), 
    'x-api-key': sessionStorage.getItem('publicKey'),
    'Strict-Transport-Security' : 'max-age=31536000',
    'X-XSS-Protection': '1; mode=block',
    'X-Content-Type-Options': 'nosniff',
    'Content-Security-Policy': "script-src 'self'",
    'X-Content-Type-Options': 'nosniff',
    'X-Permitted-Cross-Domain-Policies': 'none',
  }
}

export const hexToBase64 = (hexstring) => {
  return btoa(hexstring.match(/\w{2}/g).map(function(a) {
      return String.fromCharCode(parseInt(a, 16));
  }).join(""));
}

export const dateTimeToString = (dateTime) => {
  const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute:'2-digit', hour12: true}
  return new Date(dateTime).toLocaleTimeString('co-CO', options)
}

export const dateToString = (dateTime) => {
  return new Date(dateTime).toLocaleDateString()
}

export const dateToStringSentence = (dateTime) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' }
  return new Date(dateTime).toLocaleString('co-CO', options)
}

export const toastOptions = {
  position: 'top-right',
  hideAfter: 4
}

export const toastOptionsInfo = {
  position: 'top-right',
  hideAfter: 8
}

export const toastOptionsTime = {
  position: 'top-right',
  hideAfter: 10,
}

export const findRole = (object, role) => {
  let flag = false
  if (object.item !== undefined) {
    object.item.forEach((value) => {
      if (_.filter(value.roles, (n) => role === n).length > 0) {
        flag = true
      }
    })
  } else {
    if (_.filter(object.roles, n => role === n).length > 0) {
      flag = true
    }
  }
  return flag
}

export const getPaginatedItems = (items, page, pageSize) => {
  let pg = page || 1,
    pgSize = pageSize || 100,
    offset = (pg - 1) * pgSize,
    pagedItems = _.drop(items, offset).slice(0, pgSize)
  return {
    page: pg,
    pageSize: pgSize,
    total: items.length,
    total_pages: Math.ceil(items.length / pgSize),
    data: pagedItems
  }
}

export const transformedList = (list, idKey, textKey, nitKey) => {
  return list ? list.map((info) => ({
    key: info[idKey],
    value: info[idKey],
    text: info[textKey],
    nit: info[nitKey],
  })) : []
}

export const bytesToSize = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const LIMIT_FILE_SIZE = 10485760

export const validateEmailBags = emailBags => {
  let bagsValidation = {
    certified: undefined,
    uncertified: undefined,
  };

  const findUnlimitedCert = emailBags
    .find(bag => !bag.expired && !bag.emailBag.byConsumption && bag.emailBag.certificate)

  findUnlimitedCert && Object.assign(bagsValidation, {
    certified: {
      byConsumption: false,
      emailsAvailable: -1,
    }
  })

  if (!findUnlimitedCert) {
    let totalAvailables = 0,
      byConsumption = undefined
    for (let bag of emailBags) {
      if (!bag.expired && bag.emailBag.byConsumption && bag.emailBag.certificate) {
        totalAvailables +=
          bag.emailBag.quantity - bag.numberSent
        byConsumption = true
      }
    }
    Object.assign(bagsValidation, {
      certified: {
        byConsumption: byConsumption,
        emailsAvailable: totalAvailables
      }
    })
  }

  const findUnlimitedUncert = emailBags.find(bag => {
    return !bag.expired && !bag.emailBag.byConsumption && !bag.emailBag.certificate
  })

  findUnlimitedUncert && Object.assign(bagsValidation, {
    uncertified: {
      byConsumption: false,
      emailsAvailable: -1,
    }
  })

  if (!findUnlimitedUncert) {
    let totalAvailables = 0,
      byConsumption = undefined
    for (let bag of emailBags) {
      if (!bag.expired && bag.emailBag.byConsumption && !bag.emailBag.certificate) {
        totalAvailables +=
          bag.emailBag.quantity - bag.numberSent
        byConsumption = true
      }
    }
    Object.assign(bagsValidation, {
      uncertified: {
        byConsumption: byConsumption,
        emailsAvailable: totalAvailables
      }
    })
  }

  return bagsValidation
}

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value; //assign the value of ref to the argument
  },[value]); //this code will run when the value of 'value' changes
  return ref.current; //in the end, return the current ref value.
}
export default usePrevious;

export const useFilterPosition = (filterRef, containerRef) => {
  const [filterPosition, setFilterPosition] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (!filterRef.current || !containerRef.current) return;

      const containerRect = containerRef.current.getBoundingClientRect();
      const filterRect = filterRef.current.getBoundingClientRect();
      const newTop = filterRect.top - containerRect.top;
      setFilterPosition(newTop);
    };
    containerRef.current.addEventListener('scroll', handleScroll);
    return () => {
      containerRef.current.removeEventListener('scroll', handleScroll);
    };
  }, [filterRef, containerRef]);

  return filterPosition;
};

