import React, {useEffect} from 'react'
import PropTypes from 'prop-types'

import AllProviders from './AllProviders'
import {getGeographicList} from "../../actions/defaultListActions";
import {useDispatch, useSelector} from "react-redux";
import ContainerSearchClientsAndProviders from '../../components/Search/SearchClientsAndProviders/ContainerSearchClientsAndProviders';
import MessageModal from '../../components/Modal/MessageModal';
import WarningModal from '../../components/FormClientsAndProviders/Modal/WarningModal';
import { warningFormModal } from '../../actions/modalActions';

const Providers = (props) => {
  const dispatch = useDispatch()
  const providers = useSelector( state => state.providerReducer.providers)

  useEffect(() => {
    async function geography() {
      await dispatch(getGeographicList())
    }
    geography().then(() => {})
  }, [dispatch])
  return (
    <>
      <ContainerSearchClientsAndProviders 
        textPlaceHolder={'Buscar' } 
        buttonType= {'provider'} 
        text={'Nuevo operador'} 
        showAZ={true}
        showButton={true} 
        showFilters={true}
        showExportButton={true} 
        disabledExportButton = {!providers.length > 0}
        reportType={"LIST_PROVIDERS"} 
        providerType={"PROVIDER"}
        showAdvancedSearch={false}
      />
      <WarningModal />
      
      <AllProviders history={props.history}/>
    </>
  )
};

Providers.propTypes = {
  history: PropTypes.any.isRequired,
};


export default Providers