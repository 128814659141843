import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { connect } from "react-redux"
import UsersMenu from '../MenuBar/menu/UsersMenu'
import Configuration from '../MenuBar/Configuration'
import { ReactComponent as MenuRes } from '../../assets/icons/menu/menu-responsive-icon.svg'
import { ReactComponent as IconClose } from '../../assets/icons/report/iconCloseDark.svg'
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import PropTypes from 'prop-types'
import { Image } from "semantic-ui-react"
import user from "../../assets/icons/user.png"
import singnOff from "../../assets/icons/sign-off.png"
import { logoutUser,changeRole} from "../../actions/authActions"
import ButtonWhite from "../MenuBar/menu/ButtonWhite"
import cogoToast from "cogo-toast"
import { toastOptions } from "../../helpers/globalHelpers"
import './style.css';
import MessageModal from '../Modal/MessageModal'
import ReportModalLoading from '../ModalReport/ReportModalLoading'
import ModalMassiveIncomplete from '../ModalMassiveLoad/ModalMassiveIncomplete'
import MessageModalTwo from '../Modal/MessageModalTwo'
import { chageStateLoadNotificationsList, chageStateLoadCitizensList } from '../../actions/writerActions';
import { hideMessageModal } from '../../actions/modalActions';
import IdleTimerComponentsC from '../IdelComponent/IdleTimerComponentsC'
import { setPathnameCurrent } from '../../actions/menuActions'
import { Cookies } from 'react-cookie';
import { ReactComponent as Reviewed } from '../../assets/icons/reviewed.svg'
import MessageModalChangeRole from '../Modal/MessageModalChangeRole'
import { changeStateSuccessCreateReport, getToBeNotified, setIntervalToBeNotified } from '../../actions/reportsActions'

const HorizontalContent = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  position: relative;
`
const LeftMenuBar = styled.div`
  width: 15%;
  height: 88vh;
  background: #E4E4E4;
  @media only screen and (max-width: 1330px){
    width:96px;
  }

  @media only screen and (max-width: 768px) {
    width: 35%;
    position: fixed;
    z-index: 3000;
    width: 30vw;
    height: 100vh;
    top: 0;
    right: 0;
  }

  @media only screen and (max-width: 768px) {
    width: 60vw;
  }

  @media only screen and (max-width: 425px) {
    width: 80vw;
  }
`
const Content = styled.div`
  width: 70%;
  @media only screen and (max-width: 1500px) {
    width: 85%;
  }
  @media only screen and (max-width: 1330px) {
    width: calc(100% - 96px);
  }
    @media only screen and (max-width: 768px) {
      width:100%;
    }
`
const ConfigurationContent = styled.div`
  width: 15%;
  background: rgba(0, 0, 0, 0.03);
  @media only screen and (max-width: 1500px) {
    display:none;
  }
`

const MenuResIcon = styled(MenuRes)`
  display:none;
  position: absolute;
  right: 15px;
  top: -38px;
  z-index:1;
  cursor: pointer;
  @media only screen and (max-width: 768px)  {
    display:block;
  }
`

const ContainerMenu = styled.div`
  display: contents;
`

const ContainerDataUser = styled.div`

  position: relative;
  display: none;
  flex-direction: column;
  align-items: start;
  width:100%;
  background: #133F4F;
  @media only screen and (max-width: 768px)  {
    display: -webkit-inline-box;
    padding: 0px 0px 25px 22px;
  }
`

const Name = styled.p`
  width: 90%;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 20px;
  color: white;
  margin: 0;

  @media only screen and (max-width: 800px) {
    font-size:14px;
  }
`
const NameCompany = styled.p`
  width: 90%;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: white;
  text-transform: capitalize !important;
  margin: 0;

  @media only screen and (max-width: 800px) {
    font-size:14px;
  }
`

const UserIcon = styled(Image)`
`

const ContainerName = styled.div`
  padding-left:10px;
  margin-top: 2px;
  width: 80%;
`
const ContainerButtonWhite = styled.div`
  border-top: 1px solid #979E9F;
  display: none;
  position: absolute;
  height: 70px;
  bottom:0;
  width: 100%; 
  margin-top: 10px !important;
  background: #E4E4E4; 
  @media only screen and (max-width: 768px)  {
    display: inline-flex !important;
  }
`
const OpacityContainer = styled.div`
  position: fixed;
  width:100vw;
  height:100vh;
  background: black;
  opacity: 0.7;
  z-index: 2500;
  @media only screen and (min-width: 768px)  {
    display: none !important;
  }
`

const PopUpUserInfo = styled.div`
  padding:16px;
  position: absolute;
  width: calc(100% - 32px);
  background: white;
  position: absolute; /* Cambia a posición absoluta */
  top: 50px;
  left: 16px;
  border-radius:4px;
  z-index: 3000;
  box-shadow: 0px 5px 10px 0px #0000004D;

   &::after {
    position: absolute;
    content: '';
    width: 0.71428571em;
    height: 0.71428571em;
    top: -0.30714286em;
    bottom: auto;
    left: 20px;
    margin-left: 0;
    background: #fff;
    transform: rotate(45deg);
    border-radius: 3px;
  }
`

const DropDownName = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #133F4F;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 16px !important;

`

const DropDownText = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #6D6E71;
  overflow: hidden;
  text-overflow: ellipsis;
`

const LineBorder = styled.hr`
  height:0px;
  margin: auto;
  background-color: #B2B2B2;
  margin-top: 16px;
`
const ContainerOption = styled.div`
`
const ButtonOption = styled.button`
  color: #7C7C74;
  cursor: pointer;
  border: 0;
  background: #fff;
  font-weight: normal;
  font-size: 16px;
  height: 24px;
  text-align: left;
  width: 100%;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  margin-top: 8px;
`
const Text = styled.p`
  color: #7C7C74;
  font-weight: normal;
  font-size: 16px;
  padding-left: 24px
`

const ContainerWarningMessage = styled.div`
  background: #E8FDFE;
  min-width: 293px;
  width:100%;
  max-height: 60px;
  height: 60px;
  border: 1px solid #4E8D93;
  border-radius: 4px;
  top: 8px;
  right: 8px;
  padding: 12px 24px 12px 24px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 16px;
`;
const ContainerText= styled.div`
  display: flex;
  flex-wrap: wrap;
  width: inherit;
  gap: 4px;
`;

const ContainerTextOne = styled.label`
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #133F4F;
`;
const ContainerTextTwo = styled.label`
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #22C1D0;
  cursor: pointer;
  text-decoration-line: underline;

`;

const IconCloseDark = styled(IconClose)`
  cursor: pointer;
`;

const ContainerListWarningMessage= styled.div`
  min-width: 293px;
  width: min-content;
  height: auto;
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

class AppContent extends Component {

  state = {
    isView: false,
    classCurrent: 'hide-menu reset-menu',
    showInfouser:false,
    emailisCut:false,
    showFullEmail:false,
    isAdmin: false,
  }

  componentDidMount() {
    this.props.hideMessageModal()
      if(this.props.user.role === "61b36e2fc34e22351bf8cfb4"){
        this.setState({isAdmin : true })
        this.props.setIntervalToBeNotified(setInterval(() => {this.props.getToBeNotified()}, 30000))
      }else{
        this.setState({isAdmin : false })
      }
  }

  changeIsView = () => {
    this.validateClass(!this.state.isView)
    this.setState({ isView: !this.state.isView })
    this.setState({ showInfouser: this.state.isView })
  }

  refreshIsView = () => {
    this.setState({ isView: false })
    this.validateClass()
  }


  redirect = (component) => {
    this.props.history.push({ pathname: component.component })
    this.props.setPathnameCurrent(this.props.history.location.pathname)
    this.props.chageStateLoadNotificationsList(false)
    this.props.chageStateLoadCitizensList(false)
  }

  onLogout = e => {
    e.preventDefault()
    const myCookie= new Cookies()
    this.props.logoutUser(this.props.history, myCookie)
    cogoToast.info('Sesión finalizada exitosamente', toastOptions)
  }

  validateClass = (validate) => {
    if (!!validate) {
      this.setState({ classCurrent: 'show-menu' })
    } else {
      this.setState({ classCurrent: 'hide-menu' })
      setTimeout(() => {
        this.setState({ classCurrent: 'hide-menu reset-menu' })
      }, 300)
    }
  }
  
  handleClickUserIcon = () => {
    this.setState({ showInfouser: !this.state.showInfouser  })
  }

  cutName = (name) => {
    let [beforeAtSign, afterAtSign] = name.split("@");
    if (beforeAtSign.length > 18) {
        beforeAtSign = beforeAtSign.substring(0, 15) + "...";
        this.state.emailisCut == false && this.setState({ emailisCut: true });
    }
    if (afterAtSign.length > 13) {
        afterAtSign = afterAtSign.substring(0, 13) + "...";
        this.state.emailisCut == false && this.setState({ emailisCut: true });
    }
    return beforeAtSign + '@' + afterAtSign;
  }

  changeRole = (id, role) => {
    if(this.props.actionsRunning.length > 0){
        this.props.showChangeRoleModal(this.props.actionsRunning.length > 1)      
    }else{
      if(this.props.user.role !== role) this.props.changeRole(this.props.history, { userSerialID: id })
    }
    this.refreshIsView()
  }

  

  closeWarningMessage = () => {
    this.props.changeStateSuccessCreateReport(false)
  }

  redirectReportList = () => {
    this.props.history.push('/reports')
    this.props.changeStateSuccessCreateReport(false)
  }

  render() {
    return (
      <>
        <IdleTimerComponentsC history={this.props.history} />
        <OpacityContainer style={!!this.state.isView ? { display: 'block' } : { display: 'none' }} />
        <HorizontalContent >

          <MessageModal history={this.props.history} />
          <MessageModalChangeRole history={this.props.history}/>
          <ReportModalLoading history={this.props.history} />
          <ModalMassiveIncomplete />

          <ClickAwayListener onClickAway={this.refreshIsView}>
            <ContainerMenu>
              <MenuResIcon onClick={this.changeIsView} />
              <LeftMenuBar className={this.state.classCurrent} id='LeftMenuBar'
              >
                <UsersMenu
                  menuRoutes={this.props.menuRoutes}
                  redirect={this.redirect}
                  history={this.props.history}
                  url={window.location.pathname}
                  changeView={this.changeIsView}
                  dataUser={
                    <ContainerDataUser>
                      <UserIcon src={user} onClick={this.handleClickUserIcon}></UserIcon>
                      {
                        this.state.showInfouser &&
                          <PopUpUserInfo>
                            
                            {
                              this.props.user.firstName !== undefined && this.props.user.lastName !== undefined ?
                                (this.props.documentNumber !== undefined) ?
                                  <>
                                    <DropDownName>{this.props.user.firstName} {this.props.user.lastName}</DropDownName>
                                    <DropDownText>C.C {this.props.user.documentNumber}</DropDownText>
                                    <DropDownText>{this.cutName(this.props.user.email)}</DropDownText>
                                    {this.state.showFullEmail && this.state.emailisCut &&
                                    <div style={{position:'relative',display:'flex',justifyContent:'center'}}>
                                      <FullEmailtext >
                                        {this.props.user.email}
                                      </FullEmailtext>
                                    </div>}
                                  </>
                                  :
                                  <>
                                    <DropDownName>{this.props.user.firstName} {this.props.user.lastName}</DropDownName>
                                    <DropDownText>C.C {this.props.user.documentNumber}</DropDownText>
                                    <DropDownText  >
                                        {this.cutName(this.props.user.email)}
                                      </DropDownText>
                                    {this.state.showFullEmail && this.state.emailisCut &&
                                      <div style={{position:'relative',display:'flex',justifyContent:'center'}}>
                                        <FullEmailtext >
                                          {this.props.user.email}
                                        </FullEmailtext>
                                      </div>}
                                      
                                  </>
                                : ''
                            }
                            <LineBorder/>

                            {!!this.props.user.roles && this.props.user.roles.length >= 1 
                            && this.props.typeOfLogin === 'withCertificate' 
                            &&
                              <>
                                <ContainerOption>
                                  <DropDownName style={{ justifyContent: 'flex-start', display: 'flex', padding: '0px 0px', fontSize: '16px', 
                                  fontWeight: '400' }}>Tus roles</DropDownName>
                                  {this.props.user.roles.map(({ userSerialID, role, roleName }) =>
                                    <ButtonOption  key={userSerialID} className='buttonOptionHover' 
                                    onClick={() => this.changeRole(userSerialID, role)}>
                                      {this.props.user.role === role && <Reviewed style={{ margin: '1px 6px 0 0' }} />}
                                      {this.props.user.role !== role ? <Text>{roleName}</Text> : roleName}
                                    </ButtonOption>
                                  )}
                                </ContainerOption>
                                <LineBorder  />
                              </>
                            }

                          </PopUpUserInfo>
                      }
                      

                      <ContainerName>
                        {
                          this.props.user.firstName !== undefined && this.props.user.lastName !== undefined
                            ? <Name>{this.props.user.firstName} {this.props.user.lastName}</Name>
                            : ''
                        }
                        {
                          this.props.companyID !== undefined
                            ? <NameCompany>{this.props.companyID.name}</NameCompany>
                            : ''
                        }
                        {
                          this.props.user.roleName !== undefined
                            ? <NameCompany>{this.props.user.roleName}</NameCompany>
                            : ''
                        }
                      </ContainerName>
                    </ContainerDataUser>
                  }
                />
                <ContainerButtonWhite>
                  <ButtonWhite
                    icon={singnOff}
                    value={{ title: 'Cerrar sesión' }}
                    onClick={this.onLogout}
                    endLine={true}
                    color={'#22C1D0'}
                  />
                </ContainerButtonWhite>
              </LeftMenuBar>
            </ContainerMenu>
          </ClickAwayListener>
          <Content>
            {this.props.children}


          </Content>
          <ConfigurationContent>
            <Configuration />
          </ConfigurationContent>
          <MessageModalTwo />
          <ContainerListWarningMessage>
          {
            !!this.state.isAdmin &&
              !!this.props.successCreateReport ?
                this.props.toBeNotifiedList?.map(item => (
                  item.reportStatus !== "PENDING" ?
                    <ContainerWarningMessage>
                      <ContainerText>
                        <ContainerTextOne>
                          ¡Reporte generado correctamente!
                        </ContainerTextOne>
                        <ContainerTextTwo onClick={this.redirectReportList}>
                          {item.reportType === "STATUS_SINGLE" ?
                          `Reporte_de_estado_${item.reportDate}`
                          :
                          `Reporte_notificaciones_erróneas_${item.reportDate}`
                          }
                        </ContainerTextTwo>
                      </ContainerText>
                      <IconCloseDark onClick={this.closeWarningMessage}/>
                    </ContainerWarningMessage>
                  : ''
                ))
              :
              ''
          }
          </ContainerListWarningMessage>
        </HorizontalContent>
      </>
    )
  }
}

AppContent.propTypes = {
  viewMenu: PropTypes.func,
  menuRoutes: PropTypes.array,
  isAuthenticated: PropTypes.bool,
}

const mapStateToProps = state => ({
  user: state.authReducer.user,
  isAuthenticated: state.authReducer.isAuthenticated,
  companyID: state.authReducer.user.companyID,
  typeOfLogin: state.authReducer.typeOfLogin,
  actionsRunning: state.authReducer.actionsRunning,
  successCreateReport: state.reportsReducer.successCreateReport,
  toBeNotifiedList: state.reportsReducer.toBeNotifiedList,
})

const mapDispatchToProps = {
  logoutUser,
  chageStateLoadNotificationsList,
  chageStateLoadCitizensList,
  setPathnameCurrent,
  hideMessageModal,
  changeRole,
  changeStateSuccessCreateReport,
  getToBeNotified,
  setIntervalToBeNotified,
}

export default connect(mapStateToProps, mapDispatchToProps)(AppContent)