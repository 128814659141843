import React, { useState } from 'react';
import { Table } from 'semantic-ui-react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Cell, CellOptions } from '../../../../components/Table/CellComponent';
import { useEffect } from 'react';
import moment from 'moment'


const SentCell = styled(Table.Cell)`
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  padding: 10px !important;
  margin-left: 1rem;
`;

const Row = styled(Table.Row)`
  cursor: pointer !important;

  @media only screen and (max-width: 768px) {
    display: flex !important;
    flex-wrap: wrap;
    border-bottom: 1px solid #979e9f !important;
    padding: 0.5em 0em !important;
    
  }
`;

const ContainerCell = styled.div`
  position: relative;
  display: grid;
  margin: 4px;
`;

const ContainerSubject = styled.div`
  width: 88%;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const ContainerReviewed = styled.div`
  width: min-content;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  padding: 0px 4px 0px 4px;
  color: #22C1D0;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  position: absolute;
  right: 0;
`;

export const RowTableImproperClientsDetail = (props) => {
  const dispatch = useDispatch();
  const formatDate = (dateToTransform ) => {
    return moment(dateToTransform).utc().format('DD/MM/YYYY - h:mm a')
  }

  const redirectToImproperClientsDetail = () => {
    props.history.push({ pathname: '/improperClientsNotificationDetail/' + props.data.notificationID})
  };

  return (
    <Row onClick={redirectToImproperClientsDetail}>
      <SentCell width={3}>{props.data.documentNumber}</SentCell>
      <SentCell width={7}>
        <ContainerCell>
          <ContainerSubject>
            {props.data.subject}
          </ContainerSubject>
          {!!props.data.isReviewed
          ?
            <ContainerReviewed>
              Revisado
            </ContainerReviewed>
          :
            ''
          }
        </ContainerCell>
      </SentCell>
      <SentCell width={3}>{formatDate(props.data.dateReportError)}</SentCell>
    </Row>
  );
};

RowTableImproperClientsDetail.propTypes = {
  data: PropTypes.object.isRequired,
};

export default RowTableImproperClientsDetail;
