import React, { Component } from 'react'
import {SearchBarGenericButtonType} from '../../SearchBarGeneric'
import PopUpFilterAgreements from '../../../Search/SearchAgreements/PopUpFilterAgreements';

class ContainerSearchAgreementsSuperAdmin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchBarState: "",
      popUpOrderState: "",
    };
  }
  render() {
    return (
      <SearchBarGenericButtonType 
        buttonType={'agreement'} 
        text={'Nueva vinculación'} 
        showButton={true} 
        textPlaceHolder={'Código de la vinculación'} 
        popUpOrderState={this.popUpOrderState} 
        searchBarState={this.searchBarState} 
        showAZ={this.props.showAZ} 
        dataFilter={<PopUpFilterAgreements reportType={this.props.reportType} providerType={this.props.providerType} />} 
        showExportButton={true}
        disabledExportButton={this.props.disabledExportButton}
        showAdvancedSearch={false}
        searchbarMaxWidth={this.props.searchbarMaxWidth}
        customWidthDiv2={this.props.customWidthDiv2}
      />
      
    )
  }
}

export default ContainerSearchAgreementsSuperAdmin
