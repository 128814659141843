import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import _ from 'lodash'

import {actionClearFilter, actionInitialPagination, activePagination} from '../../../actions/filterActions'
import {getProvidersV2} from '../../../actions/providerActions'
import RowTableProviders from '../RowTableProviders'
import GeneralTable from '../../../components/Table/GeneralTable'

class Providers extends Component {

  static propTypes = {
    history: PropTypes.any.isRequired,
  }

  state = {
    loader: true,
    initialCount: 0,
    stateCount: true,
    columnData: [
      {name: "Nombre", width: 3, arrow: false},
      {name: "Documento", width: 2, arrow: false},
      {name: "Teléfono", width: 2, arrow: false},
      {name: "Email", width: 3, arrow: false},
      {name: "Representante Legal", width: 3, arrow: false},
      {name: "Activo", width: 3, arrow: false},
    ],
    init: true,
  }

  componentDidMount() {
    this.makeProvidersRequest(this.props.filterReducer)

  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.filterReducer, this.props.filterReducer) && !this.state.init) {
        this.makeProvidersRequest(this.props.filterReducer)
        this.setState({init: true, loader: true })
    }
  }

  makeProvidersRequest = (filterReducer) => {
    const body= {
      count: filterReducer.count,
      pag: filterReducer.pag,
      status: filterReducer.clientsAndProvidersFilter.clientAndProviderStatus,
      sort: this.orderTranslator(filterReducer.sort),
      textToSearch: filterReducer.searchBar[0]
    }
    this.props.getProvidersV2(body).then(() => {
      this.props.activePagination(false)
      this.setState({init: false, loader: false })
    })
  }

  orderTranslator = (order) => {
    const options = {
      'masReciente': {"_id": -1},
      'masAntiguo': {"_id": 1},
      'A-Z': {"name": 1},
      'Z-A': {"name": -1}
    }
    return options[order]
  }

  render() {
    return (
      <GeneralTable
        list={this.props.providers}
        typeList={this.props.elementsCount === 0 ? 'default' : 'providers'}
        listHeight={'providers'}
        headerTable={this.state.columnData}
        rowComponent={RowTableProviders}
        history={this.props.history}
        loader={this.state.loader}/>
    )
  }
}

Providers.propTypes = {
  providers: PropTypes.array,
  filterReducer: PropTypes.object
}
const mapStateToProps = (state) => ({
  providers: state.providerReducer.providers,
  filterReducer: state.filterReducer,
  elementsCount: state.providerReducer.elementsCount,
})

const mapDispatchToProps = {
  getProvidersV2,
  actionClearFilter,
  actionInitialPagination,
  activePagination,
}

export default connect(mapStateToProps, mapDispatchToProps)(Providers)