import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SimpleReactValidator from 'simple-react-validator'
import { reactValidatorOptions } from '../../../helpers/simpleReactValidator'
import { Form } from 'semantic-ui-react'
import InputFormUser from './InputAnimated/InputFormUser'
import InputFormDropdown from './InputAnimated/InputFormDropdown'
import { getCertificateUserV3 } from '../../../actions/usersActions'
import {getProviderDetail} from '../../../actions/providerActions'
import { withRouter } from 'react-router-dom'
import { refreshStateModule, warningFormModal } from '../../../actions/modalActions'
import { postCreateEmployee, activeFormClient } from '../../../actions/clientActions'

import styled from 'styled-components'
import { getGeographicList } from "../../../actions/defaultListActions";

const Divider = styled.div`
  width: 500px;
  margin: auto;
  @media only screen and (max-width: 992px) {
    width:90%;
  }
`
const CustomForm = styled(Form)`
  display: flex !important;
  flex-direction: column !important;
`
const ContainerButtons = styled.div`
  display: flex;
  margin-top: 15px;
  justify-content: flex-end;
  margin-bottom: 1rem;
`
const ButtoCancel = styled.button`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #22C1D0;
  text-align: center;
  width: 128px;
  height: 48px;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  background: #FFFFFF;
  margin-right: 8px;
  cursor: pointer;
`
const ButtoSave = styled.button`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #FFFFFF;
  text-align: center;
  width: 128px;
  height: 48px;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  background: #22C1D0;
  margin-left: 8px;
  cursor: pointer;
`
const ButtonContinue = styled.button`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #FFFFFF;
  text-align: center;
  width: 128px;
  height: 48px;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  background: #22C1D0;
  margin-left: 8px;
  cursor: pointer;
`
const ContainerMessage = styled.div`
  width: 100%;
`
const Message = styled.div`
  width:100%;
  padding: 10px 28px 10px 28px;
  background: #E35F001A;
  border: 1px solid #E35F00;
  border-radius: 4px;
  color: #E35F00;
`
const MessageText = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
`
const initialState = {
  documentType: '',
  documentNumber: '',
  firstName: '',
  lastName: '',
  address: '',
  email: '',
  role: '',
  isChangedState: false,
  stateFormEditUser: true,
  display: false
}

const optionsDocumentType = [
  { key: '', text: '', value: '' },
  { key: 'C.C', text: 'Cédula de Ciudadania', value: 'C.C' },
]

export class FormCreateUsers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...initialState
    }
    this.formValidator = new SimpleReactValidator(reactValidatorOptions)
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    const id = sessionStorage.getItem("id")
    if(this.props.roleUser === '61b36e2fc34e22351bf8cfb4'){
      this.props.getProviderDetail(id)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.activeForm === false && this.props.warningModal === true) {
      this.props.activeFormClient()
    }

    if (this.props.moduleType === true) {
      if(this.props.roleUser === '61b36e2fc34e22351bf8cfb4'){
        this.props.history.goBack()
        this.props.refreshStateModule()
      } else {
      this.props.history.push('/users')
      this.props.refreshStateModule()
      }
    }
  }

  handleChange(checked) {
    this.setState({ status: checked })
  }

  handleOnChange = (event, { value }) => {
    const name = event.currentTarget.parentNode.parentNode.id
    this.setState({
      [name]: value,
      stateFormEditUser: false
    })
  }

  onChange = e => {
    if (this.state.display && e.target.id === 'documentNumber') {
      this.setState({ ...initialState, documentNumber: e.target.value, documentType: this.state.documentType, stateFormEditUser: false })
    } else {
      this.setState({ ...this.state, [e.target.id]: e.target.value, stateFormEditUser: false })
    }
  }

  handleCancel = () => {
    this.formValidator.hideMessages()
    this.forceUpdate()
    this.props.warningFormModal({ type: 'warningLeaveUser' })
  }

  makeSaveUserReq = () => {
    const { role } = this.state

    const body = {
      "role": role,
      "preRegisterUserSerialID": this.props.userCertificate.preRegisterUserSerialID
  }
    this.props.postCreateEmployee(body)
  }
  handleSend = (e) => {
    e.preventDefault()
    if (this.formValidator.allValid()) {
      this.makeSaveUserReq()
    } else {
      this.formValidator.showMessages()
      this.forceUpdate()
    }
  }

  makeSearchDocument = () => {
    const { documentType, documentNumber } = this.state
    const idClients = sessionStorage.getItem("id")

    const body = {
      "documentType": documentType,
      "documentNumber": documentNumber,
    }

    const body2 = {
      "documentType": documentType,
      "documentNumber": documentNumber,
      "companySerialID": idClients
    }

    this.props.getCertificateUserV3(this.props.roleUser === '61b36e2fc34e22351bf8cfb4' ? body2 : body).then(() => {
      if (!!this.props.haveCertificate) {
        sessionStorage.setItem("documentNumberUser", documentNumber)
        this.setState({ display: true })
      }
    })
  }

  handleSearchDocument = (e) => {
    e.preventDefault()
    if (this.formValidator.allValid()) {
      this.makeSearchDocument()
    } else {
      this.formValidator.showMessages()
      this.forceUpdate()
    }
  }

  render() {
    return (<>

      <Divider>
        <CustomForm>
          <InputFormDropdown

            options={optionsDocumentType}
            placeholder={'Tipo de documento'}
            value={this.state.documentType}
            handleOnChange={this.handleOnChange}
            id={'documentType'}
            validator={this.formValidator}
            validateOptions={'required'}
            error={this.state.errors}
          />
          <div style={{ margin: '0 0 1em' }}></div>
          <InputFormUser
            id={'documentNumber'}
            label={'No. documento'}
            onChange={this.onChange}
            value={this.state.documentNumber}
            validator={this.formValidator}
            validateOptions={['required', 'numeric', { min: 4 }, { max: 15 }]}
            error={this.state.errors}
          />
          {this.state.display && <>
            <InputFormUser
              id={'firstName'}
              label={'Nombres y apellidos'}
              type={'text'}
              onChange={this.onChange}
              value={this.props.userCertificate.firstName + ' ' + this.props.userCertificate.lastName}
              validator={this.formValidator}
              validateOptions={['required', 'nameOfCompany', { min: 1 }, { max: 50 }]}
              error={this.state.errors}
              readOnly={true}
              disabled={true}
            />
            <InputFormUser
              id={'address'}
              label={'Dirección'}
              type={'text'}
              onChange={this.onChange}
              value={this.props.userCertificate.address}
              validator={this.formValidator}
              validateOptions={['required', 'address', { min: 1 }, { max: 200 }]}
              error={this.state.errors}
              readOnly={true}
              disabled={true}
            />
            <InputFormUser
              id={'email'}
              label={'Email'}
              type={'text'}
              onChange={this.onChange}
              value={this.props.userCertificate.email}
              validator={this.formValidator}
              validateOptions={['required', 'email', { min: 6 }, { max: 200 }]}
              error={this.state.errors}
              readOnly={true}
              disabled={true}
            />
            <InputFormDropdown
              options={this.props.role}
              placeholder={'Rol'}
              value={this.state.role}
              handleOnChange={this.handleOnChange}
              id={'role'}
              validator={this.formValidator}
              validateOptions={'required'}
              error={this.state.errors}
            />
            <ContainerButtons>
              <ButtoCancel
                onClick={this.handleCancel}
              >Cancelar
              </ButtoCancel>
              <ButtoSave
                disabled={this.state.stateFormEditUser}
                className='putButton'
                onClick={this.handleSend}
              >Guardar
              </ButtoSave>
            </ContainerButtons>
          </>
          }
          {!this.state.display && <>
            <ContainerMessage style={{ display: (!!this.props.haveCertificate) ? 'none' : 'block' }}>
              <Message>
                <MessageText>Este documento no cuenta con un certificado vinculado. </MessageText>
              </Message>
            </ContainerMessage>
            <ContainerButtons>
              <ButtoCancel
                onClick={this.handleCancel}
              >Cancelar
              </ButtoCancel>
              <ButtonContinue
                disabled={this.state.stateFormEditUser}
                className='putButton'
                onClick={this.handleSearchDocument}
              >Continuar
              </ButtonContinue>
            </ContainerButtons>
          </>}
        </CustomForm>
      </Divider >
    </>
    )
  }
}

FormCreateUsers.propTypes = {
  postCreateEmployee: PropTypes.func.isRequired,
  savedClient: PropTypes.array,
  moduleType: PropTypes.bool,
  activeFormClient: PropTypes.func.isRequired,
  activeForm: PropTypes.bool,
  warningModal: PropTypes.bool,
  geographicList: PropTypes.array,
  getGeographicList: PropTypes.func,
  userCertificate: PropTypes.object,
  haveCertificate: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  savedClient: state.clientReducer.savedClient,
  moduleType: state.modalReducer.refreshModule,
  activeForm: state.clientReducer.activeForm,
  warningModal: state.modalReducer.warningModal.status,
  geographicList: state.defaultListReducer.geographicList,
  userCertificate: state.userReducer.userCertificate,
  haveCertificate: state.userReducer.haveCertificate,
  roleUser: state.authReducer.user.role,
  typeProvider: state.providerReducer.detailProvider.type,
  getCertificateUserV3: PropTypes.func.isRequired
})

const mapDispatchToProps = {
  warningFormModal,
  activeFormClient,
  refreshStateModule,
  getGeographicList,
  getProviderDetail,
  getCertificateUserV3,
  postCreateEmployee,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FormCreateUsers))
