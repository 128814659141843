import React from 'react'
import styled from 'styled-components'
import IconSvg from './CustomSvg'

const Svg = styled(IconSvg)` 
  width: 25px;
  height: 29px;
`

export const AttachSvgIcon = ({ className }) => (
  <Svg viewBox="-7 -8 30 30" className={className}>
    <path
      fill="currentColor"
      d="M10.3636 5.45455V18C10.3636 19.1573 9.9039 20.2672 9.08556 21.0856C8.26722 21.9039 7.15731 22.3636 6 22.3636C4.84269 22.3636 3.73278 21.9039 2.91444 21.0856C2.0961 20.2672 1.63636 19.1573 1.63636 18V4.36364C1.63636 3.64032 1.9237 2.94663 2.43516 2.43516C2.94663 1.9237 3.64032 1.63636 4.36364 1.63636C5.08695 1.63636 5.78065 1.9237 6.29211 2.43516C6.80357 2.94663 7.09091 3.64032 7.09091 4.36364V15.8182C7.09091 16.1075 6.97597 16.385 6.77139 16.5896C6.5668 16.7942 6.28933 16.9091 6 16.9091C5.71067 16.9091 5.4332 16.7942 5.22861 16.5896C5.02403 16.385 4.90909 16.1075 4.90909 15.8182V5.45455H3.27273V15.8182C3.27273 16.5415 3.56006 17.2352 4.07153 17.7467C4.58299 18.2581 5.27668 18.5455 6 18.5455C6.72332 18.5455 7.41701 18.2581 7.92847 17.7467C8.43994 17.2352 8.72727 16.5415 8.72727 15.8182V4.36364C8.72727 3.20633 8.26753 2.09642 7.44919 1.27808C6.63085 0.459739 5.52094 0 4.36364 0C3.20633 0 2.09642 0.459739 1.27808 1.27808C0.459739 2.09642 0 3.20633 0 4.36364V18C0 19.5913 0.632141 21.1174 1.75736 22.2426C2.88258 23.3679 4.4087 24 6 24C7.5913 24 9.11742 23.3679 10.2426 22.2426C11.3679 21.1174 12 19.5913 12 18V5.45455H10.3636Z"
    />
  </Svg>
)
