import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { Modal } from 'semantic-ui-react'
import styled from 'styled-components'

import { warningFormModal, warningFormModalWrite  } from '../../../actions/modalActions'
import {deleteItemForMassive, deleteRecipientsFormMasive} from "../../../actions/writerActions";
import {saveDraftV2, deleteDraftRecipients} from '../../../actions/draftsActions';
import { ReactComponent as LeaveIcon } from '../../../assets/icons/leaveIcon.svg'
import '../../Modal/messageModal.css'
import PropTypes from "prop-types"
import { CHANGE_STATE_WARNING_FORM_MODAL_WRITE } from '../../../actions/actionsUtilities/types'

const ModalElement = styled(Modal)`
  height: 446px !important;
  padding: 40px !important;
  width: 477px !important;
`
const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const ModalMessage = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin-top: 32px;
`
const ModalActions = styled.div`
  margin-top: 32px;
`
const Texto = styled.p`
  color: #133F4F;
  font-family: Roboto;
  font-weight: 700;
  font-size: 20px;
  font-style: normal;
  line-height: 23px;
  text-align: center;
  margin-bottom: 15px !important;
`
const Button = styled.button`
  background: #FFFFFF;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  color: #22C1D0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  width: 71px;
  height: 42px;
  margin-Right: 16px;
`
const Button2 = styled.button`
  background: #22C1D0;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  color: #FFFFFF;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  width: 71px;
  height: 42px;
  margin-Right: 16px;
  
`

const WarningModal = (props) => {
        const modalData = (type) => {
        const data = {
            warningDeleteMassiveFor: {
                icon: <LeaveIcon />,
                InitialMessage: "¡Vas a eliminar los registros cargados!",
                message: 'Ten en cuenta que todas las etiquetas y archivos adjuntos ligados a estos registros se eliminarán.',
                message2: '¿Realmente deseas hacerlo?',
                buttonMessage: 'Si',
                buttonMessage2: 'No'
            },
        }
        return data[type]
    }
    const dispatch = useDispatch()
    const onClose = () => {
        dispatch(warningFormModalWrite({ type: 'warningDeleteMassiveFor' }))
    }

    const handleOnLoad = () => {
        dispatch(deleteItemForMassive())
        dispatch(deleteDraftRecipients()).then(()=>{
            dispatch(saveDraftV2(props.draftData));
        })
        dispatch(warningFormModalWrite({ type: 'warningDeleteMassiveFor' }))
    }


    const data = modalData(props.type)
    return (
        <ModalElement
            open={props.modalState.status}
            onClose={onClose}
        >
            <ModalContent>
                {!!data.icon
                    && data.icon}
                {data.multipleMessages && !!props.modalState.message && !!props.modalState.message.length &&
                    props.modalState.message.map((message) => (
                        <>
                            <ModalMessage>
                                {message}
                            </ModalMessage>
                            <br />
                        </>
                    ))
                }
                {!data.multipleMessages &&
                    <ModalMessage>
                        <Texto>{data.InitialMessage}</Texto>
                        {!props.modalState.message
                            ? data.message
                            : props.modalState.message}
                        <br />
                        <br />
                        {!props.modalState.message
                            ? data.message2
                            : props.modalState.message}
                    </ModalMessage>
                }
                {
                    <ModalActions>
                        <Button
                            onClick={handleOnLoad}
                        >
                            {data.buttonMessage}
                        </Button>
                        <Button2
                            onClick={onClose}
                        >
                            {data.buttonMessage2}
                        </Button2>
                    </ModalActions>
                }
            </ModalContent>
        </ModalElement>
    )
}
WarningModal.propTypes = {
    modalState: PropTypes.object,
    warningFormModal: PropTypes.func,
    activeForm: PropTypes.bool,
    deleteItemForMassive: PropTypes.func,
}
const mapStateToProps = (state) => ({
    modalState: state.modalReducer.warningModalWrite,
    activeForm: state.clientReducer.activeForm,
    activeFormProvider: state.providerReducer.activeForm,
    activeFormAgreements: state.agreementReducer.activeForm,
    draftData : state.draftReducer.toSaveDraft,
})

const mapDispatchToProps = {
    warningFormModal,
    deleteItemForMassive,
    deleteItemForMassive,
    deleteRecipientsFormMasive,
    saveDraftV2,
}
const ShowTheLocationWithRouter = withRouter(WarningModal);

export default connect(mapStateToProps, mapDispatchToProps)(ShowTheLocationWithRouter);
