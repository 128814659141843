import React, { useEffect, useState }  from 'react'
import PropTypes from 'prop-types'
import Header from './Header'
import Footer from './Footer'
import styled from 'styled-components'
import MassiveLoadContent from './MassiveLoadContent'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { sendMassiveFile, sendMassiveFileV2 } from '../../../actions/modalActions'
import { Form } from 'semantic-ui-react'
import { reactValidatorOptions } from '../../../helpers/simpleReactValidator'
import useValidator from '../../../helpers/simpleReactValidatorHook'

const ContainerScroll = styled.div`
  width: 100%;
  height: 89.3vh;
  overflow-y: scroll;

  ::-webkit-scrollbar-track {
    background: #CCCCCC;
  }

  ::-webkit-scrollbar-thumb {
    background: #9D9D9C !important;
  }

  ::-webkit-scrollbar:vertical {
    width: 5px;
  }
`
const CustomForm = styled(Form)`
  display: flex !important;
  flex-direction: column !important;
`

const MassiveLoad = (props) => {
  const {columnList, fileSerialID} = useSelector((state) => state.writerReducer.resultValidateFileMassive)
  const fileInfo = useSelector((state) => state.writerReducer.fileMassive)
  const [companyName, setCompanyName] = useState('')
  const dispatch = useDispatch()
  const history = useHistory()
  const [errors] = useState({})
  const [validator, showValidationMessage] = useValidator(reactValidatorOptions)

  useEffect(() => {
    dispatch(sendMassiveFileV2(fileInfo, history))
  }, [])

  return (
    <>
    <ContainerScroll>
      <CustomForm>
        <Header
          history={props.history}
          errors={errors}
          companyName={companyName}
          setCompanyName={setCompanyName}
          validator= {validator}
        />
        <MassiveLoadContent 
          data={columnList} 
          history={props.history} 
          errors={errors}
          validator= {validator}
        />
        <Footer 
          history={props.history}
          errors={errors}
          companyName={companyName}
          fileSerialID={fileSerialID}
          validator= {validator}
          showValidationMessage={showValidationMessage}
        />
      </CustomForm>
    </ContainerScroll>
    </>
  )
}

MassiveLoad.propTypes = {
  history: PropTypes.any.isRequired,
}

export default MassiveLoad