import React from 'react'
import PropTypes from 'prop-types'

import ContainerSearchClientsAndProviders from '../../../components/Search/SearchClientsAndProviders/ContainerSearchClientsAndProviders'
import AllClients from './AllClients'
import { useSelector } from 'react-redux';

const OperatorClients = (props) => {
  const clients = useSelector(state => state.clientReducer.clients)
  return (
    <>
      <ContainerSearchClientsAndProviders 
        textPlaceHolder={'Buscar' } 
        showButton={false} 
        showAZ={true}
        showExportButton={true} 
        disabledExportButton={!clients.length > 0} 
        showFilters={true}
        reportType={"LIST_CLIENTS"} 
        providerType={"CLIENT"}
        showAdvancedSearch={false}
      />
      <AllClients
        history={props.history}
      />
    </>
  )
}

OperatorClients.propTypes = {
  history: PropTypes.any.isRequired,
};

export default OperatorClients