import React from 'react'
import { connect } from 'react-redux'
import { Popup } from 'semantic-ui-react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom';
import { actionFilter, actionStarredFilter, actionFilterDraft, actionFilterReports } from '../../actions/filterActions'
import { actionFilterLogs } from '../../actions/filterDetailActions'
import ButtonIcon from '../Button/IconButton'
import { ReactComponent as Filter } from '../../assets/icons/filter.svg'
import { ReactComponent as Reviewed } from '../../assets/icons/reviewed.svg'
import './main.css'
import { changeStateSearchReportsModal } from '../../actions/modalActions';
import _ from 'lodash'

const Divider = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center
`
const Texto = styled.div`
  color: #7C7C74;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  @media only screen and (max-width: 750px) {
    display: none;
  }
`
const PopupFilter = styled(Popup)`
  background: #fff !important;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.25) !important;
  border-radius: 4px !important;
  padding: 0 !important;
  width: 210px !important;
  left: -1vw !important;
  border: none !important;

  &:before {
    position: sticky !important;
    display: none !important;
  }
`
const ButtonOption = styled.button`
  color: #7C7C74;
  border: 0;
  background: #fff;
  font-weight: normal;
  font-size: 14px;
  height: 32px;
  text-align: left;
  width: 100%;
`
const Text = styled.p`
  color: #7C7C74;
  font-weight: normal;
  font-size: 14px;
  padding-left: 40px
`
const ContainerOption = styled.div`
  padding: 8px 0px !important;
  position:absolute !important;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);

`

class PopUpFilterDetailGeneric extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      notificationState: '',
      reportType: '',
      logAction: '',
      starred: false,
      pathname: '',
    }
    this.changeAdvanced = false
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.options && this.props.options === 'logs') {
      if ((this.state.logAction !== prevState.logAction)) {
        this.props.actionFilterLogs(this.state.logAction)
      }
    }

    if (this.props.options && this.props.options === 'reports') {
      if ((this.state.reportType !== prevState.reportType)) {
        this.props.actionFilterReports(this.state.reportType)
      }
    }

    if (!_.isEqual(prevProps.draftsStatus, this.state.notificationState)) {
      this.props.actionFilterDraft(this.state.notificationState)
    }
  }

  toggle = () => {
    this.setState({ show: !this.state.show })
  }

  changeStatus = (status) => {
    if (status === 'STARRED') {
      this.setState({ notificationState: status, starred: true })
    } else {
      this.setState({ notificationState: status, starred: false })
    }
    this.setState({ show: !this.state.show })
  }

  changeReportStatus = (status) => {
    this.setState({ reportType: status, starred: false })
    this.setState({ show: !this.state.show })
  }
  changeLogsAction = (status) => {
    this.setState({ logAction: status, starred: false })
    this.setState({ show: !this.state.show })
  }
  render() {
    const { location } = this.props
    return (
      <Divider>
        <PopupFilter position='bottom left' on='click' pinned open={this.state.show} onClose={this.toggle} trigger={
          <ButtonIcon onClick={this.toggle}>
            <Texto> Filtrar </Texto>
            <Filter />
          </ButtonIcon>}>

          {this.props.options && this.props.options === 'reports' ?
            <ContainerOption style={{ top: this.props.filterPosition }}>
              <ButtonOption className='buttonOptionHover' onClick={() => this.changeReportStatus('STATUS')}>
                {this.props.agreementStatusReducer === 'STATUS' && <Reviewed className='iconReReviewed' />}
                {this.props.agreementStatusReducer !== 'STATUS' ? <Text>Reporte de estado</Text> : 'Reporte de estado'}
              </ButtonOption>
              <ButtonOption className='buttonOptionHover' onClick={() => this.changeReportStatus('WRONG_NOTIFICATION')}>
                {this.props.agreementStatusReducer === 'WRONG_NOTIFICATION' && <Reviewed className='iconReReviewed' />}
                {this.props.agreementStatusReducer !== 'WRONG_NOTIFICATION' ? <Text>Notificaciones Erróneas</Text> : 'Notificaciones Erróneas'}
              </ButtonOption>
              <ButtonOption className='buttonOptionHover' onClick={() => this.changeReportStatus('LOG')}>
                {this.props.agreementStatusReducer === 'LOG' && <Reviewed className='iconReReviewed' />}
                {this.props.agreementStatusReducer !== 'LOG' ? <Text>Log de trazabilidad</Text> : 'Log de trazabilidad'}
              </ButtonOption>
            </ContainerOption>
            : this.props.options && this.props.options === 'logs' ?
              <ContainerOption>
                <ButtonOption className='buttonOptionHover' onClick={() => this.changeLogsAction('CREATE')}>
                  {this.state.logAction === 'CREATE' && <Reviewed className='iconReReviewed' />}
                  {this.state.logAction !== 'CREATE' ? <Text>Creación</Text> : 'Creación'}
                </ButtonOption>
                <ButtonOption className='buttonOptionHover' onClick={() => this.changeLogsAction('UPDATE')}>
                  {this.state.logAction === 'UPDATE' && <Reviewed className='iconReReviewed' />}
                  {this.state.logAction !== 'UPDATE' ? <Text>Edición</Text> : 'Edición'}
                </ButtonOption>
                {this.props.agreementsLogs &&
                  <ButtonOption className='buttonOptionHover' onClick={() => this.changeLogsAction('INACTIVE')}>
                    {this.state.logAction === 'INACTIVE' && <Reviewed className='iconReReviewed' />}
                    {this.state.logAction !== 'INACTIVE' ? <Text>Anulación</Text> : 'Anulación'}
                  </ButtonOption>

                }
                {this.props.showCleanFilter &&
                  <ButtonOption className='buttonOptionHover' onClick={() => this.changeLogsAction('')}>
                    {this.state.logAction === '' && <Reviewed className='iconReReviewed' />}
                    {this.state.logAction !== '' ? <Text>Limpiar filtro</Text> : 'Limpiar filtro'}
                  </ButtonOption>}
              </ContainerOption>
              :
              <ContainerOption>
                <ButtonOption className='buttonOptionHover' onClick={() => this.changeStatus('ADJUNTOS')}>
                  {this.state.notificationState === 'ADJUNTOS' && <Reviewed className='iconReReviewed' />}
                  {this.state.notificationState !== 'ADJUNTOS' ? <Text>Con archivos adjuntos</Text> : 'Con archivos adjuntos'}
                </ButtonOption>
                <ButtonOption className='buttonOptionHover' onClick={() => this.changeStatus('NOADJUNTOS')}>
                  {this.state.notificationState === 'NOADJUNTOS' && <Reviewed className='iconReReviewed' />}
                  {this.state.notificationState !== 'NOADJUNTOS' ? <Text>Sin archivos adjuntos</Text> : 'Sin archivos adjuntos'}
                </ButtonOption>
                <ButtonOption className='buttonOptionHover' onClick={() => this.changeStatus('MASIVO')}>
                  {this.state.notificationState === 'MASIVO' && <Reviewed className='iconReReviewed' />}
                  {this.state.notificationState !== 'MASIVO' ? <Text>Envío masivo</Text> : 'Envío masivo'}
                </ButtonOption>
                {this.props.showFeatured &&
                  <ButtonOption className='buttonOptionHover' onClick={() => this.changeStatus('DESTACADO')}>
                    {this.state.notificationState === 'DESTACADO' && <Reviewed className='iconReReviewed' />}
                    {this.state.notificationState !== 'DESTACADO' ? <Text>Destacada</Text> : 'Destacada'}
                  </ButtonOption>}
                {this.props.showCleanFilter &&
                  <ButtonOption className='buttonOptionHover' onClick={() => this.changeStatus('')}>
                    {this.state.notificationState === '' && <Reviewed className='iconReReviewed' />}
                    {this.state.notificationState !== '' ? <Text>Limpiar filtro</Text> : 'Limpiar filtro'}
                  </ButtonOption>}
              </ContainerOption>
          }
        </PopupFilter>
      </Divider>
    )
  }
}

const mapStateToProps = (state) => ({
  filter: state.filterReducer.filter,
  stateSearch: state.filterReducer.searchAdvanced.notificationState,
  tabFilter: state.filterReducer.tabFilter,
  agreementStatusReducer: state.filterReducer.reportsFilter.reportStatus,
  draftsStatus: state.filterReducer.draftsFilter.draftsStatus,
})

const mapDispatchToProps = {
  actionFilter,
  actionStarredFilter,
  actionFilterDraft,
  actionFilterReports,
  changeStateSearchReportsModal,
  actionFilterLogs
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PopUpFilterDetailGeneric))
