import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useIdleTimer } from 'react-idle-timer'
import { useDispatch } from 'react-redux'
import { logoutUser, getRefreshToken, changeStatusSession} from "../../actions/authActions"
import { deleteLoadNotificationItemV2 } from '../../actions/writerActions'
import { Cookies } from 'react-cookie';

const IdleTimerComponentsC = (props) => {

  const {id} = useSelector((state) => state.authReducer.user)
  const {citizenIsCharging, notificationsIsCharging} = useSelector((state) => state.writerReducer)
  const refreshT = sessionStorage.getItem("refreshToken")
  const { iat} = useSelector((state) => state.authReducer.user)
  const refreshTokenTime = new Date ( (iat +240) * 1000 ) 
  const { resultNotificationsProgress } = useSelector(state => state.writerReducer)

  const body = {
    id : id,
    refreshToken: refreshT
  }
  const dispatch = useDispatch()
  const [isActive, setIsActive] = useState(false)

  const onIdle = () => {
    const statusList = ['Completado', 'Incompleto', 'No cargado', 'Error']
    const body = {
      fileSerialIDList : resultNotificationsProgress.progress.filter((item) => statusList.includes(item.state)).map(item=>item.fileSerialID),
      newVisibility: false
    }
    if(body.fileSerialIDList.length >0){
      dispatch(deleteLoadNotificationItemV2(body))
    }
    onLogout()
  }

  const onAction = () => {
    setIsActive(true)
  }

  const {getRemainingTime } = useIdleTimer({
    onIdle,
    onAction,
    timeout: 300000, // 5 minutos
    throttle: 500,
  }) 

  useEffect(() => {
    const interval = setInterval(() => {

      if(new Date() >= refreshTokenTime){
                onRefreshToken()
      } 
    }, 30000) //30 segundos
    return () => {
      clearInterval(interval)
    }
  }, [refreshT,isActive])

  useEffect(() => {
    if(isActive === false){
      if(citizenIsCharging === true || notificationsIsCharging === true){
        setIsActive(true)
      }
    }
  }, [isActive])

const onRefreshToken = () => {
    if(isActive === true || citizenIsCharging === true|| notificationsIsCharging === true){
    dispatch(getRefreshToken(body))
  }
}

const onLogout = () => {
  if(!citizenIsCharging || !notificationsIsCharging){
    const myCookie= new Cookies()
    dispatch(logoutUser(props.history, myCookie))
  }
}

  return (
    <div></div>
  )
}

export default IdleTimerComponentsC