import React, { useEffect } from 'react'
import { Form } from 'semantic-ui-react'
import './main.css'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

const FormCustom = styled(Form.Select)`
  .dropdown.icon{
    display: none !important;
    color: red !important;
  }
`

export const SearchDashboard = ({ valueSearch, options, handleAutocompleteSearch, textPlaceHolder = 'Vinculación, operador o cliente', onChangeHandler }) => {

  return (
    <>
      <FormCustom
        id='searchFormDashboard'
        options={options}
        search
        allowAdditions={false}
        className={'dropdownSearch'}
        placeholder={textPlaceHolder}
        selectOnBlur={false}
        onSearchChange={handleAutocompleteSearch}
        onChange={onChangeHandler}
        onSubmit={handleAutocompleteSearch}
        value={valueSearch}
      />
    </>
  )
}
