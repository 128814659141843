import React, { Component } from "react";
import { connect } from "react-redux";
import styled from 'styled-components'

import { ReactComponent as PreviewHeader } from "../../../assets/icons/write/Group-23.svg";
import { ReactComponent as PreviewButton } from "../../../assets/icons/write/Group-24.svg";
import { ReactComponent as Document } from "../../../assets/icons/write/document.svg";
import { ReactComponent as DocumentDots } from "../../../assets/icons/write/document-dots.svg";
import { ReactComponent as BackgroudButterflies } from "../../../assets/icons/write/butterflies.svg";
import { ReactComponent as NotificationPill } from "../../../assets/icons/write/notification-pill.svg";
import { ReactComponent as NotificationPillDone } from "../../../assets/icons/write/notification-pill-done.svg";
import { ReactComponent as DownArrow } from "../../../assets/icons/write/down-arrow.svg";
import { ReactComponent as SeparatorLine } from "../../../assets/icons/write/separator-line.svg";
import { ReactComponent as IconBack } from '../../../assets/icons/write/bx_bx-arrow-back.svg'
import { ReactComponent as IconShare} from '../../../assets/icons/write/share_black_24dp 1.svg'
import { ReactComponent as IconMenu} from '../../../assets/icons/write/iconMenuPoints.svg'
import DOMPurify from 'dompurify'
import PropTypes from "prop-types";
import _ from "lodash";
import { changeDatePreview } from "../../../actions/writerActions";

const HeaderWsp = styled.div`
  background: linear-gradient(180deg, #0A4A83 0%, #003057 100%, #003057 100%);  
  max-width: 313px;
  max-height: 48px;
  width:100%;
  height: 48px;
  border-radius: 3px 3px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px;
`

const DividerHeaderWsp = styled.div`
  display: flex;
  gap: 20px;
`

const IconBackWsp = styled(IconBack)`
  display: flex;
`
const IconShareWsp = styled(IconShare)`
  display: flex;
  width: 21px;
`
const IconMenuPoints = styled(IconMenu)`
  display: flex;
`
class PreviewMessage extends Component {
  async componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.statusFormNotification, this.props.statusFormNotification)) {
      let date = new Date();
      await this.props.changeDatePreview(
        date.toLocaleString("en-AU").replace(",", " - ")
      );
    }
  }

  validateHTML = (value)=>{

  }

  render() {
    return (
      <>
        {this.props.statusFormNotification === true && (
          <>
            <div className={"contents-previewMessage " +
            (!this.props.checkboxHeaderDisabled &&
              !this.props.form.notificationSignature
                ? ""
                : "active-check-notificationSigned")
            }
            >
           
              <HeaderWsp>
                <DividerHeaderWsp>
                  <IconBackWsp/>
                </DividerHeaderWsp>
                <DividerHeaderWsp>
                  <IconShareWsp/>
                  <IconMenuPoints/>
                </DividerHeaderWsp>       
              </HeaderWsp>
              <div className="notification-message-container-pro">
                <div className={!this.props.form.notificationSignature ?"notification-date onlyDate":'notification-date'}>
                {!this.props.form.notificationSignature 
                    ? ''
                    :<NotificationPill/>
                }
                <div className="text-date">
                  {this.props.datePreview}
                </div>
                </div>
                <div className="notification-title-container">  
                  <div className="notification-title">
                    {this.props.user.companyID.name}
                  </div>
                  <div className="down-arrow-title">
                    <DownArrow width="100%" height="100%" />
                  </div>
                </div>

                <div className="notification-subtitle">
                  {this.props.form.subject}
                </div>
                <div className="separator-line">
                    <SeparatorLine width="100%" height="100%" />
                </div>
                <div className="notification-description">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(this.props.form.textEditor),
                    }}
                  />
                </div>
                {!_.isEmpty(this.props.form.files) && this.props.form.textEditor &&
                <div className="separator-line">
                    <SeparatorLine width="100%" height="100%" />
                </div>
                }
                <div className="notification-documents">
                  {Object.keys(this.props.specificFiles)?.map((key) => (
                    <div className="element-document" key={key}>
                      <div className="document-icon">
                        <Document />
                      </div>
                      <div className="document-name">
                        {this.props.specificFiles[key].name}
                      </div>
                      <div className="document-options">
                        <DocumentDots />
                      </div>
                    </div>
                  ))}
                  {Object.keys(this.props.form.files)?.map((key) => (
                    <div className="element-document" key={key}>
                      <div className="document-icon">
                        <Document />
                      </div>
                      <div className="document-name">
                        {this.props.form.files[key].name}
                      </div>
                      <div className="document-options">
                        <DocumentDots />
                      </div>
                    </div>
                  ))}
                </div>
                <div className={'notification-buttons-sign ' +
                (!this.props.form.notificationSignature
                    ? "close"
                    : "")
                }
                >
                  <button className='button-sign-rejected'>RECHAZAR</button>
                  <button className='button-sign-active'>FIRMAR</button>
                </div>
              </div>
            </div>
            
          </>
        )}
      </>
    );
  }
}

PreviewMessage.propTypes = {
  checkboxHeaderDisabled: PropTypes.bool,
  changeDatePreview: PropTypes.func,
  datePreview: PropTypes.string,
  form: PropTypes.object,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  form: state.writerReducer.notificationForm,
  specificFiles: state.writerReducer.listDocumentsSpecific,
  checkboxHeaderDisabled: state.writerReducer.checkboxHeaderDisabled,
  datePreview: state.writerReducer.datePreview,
  user: state.authReducer.user,
  recipientDataState: state.writerReducer.recipientData,
  statusFormNotification:  state.writerReducer.statusFormNotification
});

const mapDispatchToProps = {
  changeDatePreview: changeDatePreview,
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewMessage);
