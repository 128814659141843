import React, { Component } from 'react'
import { Modal } from 'semantic-ui-react'
import styled from 'styled-components'
import { connect } from 'react-redux'

import { openDateModal, closeDateModal } from '../../../actions/modalActions'
import { actionSearchAdvanced, inactiveViewMix, activeViewMix } from '../../../actions/filterActions'
import { ReactComponent as Close } from '../../../assets/icons/close.svg'
import FormFilterUserDocumentsAndDocuments from './FormFilterUserDocumentsAndDocuments/FormFilterUserDocumentsAndDocuments'
import DateFilterUserDocumentsAndDocuments from './DateFilterUserDocumentsAndDocuments/DateFilterUserDocumentsAndDocuments'
import { ModalFilterSignatureSelection } from '../SearchAttachment/AdvancedSearchAttachment/ModalFilterSignatureSelection'

const Divider = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const Texto = styled.p`
  color: #000000;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`
const TextoHide = styled.p`
  display: none;
  color: #000000;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  margin: 0 0 0em;
  @media only screen and (max-width: 768px) {
    display: flex;
  }
`
const TextoHide2 = styled.p`
  display: none;
  color: #000000;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  padding: 10px 0 0 0;
  @media only screen and (max-width: 768px) {
    display: flex;
  }
`
const Texto2 = styled.p`
  color: #000000;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  padding: 10px 0 0 0;
  @media only screen and (max-width: 1024px) {
    display: none;
  }
`
const Divider2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-block: 15px;
`

const ButtonDeleteFilter = styled.button`
  color: #22C1D0;
  border: none;
  background: #FFFFFF;
  text-decoration: underline;
  padding-right: 64px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    padding-right: 10px;
    width: 47%;
  }
`
const ButtonStartFilter = styled.button`
  color: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #22C1D0;
  background: #22C1D0;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  height: 48px;
  width: 193px;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    padding-right: 2%;
    width: 47%;
  }
`
const ModalDate = styled.div`
  width:100%;
  padding-top: 0px !important;
  @media only screen and (max-width: 1024px) {
    width:100% !important;
    padding-left:0 !important;
}
`

const ModalSent = styled.div`
  width: 80% !important;
  max-width: 854px;
  min-height: 575px;
  height: auto; 
  position: relative;
  padding: 20px 27px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  margin-top: 0rem;
  margin-left: 10rem;
  align-items:center;
  @media only screen and (max-width: 1200px) {
    margin-left: 0;
  }
  @media only screen and (max-width: 768px) {
    width: 100% !important;
    max-height: unset;
    height: -webkit-fill-available;
    margin: -16px 0 0 0 !important;
    
  }
`

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-center; 
  justify-content: center;
  align-items: center;
  z-index:4000;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 25.76px;
  right: 24px;
  border: none;
  background: none;
  cursor: pointer;
`;

class ModalFilterDateUserDocumentsAndDocuments extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userDocument: true,
      document: false,
      notificationState: '',
      showWarning: false,
      date: {},
      selected:''
    };
  }


  componentDidUpdate(prevProps, prevState) {
    if (prevProps.stateSearch !== this.props.stateSearch) {
      this.setState({ notificationState: this.props.stateSearch })
    }
  }

  handleOnChange = (e, { value }) => {
    this.setState({
      notificationState: value
    }, () => { })
  }

  timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  toggleCheckbox = async (e) => {
    let name = e.target.id;
    if (name === 'userDocument') {
      if (this.state.userDocument && !this.state.document) {
        this.setState({ ...this.state, showWarning: true })
        await this.timeout(3000)
        this.setState({ ...this.state, showWarning: false })
      } else {
        this.setState({
          [name]: !this.state[name],
          notificationState: ''
        })
      }
    } else if (name === 'document') {
      if (this.state.document && !this.state.userDocument) {
        this.setState({ ...this.state, showWarning: true })
        await this.timeout(3000)
        this.setState({ ...this.state, showWarning: false })
      } else {
        this.setState({
          [name]: !this.state[name],
          notificationState: ''
        })
      }
    }
  }

  reset = () => {
    this.setState({
      userDocument: true,
      document: false,
      notificationState: '',
      date: {}
    })
  }

  setDateState = (date) => {
    this.setState({ date: date })
  }

  orderTranslator = (order) => {
    const options = {
      'masReciente': { "lastModification": -1 },
      'masAntiguo': { "lastModification": 1 },
      'A-Z': { "nameElement": 1 },
      'Z-A': { "nameElement": -1 }
    }
    return options[order]
  }

  signedFilterTranslator = (order) => {
    const options = {
      'REQUIRED_SIGNATURE': true,
      'NO_REQUIRED_SIGNATURE': false
    }
    return options[order]
  }

  makeBodyRequest = () => {
    const body = {
      pag: 1,
      sort: 'masReciente',
      searchAdvanced: {
        date: this.state.date,
        notificationState: this.state.notificationState,
        typeSearch: this.state.userDocument === true && this.state.document === false ? '1' : this.state.userDocument === false && this.state.document === true ? '2' : this.state.userDocument === true && this.state.document === true ? '3' : ''
      }
    }
    if(body.searchAdvanced.typeSearch === '2' && this.state.selected !== ''){
      body.searchAdvanced.signDocumentFilter = this.signedFilterTranslator(this.state.selected)
    }
    this.props.actionSearchAdvanced(body)
    this.props.closeDateModal()
  }

  makeBodyRequestDeleteFilter = () => {
    const body = {
      pag: 1,
      sort: 'masReciente',
      searchAdvanced: {
        date: {
          startDate: "",
          endDate: ""
        },
        notificationState: ''
      }
    }
    this.setState({
      selected: ''
    })
    this.props.actionSearchAdvanced(body)
    this.props.closeDateModal()
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.makeBodyRequest()
    this.props.activeViewMix()
  }

  handleDeleteFilter = (e) => {
    e.preventDefault()
    this.makeBodyRequestDeleteFilter()
    this.reset()
    if (!!this.props.filter.searchBar[0] === false) {
      this.props.inactiveViewMix()
    }
  }

  validClose = () => {
    this.setState({
      notificationState: this.props.stateSearch
    })
    return this.props.closeDateModal()
  }

  setSelected = (option) =>{
    this.setState({
      selected: option
    })
  }


  render() {
    return (
      <>
        {this.props.modalState &&
          <Overlay>
            <ModalSent >
              <ModalDate>
                <Divider>
                  <TextoHide>Búsqueda avanzada</TextoHide>
                  <Texto>Mostrar</Texto>
                  <CloseButton onClick={this.validClose}>
                    <Close />
                  </CloseButton>
                </Divider>
                <TextoHide2>Mostrar</TextoHide2>
                <div >
                  <FormFilterUserDocumentsAndDocuments
                    state={this.state}
                    handleOnChange={this.handleOnChange}
                    handleSubmit={this.handleSubmit}
                    toggleCheckbox={this.toggleCheckbox}
                  />
                  {!this.state.userDocument && this.state.document &&
                  <>
                    <Texto>Ver documentos</Texto>
                    <ModalFilterSignatureSelection selected={this.state.selected} setSelected={this.setSelected}/>
                  </>

                  }
                  <Texto2>Período de tiempo</Texto2>
                </div>
                <DateFilterUserDocumentsAndDocuments setDateState={this.setDateState} />
              </ModalDate>
              <Divider2>
                <ButtonDeleteFilter onClick={this.handleDeleteFilter}>
                  Borrar filtros
                </ButtonDeleteFilter>
                <ButtonStartFilter onClick={this.handleSubmit}>
                  Aplicar filtro
                </ButtonStartFilter>
              </Divider2>
            </ModalSent>
          </Overlay>
        }
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  dateModal: state.modalReducer.dateModal,
  stateSearch: state.filterReducer.searchAdvanced.notificationState,
  dateSearch: state.filterReducer.searchAdvanced.date,
  filter: state.filterReducer,
  modalState: state.modalReducer.dateModal
})

const mapDispatchToProps = {
  actionSearchAdvanced,
  openDateModal,
  closeDateModal,
  inactiveViewMix,
  activeViewMix
}
export default connect(mapStateToProps, mapDispatchToProps)(ModalFilterDateUserDocumentsAndDocuments) 
