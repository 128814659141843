import React from 'react'
import {Form, Label, Message} from 'semantic-ui-react'
import styled from 'styled-components'
import {ReactComponent as Load} from "../../assets/icons/load.svg";


const ButtonTemplate = styled.button`
  background: #22C1D0;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  color: #FFFFFF;
  font-family: Roboto;
  font-weight: bold;
  font-size: 16px;
  height: 48px;
  margin-left: 56px;
  width: 264px;

  @media screen and (max-width:792px){
    width: 100%;
    margin: 16px auto;
    display: block;
 }
`

const CustomMessage = styled(Message)`
  background: #DEDD0026 0 0 no-repeat padding-box !important;
  border: 1px solid #DEDD00 !important;
  border-radius: 6px !important;
  opacity: 1 !important;
  color: #918B19 !important;
  padding-left: 1.5em !important;
  padding-top: 0.6em !important;
  padding-bottom: 0.6em !important;
`

class InputSendMassiveFile extends React.Component {
  ref = React.createRef()

  render() {
    const {
      filename,
      name,
      id,
      accept,
      onSelectFile,
      validator,
      validateOptions,
    } = this.props
    const errorValidator = validator.message(name, filename, validateOptions)
    return (
      <div>
        <Form.Group error={errorValidator}>
          <Form.Field width='three'>
            <ButtonTemplate
              labelPosition='right'
              onClick={(e) => {
                e.preventDefault()
                this.ref.current.click()
              }}
            >
              <Load style={{marginRight: "16px"}}/>
              Cargar plantilla masiva
            </ButtonTemplate>
            {
              errorValidator &&
              <Label pointing prompt>
                {errorValidator}
              </Label>
            }
          </Form.Field>
          <Form.Field width='thirteen'>
            {
              filename &&
              <CustomMessage compact>
                {filename}
              </CustomMessage>
            }
          </Form.Field>
          <input
            ref={this.ref}
            id={id}
            name={name}
            type="file"
            hidden
            accept={accept}
            onChange={(e) => onSelectFile(e)}
          />
        </Form.Group>
      </div>
    )
  }
}

export default InputSendMassiveFile