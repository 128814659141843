import React, { Component } from 'react'
import { connect } from 'react-redux'
import GeneralTable from '../../../../components/Table/GeneralTable';
import RowTableBags from './RowTableBags';

const bolsasData = [

]

class Bags extends Component {

  state = {
    loader: false,
    columnData: [
      { name: "Nombre bolsa", width: 2, arrow: false },
      { name: "Servicios de notificación", width: 3, arrow: false },
      { name: "Cantidad transacciones", width: 2, arrow: false },
      { name: "Fecha vencimiento", width: 2, arrow: false },
      { name: "Estado", width: 1, arrow: false },
      { name: "", width: 1, arrow: false },
    ]
  };

  render() {
    return (
      <GeneralTable
        list={bolsasData}
        typeList="bags"
        headerTable={this.state.columnData}
        rowComponent={RowTableBags}
        history={this.props.history}
        loader={this.state.loader}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  filterReducer: state.filterReducer,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Bags)