import React, {Component} from 'react'
import {Modal} from 'semantic-ui-react'
import styled, {css} from 'styled-components'
import {connect} from 'react-redux'
import {
  closeSendMassiveModal, loadingSendMassiveModal, loadResultsMassiveModal, showResultsMassiveModal,
  showStateErrorMessageSendMassiveModal, sendMassiveFileV2
} from '../../../actions/modalActions'
import {ReactComponent as CloseDark} from '../../../assets/icons/closeDark.svg'
import {ReactComponent as Download} from '../../../assets/icons/download.svg'
import ListDescription from './ListDescription'
import PropTypes from "prop-types";
import InputSendMassiveFile from "../../InputFile/InputSendMassiveFile";
import cogoToast from "cogo-toast"
import {toastOptions} from '../../../helpers/globalHelpers'
import SimpleReactValidator from "simple-react-validator";
import {reactValidatorOptions} from "../../../helpers/simpleReactValidator";
import {addMassiveRecipientsFileInfo, getRecipientv2} from "../../../actions/writerActions";
import ModalDescriptionResultSendMassive from "./ModalDescriptionResultSendMassive";
import LoadingModalDescription from "./LoadingModalDescription";
import {fileToBase64Clean} from "../../../helpers/filesLib";
import {v4 as uuidv4} from 'uuid';
import './style.css'

const CustomModal = styled(Modal)`
  ${props => props.modalheight &&
          css`
            height: ${props.modalheight};
          `}
  ${props => props.modalwidth &&
          css`
            width: ${props.modalwidth};`}
  @media screen and (max-width:792px){
    width: 100% !important;
    border-radius: 0 !important;
    height: 100vh !important;
    margin: 0 !important;
    overflow-y: auto;
  }
`
const Divider = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  margin-bottom: 16px;
  @media screen and (max-width:792px){
    position: absolute;
    top: 24px;
    right: 24px;
  }
`
const Texto = styled.p`
  color: #133F4F;
  font-weight: 900;
  font-size: 24px;
  font-style: normal;
  text-align: center;
  margin-top: 30px;
  @media screen and (max-width:792px){
    text-align: inherit;
    margin: 0;
  }
`
const Divider2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 56px;

  @media screen and (max-width:792px){
    width: 95%;
    margin:auto;
    display: block;
 }
`
const Divider3 = styled.div`
  display: contents;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 3%;
  padding-right: 9%;
  padding-left: 9%;
`
const ButtonPlanilla = styled.button`
  width: 233px;
  background: #22C1D0;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 16px;
  height: 48px;

  @media screen and (max-width:792px){
    width:100%;
  }
`
const ModalDescription = styled(Modal.Description)`
  margin: 16px;
  @media screen and (max-width:792px){
    margin: 24px;
  }
`

class ModalSendMassive extends Component {
  constructor(props) {
    super(props)
    this.validator = new SimpleReactValidator(reactValidatorOptions)
  }

  modalheight = () => {
    if (this.props.loadingMassiveModal)
      return `446px`
    else if (this.props.resultsMassiveModal.showResultsMassiveModal && this.props.resultsMassiveModal.incorrectData !== 0)
      return `446px`
    else if (this.props.resultsMassiveModal.showResultsMassiveModal && this.props.resultsMassiveModal.incorrectData === 0)
      return `370px`
    else
      return `674px`
  }
  modalwidth = () => {
    if (this.props.loadingMassiveModal)
      return `518px`
    else if (this.props.resultsMassiveModal.showResultsMassiveModal && this.props.resultsMassiveModal.incorrectData !== 0)
      return `518px`
    else if (this.props.resultsMassiveModal.showResultsMassiveModal && this.props.resultsMassiveModal.incorrectData === 0)
      return `405px`
    else
      return `862px`
  }

  calculateTotalRecipientsLoaded = (prevRecipients, nextRecipients) => {
    const totalRecipientsLoaded = prevRecipients + nextRecipients
    return totalRecipientsLoaded >= 100
  }

  showMassiveModalError = (correctData, incorrectData, allInfo, totalData, file) => {
    this.props.loadResultsMassiveModal({
      correctData: correctData,
      incorrectData: incorrectData,
      modalRecipients: allInfo,
      totalData: totalData,
      file: file
    })
  }

  getIdList = async (correctData, incorrectData, documentList, totalData, file) => {
    this.props.loadingSendMassiveModal(true)
    if (this.calculateTotalRecipientsLoaded(this.props.listIdsSendMassive.ids.length, totalData)) {
      this.props.loadingSendMassiveModal(false)
      this.showMassiveModalError(0, totalData, {}, totalData, file)
      cogoToast.info(`El archivo excede el número de destiantarios permitidos.`, toastOptions)
    } else {
      let allInfo
      let ids = []
      let backendRequest = []
      let fullData = []
      if (!!documentList && documentList.length)
        for (const documentNumber of documentList) {
          await this.props.getRecipientv2({documentNumber: documentNumber}).then(() => {
            const data = documentNumber + " " + this.props.recipientData.firstName + ' ' + this.props.recipientData.secondName + ' ' + this.props.recipientData.surname
            const obj = {
              documentNumber: documentNumber,
              firstName: this.props.recipientData.firstName,
              secondName: this.props.recipientData.secondName,
              surname: this.props.recipientData.surname,
              secondSurname: this.props.recipientData.secondSurname
            }
            ids.push(documentNumber + "_" + file.fileId)
            backendRequest.push(obj)
            fullData.push(data)
          })
        }
      allInfo = {ids: ids, fullData: fullData, backendRequest: backendRequest}
      this.props.loadingSendMassiveModal(false)
      this.props.loadResultsMassiveModal({
        correctData: correctData,
        incorrectData: incorrectData,
        modalRecipients: allInfo,
        totalData: totalData,
        file: file
      })
    }
    this.props.showResultsMassiveModal(true)
  }

  handleChangeFile = async (e) => {
    const file = e.target.files
    if (file.length) {
      const fileInfo = {
        fileId: uuidv4(),
        base64: await fileToBase64Clean(file[0].name, file[0]),
        fileName: file[0].name,
        fileExtension: file[0].type.split("/").pop(),
      }
      if (fileInfo.fileExtension === 'csv') {
        const res = await this.props.sendMassiveFileV2(fileInfo)
        if (res.statusCode === 200) {
          if (!!res.result.file)
            await this.getIdList(res.result.correctData, res.result.incorrectData, [], res.result.totalData, {
              base64ErrorFile: res.result.file.base64,
              fileName: res.result.file.fileName
            })
          else
            await this.getIdList(res.result.correctData, res.result.incorrectData, res.result.listDocuments, res.result.totalData, {
              fileId: fileInfo.fileId,
              base64ErrorFile: fileInfo.base64,
              fileName: fileInfo.fileName
            })
        } else {
          this.props.loadResultsMassiveModal({
            correctData: 0, incorrectData: 100, modalRecipients: {}, totalData: 100, file: {
              base64ErrorFile: fileInfo.base64,
              fileName: fileInfo.fileName
            }
          })
          this.props.showResultsMassiveModal(true)
          cogoToast.error("Archivo no válido.", toastOptions)
        }
      } else {
        this.props.showStateErrorMessageSendMassiveModal()
        cogoToast.error("Archivo no válido.", toastOptions)
      }
    }
  }


  render() {
    return (
      <CustomModal
        onClose={this.props.closeSendMassiveModal}
        open={this.props.sendMassiveModalStatus}
        modalheight={this.modalheight()}
        modalwidth={this.modalwidth()}
      >
        {this.props.loadingMassiveModal ?
          <LoadingModalDescription/> : !!this.props.resultsMassiveModal.showResultsMassiveModal ?
            <ModalDescriptionResultSendMassive/> :
            <>
              <ModalDescription>
                <Divider>
                  <CloseDark onClick={this.props.closeSendMassiveModal}/>
                </Divider>
                <Texto>Carga masiva</Texto>
                <ListDescription/>
              </ModalDescription>
              <Divider2>
                <a
                  href={require("../../../utils/Formato_Plantilla.csv")}
                  download="Formato_Plantilla.csv">
                  <ButtonPlanilla>
                    <Download style={{marginRight: "16px"}}/>
                    Descargar planilla
                  </ButtonPlanilla>
                </a>
                <InputSendMassiveFile
                  id='sendMassiveTemplate'
                  content='Cargar planilla masiva'
                  name='sendMassiveInputFile'
                  accept='.csv'
                  onSelectFile={this.handleChangeFile}
                  validator={this.validator}
                  validateOptions='required'
                />
              </Divider2>
            </>
        }
      </CustomModal>
    )
  }
}

ModalSendMassive.propTypes = {
  sendMassiveModalStatus: PropTypes.bool,
  errorSendMassiveFile: PropTypes.string,
  showStateErrorMessageSendMassiveModal: PropTypes.func,
  closeSendMassiveModal: PropTypes.func,
  loadingSendMassiveModal: PropTypes.func,
  loadingMassiveModal: PropTypes.bool,
  loadResultsMassiveModal: PropTypes.func,
  showResultsMassiveModal: PropTypes.func,
  resultsMassiveModal: PropTypes.object,
  getRecipientv2: PropTypes.func,
  recipientData: PropTypes.object,
  addMassiveRecipientsFileInfo: PropTypes.func,
  listIdsSendMassive: PropTypes.object,
  sendMassiveFileV2: PropTypes.func
}

const mapStateToProps = (state) => ({
  sendMassiveModalStatus: state.modalReducer.sendMassiveModal.status,
  errorSendMassiveFile: state.modalReducer.sendMassiveModal.showErrorSendMassiveFile,
  loadingMassiveModal: state.modalReducer.sendMassiveModal.loadingMassiveModal,
  resultsMassiveModal: state.modalReducer.sendMassiveModal.resultsMassiveModal,
  recipientData: state.writerReducer.recipientData,
  listIdsSendMassive: state.writerReducer.listIdsSendMassive,
})

const mapDispatchToProps = {
  showStateErrorMessageSendMassiveModal,
  closeSendMassiveModal,
  loadingSendMassiveModal,
  loadResultsMassiveModal,
  showResultsMassiveModal,
  getRecipientv2,
  addMassiveRecipientsFileInfo,
  sendMassiveFileV2
}
export default connect(mapStateToProps, mapDispatchToProps)(ModalSendMassive)