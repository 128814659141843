import React, { Component } from 'react'
import { connect } from "react-redux"
import { Image } from "semantic-ui-react"

import './style.css'

class ButtonWhite extends Component {
  state = {
    hovered: false
  }

  render() {
    return (
      <div
        className="greenHover"
        onMouseOut={() => this.setState({ hovered: false })}
        onMouseOver={() => this.setState({ hovered: true })}
        onClick={this.props.onClick}
      >
        <div className='optionsContent'>
            {
              this.props.icon &&
              <div
                style={{
                  padding: '0px 10px',
                }}>
                <Image
                  src={this.props.icon}
                  style={{
                    width: 'auto',
                    margin: 'auto',
                  }}
                />
              </div>
            }
            <div
              style={{
                color: `${this.props.color}`,
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '16px',
                lineHeight: '20px',
                letterSpacing: '0px',
              }}>
              {this.props.value.title}
            </div>
          </div>
      </div>
    )
  }
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps, {})(ButtonWhite)
