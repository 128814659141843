import React, { useEffect, useState } from 'react'
import { SearchBarDrafts, SearchBarGenericButton, SearchBarGenericButtonType } from '../../components/Search/SearchBarGeneric'
import AllDrafts from './allDrafts/AllDrafts'
import { useHistory } from 'react-router-dom'
import { ReactComponent as Delete } from '../../assets/icons/delete.svg'
import { useDispatch, useSelector } from 'react-redux'
import { ADD_ALL_TO_LIST_TO_DELETE, DELETE_ALL_FROM_LIST_TO_DELETE } from '../../actions/actionsUtilities/types'
import WarningModal from '../../components/FormUser/Modal/WarningModal'
import { warningFormModal } from '../../actions/modalActions'
import styled from 'styled-components'
import { CheckBoxGenericMinusIcon } from '../../components/CheckboxForm/CheckBoxGenericMinusIcon'
import { UseWindowDimensions } from '../../utils/UseWindowsDimentions'

const Divider = styled.div`
  display: flex;
  flex-direction: row;
  height: 30px;
  padding: 0 0 0.5rem 2.5rem;
  @media only screen and (max-width: 1024px) {
    padding: 0 0 0.5rem 10px !important;
  }
  @media only screen and (max-width: 768px) {
    padding: 0 0 0.5rem 1rem !important;
  }
  `

const Title = styled.p`
color: #133F4F;
font-family: Roboto;
font-size: 24px;
font-style: normal;
font-weight: 900;
margin-top:10px !important;
margin-left:10px !important;
`

export const Drafts = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [selectCheckBox, setSelectCheckBox] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const customEqual = (oldValue, newValue) => oldValue === newValue
  var listToDelete = useSelector((state) => state.draftReducer.listToDelete, customEqual)
  const [activeAll, setActiveAll] = useState(false)
  const listdraft = useSelector((state)=> state.draftReducer.draftListArray)
  const {hasSelectedAll} = useSelector((state)=> state.draftReducer)

  const windowDimensions = UseWindowDimensions();

  useEffect(() => {
    if (listToDelete.length > 0) {
      setIsVisible(true)
      setSelectCheckBox(true)
    }else{
      setActiveAll(false)
      setSelectCheckBox(false)
      setIsVisible(false)
    }
  }, [listToDelete])

  const handleSelectAll = (e) => {
    e.preventDefault()
    setActiveAll(!activeAll)
    setIsVisible(!isVisible)
    setSelectCheckBox(!selectCheckBox)
    if (!activeAll && listToDelete.length <= 0) {
      dispatch({type: ADD_ALL_TO_LIST_TO_DELETE})
    }else{
      dispatch({type: DELETE_ALL_FROM_LIST_TO_DELETE})
    }
  }

  const handleClickDelete = () => {
    dispatch(warningFormModal({ type: 'warningDeleteDraftList'}))
  }

  return (
    <>
      {
        windowDimensions.width<769 ?
        <>
        <Title>Borradores</Title>
        <SearchBarDrafts showAZ = {false} filterAgreements={false} paddingLeft={'5%'} showButton={false} showCleanFilter={true}
        textPlaceHolder={'Número de identificación del destinatario, asunto o mensaje'} showFeatured={false} isVisible={isVisible} 
        handleSelectAll={handleSelectAll} selectCheckBox ={selectCheckBox} handleClickDelete={handleClickDelete} 
        isVisibleCheck={listdraft.length >0} checkedAll={activeAll}/>
        </>
        :
        <SearchBarGenericButton disabledExportButton={true} showAZ = {false} filterAgreements={false} paddingLeft={'5%'} showButton={false} showCleanFilter={true}
        textPlaceHolder={'Número de identificación del destinatario, asunto o mensaje'} showFeatured={false} showFilters={true} filter={false}/>
        
      }
      {
      windowDimensions.width>=769 && listdraft.length >0 &&
      <Divider style={{ display: 'flex', padding: '0 0 10px  31px', height: '30px'}}>
        <CheckBoxGenericMinusIcon onChange={handleSelectAll} checked={selectCheckBox}  checkedAll={hasSelectedAll}/>
        {isVisible && <div style={{ display: 'flex', color: '#22C1D0'}}>
          <Delete style={{ cursor: 'pointer', margin: '2px 6px 0px 28.6px' }} onClick={handleClickDelete} />
          <div style={{ cursor: 'pointer'}} onClick={handleClickDelete}>Eliminar</div>
        </div>}
      </Divider>
      }
      <AllDrafts history={history} activeAll={activeAll}/>
      <WarningModal />
     
    </>
  )
}

export default Drafts
