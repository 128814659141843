import React, {Component} from 'react'
import styled from 'styled-components'
import PrimaryButton from "../../PrimaryButton";
import SimpleReactValidator from "simple-react-validator";
import {reactValidatorOptions} from "../../../helpers/simpleReactValidator";
import PropTypes from "prop-types";
import {
  updateHistoryMassive,
  addMassiveRecipientsList,
  updateRecipientDisplay,
  isMassiveRecipient
} from "../../../actions/writerActions";
import {connect} from "react-redux";
import {closeSendMassiveModal} from "../../../actions/modalActions";
import cogoToast from "cogo-toast";
import {toastOptions} from "../../../helpers/globalHelpers";

const Title = styled.p`
  padding-top: 10%;
  color: #133F4F;
  font-weight: 900;
  font-size: 30px;
  font-style: normal;
  line-height: 35px;
  text-align: center;
  margin-top: 30px;
`

const Text = styled.p`
  padding-top: 5%;
  color: #000000;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`

const TextLink = styled.p`
  padding-top: 5%;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`

const Link = styled.button`
  background: none !important;
  border: none;
  padding: 0 !important;
  font-family: arial, sans-serif;
  color: #22C1D0;
  text-decoration: underline;
  cursor: pointer;
`

const DivContainerResults = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const TextOf = styled.p`
  padding-top: 10%;
  color: #000000;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin: 0;
`

const Numbers = styled.p`
  padding-top: 10%;
  padding-left: 3%;
  padding-right: 3%;
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  color: #22C1D0;
  margin: 0;
`
const DivContainerButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5%;
`

class ModalDescriptionResultSendMassive extends Component {
  constructor(props) {
    super(props)
    this.validator = new SimpleReactValidator(reactValidatorOptions)
  }

  handleSendMassiveIds = async () => {
    if (this.isEmptyObject(this.props.resultsMassiveModal.modalRecipients))
      cogoToast.error("Error cargando los destinatarios", toastOptions)
    else {
      await this.props.isMassiveRecipient(true)
      await this.props.addMassiveRecipientsList(this.props.resultsMassiveModal.modalRecipients)
      await this.props.updateRecipientDisplay({
        key: this.props.resultsMassiveModal.file.fileId,
        value: this.props.resultsMassiveModal.file.fileName
      })
    }
    this.props.closeSendMassiveModal()
  }

  handleDownload = async () => {
    const a = document.createElement('a')
    a.download = this.props.resultsMassiveModal.file.fileName
    a.href = this.props.resultsMassiveModal.file.base64ErrorFile
    a.click()
  }

  isEmptyObject = (value) => {
    return value && Object.keys(value).length === 0 && value.constructor === Object;
  }

  render() {
    return (
      <>
        <div>
          <Title>Resultado de la carga</Title>
        </div>
        <div>
          <Text> Registros Cargados</Text>
        </div>
        <DivContainerResults>
          <Numbers>{this.props.resultsMassiveModal.correctData}</Numbers>
          <TextOf> de </TextOf>
          <Numbers>{this.props.resultsMassiveModal.totalData}</Numbers>
        </DivContainerResults>
        {this.props.resultsMassiveModal.totalData >= 100 && this.props.resultsMassiveModal.incorrectData >= 100 &&
          <>
            <div>
              <Text>La cantidad de registros excede el máximo permitido</Text>
            </div>
            <div>
              <TextLink><Link onClick={this.handleDownload}>Descargar archivo</Link></TextLink>
            </div>
            <DivContainerButton>
              <PrimaryButton
                txtBtn='Finalizar'
                onPress={this.props.closeSendMassiveModal}
              />
            </DivContainerButton>
          </>
        }{this.props.resultsMassiveModal.correctData !== this.props.resultsMassiveModal.totalData && this.props.resultsMassiveModal.incorrectData !== this.props.resultsMassiveModal.totalData &&
        <>
          <div>
            <Text>No fue posible cargar algunos registros,
              por favor descárgalos, corrígelos y cárgalos nuevamente.</Text>
          </div>
          <div>
            <TextLink><Link onClick={this.handleDownload}>Descargar archivo con registros erróneos</Link></TextLink>
          </div>
          <DivContainerButton>
            <PrimaryButton
              txtBtn='Finalizar'
              onPress={this.props.closeSendMassiveModal}
            />
          </DivContainerButton>
        </>
      }{this.props.resultsMassiveModal.correctData === this.props.resultsMassiveModal.totalData &&
        <>
          <div>
            <Text>Se cargaron exitosamente los registros</Text>
          </div>
          <DivContainerButton>
            <PrimaryButton
              txtBtn='Finalizar'
              onPress={this.handleSendMassiveIds}
            />
          </DivContainerButton>
        </>
      }{this.props.resultsMassiveModal.incorrectData === this.props.resultsMassiveModal.totalData &&
        <>
          <div>
            <Text>No fue posible cargar ningún registro</Text>
          </div>
          <DivContainerButton>
            <PrimaryButton
              txtBtn='Finalizar'
              onPress={this.props.closeSendMassiveModal}
            />
          </DivContainerButton>
        </>
      }
      </>
    )
  }
}

ModalDescriptionResultSendMassive.propTypes = {
  resultsMassiveModal: PropTypes.object,
  addMassiveRecipientsList: PropTypes.func,
  closeSendMassiveModal: PropTypes.func,
  listIdsSendMassive: PropTypes.object,
  updateHistoryMassive: PropTypes.func,
  updateRecipientDisplay: PropTypes.func,
  isMassiveRecipient: PropTypes.func,
}

const mapStateToProps = (state) => ({
  resultsMassiveModal: state.modalReducer.sendMassiveModal.resultsMassiveModal,
  listIdsSendMassive: state.writerReducer.listIdsSendMassive
})

const mapDispatchToProps = {
  addMassiveRecipientsList,
  closeSendMassiveModal,
  updateRecipientDisplay,
  updateHistoryMassive,
  isMassiveRecipient
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalDescriptionResultSendMassive)