import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import { actionClearFilter, actionInitialPagination, activePagination} from '../../../../actions/filterActions'
import { getUserDocumentsV2 } from '../../../../actions/userDocumentsActions'
import GridTableUserDocuments from './GridTableUserDocuments'
import GeneralGrid from '../../../../components/Grid/GeneralGrid'
import GridTableUserDocumentsMix from './GridTableUserDocumentsMix'

class UserDocuments extends Component {

  static propTypes = {
    history: PropTypes.any.isRequired,
  }

  state = {
    loader: true,
    columnDataGrid: [
      {name: "Usuarios", width: 16, arrow: false},
    ],
    columnGridSearch: [
      {name: "Resultados", width: 16, arrow: false},
    ],
    init: true,
    tempPag: this.props.tempPag,
  }

  componentDidMount() {
    this.makeUserDocumentsRequest(this.props.filterReducer)
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.filterReducer, this.props.filterReducer) && !this.state.init) {

      this.makeUserDocumentsRequest(this.props.filterReducer)
      this.setState({init: true, loader: true })  
    }
  }

  makeUserDocumentsRequest = (filterReducer) => {
    const body = {
      pag:  filterReducer.pag,
      limit: filterReducer.count,
      sort: this.orderTranslator(filterReducer.sort),
      textToSearch: filterReducer.searchBar[0],
      status: filterReducer.searchAdvanced.notificationState,
      typeSearch: filterReducer.searchAdvanced.typeSearch,
      initDate: !!filterReducer.searchAdvanced.date.startDate
        ? moment(filterReducer.searchAdvanced.date.startDate).format('YYYY-MM-DD') : '',
      endDate: !!filterReducer.searchAdvanced.date.endDate
        ? moment(filterReducer.searchAdvanced.date.endDate).format('YYYY-MM-DD') : '',
    }
    this.props.getUserDocumentsV2(body).then(() => {
      this.props.activePagination(false)
      this.setState({init: false, loader: false })  
    })
  }

  orderTranslator = (order) => {
    const options = {
      'masReciente': { "lastModification": -1 },
      'masAntiguo': { "lastModification": 1 },
      'A-Z': { "nameElement": 1 },
      'Z-A': { "nameElement": -1 }
    }
    return options[order]
  }

  render() {
    return (
      <GeneralGrid
        list={this.props.userDocuments}
        typeList="userDocuments"
        headerTable={this.props.isSearch === true ? this.state.columnGridSearch : this.state.columnDataGrid}
        rowComponent={this.props.filterReducer.viewTableMix === true ? GridTableUserDocumentsMix : GridTableUserDocuments}
        history={this.props.history}
        loader={this.state.loader}
        isOrder={this.props.sort}/>
    )
  }
}

const mapStateToProps = (state) => ({
  userDocuments: state.userDocumentReducer.userDocuments,
  elementsCount: state.userDocumentReducer.elementsCount,
  filterReducer: state.filterReducer,
  typeIcon: state.userDocumentReducer.typeIcon,
  isSearch: state.filterReducer.isSearch,
  sort: state.filterReducer.sort,
})

const mapDispatchToProps = {
  getUserDocumentsV2,
  actionClearFilter,
  actionInitialPagination,
  activePagination,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDocuments)