import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { openPDFModal } from '../../../actions/modalActions'
import { upDatePDF } from '../../../actions/seeNotificationAction'
import { ReactComponent as AttachedFileIcon } from '../../../assets/icons/sent/attached-file.svg'
import './attachment.css'
import Label from '../../../components/Label/MainLabelMale'

const Divider = styled.div`
  width: 104%;
  display: flex;
  flex-wrap: wrap;

  @media only screen and (max-width: 1200px){
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}
`
const AttachCard = styled.div`
  width: 47% !important;
  float:left;
  height: auto;
  background: #FFFFFF;
  border: 1px solid #DADADA;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 6px;
  margin: 8px;

  @media only screen and (max-width: 1200px){
    width: 100% !important;
    height: 82px !important;
}
`
const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const TitleContainer = styled.div`
  width: 85%;
  display: flex;
  flex-direction: row;
  align-items: center
`
const Icon = styled.div`
  display: flex;
  align-items: center
`
const Title = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin-left: 2px;
  margin-top: 12px;
  font-family: 'Roboto';
`
const Text = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  
  @media only screen and (min-width: 2225px){
    display: none;
  }
  @media only screen and (max-width: 1200px) and (min-width: 546px){
    display: none;
  }
`
const Text2 = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  
  @media only screen and (min-width: 425px){
    display: none;
  }
  
`
const FileSize = styled.p`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #979E9F;

`
const Content = styled.div`
  padding-bottom: 10px !important;
  @media only screen and (min-width: 960px) and (max-width: 1024px) {
    padding-top: 0;
  }
`

const StatusContainer = styled.div`
  float: left !important;
  @media only screen and (max-width: 1024px){
    float: right !important;
}
`


const Attachment = (props) => {

  const dispatch = useDispatch()
  const { detailNotification } = useSelector((state) => state.sentReducer)
  const handleClick = (documentName, fileURL, documentID, fileDecodedBase64) => {
    dispatch(upDatePDF({ "documentName": documentName, "fileURL": fileURL, "documentID": documentID, "fileDecodedBase64": fileDecodedBase64 }))
    dispatch(openPDFModal())
  }

  const attachments= [
    {
      documentID:"c0ab2044-e7ce-4595-bc20-6949beefeb96",
      documentName:"Nombre del documento actulalmente firmado.pdf",
      fileName:"c0ab2044-e7ce-4595-bc20-6949beefeb96.pdf",
      reqSignature:false,
      reqReadDate:false,
      fileURL:"https://demo-t.gse.com.co/documents/attachments/originalTemp/6317a2ee4f77e28f47ed13b0/6317b80e4f77e28f47ee67fd/2023/8/23/c0ab2044-e7ce-4595-bc20-6949beefeb96.pdf",
      fileExtension:"pdf",
      fileSize:"320KB",
      fileEncryptedURL:"https://demo-t.gse.com.co/documents/attachments/encrypted/6317a2ee4f77e28f47ed13b0/6317b80e4f77e28f47ee67fd/2023/8/23/c0ab2044-e7ce-4595-bc20-6949beefeb96",
      receivedDate:"2023-08-23T20:34:21.000Z",
      isSigned:false,
      rejected:false,
      fileDecodedBase64:null,
      currentStatus: 
        {
          id:3,
          name:"Pendiente",
          date:"2023-08-23T20:34:13.804Z",
          observation:null,
          color:"#E35F00",
          pending:true,
          icon:"../../../assets/icons/sent/pending.svg",
          chipIcon:"https://demo-t.gse.com.co/image/public/icon/notification_states/No-Leida.png",
        },
    },
    {
      documentID:"c0ab2044-e7ce-4595-bc20-6949beefeb96",
      documentName:"Nombre del documento actulalmente firmado.pdf",
      fileName:"c0ab2044-e7ce-4595-bc20-6949beefeb96.pdf",
      reqSignature:false,
      reqReadDate:false,
      fileURL:"https://demo-t.gse.com.co/documents/attachments/originalTemp/6317a2ee4f77e28f47ed13b0/6317b80e4f77e28f47ee67fd/2023/8/23/c0ab2044-e7ce-4595-bc20-6949beefeb96.pdf",
      fileExtension:"pdf",
      fileSize:"320KB",
      fileEncryptedURL:"https://demo-t.gse.com.co/documents/attachments/encrypted/6317a2ee4f77e28f47ed13b0/6317b80e4f77e28f47ee67fd/2023/8/23/c0ab2044-e7ce-4595-bc20-6949beefeb96",
      receivedDate:"2023-08-23T20:34:21.000Z",
      isSigned:false,
      rejected:false,
      fileDecodedBase64:null,
      currentStatus: 
        {
          id:5,
          name:"Completado",
          date:"2023-08-23T20:34:13.804Z",
          observation:null,
          color:"#009838",
          pending:true,
          icon:"̣https://demo-t.gse.com.co/image/public/icon/status/completado.png",
          chipIcon:"https://demo-t.gse.com.co/image/public/icon/notification_states/No-Leida.png",
        },
    },
    {
      documentID:"c0ab2044-e7ce-4595-bc20-6949beefeb96",
      documentName:"Nombre del documento actulalmente firmado.pdf",
      fileName:"c0ab2044-e7ce-4595-bc20-6949beefeb96.pdf",
      reqSignature:false,
      reqReadDate:false,
      fileURL:"https://demo-t.gse.com.co/documents/attachments/originalTemp/6317a2ee4f77e28f47ed13b0/6317b80e4f77e28f47ee67fd/2023/8/23/c0ab2044-e7ce-4595-bc20-6949beefeb96.pdf",
      fileExtension:"pdf",
      fileSize:"320KB",
      fileEncryptedURL:"https://demo-t.gse.com.co/documents/attachments/encrypted/6317a2ee4f77e28f47ed13b0/6317b80e4f77e28f47ee67fd/2023/8/23/c0ab2044-e7ce-4595-bc20-6949beefeb96",
      receivedDate:"2023-08-23T20:34:21.000Z",
      isSigned:false,
      rejected:false,
      fileDecodedBase64:null,
      currentStatus: 
        {
          id:1,
          name:"No leído",
          date:"2023-08-23T20:34:13.804Z",
          observation:null,
          color:"#0E3B52",
          pending:true,
          icon:"̣https://demo-t.gse.com.co/image/public/icon/status/icono_no_leido.png",
          chipIcon:"https://demo-t.gse.com.co/image/public/icon/notification_states/No-Leida.png",
        },
    },
  ]

  return (
    <Divider>
       {
        detailNotification
        && detailNotification.attachments?.map((ele, index) => (
          <AttachCard key={index} onClick={() => {
            handleClick(ele.documentName, ele.fileURL, ele.documentID, ele.fileDecodedBase64)
          }}>
            <Header>
              <TitleContainer className='attached-title-element'>
                <Icon>
                  <AttachedFileIcon />
                </Icon>
                {
                  ele.documentName.length > 36
                    ?
                    <>
                      <Title className='text-title-document'>
                        {ele.documentName}
                      </Title>
                      <Text>
                        {ele.fileExtension}
                      </Text>
                    </>
                    :
                    <>
                      <Title className='text-title'>
                        {ele.documentName}
                      </Title>
                      {
                        ele.documentName.length > 28
                        ?
                          <Text2>
                            {ele.fileExtension}
                          </Text2>
                        : ''
                      }
                    </>
                }
              </TitleContainer>
              <FileSize>
                {ele.fileSize}
              </FileSize>
            </Header>
            <Content>
              <StatusContainer>
                <Label
                  text={ele.currentStatus.name}
                  icon={ele.currentStatus.id}
                  color={ele.currentStatus.color}
                />
              </StatusContainer>
            </Content>
          </AttachCard>
        ))
      }
    </Divider>
  )
}

export default Attachment