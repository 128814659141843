import isEmpty from '../utils/isEmpty'
import {
  LOGOUT,
  RESET_PASSWORD,
  SET_CURRENT_USER,
  GET_LIST_CERTIFICATE,
  GET_LIST_CERTIFICATE_2,
  GET_QR,
  UPDATE_SESSION_ACTIVE,
  SET_LOGIN_TYPE,
  SET_ROLES,
  CLEAR_LOGIN,
  CLEAR_ROLES,
  CLEAR_CERTIFICATES,
  SET_LOGIN_STATUS,
  VALID_TOKEN,
  RESET_CAPTCHA,
  DELETE_FROM_ACTION_LIST,
  UPDATE_ACTIONS_LIST
} from '../actions/actionsUtilities/types.js'

const initialState = {
  isAuthenticated: false,
  user: {},
  reset: false,
  loading: false,
  certificates: [],
  numberLogin: 0,
  code: {},
  sessionActive: false,
  typeOfLogin: '',
  roles: [],
  actionsRunning: [],
  statusIP: 'ALLOWED',
  captcha: false,
  remainingTime: null,
  validToken: {},
  resetCaptcha: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT:
      return initialState
    case CLEAR_LOGIN:
      return initialState
    case CLEAR_CERTIFICATES:
      return {
        ...state,
        certificates: [],
      };
    case CLEAR_ROLES:
      return {
        ...state,
        roles: []
      };
    case UPDATE_ACTIONS_LIST:
      return {
        ...state,
        actionsRunning: [...state.actionsRunning, action.payload],
      }
    case DELETE_FROM_ACTION_LIST:
      return {
        ...state,
        actionsRunning: state.actionsRunning.filter(item => item !== action.payload)
      }
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
        loading: false,
      };
    case UPDATE_SESSION_ACTIVE:
      return {
        ...state,
        sessionActive: action.payload
      }
    case RESET_PASSWORD:
      return {
        reset: true
      }
    case GET_LIST_CERTIFICATE:
      return {
        ...state,
        certificates: action.payload
      }
    case GET_LIST_CERTIFICATE_2:
      return {
        ...state,
        certificates: action.payload,
        numberLogin: 2
      }
    case SET_ROLES:
      return {
        ...state,
        roles: action.payload,
      }
    case SET_LOGIN_STATUS:
      return {
        ...state,
        statusIP: action.payload.status,
        captcha: action.payload.captcha,
        remainingTime: action.payload.remainingTime
      }
    case RESET_CAPTCHA:
      return {
        ...state,
        resetCaptcha: !state.resetCaptcha
      }
    case VALID_TOKEN:
      return {
        ...state,
        validToken: action.payload
      };
    case GET_QR:
      return {
        ...state,
        code: action.payload
      }
    case SET_LOGIN_TYPE:
      return {
        ...state,
        typeOfLogin: action.payload
      }
    default:
      state.reset = false
      return state
  }
}
