import { ADD_ALL_TO_LIST_TO_DELETE, DELETE_ALL_FROM_LIST_TO_DELETE, DELETE_FROM_LIST_TO_DELETE, 
  CLEAR_DATA_OF_DRAFT, DRAFTS_LIST, UPDATE_LIST_TO_DELETE, SAVE_DATA_OF_DRAFT, DELETE_DRAFT_RECIPIENT, 
  UPDATE_FILE_SERIAL_ID, SET_DRAFT_SERIAL_ID_DRAFTS, LOGOUT, DELETE_DRAFT_TAGS_AND_SPECIFIC_FILES } from "../actions/actionsUtilities/types"

const initialState = {
  draftListArray: [],
  elementsCount: 0,
  hasSelectedAll:false,
  listToDelete: [],
  toSaveDraft: {
    notificationTypeVisualization: "PRINCIPAL",
    requiredSignature: false,
    requiredConfirmationRead: false,
    isPushNotification: false,
    recipients: [],
    subject: "",
    description: "",
    attachments: [],
    fileSerialID: '',
    draftSerialID: ''
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case DRAFTS_LIST:
      return {
        ...state,
        draftListArray: !!action.payload.drafts ? action.payload.drafts : [],
        elementsCount: !!action.payload.elementsCount ? action.payload.elementsCount : 0
      }
    case UPDATE_LIST_TO_DELETE:
      return {
        ...state,
        listToDelete: [...state.listToDelete, action.payload],
        hasSelectedAll:[...state.listToDelete, action.payload].length === state.draftListArray.length
      }
    case DELETE_FROM_LIST_TO_DELETE:
      return {
        ...state,
        listToDelete: state.listToDelete.filter(item => item !== action.payload),
        hasSelectedAll:false
      }
    case ADD_ALL_TO_LIST_TO_DELETE:
      return {
        ...state,
        listToDelete: state.draftListArray.map(item => item.draftSerialID),
        hasSelectedAll:true
      }
    case DELETE_ALL_FROM_LIST_TO_DELETE:
      return {
        ...state,
        listToDelete: [],
        hasSelectedAll:false
      }
    case SAVE_DATA_OF_DRAFT:
      return {
        ...state,
        toSaveDraft: {
          ...state.toSaveDraft,
          ...action.payload
        }
      }
    case DELETE_DRAFT_RECIPIENT:
      return {
        ...state,
        toSaveDraft: {
          ...state.toSaveDraft,
          fileSerialID: null,
        }
      }
    case DELETE_DRAFT_TAGS_AND_SPECIFIC_FILES:
      return {
        ...state,
        toSaveDraft: {
          ...state.toSaveDraft,
          listDocumentsSpecific:[],
          listTagsChargeMassive:[]
        }
      }
    case CLEAR_DATA_OF_DRAFT:
      return {
        ...state,
        toSaveDraft: initialState.toSaveDraft
      }
    case UPDATE_FILE_SERIAL_ID:
      return {
        ...state,
        toSaveDraft: {
          ...state.toSaveDraft,
          fileSerialID: action.payload
        }
      }
    case SET_DRAFT_SERIAL_ID_DRAFTS:
    return {
      ...state,
      toSaveDraft: {
        ...state.toSaveDraft,
        draftSerialID: action.payload
      }
    }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}