import {
  STATE_FILTER_SEARCH_BAR_INTERNAL,
  STATE_FILTER_POPUP_FILTER_INTERNAL,
  STATE_FILTER_POPUP_ORDER_INTERNAL,
  STATE_FILTER_PAGINACION_INTERNAL,
  STATE_FILTER_ADVANCED_SEARCH_DATE_INTERNAL,
  STATE_FILTER_ADVANCED_SEARCH_INTERNAL,
  STATE_STARRED_FILTER_INTERNAL,
  STATE_TAB_FILTER_INTERNAL,
  STATE_CLEAR_FILTER_INTERNAL,
  STATE_INITIAL_PAGINATION_INTERNAL,
  ACTIVE_TABLE_MIX,
  INACTIVE_TABLE_MIX,
  STATE_FILTER_POPUP_FILTER_AGREEMENTS_INTERNAL,
  STATE_FILTER_POPUP_FILTER_IMPROPER_CLIENTS_INTERNAL,
  STATE_FILTER_POPUP_FILTER_DRAFTS_INTERNAL,
  STATE_FILTER_POPUP_FILTER_REPORTS_INTERNAL,
  STATE_INITIAL_FILTER_INTERNAL,
  ACTIVE_PAGINATION,
  STATE_FILTER_MASSIVE_DETAIL_INTERNAL,
  STATE_TEMP_PAG_INTERNAL,
  STATE_FILTER_POPUP_FILTER_LOGS_INTERNAL,
} from "./actionsUtilities/types.js"

export const actionSearchBar = (text, cancelFlag, state) => (dispatch) => {
  dispatch({
    type: STATE_FILTER_SEARCH_BAR_INTERNAL,
    payload: [
      text
    ],
    cancelFlag: cancelFlag
  })
}

export const actionFilter = (filter) => (dispatch) => {
  dispatch({
    type: STATE_FILTER_POPUP_FILTER_INTERNAL,
    payload: filter
  })
}

export const actionFilterDraft = (status) => (dispatch) => {
  dispatch({
    type: STATE_FILTER_POPUP_FILTER_DRAFTS_INTERNAL,
    payload: status
  })
}

export const actionFilterAgreements = (optionSelected) => (dispatch) => {
  dispatch({
    type: STATE_FILTER_POPUP_FILTER_AGREEMENTS_INTERNAL,
    payload: optionSelected
  })
}

export const actionFilterLogs = (optionSelected) => (dispatch) => {
  dispatch({
    type: STATE_FILTER_POPUP_FILTER_LOGS_INTERNAL,
    payload: optionSelected
  })
}

export const actionFilterImproperClients = (optionSelected) => (dispatch) => {
  
  dispatch({
    type: STATE_FILTER_POPUP_FILTER_IMPROPER_CLIENTS_INTERNAL,
    payload: optionSelected
  })
  
}

export const actionFilterReports = (optionSelected) => (dispatch) => {
  dispatch({
    type: STATE_FILTER_POPUP_FILTER_REPORTS_INTERNAL,
    payload: optionSelected
  })
}

export const actionOrder = (order) => (dispatch) => {
  dispatch({
    type: STATE_FILTER_POPUP_ORDER_INTERNAL,
    payload: order
  })
}

export const actionPagination = (pag) => (dispatch) => {
  dispatch({
    type: STATE_FILTER_PAGINACION_INTERNAL,
    payload: pag
  })
}

export const actionSearchAdvancedDate = (date) => (dispatch) => {
  dispatch({
    type: STATE_FILTER_ADVANCED_SEARCH_DATE_INTERNAL,
    payload: date
  })
}

export const actionSearchAdvanced = (state) => (dispatch) => {
  dispatch({
    type: STATE_FILTER_ADVANCED_SEARCH_INTERNAL,
    payload: state
  })
}

export const actionStarredFilter = (state) => (dispatch) => {
  dispatch({
    type: STATE_STARRED_FILTER_INTERNAL,
    payload: state
  })
}

export const actionUpdateTabFilter = (state) => (dispatch) => {
  dispatch({
    type: STATE_TAB_FILTER_INTERNAL,
    payload: state
  })
}

export const actionClearFilter = (state) => (dispatch) => {
  dispatch({
    type: STATE_CLEAR_FILTER_INTERNAL,
    payload: state
  })
}

export const actionSetInitialState = (state) => (dispatch) => {
  dispatch({
    type: STATE_INITIAL_FILTER_INTERNAL,
    payload: state
  })
}

export const actionInitialPagination = (state) => (dispatch) => {
  dispatch({
    type: STATE_INITIAL_PAGINATION_INTERNAL,
    payload: state
  })
}

export const activeViewMix = () => async (dispatch) => {
  dispatch({
    type: ACTIVE_TABLE_MIX,
  })
}

export const inactiveViewMix = () => async (dispatch) => {
  dispatch({
    type: INACTIVE_TABLE_MIX,
  })
}

export const activePagination = (state) => (dispatch) => {
  dispatch({
    type: ACTIVE_PAGINATION,
    payload: state
  })
}

export const actionMassiveDetailFilter = () => (dispatch) =>{
  dispatch({type: STATE_FILTER_MASSIVE_DETAIL_INTERNAL})
}

export const setTempPag = (pag) => (dispatch) => {
  dispatch({
    type: STATE_TEMP_PAG_INTERNAL,
    payload: pag
  })
}