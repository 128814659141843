import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { ReactComponent as DownloadIcon } from '../../assets/icons/sent/download.svg'

const Button = styled.button`
  width: auto;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  background: unset;
  border: 1px solid #22C1D0;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 14px;
  color: #22C1D0;
  cursor: pointer;
  & svg {
    margin-right: 8px;
  }
  @media only screen and (max-width: 1200px)  {
    width: 100%;
    justify-content: center;
  }
`

const ButtonBasic = (props) => {

  const getIcon = () => {
    const name = props.icon
    const icons = {
      'download': <DownloadIcon />,
      'default': null
    }
    return !!icons[name] ? icons[name] : icons['default']
  }

  return (
    <Button
      onClink={props.handleClick()}
    >
      {props.icon && getIcon()}
      {props.text}
    </Button>
  )
}

ButtonBasic.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  icon: PropTypes.string,
}

export default ButtonBasic
