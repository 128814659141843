import React from "react"
import PropTypes from "prop-types"

import { useDispatch } from 'react-redux'
import './input-from-entities.css'
import styled from "styled-components"
import { Form } from "semantic-ui-react"
import { activeFormClient } from "../../../actions/clientActions"
import { ReactComponent as DateIconDisable } from '../../../assets/icons/dateIconDisabled.svg'

const text = styled.p`

`
const text2 = styled.span`
    
`
const InputFormDate = ({ ...props }) => {
    const dispatch = useDispatch()
    const { placeholder, type, id, onChange, value, label, validator, validateOptions, defaultValue, disabled, endDate, startDate } = props
    let errorValidator = null;
    if (validator && validateOptions) errorValidator = validator ? validator.message(label !== '' && label !== undefined ? label : placeholder, value, validateOptions) : ''

    const validator2 = (value) => {
        if (value !== "") {
            dispatch(activeFormClient())
        }
    }
    const limitDate = () => {
        if(!!startDate){
            const today = new Date()
            const dateMin = new Date(startDate)
            var formattedMinDate 
            dateMin.setDate(dateMin.getDate()+29)
            dateMin <= today?
                formattedMinDate = dateMin.toISOString().split('T')[0]
            :
                formattedMinDate = today.toISOString().split('T')[0]
            return formattedMinDate
        }else{
            const formattedMaxDate = new Date().toISOString().split('T')[0]
            return formattedMaxDate
        }

    }

    const limitMinDate = () =>{
        if(!!startDate){
            const dateMin = new Date(startDate)
            dateMin.setDate(dateMin.getDate()+1)
            const formattedMinDate= dateMin.toISOString().split('T')[0]
            return formattedMinDate
        }
    }
    return (
        <Form.Field>
            <label className={errorValidator ? 'labelInputFormDateError' : endDate !== undefined && (value > endDate || value === endDate && endDate !== '') ? 'labelInputFormDateError' : 'labelInputDate'} >
                <input
                    id={id}
                    type={type}
                    value={value}
                    defaultValue={defaultValue}
                    placeholder={placeholder}
                    onChange={onChange}
                    onBlur={() => validator2(value)}
                    onKeyDown={(e) => e.keyCode === 13 && e.preventDefault()}
                    className={'inputFormDate'}
                    disabled={disabled}
                    max={limitDate()}
                    min={limitMinDate()}
                />
                {
                    disabled === true &&
                    <>
                        <span className={'spanLabelInputTop'}>{label}</span>
                        <DateIconDisable className={'iconDisable'} />
                    </>
                }
                {
                    errorValidator && value === ''
                    &&
                    <>
                        <text2 className={errorValidator && value === '' && 'spanLabelInputTopError'}>{label}</text2>
                        <text className={(errorValidator) && 'input-error4'}>{errorValidator}</text>
                    </>
                }
                {
                    errorValidator === undefined && value !== ''
                    &&
                    <>
                        <span className={'spanLabelInputTop'}>{label}</span>
                    </>
                }
                {
                    errorValidator === undefined && value === ''
                    &&
                    <>
                        <span className={'spanLabelInputDate'}>{label}</span>
                    </>
                }
                {
                    endDate !== '' && value === endDate
                    &&
                    <>
                        <text2 className={'spanLabelInputTopError'}>{label}</text2>
                        <text className={'input-error2'}>La fecha de inicio no debe ser igual a la fecha fin</text>
                    </>
                }
                {
                    endDate !== '' && value > endDate
                    &&
                    <>
                        <text2 className={'spanLabelInputTopError'}>{label}</text2>
                        <text className={'input-error2'}>La fecha de inicio debe ser menor a la fecha fin</text>
                    </>
                }
                {
                    (startDate !== '' && value !== '') && value < startDate
                    &&
                    <>
                        <text2 className={'spanLabelInputTopError'}>{label}</text2>
                        <text className={'input-error2'}>La fecha fin debe ser mayor a la fecha de inicio</text>
                    </>
                }
                {
                    errorValidator && value !== ''
                    &&
                    <>
                        <text2 className={'spanLabelInputTopError'}>{label}</text2>
                        <text className={'input-error2'}>{errorValidator}</text>
                    </>
                }
                {
                    endDate !== '' && value > endDate
                    &&
                    <>
                        <text2 className={'spanLabelInputTopError'}>{label}</text2>
                        <text className={'input-error2'}>Debe de ser menor a la fecha final</text>
                    </>
                }
            </label>
        </Form.Field>
    )
}


InputFormDate.propTypes = {
    labelProps: PropTypes.object,
    id: PropTypes.string,
}
export default InputFormDate
