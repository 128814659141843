import React from 'react'
import {connect} from 'react-redux'
import {Popup} from 'semantic-ui-react'
import styled from 'styled-components'
import {withRouter} from 'react-router-dom';
import {actionFilterImproperClients} from '../../../actions/filterActions'
import ButtonIcon from '../../../components/Button/IconButton'
import {ReactComponent as Filter} from '../../../assets/icons/filter.svg'
import {ReactComponent as Reviewed} from '../../../assets/icons/reviewed.svg'
import '../main.css'
import PropTypes from "prop-types";

const Divider = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 0px 0px 20px;
  @media only screen and (max-width: 1280px) {
    padding: 0px 0px 0px 0px;
  }
`
const Texto = styled.div`
  color: #7C7C74;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  @media only screen and (max-width: 750px) {
    display: none;
  }
`
const PopupFilter = styled(Popup)`
  background: #fff !important;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.25) !important;
  border-radius: 4px !important;
  padding: 0 !important;
  width: 190px !important;
  left: -1vw !important;
  border: none !important;

  &:before {
    display: none !important;
  }
`
const ButtonOption = styled.button`
  color: #7C7C74;
  border: 0;
  background: #fff;
  font-weight: normal;
  font-size: 14px;
  height: 32px;
  text-align: left;
  width: 100%;
  border-radius: 4px;
`
const Text = styled.p`
  color: #7C7C74;
  font-weight: normal;
  font-size: 14px;
  padding-left: 40px;
`

const ContainerOption = styled.div`
  padding: 8px 0px !important;
`

class PopUpFilterImproperClients extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      filterOptions: '',
      pathname: ''
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.filterOptions !== prevState.filterOptions) {
      this.props.actionFilterImproperClients(this.state.filterOptions)
    }
  }

  toggle = () => {
    this.setState({filterOptions: this.props.filterOptionsReducer})
    this.setState({show: !this.state.show})
  }

  changeOptions = (optionSelected) => {
    this.setState({filterOptions: optionSelected})
  }

  render() {
    return (
      <Divider>
        <PopupFilter
          position='bottom left'
          on='click'
          pinned
          open={this.state.show}
          onClose={this.toggle}
          trigger={
            <ButtonIcon onClick={this.toggle}>
              <Texto>
                Filtrar
              </Texto>
              <Filter/>
            </ButtonIcon>
          }
        >
          <ContainerOption>
            <ButtonOption
              className='buttonOptionHover'
              onClick={() => this.changeOptions('')}
            >
              {
                this.state.filterOptions === ''
                && <Reviewed className='iconReReviewed'/>
              }
              {
                this.state.filterOptions !== ''
                  ? <Text>Todos</Text>
                  : 'Todos'
              }
            </ButtonOption>
            <ButtonOption
              className='buttonOptionHover'
              onClick={() => this.changeOptions('1')}
            >
              {
                this.state.filterOptions === '1'
                && <Reviewed className='iconReReviewed'/>
              }
              {
                this.state.filterOptions !== '1'
                  ? <Text>Uso indebido alto</Text>
                  : 'Uso indebido alto'
              }
            </ButtonOption>
            <ButtonOption
              className='buttonOptionHover'
              onClick={() => this.changeOptions('2')}
            >
              {
                this.state.filterOptions === '2'
                && <Reviewed className='iconReReviewed'/>
              }
              {
                this.state.filterOptions !== '2'
                  ? <Text>Del 60% al 80%</Text>
                  : 'Del 60% al 80%'
              }
            </ButtonOption>
            <ButtonOption
              className='buttonOptionHover'
              onClick={() => this.changeOptions('3')}
            >
              {
                this.state.filterOptions === '3'
                && <Reviewed className='iconReReviewed'/>
              }
              {
                this.state.filterOptions !== '3'
                  ? <Text>Del 40% al 60%</Text>
                  : 'Del 40% al 60%'
              }
            </ButtonOption>
            <ButtonOption
              className='buttonOptionHover'
              onClick={() => this.changeOptions('4')}
            >
              {
                this.state.filterOptions === '4'
                && <Reviewed className='iconReReviewed'/>
              }
              {
                this.state.filterOptions !== '4'
                  ? <Text>Menos del 40%</Text>
                  : 'Menos del 40%'
              }
            </ButtonOption>
          </ContainerOption>
        </PopupFilter>
      </Divider>
    )
  }
}

PopUpFilterImproperClients.propTypes = {
  filterOptionsReducer: PropTypes.string,
  actionFilterImproperClients: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  filterOptionsReducer: state.filterReducer.improperClientsFilter.filterOptions
})

const mapDispatchToProps = {
  actionFilterImproperClients
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PopUpFilterImproperClients))
