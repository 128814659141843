
import * as PapaParse from "papaparse";

export const fileToBase64Clean = (filename, file) => {
  return new Promise(resolve => {
    let reader = new FileReader()
    reader.onload = function (event) {
      resolve(event.target.result)
    }
    reader.readAsDataURL(file)
  })
}

export const fileToBase64 = (filename, file) => {
  return new Promise(resolve => {
    let reader = new FileReader()
    reader.onload = function (event) {
      resolve(event.target.result)
    }
    reader.readAsDataURL(file)
  })
}

export const fileToCsvData = (filename, file) => {
  return new Promise(resolve => {
    let reader = new FileReader()
    reader.onload = () => {
      const csvData = PapaParse.parse(reader.result, {
        header: false,
        dynamicTyping: true,
        skipEmptyLines: true
      })
    resolve(csvData.data)
    }
    reader.readAsText(file[0], 'UTF-8')
  })
}

export const iconByMimeType = (mimeType) => {
  if (mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    || mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.template'
    || mimeType === 'application/vnd.ms-word.document.macroEnabled.12'
    || mimeType === 'application/vnd.ms-word.template.macroEnabled.12'
    || mimeType === 'application/msword')
    return 'file word outline'
  else if (mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    || mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.template'
    || mimeType === 'application/vnd.ms-excel.sheet.macroEnabled.12'
    || mimeType === 'application/vnd.ms-excel.template.macroEnabled.12'
    || mimeType === 'application/vnd.ms-excel.addin.macroEnabled.12'
    || mimeType === 'application/vnd.ms-excel.sheet.binary.macroEnabled.12'
    || mimeType === 'application/vnd.ms-excel')
    return 'file excel outline'
  else if (mimeType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    || mimeType === 'application/vnd.openxmlformats-officedocument.presentationml.template'
    || mimeType === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow'
    || mimeType === 'application/vnd.ms-powerpoint.addin.macroEnabled.12'
    || mimeType === 'application/vnd.ms-powerpoint.presentation.macroEnabled.12'
    || mimeType === 'application/vnd.ms-powerpoint.template.macroEnabled.12'
    || mimeType === 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12'
    || mimeType === 'application/vnd.ms-powerpoint')
    return 'file powerpoint outline'
  else if (mimeType === 'application/pdf')
    return 'file pdf outline'
  else if (mimeType === 'image/jpeg'
    || mimeType === 'image/png'
    || mimeType === 'image/svg+xml'
    || mimeType === 'image/gif')
    return 'file image outline'
  else if (mimeType === 'application/x-rar-compressed'
    || mimeType === 'application/zip')
    return 'file archive outline'
  else
    return 'file outline'
}

