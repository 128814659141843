import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AllAttachmentDetailList from './viewList/AllAttachmentDetailList/AllAttachmentDetailList'
import AllAttachmentDetailGrid from './viewGrid/AllAttachmentDetailGrid/AllAttachmentDetailGrid'
import ContainerUserDocumentSearch from '../../../components/Search/SearchAttachment/Container/ContainerDetailDocumentSeacrh'

import Header from './Header'
import { PDFViewer } from '../../../components/viewPdf/PDFViewer'
import { ADD_ALL_TO_LIST_TO_DELETE_DOCUMENTS, CLEAR_ATTACHMENT_DETAIL, DELETE_ALL_FROM_LIST_TO_DELETE_DOCUMENTS } from '../../../actions/actionsUtilities/types'
import { exportDocumentsToPDF } from '../../../actions/userDocumentsActions'

const AttacmentDetail = (props) => {
    const dispatch = useDispatch()

    const { viewList } = useSelector(state => state.userDocumentReducer)
    const [selectCheckBox, setSelectCheckBox] = useState(false)
    const [isVisible, setIsVisible] = useState(false)
    const customEqual = (oldValue, newValue) => oldValue === newValue
    var listToDelete = useSelector((state) => state.userDocumentReducer.listToDelete, customEqual)
    const [activeAll, setActiveAll] = useState(false)
    const {hasSlectedAllAttachmentsToDownload} = useSelector((state) => state.userDocumentReducer)

    const listdraft = useSelector((state) => state.draftReducer.draftListArray)

    useEffect(()=>{
        dispatch({type:CLEAR_ATTACHMENT_DETAIL})
    },[])


    useEffect(() => {
        if (listToDelete.length > 0) {
            setIsVisible(true)
            setSelectCheckBox(true)
        } else {
            setActiveAll(false)
            setSelectCheckBox(false)
            setIsVisible(false)
        }
    }, [listToDelete])

    const handleSelectAll = (e) => {
        e.preventDefault()
        setActiveAll(!activeAll)
        setIsVisible(!isVisible)
        setSelectCheckBox(!selectCheckBox)
        if (!activeAll && listToDelete.length <= 0) {
            dispatch({ type: ADD_ALL_TO_LIST_TO_DELETE_DOCUMENTS })
        } else {
            dispatch({ type: DELETE_ALL_FROM_LIST_TO_DELETE_DOCUMENTS })
        }
    }

    const handleClickDownload = () => {
        dispatch(exportDocumentsToPDF({attachmentSerialIDList: listToDelete}))
    }
    return (
        <>
            <Header history={props.history} />
            <PDFViewer />
            <ContainerUserDocumentSearch showAZ={true} textPlaceHolder={'Nombre de documento  '} isVisible={isVisible} 
        handleSelectAll={handleSelectAll} selectCheckBox ={selectCheckBox} handleClickDownload={handleClickDownload} 
        isVisibleCheck={listdraft.length >0} checkedAll={hasSlectedAllAttachmentsToDownload}/>
            {viewList === true ? <AllAttachmentDetailList activeAll={activeAll} history={props.history} /> :
                <AllAttachmentDetailGrid history={props.history} activeAll={activeAll} />}
        </>
    )
}

export default AttacmentDetail