import {
  STATE_FILTER_SEARCH_BAR_INTERNAL,
  STATE_FILTER_POPUP_FILTER_INTERNAL,
  STATE_FILTER_POPUP_ORDER_INTERNAL,
  STATE_FILTER_PAGINACION_INTERNAL,
  STATE_FILTER_ADVANCED_SEARCH_DATE_INTERNAL,
  STATE_FILTER_ADVANCED_SEARCH_INTERNAL,
  STATE_DELETE_FILTER_INTERNAL,
  STATE_STARRED_FILTER_INTERNAL,
  STATE_TAB_FILTER_INTERNAL,
  STATE_CLEAR_FILTER_INTERNAL,
  STATE_INITIAL_PAGINATION_INTERNAL,
  ACTIVE_TABLE_MIX,
  INACTIVE_TABLE_MIX, STATE_FILTER_POPUP_FILTER_AGREEMENTS_INTERNAL,
  STATE_FILTER_POPUP_FILTER_IMPROPER_CLIENTS_INTERNAL, 
  STATE_FILTER_POPUP_FILTER_DRAFTS_INTERNAL, 
  STATE_FILTER_POPUP_FILTER_REPORTS_INTERNAL,
  VIEW_PAGINATION_INTERNAL,
  STATE_FILTER_MASSIVE_DETAIL_INTERNAL,
  STATE_FILTER_POPUP_FILTER_LOGS_INTERNAL,
  STATE_FILTER_REQUIRED_SIGNATURE,
} from "../actions/actionsUtilities/types.js";

const initialState = {
  isSearch: false,
  searchBar: '',
  sort: 'masReciente',
  count: 20,
  pag: 1,
  total: 0,
  requiredType: {
    key: '',
    value: ''
  },
  starred: false,
  searchAdvanced: {
    date: {
      startDate: '',
      endDate: '',
      key: ''
    },
    attachedFile: false,
    notificationState: '',
    typeSearch: '',
    reportType:'',
    requiredSignature:''
  },
  viewTableMix: false,
  tabFilter: 'all',
  agreementFilter: {
    agreementStatus: ''
  },
  improperClientsFilter: {
    filterOptions: ''
  },
  draftsFilter: {
    draftsStatus: ''
  },
  reportsFilter: {
    reportStatus: ''
  }, 
  logsFilter:{
    action:''
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case STATE_FILTER_SEARCH_BAR_INTERNAL:
      return {
        ...initialState,
        isSearch: !action.cancelFlag,
        searchBar: action.payload
      }
    case STATE_FILTER_POPUP_FILTER_INTERNAL:
      return {
        ...state,
        pag: initialState.pag,
        searchAdvanced: {
          ...initialState.searchAdvanced,
          notificationState: action.payload,
        },
        starred: initialState.starred
      }
    case STATE_FILTER_POPUP_ORDER_INTERNAL:
      return {
        ...state,
        sort: action.payload,
      }
    case STATE_FILTER_PAGINACION_INTERNAL:
      return {
        ...state,
        pag: action.payload,
      }
    case STATE_FILTER_ADVANCED_SEARCH_DATE_INTERNAL:
      return {
        ...state,
        searchAdvanced: {
          ...state.searchAdvanced,
          date: action.payload,
        }
      }
    case STATE_FILTER_ADVANCED_SEARCH_INTERNAL:
      return {
        ...state,
        ...action.payload
      }
    case STATE_DELETE_FILTER_INTERNAL:
      return {
        ...state,
        searchAdvanced: action.payload
      }
    case STATE_STARRED_FILTER_INTERNAL:
      return {
        ...state,
        pag: initialState.pag,
        starred: action.payload,
        searchAdvanced: {
          ...state.searchAdvanced,
          notificationState: initialState.searchAdvanced.notificationState
        }
      }
    case STATE_TAB_FILTER_INTERNAL:
      return {
        ...state,
        tabFilter: action.payload,
      }
    case STATE_CLEAR_FILTER_INTERNAL:
      return {
        ...initialState
      }
    case STATE_INITIAL_PAGINATION_INTERNAL:
      return {
        ...state,
        total: action.payload
      }
    case ACTIVE_TABLE_MIX:
      return {
        ...state,
        viewTableMix: true
      }
    case INACTIVE_TABLE_MIX:
      return {
        ...state,
        viewTableMix: false
      }
    case STATE_FILTER_POPUP_FILTER_AGREEMENTS_INTERNAL:
      return {
        ...state,
        pag: initialState.pag,
        agreementFilter: {
          ...state.agreementFilter,
          agreementStatus: action.payload
        }
      }
    case STATE_FILTER_POPUP_FILTER_IMPROPER_CLIENTS_INTERNAL:
      return {
        ...state,
        count: 15,
        pag:1,
        total: 15,
        improperClientsFilter: {
          ...state.improperClientsFilter,
          filterOptions: action.payload
        }
        }
    case STATE_FILTER_POPUP_FILTER_DRAFTS_INTERNAL:
      return {
        ...state,
        pag: initialState.pag,
        draftsFilter: {
          ...state.draftsFilter,
          draftsStatus: action.payload
        }
      }
      case STATE_FILTER_POPUP_FILTER_REPORTS_INTERNAL:
      return {
        ...state,
        pag: initialState.pag,
        reportsFilter: {
          ...state.reportsFilter,
          reportStatus: action.payload
        }
      }
      case VIEW_PAGINATION_INTERNAL:
        return {
          ...state,
          count: action.payload.count,
          total: action.payload.total,
        }
    case STATE_FILTER_MASSIVE_DETAIL_INTERNAL:
        return {
          ...state,
          count: 10,
          pag:1,
          total: 0
        }
      case STATE_FILTER_POPUP_FILTER_LOGS_INTERNAL:
        return {
          ...state,
          count: 20,
          pag: 1,
          total: 0,
          logsFilter: {
            action: action.payload
          }
        }
      case STATE_FILTER_REQUIRED_SIGNATURE:
        return {
          ...state,
          searchAdvanced:{
            ...state.searchAdvanced,
            requiredSignature:action.payload
          }
        }
    default:
      return state
  }
}
