import React, { Component } from 'react'
import { MainSegment } from '../../../../../components/Sengment/MainSegment'
import CreateEmployee from './CreateEmployee'

export class AddEmployee extends Component {

  render() {
    return (
      <MainSegment>
        <CreateEmployee />
      </MainSegment>
    )
  }
}
export default AddEmployee