import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import { useDispatch, useSelector } from 'react-redux'
import './input-from-entities.css'
import styled from "styled-components"
import { Form } from "semantic-ui-react"
import { activeFormClient } from "../../../actions/clientActions"
import { cleanErrorsAction } from "../../../actions/errorsActions"

const Text = styled.div`

`
const Text2 = styled.span`
    
`
const InputFormClient = ({ ...props }) => {

    const [errorOnBlur, setErrorOnBlur] = useState(false)
    const [isErrorForm, setIsErrorForm] = useState('')
    const dispatch = useDispatch()
    const { placeholder, type, id, onChange, value, label, validator, validateOptions, defaultValue, disabled, onBlur ,fixedMargin= false,errorQuantity = false,setMessageShown  = null} = props

    let errorValidator = null;
    if (validator && validateOptions)
      errorValidator = validator
        ? validator.message(
            id,
            value,
            validateOptions
          )
        : "";

    const errorsForm = useSelector(state=> state.errorReducer.errors)
    const errors = useSelector(state=> state.errorReducer.errorsForm)
    
    useEffect(() => {
        return () => {
          dispatch(cleanErrorsAction());
        };
      }, []);

    useEffect(() => {

       
      if (!!errorsForm && Object.keys(errorsForm).length > 0) {
            const { formErrors } = errorsForm;
            if(formErrors !== undefined){
                if (formErrors.hasOwnProperty("name")) {
                    setIsErrorForm("name");
                } else if (formErrors.hasOwnProperty("phone")) {
                    setIsErrorForm("phone");
                } else if (formErrors.hasOwnProperty("numberDocumentCompany")) {
                    setIsErrorForm("document");
                } else if (formErrors.hasOwnProperty("codeCompany")) {
                    setIsErrorForm("codeCompany");
                }else {
            
                setIsErrorForm("");
                }
            }
      }

      if (Object.keys(errors).length > 0) {
        if(!!errors.errors && !!errors.errors.formErrors){
            const { formErrors } = errors.errors;
            if (formErrors.hasOwnProperty("name")) {
            setIsErrorForm("name");
            } else if (formErrors.hasOwnProperty("phone")) {
            setIsErrorForm("phone");
            } else if (formErrors.hasOwnProperty("numberDocumentCompany")) {
            setIsErrorForm("document");
            }else if (formErrors.hasOwnProperty("codeCompany")) {
                setIsErrorForm("codeCompany");
            }else if(formErrors.hasOwnProperty("providerBag.quantity")){
                setIsErrorForm("quantity");
            }else{
            setIsErrorForm("");
            }
        }
        if(!!errors.name && errors.hasOwnProperty("name")){
            setIsErrorForm("name");
        }else if(!!errors.phone && errors.hasOwnProperty("phone")){
            setIsErrorForm("phone");
        }else if(!!errors.numberDocumentCompany && errors.hasOwnProperty("numberDocumentCompany")){
            setIsErrorForm("document");
        }else if(!!errors.codeCompany && errors.hasOwnProperty("codeCompany")){
            setIsErrorForm("codeCompany");
        }else if(!!errors.numberDocumentCompany && errors.hasOwnProperty("providerBag.quantity")){
            setIsErrorForm("quantity");
        }else {
            setIsErrorForm("");
        }

       if (!!errors.formErrors ) {
            
            const hasKey = Object.keys(errors.formErrors).some(key => key.includes("agreementCode dup key"));
            if(hasKey){
                setIsErrorForm("agreementCode");
            }
            const hasKeyQ = Object.keys(errors.formErrors).some(key => key.includes("agreementCode"));
            if(hasKeyQ){
                setIsErrorForm("agreementCode");
            }
            const hasKeyQuantity = Object.keys(errors.formErrors).some(key => key.includes("providerBag.quantity"));
            if(hasKeyQuantity){
                setIsErrorForm("quantity");
            }

       }
        if (errors.hasOwnProperty("message") && errors.hasOwnProperty("codeValidate")) {
            if (!!errors.formErrors) {
                const hasKey = Object.keys(errors.formErrors).some(key => key.includes("agreementCode dup key"));
                if(!hasKey){
                    setIsErrorForm(errors.message);
                }
                const hasKeyQ = Object.keys(errors.formErrors).some(key => key.includes("agreementCode"));
                if(hasKeyQ){
                    setIsErrorForm("agreementCode");
                }
                const hasKeyQuantity = Object.keys(errors.formErrors).some(key => key.includes("providerBag.quantity"));
                if(hasKeyQuantity){
                    setIsErrorForm("quantity");
                }
            }else{
                setIsErrorForm(errors.message);
            }
        }
        if (setMessageShown !== null) {
            setMessageShown(true)
        }
        
      }
    }, [errorsForm,errors]);
    

    const validator2 = (value) => {
        if (value !== "") {
            dispatch(activeFormClient())
        }
    }
    
    return (
        <Form.Field className={props.stylesAgreements && 'stylesAgreements'}>
            <div>
                <label className={errorValidator|| isErrorForm === 'agreementCode' && id === 'agreementCode' ||  errors.internalCode === "MNF" && id === 'email' ? 'labelInputFormEntitiesError' : 'labelInputFormEntities'} style={{marginBottom: errorValidator && fixedMargin ? '10px' : '0px'}}>
                    <input
                        id={id}
                        name={id}
                        type={type}
                        value={value}
                        defaultValue={defaultValue}
                        placeholder={placeholder}
                        onChange={(e)=>{
                            if(!!onBlur){
                                setErrorOnBlur(!onBlur())
                            }
                            setIsErrorForm('')
                            onChange(e)
                        }}
                        onBlur={() => {
                            validator2(value)
                            if(!!onBlur){
                                setErrorOnBlur(!onBlur())
                            }
                            }}
                        onKeyDown={(e) => e.keyCode === 13 && e.preventDefault()}
                        className={'inputFormEntities'}
                        disabled={disabled}
                        label={label}
                        autoComplete="off"
                    />
                    {
                        errorValidator && value === ''
                        &&
                        <>
                            <Text2 className={errorValidator && value === '' && 'spanLabelInputTopError'}>{label}</Text2>
                            <Text className={(errorValidator) && 'input-error3'}>{errorValidator}</Text>
                        </>
                    }
                    {
                        errorValidator === undefined && value !== ''
                        &&
                        <>
                            <span className={'spanLabelInputTop'}>{label}</span>
                        </>
                    }
                    {
                        errorValidator === undefined && value === ''
                        &&
                        <>
                            <span className={'spanLabelInput'}>{label}</span>
                        </>
                    }
                    {
                        errorValidator && value !== ''
                        &&
                        <>
                            <Text2 className={'spanLabelInputTopError'}>{label}</Text2>
                            <Text className={'input-error2'}>{errorValidator}</Text>
                        </>
                    }
                    {
                        errorOnBlur && label === 'Código de vinculación (ej: P-01010101)' &&
                        <>
                            <Text2 className={'spanLabelInputTopError'}>{label}</Text2>
                            <Text className={'input-error2'}>{'Campo alfanumérico, min. 3 caracteres y con guión'}</Text>
                        </>
                    }
                    {
                        errorOnBlur && label === 'Nombre' &&
                        <>
                            <Text2 className={'spanLabelInputTopError'}>{label}</Text2>
                            <Text className={'input-error2'}>{'Este campo debe tener una longitud mínima de 3 caracteres'}</Text>
                        </>
                    }
                    {
                        isErrorForm === 'name' && label === 'Nombre' &&
                        <>
                            <Text2 className={'spanLabelInputTopError'}>{label}</Text2>
                            <Text className={'input-error2'}>{'Otro usuario ya tiene registrado este nombre'}</Text>
                        </>
                    }
                  
                    {
                        isErrorForm === 'phone' && label === 'Teléfono' && id !== 'phoneContact1' && id !== 'phoneContact2' &&
                        <>
                            <Text2 className={'spanLabelInputTopError'}>{label}</Text2>
                            <Text className={'input-error2'}>{'Otro usuario ya tiene registrado este número telefónico'}</Text>
                        </>
                    }
                    {
                        isErrorForm === 'document' && label === 'No. documento' &&
                        <>
                            <Text2 className={'spanLabelInputTopError'}>{label}</Text2>
                            <Text className={'input-error2'}>{'Este No. documento ya fue registrado'}</Text>
                        </>
                    }
                    {
                        isErrorForm === 'Dominio de correo electronico no permitido' && id === 'email' &&
                        <>
                            <Text2 className={'spanLabelInputTopError'}>{label}</Text2>
                            <Text className={'input-error2'}>Dominio de email no permitido</Text>
                        </>
                    }

                    {
                    isErrorForm === "El correo electrónico no existe." && id === 'email' &&
                        <>
                            <Text2 className={'spanLabelInputTopError'}>{label}</Text2>
                            <Text className={'input-error2'}>{isErrorForm}</Text>
                        </>
                    }
                    
                    {
                        errorOnBlur && label === 'Dirección' &&
                        <>
                            <Text2 className={'spanLabelInputTopError'}>{label}</Text2>
                            <Text className={'input-error2'}>{'Este campo debe tener una longitud mínima de 1 caracter'}</Text>
                        </>
                    }
                    {
                    isErrorForm === "codeCompany" && id === 'codeCompany' &&
                        <>
                            <Text2 className={'spanLabelInputTopError'}>{label}</Text2>
                            <Text className={'input-error2'}>Este campo ya ha sido registrado</Text>
                        </>
                    }
                    {
                    isErrorForm === "codeCompany" && id === 'providerCode' &&
                        <>
                            <Text2 className={'spanLabelInputTopError'}>{label}</Text2>
                            <Text className={'input-error2'}>Este campo ya ha sido registrado</Text>
                        </>
                    }
                    {
                    isErrorForm === "codeCompany" && id === 'clientCode' &&
                        <>
                            <Text2 className={'spanLabelInputTopError'}>{label}</Text2>
                            <Text className={'input-error2'}>Este campo ya ha sido registrado</Text>
                        </>
                    }
                    {
                    isErrorForm === "agreementCode" && id === 'agreementCode' &&
                        <>
                            <Text2 className={'spanLabelInputTopError'}>{label}</Text2>
                            <Text className={'input-error2'}>Este código ya fue registrado</Text>
                        </>
                    }
                    {isErrorForm === "quantity" && id === 'quantity' &&
                        <>
                            <Text2 className={'spanLabelInputTopError'}>{label}</Text2>
                            <Text className={'input-error2'}>Este valor debe ser superior o igual a 1</Text>
                        </>
                    }
                    {errorQuantity === true &&
                        <>
                            <Text2 className={'spanLabelInputTopError'}>{label}</Text2>
                            <Text className={'input-error2'}>Este valor debe ser superior o igual a 1</Text>
                        </>
                    }
                    
                    
                </label>
            </div>

        </Form.Field>
    )
}


InputFormClient.propTypes = {
    labelProps: PropTypes.object,
    id: PropTypes.string,
}
export default InputFormClient