import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'

import { ReactComponent as LeftArrowIcon } from '../../../../assets/icons/sent/leftArrow.svg'

const Container = styled.div`
  margin: 25px;
  margin-left:15px;
  margin-bottom:0px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
`
const Arrow = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
`
const TitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
`
const Title = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #133F4F;
`
const LeftArrow = styled.button`
  padding: 0;
  margin: 0 16px 0 0;
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor: pointer;
`

const Header = (props) => {


  const { name } = useSelector(state => state.clientReducer.detailClient)

  const handleOnClick = () =>{
    props.history.goBack()
  }


  return (
    <Container>
      <Arrow>
        <LeftArrow
          onClick={handleOnClick}
        >
          <LeftArrowIcon />
        </LeftArrow>
      </Arrow>
      <TitleContainer>
        {
          <Title>
            {`${!!name? name : 'Cliente'} / Notificaciones reportadas con uso indebido`}
          </Title>
        }
      </TitleContainer>
    </Container>
  )
}

Header.propTypes = {
  history: PropTypes.any.isRequired,
}

export default Header
