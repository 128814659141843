import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { ReactComponent as clientIcon } from '../../../../assets/icons/clients/Client-Icon.svg'

const Button = styled.button`
  background: #22C1D0;
  border: 1px solid #22C1D0;
  color: #FFFFFF;
  margin-left: 10px;
  padding: 10px, 32px, 10px, 32px;
  width: 208px;
  height: 48px;
  font-family: Roboto;
  font-weight: Bold;
  font-size: 16px;
  cursor: pointer;

  :disabled{
    background: #c9edf0;
    border: 1px solid #c9edf0;
    color: #FFFFFF;
}
  @media only screen and (min-width: 1024px) and (max-width: 1465px) {
  font-size: 14px;
  width: 130px;
  margin-right: 5px;
}
`
const IconNew = styled(clientIcon)`{
  float:left !important;
  margin: -1px 0px 0px 25px !important;
}
`

class ButtonNewEmployee extends React.Component {

    handleClick = () => {
        this.props.history.push({ pathname: '/createEmployeeProvider' })
    }

    render() {
        return (
            <>
                <Button
                    onClick={this.handleClick}
                    disabled={this.props.employeesCount >= 1 ? true : false}
                >
                    <IconNew />
                    Nuevo Usuario
                </Button>
            </>
        )
    }
}
const mapStateToProps = (state) => ({
    employeesCount: state.clientReducer.employeesCount,
})

export default connect(mapStateToProps)(withRouter(ButtonNewEmployee))
