import React, { Component } from 'react'
import { MainSegment } from '../../components/Sengment/MainSegment'

class Roles extends Component {
  render() {
    return (
      <MainSegment>
        Roles
      </MainSegment>
    )
  }
}

export default Roles
