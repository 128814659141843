import React from 'react'
import PropTypes from 'prop-types'
import ContainerSearch from '../../components/Search/ContainerSearch'
import AllNotifications from './AllNotifications'

const Notifications = (props) => {

  return (
    <>
      <ContainerSearch showAdvancedSearch={true}/>
      <AllNotifications tabFilter={props.tabFilter} history={props.history} />
    </>
  )
}

Notifications.propTypes = {
  history: PropTypes.any.isRequired,
}

export default Notifications
