import React, { Component } from 'react'
import { MainSegment } from '../../../components/Sengment/MainSegment'
import AllSuperAdminImproperClients from './allSuperAdminImproperClients/AllSuperAdminImproperClients'
import ContainerSearchImproperClients from '../../../components/Search/SearchImproperClients/ContainerSearchImproperClients'

class SuperAdminImproperClients extends Component {
  render() {
    return (
      <MainSegment>
        <ContainerSearchImproperClients showAdvancedSearch={true} showFilters={true} showOldestNewest={false} showAZ={true} showPopUpFilter={true}/>
        <AllSuperAdminImproperClients history={this.props.history}/>
      </MainSegment>
    )
  }
}

export default SuperAdminImproperClients
