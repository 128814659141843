import _ from 'lodash'

export const permissionValidate = (props) => {
    switch(props.role){
        case '61b36e2fc34e22351bf8cfb4':
            return props.views.superAdmin
        case '61e59ac8c34e22351b614e41':
            return props.views.operator
        case '64d3c96218d350a93692cd7c':
            return props.views.auditor
        default:
            return props.views.client
    }
  
}

export const permissionButtonValidate = (props) => {

    const {componentButton, role} = props

    switch(props.role){
        case '61b36e2fc34e22351bf8cfb4':
            return componentButton
        default:
            return ''
    }
  
}


