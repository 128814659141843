import React from 'react'
import { connect } from 'react-redux'
import { Popup } from 'semantic-ui-react'
import styled from 'styled-components'

import { actionOrder } from '../../actions/filterDetailActions'
import ButtonIcon from '../Button/IconButton'
import { ReactComponent as OrderIcon } from '../../assets/icons/Order.svg'
import { ReactComponent as Reviewed } from '../../assets/icons/reviewed.svg'
import './main.css'
import PropTypes from "prop-types";
import {chageStateLoadNotificationsList, chageStateLoadCitizensList} from '../../actions/writerActions'


const Divider = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center
`
const Texto = styled.div`
  color: #7C7C74;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  @media only screen and (max-width: 750px) {
    display: none;
  }
`
const PopupFilterOrder = styled(Popup)`
  background: #fff !important;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.25) !important;
  border-radius: 4px !important;
  padding: 0 !important;
  max-width: 260px !important;
  min-width: 190px !important;
  left: -1vw !important;
  border: none !important;

  &:before {
    position: sticky !important;
    display: none !important;
  }
`
const ButtonOption = styled.button`
  color: #7C7C74;
  border: 0;
  background: #fff;
  font-family: Roboto;
  font-weight: normal;
  font-size: 14px;
  height: 32px;
  text-align: left;
  width: 100%;
`
const Text = styled.p`
  color: #7C7C74;
  font-family: Roboto;
  font-weight: normal;
  font-size: 14px;
  padding-left: 40px
`
const ContainerOption = styled.div`
  padding: 8px 0px !important;
  position: absolute !important;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);
`

class PopupOrderDetail extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      show: false, //visibilidad del Popup
      state: this.props.order
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.order !== this.props.order) {
      this.setState({ state: this.props.order })
    }

    if (this.state.state !== prevState.state) {
      this.props.actionOrder(this.state.state)
    }
  }

  toggle = () => {
    this.setState({ show: !this.state.show });

    if (this.props.statusLoadNotifications)
      this.props.chageStateLoadNotificationsList(false)
    else
      this.props.chageStateLoadCitizensList(false)
  }

  handleButtonClick = (newOrder) => {
    this.setState({ state: newOrder });
    // Cerrar el Popup
    this.setState({ show: false });
  }

  render() {
    return (
      <Divider>
        <PopupFilterOrder position='bottom left' on='click' onClose={this.toggle} open={this.state.show}
          trigger={
            <ButtonIcon onClick={this.toggle}>
              <Texto> Ordenar</Texto>
              <OrderIcon />
            </ButtonIcon>
          } >
          <ContainerOption>
            {this.props.showOldestNewest &&
              <>
                {!!this.props.hideOptions && !!this.props.hideOptions.find(hide => hide === 'masReciente') ? <></> :
                  <ButtonOption className='buttonOptionHover' onClick={() => this.handleButtonClick('masReciente')}>
                    {this.state.state === 'masReciente' && <Reviewed className='iconReReviewed' />}
                    {this.state.state !== 'masReciente' ? <Text>{this.props.options === 'reports' ? 'Fecha generación (más reciente)' : 'Más reciente'}</Text> : this.props.options === 'reports' ? 'Fecha generación (más reciente)' : 'Más reciente'}
                  </ButtonOption>
                }
                {!!this.props.hideOptions && !!this.props.hideOptions.find(hide => hide === 'masAntiguo') ? <></> :
                  <ButtonOption className='buttonOptionHover' onClick={() => this.handleButtonClick('masAntiguo')} >
                    {this.state.state === 'masAntiguo' && <Reviewed className='iconReReviewed' />}
                    {this.state.state !== 'masAntiguo' ? <Text>{this.props.options === 'reports' ? 'Fecha generación (más antigua)' : 'Más antiguo'}</Text> : this.props.options === 'reports' ? 'Fecha generación (más antigua)' : 'Más antiguo'}
                  </ButtonOption>
                }
              </>
            }
            {this.props.showAZ &&
              <>
                {!!this.props.hideOptions && !!this.props.hideOptions.find(hide => hide === 'A-Z') ? <></> :
                  <ButtonOption className='buttonOptionHover' onClick={() => this.handleButtonClick('A-Z')}>
                    {this.state.state === 'A-Z' && <Reviewed className='iconReReviewed' />}
                    {this.state.state !== 'A-Z' ? <Text>{this.props.options === 'reports' ? 'Usuario (A-Z)' : 'A-Z'}</Text> : this.props.options === 'reports' ? 'Usuario (A-Z)' : 'A-Z'}
                  </ButtonOption>
                }
                {!!this.props.hideOptions && !!this.props.hideOptions.find(hide => hide === 'Z-A') ? <></> :
                  <ButtonOption className='buttonOptionHover' onClick={() => this.handleButtonClick('Z-A')}>
                    {this.state.state === 'Z-A' && <Reviewed className='iconReReviewed' />}
                    {this.state.state !== 'Z-A' ? <Text>{this.props.options === 'reports' ? 'Usuario (Z-A)' : 'Z-A'}</Text> : this.props.options === 'reports' ? 'Usuario (Z-A)' : 'Z-A'}
                  </ButtonOption>
                }
              </>
            }
          </ContainerOption>
        </PopupFilterOrder>
      </Divider>
    )
  }
}

PopupOrderDetail.propTypes = {
  hideOptions: PropTypes.array,
  order: PropTypes.string
}

const mapStateToProps = (state) => ({
  order: state.filterDetailReducer.sort,
  statusLoadNotifications: state.writerReducer.loadNotifications
})

const mapDispatchToProps = {
  actionOrder,
  chageStateLoadNotificationsList,
  chageStateLoadCitizensList,
}

export default connect(mapStateToProps, mapDispatchToProps)(PopupOrderDetail)