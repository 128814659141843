import React, { Component } from 'react'
import { MainSegment } from '../../components/Sengment/MainSegment'
import AllReports from './allReports/AllReports'
import ContainerSearchReports from '../../components/Search/SearchReports/ContainerSearchReports'

class Reports extends Component {
  render() {
    return (
      <MainSegment>
        <ContainerSearchReports showAdvancedSearch={true} />
        <AllReports history={this.props.history}/>
      </MainSegment>
    )
  }
}

export default Reports
