export const ValidateMessageErrors = (type, errorValidator, value) => {
    const validateMessage = (type) => {
        const data = {
            write: () =>{
                if(errorValidator && value !== ''){
                    if(value.length <3 || value.length >300){
                        return  'minLength'
                    }else{
                        return 'specialCharacters'
                    }
                }else{
                    return ''
                }
            }
        }
        return data[type]()
    }

    const keyMessage = validateMessage(type)
    return (keyMessage)
}
