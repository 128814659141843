import React, {Component} from 'react'
import {Form, Label, Icon, Divider} from "semantic-ui-react"

import PropTypes from "prop-types";
import {
  addMassiveRecipientsList, changeWriteNotificationForm,
  filterMassiveRecipients,
  getRecipientv2, replaceRecipientDisplay, setMassiveRecipientFileInfo, updateHistoryMassive,
  updateRecipientDisplay, changeDisabledGetRecipient,
} from "../../actions/writerActions";
import {warningFormModalWrite} from '../../actions/modalActions'
import { saveDraftData, saveDraftV2, deleteDraftRecipients} from '../../actions/draftsActions';
import {connect} from "react-redux";
import cogoToast from "cogo-toast";
import {toastOptions} from "../../helpers/globalHelpers";
import styled, {css} from "styled-components";
import './dropdownStyle.css'
import DropdownOptions from "./DropdownOptions";
import {ReactComponent as IconVector} from '../../assets/icons/write/vector.svg'
import {ReactComponent as IconToClose} from '../../assets/icons/write/to-close.svg'
const FormField = styled(Form.Field)`
  width: 100%;
  min-width: 0;
`

const LabelFor = styled.label`
  display: inline-block;
  width: 6%;
  position: absolute;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 14px;
  ${props => props.labelerrorstatus &&
          css`
            color: ${props.labelerrorstatus};
          `} 
`
const DivRecipientsAdded = styled.div`
  max-width: 63%;
  line-height: 1em;
  overflow-x: hidden;
  width:0;
  ${props => props.fieldHeightDivFocused &&
          css`
            overflow-y: ${props.fieldHeightDivFocused.overflowY};
            z-index: ${props.fieldHeightDivFocused.zIndex};
          `}
  position: absolute;
  background-color: white;
  margin-left: 40px;
  top:-8px;
  @media only screen and (max-width: 1500px)  {
    max-width: 71%;

  }

  @media only screen and (max-width: 992px)  {
    max-width: 100%;
  }

`
const DivRecipientsMassiveAdded = styled.div `
  max-width: 63%;
  line-height: 1em;
  overflow-x: hidden;
  
  overflow-y: hidden;
   z-index: 1;
   position: absolute;
  background-color: white;
  margin-left: 40px;
  top:-8px;
  @media only screen and (max-width: 1500px)  {
    max-width: 80%;

  }
`
const LabelItemsFor = styled(Label)`
  max-width: 200px;
  max-height: 30px;
  overflow: hidden;
  position:relative;
  text-overflow: ellipsis;
  border-radius: 15px !important;
  font-family: 'Mulish' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  height: 98%;
  padding: 5px 8px 5px 8px !important;
  padding-right: 20px !important;
  margin-bottom: 2px !important;
  ${props => props.labelerrorstatusitem &&
          css`
            color: ${props.labelerrorstatusitem.color} !important;
            background: ${props.labelerrorstatusitem.background} !important;
            border: ${props.labelerrorstatusitem.border} !important;
          `}
`
const InputForInside = styled.input`
  outline: none;
  border: none;
  border-radius: 0;
  padding: 0;
  line-height: 1.21428571em;
  font-size: 16px;

  ${props => props.fieldwidthinside &&
          css`
            width: ${props.fieldwidthinside};
          `}
  ::placeholder {
    color: #BA3555;
    padding-bottom: 1px;
  }
`

const InputForOutside = styled.input`
  width:100%;
  display: inline-block;
  padding-left: 45px;
  outline: none;
  border-radius: 0;
  line-height: 1.21428571em;
  font-size: 16px;
  border: none;
  ${props => props.fieldwidthoutside &&
          css`
            visibility: ${props.fieldwidthoutside.visibility};
            z-index: ${props.fieldwidthoutside.zIndex};
          `}
  ::placeholder {
    color: #BA3555;
  }
`

const DividerInputFor = styled(Divider)`
  position: absolute;
  width: 75%;
  line-height: 1px;
  z-index: 0;
  top: 28px;
  @media only screen and (max-width: 992px)  {
    width: 100%;
  }
  ${props => props.mandatoryfield && 
          css`
            background-color: ${props.mandatoryfield.borderColor};
          `} 
`

const ErrorLabel = styled.label`
  display: inline-block;
  width: max-content;
  height: 5%;
  top: 30px;
  position: absolute;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #BA3555;
  left: 6.19%;
`
const IconDelete = styled(IconToClose)`
  width: 9px;
  height: 9px;
  margin-left: 10px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 7px;

`
class DropdownAllowAddition extends Component {
  constructor(props) {
    super(props)
    this.state = {
      focused: false,
      onValidation: false,
      onEdition: false,
      historyTo: '',
      historyVisible: false,
      valueToLocal: {},
    }
    this.textInputInside = React.createRef();
    
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = prevState
    if (nextProps.onEdition !== prevState.onEdition)
      update.onEdition = nextProps.onEdition
    return update
  }

  handleKeyDown = async evt => {
    if (["Enter", ","].includes(evt.key)) {
      this.props.changeDisabledGetRecipient(true)
      evt.preventDefault()
      if (this.props.listIdsSendMassive.ids.length <= 100) {
        const value = this.props.value && this.props.value.trim()
        if (value && this.isValid(value)) {
          await this.performAction(value)
          this.textInputInside.current.focus()
        }else{
          this.props.changeDisabledGetRecipient(false)
        }
      } else
        cogoToast.error("Has alcanzado el límite máximo de destinatarios", toastOptions)
    }

    if (["Tab"].includes(evt.key)) {
      this.props.changeDisabledGetRecipient(true)
      if (this.props.listIdsSendMassive.ids.length <= 100) {
        const value = this.props.value && this.props.value.trim()
        if (value && this.isValid(value)) {
          await this.performAction(value)
        }else{
          this.props.changeDisabledGetRecipient(false)
        }
      } else
        cogoToast.error("Has alcanzado el límite máximo de destinatarios", toastOptions)
    }
  }
  handleOnBlurInput = async (value) =>{
    if (value && this.isValid(value)) {
      await this.performAction(value)
      const recipients = this.props.items.map(item => item.key);
      await this.saveDraft(recipients)
    }else{
      this.props.changeDisabledGetRecipient(false)
    }
  }

  performAction = async (value) => {
    const recipientsData = await this.calculateRecipient(value)
    if (!!recipientsData) {
      this.props.updateRecipientDisplay({key: recipientsData.ids[0], value: recipientsData.fullData[0]})
    } else {
      cogoToast.error("Ocurrio un error obteniendo datos del destiantario", toastOptions)
    }
    let errorObj = {status: false, content: ''}
    if (this.isInvalidRecipient(this.props.listIdsSendMassive.backendRequest))
      errorObj = {status: true, content: 'Existen registros no válidos'}
    this.props.changeState({
      value: "",
      recipientError: {status: errorObj.status, content: errorObj.content}
    })
    this.resetInputValues()
  }

  resetInputValues = () => {
    Object.keys(this.state.valueToLocal).forEach((key, index) => {
      this.setState((state) => {
        const newObject = {...state.valueToLocal};
        newObject[`${index}`] = {value: '', key: index, width: this.fieldWidthInside('')}
        return {valueToLocal: newObject}
      })
    })
  }

  handleChange = (evt, index) => {
    evt.persist()
    if (evt.target.value === '' || this.isNumber(evt.target.value)) {
      this.props.changeState({
        value: evt.target.value,
        recipientError: {status: false, content: ''}
      })
      if (index !== -1) {
        this.setState((state) => {
          const newObject = {...state.valueToLocal};
          newObject[`${index}`] = {value: evt.target.value, key: index, width: this.fieldWidthInside(evt.target.value)}
          return {valueToLocal: newObject}
        })
      }
      this.setState({
        focused: false,
        onValidation: false,
        isHovering: false,
        historyVisible: false,
      })
    }
  }

  calculateRecipient = async (idNumber) => {
    return await this.props.getRecipientv2({documentNumber: idNumber}).then(async () => {
      let allInfo
      let ids = []
      let backendRequest = []
      let fullData = []
      if (!this.isRecipientDataInfo(idNumber)) {
        ids.push(idNumber)
        fullData.push(idNumber)
        backendRequest.push({documentNumber: idNumber})
        allInfo = {ids: ids, fullData: fullData, backendRequest: backendRequest}
        await this.props.addMassiveRecipientsList(allInfo)
      } else {
        
        const name = this.props.recipientData.variableInformation
        const obj = {
          documentNumber: idNumber,
          firstName: this.props.recipientData.firstName,
          secondName: this.props.recipientData.secondName,
          surname: this.props.recipientData.surname,
          secondSurname: this.props.recipientData.secondSurname
        }
        ids.push(idNumber)
        fullData.push(name)
        backendRequest.push(obj)
        allInfo = {ids: ids, fullData: fullData, backendRequest: backendRequest}
        await this.props.addMassiveRecipientsList(allInfo)
        if (this.isValidHistory(idNumber))
          await this.props.updateHistoryMassive([{value: idNumber, key: idNumber}])
      }
      return allInfo
    }).catch((err) => {
      console.error(err)
      return null
    })
  }

  isRecipientDataInfo = (idNumber) => {
    return !!(Object.keys(this.props.recipientData).length && this.props.recipientData.documentNumber === idNumber)
  }

  handleDelete = async item => {
    if (this.isNumber(item.key))
      await this.removeReduxRecipientsNormal(item)
    else
      await this.removeReduxRecipientsFromFile(item)
    if (!this.isInvalidRecipient(this.props.listIdsSendMassive.backendRequest))
      this.props.changeState({
        recipientError: {status: false, content: ''}
      })
  }

  handleDeleteMassive = async item => {
    this.props.warningFormModalWrite({ type: 'warningDeleteMassiveFor' })
  }
  
  removeReduxRecipientsNormal = async (item) => {
    let allInfo
    const resultIds = this.props.listIdsSendMassive.ids.filter((arrayValueElement) => arrayValueElement !== item.key)
    const resultFullData = this.props.listIdsSendMassive.fullData.filter((arrayValueElement) => arrayValueElement !== item.value)
    const resultBackedReq = this.props.listIdsSendMassive.backendRequest.filter((arrayValueElement) => arrayValueElement.documentNumber !== item.key)
    allInfo = {ids: resultIds, fullData: resultFullData, backendRequest: resultBackedReq}
    await this.props.filterMassiveRecipients(allInfo)
    const resultItems = this.props.items.filter(i => i.key !== item.key)
    await this.props.replaceRecipientDisplay(resultItems)
  }

  removeReduxRecipientsFromFile = async (fileId) => {
    let allInfo
    let resultFullData = []
    let resultBackedReq = []
    const resultIds = this.props.listIdsSendMassive.ids.filter((arrayValueElement) => !arrayValueElement.includes(fileId.key))
    for (const resultId of resultIds) {
      resultFullData = this.props.listIdsSendMassive.fullData.filter((arrayValueElement) => arrayValueElement !== resultId.split("_")[0])
      resultBackedReq = this.props.listIdsSendMassive.backendRequest.filter((arrayValueElement) => arrayValueElement.documentNumber !== resultId.split("_")[0])
    }
    allInfo = {ids: resultIds, fullData: resultFullData, backendRequest: resultBackedReq}
    await this.props.filterMassiveRecipients(allInfo)
    const resultItems = this.props.items.filter(i => i.key !== fileId.key)
    await this.props.setMassiveRecipientFileInfo(resultItems)
    await this.props.replaceRecipientDisplay(resultItems)
  }


  isValid(idNumber) {
    let recipientError = {status: false, content: ''}
    if (this.isInList(idNumber)) recipientError = {
      status: true,
      content: `${idNumber} ya se encuentra en la lista de destinatarios.`
    }
    if (recipientError.status) {
      cogoToast.info(recipientError.content, toastOptions)
      return false
    }
    return true
  }

  isValidHistory(idNumber) {
    return !this.isInListHistory(idNumber);
  }

  isInList(idNumber) {
    return this.props.items.some(e => e.key === idNumber)
  }

  isInListHistory(idNumber) {
    return this.props.historyRecipient.some(e => e.key === idNumber)
  }

  isInvalidRecipient = (backendRequestDataArray) => {
    return backendRequestDataArray.filter(e => !e.firstName).length > 0
  }

  isRecipientEmpty = (backendRequestDataArray) => {
    return backendRequestDataArray.length <= 0 || this.props.items.length <= 0;
  }

  isNumber(idNumber) {
    return /^[0-9\b]+$/.test(idNumber)
  }

  onBlurDropdown = async () => {
    if(!this.props.isMassiveRecipient)
      this.setState({focused: false, onValidation: true, historyVisible: false})
      const recipients = this.props.items.map(item => item.key);
      this.saveDraft(recipients)
  }

  saveDraft = async (recipients) => {
    if (recipients.length) {
      await this.props.saveDraftData({recipients})
      const { draftData } = this.props;
      await this.props.saveDraftV2(draftData);
    }
  }

  onClickDropdown = () => {
    if(!this.props.isMassiveRecipient){

      this.props.changeWriteNotificationForm('onEdition', true)
      this.setState({focused: true, onValidation: false, isHovering: true, historyVisible: true}, () => {
        if (!!this.props.items.length)
        this.textInputInside.current.focus()        
      })
    }
  }

  fieldHeightDivFocused = (listSize) => {
    if (listSize > 1)
      return {overflowY: `visible`, zIndex: `1`}
    return {overflowY: `hidden`, zIndex: `0`}
  }

  mandatoryField = (editing, isInvalidRecipient, isRecipientEmpty, onValidation) => {
    if (onValidation)
      if (editing && isInvalidRecipient) {
        return {borderHeight: `2px`,borderColor: `#BA3555`, positionTop:`28px`}
      } else if (editing && isRecipientEmpty){
        return {borderHeight: `2px`,borderColor: `#BA3555`, positionTop:`27px`}
      }
    
    if(!isRecipientEmpty){
      return {borderHeight: `1px`,borderColor: `rgba(34,36,38,.15)`, positionTop:`28px`}
    }else{
      return {borderHeight: `1px`,borderColor: `rgba(34,36,38,.15)`, positionTop:`27px`}
    }
  }

  fieldWidthVisibilityOutside = (listSize) => {
    if (listSize === 0) {
      return {width: `60%`, visibility: `visible`, zIndex: `1`}
    }
    return {width: `0%`, visibility: `hidden`, zIndex: `0`}
  }

  fieldWidthInside = (typedText) => {
    if (typedText.length === 0)
      return `3%`
    return `${typedText.length * 3}%`
  }

  handleMouseHover = () => {
    this.setState({isHovering: true, focused: false})
  }

  handleMouseOut = () => {
    this.setState({isHovering: false})
  }

  labelErrorStatus = (errorStatus) => {
    if (errorStatus) {
      return `#BA3555`
    }
    return `#B2B2B2`
  }

  labelErrorStatusItem = (itemValue) => {
    if (this.isNumber(itemValue)) {
      return {color: `#BA3555`, background: `rgba(196, 19, 46, 0.05)`, border: `1px solid #C3132E`}
    }
    return {color: `#3C3C3B`, background: `#F3F3F3`, border: `1px solid #C1C1C1`}
  }

  onFocusInnerInput = () => {
    this.setState({historyVisible: false})
  }

  /**
   * Options Part
   */

  changeOptionsState = (data) => {
    this.setState((prevState) => ({
      ...prevState,
      historyTo: data.value === undefined ? "" : data.value,
    }), async () => {
      if (this.state.historyTo && this.isValid(this.state.historyTo)) {
        await this.performAction(this.state.historyTo)
        const recipients = this.props.items.map(item => item.key);
        await this.saveDraft(recipients)
      }else{
        this.props.changeDisabledGetRecipient(false)
      }
      this.setState({historyVisible: false})
    })
  }

  handleClickAway = () => {
  }

  render() {
    const {validator, label, validateOptions, required} = this.props
    let errorValidator = validator ? validator.message(label !== '' && label !== undefined ? label : '', this.propsvalue, validateOptions) : ''
    return (
      <FormField onBlur={this.onBlurDropdown} onClick={this.onClickDropdown}
                 error={!!this.props.recipientError.status || !!errorValidator} required={required && true}>
        <LabelFor labelerrorstatus={this.labelErrorStatus(this.props.recipientError.status)}>{label}</LabelFor>
        <InputForOutside
          value={this.props.value}
          placeholder={!this.state.focused && this.state.onEdition && this.props.items.length <= 0 ? "Campo obligatorio" : ""}
          onKeyDown={this.handleKeyDown}
          onBlur= {() => this.handleOnBlurInput(this.props.value)}
          onChange={(event) => this.handleChange(event, -1)}
          fieldwidthoutside={this.fieldWidthVisibilityOutside(this.props.items.length)}
          mandatoryfield={this.mandatoryField(this.state.onEdition, this.isInvalidRecipient(this.props.listIdsSendMassive.backendRequest), this.isRecipientEmpty(this.props.listIdsSendMassive.backendRequest), this.state.onValidation)}
          disabled={this.props.isDisabledGetRecipient}
          fitted/>
        {this.props.isMassiveRecipient? 
            <DivRecipientsMassiveAdded>
                <LabelItemsFor labelerrorstatusitem={this.labelErrorStatusItem(this.props.items)}>
                  {this.props.items}
                  <IconDelete name='delete' onClick={() => this.handleDeleteMassive("massiveFor")}/>
                </LabelItemsFor>
            </DivRecipientsMassiveAdded>
        
          :  
            <DivRecipientsAdded onMouseOut={this.handleMouseOut} onMouseOver={this.handleMouseHover} onBlur={this.handleOnBlur}
                                fieldHeightDivFocused={this.fieldHeightDivFocused(this.props.items.length)}
                                className={(this.state.focused || this.state.isHovering) && !!this.props.items.length  ? "dropdownForNotFocus" : (!!this.props.items.length)?"dropdownForNotFocus":''}
            >
          {this.props.items.map((item) => (
            <>
              <LabelItemsFor labelerrorstatusitem={this.labelErrorStatusItem(item.value)} key={item.key}>
                {item.value}
                  <IconDelete name='delete' onClick={() => this.handleDelete(item)}/>
              </LabelItemsFor>
            </>
          ))}
          
          <InputForInside
            ref={this.textInputInside}
            value={!!this.state.valueToLocal[0] && !!this.state.valueToLocal[0].value ? this.state.valueToLocal[0].value : ''}
            onKeyDown={this.handleKeyDown}
            onBlur= {() => this.handleOnBlurInput(this.props.value)}
            onChange={(event) => this.handleChange(event, 0)}
            fieldwidthinside={!!this.state.valueToLocal[0] && !!this.state.valueToLocal[0].width ? this.state.valueToLocal[0].width : '3%'}
            onFocus={this.onFocusInnerInput}
            disabled={this.props.isDisabledGetRecipient}
          />
        </DivRecipientsAdded>
          }
        <>
          <div className='iconArrowDiv'>
            <IconVector/>
          </div>
          <DividerInputFor
            mandatoryfield={this.mandatoryField(this.state.onEdition, this.isInvalidRecipient(this.props.listIdsSendMassive.backendRequest), this.isRecipientEmpty(this.props.listIdsSendMassive.backendRequest), this.state.onValidation)}
            fitted/>
        </>
        <DropdownOptions
          value={this.state.historyTo}
          items={this.props.historyRecipient}
          changeOptionsState={this.changeOptionsState}
          visible={this.state.historyVisible}
        />
        {
          this.props.recipientError.status &&
          <ErrorLabel size={'mini'}>{this.props.recipientError.content}</ErrorLabel>
        }
        {
          (errorValidator && !this.props.recipientError.status) &&
          <Label prompt basic pointing>{errorValidator}</Label>
        }
      </FormField>
    )
  }
}

DropdownAllowAddition.propTypes = {
  updateRecipientDisplay: PropTypes.func,
  replaceRecipientDisplay: PropTypes.func,
  getRecipientv2: PropTypes.func,
  recipientData: PropTypes.object,
  addMassiveRecipientsList: PropTypes.func,
  listIdsSendMassive: PropTypes.object,
  filterMassiveRecipients: PropTypes.func,
  setMassiveRecipientFileInfo: PropTypes.func,
  changeWriteNotificationForm: PropTypes.func,
  onEdition: PropTypes.bool,
  updateHistoryMassive: PropTypes.func,
  historyRecipient: PropTypes.array,
  isMassiveRecipient: PropTypes.bool,
  warningFormModalWrite: PropTypes.func,
}
const mapStateToProps = (state) => ({
  recipientData: state.writerReducer.recipientData,
  listIdsSendMassive: state.writerReducer.listIdsSendMassive,
  onEdition: state.writerReducer.notificationForm.onEdition,
  historyRecipient: state.writerReducer.historyRecipient,
  draftData : state.draftReducer.toSaveDraft,
  isMassiveRecipient: state.writerReducer.isMassiveRecipient,
  isDisabledGetRecipient: state.writerReducer.isDisabledGetRecipient,
})
const mapDispatchToProps = {
  updateRecipientDisplay,
  replaceRecipientDisplay,
  getRecipientv2,
  addMassiveRecipientsList,
  filterMassiveRecipients,
  setMassiveRecipientFileInfo,
  changeWriteNotificationForm,
  updateHistoryMassive,
  saveDraftData,
  deleteDraftRecipients,
  saveDraftV2,
  warningFormModalWrite,
  changeDisabledGetRecipient,
}

export default connect(mapStateToProps, mapDispatchToProps)(DropdownAllowAddition)
