import React, { Component } from 'react'
import { MainSegment } from '../../../../components/Sengment/MainSegment'
import CreateAgreements from './CreateAgreements'

export class AddAgreements extends Component {

    render() {
        return (
            <MainSegment>
                <CreateAgreements />
            </MainSegment>
        )
    }
}
export default AddAgreements