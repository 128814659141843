import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'

import GeneralTable from '../../../components/Table/GeneralTable'
import RowTableUSers from '../RowTableUsers'
import { getUsers } from '../../../actions/usersActions'
import { actionInitialPagination, actionClearFilter, activePagination } from '../../../actions/filterActions'

class Users extends Component {

  static propTypes = {
    history: PropTypes.any.isRequired,
  }

  state = {
    loader: true,
    columnData: [
      { name: "Nombre", width: 5, arrow: false },
      { name: "Documento", width: 2, arrow: false },
      { name: "Email", width: 3, arrow: false },
      { name: "Rol", width: 2, arrow: false },
      { name: "Activo", width: 3, arrow: false },
    ],
    init: true,
  }

  componentDidMount() {
    this.makeUsersRequest(this.props.filterReducer)
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.filterReducer, this.props.filterReducer) && !this.state.init) {

      this.makeUsersRequest(this.props.filterReducer)
      this.setState({init: true, loader: true })
    } 
  }

  makeUsersRequest = (filterReducer) => {
    const body = {
      count: filterReducer.count,
      pag: filterReducer.pag,
      sort: this.orderTranslator(filterReducer.sort),
      textToSearch: filterReducer.searchBar[0]
    }

    this.props.getUsers(body).then(() => {
      this.props.activePagination(false)
      this.setState({init: false, loader: false })
    })
  }

  orderTranslator = (order) => {
    const options = {
      'masReciente': { "_id": -1 },
      'masAntiguo': { "_id": 1 },
      'A-Z': { "firstName": 1 },
      'Z-A': { "firstName": -1 }
    }
    return options[order]
  }

  render() {
    return (
      <GeneralTable
        list={this.props.users}
        history={this.props.history}
        headerTable={this.state.columnData}
        rowComponent={RowTableUSers}
        loader={this.state.loader} 
        typeList={this.props.filterReducer.isSearch === true ? 'default' : 'users'}
        listHeight={'users'}
        noHover = {this.props.noHover}/>
    )
  }
}

const mapStateToProps = (state) => ({
  users: state.userReducer.listUsers,
  filterReducer: state.filterReducer,
  elementsCount: state.userReducer.elementsCount,
})

const mapDispatchToProps = {
  getUsers,
  actionClearFilter,
  actionInitialPagination,
  activePagination
}

export default connect(mapStateToProps, mapDispatchToProps)(Users)