import React from 'react'
import {Header as SemanticHeader} from 'semantic-ui-react'
import styled from 'styled-components'

const CustomHeader = styled.div`
  & h1 {
    color: #0E3B52 !important;
    font-size: 18pt !important;
    font-weight: 700 !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 32px !important;
  }

  & h2 {
    color: #0E3B52 !important;
    font-size: 16pt !important;
    font: Roboto !important;
    font-weight: 700 !important;
  }

  & h3 {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    color: #0E3B52 !important;
  }

  & h4 {
    color: #0E3B52 !important;
    font-size: 8pt !important;
    font: Roboto !important;
    font-weight: 700 !important;
  }
`

export default (props) => {
  return (
    <CustomHeader>
      <SemanticHeader as={props.as}>
        {props.children}
      </SemanticHeader>
    </CustomHeader>
  )
}
