import { initial } from "lodash"
import {
  CHANGE_WRITE_NOTIFICATION_FORM,
  CHANGE_WRITE_NOTIFICATION_FORM_ADD,
  CHANGE_ATTACHED_DOCUMENT_SIGNATURE,
  CHANGE_ATTACHED_DOCUMENT_SIGNATURE_SPECIFIC,
  SAVE_NOTIFICATION,
  LOGOUT,
  CLEAN_WRITER_fORM,
  CHANGE_HEADER_CHECKBOX,
  CHANGE_DATE_PREVIEW,
  GET_RECIPIENT,
  CHANGE_ATT_BUTTON,
  CLEAR_EDITOR_CHANGE,
  CHANGE_COLOR_TEXT_EDITOR,
  HISTORY_RECIPIENT,
  ADD_MASSIVE_RECIPIENTS_LIST,
  SET_MASSIVE_RECIPIENTS_LIST_FILE_INFO,
  CLOSE_LOAD_MASSIVE_RECIPIENTS_LIST,
  UPDATE_RECIPIENT_DISPLAY,
  HISTORY_RECIPIENT_MASSIVE,
  LOADING_SEND_NOTIFICATION,
  ADD_MASSIVE_RECIPIENTS_FILE_INFO,
  FILTER_MASSIVE_RECIPIENTS,
  IS_MASSIVE_RECIPIENT,
  REPLACE_RECIPIENT_DISPLAY,
  LOADING_SEND_NOTIFICATION_MASSIVE,
  SAVE_NOTIFICATION_MASSIVE_SUCCESS,
  SAVE_HIRED,
  CHANGE_TEXT_EDITOR,
  CHANGE_LABEL_NAME,
  STATE_VALIDATE_FILE_MASIVE,
  CHANGE_STATE_VIEW_WRITTER,
  INACTIVE_STATE_FORM_NOTIFICATION,
  ACTIVE_STATE_FORM_NOTIFICATION,
  GET_ATTRIBUTE_LIST,
  SET_FIELD_PARAMETRIZATION,
  SAVE_FILE_MASSIVE,
  STATE_RECORDS_FILE_MASSIVE,
  STATE_RECORDS_FILE_MASSIVE_PARAMETRIZATION,
  CLEAR_RECORDS_FILE_MASSIVE_PARAMETRIZATION,
  DRAFT_TO_WRITE,
  SET_FILE_SERIAL_ID,
  STATE_NOTIFICATION_PROGRESS,
  CHANGE_STATE_LOAD_CITIZENS,
  CHANGE_STATE_LOAD_NOTIFICATIONS,
  CHANGE_STATE_LOAD_CITIZENS_LIST,
  CHANGE_STATE_LOAD_NOTIFICATIONS_LIST,
  CLEAN_WRITER_FORM_ONLY,
  DELETE_ITEM_FOR_MASSIVE,
  SET_DRAFT_SERIAL_ID,
  DELETE_LOAD_CITIZEN_ITEM,
  DELETE_LOAD_NOTIFICATION_ITEM,
  CREATE_NEW_TAGS,
  CHANGE_STATE_CITIZENS_CHARGING,
  CHANGE_STATE_NOTIFICATIONS_CHARGING,
  CHANGE_STATE_SEND_EMAIL,
  SPECIFIC_DOCUMENTS,
  CHANGE_DISABLED_RECIPIENT,
  RESET_FIELD_PARAMETRIZATION,
  FILE_MASSIVE_DATA,
  DELETE_RECIPIENTS_FROM_MASSIVE,
  DOWNLOADED_USERS_GUIDE
} from "../actions/actionsUtilities/types.js"

const initialState = {
  notificationForm: {
    recipient: [],
    subject: '',
    textEditor: '',
    files: {},
    notificationSignature: false,
    readReceipt: false,
    pushNotification: false,
    textEditorFromDraft: '',
    editorText: [
      {
        children: [{ text: '' }],
      }
    ],
    clearEditor: false,
    colorTextEditor: '#3C3C3B',
    onEdition: false,
    tags: {
      nombres: '[nombres]',
      nombres_apellidos: '[nombres_apellidos]',
      apellidos: '[apellidos]',
      primer_nombre: '[Primer_nombre]',
      primer_apellido: '[Primer_apellido]',
      cedula: '[cedula]',
    },
    parametrizationTags:[],
    chargingFiles:[],
    notificationCount: 1,
    documentSignature:false,
  },
  draftSerialID: '',
  recipientData: {},
  listIdsSendMassive: { ids: [], fullData: [], backendRequest: [] },
  fileSerialID: '',
  isMassiveRecipient: false,
  massiveRecipientFileInfo: [],
  historyRecipient: [],
  checkboxHeaderDisabled: false,
  arrowDisabled: false,
  datePreview: '',
  savedNotification: [],
  filesDescription: [],
  disableAtt: false,
  loadingSendNotification: false,
  loadingSendNotificationMassive: {
    status: false,
    numberSent: 0,
    totalNotifications: 0,
    percentage: 0,
    abortSend: false,
  },
  savedNotificationMassive: [],
  notificationsAvailable: 0,
  hiredData: {
    servicesList: {
      notificationSignature: true,
      notificationReading: true,
      pushNotification: true,
    }
  },
  textEditorTwo: '',
  resultValidateFileMassive: {},
  viewState: true,
  statusFormNotification: false,
  fieldParametrizationData: {
    columnList : [],
  },
  savedFileMassive: [],
  resultRecordFileMassive: {
    listFileSearchMassive: []
  },
  resultRecordParametrization: {
    listFileSearchMassive: [{
      citizenValidate: 0,
      totalRecordsVerified: 0,
      totalRecordsToVerify: 0,
    }]
  },
  whiteTime: 3000,
  resultNotificationsProgress:{
    progress:[]
  },
  loadNotifications: false,
  loadCitizens: false,
  loadCitizensList: false,
  loadNotificationsList: false,
  citizenIsCharging: false,
  notificationsIsCharging:false,
  IsSendEmail: false,
  listDocumentsSpecific: [],
  isDisabledGetRecipient: false,
  fileMassive:{
    massiveDataExtension: '',
    massiveDataName: '',
    massiveData64:''
  },
  userGuideURl: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SAVE_NOTIFICATION:
      return {
        ...state,
        savedNotification: action.payload
      }
    case SAVE_NOTIFICATION_MASSIVE_SUCCESS:
      return {
        ...state,
        savedNotificationMassive: action.payload
      }
    case LOADING_SEND_NOTIFICATION:
      return {
        ...state,
        loadingSendNotification: action.payload
      }
    case LOADING_SEND_NOTIFICATION_MASSIVE:
      return {
        ...state,
        loadingSendNotificationMassive: {
          ...state.loadingSendNotificationMassive,
          [action.payload.key]: action.payload.value
        }
      }
    case CHANGE_WRITE_NOTIFICATION_FORM:
      return {
        ...state,
        notificationForm: {
          ...state.notificationForm,
          [action.payload.key]: action.payload.value
        }
      }
    case UPDATE_RECIPIENT_DISPLAY:
      return {
        ...state,
        notificationForm: {
          ...state.notificationForm,
          recipient: [...state.notificationForm.recipient, action.payload]
        }
      }
    case REPLACE_RECIPIENT_DISPLAY:
      return {
        ...state,
        notificationForm: {
          ...state.notificationForm,
          recipient: action.payload
        }
      }
    case CHANGE_WRITE_NOTIFICATION_FORM_ADD:
      return {
        ...state,
        notificationForm: {
          ...state.notificationForm,
          [action.payload.key]: { ...state.notificationForm.files, ...action.payload.value }
        }
      }
    case CHANGE_HEADER_CHECKBOX:
      return {
        ...state,
        checkboxHeaderDisabled: action.payload
      }
    case CHANGE_DATE_PREVIEW:
      return {
        ...state,
        datePreview: action.payload
      }
    case CHANGE_ATTACHED_DOCUMENT_SIGNATURE:
      return {
        ...state,
        notificationForm: {
          ...state.notificationForm,
          files: {
            ...state.notificationForm.files,
            [action.payload.key]: {
              ...state.notificationForm.files[action.payload.key],
              sign: action.payload.value
            }
          }
        }
      }
    case CHANGE_ATTACHED_DOCUMENT_SIGNATURE_SPECIFIC:
      return {
        ...state,
        listDocumentsSpecific: {
          ...state.listDocumentsSpecific,
          [action.payload.key]: {
            ...state.listDocumentsSpecific[action.payload.key],
            isSignedFile: action.payload.value
          }
        }
      }
    case GET_RECIPIENT:
      return {
        ...state,
        recipientData: action.payload
      }
    case CHANGE_ATT_BUTTON:
      return {
        ...state,
        disableAtt: action.payload
      }
    case CLEAN_WRITER_fORM:
      return {
        ...initialState,
        historyRecipient: state.historyRecipient,
        notificationForm: {
          ...initialState.notificationForm,
          editorText: [
            {
              children: [{ text: '' }],
            }
          ],
          clearEditor: true
        }
      }
    case LOGOUT:
      return {
        ...initialState,
        historyRecipient: state.historyRecipient,
      }
    case CLEAR_EDITOR_CHANGE:
      return {
        ...state,
        notificationForm: {
          ...state.notificationForm,
          editorText: [
            {
              children: [{ text: '' }],
            }
          ],
          clearEditor: action.payload
        }
      }
    case CHANGE_COLOR_TEXT_EDITOR:
      return {
        ...state,
        notificationForm: {
          ...state.notificationForm,
          colorTextEditor: action.payload
        }
      }
    case HISTORY_RECIPIENT:
      let hist = state.historyRecipient
      if (state.historyRecipient.indexOf() === -1) {
        hist.push(action.payload)
      }
      return {
        ...state,
        historyRecipient: hist
      }
    case HISTORY_RECIPIENT_MASSIVE:
      return {
        ...state,
        historyRecipient: [...state.historyRecipient, ...action.payload]
      }
    case ADD_MASSIVE_RECIPIENTS_LIST:
      return {
        ...state,
        listIdsSendMassive: {
          ids: [...state.listIdsSendMassive.ids, ...action.payload.ids],
          fullData: [...state.listIdsSendMassive.fullData, ...action.payload.fullData],
          backendRequest: [...state.listIdsSendMassive.backendRequest, ...action.payload.backendRequest],
        }
      }
    case FILTER_MASSIVE_RECIPIENTS:
      return {
        ...state,
        listIdsSendMassive: action.payload
      }
    case ADD_MASSIVE_RECIPIENTS_FILE_INFO:
      return {
        ...state,
        massiveRecipientFileInfo: [...state.massiveRecipientFileInfo, {
          massiveRecipientFileId: action.payload.massiveRecipientFileId,
          massiveRecipientFileName: action.payload.massiveRecipientFileName
        }]
      }
    case SET_MASSIVE_RECIPIENTS_LIST_FILE_INFO:
      return {
        ...state,
        massiveRecipientFileInfo: action.payload,
      }
    case IS_MASSIVE_RECIPIENT:
      return {
        ...state,
        isMassiveRecipient: action.payload
      }
    case CLOSE_LOAD_MASSIVE_RECIPIENTS_LIST:
      return {
        ...state,
        isMassiveRecipient: false,
        listIdsSendMassive: {},
        massiveRecipientFileName: '',
      }
    case SAVE_HIRED:
      return {
        ...state,
        hiredData: action.payload.hiredData,
        notificationsAvailable: action.payload.availableQuantity
      }
    case CHANGE_TEXT_EDITOR:
      return {
        ...state,
        notificationForm: {
          ...state.notificationForm,
          textEditor: action.payload
        }
      }
    case STATE_VALIDATE_FILE_MASIVE:
      return {
        ...state,
        resultValidateFileMassive: action.payload
      }
    case CHANGE_STATE_VIEW_WRITTER:
      return {
        ...state,
        viewState: !state.viewState
      }
    case ACTIVE_STATE_FORM_NOTIFICATION:
      return {
        ...state,
        statusFormNotification: true,
      }
    case INACTIVE_STATE_FORM_NOTIFICATION:
      return {
        ...state,
        statusFormNotification: false,
      }
    case GET_ATTRIBUTE_LIST:
      return {
        ...state,
        listAttributeMassive: action.payload,
      }
    case SET_FIELD_PARAMETRIZATION:
      let column
      if(action.payload.key === 'columnList'){
        column= state.fieldParametrizationData.columnList[action.payload.index] 
        column= action.payload.value
        state.fieldParametrizationData.columnList[action.payload.index]= column
      }else if(action.payload.key === 'tagName'){
        column= state.fieldParametrizationData.columnList[action.payload.index] 
        column.tagName= action.payload.value
        state.fieldParametrizationData.columnList[action.payload.index]= column
      }
      return{
        ...state,
        fieldParametrizationData:{
          ...state.fieldParametrizationData,
        }
      }
    case SAVE_FILE_MASSIVE:
      return {
        ...state,
        savedFileMassive: action.payload
      }
    case STATE_RECORDS_FILE_MASSIVE:
      return {
        ...state,
        resultRecordFileMassive: action.payload
      }
    case STATE_RECORDS_FILE_MASSIVE_PARAMETRIZATION:
      return {
        ...state,
        resultRecordParametrization: action.payload
      }
    case CLEAR_RECORDS_FILE_MASSIVE_PARAMETRIZATION:
      return {
        ...state,
        resultRecordParametrization: initialState.resultRecordParametrization
      }
    case DRAFT_TO_WRITE:
      return {
        ...state,
        notificationForm: {
          ...state.notificationForm,
          recipient: action.payload.recipient,
          subject: action.payload.subject,
          files: action.payload.files,
          notificationSignature: action.payload.notificationSignature,
          readReceipt: action.payload.readReceipt,
          pushNotification: action.payload.pushNotification,
          textEditorFromDraft: action.payload.textEditor,
        },
        fileSerialID: action.payload.fileSerialID,
        draftSerialID: action.payload.draftSerialID
      }
    case SET_FILE_SERIAL_ID:
    return {
      ...state,
      fileSerialID: action.payload,
    }
    case STATE_NOTIFICATION_PROGRESS:
      return{
        ...state,
        resultNotificationsProgress: action.payload
      }
    case CHANGE_STATE_LOAD_NOTIFICATIONS:
      return {
        ...state,
        loadNotifications: action.payload
      }
    case CHANGE_STATE_LOAD_CITIZENS:
      return {
        ...state,
        loadCitizens: action.payload
          
      }
    case SET_DRAFT_SERIAL_ID:
      return {
          ...state,
          draftSerialID: action.payload
      }
    case CHANGE_STATE_LOAD_CITIZENS_LIST:
      return {
        ...state,
        loadCitizensList: action.payload
      }
    case CHANGE_STATE_LOAD_NOTIFICATIONS_LIST:
      return {
        ...state,
        loadNotificationsList: action.payload
    }
    case CLEAN_WRITER_FORM_ONLY:
      return {
        ...initialState,
        loadNotifications: state.loadNotifications,
        loadCitizens: state.loadCitizens,
        loadNotificationsList:state.loadNotificationsList,
        loadCitizensList: state.loadCitizensList,
        resultNotificationsProgress: state.resultNotificationsProgress,
        resultRecordFileMassive: state.resultRecordFileMassive,
        historyRecipient: state.historyRecipient,
        notificationForm: {
          ...initialState.notificationForm,
          editorText: [
            {
              children: [{ text: '' }],
            }
          ],
          clearEditor: true
        }
      }
    case DELETE_ITEM_FOR_MASSIVE:
      return {
        ...state,
        notificationForm: {
          ...state.notificationForm,
          recipient: initialState.notificationForm.recipient,
          parametrizationTags: initialState.notificationForm.parametrizationTags,
        },
        listIdsSendMassive: {
          ...state.listIdsSendMassive,
          fileSerialID: initialState.listIdsSendMassive.fileSerialID,
        },
        resultValidateFileMassive: initialState.resultRecordParametrization,
        isMassiveRecipient: false,
      }
    case DELETE_LOAD_CITIZEN_ITEM:
      return {
        ...state,
        resultRecordFileMassive: { listFileSearchMassive: action.payload}
      }
    case DELETE_LOAD_NOTIFICATION_ITEM:
      return {
        ...state,
        resultNotificationsProgress: { progress: action.payload}
      }
    case CREATE_NEW_TAGS:
      return {
        ...state,
        notificationForm: {
          ...state.notificationForm,
          parametrizationTags: action.payload
        },
      }
    case CHANGE_STATE_CITIZENS_CHARGING:
      return {
        ...state,
        citizenIsCharging: action.payload
    }
    case CHANGE_STATE_NOTIFICATIONS_CHARGING:
      return {
        ...state,
        notificationsIsCharging: action.payload
    }
    case CHANGE_STATE_SEND_EMAIL:
      return {
        ...state,
        IsSendEmail: action.payload
    }
    case SPECIFIC_DOCUMENTS:
      return {
        ...state,
        listDocumentsSpecific: action.payload
    }
    case CHANGE_DISABLED_RECIPIENT:
      return {
        ...state,
        isDisabledGetRecipient: action.payload
    }
    case RESET_FIELD_PARAMETRIZATION:
      return {
        ...state,
        fieldParametrizationData: {
          columnList : [],
        }
    }
    case FILE_MASSIVE_DATA:
      return {
        ...state,
        fileMassive: action.payload
    }
    case DELETE_RECIPIENTS_FROM_MASSIVE:
      return {
        ...state,
        notificationForm: {
          ...state.notificationForm,
          recipient: [],
          parametrizationTags:[],
        },
        listDocumentsSpecific: [],
      }
      case DOWNLOADED_USERS_GUIDE:
        return {
          ...state,
          userGuideURl: action.payload
        }
    default:
      return state
  }
}
