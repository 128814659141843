import React, { useState } from 'react'
import { Table } from 'semantic-ui-react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { ReactComponent as EnabledButton } from '../../../../assets/icons/clients/enabled-button.svg'
import { ReactComponent as EditImage } from '../../../../assets/icons/clients/edit-button.svg'
import moment from "moment/moment";
import { Cell } from '../../../../components/Table/CellComponent'
import { useSelector } from 'react-redux'

const SentCell = styled(Table.Cell)`
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  max-width: 1px !important;
  padding: 10px !important;
`

const Row = styled(Table.Row)`

  height: 50px !important;
  @media only screen and (max-width: 768px) {
    height: auto !important;
  }
  ${props => props.rowcolor && css`
    color: ${props.rowcolor}
  `}
`

const Enabled = styled(EnabledButton)`
  margin: 4px 0 0 0 !important;
`

const Edit = styled(EditImage)`
  float: right !important;
  margin: 4px 25px 0 0 !important;
`
export const RowTableConvetionsPrivate = (props) => {

    const [Isvisible, setIsVisible] = useState(false);
    const userRole = useSelector(state => state.authReducer.user.roleName)

    const agreementStatus = {
      ACTIVE: "En curso",
      PENDING: "Aprobado",
      INACTIVE: "Anulado",
      EXPIRED: "Vencido",
    }

    const formatDate = (userDocumentDate) => moment(userDocumentDate).utc().format('DD/MM/YYYY')

    const colorRow = (status) => {
        if (agreementStatus[status] === 'Vencido' || agreementStatus[status] === 'Anulado' ) {
          return `rgba(60, 60, 59, 0.5)`
        }
        return `#3C3C3B`
      }

    const isActiveAgreement = (agreementSstate) => {
      if (agreementStatus[agreementSstate] ==='En curso') {
        return true
      }
      return false
    }

    const offsetRows = userRole==='Operador' ? 1:0;
    return (
        <Row
            onMouseEnter={() => setIsVisible(true)}
            onMouseLeave={() => setIsVisible(false)}
            rowcolor={colorRow(props.data.statusAgreement)}
        >
            <Cell width={2}  text={props.data.agreementCode} columnName={props.headerTable[0].name} />
            {userRole!=='Operador' && <Cell width={2}  text={props.data.nameProvider} columnName={props.headerTable[1].name} />}
            <Cell width={2}  text={formatDate(props.data.startDate)} columnName={props.headerTable[2-offsetRows].name} />
            <Cell width={2}  text={formatDate(props.data.endDate)} columnName={props.headerTable[3-offsetRows].name} />
            <Cell width={2}  text={`${props.data.sendNotifications!== null ? props.data.sendNotifications : 0 } / ${props.data.sendNotifications + props.data.pendingNotifications}` } columnName={props.headerTable[4-offsetRows].name} />
            <Cell width={2}  text={props.data.pendingNotifications} columnName={props.headerTable[5-offsetRows].name} />
            <Cell
            width={2}
            text={
              isActiveAgreement(props.data.statusAgreement)
                ? <strong>{agreementStatus[props.data.statusAgreement]}</strong>
                : agreementStatus[props.data.statusAgreement]
            }
            columnName={props.headerTable[6-offsetRows].name}
          />
        </Row>
    )
}

RowTableConvetionsPrivate.propTypes = {
    data: PropTypes.object.isRequired
};

export default RowTableConvetionsPrivate