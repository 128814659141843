import { AUDITOR_COMMENT_NOTIFICATION, GET_IMPROPER_CLIENTS, IMPROPER_CLIENT_DETAIL } from "../actions/actionsUtilities/types"

const initialState = {
    improperClients: [],
    improperClientDetail:{
        notifications:[]
    },
    elementsCount: 0,
    auditorComment:{
        notificationID: '',
        comment:''
    }
}

export default (state = initialState, action) => {

    switch (action.type) {
        case GET_IMPROPER_CLIENTS:
            return{
                ...state,
                improperClients: action.payload.improperClients,
                elementsCount: action.payload.elementsCount
            }
        case IMPROPER_CLIENT_DETAIL:
            return{
                ...state,
                improperClientDetail: {
                    ...state.improperClientDetail, 
                    notifications: action.payload.notifications
                },
                elementsCount: action.payload.elementsCount
            }
        case AUDITOR_COMMENT_NOTIFICATION:
            return{
                ...state,
                auditorComment:{
                    notificationID: action.payload.notificationID,
                    comment:action.payload.comment,
                }

            }

        default:
      return state
  }

}