import React from 'react'
import styled from 'styled-components'
import { BrowserRouter, Route, Router, Redirect, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { connect } from "react-redux"
import '../components/HomeMenu/sideBar.css'
import PrivateComponent from "../components/PrivateComponent"
import { HomeMenu } from "../components/HomeMenu/HomeMenu"
import Login from "../views/auth/Login"
import Logout from "../views/auth/Logout"
import LoginWithNumberDocument from '../views/auth/LoginWithNumberDocument'
import LoginWithCertificate from '../views/auth/LoginWithCertificate'
import ForgotPassword from '../views/auth/ForgotPassword'

import QRView from '../views/auth/QRView'
import ViewWithOutQr from '../views/auth/ViewWithOutQr'
import RoutesWithNotFound from '../utils/routes-with-not-found'

const Container = styled.div`
    width: 100vw;
    height:100vh;
 ` 

let history = createBrowserHistory()

/**
 * Browser Router (export a browser router with respective routes,
 * PrivateRoute middleware params(param1: Component, param2: 'accepted roles separated with colon eg. "Admin, Guest"')
 * can you send "" in param2 for accept all loggedIn users)
 *
 * @export class
 * @class Routes
 * @extends {React.Component}
 */
class Routes extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Router history={history}>
        {
          !Object.keys(this.props.user).length &&
          
          <Switch>
            <Route exact path="/" component={() =><LoginWithNumberDocument history={history}/>} />
            <Route exact path="/login-user" component={() => <Login  history={history}/>}/>
            <Route exact path="/login" component={() =><LoginWithNumberDocument history={history}/>}/>
            <Route exact path="/login-certificate/:userId" component={() =><LoginWithCertificate />}/>
            <Route exact path="/login-certificate/:userId/:key" component={() =><LoginWithCertificate />}/>
            <Route exact path="/login-QR/:userId/:key" component={() =><QRView />}/>
            <Route exact path="/login-QR/:userId" component={() =><QRView />}/>
            <Route exact path="/login-QR2/:userId" component={() =><ViewWithOutQr />}/>
            <Route exact path="/logout" component={() =><Logout />} />
            <Route exact path="/forgotPassword" component={() =><ForgotPassword/>}/>
            <Route path="*" component={()=><Container><RoutesWithNotFound/></Container>}/>
          </Switch>
        }
          
          {
            !!Object.keys(this.props.user).length &&
            <PrivateComponent component={HomeMenu} history={history} />
          }
        </Router>
      </BrowserRouter>
    )
  }
}


const mapStateToProps = (state) => ({
  user: state.authReducer.user,
})

export default connect(mapStateToProps, {})(Routes)
