import React from "react"
import PropTypes from "prop-types"
import { Dropdown } from "semantic-ui-react"
import styled from 'styled-components'
import './input-from-users.css'
import { useDispatch } from "react-redux"
import { activeFormClient } from "../../../../actions/clientActions"

const Divider = styled.div`
  display: flex;
`
const InputFormDropdown = ({ ...props }) => {

    const dispatch = useDispatch()
    const { placeholder, options, value, id, handleOnChange, validator, validateOptions, disabled } = props

    let errorValidator = null;
    if (validator && validateOptions) errorValidator = validator.message('', value, validateOptions);

    const validator2 = (value) => {
        if (value !== "") {
            dispatch(activeFormClient())
        }
    }
    return (
        <Divider className={errorValidator ? 'divInputDropdownFormEntitiesError' : 'divInputDropdownFormEntities'}>
            <Dropdown
                upward={false}
                name={id}
                selection
                button
                options={options}
                className={'inputDropdownFormEntities'}
                value={value}
                onChange={handleOnChange}
                id={id}
                autoComplete='off'
                style={{ paddingBottom: "29px" }}
                onBlur={() => validator2(value)}
                disabled={disabled}
            />
            {errorValidator ?
                <>
                    <label className={errorValidator && value === '' && 'spanLabelInputTopError'}>{placeholder}</label>
                    <label className={(errorValidator) && "input-error3"}>{errorValidator}</label>
                </>
                :
                <span className={value !== '' ? 'labelInputDropdownFormEntitiesTop' : 'labelInputDropdownFormEntities'}>{placeholder}</span>
            }
        </Divider>
    )
}


InputFormDropdown.propTypes = {
    labelProps: PropTypes.object,
    id: PropTypes.string,
}
export default InputFormDropdown