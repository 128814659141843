import React, { Component } from "react";
import { Grid, GridColumn, GridRow, Divider } from 'semantic-ui-react'
import styled, { css } from 'styled-components'
import { connect } from 'react-redux'
import _ from 'lodash'

import { cleanWriterForm, noAgreementsActive,  } from '../../actions/writerActions'
import { changeWriteNotificationForm, activeFormProvider, chageStateLoadNotificationsList, chageStateLoadCitizensList } from '../../actions/writerActions'
import { saveDraftData,saveDraftV2 } from '../../actions/draftsActions'
import { MainSegment } from '../../components/Sengment/MainSegment'
import InputsFor from '../../components/TextEditor/Input/InputsFor'
import RichEditor from './editor/RichEditor'
import Preview from './preview/Preview'
import ViewHeader from './Header'
import MessageModal from '../../components/Modal/MessageModal'
import WarningModal from '../../components/TextEditor/ModalSend/WarningModal'
import WarningModalWrite from '../../components/TextEditor/ModalWriter/WarningModal'
import SimpleReactValidator from 'simple-react-validator'
import { reactValidatorOptions } from '../../helpers/simpleReactValidator'
import InputFormValidate from '../../components/FormWrite/InputAnimated/InputFormValidate'

import { ReactComponent as LeftArrowIcon } from '../../assets/icons/write/left-arrow.svg'
import { ReactComponent as RightArrowIcon } from '../../assets/icons/write/right-arrow.svg'
import { ReactComponent as Close } from '../../assets/icons/write/close.svg'
import PropTypes, { func } from "prop-types"
import { refreshStateErrorMessageSendMassiveModal } from "../../actions/modalActions";

const Container = styled.div`
  margin: 20px 30px 0 30px !important;
  @media only screen and (max-width: 1500px) {
    margin: 20px 16px 0px 16px !important;
  }
`
const ContainerScroll = styled.div`
  width: 100%;
  height: 89.3vh;
  overflow-y: scroll;
  @media only screen and (max-width: 992px) {
    height: 85vh;
  }

  ::-webkit-scrollbar-track {
    background: #CCCCCC;
  }

  ::-webkit-scrollbar-thumb {
    background: #9D9D9C !important;
  }

  ::-webkit-scrollbar:vertical {
    width: 5px;
  }
`
const DividerHr = styled(Divider)`
  @media only screen and (max-width: 1500px) {
    display:none;
  }
`
const GridContainerPrev = styled(Grid)`
 
`

const PreviewColumn = styled(GridColumn)`
  max-width: 346.83px !important;
  min-width: 270.83px !important;
  max-height: 683px !important;
  min-height: 532px !important;
  height: calc(40.253vw - 11.6vh) !important;
  display: block !important;
  padding: 0 !important;
  margin: auto 0px auto 0px !important;
`

const EditorColumn = styled(GridColumn)`
  height: 720px !important;
  position: relative;
  margin-bottom: 15%; 
  @media only screen and (max-width: 1500px) {
    height: auto !important;
  }
`
const LeftArrowColumn = styled(GridColumn)`
  display:flex !important;
  height: 100% !important;
  padding: 0 !important;
  @media only screen and (max-width: 1500px) {
    display:none !important;
  }
`
const LeftArrow = styled.button`
  width:100% !important;
  margin-top: 50px !important;
`
const LeftArrowRes = styled.button`
  width:auto;
  margin-top: 10px;
  display: none;
  @media only screen and (max-width: 1500px) {
    display: block;
  }
`
const RightArrowColumn = styled(GridColumn)`
  display: flex !important;
  height: 100% !important;
  padding: 0 !important;
  @media only screen and (max-width: 1500px) {
    display:none !important;
  }
`
const RightArrow = styled.button`
  width:100% !important;
  margin-top: 50px !important;
`
const RightArrowRes = styled.button`
  width:auto;
  margin-top: 10px;
  display: none;
  @media only screen and (max-width: 1500px) {
    display: block;
  }
`
const ContainerGrid = styled.div`
  width: 60%;
  margin-top: 2rem;
  @media only screen and (max-width: 1500px) {
    width:100%;
    padding: 0px 8px !important;
  }
`
const ContainerPreview = styled.aside`
  width: 40%;
  @media only screen and (max-width: 1500px) {
    ${props => props.changeView &&
    css`
        display: ${props.changeView.display};
      `}
    align-items: start;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: white;
    z-index:3000;
    justify-content: center;
  }
`

const CloseButton = styled.a`
  height: auto;
  cursor: pointer;
  margin:15px;
  display:none;
  position: fixed;
  top: 0;
  right: 0;
  z-index:30;
  @media only screen and (max-width: 1500px) {
    display:block;
  }
`
const TextPrevContainer = styled.div`
  width:100%;
  margin 20px auto;
  display:none;
  @media only screen and (max-width: 1500px) {
    margin 20px auto;
    display:block;
  }

  @media only screen and (max-width: 992px) {
    margin 10px auto;
  }
`
const TextPrev = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #22C1D0;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
`
const SubjectContent = styled.div`
  display:block;
  position:relative;
  margin-bottom: 15px;
`
const SubjectTitle = styled.span`
  position: absolute;
  top: 20px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 14px;
  color: #B2B2B2;
`

const SubjectInput = styled.input`
  margin:0;
  margin-top: 8px;
  padding-left:60px;
  height: 40px;
  color: #3C3C3B;
  font-size: 16px;
`

const ContentCellphone = styled.div`
  background: #3F3051;
  max-width:346.83px;
  min-width: 270.83px;
  max-height: 683px;
  min-height: 532px;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  position: relative;
  margin: auto 0px auto 0px;

  @media only screen and (max-width: 1500px) {
    width: 270.83px;
    height: 532px;
  }
`
const ContainerUp = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 0px 20px;
`
const Camera = styled.div`
  background: #FFFFFF;
  border-radius: 50%;
  max-width: 10.66px;
  max-height: 10.66px;
  width:10.66px;
  height: 10.66px;
  margin: 0px 10px;
`
const Speaker = styled.div`
  background: #FFFFFF;
  border-radius: 30px;
  max-width: 67.82px;
  max-height: 4.84px;
  width:67.82px;
  height: 4.84px;
`
const ContainerDown = styled.div`
  width:100%; 
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 35px;
  position: absolute;
  bottom: 0;
`
const ButtonCellphone = styled.div`
  background: #FFFFFF;
  border-radius: 15px;
  max-width: 87.19px;
  max-height: 23.25px;
  width: 87.19px;
  height: 23.25px
`
const ContainerView = styled.div`
  max-width: 90%;
  max-height: 558px;
  max-height: 558px;
  min-height: 75%;
  width: 90%;
  height: 75%;
  margin: auto;
  background: #FFFFFF;
  border-radius: 5px;
`

class Write extends Component {
  state = {
    previews: [
      'previewMessage',
      'previewList',
    ],
    activePreview: 'previewMessage',
    user: '',
    closeView: true,
    subjectError: false,
    formValidator : new SimpleReactValidator(reactValidatorOptions),
    messageErrors: 
      {
        specialCharacters:
        {
          className: "messageErrorBottom",
          text: "Este campo no acepta caracteres especiales",
        },
        minLength:
        {
          className: "messageErrorBottom",
          text: "Este campo debe contener entre 3 y 300 caracteres",
        }
      },
    viewMessage: false,
    errors: {},
  }

  handleOnChange = e => {
    this.props.changeWriteNotificationForm(
      [e.target.id],
      e.target.value
    )
    this.setState({subjectError: false})
  }

  validator = (stateSubject) => {
    if (stateSubject === "") {
      this.setState({subjectError: true})
    }
  }
  componentDidMount() {
    this.props.chageStateLoadCitizensList(false)
    this.props.chageStateLoadNotificationsList(false)
    this.props.refreshStateErrorMessageSendMassiveModal()
  }

  componentDidUpdate(prevProps, prevState) {

    if (!_.isEqual(prevProps.writerRequest, this.props.writerRequest)) {
      this.props.cleanWriterForm()
    }

    if (!_.isEqual(prevProps.pushNotification, this.props.pushNotification)) {
      this.changePreviewList({ value: this.props.pushNotification, view: 'previewNotification' })
    }
  }

  nextPreview = () => {
    const index = this.state.previews.indexOf(this.state.activePreview);
    (index < (this.state.previews.length) - 1)
      ? this.setState(
        (prevState) =>
          ({ activePreview: prevState.previews[index + 1] })
      )
      : this.setState(
        (prevState) =>
          ({ activePreview: prevState.previews[0] })
      )
  }

  prevPreview = () => {
    const index = this.state.previews.indexOf(this.state.activePreview);
    (index > 0)
      ? this.setState(
        (prevState) =>
          ({ activePreview: prevState.previews[index - 1] })
      )
      : this.setState(
        (prevState) =>
          ({ activePreview: prevState.previews[prevState.previews.length - 1] })
      )
  }

  handleTag = (userData) => {
    this.setState({ user: userData })
  }

  changePreviewList = (data) => {
    if (data.value === true) {
      this.setState(
        (prevState) =>
          (prevState.previews.push(data.view))
      )
    } else {
      const newPreview = this.state.previews.filter((value) => { return value !== data.view })
      this.setState(
        (prevState) =>
          (prevState.previews = newPreview, prevState.activePreview = 'previewMessage')
      )
    }
  }

  changeViewPrev = () => {
    this.setState({ closeView: !this.state.closeView })
  }

  changeDateView = (close) => {
    if (!!close) {
      return { display: `none` }
    } else {
      return { display: `flex` }
    }
  }

  handleOnChange = e => {
    if (this.state.formValidator.allValid()) {
      this.setState({viewMessage: false})
    } 
    else {
      this.state.formValidator.showMessages()
      this.forceUpdate()
    }
    if (e.target.value.length > 200) {
      this.props.changeWriteNotificationForm(
        [e.target.id],
        e.target.value.slice(0,200)
      )
    } else {
      this.props.changeWriteNotificationForm(
        [e.target.id],
        e.target.value
      )
    }
    
    this.props.activeFormProvider()
    this.setState({subjectError: false})
  }

  handleBlurInput = async () => {
    if (this.state.formValidator.allValid()) {

    } else {
      this.setState({viewMessage: true})
      this.state.formValidator.showMessages()
      this.forceUpdate()
    }
    this.validator(this.props.subject)
    
    const subject = this.props.subject;
    await this.props.saveDraftData({subject})
    const { draftData } = this.props;
    this.props.saveDraftV2(draftData)
  }

  handleFocusInput = () => {
    this.setState({viewMessage: false})
  }

  validateSubject () {
      // Simular evento de blur para validar automáticamente el campo
      const inputSubject = document.getElementById('subject');
      if (inputSubject) {
        inputSubject.dispatchEvent(new Event('blur'));
      }
};

  render() {
    return (
      <>
          <ContainerScroll>
            <MainSegment>
              <ViewHeader />
              <DividerHr />
              <Container>
                <Grid>
                  <ContainerGrid>
                    <Grid.Column computer={16} tablet={16} mobile={16}>
                      <EditorColumn>
                        <InputsFor />
                        <SubjectContent>
                          <SubjectTitle>
                            Asunto
                          </SubjectTitle>
                          <InputFormValidate
                            id={'subject'}
                            placeholder={''}
                            placeholderError={'Campo obligatorio'} 
                            type={'text'}
                            onChange={this.handleOnChange}            
                            value={this.props.subject}
                            className={'inputSubject'}
                            classNameError={'inputSubjectError'}
                            onBlur={this.handleBlurInput}
                            onFocus={this.handleFocusInput}
                            onKeyDown={(e) => e.keyCode === 13 && e.preventDefault()}
                            validator={this.state.formValidator}
                            validateOptions={'required|subjectNotificationTwo'}
                            messageErrors={this.state.messageErrors}
                            validateShowMessage={this.validateShowMessage}
                            viewMessage= {this.state.viewMessage}
                            errors={this.state.errors}
                            required
                          />
                        </SubjectContent>
                        <RichEditor
                          userData={this.state.user}
                          history={this.props.history}
                          stateFormValidator={this.state.formValidator.allValid()}
                          validateSubject={this.validateSubject}
                        />
                      </EditorColumn>
                    </Grid.Column>
                  </ContainerGrid>
                  <ContainerPreview changeView={this.changeDateView(this.state.closeView)}>
                    <CloseButton
                      onClick={this.changeViewPrev}
                    >
                      <Close />
                    </CloseButton>
                    <GridContainerPrev>
                      <Grid.Row centered>
                        <LeftArrowColumn computer={2}>
                          <LeftArrow
                            className='leftArrow'
                            onClick={this.prevPreview}
                            style={this.props.statusFormNotification === false ? { "pointerEvents": "none" } : {}}
                          >

                            <div className={this.props.statusFormNotification === false ? "option-hide close" : "option-hide"}>
                              <LeftArrowIcon className="icon-arrow" />
                            </div>
                          </LeftArrow >
                        </LeftArrowColumn >
                        <PreviewColumn computer={10} tablet={16} mobile={16}>
                          <div className='title-filler'>
                            <div className='title-preview'>
                              Previsualización
                            </div>
                          </div>
                          <ContentCellphone>
                              <ContainerUp>
                                <Camera/>
                                <Speaker/>
                              </ContainerUp>
                              <ContainerView>
                                <Preview
                                  activePreview={this.state.activePreview}
                                  classViewArrow={this.props.statusFormNotification === false ? "option-hide-res close" : "option-hide-res"}
                                />
                              </ContainerView>
                              <ContainerDown>
                                <ButtonCellphone/>
                              </ContainerDown>
                          </ContentCellphone>
                          <LeftArrowRes
                            className='leftArrowRes'
                            onClick={this.prevPreview}
                            style={this.props.statusFormNotification === false ? { "pointerEvents": "none" } : {}}
                          >
                            <div>
                              <LeftArrowIcon className="iconArrowLeft" />
                            </div>
                          </LeftArrowRes>
                          <RightArrowRes
                            className='rightArrowRes'
                            onClick={this.nextPreview}
                            style={this.props.statusFormNotification === false ? { "pointerEvents": "none" } : {}}
                          >
                            <div>
                              <RightArrowIcon className="iconArrowRight" />
                            </div>
                          </RightArrowRes>
                        </PreviewColumn>
                        <RightArrowColumn computer={2}>
                          <RightArrow
                            className='rightArrow'
                            onClick={this.nextPreview}
                            style={this.props.statusFormNotification === false ? { "pointerEvents": "none" } : {}}
                          >
                            <div className={this.props.statusFormNotification === false ? "option-hide close" : "option-hide"}>
                              <RightArrowIcon className="icon-arrow" />
                            </div>
                          </RightArrow>
                        </RightArrowColumn>
                      </Grid.Row>
                    </GridContainerPrev>
                  </ContainerPreview>
                </Grid>
                <TextPrevContainer
                  onClick={this.changeViewPrev}
                >
                  <TextPrev>Ver previsualización</TextPrev>
                </TextPrevContainer>
              </Container>
             
              <WarningModalWrite/>
            </MainSegment>
          </ContainerScroll>
      </>
    )
  }
}

Write.propTypes = {
  writerRequest: PropTypes.array,
  cleanWriterForm: PropTypes.func,
  activeFormProvider: PropTypes.func
}

const mapStateToProps = (state) => ({
  writerRequest: state.writerReducer.savedNotification,
  subject: state.writerReducer.notificationForm.subject,
  pushNotification: state.writerReducer.notificationForm.pushNotification,
  hasAgreements: state.dashboardReducer.hasAgreements,
  statusFormNotification:  state.writerReducer.statusFormNotification,
  draftData : state.draftReducer.toSaveDraft,
  activeModalWrite: state.writerReducer.activeModalWrite
})

const mapDispatchToProps = {
  cleanWriterForm,
  changeWriteNotificationForm,
  noAgreementsActive,
  activeFormProvider,
  saveDraftData,
  saveDraftV2,
  chageStateLoadCitizensList,
  chageStateLoadNotificationsList,
  refreshStateErrorMessageSendMassiveModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(Write)