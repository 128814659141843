import axios from 'axios';
import { deleteFromListActions, reasonDispatch, updateListActions } from "./DispatchGeneric";
import { API_ENDPOINT } from "../config/config"
import { GENERATE_REPORT, GET_REPORTS, POST_CREATE_WRONG_REPORT_ROUTE, GET_CSV_ERROR_ROUTE, GET_REPORT_DETAIL_ROUTE, GET_CLIENT_REPORT_SEARCH, GET_REPORT_DETAIL_ROUTE_V2, REPORT_CREATE, GET_BASE64_REPORT, CREATE_EXPORT_PDF , GET_TO_BE_NOTIFIED} from './actionsUtilities/actionsRoutes';
import { CHANGE_STATE_REPORTS_LOADING_MODAL, GET_REPORTS_LIST, CHANGE_STATE_MESSAGE_MODAL, DETAIL_CSV_ERROR, REPORT_DETAIL, CHANGE_TYPE_REPORT_CURRENT, GET_CLIENTS_REPORT, STATE_FILTER_PAGINACION, VIEW_PAGINATION, GET_All_CLIENTS_REPORT, CHANGE_DATA_CREATE_REPORT, GET_RESPONSE_CREATE_REPORT, RESPONSE_BASE64_REPORT, REPORT_ERROR, CHANGE_STATE_REFRESH_MODULE, SUCCESS_CREATE_REPORT,CHANGE_TO_BE_NOTIFIED, INTERVAL_TO_BE_NOTIFIED, CLEAR_INTERVAL_TO_BE_NOTIFIED } from './actionsUtilities/types';
import { changeMessageModalTwo, changeReportsLoadingModal } from './modalActions';
import { getDataCypher, getDataDecypher } from '../helpers/dataCypher';
import { decrypt, encrypt } from '../helpers/cypher';
import { getHeaders } from '../helpers/globalHelpers';



export const getReports = (body) => async (dispatch) => {
  const encryptedBody = await encrypt(body);
  await axios.post(`${API_ENDPOINT + GET_REPORTS}`, { data: encryptedBody }, { headers: getHeaders() }
  ).then(async (res) => {
    const decryptedData = await decrypt(res.data.result);
    dispatch({
      type: GET_REPORTS_LIST,
      payload: {
        reportsList: !!decryptedData.reports ? decryptedData.reports : [],
        elementsCount: decryptedData.elementsCount
      }
    })

    dispatch({
      type: VIEW_PAGINATION,
      payload: {
        total: decryptedData.elementsCount,
      }
    })
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
  })
}

export const postCreateWrongReport = (body) => async (dispatch) => {
  const encryptedBody = await encrypt(body);
  await axios.post(
    `${API_ENDPOINT + POST_CREATE_WRONG_REPORT_ROUTE}`, { data: encryptedBody }, { headers: getHeaders() }
  ).then(async res => {
    const decryptedData = await decrypt(res.data.result);
    dispatch({
      type: CHANGE_STATE_REPORTS_LOADING_MODAL,
      payload: { type: 'reportLoadingMessage' }
    });

  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
  })
}


export const getReportDetail = (body) => async dispatch => {
  const encryptedBody = await encrypt(body);
  await axios.post(
    `${API_ENDPOINT + GET_REPORT_DETAIL_ROUTE}`, { data: encryptedBody }, { headers: getHeaders() }
  ).then(async res => {
    const decryptedData = await decrypt(res.data.result);
    dispatch({
      type: CHANGE_TYPE_REPORT_CURRENT,
      payload: decryptedData.report.reportType
    })
    dispatch({
      type: REPORT_DETAIL,
      payload: decryptedData.report
    })
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
  })
}

export const sendReport = (body, history) => async (dispatch) => {
  dispatch(updateListActions('CREATE_REPORT'))
  const encryptedBody = await encrypt(body);
    await axios.post(`${API_ENDPOINT+GENERATE_REPORT}`, { data: encryptedBody }, { headers: getHeaders() }
    ).then(async (res) => {
      const  decryptedData = await decrypt(res.data.result);
      if(!!decryptedData.success){
       dispatch({
          type: CHANGE_STATE_REPORTS_LOADING_MODAL,
          payload: { type: 'reportLoadingMessage', status: false }
          })
        dispatch(getToBeNotified())
      }else{
        dispatch({
          type: CHANGE_STATE_REPORTS_LOADING_MODAL,
          payload: {type:'reportLoadingMessage', status: false}
        })

      dispatch(changeMessageModalTwo({
        type: 'reportDataNotFound',
        message: ['¡Lo sentimos!', 'No encontramos resultados para generar este reporte'],
      }))
    }
    dispatch(deleteFromListActions('CREATE_REPORT'))
  }).catch(async reason => {
    const decryptedData = await decrypt(reason.response.data.result);
    dispatch({
      type: CHANGE_STATE_REPORTS_LOADING_MODAL,
      payload: { type: 'reportLoadingMessage' , status: false}
    })
    if (decryptedData.message === 'No se encontraron registros') {
      dispatch(changeMessageModalTwo({
        type: 'reportDataNotFound',
        message: ['¡Lo sentimos!', 'No encontramos resultados para generar este reporte'],
      }))
    } else if (decryptedData.message.includes('El destinatario con cedula')) {
      dispatch(changeMessageModalTwo({
        type: 'reportRecipientNotFound',
        message: ['¡Lo sentimos!', 'No existe registro de este cliente'],
      }))
    } else {
      dispatch(changeMessageModalTwo({
        type: 'reportInternalError',
        message: ['¡Lo sentimos!', 'Ocurrió un error interno al generar el reporte, por favor inténtalo de nuevo'],
      }))
    }
    dispatch(deleteFromListActions('CREATE_REPORT'))

  })
}

export const getClientSearch = (param) => async dispatch => {

  await axios.get(`${API_ENDPOINT + GET_CLIENT_REPORT_SEARCH + param}`, { headers: getHeaders() }
  ).then(async (res) => {
    const decryptedData = await decrypt(res.data.result);
    dispatch({ type: GET_CLIENTS_REPORT, payload: decryptedData.companies })
  }).catch(async (reason) => {
    await reasonDispatch(dispatch, reason, false)
  })

}

export const getAllClientSearch = () => async dispatch => {
  await axios.get(`${API_ENDPOINT + GET_CLIENT_REPORT_SEARCH}`, { headers: getHeaders() }
  ).then(async (res) => {
    const decryptedData = await decrypt(res.data.result);
    dispatch({ type: GET_All_CLIENTS_REPORT, payload: decryptedData.companies })
  }).catch(async (reason) => {
    await reasonDispatch(dispatch, reason, false)
  })

}

export const changeDataCreateReport = (data) => (dispatch) => {
  dispatch({ type: CHANGE_DATA_CREATE_REPORT, payload: data })
}

export const createReportInterceptor = async (body) => {
  const encryptedBody = await encrypt(body);
  return axios.post(
    `${API_ENDPOINT + REPORT_CREATE}`, { data: encryptedBody }, { headers: getHeaders() }
  ).then(async res => {
    const decryptedData = await decrypt(res.data.result);
    return decryptedData
  })
}

export const getBase64ReportInterceptor = async (body) => {
  const encryptedBody = await encrypt(body);
  return axios.post(
    `${API_ENDPOINT + GET_BASE64_REPORT}`, { data: encryptedBody }, { headers: getHeaders() }
  ).then(async res => {
    const decryptedData = await decrypt(res.data.result);
    return decryptedData
  })
}

export const createExportPDFInterceptor = async (param) => {
  return axios.get(`${API_ENDPOINT + CREATE_EXPORT_PDF + param}`, { headers: getHeaders() }).then(async res => {
    const decryptedData = await decrypt(res.data.result);
    return decryptedData
  })
}

  export const createExportCSVInterceptor = async (body) => {
    const encryptedBody = await encrypt(body);
    return axios.post(
      `${API_ENDPOINT+REPORT_CREATE}`, { data: encryptedBody }, { headers: getHeaders() }
    ).then(async res => {
      const  decryptedData = await decrypt(res.data.result);
      return decryptedData
    })
  }

  export const changeStateSuccessCreateReport = (status) => (dispatch) => {
    dispatch({type: SUCCESS_CREATE_REPORT, payload: status})
  }

  export const getToBeNotified = (isAdmin)  => async (dispatch) => {
    await axios.post(`${API_ENDPOINT+GET_TO_BE_NOTIFIED}`, { headers: getHeaders() }
    ).then(async (res) => {
      dispatch({
        type: CHANGE_TO_BE_NOTIFIED, 
        payload: res.data.result.reportsToNotify
      })
      
      const interval = setInterval(() => {
        dispatch(changeStateSuccessCreateReport(false))
        clearInterval(interval)
      }, 4000) //4 segundos
    }).catch(async (reason) =>{
      await reasonDispatch(dispatch,reason,false)
    })
  }

  export const setIntervalToBeNotified = (interval) => (dispatch) => {
    dispatch({type: INTERVAL_TO_BE_NOTIFIED, payload: interval})
  }

  export const clearIntervalToBeNotified = () => (dispatch) => {
    dispatch({type: CLEAR_INTERVAL_TO_BE_NOTIFIED})
  }