import React from 'react'
import './input-from-entities.css'

export const InputRadio = ({ ...props }) => {

  const { placeholder, id, onChange, value, label, disabled, validator, validateOptions, error, classNameDiv, classNameCheck, defaultChecked, checked } = props
    let errorValidator = validator.message(label !== '' && label !== undefined ? label : placeholder, value, validateOptions)
  return (
    <>
      <div className={'classNameDivRadio'}>
        <input
          id={id}
          onChange={onChange}
          value={value}
          checked={checked} 
          type="radio" 
          class="hidden" 
          readonly="" 
          tabindex="0"
        />
        <label className='labelRadio'>
          {label}
        </label>
        </div>
    </>
  )
}
