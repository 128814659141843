import {
    POST_STARRED,
    GET_NOTIFICATIONS_STARRED,
    LOGOUT,
    CLEAR_NOTIFICATIONS_STARRED
} from '../actions/actionsUtilities/types.js'

const initialState = {
    notificationsStarreds: [],
    stateNotificationStarred: {}
}

export default (state = initialState, action) => {
    switch (action.type) {
        case LOGOUT:
            return initialState
        case GET_NOTIFICATIONS_STARRED:
            return {
                ...state,
                notificationsStarreds: action.payload
            }
        case POST_STARRED:
            return {
                ...state,
                stateNotificationStarred: action.payload
            }
        case CLEAR_NOTIFICATIONS_STARRED:
            return {
                ...state,
                stateNotificationStarred: {}
            }
        default:
            return state
    }
}