import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SimpleReactValidator from 'simple-react-validator'
import { reactValidatorOptions } from '../../helpers/simpleReactValidator'
import { Form, Grid } from 'semantic-ui-react'
import InputFormClient from './InputAnimated/InputFormClient'
import InputFormDropdown from './InputAnimated/InputFormDropdown'
import InputDV from './InputAnimated/InputDV'
import { postCreateEntities, activeFormClient } from '../../actions/clientActions'
import { withRouter } from 'react-router-dom'
import { refreshStateModule, warningFormModal } from '../../actions/modalActions'
import modulo from 'modulo-11'

import styled from 'styled-components'
import './index.css'
import { getGeographicList } from "../../actions/defaultListActions";
import { AttachmentButtonForm } from './FormProvider/AttachmentButtonForm'
import { FormContactProvider } from './FormProvider/FormContactProvider'

const Divider = styled.div`
  padding-left: 160px;
  padding-right: 160px;
  @media only screen and (max-width: 1200px) {
    width: 100%;
    min-width: 350px;
    height: 100vh;
  }
  @media only screen and (max-width: 700px) {
    width: 100%;
    min-width: 350px;
    height: 100vh;
    padding: 0px 15px;
  }
`
const CustomForm = styled(Form)`
  display: flex !important;
  flex-direction: column !important;
`
const Title = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #133F4F;
`
const Title2 = styled.p`
  color: #133F4F;
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  padding-Top: 22px
`

const TextInformation = styled.p`
font-family: Roboto;
font-size: 18px;
font-weight: 400;
text-align: left;
`
const Divider2 = styled.div`
  display: flex;
  margin-top: 15px;
  justify-content: flex-end;
  margin-bottom: 2rem;
  @media only screen and (max-width: 1200px) {
    display: block;
    margin: 30px 0px;
  }
`
const ButtoCancel = styled.button`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #22C1D0;
  text-align: center;
  width: 128px;
  height: 48px;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  background: #FFFFFF;
  margin-right: 8px;
  cursor: pointer;
  @media only screen and (max-width: 1200px) {
    float:left;
    width:49%;
    margin:0;
  }
`
const ButtoSave = styled.button`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #FFFFFF;
  text-align: center;
  width: 128px;
  height: 48px;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  background: #22C1D0;
  margin-left: 8px;
  cursor: pointer;
  @media only screen and (max-width: 1200px) {
    float:right;
    width:49%;
    margin:0;
  }
`
const ColumnGrid = styled(Grid.Column)`
  width:50% !important;
  @media only screen and (max-width: 1200px) {
    width: 100% !important;
  }
`
const ColumnGridDividerOne = styled(Grid.Column)`
  width:40% !important;
  @media only screen and (max-width: 1200px) {
    width: 80% !important;
  }
`
const ColumnGridDividerTwo = styled(Grid.Column)`
  width:10% !important;
  @media only screen and (max-width: 1200px) {
    width: 20% !important;
  }
`
const initialState = {
  clientCode:'',
  nameOfCompany: '',
  typeOfCompany: '',
  typePersonCompany: '',
  typeDocumentCompany: '',
  numberDocumentCompany: '',
  DV: '',
  phone: '',
  email: '',
  domain: '',
  address: '',
  countryList: [],
  selectedCountry: '',
  departmentList: [],
  selectedDepartment: '',
  cityList: [],
  selectedCity: '',
  legalManagerName: '',
  legalManagerLastName: '',
  typeDocumentLegalManager: '',
  numberDocumentLegalManager: '',
  status: false,
  errors: {},
  departaments: [],
  cities: [],
  isChangedState: false,
  contactInformation:[
    {
      name:'',
      surNames:'',
      email:'',
      phoneContact:'',
      occupation:''
    },
    {
      name:'',
      surNames:'',
      email:'',
      phoneContact:'',
      occupation:''
    },
  ],
  supportFiles:[],
  progress: [0, 0, 0],
  errorFile:false,
  isVisible: false,
  supportDescription:''
}
const optionsTypeOfCompany = [
  { key: '', text: '', value: '' },
  { key: 'PUBLIC', text: 'PÚBLICA', value: 'PUBLIC' },
  { key: 'PRIVATE', text: 'PRIVADA', value: 'PRIVATE' },
]

const optionsTypePersonCompany = [
  { key: '', text: '', value: '' },
  { key: 'LEGAL_PERSON', text: 'PERSONA JURÍDICA', value: 'LEGAL_PERSON' },
]

const optionsTypeDocumentCompany = [
  { key: '', text: '', value: '' },
  { key: 'NIT', text: 'NIT', value: 'NIT' },
  { key: 'ID_EXTRANJERO', text: 'ID EXTRANJERO', value: 'ID_EXTRANJERO' },
]

const optionsTypeDocumentLegalManager = [
  { key: '', text: '', value: '' },
  { key: 'C.C', text: 'C.C', value: 'C.C' },
  { key: 'C.E', text: 'C.E', value: 'C.E' },
  { key: 'DNI', text: 'DNI', value: 'DNI' },
  { key: 'NIT', text: 'NIT', value: 'NIT' },
  { key: 'NUI', text: 'NUI', value: 'NUI' },
  { key: 'PAS', text: 'PAS', value: 'PAS' },
  { key: 'PEP', text: 'PEP', value: 'PEP' },
  { key: 'R.C', text: 'R.C', value: 'R.C' },
  { key: 'T.I', text: 'T.I', value: 'T.I' },
]

export class FormCreateClients extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...initialState,
      messagesShown: false,
    }
    this.formValidator = new SimpleReactValidator(reactValidatorOptions)
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    await this.props.getGeographicList().then(() => {
      const dataCountry = this.props.geographicList.map((x) => {
        return { key: x.code, text: x.name, value: x.code }
      })
      dataCountry.unshift({ key: '', text: '', value: '' })
      this.setState({ countryList: dataCountry })
    })
  }

  componentWillUnmount() {
    initialState.contactInformation = [
      {
        name:'',
        surNames:'',
        email:'',
        phoneContact:'',
        occupation:''
      },
      {
        name:'',
        surNames:'',
        email:'',
        phoneContact:'',
        occupation:''
      },
    ]
    this.setState({ ...initialState });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedCountry !== this.state.selectedCountry) {
      this.props.geographicList.forEach((data) => {
        if (data.code === this.state.selectedCountry) {
          const dataDepartments = data.departments.map((ele) => {
            return { key: ele.code, text: ele.name, value: ele.code }
          })
          dataDepartments.unshift({ key: '', text: '', value: '' })
          this.setState({ departmentList: dataDepartments })
          this.setState({ selectedDepartment: '' })
          this.setState({ selectedCity: '' })
        }
      })
    }

    if (prevState.selectedDepartment !== this.state.selectedDepartment) {
      this.props.geographicList.forEach((data) => {
        if (data.code === this.state.selectedCountry) {
          data.departments.forEach((data) => {
            if (data.code === this.state.selectedDepartment) {
              const dataCities = data.cities.map((ele) => {
                return { key: ele.code, text: ele.name, value: ele.code }
              })
              dataCities.unshift({ key: '', text: '', value: '' })
              this.setState({ cityList: dataCities })
              this.setState({ selectedCity: '' })
            }
          })
        }
      })
    }
   
    if (prevProps.moduleType === false &&  this.props.moduleType === true) {
      this.props.refreshStateModule()
      this.goBackHistory()
    }

    if (this.props.activeForm === false && this.props.warningModal === true) {
      this.props.activeFormClient()
    }

    if(prevState.messagesShown !== this.state.messagesShown){
      if(!!this.state.messagesShown){
        this.validateErrors()
      }
    }
    
  }

  goBackHistory = () => {
    const { history } = this.props;
    if(history) history.goBack();
  }


  handleChange(checked) {
    this.setState({ status: checked })
  }

  handleOnChange = (event, { value }) => {
    let name =''
    if(event.target.id){
      name = event.target.id
    }else{
      name= event.currentTarget.parentNode.parentNode.id
    }
    this.setState({
      [name]: value,
    })
  }


  trimStart = (input)=> {
    let start = 0;
    while (start < input.length && input[start] === ' ') {
        start++;
    }
    return input.substring(start);
  }

  onChange = (e) => {
    const fieldName = e.target.id;
    const fieldlabel = e.target.getAttribute("label");
    let value = e.target.value; // Obtener el valor sin aplicar trim

    const start = e.target.selectionStart;
    const end = e.target.selectionEnd;
    const trimmedValue = this.trimStart(value);
    const diffLength = value.length - trimmedValue.length;

    const cursorPosition = Math.max(start - diffLength, 0);
    value = trimmedValue;

    this.setState(
      {
        [fieldName]: value,
      },
      () => {
        const input = document.getElementById(fieldName);
        input.setSelectionRange(cursorPosition, cursorPosition);
        this.formValidator.showMessageFor(fieldName);
      }
    );
};

  onEmailChange = (e) => {
    let inputValue = e.target.value;
    const fieldlabel = e.target.getAttribute("label");
    const inputId = e.target.id;

    const start = e.target.selectionStart;
    const end = e.target.selectionEnd;
    const trimmedValue = inputValue.trim();
    const diffLength = inputValue.length - trimmedValue.length;

    const cursorPosition = Math.max(start - diffLength, 0);
    inputValue = trimmedValue;
    
    this.setState({ [inputId]: inputValue }, () => {
      const input = document.getElementById(inputId);
      input.setSelectionRange(cursorPosition, cursorPosition);
      this.formValidator.showMessageFor(inputId);
    });
  };
  
  onBlurMail = (e) =>{
    this.setState({ 'email': this.state.email.toLowerCase() });
  }

  onBlurDomain = (e) =>{
    this.setState({ 'domain': this.state.domain.toLowerCase() });
  }

  handleCancel = () => {
    this.formValidator.hideMessages()
    this.forceUpdate()
    this.props.warningFormModal({ type: 'warningLeaveClient' })
  }

  validateDiferentContactInformation = (contactinfo) => {
    if (contactinfo !== undefined) {
      const contact1 = contactinfo[0]
      const contact2 = contactinfo[1]

      if (
        contact1.name.trim() !== contact2.name.trim() ||
        contact1.surNames.trim() !== contact2.surNames.trim() ||
        contact1.email.trim() !== contact2.email.trim() ||
        contact1.phoneContact.trim() !== contact2.phoneContact.trim() ||
        contact1.occupation.trim() !== contact2.occupation.trim()
      ) {
        return true; 
      }

      return false;
    }
  }

  makeSaveNotificationReq = () => {

    const {
      clientCode,
      nameOfCompany,
      typeOfCompany,
      typePersonCompany,
      typeDocumentCompany,
      numberDocumentCompany,
      DV,
      phone,
      email,
      domain,
      address,
      selectedCountry,
      selectedDepartment,
      selectedCity,
      legalManagerName,
      legalManagerLastName,
      typeDocumentLegalManager,
      numberDocumentLegalManager,
      status,
      supportFiles,
      contactInformation,
      supportDescription
    } = this.state

      const body = {
        "codeCompany":clientCode,
        "name": nameOfCompany,
        "providerDefinition": typeOfCompany,
        "typePersonCompany": typePersonCompany,
        "typeDocumentCompany": typeDocumentCompany,
        "numberDocumentCompany": numberDocumentCompany,
        "DV": '',
        "phone": phone,
        "email": email,
        "domain": domain,
        "address": address,
        "country": selectedCountry,
        "department": selectedDepartment,
        "city": selectedCity,
        "legalManagerName": legalManagerName,
        "legalManagerLastName": legalManagerLastName,
        "typeDocumentLegalManager": typeDocumentLegalManager,
        "numberDocumentLegalManager": numberDocumentLegalManager,
        "status": status,
        "supportFiles":supportFiles,
        "contactInformation":contactInformation,
        'supportDescription':supportDescription
      }
      this.props.postCreateEntities(body)
  }
  handleSend = (e) => {
    e.preventDefault()
    if (this.formValidator.allValid()) {
      if (this.state.supportFiles.length > 0) {
        this.makeSaveNotificationReq()
      }else{
        this.setState({ errorFile: true, messagesShown: true })
      }
    } else {
      this.formValidator.showMessages()
      this.forceUpdate()
      if (this.state.supportFiles.length ===0) {
        this.setState({ errorFile: true })
      }
      this.setState({messagesShown: true})
    }
  }

  calculateDV = () =>{
    let generatedDV = ''
    try {
      generatedDV = modulo.calc(this.state.numberDocumentCompany).toString()
      this.setState({DV : generatedDV })
    } catch (error) {
      
    }
  }

  setContactInformation= (index, property, value) => {
    const updatedContactInformation = [...this.state.contactInformation];
    updatedContactInformation[index][property] = value;
    this.setState({ contactInformation: updatedContactInformation },()=>{
      this.formValidator.showMessageFor(`${property}${index+1}`)
    });
  };

  setSupportFiles = (supportFiles) => {
    this.setState({ supportFiles: supportFiles })
  }
  setErrorFile = (errorFile) => {
    this.setState({ errorFile: errorFile })
  }

  addNewContactFields =()=>{
    this.setState({ contactInformation: [...this.state.contactInformation,{
      name:'',
      surNames:'',
      email:'',
      phoneContact:'',
      occupation:''
    }] });
  }
  
  removeContactsFields = () =>{
    if (this.state.contactInformation.length >= 3) {
      const updatedContactInformation = this.state.contactInformation.slice(0, -1);
      this.setState({ contactInformation: updatedContactInformation });
    } 
  }

  validateErrors = () => {
    const elementError= document.querySelector('.labelInputFormEntitiesError, .divInputDropdownFormEntitiesError, .supportFilesError')
    if(elementError.className.includes('divInputDropdownFormEntitiesError')){
      elementError.firstChild.focus()
    }else{
      elementError.focus()
    }
    this.setState({messagesShown: false})
  }

  render() {
    return (
      <Divider>
        <CustomForm>
          <Title>Datos del cliente</Title>
          <Grid>
            <ColumnGrid>
            <InputFormClient
                id={"clientCode"}
                label={"Código del cliente"}
                type={"text"}
                onChange={this.onChange}
                value={this.state.clientCode}
                validator={this.formValidator}
                validateOptions={[
                  "required",
                  'providerClientCode',{ min: 3 }, { max: 10 }
                ]}
                error={this.state.errors}
              />
            </ColumnGrid>
          </Grid>
          <Grid>
            <ColumnGrid>
              <InputFormClient
                id={'nameOfCompany'}
                label={'Nombre'}
                type={'text'}
                onChange={this.onChange}
                value={this.state.nameOfCompany}
                validator={this.formValidator}
                validateOptions={'required|nameOfCompany'}
                error={this.state.errors}
              />
            </ColumnGrid>
            <ColumnGrid>
              <InputFormDropdown
                options={optionsTypeOfCompany}
                placeholder={'Tipo de empresa'}
                value={this.state.typeOfCompany}
                handleOnChange={this.handleOnChange}
                id='typeOfCompany'
                validator={this.formValidator}
                validateOptions={'required'}
                error={this.state.errors}
              />
            </ColumnGrid>
          </Grid>
          <Grid>
            <ColumnGrid>
              <InputFormDropdown
                options={optionsTypePersonCompany}
                placeholder={'Tipo de persona'}
                value={this.state.typePersonCompany}
                handleOnChange={this.handleOnChange}
                id={'typePersonCompany'}
                validator={this.formValidator}
                validateOptions={'required'}
                error={this.state.errors}
              />
            </ColumnGrid>
            <ColumnGrid>
              <InputFormDropdown
                options={optionsTypeDocumentCompany}
                placeholder={'Tipo de documento'}
                value={this.state.typeDocumentCompany}
                handleOnChange={this.handleOnChange}
                id={'typeDocumentCompany'}
                validator={this.formValidator}
                validateOptions={'required'}
                error={this.state.errors}
              />
            </ColumnGrid>
          </Grid>
          <Grid>
            <ColumnGridDividerOne>
              <InputFormClient
                id={'numberDocumentCompany'}
                label={'No. documento'}
                type={'text'}
                onChange={this.onChange}
                value={this.state.numberDocumentCompany}
                onBlur={this.calculateDV}
                validator={this.formValidator}
                validateOptions={['required', 'numeric', { min: 8 }, { max: 12 }]}
                error={this.state.errors}
              />
            </ColumnGridDividerOne>
            <ColumnGridDividerTwo>
              <InputDV
                id={'DV'}
                label={'DV'}
                type={'text'}
                onChange={this.onChange}
                value={this.state.DV}
                disable={true}
                validator={this.formValidator}
                validateOptions={this.state.typeDocumentCompany === 'NIT' ? ['required', 'numeric', { min: 1 }, { max: 1 }] : ['numeric', { min: 1 }, { max: 1 }]}
                error={this.state.errors}
              />
            </ColumnGridDividerTwo>
            <ColumnGrid>
              <InputFormClient
                id={'phone'}
                label={'Teléfono'}
                type={'text'}
                onChange={this.onChange}
                value={this.state.phone}
                validator={this.formValidator}
                validateOptions={['phone', { min: 7 }, { max: 15 }]}
                error={this.state.errors}
              />
            </ColumnGrid>
          </Grid>
          <Grid>
            <ColumnGrid>
              <InputFormClient
                id={'email'}
                label={'Email'}
                type={'text'}
                onChange={this.onEmailChange}
                value={this.state.email}
                validator={this.formValidator}
                validateOptions={['required', 'email', { min: 6 }, { max: 200 }]}
                error={this.state.errors}
                onBlur={this.onBlurMail}
              />
            </ColumnGrid>
            <ColumnGrid>
              <InputFormClient
                id={'domain'}
                label={'Dominio'}
                type={'text'}
                onChange={this.onEmailChange}
                value={this.state.domain}
                validator={this.formValidator}
                validateOptions={["url",{ min: 1 }, { max: 50 }]}
                error={this.state.errors}
                onBlur={this.onBlurDomain}
              />
            </ColumnGrid>
          </Grid>
          <Grid>
            <ColumnGrid>
              <InputFormClient
                id={'address'}
                label={'Dirección'}
                type={'text'}
                onChange={this.onChange}
                value={this.state.address}
                validator={this.formValidator}
                validateOptions={['address', { min: 1 }, { max: 200 }]}
                error={this.state.errors}
              />
            </ColumnGrid>
            <ColumnGrid>
              <InputFormDropdown
                options={this.state.countryList}
                placeholder={'País'}
                value={this.state.selectedCountry}
                handleOnChange={this.handleOnChange}
                id={'selectedCountry'}
                validator={this.formValidator}
                validateOptions={'required'}
                error={this.state.errors}
              />
            </ColumnGrid>
            <ColumnGrid>
              <InputFormDropdown
                options={this.state.departmentList}
                placeholder={'Departamento'}
                value={this.state.selectedDepartment}
                handleOnChange={this.handleOnChange}
                id={'selectedDepartment'}
                validator={this.formValidator}
                validateOptions={'required'}
                error={this.state.errors}
              />
            </ColumnGrid>

            <ColumnGrid>
              <InputFormDropdown
                options={this.state.cityList}
                placeholder={'Ciudad'}
                value={this.state.selectedCity}
                handleOnChange={this.handleOnChange}
                id={'selectedCity'}
                validator={this.formValidator}
                validateOptions={'required'}
                error={this.state.errors}
              />
            </ColumnGrid>
          </Grid>
          <Title2>Datos representante legal</Title2>
          <Grid>
            <ColumnGrid>
              <InputFormClient
                id={'legalManagerName'}
                label={'Nombres representante legal'}
                type={'text'}
                onChange={this.onChange}
                value={this.state.legalManagerName}
                validator={this.formValidator}
                validateOptions={['required', 'nameOfProviderClient', { min: 1 }, { max: 100 }]}
                error={this.state.errors}
              />
            </ColumnGrid>
            <ColumnGrid>
              <InputFormClient
                id={'legalManagerLastName'}
                label={'Apellidos representante legal'}
                type={'text'}
                onChange={this.onChange}
                value={this.state.legalManagerLastName}
                validator={this.formValidator}
                validateOptions={['required', 'nameOfProviderClient', { min: 1 }, { max: 100 }]}
                error={this.state.errors}
              />
            </ColumnGrid>
          </Grid>
          <Grid>
            <ColumnGrid>
              <InputFormDropdown
                options={optionsTypeDocumentLegalManager}
                placeholder={'Tipo de documento representante legal'}
                value={this.state.typeDocumentLegalManager}
                handleOnChange={this.handleOnChange}
                id={'typeDocumentLegalManager'}
                validator={this.formValidator}
                validateOptions={'required'}
                error={this.state.errors}
              />
            </ColumnGrid>
            <ColumnGrid>
              <InputFormClient
                id={'numberDocumentLegalManager'}
                label={'No. documento representante legal'}
                onChange={this.onChange}
                value={this.state.numberDocumentLegalManager}
                validator={this.formValidator}
                validateOptions={['required', 'numeric', { min: 4 }, { max: 15 }]}
                error={this.state.errors}
              />
            </ColumnGrid>
          </Grid>
    

          <AttachmentButtonForm supportFiles ={this.state.supportFiles} setSupportFiles={this.setSupportFiles} setErrorFile={this.setErrorFile} formValidator={this.formValidator} errorFile={this.state.errorFile} supportDetail={this.state.supportDescription} onChange={this.onChange} validator={this.formValidator}/>

          <TextInformation>Para tener más información del cliente, debes ingresar los siguientes datos de contacto.</TextInformation>

          <FormContactProvider setContactInformation={this.setContactInformation} contactInformation={this.state.contactInformation} formValidator={this.formValidator} errors={this.state.errors} supportFiles={this.state.supportFiles} setSupportFiles={this.setSupportFiles} addNewContactFields={this.addNewContactFields} removeContactsFields = {this.removeContactsFields} errorFile={this.state.errorFile} setErrorFile={this.setErrorFile}/>


          <Divider2>
            <ButtoCancel
              onClick={this.handleCancel}
            >Cancelar
            </ButtoCancel>
            <ButtoSave
              onClick={this.handleSend}
            >Guardar
            </ButtoSave>
          </Divider2>
        </CustomForm>
      </Divider>
    )
  }
}

FormCreateClients.propTypes = {
  postCreateEntities: PropTypes.func.isRequired,
  savedClient: PropTypes.array,
  moduleType: PropTypes.bool,
  activeFormClient: PropTypes.func.isRequired,
  activeForm: PropTypes.bool,
  warningModal: PropTypes.bool,
  geographicList: PropTypes.array,
  getGeographicList: PropTypes.func,
}

const mapStateToProps = (state) => ({
  savedClient: state.clientReducer.savedClient,
  moduleType: state.modalReducer.refreshModule,
  activeForm: state.clientReducer.activeForm,
  warningModal: state.modalReducer.warningModal.status,
  geographicList: state.defaultListReducer.geographicList,
})

const mapDispatchToProps = {
  postCreateEntities,
  warningFormModal,
  activeFormClient,
  refreshStateModule,
  getGeographicList
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FormCreateClients))
