export const createCookie = (value, myCookie) => { 
    const newDate = new Date();
    const sessionSum = 50;
    const minutes = newDate.getMinutes();
    newDate.setMinutes(minutes + sessionSum);

    for(const [key, val] of Object.entries(value)){
        myCookie.setCookie(key, val, {
            httpOnly: true,
            secure: true,
            sameSite: 'strict',
            expires: newDate
        })
    }
}

export const createCookieClass = (value, myCookie) => { 
    const newDate = new Date();
    const sessionSum = 50;
    const minutes = newDate.getMinutes();
    newDate.setMinutes(minutes + sessionSum);

    for(const [key, val] of Object.entries(value)){
        myCookie.set(key, val, {
            httpOnly: true,
            secure: true,
            sameSite: 'strict',
            expires: newDate
        })
    }
}

