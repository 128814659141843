import React from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { ReactComponent as clientIcon } from '../../../src/assets/icons/clients/Client-Icon.svg'
import ModalFilterDate from './AdvancedSearch/ModalFilterDate'

const Button = styled.button`
  background: #22C1D0;
  border: 1px solid #22C1D0;
  color: #FFFFFF;
  padding: 10px, 32px, 10px, 32px;
  width: 208px;
  height: 48px;
  font-family: Roboto;
  font-weight: Bold;
  font-size: 16px;
  cursor: pointer;
  @media only screen and (min-width: 1024px) and (max-width: 1465px) {
  font-size: 14px;
  width: 130px;
  }
  @media only screen and (max-width: 1024px){
    width: 100%;
    
  }
`
const IconNew = styled(clientIcon)`{
  float:left !important;
  margin: -3px 0px 0px 0px !important;
}
`

class ButtonClientSearch extends React.Component {

    handleClick = () => {
        this.props.history.push({ pathname: '/createClients' })
    }

  render() {
    return (
      <>
        <Button
          onClick={this.handleClick}
        >
          <IconNew />
          Nuevo Cliente
        </Button>
        <ModalFilterDate />
      </>
    )
  }
}

export default withRouter(ButtonClientSearch)