import React, { useState } from 'react';
import { Table } from 'semantic-ui-react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Cell, CellOptions } from '../../../../components/Table/CellComponent';
import { useEffect } from 'react';
import moment from 'moment'


const SentCell = styled(Table.Cell)`
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  padding: 10px !important;
  margin-left: 1rem;
`;

const Row = styled(Table.Row)`
  cursor: pointer !important;

  @media only screen and (max-width: 768px) {
    display: flex !important;
    flex-wrap: wrap;
    border-bottom: 1px solid #979e9f !important;
    padding: 0.5em 0em !important;
    
  }
`;

const ContainerCell = styled.div`
  position: relative;
  display: grid;
  margin: 4px;
`;


const ContainerAuditReview = styled.div`
  width: 88%;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const ContainerViewMore = styled.div`
  position: absolute;
  right: 0;
`;

const StyleAuditReviewLink = styled.span`
  font-weight: 400;
  color: #22C1D0;
  text-decoration: underline;
  cursor: pointer;
`;
export const RowTableImproperClientsDetail = (props) => {
  const dispatch = useDispatch();
  const formatDate = (dateToTransform ) => {
    return moment(dateToTransform).utc().format('DD/MM/YYYY - h:mm a')
  }

  return (
    <Row>
      <SentCell width={3}>{props.data.documentNumber}</SentCell>
      <SentCell width={3}>
            {props.data.subject}
      </SentCell>
      <SentCell width={7}>
      <ContainerCell>
        <ContainerAuditReview>
          {props.data.reviewComment}
        </ContainerAuditReview>
        {!!props.data.isReviewed
          ?
            <ContainerViewMore>
              <StyleAuditReviewLink>Ver más</StyleAuditReviewLink>
            </ContainerViewMore>
          :
            ''
          }
      </ContainerCell>
      </SentCell>
    </Row>
  );
};

RowTableImproperClientsDetail.propTypes = {
  data: PropTypes.object.isRequired,
};

export default RowTableImproperClientsDetail;
