import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import GeneralTable from '../../../../components/Table/GeneralTable'
import RowTableUsers from './RowTableUsers'
import {actionClearFilter, activePagination } from '../../../../actions/filterDetailActions'
import { getEmployees } from '../../../../actions/clientActions'

class Users extends Component {


  state = {
    loader: true,
    columnData: [
      { name: "Nombre ", width: 3, arrow: false },
      { name: "Documento", width: 3, arrow: false },
      { name: "Email", width: 4, arrow: false },
      { name: "Rol", width: 2, arrow: false },
      { name: "Activo", width: 1, arrow: false },
      { name: "", width: 2, arrow: false },
    ],
    init: true,
  }

  componentDidMount() {
    if(this.props.filterReducer.tabFilter !== 'users'){
      this.setState({init:false})
      this.props.actionClearFilter()
    }else{
      this.makeClientsRequest(this.props.filterReducer)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.filterReducer, this.props.filterReducer)  && !this.state.init) {

      this.makeClientsRequest(this.props.filterReducer)
      this.setState({init: true, loader: true })
    }
  }

  makeClientsRequest = (filterReducer) => {
    const body = {
      count: filterReducer.count,
      pag: filterReducer.pag,
      sort: this.orderTranslator(filterReducer.sort),
      companySerialID: this.props.companySerialID,
      textToSearch: filterReducer.searchBar[0],
    }


    this.props.getEmployees(body).then(() => {
      this.props.activePagination(false)
      this.setState({init: false, loader: false })
    })
  }

  orderTranslator = (order) => {
    const options = {
      'masReciente': { "_id": -1 },
      'masAntiguo': { "_id": 1 },
      'A-Z': { "firstName": 1 },
      'Z-A': { "firstName": -1 }
    }
    return options[order]
  }

  render() {
    return (
      <GeneralTable
        list={this.props.employees}
        typeList="users"
        headerTable={this.state.columnData}
        rowComponent={RowTableUsers}
        history={this.props.history}
        loader={this.state.loader}
      />
    )
  }
}

Users.propTypes = {
  getEmployees: PropTypes.func.isRequired,
  actionClearFilter: PropTypes.func,
  activePagination: PropTypes.func,
  employees: PropTypes.object.isRequired,
  employeesCount: PropTypes.object.isRequired,
  filterReducer: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  employees: state.clientReducer.employees,
  employeesCount: state.clientReducer.employeesCount,
  filterReducer: state.filterDetailReducer
})

const mapDispatchToProps = {
  activePagination,
  getEmployees,
  actionClearFilter,
}

export default connect(mapStateToProps, mapDispatchToProps)(Users)