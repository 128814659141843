import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Modal } from 'semantic-ui-react'
import styled, { css } from 'styled-components'

import { changeMessageModal, closewriteNotificationModal, hideMessageModal } from '../../actions/modalActions'
import PrimaryButton from '../../components/PrimaryButton'
import { ReactComponent as SentIcon } from '../../assets/icons/write/sent.svg'
import { ReactComponent as FailSentIcon } from '../../assets/icons/write/failSent.svg'
import { ReactComponent as InfoIcon } from '../../assets/icons/write/info.svg'
import { ReactComponent as InfoIconFiles } from '../../assets/icons/write/infoFiles.svg'
import { ReactComponent as ReportIcon } from '../../assets/icons/write/download-report.svg'
import { ReactComponent as CreateEntity } from '../../assets/icons/createdEntity.svg'
import { ReactComponent as InactiveEntity } from '../../assets/icons/inactiveEntity.svg'
import { ReactComponent as ErrorCreate } from '../../assets/icons/errorCreate.svg'
import { ReactComponent as ChangeState } from '../../assets/icons/CantChangeUserState.svg'
import { ReactComponent as NotFoundAgreements } from '../../assets/icons/dashboard/notFoundAgreements.svg'
import { ReactComponent as MassiveInfoIcon } from '../../assets/icons/write/waiting.svg'
import { ReactComponent as DeleteDraftIcon } from '../../assets/icons/drafts/deleteDraft.svg'
import { ReactComponent as CanceleAgreementsIcon } from '../../assets/icons/agreement/canceleAgreements.svg'

import './messageModal.css'
import { clearEditorChange, cleanWriterFormOnly, chageStateLoadNotifications, chageStateLoadNotificationsList } from "../../actions/writerActions";
import PropTypes from "prop-types";

const ModalElement = styled(Modal)`
${props => !!props.modalState.buttonMessage ?
    css`
        width: 496px !important;
        `
    :
    css`
        width: 328px !important;
        `
  }
  padding: 40px !important;  
`
const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const ModalMessage = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin-top: 32px;

`
const ModalActions = styled.div`
  margin-top: 32px;
`
const Texto = styled.p`
  color: #133F4F;
  font-family: Roboto;
  font-weight: 900;
  font-size: 17px;
  font-style: normal;
  line-height: 35px;
  text-align: center;
  margin-bottom: 15px !important;
`

const MessageModal = (props) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (props.modalState.status === false) {
      dispatch(clearEditorChange(false))
    }
    if (props.modalState.status === true && (props.modalState.type === 'clientEdit' || props.modalState.type === 'providerEdit' || props.modalState.type === 'agreementEdit')) {
      setTimeout(() => {
        dispatch(hideMessageModal())
      }, 2000);
    }
  }, [props.modalState.status, props.modalState.type, dispatch])

  const modalData = (type) => {
    const data = {
      finish: {
        icon: <SentIcon />,
        message: '¡Notificación enviada!'
      },
      error: {
        icon: <FailSentIcon />,
        message: 'En este momento no se puede enviar la notificación, quedará guardada en borradores, espera unos minutos y podrás reenviarla.',
        buttonMessage: 'Entendido',
      },
      warning: {
        icon: <InfoIcon />,
        message: 'Debes ingresar al menos un número de cédula, el asunto y el mensaje antes de enviar.',
        buttonMessage: 'Entendido'
      },
      warningFiles: {
        icon: <InfoIconFiles />,
        message: '',
        multipleMessages: true,
        buttonMessage: 'Aceptar',
      },
      errorDownloadReport: {
        icon: <ReportIcon />,
        InitialMessage: "¡Ha ocurrido un error interno!.",
        message: "Por favor inténtalo de nuevo en un momento.",
        buttonMessage: 'Aceptar',
      },
      entityCreate: {
        icon: <CreateEntity />,
        InitialMessage: "¡Cliente creado!"
      },
      clientEdit: {
        icon: <CreateEntity />,
        InitialMessage: "¡Cliente actualizado!"
      },
      entityEdit: {
        icon: <CreateEntity />,
        InitialMessage: "¡Entidad actualizada!"
      },
      errorCreateEntities: {
        icon: <ErrorCreate />,
        InitialMessage: "¡Error de creación!",
        message: "Algunos campos no cumplen con los datos solicitados.",
        buttonMessage: 'Aceptar',
      },
      unknownError: {
        icon: <ErrorCreate />,
        InitialMessage: "¡Ha ocurrido un error interno!.",
        message: "Por favor inténtalo de nuevo en un momento.",
        buttonMessage: 'Aceptar',
      },
      inactiveEntity: {
        icon: <InactiveEntity />,
        InitialMessage: "¡Entidad inactiva!"
      },
      operatorCreate: {
        icon: <CreateEntity />,
        InitialMessage: "¡Operador creado!"
      },
      providerEdit: {
        icon: <CreateEntity />,
        InitialMessage: "¡Operador actualizado!"
      },
      employeeCreate: {
        icon: <CreateEntity />,
        InitialMessage: "¡Usuario creado!"
      },
      employeeEdit: {
        icon: <CreateEntity />,
        InitialMessage: "¡Usuario actualizado!"
      },
      agreementCreate: {
        icon: <CreateEntity />,
        InitialMessage: "¡Vinculación creada!"
      },
      agreementCanceleSucces: {
        icon: <CanceleAgreementsIcon />,
        InitialMessage: "¡Vinculación anulada!"
      },
      agreementEdit: {
        icon: <CreateEntity />,
        InitialMessage: "¡Vinculación actualizada!"
      },
      userActive: {
        icon: <CreateEntity />,
        InitialMessage: "¡Usuario activo!"
      },
      userInactive: {
        icon: <InactiveEntity />,
        InitialMessage: "¡Usuario inactivo!"
      },
      providerActive: {
        icon: <CreateEntity />,
        InitialMessage: "¡Operador activo!"
      },
      providerInactive: {
        icon: <InactiveEntity />,
        InitialMessage: "¡Operador inactivo!"
      },
      clientActive: {
        icon: <CreateEntity />,
        InitialMessage: "¡Cliente activo!"
      },
      clientInactive: {
        icon: <InactiveEntity />,
        InitialMessage: "¡Cliente inactivo!"
      },
      userCantChangeStatus: {
        icon: <ChangeState />,
        InitialMessage: "¡No es posible activar este usuario!",
        message: "Para poder realizar esta acción, el usuario debe haber ingresado por primera vez a la plataforma.",
        buttonMessage: 'Entendido',
      },
      clientCantActive: {
        icon: <ChangeState />,
        InitialMessage: "¡No es posible activar este cliente!",
        message: "Ocurrió un error interno, vuelve a intentarlo mas tarde.",
        buttonMessage: 'Entendido',
      },
      clientCantInactive: {
        icon: <ChangeState />,
        InitialMessage: "¡No es posible inactivar este cliente!",
        message: "Ocurrió un error interno, vuelve a intentarlo mas tarde.",
        buttonMessage: 'Entendido',
      },
      providerCantActive: {
        icon: <ChangeState />,
        InitialMessage: "¡No es posible activar este operador!",
        message: "Ocurrió un error interno, vuelve a intentarlo mas tarde.",
        buttonMessage: 'Entendido',
      },
      providerCantInactive: {
        icon: <ChangeState />,
        InitialMessage: "¡No es posible inactivar este operador!",
        message: "Ocurrió un error interno, vuelve a intentarlo mas tarde.",
        buttonMessage: 'Entendido',
      },
      noAgreementsActive: {
        icon: <NotFoundAgreements />,
        InitialMessage: "¡No cuentas con una vinculación para enviar notificaciones!",
        message: "Comunícate con tu administrador.",
        buttonMessage: 'Aceptar',
      },
      massiveNotificationAlert: {
        icon: <MassiveInfoIcon />,
        InitialMessage: "¡Este envío puede tardar más de lo esperado!",
        message: "Podrás revisar el estado del envío desde la ventana desplegable que aperecerá en la parte inferior derecha.",
        buttonMessage: 'Entendido',
      },
      deleteDraft: {
        icon: <DeleteDraftIcon />,
        InitialMessage: "¡Borrador eliminado!"
      },
      deleteDraftList: {
        icon: <DeleteDraftIcon />,
        InitialMessage: "¡Borradores eliminados!"
      },
      errorHyperlinks: {
        icon: <FailSentIcon />,
        InitialMessage: "¡No es posible realizar el envío!",
        message: "Uno o varios archivos adjuntos contienen hipervínculos, por favor elimínalos y vuelve a intentarlo",
        buttonMessage: 'Aceptar'
      },
      finishCommentAuditor: {
        icon: <SentIcon />,
        message: 'Revisión enviada!',
      },
      exportInterceptorError: {
        icon: <ErrorCreate />,
        InitialMessage: "¡No es posible exportar el archivo!",
        message: "Ha ocurrido un error interno",
        buttonMessage: 'Aceptar'
      },
      errorBag: {
        icon: <NotFoundAgreements />,
        InitialMessage: "¡No es posible cargar los ciudadanos!",
        message: `Solo cuentas con ${props.hiredData.availableQuantity} notificaciones y estas intentando cargar ${props.modalState.quantyCitizen} ciudadanos, debes comunicarte con tu administrador para adquirir más bolsas`,
        buttonMessage: 'Entendido',
      },

    }
    return data[type]
  }

  const onClose = () => {
    props.changeMessageModal({ type: props.modalState.type })
    if (props.modalState.type === 'massiveNotificationAlert') {
      props.closewriteNotificationModal()
      props.chageStateLoadNotifications(true)
      props.cleanWriterFormOnly()

    }
  }

  const data = modalData(props.modalState.type)
  return (
    <ModalElement
      open={props.modalState.status}
      // size={props.modalState.type === 'errorHyperlinks' || props.modalState.type === 'warningFiles' || props.modalState.type === 'errorBag'?'tiny':'mini'}
      closeOnDimmerClick={props.modalState.type === 'finish' ? false : true}
      // className= {props.modalState.type === 'noAgreementsActive' && 'modalNoAgreements'}
      modaltype={props.modalState.type}
      modalState={data}
    >
      <ModalContent>
        {!!data.icon && data.icon}
        {data.multipleMessages && !!props.modalState.message && !!props.modalState.message.length &&
          props.modalState.message.map((message) => (
            <>
              <ModalMessage>
                {message}
              </ModalMessage>
              <br />
            </>
          ))
        }
        {!data.multipleMessages &&
          <ModalMessage>
            <Texto>{data.InitialMessage}</Texto>
            {!props.modalState.message
              ? data.message
              : props.modalState.message}
          </ModalMessage>
        }
        {
          props.modalState.type !== 'deleteDraft' && props.modalState.type !== 'deleteDraftList' && props.modalState.type !== 'finish' && props.modalState.type !== 'entityCreate' && props.modalState.type !== 'inactiveEntity' && props.modalState.type !== 'operatorCreate' && props.modalState.type !== 'entityEdit' && props.modalState.type !== 'clientEdit' && props.modalState.type !== 'employeeCreate' && props.modalState.type !== 'employeeEdit' && props.modalState.type !== 'providerEdit' && props.modalState.type !== 'agreementCreate' && props.modalState.type !== 'agreementEdit' && props.modalState.type !== 'userActive' && props.modalState.type !== 'userInactive' && props.modalState.type !== 'providerInactive' && props.modalState.type !== 'providerActive' && props.modalState.type !== 'clientInactive' && props.modalState.type !== 'clientActive' && props.modalState.type !== 'finishCommentAuditor' && props.modalState.type !== 'agreementCanceleSucces' &&
          <ModalActions>
            <PrimaryButton
              txtBtn={data.buttonMessage}
              onPress={onClose}
            />
          </ModalActions>
        }

      </ModalContent>
    </ModalElement>

  )
}
MessageModal.propTypes = {
  modalState: PropTypes.object,
  changeMessageModal: PropTypes.func,
  clearEditorChange: PropTypes.func,
  closewriteNotificationModal: PropTypes.func,
  cleanWriterFormOnly: PropTypes.func,
}
const mapStateToProps = (state) => ({
  modalState: state.modalReducer.messageModal,
  hiredData: state.writerReducer.hiredData,
})

const mapDispatchToProps = {
  changeMessageModal,
  clearEditorChange,
  closewriteNotificationModal,
  cleanWriterFormOnly,
  chageStateLoadNotifications,
  chageStateLoadNotificationsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageModal)
