import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'

import { actionClearFilter, actionInitialPagination, activePagination } from '../../../../actions/filterActions'
import { getClients } from '../../../../actions/clientActions'
import {changeDataCreateReport} from '../../../../actions/reportsActions'
import RowTableClientsOperator from '../RowTableClientsOperator'
import GeneralTable from '../../../../components/Table/GeneralTable'

class Clients extends Component {

  state = {
    loader: true,
    columnData: [
      { name: "Nombre", width: 3, arrow: false },
      { name: "Documento", width: 3, arrow: false },
      { name: "Teléfono", width: 3, arrow: false },
      { name: "Email", width: 3, arrow: false },
    ],
    init: true,
    dataCreateReport:{
      reportType:"LIST_CLIENTS",
      status:["ENABLE","DISABLE"],
      providerType:"CLIENT"
    }
  };

  componentDidMount() {
    this.props.changeDataCreateReport(this.state.dataCreateReport)
    this.makeClientsRequest(this.props.filterReducer)
  };

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.filterReducer, this.props.filterReducer) && !this.state.init) {
      this.makeClientsRequest(this.props.filterReducer)
      this.setState({init: true, loader: true })
    }
  };

  makeClientsRequest = (filterReducer) => {
    const body = {
      count: filterReducer.count,
      pag: filterReducer.pag,
      status: filterReducer.clientsAndProvidersFilter.clientAndProviderStatus,
      sort: this.orderTranslator(filterReducer.sort),
      textToSearch: filterReducer.searchBar[0]
    }

    this.props.getClients(body).then(() => {
      this.props.activePagination(false)
      this.setState({init: false, loader: false })
    })
  };

  orderTranslator = (order) => {
    const options = {
      'A-Z': { "name": 1 },
      'Z-A': { "name": -1 }
    }
    return options[order]
  };

  render() {
    return (
      <GeneralTable

        list={this.props.clients}
        typeList= {this.props.elementsCount=== 0 ? 'default' : 'clients'}
        listHeight = {'clients'}
        headerTable={this.state.columnData}
        rowComponent={RowTableClientsOperator}
        history={this.props.history}
        loader={this.state.loader} />
    )
  }
}

Clients.propTypes = {
  history: PropTypes.any.isRequired,
  getClients: PropTypes.func.isRequired,
  actionClearFilter: PropTypes.func,
  actionInitialPagination: PropTypes.func,
  clients: PropTypes.array.isRequired,
  elementsCount: PropTypes.number.isRequired,
  filterReducer: PropTypes.object.isRequired,
  changeDataCreateReport: PropTypes.func,
}

const mapStateToProps = (state) => ({
  clients: state.clientReducer.clients,
  elementsCount: state.clientReducer.elementsCount,
  filterReducer: state.filterReducer,
});

const mapDispatchToProps = {
  getClients,
  actionClearFilter,
  actionInitialPagination,
  activePagination,
  changeDataCreateReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(Clients)
