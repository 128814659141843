import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import Butterflies from '../../assets/icons/login/butterflies.svg'
import { ReactComponent as EmailIcon } from '../../assets/icons/emailIcon.svg'
import { ReactComponent as ErrorCreate } from '../../assets/icons/login/error.svg'
import PrimaryButton from '../PrimaryButton'
import { useDispatch, useSelector } from 'react-redux'
import { changeMessageModalLogin } from '../../actions/modalActions'

const Divider = styled.div`
  width: 446px !important;
  height: 480px !important;
  position: absolute;
  z-index: 999;
  top: 110px;
  left: 0;
  right: 0; 
  bottom: 0;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background: #FFFFFF;
  margin: auto;
  @media only screen and (max-width: 1200px)  {
    top: 80px;
    height: 450px !important;
  }
  @media only screen and (max-width: 482px)  {
    width: 87.47% !important;
  }
`
const ContentButterflies = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${Butterflies});
  background-repeat: no-repeat;
  background-position-x: 100%;
  padding: 50px;
  position: absolute;
  vertical-align: middle;
`
const DividerContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const DividerMessage = styled.div `
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin-top: 32px;
  margin-bottom: 32px;
`
const Texto = styled.p`
  color: #133F4F;
  font-family: Roboto;
  font-weight: 900;
  font-size: 17px;
  font-style: normal;
  line-height: 35px;
  text-align: center;
  margin-bottom: 15px !important;
`

const ModalGeneral = (props) => { 
    const dispatch = useDispatch()
    const history = useHistory()
    const {status, type} = useSelector((state) => state.modalReducer.messageLoginModal)
    const modalData = (type) => {
        const data = {
          sendEmail: {
            icon: <EmailIcon />,
            InitialMessage: "¡Revisa tu email!",
            message: 'Te hemos enviado un email para continuar con el proceso de verificación.',
            buttonMessage: 'Cerrar',
          },
          unknownError: {
            icon: <ErrorCreate />,
            InitialMessage: "¡Ha ocurrido un error interno!.",
            message: "Por favor inténtalo de nuevo en un momento.",
            buttonMessage: 'Aceptar',
          },
          sendEmailNewPassword: {
            icon: <EmailIcon />,
            InitialMessage: "¡Revisa tu email!",
            message: 'Hemos enviado a tu email las nuevas credenciales para restablecer tu contraseña',
            buttonMessage: 'Cerrar',
          },
        }
        return data[type]
    }

    const data = modalData(type)

    const onClose = () => {
        if (type === 'unknownError') {
          history.push('/login')
        } else if (type === 'sendEmailNewPassword'){
            history.push('/')
        } else if (type === 'sendEmail') { 
          history.push('/login')
        }
        dispatch(changeMessageModalLogin({ type: type }))
    }
    
    return (
        <>
        {
            status === true && type !== 'progress'
            && 
            <Divider>
            <ContentButterflies>
              <DividerContent>
                {!!data.icon && data.icon}
                {data.multipleMessages && !!props.modalState.message && !!props.modalState.message.length &&
                props.modalState.message.map((message) => (
                    <>
                    <DividerMessage>
                        {message}
                    </DividerMessage>
                    <br />
                    </>
                ))
                }
                <DividerMessage>
                    <Texto>{data.InitialMessage}</Texto>
                    {data.message}
                </DividerMessage>
                {type !== 'progress' &&
                        <PrimaryButton
                        txtBtn={data.buttonMessage}
                        onPress={onClose}
                        />
                }
              </DividerContent>
            </ContentButterflies>
        </Divider>
        }
        </>
    )
}
export default ModalGeneral