import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import styled from 'styled-components'

import './input-from-entities.css'
import './inputSelect.css'
import { useDispatch } from "react-redux"
import { activeFormClient } from "../../../actions/clientActions"
import { ReactComponent as ArrowIcon } from '../../../assets/icons/agreement/arrow.svg'

const Divider = styled.div`
  display: flex;
`
const Text = styled.p`
  padding-top: 15px;
  padding-left: 15px;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #000000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 60px;
`
const ButtonIcon = styled.button`
  position: absolute; 
  left: 90%; 
  top: 10px;
  background: #FFFF;
  border: none;
  cursor:pointer;
`
const List = styled.div`
  max-height: 296px;
  overflow-y: scroll;
  border: 1px solid #B2B2B2;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0px 4px 16px 0px #00000040;
  padding: 8px 8px;
`
const TextList = styled.p`
  padding: 4px 8px 4px 10px;
  cursor: pointer;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    background: #E4E4E4
  }
`
const Divider2 = styled.div`
  display: block;
  margin-top: -10px;
  padding-bottom: 4px;
  padding-top: 8px;
  position: sticky;
  top: -10px;
  background: #FFFF;
`
const InputSelect = ({ ...props }) => {

    const dispatch = useDispatch()
    const [stateList, setStateList] = useState(false)
    const [newData, setNewData] = useState('')
    const [searchProviderAndClient, setSearchProviderAndClient] = useState('')
    const [name, setName] = useState('')
    const [clientName, setClientName] = useState('')
    const { placeholder, options, value, idName, data, handleOnChange, validator, validateOptions, disabled, label, errorClient, errorProvider } = props
   
    let errorValidator = null;
    if (validator && validateOptions) errorValidator = validator.message('', value, validateOptions);
    const validator2 = (value) => {
        if (value !== "") {
            dispatch(activeFormClient())
        }
    }

    const clickList = () => {
        setStateList(!stateList)
    }
    const onChange = e => {
        setSearchProviderAndClient(e.target.value)
        validator2(value)
    }

    const clickSelect = (idName,id) => {
        handleOnChange(idName,id)
        setStateList(!stateList)
        validator2(value)
    }

    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        setStateList(!stateList)
      }
    }

    useEffect(() => {
        if(data){
            data.map((x) => {
            if (x.id === value) {
                setName(x.text)
            }})
        }
    }, [value, data])

    useEffect(() => {
        if(searchProviderAndClient !== ''){
        const text =  searchProviderAndClient.toLocaleLowerCase()
        let dataFilter = []
        for(let nameProviderAndClient of data) {
            let name = nameProviderAndClient.text.toLocaleLowerCase()
            if(name.indexOf(text) !== -1){
                dataFilter.push({text:nameProviderAndClient.text, id:nameProviderAndClient.id})
            }
        }
        setNewData(dataFilter)   
    }
    }, [searchProviderAndClient])

    return (
        <>
        <Divider onClick={clickList} className={ errorClient === true || errorProvider === true ? 'divInputDropdownFormEntitiesError' : 'divInputDropdownFormEntities'}>
            { errorClient === true || errorProvider === true  ?
                <>
                    <label className={ (errorClient === true || errorProvider === true)  && value === '' && 'spanLabelInputTopError'}>{placeholder}</label>
                    <label className={( errorClient === true || errorProvider === true ) && "input-error3"}>Campo obligatorio</label>
                </>
                :
                <span className={value !== '' ? 'labelInputDropdownFormEntitiesTop' : 'labelInputDropdownFormEntities'} >{placeholder}</span>
            }
            <Text>{name}</Text>
            <ButtonIcon
              onClick={clickList}
            >
            <ArrowIcon/>
            </ButtonIcon>
        </Divider>
        {
            stateList === true && data 
            ?
            <List>
                <Divider2>
                <input
                  id='searchProviderAndClient'
                  className='inputListSearch'
                  type='text'
                  value={searchProviderAndClient}
                  onChange={onChange}
                  autoComplete='off'
                  name={props.name}
                  onKeyDown={handleKeyDown}
                  autoFocus={true}
                />
                </Divider2>
                {
                    searchProviderAndClient !== '' && newData
                    ? 
                     newData.map((data, index)=>(
                      <TextList 
                        key={index}
                        onClick={()=>clickSelect(idName,data.id)}
                      >
                        {data.text}
                      </TextList>
                    ))
                    :
                      data.map((data, index)=>(
                        <TextList 
                          key={index}
                          onClick={()=>clickSelect(idName,data.id)}
                          >
                            {data.text}
                          </TextList>
                    ))
                }
            </List>
            : ''
        }
        </>
    )
}


InputSelect.propTypes = {
    labelProps: PropTypes.object,
    id: PropTypes.string,
}
export default InputSelect