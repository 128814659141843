import styled from "styled-components";
import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";
import React, { useEffect, useState } from "react";
import moment from "moment/moment";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {getReportDetail} from '../../actions/reportsActions'
import { ReactComponent as PDFIcon } from '../../assets/icons/reports/pdf-button.svg'
import { ReactComponent as CSVIcon } from '../../assets/icons/reports/csv-button.svg'
import { API_ENDPOINT } from "../../config/config";
import { Cell } from "../../components/Table/CellComponent";

const SentCell = styled(Table.Cell)`
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  max-width: 1px !important;
  padding: 10px !important;
`;

const Row = styled(Table.Row)`
  cursor: pointer !important;
  height: 50px;
  @media only screen and (min-width: 100px) and (max-width: 768px) {
    height: unset;
  }
`;

const ButtonEdit = styled.button`
  background: #FFFFFF;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  padding: 8px 8px;
  margin: 0 4px  0 4px;
  height:  32px;
  cursor: pointer;
  @media only screen and (max-width: 1366px) and (min-width: 1025px)  {
    svg{
      width: 100 !important;
      height: 16;
      fill: none;
    }
    
    @media only screen and (min-width: 1800px)  {
      width: 83.18px;
}
`

const RowTableReports = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [Isvisible, setIsVisible] = useState(false);
  const [finalizedStatus, setFinalizedStatus] = useState({status:false, fileName: props.data.fileName, file: props.data.url})
  const [queryReportDetail, setQueryReportDetail] = useState(false);
  const {toBeNotifiedList, reportDetail} = useSelector(state => state.reportsReducer)

  const formatDate = (dateToTransform ) => {
    return moment(dateToTransform).utc().format('DD/MM/YYYY')
  }

  const redirectToReportDetail = () => {
    props.history.push({ pathname: '/reportsDetail/' + props.data.reportSerialID })
  };

  const reportTypeToString = (type) =>{
    switch (type) {
      case 'WRONG_NOTIFICATION': 
        return 'Notificaciones erróneas';
      case 'STATUS_SINGLE': 
        return 'Estado';
      case "LIST_MASSIVE":
        return 'Estado';
      case "STATUS_SECUNDARY":
         return 'Estado'
      default:
        return 'Log de trazabilidad'
    }
  }
  
  const downloadReport = (status) =>{
    const link = document.createElement("a");
    link.setAttribute("download", status.fileName);
     link.href = status.file;
     document.body.appendChild(link);
     link.click();
 }

  useEffect(() => {
    toBeNotifiedList?.map(item => (
      item.reportSerialID === props.data.reportSerialID && item.reportStatus === "FINALIZED" ?
        changeReportDetail({reportSerialID: item.reportSerialID})
      : ''
    ))
  }, [toBeNotifiedList, dispatch])

  useEffect(() => {
    if(!!queryReportDetail){
      const {fileName, base64} = reportDetail 
      setFinalizedStatus({status: true, fileName: fileName, file: base64})
    }
    
  }, [reportDetail])

  const changeReportDetail = (data) =>{
    dispatch(getReportDetail(data))
    setQueryReportDetail(true)
  }

  return (
    <Row
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
      onClick={redirectToReportDetail}
    >
      <Cell
        width={3}
        text={formatDate(props.data.generationDate)}
        columnName={props.headerTable[0].name}
      />
      <Cell
        width={3}
        text={props.data.userName}
        columnName={props.headerTable[1].name}
      />
      <Cell
        width={3}
        text={reportTypeToString(props.data.reportType)}
        columnName={props.headerTable[2].name}
      />
      <Cell
        width={2}
        text={formatDate(props.data.initialDate)}
        columnName={props.headerTable[3].name}
      />
      <Cell
        width={2}
        text={formatDate(props.data.finalDate)}
        columnName={props.headerTable[4].name}
      />
      <Cell width={3}  text={''} columnName={props.headerTable[5].name}>
        {props.data.reportStatus == "FINALIZED" || !!finalizedStatus.status ? (
          <>
            <ButtonEdit
              onClick={(e) => {
                e.stopPropagation(); // Detener la propagación del evento
                downloadReport(finalizedStatus);
              }}
            >
              <CSVIcon/>
            </ButtonEdit>
          </>
        ) : (
          "En proceso..."
        )}
      </Cell>

    </Row>
  );
};

RowTableReports.propTypes = {
  data: PropTypes.object.isRequired,
};

export default RowTableReports;
