import {CHANGE_STYLES_MENU, CHANGE_SET_PATHNAME_CURRENT} from "./actionsUtilities/types.js"


export const changeStylesMenu = (data) => async (dispatch) => {
  dispatch({type: CHANGE_STYLES_MENU, payload: data})
}

export const setPathnameCurrent = (data) => async (dispatch) => {
  dispatch({type: CHANGE_SET_PATHNAME_CURRENT, payload: data})
}





