import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import {actionInitialPagination, activePagination } from '../../../../../actions/filterDetailActions'
import { detailAttachmentsV2, viewPDF, updateList, removeFromList, deleteAllFromList } from '../../../../../actions/seeAttachmentAction'
import styled from 'styled-components'
import CardAttachment from './CardAttachment'
import CardAttachmentDetail from './CardAttachmentDetail'

const Divider = styled.div`
  background: #EBEBEB;
  height: 45px;
  width: 100%;
`
const Title = styled.h1`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #133F4F;
  padding-top: 11px;
  padding-bottom: 10px;
  padding-left: 23px;
    
`
class AllAttachmentDetailGrid extends Component {

    state = {
        loader: true,
        init: true,
    }

    componentDidMount() {
        this.makeDetailAttachmentsRequest(this.props.filterReducer)
        this.props.viewPDF({ "viewPDF": false })
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(prevProps.filterReducer, this.props.filterReducer) && !this.state.init) {

            this.makeDetailAttachmentsRequest(this.props.filterReducer)
            this.setState({init: true, loader: true })
        }
    }

    makeDetailAttachmentsRequest = (filterReducer) => {
        this.props.deleteAllFromList()
        const body = {
            count: filterReducer.count,
            pag: filterReducer.pag,
            limit: 20,
            sort: this.orderTranslator(filterReducer.sort),
            status: this.validateStatus(filterReducer.searchAdvanced.notificationState),
            initDate: !!filterReducer.searchAdvanced.date.startDate
                ? moment(filterReducer.searchAdvanced.date.startDate).format('YYYY-MM-DD') : '',
            endDate: !!filterReducer.searchAdvanced.date.endDate
                ? moment(filterReducer.searchAdvanced.date.endDate).format('YYYY-MM-DD') : '',
            starred: filterReducer.starred,
            attachmentsName: filterReducer.searchBar[0],
            to: this.props.match.params.attachmentID,
        }

        if (filterReducer.searchAdvanced.notificationState === 'REQUIRED_SIGNATURE' || filterReducer.requiredSignature  === 'REQUIRED_SIGNATURE' ) {
            body.requiredSignature = true;
          }
      
          if (filterReducer.searchAdvanced.notificationState === 'NO_REQUIRED_SIGNATURE' || filterReducer.requiredSignature  === 'NO_REQUIRED_SIGNATURE') {
            body.requiredSignature = false;
          }
      

        if (body.initDate === "") {
            delete body.initDate
            delete body.endDate
        }

        this.props.detailAttachmentsV2(body)
            .then(() => {
                this.props.activePagination(false)
                this.setState({init: false, loader: false })
            })
    }

    validateStatus = (status) =>{
        return status === 'REQUIRED_SIGNATURE'  || status === 'NO_REQUIRED_SIGNATURE' ? '' : status
      }
    

    orderTranslator = (order) => {
        const options = {
            'masReciente': { "_id": -1 },
            'masAntiguo': { "_id": 1 },
            'A-Z': { "fileOriginalName": 1 },
            'Z-A': { "fileOriginalName": -1 }
        }
        return options[order]
    }

    addCheckedRow = (id) => {
        this.props.updateList(id)
      }
    
      removeCheckedRow = (id) => {
        this.props.removeFromList(id)
      }

    render() {
        return (
            <>
                <Divider>
                    <Title>{this.props.isSearch === true ? "Resultados" : "Documentos"}</Title>
                </Divider>
                <CardAttachment
                    list={this.props.listAttachments}
                    history={this.props.history}
                    loader={this.state.loader}
                    cardComponent={CardAttachmentDetail}
                    isOrder={this.props.sort}
                    addCheckedRow={this.addCheckedRow}
                    removeCheckedRow={this.removeCheckedRow}
                    activeAll={this.props.activeAll} 
                    to={this.props.match.params.attachmentID}
                    />
                
            </>
        )
    }
}

AllAttachmentDetailGrid.propTypes = {
    history: PropTypes.any.isRequired,
    detailAttachmentsV2: PropTypes.func.isRequired,
    actionClearFilter: PropTypes.func,
    actionInitialPagination: PropTypes.func,
    listAttachments: PropTypes.array.isRequired,
    countAttachments: PropTypes.number.isRequired,
    documentCurrent: PropTypes.string,
    userData: PropTypes.object,
    typeIcon: PropTypes.number.isRequired,
    filterReducer: PropTypes.object.isRequired,
    stateUserDocumentStarred: PropTypes.object,
}

const mapStateToProps = (state) => ({
    listAttachments: state.userDocumentReducer.detailAttachment,
    countAttachments: state.userDocumentReducer.countAttachments,
    documentCurrent: state.userDocumentReducer.documentCurrent,
    viewPDF: state.userDocumentReducer.viewPDF,
    userData: state.userDocumentReducer.userData,
    typeIcon: state.userDocumentReducer.typeIcon,
    filterReducer: state.filterDetailReducer,
    sort: state.filterDetailReducer.sort,
    isSearch: state.filterDetailReducer.isSearch,
    stateUserDocumentStarred: state.userDocumentReducer.stateUserDocumentStarred,
})

const mapDispatchToProps = {
    detailAttachmentsV2,
    actionInitialPagination,
    viewPDF,
    activePagination,
    removeFromList,
    updateList,
    deleteAllFromList
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AllAttachmentDetailGrid))