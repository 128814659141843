import React, { useState }  from 'react'
import Header from './Header'
import styled from 'styled-components'
import { useEffect } from 'react'
import { Dimmer, Loader } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import ReportContent from './ReportContent'
import {getReportDetail} from '../../../actions/reportsActions'

const ContainerScroll = styled.div`
  width: 100%;
  height: 89.3vh;
  overflow-y: scroll;

  ::-webkit-scrollbar-track {
    background: #CCCCCC;
  }

  ::-webkit-scrollbar-thumb {
    background: #9D9D9C !important;
  }

  ::-webkit-scrollbar:vertical {
    width: 5px;
  }
`

const ReportDetail = (props) => {
  const dispatch = useDispatch()
  const id = props.match.params.reportSerialID
  const [loader, setLoader] = useState(true)
  const [status, setStatus] = useState(false)
  const {typeReportCurrent, reportDetail} = useSelector((state) => state.reportsReducer)
  const body= {
    reportSerialID: id,
    format: "csv"
  }

  useEffect(() => {
    dispatch(getReportDetail({reportSerialID: body.reportSerialID})).then(() => {
      setStatus(true)
      setLoader(false)
    })
  }, [dispatch])

  

    return (
      <>
        <Dimmer active={loader} inverted>
          <Loader size='medium'>Cargando...</Loader>
        </Dimmer>
        <ContainerScroll>
          <Header
            history={props.history}
          />
          <ReportContent id={id}/>
        </ContainerScroll>
      </>
    )
}

export default ReportDetail