import React, {Component} from 'react'

import PropTypes from "prop-types";
import {
  addMassiveRecipientsList, changeWriteNotificationForm,
  filterMassiveRecipients,
  replaceRecipientDisplay, setMassiveRecipientFileInfo, updateHistoryMassive,
  updateRecipientDisplay, changeDisabledGetRecipient
} from "../../actions/writerActions";
import {connect} from "react-redux";
import styled, {css} from "styled-components";
import './dropdownStyle.css'
import { ClickAwayListener } from '@material-ui/core';

const DivHistoryRecipientsAdded = styled.div`
  ${props => props.fieldvisibility &&
          css`
            visibility: ${props.fieldvisibility.visibility};
            z-index: ${props.fieldvisibility.zIndex};
          `}
  max-width: 84%;
  overflow-x: visible;
  ${props => props.fieldHeightDivFocused &&
          css`
            overflow-y: ${props.fieldHeightDivFocused};
          `}
  ${props => props.fieldtop &&
          css`
            top: ${props.fieldtop};
          `}
  max-height: 7rem;
  position: relative;
  background-color: white;
  width: auto;
  margin-left: 10%;

  &:hover {
    visibility: visible;
  }
`


const ButtonItemsHistoryFor = styled.button`
  padding: 0.78571429rem 1.14285714rem!important;
  white-space: normal;
  word-wrap: normal;
  position: relative;
  cursor: pointer;
  display: block;
  height: auto;
  width: 100%;
  text-align: left;
  color: rgba(0,0,0,.87);
  font-size: 1rem;
  text-transform: none;
  font-weight: 400;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-touch-callout: none;
  background-color: #FFFFFF;
  outline: none;
  border: none;
  &:hover{
    background-color: #E4E4E4;
  }
`


class DropdownOptions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isHovering: false,
    }
  }


  handleClick = async evt => {
    this.props.changeDisabledGetRecipient(true)
    this.props.changeOptionsState({
      value: evt.target.value
    })
  }

  fieldHeightDivFocused = (listSize) => {
    if (listSize > 1)
      return `scroll`
    return `hidden`
  }

  fieldVisible = (visible, onHover) => {
    let attributes
    if (visible || onHover) {
      attributes = {visibility: `visible`, zIndex: `2`}
      return attributes
    } else {
      attributes = {visibility: `hidden`, zIndex: `-1`}
      return attributes
    }
  }

  fieldTop = (listSize) => {
    if (listSize <= 0)
      return `7%`
    return `0`
  }
  handleMouseHover = () => {
    this.setState({isHovering: true})
  }

  handleMouseOut = () => {
    this.setState({isHovering: false})
  }

  handleClickAway= () => {
    this.setState({isHovering: false})
  }

  render() {
    return (
      <ClickAwayListener onClickAway={this.handleClickAway}>
        <DivHistoryRecipientsAdded onMouseOver={this.handleMouseHover}
                                  fieldvisibility={this.fieldVisible(this.props.visible, this.state.isHovering)}
                                  fieldtop={this.fieldTop(this.props.listIdsSendMassive.ids.length)}
                                  fieldHeightDivFocused={this.fieldHeightDivFocused(this.props.items.length)}
                                  className={"dropdownForFocusOptions"}>
         
          {this.props.items.map(item => (
            <ButtonItemsHistoryFor onClick={this.handleClick} key={item.key} value={item.value} disabled={this.props.isDisabledGetRecipient}>
              Cédula: {item.value}
            </ButtonItemsHistoryFor>
          ))}
        </DivHistoryRecipientsAdded>
      </ClickAwayListener>
    )
  }
}

DropdownOptions.propTypes = {
  updateRecipientDisplay: PropTypes.func,
  replaceRecipientDisplay: PropTypes.func,
  recipientData: PropTypes.object,
  addMassiveRecipientsList: PropTypes.func,
  listIdsSendMassive: PropTypes.object,
  filterMassiveRecipients: PropTypes.func,
  setMassiveRecipientFileInfo: PropTypes.func,
  changeWriteNotificationForm: PropTypes.func,
  onEdition: PropTypes.bool,
  updateHistoryMassive: PropTypes.func,
  historyRecipient: PropTypes.array,
}
const mapStateToProps = (state) => ({
  recipientData: state.writerReducer.recipientData,
  listIdsSendMassive: state.writerReducer.listIdsSendMassive,
  onEdition: state.writerReducer.notificationForm.onEdition,
  historyRecipient: state.writerReducer.historyRecipient,
  isDisabledGetRecipient: state.writerReducer.isDisabledGetRecipient,
})
const mapDispatchToProps = {
  updateRecipientDisplay,
  replaceRecipientDisplay,
  addMassiveRecipientsList,
  filterMassiveRecipients,
  setMassiveRecipientFileInfo,
  changeWriteNotificationForm,
  updateHistoryMassive,
  changeDisabledGetRecipient,
}

export default connect(mapStateToProps, mapDispatchToProps)(DropdownOptions)
