import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled, {css} from 'styled-components'
import { openSendMassiveModal } from '../../../actions/modalActions'
import ModalSendMassive from '../ModalSend/ModalSendMassive'
import PropTypes from "prop-types";
import { ModalSendMassiveTwo } from '../ModalSend/ModalSendMassiveTwo'
import ModalLoaderFile from '../ModalSend/ModalLoaderFile'

const ButtonSendMassiveModal = styled.button`
  cursor: pointer;
  background: #FFFFFF;
  border: 2px solid #22C1D0;
  border-radius: 4px;
  box-sizing: border-box;
  color: #22C1D0;
  font-weight: 500;
  font-size: 0.75vw;
  width: 20%;
  padding: 5px;
  margin-left: 25px;
  position: relative;
  top: -3px;
  ${props => props.changeIsMassiveRecipient &&
    css`
      opacity: ${props.changeIsMassiveRecipient};
    `}
  @media only screen and (max-width: 992px) {
    font-size: 16px;
    margin: 0;
    width: 100%;
    height: 30px;
    position: absolute;
    top: -50px;
  }
`

class SendMassiveButton extends Component {
  render() {
    return (
      <>
        <ButtonSendMassiveModal
          onClick={this.props.openSendMassiveModal}
          changeIsMassiveRecipient={(!!this.props.isMassiveRecipient || this.props.recipients.length > 0)? '0.4':'1'}
          disabled= {(!!this.props.isMassiveRecipient || this.props.recipients.length > 0)? true: false}
        >
          Carga masiva
        </ButtonSendMassiveModal>
        <ModalSendMassiveTwo />
        <ModalLoaderFile/>
      </>
    )
  }
}

SendMassiveButton.propTypes = {
  sendMassiveModal: PropTypes.object,
  openSendMassiveModal: PropTypes.func,
  isMassiveRecipient: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  sendMassiveModal: state.modalReducer.sendMassiveModal,
  isMassiveRecipient: state.writerReducer.isMassiveRecipient,
  recipients: state.writerReducer.notificationForm.recipient,
})

const mapDispatchToProps = {
  openSendMassiveModal
}

export default connect(mapStateToProps, mapDispatchToProps)(SendMassiveButton)
