import React, {Component} from 'react'
import {connect} from 'react-redux'
import styled from 'styled-components'
import {
  changeWriteNotificationForm,
  closeLoadMassiveRecipients,
  addMassiveRecipientsList,
  updateHistory, updateHistoryMassive, updateRecipientDisplay, isMassiveRecipient, filterMassiveRecipients
} from '../../../actions/writerActions'
import { saveDraftData } from '../../../actions/draftsActions' 
import './main.css'
import PropTypes from "prop-types";
import SendMassiveButton from "../Button/SendMassiveButton"
import DropdownAllowAddition from "../../Dropdown/DropdownAllowAddition"

const Content = styled.div`
  width: 100%;
  display:block;
`

const DivFor = styled.div`
  height: 100%;
  width: 80%;
`
const DivMassiveButton = styled.div`
  height: 100%;
  width: 20%;
`


class InputsFor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      disableRecipientField: true,
      options: [],
      to: '',
      recipientError: {status: false, content: ''}
    }
  }

  

  changeState = (data) => {
    this.setState((prevState) => ({
      ...prevState,
      to: data.value === undefined ? "" : data.value,
      recipientError: {
        ...prevState.recipientError,
        status: data.recipientError.status,
        content: data.recipientError.content
      }
    }))
  }

  render() {
    return (
      <>
        <Content>
          <div className='forContent'>
              <DropdownAllowAddition
                label={"Para"}
                value={this.props.isMassiveRecipient? this.props.form.recipient : this.state.to}
                items={this.props.form.recipient}
                recipientError={this.state.recipientError}
                required={true}
                changeState={this.changeState}
                massiveRecipient={this.props.isMassiveRecipient}  
              />
          </div>
          <SendMassiveButton/>
        </Content>
      </>
    )
  }
}

InputsFor.propTypes = {
  updateHistory: PropTypes.func,
  updateHistoryMassive: PropTypes.func,
  updateForm: PropTypes.func,
  form: PropTypes.object,
  recipientData: PropTypes.object,
  historyRecipient: PropTypes.array,
  isMassiveRecipientValue: PropTypes.bool,
  isMassiveRecipientAction: PropTypes.func,
  massiveRecipientFileName: PropTypes.string,
  listIdsSendMassive: PropTypes.object,
  closeLoadMassiveRecipients: PropTypes.func,
  updateRecipientDisplay: PropTypes.func,
  addMassiveRecipientsList: PropTypes.func,
  massiveRecipientFileId: PropTypes.array,
  filterMassiveRecipients: PropTypes.func,
  isMassiveRecipient: PropTypes.bool
}

const mapStateToProps = (state) => ({
  form: state.writerReducer.notificationForm,
  recipientData: state.writerReducer.recipientData,
  historyRecipient: state.writerReducer.historyRecipient,
  isMassiveRecipientValue: state.writerReducer.isMassiveRecipient,
  listIdsSendMassive: state.writerReducer.listIdsSendMassive,
  massiveRecipientFileName: state.writerReducer.massiveRecipientFileName,
  massiveRecipientFileId: state.writerReducer.massiveRecipientFileId,
  isMassiveRecipient: state.writerReducer.isMassiveRecipient
})

const mapDispatchToProps = {
  changeWriteNotificationForm,
  updateHistory,
  updateHistoryMassive,
  closeLoadMassiveRecipients,
  updateRecipientDisplay,
  addMassiveRecipientsList,
  isMassiveRecipientAction: isMassiveRecipient,
  filterMassiveRecipients,
  saveDraftData
}

export default connect(mapStateToProps, mapDispatchToProps)(InputsFor)
