import React, { Component } from 'react'
import Switch from "react-switch";
import './input-from-users.css'


class InputSwitch extends Component {
    render() {
        return (
            <label htmlFor="material-switch">
                <span className={'spanSwitch'}>Activo</span>
                <Switch
                    checked={this.props.checked}
                    onChange={this.props.onChange}
                    onColor="#133F4F"
                    onHandleColor="#FFFFFF"
                    handleDiameter={15}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={22}
                    width={36}
                    className="react-switch"
                    id="material-switch"
                />
            </label>
        )
    }
}

export default InputSwitch