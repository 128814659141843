import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Divider, Form, Message } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { loginUserCipher,checkLoginStatus } from '../../actions/authActions'
import { reactValidatorOptions } from '../../helpers/simpleReactValidator'
import ImageSegment from '../../components/Sengment/ImageSegment'
import InputFormMaterial from '../../components/InputForm/InputFormMaterial'
import PrimaryButton from '../../components/PrimaryButton'
import { generateKeyTosessionStorage } from '../../helpers/cypher'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useSelector } from 'react-redux'
import useValidator from '../../helpers/simpleReactValidatorHook'
import { CLEAR_ERRORS, VALID_TOKEN } from '../../actions/actionsUtilities/types'
import { Recaptcha } from './Recaptcha'
//import { Timer } from '../../components/Timer/Timer'

const CustomPrimaryButton = styled(PrimaryButton)`
  background: #22C1D0 !important;
  border-radius: 4px !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 16px !important;
  text-align: center !important;
  color: #FFFFFF !important;
  padding: 16px 32px !important;;
  width: 141px !important;
  
  @media only screen and (max-width: 992px)  {
    width: 100% !important;
  }
`
const LoginForm = styled.div`
  height: 80%;
`
const CustomForm = styled(Form)`
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  gap: 10px;
`
const Footer = styled.div`
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: flex-end
`
const ForgotPassword = styled.button`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  text-decoration-line: underline;
  color: #22C1D0;
  cursor: pointer;
  border: none;
  background: white;
`
const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #133F4F;
`
const MessageLogin = styled(Message)`
  width: 100% !important;
  text-align: center !important;
  letter-spacing: 0 !important;
  background: #FFE2E9 !important;
  border: 1px solid #BA3555 !important;
  box-sizing: border-box !important;
  border-radius: 4px;
  color: #BA3555 !important;
  margin: 10px !important;
`
// const MessageLogin2 = styled.div`
//   width: 100% !important;
//   text-align: center !important;
//   letter-spacing: 0 !important;
//   background: #FFE2E9 !important;
//   border: 1px solid #BA3555 !important;
//   box-sizing: border-box !important;
//   border-radius: 4px;
//   color: #BA3555 !important;
//   margin: 10px !important;
//   padding: 10px 5px;
// `

export const Login = () => {

  const customEqual = (oldValue, newValue) => oldValue === newValue
  var errorReducer = useSelector(state => state.errorReducer, customEqual)
  const [loginValidator, showValidationMessage] = useValidator(reactValidatorOptions)
  var { statusIP, accessTime, validToken } = useSelector(state => state.authReducer, customEqual)
  const history = useHistory()
  const dispatch = useDispatch()

  const [state, setState] = useState({ username: '', password: '' })
  const [errors, setErrors] = useState({ error: '', message: '', username: '', password: '' })
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    dispatch(checkLoginStatus())
    if (!sessionStorage.getItem('publicKey')) generateKeyTosessionStorage()
  }, [])

  useEffect(() => {
    setErrors(errorReducer.errorsForm)
  }, [errorReducer])

  const onChange = (e) => {
    setState({ ...state, [e.target.id]: e.target.value })
  }

  const setLogin = async (e) => {
    e.preventDefault()
    if (validToken && Object.keys(validToken).length === 0 && state.username !== '' && state.password !== '') {
      setShowError(true)
    } else {
      if (loginValidator.allValid() ) { //&& statusIP === 'ALLOWED'
        let userData = { email: state.username, password: state.password }
        dispatch(loginUserCipher(history, userData))
      } else {
        showValidationMessage(true)
      }
    }
  }

  const onExpiredCaptcha = () => {
    dispatch({ type: VALID_TOKEN, payload: {} })
  }

  useEffect(() => {
    if (validToken && Object.keys(validToken).length !== 0) {
      if ( validToken.valid) { //statusIP === 'ALLOWED' &&
        setShowError(false)
      }
    }
  }, [validToken])

  return (
    <ImageSegment>
      <LoginForm>
        <Title>Login</Title>
        <CustomForm className='ui form' onSubmit={setLogin}>
          <InputFormMaterial
            id={'username'}
            label={'Usuario'}
            type={'text'}
            onChange={onChange}
            value={state.username}
            validator={loginValidator}
            validateOptions={'required'}
            autocomplete="username"
            error={errors.username}
          />
          <InputFormMaterial
            id={'password'}
            label={'Contraseña'}
            type={'password'}
            onChange={onChange}
            value={state.password}
            validator={loginValidator}
            validateOptions={'required'}
            autoComplete={"password"}
            error={errors.password}
          />
          <Recaptcha onExpiredCaptcha={onExpiredCaptcha} 
          //accessTime={accessTime} 
          showError={showError} />
          {errors.message && errors.internalCode === "UPI" && 
          //!accessTime &&
            <MessageLogin content={errors.message.slice(0, -1) + ',  por favor inténtalo de nuevo.'} />}
          {//Bloqueo de inicio de sesión por intentos fallidos
          /* {!!accessTime &&
            <MessageLogin2>
              <Timer message={'Se ha detectado actividad inusual al ingresar usuario y/o contraseña no válidos, por seguridad, el inicio de sesión' +
                ' será bloqueado durante '}/>
            </MessageLogin2>
          } */}
          <CustomPrimaryButton 
          //disable={!!accessTime} 
          fluid={true} txtBtn='INGRESAR' login={true} />
        </CustomForm>
      </LoginForm>
      <Divider />
      <Footer>
        <ForgotPassword as={Link} to={'/forgotPassword'} onClick={()=>{dispatch({type:CLEAR_ERRORS})}}> Olvidé mi contraseña </ForgotPassword>
      </Footer>
    </ImageSegment>
  )
}

export default Login