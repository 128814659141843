import React, { Component } from 'react'
import styled, {css} from 'styled-components'
import { Form } from 'semantic-ui-react'
import SimpleReactValidator from 'simple-react-validator'
import { connect } from 'react-redux'

import { reactValidatorOptions } from '../../helpers/simpleReactValidator'
import CheckboxForm from '../../components/CheckboxForm/CheckboxForm'
import Header from '../../components/Header/Header'
import {
  changeHeaderCheckbox,
  changeWriteNotificationForm,
  cleanWriterForm,
  clearEditorChange,
  saveHiredV2,
  inactiveFormProvider,
  cleanWriterFormOnly
} from '../../actions/writerActions'
import { ReactComponent as AlertIcon } from '../../assets/icons/write/warning-alet-icon.svg'
import { saveDraftData,saveDraftV2, draftsListV2 } from '../../actions/draftsActions'
import { closewriteNotificationModal } from '../../actions/modalActions'
import { clearDraftData } from '../../actions/draftsActions'
import { ReactComponent as Close } from '../../assets/icons/write/close.svg'
import { ReactComponent as DisplayIconOn } from '../../assets/icons/write/display-icon-on.svg'
import { ReactComponent as DisplayIconOff } from '../../assets/icons/write/display-icon-off.svg'
import _, { forEach } from "lodash";
import PropTypes from 'prop-types'
import moment from 'moment'

const Container = styled.div`
  margin: 15px 30px 0 30px ;
  @media only screen and (max-width: 1500px) {
    margin: 10px 16px;
  }
`

const HeaderTitle = styled(Header)`
  color: #133F4F;
  font-weight: 600;
  @media only screen and (max-width: 1500px) {
    font-size: 18px !important;
  }
`
const NotificationCount = styled.p`
  display: none;
  color: #E35F00;
  line-height: 24px;
  position: absolute;
  right: 25%;
  @media only screen and (max-width: 1500px) {
    display: block;
    font-size: 16px !important;
    right: 12%;
  }

  @media only screen and (max-width: 500px) {
    position: inherit;
    left: 0;
  }
`

const TypeNotificationCount = styled.p`
  
  @media only screen and (max-width: 1500px) {
    display: none;
  }
`

const ChecksBox = styled(Form)`
  @media only screen and (max-width: 1500px) {
    ${props => props.changeViewCheckbox &&
      css`
        display: ${props.changeViewCheckbox.display};
      `}
  }
`

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: inherit;
  position: relative;
`

const CloseButton = styled.a`
  width:16px;
  height: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 3px 0px;
`
const TextTypeContainer = styled.div`
  width:100%;
  margin 10px auto;
  position: relative;
  display:none;
  @media only screen and (max-width: 1500px) {
    display:block;
  }
`
const TextType = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #22C1D0;
  text-align: left;
  cursor: pointer;
`
const DisplayIconUp = styled(DisplayIconOn)`
  position: absolute;
  right: 0;
`

const DisplayIconDown = styled(DisplayIconOff)`
  position: absolute;
  right: 0;
`
const Text = styled.p`
  color: #E35F00;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-right: 15px;
`
const TextAlert = styled.p`
  color: #E35F00;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21.09px;
`
const TextAlert2 = styled.p`
  color: #E35F00;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21.09px;
`
const ListItem = styled.li`
  float: left;
  color: #E35F00;
  width: 100%;
`
const WarningList = styled.ul`
  margin: 0;
  padding-left: 3%;
  width: 90%;
`
const CustomDiv = styled.div`
  display: flex;
  padding: 16px 24px 16px 24px;
  width: 100%;
  background: rgba(227, 95, 0, 0.1);
  border: 1px solid #E35F00;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 10px 0 24px 0;
  align-items:center;
  height:auto;
  min-height: 56px;
  font-weight: 400;
  font-size: 18px !important;
  line-heigt:21.09px;
  gap:10px;
`
class ViewHeader extends Component {
  constructor(props) {
    super(props)
    this.validator = new SimpleReactValidator(reactValidatorOptions)
    
  }

  componentDidMount() {
    this.props.saveHiredV2()
    if(!!Object.keys(this.props.specificFiles).length){
      for (let index = 0; index < Object.keys(this.props.specificFiles).length; index++) {
        const file = this.props.specificFiles[index];
        if(file.isSignedFile){
          this.props.changeHeaderCheckbox(true)
          break
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.checkboxHeaderDisabled, this.props.checkboxHeaderDisabled)) {
      this.setState({ disableNotificationSignature: this.props.checkboxHeaderDisabled })
      this.setState({ notificationSignature: false }, async () => {
        await this.props.updateForm("notificationSignature", this.state.notificationSignature)
      })
    }
    if (!_.isEqual(prevProps.files, this.props.files) && Object.keys(this.props.files).length === 0) {
      this.setState({ disableNotificationSignature: false }, async () => {
        await this.props.changeHeaderCheckbox(false)
      })
      this.setState({ notificationSignature: false }, async () => {
        await this.props.updateForm("notificationSignature", this.state.notificationSignature)
      })
    }
    if (prevProps.readReceipt !== this.props.readReceipt) {
      this.setState({ readReceipt: this.props.readReceipt })
    }
    if (prevProps.notificationSignature !== this.props.notificationSignature) {
      this.setState({ notificationSignature: this.props.notificationSignature })
    }
    if (prevProps.pushNotification !== this.props.pushNotification) {
      this.setState({ pushNotification: this.props.pushNotification })
    }
    if (prevProps.hiredData !== this.props.hiredData) {
      this.setState({ hiredData: this.props.hiredData })
    }
  }

  state = {
    notificationSignature: this.props.notificationSignature,
    readReceipt: this.props.readReceipt,
    pushNotification: this.props.pushNotification,
    disableNotificationSignature: false,
    closeTypeNotification: true,
  }

  toggle = (data) => {
    const id = data.target.id
    this.setState(
      (prevState) => ({ [id]: !prevState[id] }),
      async () => {
        this.props.updateForm(id, this.state[id])
        switch (id) {
          case 'notificationSignature':
              await this.props.saveDraftData({requiredSignature:this.state[id]})
            break;
          case 'readReceipt':
              await this.props.saveDraftData({requiredConfirmationRead:this.state[id]})
            break;
          case 'pushNotification':
              await this.props.saveDraftData({isPushNotification :this.state[id]})
            break;
          default:
            break;
        }
        const { draftData } = this.props;
        this.props.saveDraftV2(draftData)
      }
    )
  }

  clearState = async () => {
    await this.props.closewriteNotificationModal()
    await this.props.inactiveFormProvider()
    await this.props.cleanWriterForm()
    await this.props.clearEditorChange()
    await this.props.clearDraftData()
  }

  changeViewNotificationType= () =>{
    this.setState({closeTypeNotification: !this.state.closeTypeNotification})
  }

  changeDateViewNotificationType= (close) =>{
    if(!!close){
      return {display:`none`}
    }else{
      return {display:`block`}
    }
  }
  formatDate = (date) => {
    return moment(date).format('DD/MMM/YYYY');
  }

  render() {
    return (
      <Container>
        <HeaderContainer>
          <HeaderTitle as='h1'>Nueva Notificación</HeaderTitle>
          <NotificationCount>
            {this.props.notificationsAvailable !== null? `Disponibles: ${this.props.notificationsAvailable}`: ''}
          </NotificationCount>
          <CloseButton
            onClick={() => this.clearState()}
          >
            <Close />
          </CloseButton>
        </HeaderContainer>
        {this.props.hiredData.closeToExpiration &&
          <CustomDiv >
            <AlertIcon /><TextAlert><strong>La vinculación actual vence el {this.formatDate(this.props.hiredData.dateToExpiration)}.</strong> Recuerda tener una vinculación aprobada para continuar con el envío de notificaciones</TextAlert>
          </CustomDiv>
          }

        <TextTypeContainer
              onClick={this.changeViewNotificationType}
              style={!!this.state.closeTypeNotification? {"borderBottom": '1px solid #979E9F'} : {"borderBottom": '0'}}
            >
              <TextType>Tipo de Notificación {!!this.state.closeTypeNotification? <DisplayIconDown/> : <DisplayIconUp/>}</TextType>
        </TextTypeContainer>
        <ChecksBox changeViewCheckbox= {this.changeDateViewNotificationType(this.state.closeTypeNotification)}>
          <Form.Group inline>
            {
                this.props.notificationsAvailable !== null
                ?
                <TypeNotificationCount>
                  <Text>
                    Notificaciones disponibles: {this.props.notificationsAvailable}
                  </Text>
                </TypeNotificationCount>
                : ''
              }
            <CheckboxForm
              label='Firma en notificación'
              onChange={this.toggle}
              value={this.state.notificationSignature}
              validator={this.validator}
              validateOptions='required'
              id='notificationSignature'
              disabled={this.props.hiredData.servicesList.notificationSignature === false ? true : this.props.checkboxHeaderDisabled === true ? true : false}
            />
            <CheckboxForm
              label='Confirmación de lectura'
              onChange={this.toggle}
              value={this.state.readReceipt}
              validator={this.validator}
              validateOptions='required'
              id='readReceipt'
              disabled={!this.props.hiredData.servicesList.notificationReading}
            />
            <CheckboxForm
              label='Notificación push'
              onChange={this.toggle}
              value={this.state.pushNotification}
              validator={this.validator}
              validateOptions='required'
              id='pushNotification'
              disabled={!this.props.hiredData.servicesList.pushNotification}
            />
          </Form.Group>
        </ChecksBox>
      </Container>
    )
  }
}

ViewHeader.propTypes = {
  checkboxHeaderDisabled: PropTypes.bool,
  updateForm: PropTypes.func,
  files: PropTypes.object,
  changeHeaderCheckbox: PropTypes.func,
  closewriteNotificationModal: PropTypes.func,
  cleanWriterForm: PropTypes.func,
  clearEditorChange: PropTypes.func,
  saveHiredV2: PropTypes.func,
  inactiveFormProvider: PropTypes.func
}

const mapStateToProps = state => ({
  checkboxHeaderDisabled: state.writerReducer.checkboxHeaderDisabled,
  files: state.writerReducer.notificationForm.files,
  specificFiles: state.writerReducer.listDocumentsSpecific,
  readReceipt: state.writerReducer.notificationForm.readReceipt,
  notificationSignature: state.writerReducer.notificationForm.notificationSignature,
  notificationsAvailable: state.writerReducer.notificationsAvailable,
  pushNotification: state.writerReducer.notificationForm.pushNotification,
  hiredData: state.writerReducer.hiredData,
  draftData : state.draftReducer.toSaveDraft,
})

const mapDispatchToProps = {
  closewriteNotificationModal,
  updateForm: changeWriteNotificationForm,
  changeHeaderCheckbox: changeHeaderCheckbox,
  cleanWriterForm: cleanWriterFormOnly,
  clearEditorChange: clearEditorChange,
  saveHiredV2,
  inactiveFormProvider,
  clearDraftData,
  saveDraftData,
  saveDraftV2,
  draftsListV2
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewHeader)