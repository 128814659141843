import React, { Component } from 'react'
import { SearchBarDetailGenericAdvancedSearch } from '../../SearchBarGeneric'
import { connect } from 'react-redux';

class ContainerUserDocumentSearch extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchBarState: "",
      popUpFilterState: "",
      popUpOrderState: "",
    };
  }
  render() {
    return (
      <SearchBarDetailGenericAdvancedSearch searchBarState={this.searchBarState} popUpOrderState={this.popUpOrderState}
      popUpFilterState={this.popUpFilterState} typeButton={'attachments'} viewGrid={this.props.viewGrid} showAZ={this.props.showAZ} 
      textPlaceHolder={this.props.textPlaceHolder} isVisible={this.props.isVisible} handleSelectAll={this.props.handleSelectAll} 
      selectCheckBox={this.props.selectCheckBox} handleClickDownload={this.props.handleClickDownload} 
      isVisibleCheck={this.props.isVisibleCheck} checkedAll={this.props.checkedAll}/>
    )
  }
}
const mapStateToProps = (state) => ({
  viewList: state.userDocumentReducer.viewList,
  viewGrid: state.userDocumentReducer.viewGrid
})

export default connect(mapStateToProps)(ContainerUserDocumentSearch)