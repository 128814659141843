import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import { actionClearFilter, actionInitialPagination, activePagination, actionPagination } from '../../../../actions/filterActions'
import { getUserDocumentsV2 } from '../../../../actions/userDocumentsActions'
import RowTableUserDocuments from './RowTableUserDocuments'
import GeneralTable from '../../../../components/Table/GeneralTable'
import RowTableUserDocumentsMix from './RowTableUserDocumentsMix'

class UserDocuments extends Component {

  static propTypes = {
    history: PropTypes.any.isRequired,
  }

  state = {
    loader: true,
    columnData: [
      { name: "No. de identificación", width: 8, arrow: false },
      { name: "Última modificación", width: 3, arrow: false },
      { name: "", width: 2, arrow: false },
    ],
    columnDataMix: [
      { name: "", width: 1, arrow: false },
      { name: "Carpeta/archivos", width: 4, arrow: false },
      { name: "No. de identificación", width: 3, arrow: false },
      { name: "Última modificación", width: 3, arrow: false },
      { name: "", width: 3, arrow: false },
      { name: "", width: 2, arrow: false },
    ],
    init: true,
  }

  componentDidMount() {
      this.makeUserDocumentsRequest(this.props.filterReducer)
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.filterReducer, this.props.filterReducer) && !this.state.init) {

      this.makeUserDocumentsRequest(this.props.filterReducer)
      this.setState({init: true, loader: true })  
    }
  }

  makeUserDocumentsRequest = (filterReducer) => {
    const body = {
      pag: filterReducer.pag,
      limit: filterReducer.count,
      sort: this.orderTranslator(filterReducer.sort),
      textToSearch: filterReducer.searchBar[0],
      status: filterReducer.searchAdvanced.notificationState,
      typeSearch: filterReducer.searchAdvanced.typeSearch,
      signDocumentFilter: filterReducer.searchAdvanced.signDocumentFilter,
      initDate: !!filterReducer.searchAdvanced.date.startDate
        ? moment(filterReducer.searchAdvanced.date.startDate).format('YYYY-MM-DD') : '',
      endDate: !!filterReducer.searchAdvanced.date.endDate
        ? moment(filterReducer.searchAdvanced.date.endDate).format('YYYY-MM-DD') : '',
    }
    this.props.getUserDocumentsV2(body).then(() => {
      this.props.activePagination(false)
      this.setState({init: false, loader: false })  
    })
  }

  orderTranslator = (order) => {
    const options = {
      'masReciente': { "lastModification": -1 },
      'masAntiguo': { "lastModification": 1 },
      'A-Z': { "nameElement": 1 },
      'Z-A': { "nameElement": -1 }
    }
    return options[order]
  }

  render() {
    return (
      <GeneralTable
        headerTable={this.props.filterReducer.viewTableMix === true ? this.state.columnDataMix : this.state.columnData}
        rowComponent={this.props.filterReducer.viewTableMix === true ? RowTableUserDocumentsMix : RowTableUserDocuments}
        history={this.props.history}
        list={this.props.userDocuments}
        loader={this.state.loader} 
        typeList="userDocuments"
        listHeight="userDocuments"
        />
    )
  }
}

const mapStateToProps = (state) => ({
  userDocuments: state.userDocumentReducer.userDocuments,
  elementsCount: state.userDocumentReducer.elementsCount,
  filterReducer: state.filterReducer,
})

const mapDispatchToProps = {
  getUserDocumentsV2,
  actionClearFilter,
  actionInitialPagination,
  activePagination,
  actionPagination,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDocuments)