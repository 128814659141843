import React, { Component } from 'react'
import { Dimmer, Dropdown, Form, Modal, Segment } from 'semantic-ui-react'
import styled from 'styled-components'
import { connect } from 'react-redux'

import { openDateModal, closeDateModal } from '../../../actions/modalActions'
import FormFilter from './FormFilter/FormFilter'
import DateFilter from './DateFilter/DateFilter'
import { actionSearchAdvanced } from '../../../actions/filterActions'
import { ReactComponent as Close } from '../../../assets/icons/close.svg'
import './style.css'

const Divider = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const Texto = styled.p`
  color: #000000;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`
const TextoHide = styled.p`
  display: none;
  color: #000000;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  margin: 0 0 0em;
  @media only screen and (max-width: 768px) {
    display: flex;
  }
`
const TextoHide2 = styled.p`
  display: none;
  color: #000000;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  padding: 10px 0 0 0;
  @media only screen and (max-width: 768px) {
    display: flex;
  }
`
const Texto2 = styled.p`
  color: #000000;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  padding: 10px 0 0 0;
  line-height: 24px;
  margin-bottom: 16px !important;
  @media only screen and (max-width: 1024px) {
    display: none;
  }
`
const Divider2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-block: 15px;
`

const ButtonDeleteFilter = styled.button`
  color: #22C1D0;
  border: none;
  background: #FFFFFF;
  text-decoration: underline;
  padding-right: 64px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    padding-right: 10px;
    width: 47%;
  }
`
const ButtonStartFilter = styled.button`
  color: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #22C1D0;
  background: #22C1D0;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  height: 48px;
  width: 193px;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    padding-right: 2%;
    width: 47%;
  }
`
const ModalDate = styled.div`
  width:100%;
  padding-top: 0px !important;
  @media only screen and (max-width: 1024px) {
    width:100% !important;
    padding-left:0 !important;
}
`

const ModalSent = styled.div`
  width: 80% !important;
  max-width: 854px;
  min-height: 575px;
  height: auto; 
  position: relative;
  padding: 20px 27px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  margin-top: 4rem;
  margin-left: 17rem;
  align-items:center;
  @media only screen and (max-width: 1200px) {
    margin-left: 0;
  }
  @media only screen and (max-width: 768px) {
    width: 100% !important;
    max-height: unset;
    height: -webkit-fill-available;
    margin: -16px 0 0 0 !important;
    
  }
`

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-center; 
  justify-content: center;
  align-items: center;
  z-index:4000;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 25.76px;
  right: 24px;
  border: none;
  background: none;
  cursor: pointer;
`;


class ModalFilterDate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      attachedFile: false,
      notificationState: '',
      date: {},
      dropValue: ''
    };
  }


  componentDidUpdate(prevProps, prevState) {
    if (prevProps.stateSearch !== this.props.stateSearch) {
      this.setState({ notificationState: this.props.stateSearch })
    }
  }

  handleOnChange = (e, { value }) => {
    this.setState({
      notificationState: value
    }, () => { })
  }

  toggleCheckbox = (e) => {
    let name = e.target.id;
    this.setState({
      [name]: !this.state[name]
    })
  }

  reset = () => {
    this.setState({
      attachedFile: false,
      notificationState: '',
      date: {}
    })
  }

  setDateState = (date) => {
    this.setState({ date: date })
  }

  orderTranslator = (order) => {
    const options = {
      'masReciente': { notificationDate: -1 },
      'masAntiguo': { notificationDate: 1 },
      'A-Z': { notificationDate: -1 },
      'Z-A': { notificationDate: 1 }
    }
    return options[order]
  }

  makeBodyRequest = () => {
    const body = {
      pag: 1,
      sort: 'masReciente',
      starred: false,
      searchAdvanced: {
        date: this.state.date,
        notificationState: this.state.notificationState,
        attachedFile: this.state.attachedFile
      }
    }
    this.props.actionSearchAdvanced(body)
    this.props.closeDateModal()
  }

  makeBodyRequestDeleteFilter = () => {
    const body = {
      pag: 1,
      sort: 'masReciente',
      starred: false,
      searchAdvanced: {
        date: {
          startDate: "",
          endDate: ""
        },
        notificationState: '',
        attachedFile: false
      }
    }
    this.props.actionSearchAdvanced(body)
    this.props.closeDateModal()
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.makeBodyRequest()
  }

  handleDeleteFilter = (e) => {
    e.preventDefault()
    this.makeBodyRequestDeleteFilter()
    this.reset()
  }

  validClose = () => {
    this.setState({
      notificationState: this.props.stateSearch
    })
    return this.props.closeDateModal()
  }

  render() {
    return (
      <>
        {this.props.modalState &&

          <Overlay>

            <ModalSent id={'modalSent'}>
              <ModalDate>
                <Divider>
                  <TextoHide>Búsqueda avanzada</TextoHide>
                  <Texto>Mostrar</Texto>
                  <CloseButton onClick={this.validClose}>
                    <Close />
                  </CloseButton>
                </Divider>
                <TextoHide2>Mostrar</TextoHide2>
                <div >
                  <FormFilter
                    state={this.state}
                    handleOnChange={this.handleOnChange}
                    handleSubmit={this.handleSubmit}
                    toggleCheckbox={this.toggleCheckbox}
                    tabFilter={this.props.tabFilter}
                  />
                  <Texto2>Período de tiempo</Texto2>

                </div>
                <DateFilter setDateState={this.setDateState} />
              </ModalDate>
              <Divider2>
                <ButtonDeleteFilter onClick={this.handleDeleteFilter}>
                  Borrar filtros
                </ButtonDeleteFilter>
                <ButtonStartFilter onClick={this.handleSubmit}>
                  Aplicar filtro
                </ButtonStartFilter>
              </Divider2>
            </ModalSent>
          </Overlay>
        }
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  dateModal: state.modalReducer.dateModal,
  stateSearch: state.filterReducer.searchAdvanced.notificationState,
  dateSearch: state.filterReducer.searchAdvanced.date,
  tabFilter: state.filterReducer.tabFilter,
  modalState: state.modalReducer.dateModal
})

const mapDispatchToProps = {
  actionSearchAdvanced,
  openDateModal,
  closeDateModal
}
export default connect(mapStateToProps, mapDispatchToProps)(ModalFilterDate) 
