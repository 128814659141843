import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import GeneralTable from '../../../../components/Table/GeneralTable';

import {actionClearFilter,actionInitialPagination, activePagination } from '../../../../actions/filterDetailActions'
import {getLogsList} from "../../../../actions/clientActions";
import _ from 'lodash'
import RowTableLogs from './RowTableLogs';



class Logs extends Component {

  state = {
    loader: true,
    init: true,
  };
  
  componentDidMount() {
    if(this.props.filterReducer.tabFilter !== 'Logs'){
      this.setState({init:false})
      this.props.actionClearFilter()
    }else{
      this.makeLogsRequest(this.props.filterReducer);
    }
  };

  makeLogsRequest =  (filterReducer) => {
      const body={
      companySerialID: this.props.clientDatail.companySerialID,
      pag: filterReducer.pag,
      count: !!filterReducer.count ? filterReducer.count : 20,
      order: this.orderTranslator(filterReducer.sort),
      userName: filterReducer.searchBar[0],
      action:filterReducer.logsFilter.action
    }
      this.props.getLogsList(body).then(() => {
        this.props.activePagination(false)
        this.setState({init: false, loader: false })
      }) 
  };

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.filterReducer, this.props.filterReducer) || (prevProps.clientDatail !== this.props.clientDatail && !this.state.init)) {
      
      this.makeLogsRequest(this.props.filterReducer); 
      this.setState({ loader: true, init: true });
    }
  }


  orderTranslator = (order) => {
    const options = {
      'masReciente':  -1,
      'masAntiguo':  1,
      
    }
    return options[order]
  };

  
  columnData =[
    { name: "Acción", width: 2, arrow: false },
    { name: "Usuario", width: 2, arrow: false },
    { name: "Fecha y hora", width: 2, arrow: false },
  ]

  render() {
    return (
      <GeneralTable
        list={this.props.logsList}
        typeList="logs"
        headerTable={this.columnData}
        rowComponent={RowTableLogs}
        history={this.props.history}
        loader={this.state.loader}
        heigthHeader ={'45px'}
        noWrap={false}
      />
    )
  }
}

Logs.propTypes = {
  
}

const mapStateToProps = (state) => ({
  filterReducer: state.filterDetailReducer,
  clientDatail: state.providerReducer.detailProvider,
  logsList: state.clientReducer.logsList,
  userRole: state.authReducer.user.roleName,
});

const mapDispatchToProps = {
  actionClearFilter,
  getLogsList,
  actionInitialPagination,
  activePagination
};

export default connect(mapStateToProps, mapDispatchToProps)(Logs)