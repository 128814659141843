import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Table, Rating } from 'semantic-ui-react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { connect, useSelector } from 'react-redux'

import Label from '../../../../../components/Label/MainLabelMale'
import StarredButton from '../../../../../components/Starred/StarredButton'
import { ReactComponent as IconAttach } from '../../../../../assets/icons/userDocuments/Attachment-icon.svg'
import { viewPDF, getPDF } from '../../../../../actions/seeAttachmentAction'
import { changeMessageModal } from '../../../../../actions/modalActions'
import { useDispatch } from 'react-redux'
import { exportDocumentsToPDF, userDocumentStarred } from '../../../../../actions/userDocumentsActions'
import { CheckBoxGeneric } from '../../../../../components/CheckboxForm/CheckBoxGeneric'
import { ReactComponent as Download } from '../../../../../assets/icons/download-attachment.svg'

const SentCell = styled(Table.Cell)`
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  max-width: 1px !important;
  padding: 10px !important;
`

const Row = styled(Table.Row)`
  cursor: pointer !important;
  font-size: 15px;
  @media only screen and (min-width: 100px) and (max-width: 768px) {
    display: flex !important;
    flex-direction: column;
    border-bottom: 1px solid #979E9F !important;
    padding: 0.5em 0.5em 0.5em 0em!important;
    box-sizing: content-box;
  }
`
const SentCellResponsive = styled(Table.Cell)`
  width: 100%;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  display block;
  border-top: 0px solid rgba(34,36,38,.1) !important;
  padding: 0.25em 0em!important;
  @media only screen and (max-width: 1024px) {
    .ui.basic.label{
      min-width: 18px;
      padding: 0px 0.5em !important;
      height: 24px !important;
    }
  }
  @media only screen and (max-width: 768px) {
    .ui.basic.label{
      min-height: 24px !important;
      padding-bottom: 0px !important;
      height: 24px !important;
      max-height: 17px;
      line-height: 24px;
    }
  }
`
const StatusContainer = styled.div`
  float: left!important;
  
`

const IconAttachment = styled(IconAttach)`
  margin-right: 12px;
`

const HideText = styled.div`
  display: flex;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`
const Divider = styled.div`
display: flex;
flex-direction: column;
`
const DividerIconsResponsive = styled.div`
  margin: -2px 0px 0 0;
  `


const Divider0 = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  `

const Divider1 = styled.div`
  display: flex;
  flex-direction: column;
  padding-inline:2%;
  width: 5%;
  `
const Divider2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  `

const Divider3 = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  justify-content: space-between;
  `

const Divider4 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  `
const DeleteButton = styled.div`
  display: flex;
  cursor: pointer;
  font-size: 14px;
  color: #22C1D0;
  justify-content: flex-end;
`;

const StarredIcon = styled(Rating)`
  
`

function getWindowDimensions() {
  const { innerWidth: width } = window;
  return {
    width
  };
}

function useWindowDimensions() {
  const [windowDimensions = 0, setWindowDimensions] = useState(getWindowDimensions);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [windowDimensions]);

  return windowDimensions;
}

const RowTableAttachmentDetail = (props) => {

  const [stateLocalStarred, setStateLocalStarred] = useState(props.data.starred)
  const filterReducer = useSelector(state => state.filterDetailReducer)
  const orderTranslator = (order) => {
    const options = {
      'masReciente': { "_id": -1 },
      'masAntiguo': { "_id": 1 },
      'A-Z': { "fileOriginalName": 1 },
      'Z-A': { "fileOriginalName": -1 }
    }
    return options[order]
  }


  const bodyPagination = {
    count: filterReducer.count,
    pag: filterReducer.pag,
    limit: 20,
    sort: orderTranslator(filterReducer.sort),
    status: filterReducer.searchAdvanced.notificationState,
    initDate: !!filterReducer.searchAdvanced.date.startDate
      ? moment(filterReducer.searchAdvanced.date.startDate).format('YYYY-MM-DD') : '',
    endDate: !!filterReducer.searchAdvanced.date.endDate
      ? moment(filterReducer.searchAdvanced.date.endDate).format('YYYY-MM-DD') : '',
    starred: filterReducer.starred,
    attachmentsName: filterReducer.searchBar[0],
    to: props.to,
  }

  const validateUrl = (url) => {
    if (url === undefined) {
      props.changeMessageModal({
        type: 'errorDownloadReport'
      })

      return false
    } else {
      return true
    }
  }

  const viewPdf = () => {
    if (validateUrl(props.data.fileURL) === true) {
      props.viewPDF({ "urlPDF": props.data.fileURL, "viewPDF": true, "documentName": props.data.fileName })
      props.getPDF({
        fileURL: props.data.fileUrlEncrypted,
        fileName: props.data.fileName
      })
    } else {

    }
  }

  const dispatch = useDispatch()
  const hadleStarred = () => {
    setStateLocalStarred(!stateLocalStarred)
    const body = { "attachmentSerialID": props.data.documentId }
    dispatch(userDocumentStarred(body))
  }

  const [selectDocument, setSelectDocument] = useState(false)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    setSelectDocument(props.activeAll)
  }, [props.activeAll])

  useEffect(() => {
    setSelectDocument(false)
    setStateLocalStarred(props.data.starred)
  }, [props.data])

  const handleSelectDocument = (e) => {
    e.stopPropagation()
    setSelectDocument(!selectDocument)
    if (!selectDocument) {
      props.addCheckedRow(props.data.documentId)
    } else {
      props.removeCheckedRow(props.data.documentId)
    }
  }

  const handleClickDownload = () => {
    dispatch(exportDocumentsToPDF({ attachmentSerialIDList: [props.data.documentId] }))
  }

  const formatDate = (userDocumentDate) => moment(userDocumentDate).format('DD/MM/YYYY')

  return (
    <>
      {useWindowDimensions().width > 768 ?
        <Row onMouseEnter={() => setIsVisible(true)} onMouseLeave={() => setIsVisible(false)}>
          <SentCell textAlign='left' width={1}>
            <CheckBoxGeneric onChange={handleSelectDocument} checked={selectDocument} />
          </SentCell>

          <SentCell textAlign='left' width={1} onClick={viewPdf}>
            <StarredButton
              onClick={hadleStarred}
              active={stateLocalStarred}
            />
          </SentCell>
          <SentCell width={5} onClick={viewPdf}>
            <IconAttachment />
            {props.data.fileName}
          </SentCell>
          <SentCell width={2} onClick={viewPdf}>
            {(formatDate(props.data.sendDate) === "Invalid date") ? "-- /-- /--" : formatDate(props.data.sendDate)}
          </SentCell>
          <SentCell width={3} onClick={viewPdf}>
            {props.data.reqSignature && 'Con solicitud de firma'}
          </SentCell>
          <SentCell width={2} textAlign='center' onClick={viewPdf}>
            <StatusContainer>
              <Label
                text={props.data.currentStatus.name}
                icon={props.data.currentStatus.id}
                color={props.data.currentStatus.color}
              />
            </StatusContainer>
          </SentCell>
          <SentCell width={5} textAlign='center' >
            {
              (isVisible && !selectDocument) && <div style={{ display: 'flex', color: '#22C1D0' }}>
                <Download style={{ cursor: 'pointer', margin: '2px 6px 0px 0px', minWidth: '20px' }} onClick={handleClickDownload} />
                <div style={{ overflowWrap: 'break-word', cursor: 'pointer' }}
                  onClick={handleClickDownload}>Descargar</div>
              </div>
            }
          </SentCell>
        </Row>
        :
        <Row onMouseEnter={() => setIsVisible(true)} onMouseLeave={() => setIsVisible(false)}>
          <Divider0>
            <Divider1>
              <CheckBoxGeneric onChange={handleSelectDocument} checked={selectDocument} />
            </Divider1>
            <Divider2 >
              <div style={{ justifyContent: 'space-between', display: 'flex', fontWeight: '400' }}>
                <Divider style={{ width: '55vw' }} onClick={viewPdf}>
                  <div style={{ display: 'flex' }}>
                    <DividerIconsResponsive>
                      <IconAttachment />
                    </DividerIconsResponsive>
                    <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                      {props.data.fileName}
                    </div>

                  </div>
                  <div style={{ width: '75px' }}>

                    <Label text={props.data.currentStatus.name} icon={props.data.currentStatus.id}
                      color={props.data.currentStatus.color} />
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <StarredButton active={stateLocalStarred} onClick={hadleStarred} />
                    </div>
                  </div>
                </Divider>

              </div>
            </Divider2>

            <Divider3 >
              <Divider4 onClick={viewPdf}>
                <div style={{ color: '#7C7C74', fontWeight: '400' }}>
                  <HideText>
                    Fecha envío:

                  </HideText>
                  {formatDate(props.data.notificationDate)}
                </div>
                <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                  {props.data.reqSignature && 'Con solicitud de firma'}
                </div>

              </Divider4>
              {
                (isVisible && !selectDocument) && <div style={{ display: 'flex', color: '#22C1D0' }}>
                  <Download style={{ cursor: 'pointer', margin: '2px 6px 0px 0px', minWidth: '20px' }} onClick={handleClickDownload} />
                  <div style={{ cursor: 'pointer' }} onClick={handleClickDownload}>Descargar</div>
                </div>
              }
            </Divider3>
          </Divider0>
        </Row>}
    </>
  )
};

RowTableAttachmentDetail.propTypes = {
  data: PropTypes.object.isRequired,
  viewPDF: PropTypes.func,
  changeMessageModal: PropTypes.func,
};

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
  viewPDF,
  changeMessageModal,
  getPDF
}

export default connect(mapStateToProps, mapDispatchToProps)(RowTableAttachmentDetail)
