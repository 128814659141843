import React from "react"
import PropTypes from "prop-types"
import { Form } from "semantic-ui-react"

import './input-from-material.css'

const InputFormMaterial = ({ ...props }) => {
  const { placeholder, type, id, onChange, value, label, disabled, validator, validateOptions, onBlur, error, hidden, min, width, required, autoComplete } = props

  let errorValidator = validator ? validator.message(label !== '' && label !== undefined ? label : placeholder, value, validateOptions) : ''
  if (!!hidden) return null
  return (
    <Form.Field className={`ui-material ${errorValidator || error ? 'input-error ' : ''}form ${disabled ? 'disabled' : ''}`} width={width && width}>
      <div className="field">
        <input
          id={id}
          type={type}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          min={min}
          autoComplete={autoComplete}
          required={!!required}
        />
        <label htmlFor={id}>
          <p className="labelName">{label}</p>
          <p style={{marginTop: '10px'}} className="labelError">{!disabled && ((error || errorValidator) && ` ${error || errorValidator.props.children}`)}</p>
        </label>
      </div>
    </Form.Field>
  )
}


InputFormMaterial.propTypes = {
  labelProps: PropTypes.object,
  id: PropTypes.string,
}
export default InputFormMaterial
