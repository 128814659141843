import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'

import { actionClearFilter, actionInitialPagination, activePagination } from '../../../actions/filterActions'
import GeneralTable from '../../../components/Table/GeneralTable'
import { draftsListV2, updateList, removeFromList, deleteAllFromList } from "../../../actions/draftsActions";
import RowTableDrafts from './RowTableDrafts'

class AllDrafts extends Component {

    state = {
        loader: true,
        init: true,
    }

    componentDidMount() {
        this.makeDraftsRequest(this.props.filterReducer)
    };

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(prevProps.filterReducer, this.props.filterReducer) && !this.state.init) {

            this.makeDraftsRequest(this.props.filterReducer)
            this.setState({init: true, loader: true })  
        }      
    };

    makeDraftsRequest = (filterReducer) => {
        this.props.deleteAllFromList()
        var body = {
            count: filterReducer.count,
            pag: filterReducer.pag === 0 ? 1 : filterReducer.pag,
            status: "",
            sort: this.orderTranslator(filterReducer.sort),
            textToSearch: filterReducer.searchBar[0],
        }
        if (filterReducer.draftsFilter.draftsStatus == 'ADJUNTOS') {
            body = Object.assign({ haveAttachments: true }, body)
        } else if (filterReducer.draftsFilter.draftsStatus == 'NOADJUNTOS') {
            body = Object.assign({ haveAttachments: false }, body)
        }else if(filterReducer.draftsFilter.draftsStatus == 'MASIVO'){
            body = Object.assign({ massiveDeliveryType : true }, body)
        }
        this.props.draftsListV2(body).then(() => {
            this.props.activePagination(false)
            this.setState({init:false, loader: false})
        })
    }

    orderTranslator = (order) => {
        const options = {
            'masReciente': { "_id": -1 },
            'masAntiguo': { "_id": 1 },
            'A-Z': { "recipients": 1 },
            'Z-A': { "recipients": -1 }
        }
        return options[order]
    };

    addCheckedRow = (id) => {
        this.props.updateList(id)
    }

    removeCheckedRow = (id) => {
        this.props.removeFromList(id)
    }

    render() {
        return (
            <GeneralTable
                list={this.props.draftsListArray}
                typeList={this.props.filterReducer.isSearch === 0 ? 'default' : 'drafts'}
                listHeight={'drafts'}
                headerTable={[]}
                rowComponent={RowTableDrafts}
                addCheckedRow={this.addCheckedRow}
                removeCheckedRow={this.removeCheckedRow}
                activeAll={this.props.activeAll}
                history={this.props.history}
                loader={this.state.loader} />
        )
    }
}

AllDrafts.propTypes = {
    draftsListV2: PropTypes.func.isRequired,
    draftsListArray: PropTypes.array.isRequired,
    history: PropTypes.any.isRequired,
    actionClearFilter: PropTypes.func,
    actionInitialPagination: PropTypes.func,
    elementsCount: PropTypes.any,
    filterReducer: PropTypes.object.isRequired,
    updateList: PropTypes.func
}

const mapStateToProps = (state) => ({
    draftsListArray: state.draftReducer.draftListArray,
    elementsCount: state.draftReducer.elementsCount,
    filterReducer: state.filterReducer,
    listToDelete: state.draftReducer.listToDelete
});

const mapDispatchToProps = {
    updateList,
    draftsListV2,
    deleteAllFromList,
    actionClearFilter,
    actionInitialPagination,
    removeFromList,
    activePagination,
};

export default connect(mapStateToProps, mapDispatchToProps)(AllDrafts)