import React, { Component } from 'react'
import { Modal } from 'semantic-ui-react'
import styled from 'styled-components'
import { connect } from 'react-redux'

import { openDateModal, closeDateModal } from '../../../../actions/modalActions'
import FormFilterAttachment from './FormFilterAttachment/FormFilterAttachment'
import DateFilterAttachment from './DateFilterAttachment/DateFilterAttachment'
import { actionSearchAdvanced } from '../../../../actions/filterActions'
import { ReactComponent as Close } from '../../../../assets/icons/close.svg'

const Divider = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 16px;
  margin-right: 14px;
`
const Texto = styled.p`
  color: #000000;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  padding-top: 10px
`
const Divider2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
`

const ButtonDeleteFilter = styled.button`
  color: #22C1D0;
  border: none;
  background: #FFFFFF;
  text-decoration: underline;
  padding-right: 64px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
`
const ButtonStartFilter = styled.button`
  color: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #22C1D0;
  background: #22C1D0;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  height: 48px;
  width: 193px;
  cursor: pointer;
`
const ModalDate = styled(Modal)`
  width: 854px;
`
const ModalSent = styled(Modal)`
  width: 80% !important;
  max-width: 854px;
  max-height: 633px;
  min-height: 575px;
  height: 80%; 
  @media only screen and (max-width: 768px) {
    width: 100% !important;
    max-height: unset;
    height: 100%;
    margin: -16px 0 0 0 !important;
  }
`
const Texto2 = styled.p`
  color: #000000;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  padding: 10px 0 0 0;
  @media only screen and (max-width: 1024px) {
    display: none;
  }
`

class ModalFilterDate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      attachedFile: false,
      notificationState: '',
      date: {}
    };
  }


  componentDidUpdate(prevProps, prevState) {
    if (prevProps.stateSearch !== this.props.stateSearch) {
      this.setState({ notificationState: this.props.stateSearch })
    }
  }

  handleOnChange = (e, { value }) => {
    this.setState({
      notificationState: value
    }, () => {})
  }

  toggleCheckbox = (e) => {
    let name = e.target.id;
    this.setState({
      [name]: !this.state[name]
    })
  }

  reset = () => {
    this.setState({
      attachedFile: false,
      notificationState: '',
      date: {}
    })
  }

  setDateState = (date) => {
    this.setState({ date: date })
  }

  orderTranslator = (order) => {
    const options = {
      'masReciente': { notificationDate: -1 },
      'masAntiguo': { notificationDate: 1 },
      'A-Z': { notificationDate: -1 },
      'Z-A': { notificationDate: 1 }
    }
    return options[order]
  }

  makeBodyRequest = () => {
    const body = {
      pag: 1,
      sort: 'masReciente',
      starred: false,
      searchAdvanced: {
        date: this.state.date,
        notificationState: this.state.notificationState,
        attachedFile: this.state.attachedFile
      }
    }
    this.props.actionSearchAdvanced(body)
    this.props.closeDateModal()
  }

  makeBodyRequestDeleteFilter = () => {
    const body = {
      pag: 1,
      sort: 'masReciente',
      starred: false,
      searchAdvanced: {
        date: {
          startDate: "",
          endDate: ""
        },
        notificationState: '',
        attachedFile: false
      }
    }
    this.props.actionSearchAdvanced(body)
    this.props.closeDateModal()
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.makeBodyRequest()
  }

  handleDeleteFilter = (e) => {
    e.preventDefault()
    this.makeBodyRequestDeleteFilter()
    this.reset()
  }

  validClose = () => {
    this.setState({
      notificationState: this.props.stateSearch
    })
    return this.props.closeDateModal()
  }


  render() {
    return (
      <ModalSent
        onClose={this.validClose}
        open={this.props.dateModal}
        id={'modalSent'}
      >
        <ModalDate.Description style={{ margin: "15px" }}>
          <Divider>
            <Texto>Mostrar</Texto>
            <Close onClick={this.validClose} />
          </Divider>
          <div style={{ marginLeft: "15px" }}>
            <FormFilterAttachment
              state={this.state}
              handleOnChange={this.handleOnChange}
              handleSubmit={this.handleSubmit}
              toggleCheckbox={this.toggleCheckbox}
            />
            <Texto2>Período de tiempo</Texto2>
          </div>
          <DateFilterAttachment
            setDateState={this.setDateState}
          />
        </ModalDate.Description>
        <Divider2>
          <ButtonDeleteFilter
            onClick={this.handleDeleteFilter}
          >
            Borrar filtros
          </ButtonDeleteFilter>
          <ButtonStartFilter
            onClick={this.handleSubmit}
          >
            Aplicar filtro
          </ButtonStartFilter>
        </Divider2>
      </ModalSent>
    )
  }
}

const mapStateToProps = (state) => ({
  dateModal: state.modalReducer.dateModal,
  stateSearch: state.filterReducer.searchAdvanced.notificationState,
  dateSearch: state.filterReducer.searchAdvanced.date
})

const mapDispatchToProps = {
  actionSearchAdvanced,
  openDateModal,
  closeDateModal
}
export default connect(mapStateToProps, mapDispatchToProps)(ModalFilterDate) 
