import React from 'react'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import _, { stubTrue, truncate } from 'lodash'

import { actionPagination, activePagination } from '../../actions/filterActions'
import { ReactComponent as RightArrowIcon } from '../../assets/icons/right-arrow.svg'
import { ReactComponent as LeftArrowIcon } from '../../assets/icons/left-arrow.svg'

const Divider = styled.div`
  display: flex;
  align-items: center;
  padding-top: 10px;
  gap: 19px;

  @media only screen and (max-width: 768px) {
    gap: 9px;
  }
`
const Text = styled.p`
  width: max-content;
  color: #7C7C74;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 0.5rem !important;
  @media only screen and (max-width: 768px) {
    font-size: 15px;
  }
`
const DividerIcon = styled.div`
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  @media only screen and (min-width: 1024px) and (max-width: 1440px) {
    display: flex
  } 
  @media only screen and (max-width: 768px) {
    gap: 5px;
  }
`
const LeftArrow = styled.button`
  padding: 0;
  margin: 0 5px 0 5px;
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor: pointer;
  ${props => !!props.isDiabledLeft && css`
  opacity: 0.3;
  cursor: default;
  `}

`
const RightArrow = styled.button`
  padding: 0;
  margin: 0 0 0 0;
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor: pointer;
  ${props => !!props.isDiabledRight && css`
  opacity: 0.3;
  cursor: default;
  `}
`

class Pagination extends React.Component {
  state = {
    first: ((this.props.filterReducer.count * this.props.pag) - this.props.filterReducer.count) + 1 ,
    last: this.props.filterReducer.count * this.props.pag,
    pag: this.props.pag,
    totalCount: this.props.filterReducer.count,
    total: 0,
    disabledLeft: false,
    disabledRight: false,
  }

  componentDidMount() {
    this.setState({ total: this.props.filterReducer.total, pag: this.props.filterReducer.pag, first: ((this.props.filterReducer.count * this.props.filterReducer.pag) - this.props.filterReducer.count) + 1, last: ((this.props.filterReducer.count * this.props.filterReducer.pag) < this.props.filterReducer.total) ? (this.props.filterReducer.count * this.props.filterReducer.pag) : this.props.filterReducer.total })
    this.props.actionPagination(this.state.pag)
    this.validateDisableArrow()
  }


  componentDidUpdate(prevProps, prevState) {
    let refreshPag = this.props.filterReducer.pag
    let refreshFirst = this.state.first
    let refreshLast = this.props.filterReducer.count
    let refreshTotal = this.state.total
    let refreshTotalCount = this.props.filterReducer.count
    if (this.state.pag !== prevState.pag) {
      this.props.actionPagination(this.state.pag)
      this.validateDisableArrow()
    } else {
      if (this.state.pag !== 1 && this.props.pag === 1) {
        refreshPag = 1
        refreshFirst = 1
        refreshLast = this.props.filterReducer.count * this.state.pag
      }
    }
    
    if (!_.isEqual(prevProps.filterReducer.total, this.props.filterReducer.total)) {
      refreshPag= this.props.filterReducer.pag <= 0 ?  1 : this.props.filterReducer.pag
      refreshTotal = this.props.filterReducer.total
      refreshLast= this.props.filterReducer.count * refreshPag
      refreshFirst= ((this.props.filterReducer.count * refreshPag) - this.props.filterReducer.count) + 1
      this.setState({ total: refreshTotal, totalCount: refreshTotalCount, pag: refreshPag, first: refreshFirst, 
        last: (refreshTotal >= refreshLast ? (refreshLast === 0 ? refreshLast: refreshLast) : refreshTotal) })
      this.validateDisableArrow()
    }

  }

  handleClickIncrement = () => {
    if (this.state.last < this.state.total) {
      this.setState((prevState) => ({
        first: prevState.first + prevState.totalCount,
        last: (prevState.last + prevState.totalCount) > this.state.total ? this.state.total : (prevState.last + prevState.totalCount),
        pag: this.state.pag + 1,
      }))
      this.props.activePagination(true)
    }
  }

  handleClickDecrement = () => {
    if (this.state.last > this.state.totalCount) {
      this.setState((prevState) => ({
        first: prevState.first - prevState.totalCount,
        last: prevState.first - 1,
        pag: this.state.pag - 1,
      }))
      this.props.activePagination(true)
    }
  }

  validateDisableArrow = () => {
    const validateDisabled=  (this.props.filterReducer.total - (this.props.filterReducer.count * this.state.pag))
    if(this.props.filterReducer.total > 0){
      if(this.state.pag > 1){
        if(validateDisabled < 1){
          this.setState({disabledLeft: false, disabledRight: true})
        }else{
          this.setState({disabledLeft: false, disabledRight: false })
        }
      }else{
        if(validateDisabled < 1){
          this.setState({disabledRight: true })
        }else{
          this.setState({disabledRight: false})
        }
        this.setState({disabledLeft: true})
      }
    }else{
      this.setState({disabledLeft: true, disabledRight: true })
    }
  }

  render(){
    return (
      <Divider>
          {
              this.state.total === undefined || this.state.total === 0
              ? 
              <Text> 0 - 0 de 0 </Text>
              : 
              <Text>{this.state.first} - {this.state.last} de {this.state.total}</Text>
          }
        <DividerIcon>
          <LeftArrow onClick={this.handleClickDecrement} disabled={this.props.activePag} isDiabledLeft={this.state.disabledLeft}>
            <LeftArrowIcon />
          </LeftArrow>
          <RightArrow onClick={this.handleClickIncrement}  disabled={this.props.activePag} isDiabledRight={this.state.disabledRight}>
            <RightArrowIcon />
          </RightArrow>
        </DividerIcon>
      </Divider>
    )
  }
}

const mapStateToProps = (state) => ({
  activePag: state.menuReducer.activePagination,
  filterReducer: state.filterReducer,
  pag: state.filterReducer.pag,
  notifications: state.sentReducer.notifications,
  clients: state.clientReducer.clients,
})

const mapDispatchToProps = {
  actionPagination,
  activePagination,
}

export default connect(mapStateToProps, mapDispatchToProps)(Pagination)
