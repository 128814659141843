import React, { useState } from 'react';
import { Table } from 'semantic-ui-react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ReactComponent as EditImage } from '../../../../assets/icons/clients/edit-button.svg';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ReactComponent as EnabledButton } from '../../../../assets/icons/clients/enabled-button.svg';
import { ReactComponent as Inactive } from '../../../../assets/icons/users/inactive.svg';
import { getEmployeeEdit, } from '../../../../actions/clientActions';
import { getUserEdit} from '../../../../actions/usersActions'
import { refreshStateModule, warningFormModal } from '../../../../actions/modalActions'
import { Cell, CellOptions } from '../../../../components/Table/CellComponent';
import { useEffect } from 'react';

const Row = styled(Table.Row)`
  height: 50px !important;
  @media only screen and (max-width: 768px) {
    height: auto !important;
    display: flex !important;
    flex-wrap: wrap;
    border-bottom: 1px solid #979e9f !important;
    padding: 0.5em 0em !important;
    
  }
`;

function getWindowDimensions() {
  const { innerWidth: width } = window;
  return {
    width
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export const RowTableUsers = (props) => {
  const [isVisible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleChangeActive = () => {
    sessionStorage.setItem("userSerialId", props.data.id)
    const idUser = sessionStorage.getItem("userSerialId") 
    dispatch(getUserEdit({"idUser":idUser}))
    dispatch(warningFormModal({ type: 'warningUpdateUserActiveProviderAndClient' }))
  }

  const handleChangeInactive = () => {
    sessionStorage.setItem("userSerialId", props.data.id)
    const idUser = sessionStorage.getItem("userSerialId")
    dispatch(getUserEdit({"idUser":idUser}))
    dispatch(warningFormModal({ type: 'warningUpdateUserInactiveProviderAndClient' }))
  }

  const handleClick = (e) => {
    e.stopPropagation();
    dispatch(refreshStateModule())
    sessionStorage.setItem('userSerialId', props.data.id);
    dispatch(getEmployeeEdit(props.data.id));
    history.push('/editEmployee');
  };

  return (
    <Row
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      <Cell columnName={props.headerTable[0].name} width={3}  text={props.data.names + ' '+ props.data.surnames}  ></Cell>
      <Cell width={3} columnName={props.headerTable[1].name} text={props.data.document} >  </Cell>
      <Cell width={4} columnName={props.headerTable[2].name} text={props.data.email}></Cell>
      <Cell width={2} columnName={props.headerTable[3].name} text = {props.data.role}></Cell>
      <CellOptions isVisible={isVisible} columnName={props.headerTable[4].name} clickEdit={handleClick}
       active={props.data.status} handleChangeActive={handleChangeActive} handleChangeInactive={handleChangeInactive}/>
    </Row>
  );
};

RowTableUsers.propTypes = {
  data: PropTypes.object.isRequired,
};

export default RowTableUsers;
