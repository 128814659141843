import {
  STATE_FILTER_SEARCH_BAR,
  STATE_FILTER_POPUP_FILTER,
  STATE_FILTER_POPUP_ORDER,
  STATE_FILTER_PAGINACION,
  STATE_FILTER_ADVANCED_SEARCH_DATE,
  STATE_FILTER_ADVANCED_SEARCH,
  STATE_DELETE_FILTER,
  STATE_STARRED_FILTER,
  STATE_TAB_FILTER,
  STATE_CLEAR_FILTER,
  STATE_INITIAL_PAGINATION,
  ACTIVE_TABLE_MIX,
  INACTIVE_TABLE_MIX, STATE_FILTER_POPUP_FILTER_AGREEMENTS,
  STATE_FILTER_POPUP_FILTER_IMPROPER_CLIENTS, 
  STATE_FILTER_POPUP_FILTER_DRAFTS, 
  STATE_FILTER_POPUP_FILTER_REPORTS,
  STATE_FILTER_POPUP_FILTER_CLIENTS_AND_PROVIDERS,
  VIEW_PAGINATION,
  STATE_FILTER_MASSIVE_DETAIL,
  STATE_FILTER_REQUIRED_SIGNATURE,
} from "../actions/actionsUtilities/types.js";

const initialState = {
  isSearch: false,
  searchBar: '',
  sort: 'masReciente',
  count: 20,
  pag: 1,
  total: 0,
  requiredType: {
    key: '',
    value: ''
  },
  starred: false,
  searchAdvanced: {
    date: {
      startDate: '',
      endDate: '',
      key: ''
    },
    attachedFile: false,
    notificationState: '',
    typeSearch: '',
    reportType:'',
    requiredSignature:''
  },
  viewTableMix: false,
  tabFilter: 'all',
  agreementFilter: {
    agreementStatus: ''
  },
  improperClientsFilter: {
    filterOptions: ''
  },
  draftsFilter: {
    draftsStatus: ''
  },
  reportsFilter: {
    reportStatus: ''
  },
  clientsAndProvidersFilter: {
    clientAndProviderStatus: ''
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case STATE_FILTER_SEARCH_BAR:
      return {
        ...state,
        pag: initialState.pag,
        isSearch: !action.cancelFlag,
        searchBar: action.payload
      }
    case STATE_FILTER_POPUP_FILTER:
      return {
        ...state,
        pag: initialState.pag,
        searchAdvanced: {
          ...initialState.searchAdvanced,
          notificationState: action.payload,
        },
        starred: initialState.starred
      }
    case STATE_FILTER_POPUP_ORDER:
      return {
        ...state,
        sort: action.payload,
      }
    case STATE_FILTER_PAGINACION:
      return {
        ...state,
        pag: action.payload,
      }
    case STATE_FILTER_ADVANCED_SEARCH_DATE:
      return {
        ...state,
        searchAdvanced: {
          ...state.searchAdvanced,
          date: action.payload,
        }
      }
    case STATE_FILTER_ADVANCED_SEARCH:
      return {
        ...state,
        ...action.payload
      }
    case STATE_DELETE_FILTER:
      return {
        ...state,
        searchAdvanced: action.payload
      }
    case STATE_STARRED_FILTER:
      return {
        ...state,
        pag: initialState.pag,
        starred: action.payload,
        searchAdvanced: {
          ...state.searchAdvanced,
          notificationState: initialState.searchAdvanced.notificationState
        }
      }
    case STATE_TAB_FILTER:
      return {
        ...initialState,
        tabFilter: action.payload,
        stateInitial:false,
      }
    case STATE_CLEAR_FILTER:
      return {
        ...initialState
      }
    case STATE_INITIAL_PAGINATION:
      return {
        ...state,
        total: action.payload
      }
    case ACTIVE_TABLE_MIX:
      return {
        ...state,
        viewTableMix: true
      }
    case INACTIVE_TABLE_MIX:
      return {
        ...state,
        viewTableMix: false
      }
    case STATE_FILTER_POPUP_FILTER_AGREEMENTS:
      return {
        ...state,
        pag: initialState.pag,
        agreementFilter: {
          ...state.agreementFilter,
          agreementStatus: action.payload
        }
      }
    case STATE_FILTER_POPUP_FILTER_IMPROPER_CLIENTS:
      return {
        ...state,
        count: 15,
        pag:1,
        total: 15,
        improperClientsFilter: {
          ...state.improperClientsFilter,
          filterOptions: action.payload
        }
        }
    case STATE_FILTER_POPUP_FILTER_DRAFTS:
      return {
        ...state,
        pag: initialState.pag,
        draftsFilter: {
          ...state.draftsFilter,
          draftsStatus: action.payload
        }
      }
    case STATE_FILTER_POPUP_FILTER_REPORTS:
      return {
        ...state,
        pag: initialState.pag,
        searchAdvanced: {
          ...initialState.searchAdvanced,
          reportType: action.payload,
        },
        reportsFilter: {
          ...state.reportsFilter,
          reportStatus: action.payload
        }
      }
    case STATE_FILTER_POPUP_FILTER_CLIENTS_AND_PROVIDERS:
      return {
        ...state,
        pag: initialState.pag,
        clientsAndProvidersFilter: {
          ...state.clientsAndProvidersFilter,
          clientAndProviderStatus: action.payload
        }
      }
      case VIEW_PAGINATION:
        return {
          ...state,
          total: action.payload.total,
        }
    case STATE_FILTER_MASSIVE_DETAIL:
        return {
          ...state,
          count: 10,
          pag:1,
          total: 0
        }
    default:
      return state
  }
}
