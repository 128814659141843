import axios from 'axios'
import { BLOCK_CHANGE_TAP, GET_NOTIFICATIONS, VIEW_PAGINATION } from './actionsUtilities/types.js'
import {API_ENDPOINT, API_ENDPOINT_NOTIFICATION_DEV} from "../config/config"
import { GET_LIST_NOTIFICATIONS, GET_LIST_NOTIFICATIONSV2 } from './actionsUtilities/actionsRoutes.js'
import { reasonDispatch } from './DispatchGeneric.js'
import { decrypt, encrypt } from '../helpers/cypher.js'
import { getHeaders } from '../helpers/globalHelpers.js'

export const getNotificationsv2 = (body) => async (dispatch) => {
  const encryptedBody = await encrypt(body);
  dispatch(blockChangeTapAction(true))
  await axios.post(
    `${API_ENDPOINT+GET_LIST_NOTIFICATIONSV2}`,
    {data: encryptedBody},
    { headers: getHeaders() }
  ).then(async res => {
    const decryptedData = await decrypt(res.data.result);
    
    dispatch({
      type: GET_NOTIFICATIONS,
      payload: { notifications: decryptedData.notifications, elementsCount: decryptedData.elementsCount }
    }) 
    dispatch({
      type: VIEW_PAGINATION,
      payload: {
        total: decryptedData.elementsCount,
      }
    })
    dispatch(blockChangeTapAction(false))
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
  })
}

export const blockChangeTapAction = (activate) => (dispatch) => {
  dispatch({
    type: BLOCK_CHANGE_TAP,
    payload: activate
  })
}
