import React from 'react';
import Pagination from './Paginacion';
import PaginationDetail from './PaginationDetail';
import SearchBar from './SearchBar';
import SearchBarDetail from './SearchBarDetail';
import styled, { css } from "styled-components"
import PopUpOrder from './PopUpOrderGeneric';
import PopUpOrderDetail from './PopUpOrderDetail';
import { CreateButton } from './CreateButton';
import { ExportButton } from './ExportButton';
import PopUpFilterAgreements from '../Search/SearchAgreements/PopUpFilterAgreements';
import PopUpFilterDetailAgreements from '../Search/SearchAgreements/PopUpFilterDetailAgreements';
import { ButtonTypeGrid } from './ButtonTypeGrid';
import { ButtonTypeList } from './ButtonTypeList';
import PopUpFilter from './PopUpFilter';
import PopUpFilterDetail from './PopUpFilterDetail';
import PopUpFilterDetailUserDocument from './PopUpFilterDetailUserDocument';
import ButtonAdvancedSearch from './ButtonAdvancedSearch';
import ButtonDetailAdvancedSearch from './ButtonDetailAdvancedSearch';
import PopUpFilterGeneric from './PopUpFilterGeneric';
import PopUpFilterDetailGeneric from './PopUpFilterDetailGeneric';
import SearchBarReports from './SearchReports/SearchBarReports';
import { CheckBoxGenericMinusIcon } from '../CheckboxForm/CheckBoxGenericMinusIcon';
import { ReactComponent as Delete } from '../../assets/icons/delete.svg'
import { ReactComponent as Download } from '../../assets/icons/download-attachment.svg'
import { ExportButtonGeneric } from './ExportButtonGeneric';
import { UseWindowDimensions } from '../../utils/UseWindowsDimentions';

const Divider = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.8% 1.8%;
  gap: 16px 40px;

  ${props => (!!props.searchbarMaxWidth) && css`
    .searchBarContent{
      max-width:${props.searchbarMaxWidth};
    }
  `}
  ${props => (!!props.customWidthDiv2) && css`
      .div2 {
        width: ${props.customWidthDiv2};
      }
  `}
  ${props => (!props.showAdvancedSearch) ? css` .div1 { max-width: 640px; }`
  :
  css` .div1 { max-width:863px; }`
  }
  ${props => (!!props.showAdvancedSearch && !!props.showButton) && css`
    @media only screen and (max-width: 1930px) and (min-width: 1500px) {
      flex-direction: column;
      .div1 {
        width: 100%;
      }

      .searchBarContent{
        width:50%;
        max-width:640px;
      }

      .div2 {
        width: 100%;
      }
    }
  `}
  ${props => (!!props.showExportButton && !!props.showButton) && css`
    @media only screen and (min-width: 1200px) and (max-width: 1980px) {
      padding-bottom: 50px;
      position: relative;
    }
      .divExport {
        position: absolute;
        right: 0;
        bottom: 5px;
        padding: 0 1.8%;
      }
    }
  `}

  .searchBarContent{
    width:100%;
    max-width:640px;
  }
  .buttonAdvanceSearchContent{
    width:35%;
    max-width:208px;
  }
  .div1 {
    width:100%;
    display: flex;
    justify-content: space-between;
  }
  .div6{
    display:none;
  }
  .div5 {
    display: none;
  }
  .divExportRes {
    display: none;
  }
  .paginationAndCreate{
    display: flex;
    gap: 40px;
  }
  .contentPaginationAndExport{
    display:flex;
    gap: 32px;
  }
  .div2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 22px;
    /width:100%;
    flex-grow: 100;
  }
  .div6 {
    align-items: center;
    gap: 20px;
  }
  .containerOrder {
    padding-left: 20px;
    width: 100%;
    display: flex;
    -webkit-justify-content: space-between;
  }

  @media only screen and (max-width: 1800px) {
    .div1 {
      gap: 15px !important;
    }
  }
  @media only screen and (max-width: 1700px) {
    .contentPaginationAndExport{
      gap: 10px;
    }
  }
  @media only screen and (max-width: 1600px) {
    gap: 16px 20px;
    .div2 {
      gap: 10px;
    }
    .paginationAndCreate{
      gap: 20px;
    }
  }
  @media only screen and (max-width: 1024px) {
    padding: 0.5rem 10px !important;
  }
  @media only screen and (max-width: 1200px) {
    flex-direction: column;
    gap: 0px;
    padding: 1% 24px;
    .div1 {
      padding: 1% 0;
      width: 100% !important;
      display: flex;
      max-width:unset;
    }
    .div2 {
      width: 100%;
      justify-content: space-between !important;
      gap: 0px;
    }
    .div4 {
      width: 100%;
      justify-content: space-between !important;
    }
    .div3 {
      display: none;
    }
    .divExport{
      display: none;
    }
    .divExportRes {
      display: flex;
    }
    .div5 {
      display: flex;
    }
    .containerOrder {
      padding: 0;
    }
    .div6{
      display:block;
    }
    .searchBarContent{
      max-width:unset;
    }
    .buttonAdvanceSearchContent{
      width:auto !important;
    }
  }
  @media only screen and (max-width: 768px) {
    padding: 1% 1.6%;
    .searchBarContent{
      width:100%;
    }
    .buttonAdvanceSearchContent{
      width: 35%
    }
  }
  @media only screen and (max-width: 450px) {
    .contentPaginationAndExport{
      gap: 5px;
    }
  }
`;

const InsideDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 22px;
  @media only screen and (max-width: 1600px) {
    gap: 11px;
  }
  @media only screen and (max-width: 1200px) {
    width: 100%;
    padding-left: 0% !important;
    justify-content: space-between;
    gap: 16px;
  }
  @media only screen and (max-width: 768px) {
    gap: 0px;
  }
`;

const DividerCheckbox = styled.div`
  display: flex;
  align-items: baseline;
  padding-top:5px;
  box-sizing:unset;
`

const DivButtons = styled.div`
display: flex;
flex-direction: row;
justify-content: ${props => props.showButton ? 'center' : 'space-between'};
`
const ContentFlex = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: baseline;
`

const DividerCheckbox2 = styled.div`
  display: flex;
  align-items: center;
  padding:0px 0px 10px 2.5rem;
  box-sizing:unset;
  height: 25px;
  margin-top:-10px;
  @media only screen and (max-width: 1024px) {
    padding-left: 2%;
  }
`

export const SearchBarGenericCreate = ({ textPlaceHolder, popUpOrderState, searchBarState, buttonType = '', text, customWidthDiv2 }) => {
  return (
    <>
      <Divider customWidthDiv2={customWidthDiv2}>
        <div className='div1'>
          <div className='searchBarContent'>
            <SearchBar textPlaceHolder={textPlaceHolder} searchBarState={searchBarState} />
          </div>
          <div className='div5' style={{ width: '25%' }}>
            <CreateButton buttonType={buttonType} text={text} />
          </div>
        </div>
        <div className='div2'>
          <InsideDiv>
            <PopUpOrder showOldestNewest={false} showAZ={true} popUpOrderState={popUpOrderState} />
            <Pagination />
          </InsideDiv>
        </div>
        <div className='div3'>
          <CreateButton buttonType={buttonType} text={text} />
        </div>
      </Divider>
    </>
  );
};
export const SearchBarGenericButton = ({ filterAgreements = true, paddingLeft, showButton, showAZ = false, textPlaceHolder, searchBarState,
  popUpOrderState, buttonType = '', text, showFilters = true, disabledButton, showFeatured = true, showCleanFilter = false, customWidthDiv2 }) => {
  return (
    <Divider customWidthDiv2={customWidthDiv2}>
      <div className='div1'>
        <div className='searchBarContent'>
          <SearchBar textPlaceHolder={textPlaceHolder} searchBarState={searchBarState} />
        </div>
        {showButton && (
          <div className='div5' style={{ width: '25%' }}>
            <CreateButton buttonType={buttonType} text={text} />
          </div>
        )}
      </div>
      <div className='div2'>
        <InsideDiv style={{ width: 'auto' }}>
          {showFilters && (filterAgreements ? <PopUpFilterAgreements /> :
            <PopUpFilterGeneric showCleanFilter={showCleanFilter} showFeatured={showFeatured} />)}
          <PopUpOrder popUpOrderState={popUpOrderState} showOldestNewest={true} showAZ={showAZ} />
        </InsideDiv>

        <div className='paginationAndCreate'>
          <Pagination />
          {showButton && (
            <div className='div3' style={{ paddingLeft: '1%' }}>
              <CreateButton buttonType={buttonType} text={text} disabledButton={disabledButton} />
            </div>
          )}
        </div>
      </div>
    </Divider>
  );
};

export const SearchBarGenericButtonType = ({ filter = true, dataFilter, paddingLeft, showButton, showAZ = false, textPlaceHolder,
  searchBarState, popUpOrderState, buttonType = '', text, showFilters = true, disabledButton, showFeatured = true,
  showCleanFilter = false, showOldestNewest = true, showExportButton = false, disabledExportButton, customWidthDiv2, showAdvancedSearch = true,
  searchbarMaxWidth }) => {
  return (
    <Divider customWidthDiv2={customWidthDiv2} showAdvancedSearch={showAdvancedSearch} showExportButton={showExportButton}
      showButton={showButton} searchbarMaxWidth={searchbarMaxWidth}>
      <div className='div1'>
        <div className='searchBarContent'>
          <SearchBar textPlaceHolder={textPlaceHolder} searchBarState={searchBarState} />
        </div>
        {disabledButton && (
          <div className='div5' style={{ width: '25%' }}>
            <CreateButton buttonType={buttonType} text={text} />
          </div>
        )}
        {showButton && !disabledButton && (
          <div className='div6'>
            <CreateButton buttonType={buttonType} text={text} disabledButton={disabledButton} />
          </div>
        )}
      </div>
      <div className='div2' style={{ justifyContent: `${showButton ? 'center' : 'spaceBetween'}` }} >
        <InsideDiv style={{ width: 'auto', paddingLeft: paddingLeft }}>
          {showFilters && (filter ? dataFilter :
            <PopUpFilterGeneric showCleanFilter={showCleanFilter} showFeatured={showFeatured} />)}
          <PopUpOrder popUpOrderState={popUpOrderState} showOldestNewest={showOldestNewest} showAZ={showAZ} />
        </InsideDiv>
        <div className='contentPaginationAndExport'>
          <Pagination />
          <ContentFlex>
            {showButton && (
              <div className='div3' style={{ paddingLeft: '1%' }}>
                <CreateButton buttonType={buttonType} text={text} disabledButton={disabledButton} />
              </div>
            )}
            {!disabledExportButton && (
              <div className='divExport' style={{ paddingLeft: '5px' }}>
                <ExportButton disabledButton={disabledExportButton} />
              </div>
            )}
          </ContentFlex>
          {!disabledExportButton && (
            <div className='divExportRes' style={{ paddingLeft: '1%' }}>
              <ExportButton disabledButton={disabledExportButton} />
            </div>
          )}
        </div>
      </div>
    </Divider>
  );
};

export const SearchBarDetailGenericButton = ({ filterAgreements = true, paddingLeft, showButton, showAZ = false, textPlaceHolder, searchBarState,
  popUpOrderState, buttonType = '', text, showFilters = true, disabledButton, showFeatured = true, showCleanFilter = false,shortPaddingTop=false, options, filterPosition, agreementsLogs=false}) => {
  return (
    <Divider shortPaddingTop={shortPaddingTop} style={{ justifyContent: 'left', }}>
      <div className='div1'>
        <div className='searchBarContent'>
          <SearchBarDetail textPlaceHolder={textPlaceHolder} searchBarState={searchBarState} />
        </div>
        {showButton && (
          <div className='div5' style={{ width: '25%' }}>
            <CreateButton buttonType={buttonType} text={text} disabledButton={disabledButton} />
          </div>
        )}
      </div>
      <div className='div2' style={{ justifyContent: `${showButton ? 'center' : 'spaceBetween'}` }} >
        <InsideDiv style={{ width: 'auto', paddingLeft: paddingLeft }}>
          {showFilters && (filterAgreements ? <PopUpFilterDetailAgreements filterPosition={filterPosition}/> :
            <PopUpFilterDetailGeneric showCleanFilter={showCleanFilter} showFeatured={showFeatured} options={options} filterPosition={filterPosition} agreementsLogs={agreementsLogs}/>)}
          <PopUpOrderDetail popUpOrderState={popUpOrderState} showOldestNewest={true} showAZ={showAZ} filterPosition={filterPosition} />
        </InsideDiv>
        <PaginationDetail />
      </div>
      {showButton && (
        <div className='div3' style={{ paddingLeft: '1%' }}>
          <CreateButton buttonType={buttonType} text={text} disabledButton={disabledButton} />
        </div>
      )}
    </Divider>
  );
};

export const SearchBarDetailGenericButtonWithExport = ({ filterAgreements = true, paddingLeft, showButton, showAZ = false, textPlaceHolder, searchBarState,
  popUpOrderState, buttonType = '', text, showFilters = true, disabledButton, showFeatured = true, showCleanFilter = false,shortPaddingTop=false, options, filterPosition, agreementsLogs=false, handleClickExport, loaderExport}) => {
    const {width} = UseWindowDimensions()
    return (
      <Divider shortPaddingTop={shortPaddingTop} style={{ justifyContent: 'left', }}>
        <div className='div1'>
          <div className='searchBarContent'>
            <SearchBarDetail textPlaceHolder={textPlaceHolder} searchBarState={searchBarState} />
          </div>
          {showButton && (
            <div className='div5' style={{ width: '25%' }}>
              <CreateButton buttonType={buttonType} text={text} disabledButton={disabledButton} />
            </div>
          )}
        </div>
        <DivButtons className='div2' style={{ justifyContent: `${showButton ? 'center' : 'spaceBetween'}` }} >
          <InsideDiv >
            <div style={{display:'flex',gap:'10px'}}>
            {showFilters && (filterAgreements ? <PopUpFilterDetailAgreements filterPosition={filterPosition}/> :
              <PopUpFilterDetailGeneric showCleanFilter={showCleanFilter} showFeatured={showFeatured} options={options} filterPosition={filterPosition} agreementsLogs={agreementsLogs}/>)}
            <PopUpOrderDetail popUpOrderState={popUpOrderState} showOldestNewest={true} showAZ={showAZ} filterPosition={filterPosition} />
            </div>
            <ExportButtonGeneric handleClickExport={handleClickExport} loader={loaderExport}/>
          </InsideDiv>
          {width > 768 && <PaginationDetail />}
        </DivButtons>
        {width <= 768 && 
          <div style={{alignSelf:'end'}}>
            <PaginationDetail/>
          </div>}
        {showButton && (
          <div className='div3' style={{ paddingLeft: '1%' }}>
            <CreateButton buttonType={buttonType} text={text} disabledButton={disabledButton} />
          </div>
        )}
      </Divider>
    );
};



export const SearchBarDrafts = ({ filterAgreements = true, paddingLeft, showButton, showAZ = false, textPlaceHolder, searchBarState,
  popUpOrderState, buttonType = '', text, showFilters = true, disabledButton, showFeatured = true, showCleanFilter = false,
  isVisible, handleSelectAll, selectCheckBox, handleClickDelete, isVisibleCheck, checkedAll }) => {
  return (
    <Divider style={{ justifyContent: 'left' }}>
      <div className='div1'>
        <div className='searchBarContent'>
          <SearchBar textPlaceHolder={textPlaceHolder} searchBarState={searchBarState} />
        </div>
        {showButton && (
          <div className='div5' style={{ width: '25%' }}>
            <CreateButton buttonType={buttonType} text={text} />
          </div>
        )}
      </div>
      <div className='div2' style={{ justifyContent: `${showButton ? 'center' : 'spaceBetween'}`, marginLeft: 'unset', marginTop: '10px' }} >

        {isVisibleCheck && <DividerCheckbox>
          <CheckBoxGenericMinusIcon onChange={handleSelectAll} checked={selectCheckBox} checkedAll={checkedAll} />
          {isVisible && <div style={{ display: 'flex', color: '#22C1D0' }}>
            <Delete style={{ cursor: 'pointer', margin: '2px 6px 0px 28.6px' }} onClick={handleClickDelete} />
            <div style={{ cursor: 'pointer' }} onClick={handleClickDelete}>Eliminar</div>
          </div>}
        </DividerCheckbox>}

        <InsideDiv style={{ width: 'auto', paddingLeft: paddingLeft }}>
          {showFilters && (filterAgreements ? <PopUpFilterAgreements /> :
            <PopUpFilterGeneric showCleanFilter={showCleanFilter} showFeatured={showFeatured} />)}
          <PopUpOrder popUpOrderState={popUpOrderState} showOldestNewest={true} showAZ={showAZ} />
          <Pagination />
        </InsideDiv>
      </div>
      {showButton && (
        <div className='div3' style={{ paddingLeft: '1%' }}>
          <CreateButton buttonType={buttonType} text={text} disabledButton={disabledButton} />
        </div>
      )}
    </Divider>
  );
};

export const SearchBarGenericInitial = ({ paddingLeft, showButton, showAZ = false, textPlaceHolder, searchBarState, popUpFilter,
  popUpOrderState, showOldestNewest = false, buttonType = '', text, showFilters = false, disabledButton, showPopUpFilter = false }) => {
  return (
    <Divider>
      <div className='div1'>
        <div className='searchBarContent'>
          <SearchBar textPlaceHolder={textPlaceHolder} searchBarState={searchBarState} />
        </div>
        {showButton && (<div className='div5' style={{ width: '25%' }}>
          <CreateButton buttonType={buttonType} text={text} />
        </div>
        )}
      </div>
      <div className='div2' style={{ justifyContent: `${showButton ? 'center' : 'spaceBetween'}` }} >
        <InsideDiv style={{ width: 'auto', paddingLeft: paddingLeft }}>
          {showFilters && (!!showPopUpFilter && popUpFilter)}
          {!!showFilters && <PopUpOrder popUpOrderState={popUpOrderState} showOldestNewest={showOldestNewest} showAZ={showAZ} />}
        </InsideDiv>
        <Pagination />
      </div>
      {showButton && (
        <div className='div3' style={{ paddingLeft: '1%' }}>
          <CreateButton buttonType={buttonType} text={text} disabledButton={disabledButton} />
        </div>
      )}
    </Divider>
  );
};

export const SearchBarGenericAdvancedSearch = ({ textPlaceHolder, searchBarState, popUpOrderState, viewGrid, typeButton, showAZ }) => {
  return (
    <Divider showAdvancedSearch={true}>
      <div className='div1' style={{ display: 'flex', gap: '15px' }}>
        <div className='searchBarContent'>
          <SearchBar textPlaceHolder={textPlaceHolder} searchBarState={searchBarState} />
        </div>
        <div className='buttonAdvanceSearchContent'>
          <ButtonAdvancedSearch typeButton={typeButton} className='buttonAdvanceSearchContent' />
        </div>
      </div>
      <div className='div4'>
        <InsideDiv>
          <PopUpOrder popUpOrderState={popUpOrderState} showOldestNewest={true} showAZ={showAZ} />
          <div className='div6' style={{ display: 'flex' }}>
            <Pagination />
            {viewGrid ? <ButtonTypeGrid /> : <ButtonTypeList />}
          </div>
        </InsideDiv>
      </div>
    </Divider>
  );
};

export const SearchBarDetailGenericAdvancedSearch = ({ textPlaceHolder, searchBarState, popUpOrderState, viewGrid, typeButton, showAZ,
  isVisible, handleSelectAll, selectCheckBox, handleClickDownload, isVisibleCheck, showButton = false, checkedAll = false, popUpFilterState, filterSmall }) => {
  return (
    <>
      <Divider showAdvancedSearch={true}>

        <div className='div1' style={{ display: 'flex', gap: '20px' }}>
          <div className='searchBarContent'>
            <SearchBarDetail textPlaceHolder={textPlaceHolder} searchBarState={searchBarState} />
          </div>
          <div className='buttonAdvanceSearchContent'>
            <ButtonDetailAdvancedSearch typeButton={typeButton} />
          </div>
        </div>
        <div className='div2'>
          <InsideDiv style={{ width: 'auto' }}>
            <PopUpFilterDetailUserDocument popUpFilterState={popUpFilterState} filterSmall={filterSmall} noStarred={true} />
            <PopUpOrderDetail popUpOrderState={popUpOrderState} showOldestNewest={true} showAZ={showAZ} />
          </InsideDiv>
          <div className='div6' style={{ display: 'flex' }}>
            <PaginationDetail />
            {viewGrid ? <ButtonTypeGrid /> : <ButtonTypeList />}
          </div>
        </div>
      </Divider>
      <div style={{ justifyContent: 'left' }}>

        <div className='div2' style={{ marginLeft: 'unset' }} >

          <DividerCheckbox2 style={{}}>
            <CheckBoxGenericMinusIcon onChange={handleSelectAll} checked={selectCheckBox} checkedAll={checkedAll} />
            {isVisible && <div style={{ display: 'flex', color: '#22C1D0', margin: '0 0 -6px 0px' }}>
              <Download style={{ cursor: 'pointer', margin: '2px 6px 0px 28.6px' }} onClick={handleClickDownload} />
              <div style={{ cursor: 'pointer', marginTop: '3px' }} onClick={handleClickDownload}>Descargar</div>
            </div>}
          </DividerCheckbox2>
        </div>
      </div>
    </>
  );
};

export const SearchBarGenericSent = ({ textPlaceHolder, searchBarState, popUpOrderState, popUpFilterState, showAdvancedSearch,
  showAZ, filterSmall }) => {
  return (
    <Divider showAdvancedSearch={showAdvancedSearch}>
      <div className='div1' style={{ display: 'flex', gap: '15px' }}>
        <div className='searchBarContent'>
          <SearchBar textPlaceHolder={textPlaceHolder} searchBarState={searchBarState} />
        </div>
        {showAdvancedSearch && (
          <div className='buttonAdvanceSearchContent'>
            <ButtonAdvancedSearch typeButton={'sents'} />
          </div>
        )}
      </div>
      <div className='div2' style={{ justifyContent: `${showAdvancedSearch ? 'end' : 'space-between'}`, marginLeft: '0' }} >
        <InsideDiv style={{ width: 'auto', paddingLeft: `${!showAdvancedSearch && '33px'}` }} >
          <PopUpFilter popUpFilterState={popUpFilterState} filterSmall={filterSmall} />
          <PopUpOrder popUpOrderState={popUpOrderState} showOldestNewest={true} showAZ={false} />
        </InsideDiv>
        <Pagination />
      </div>
    </Divider>
  );
};

export const SearchBarGenericSentMassiveDetail = ({ textPlaceHolder, searchBarState, popUpOrderState, popUpFilterState, showAdvancedSearch, showAZ, filterSmall }) => {
  return (
    <Divider showAdvancedSearch={showAdvancedSearch}>
      <div className='div1' style={{ display: 'flex', gap: '20px' }}>
        <div className='searchBarContent'>
          <SearchBarDetail textPlaceHolder={textPlaceHolder} searchBarState={searchBarState} />
        </div>
        {showAdvancedSearch && (
          <div className='buttonAdvanceSearchContent'>
            <ButtonAdvancedSearch typeButton={'sents'} />
          </div>
        )}
      </div>
      <div className='div2' style={{ justifyContent: `${showAdvancedSearch ? 'end' : 'space-between'}`, marginLeft: '0' }} >
        <InsideDiv style={{ width: 'auto' }} >
          <PopUpFilterDetail popUpFilterState={popUpFilterState} filterSmall={filterSmall} noStarred={true} />
          <PopUpOrderDetail popUpOrderState={popUpOrderState} showOldestNewest={true} showAZ={false} />
        </InsideDiv>
        <PaginationDetail />
      </div>
    </Divider>
  );
};

export const SearchBarGenericClients = ({ showButton, textPlaceHolder, searchBarState, popUpOrderState }) => {
  return (
    <Divider style={{ justifyContent: 'left' }}>
      <div className='div1'>
        <div className='searchBarContent'>
          <SearchBar textPlaceHolder={textPlaceHolder} searchBarState={searchBarState} />
        </div>
      </div>
      <div className='containerOrder' style={{ justifyContent: `${showButton ? 'center' : 'spaceBetween'}` }} >
        <InsideDiv style={{ width: 'auto' }}>
          <PopUpOrder popUpOrderState={popUpOrderState} showOldestNewest={false} showAZ={true} />
        </InsideDiv>
        <Pagination />
      </div>
    </Divider>
  );
};

export const SearchBarGenericReports = ({ textPlaceHolder, searchBarState, popUpOrderState, popUpFilterState, showAdvancedSearch,
  buttonType = '', text, customWidthDiv2 }) => {
  return (
    <Divider customWidthDiv2={customWidthDiv2} showAdvancedSearch={showAdvancedSearch} >
      <div className='div1' style={{ display: 'flex', gap: '20px' }}>
        <div className='searchBarContent'>
          <SearchBarReports textPlaceHolder={textPlaceHolder} searchBarState={searchBarState} />
        </div>
        {showAdvancedSearch && (
          <div className='buttonAdvanceSearchContent'>
            <ButtonAdvancedSearch typeButton={buttonType} />
          </div>
        )}
      </div>
      <div className='div2'>
        <InsideDiv style={{ width: 'auto', gap: '0px', paddingLeft: `${!showAdvancedSearch && '33px'}` }}>
          <PopUpFilterGeneric popUpFilterState={popUpFilterState} options={'reports'} />
          <PopUpOrder popUpOrderState={popUpOrderState} showOldestNewest={true} showAZ={true} options={'reports'} />
        </InsideDiv>
        <div className='paginationAndCreate'>
          <Pagination />
          <div>
            <CreateButton buttonType={buttonType} text={text} />
          </div>
        </div>

      </div>
    </Divider>
  );
};
