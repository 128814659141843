import React, {Component} from 'react'
import {connect} from 'react-redux'

import styled from 'styled-components'
import DOMPurify from 'dompurify'
import {Divider} from 'semantic-ui-react'
import htmlToText from 'html-to-text'
import {ReactComponent as PreviewHeaderAndFilter} from '../../../assets/icons/write/Group-26.svg'
import {ReactComponent as ViewReadConfirmation} from '../../../assets/icons/write/viewReadConfirmation.svg'
import {ReactComponent as AzIcon} from '../../../assets/icons/write/previewList/AZicon.svg'
import {ReactComponent as FilterIcon} from '../../../assets/icons/write/previewList/Filtericon.svg'
import {ReactComponent as SearchIcon} from '../../../assets/icons/write/previewList/Searchicon.svg'
import {ReactComponent as StatusNotificationIcon} from '../../../assets/icons/write/previewList/StatusNotificationicon.svg'
import {ReactComponent as NotificationDisabled} from '../../../assets/icons/write/previewList/NotificationDisabled.svg'
import {ReactComponent as NotificationMenu} from '../../../assets/icons/write/previewList/NotificationMenu.svg'
import PropTypes from "prop-types";
import _ from "lodash";
import {changeDatePreview} from "../../../actions/writerActions";

const ListElements = styled.div`
  display: flex;
  flex-direction: column;
`
const Element = styled.div`
  padding-left: 16px;
  padding-right: 16px;

  &:first-child {
    padding-top: 16px;
  }
`
const ElementDate = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #000000;
`
const ElementTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`
const ElementDescription = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: #7C7C74;
`

const ElementContent = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: #73736A;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`
const HeaderWsp = styled.div`
  background: linear-gradient(180deg, #0A4A83 0%, #003057 100%, #003057 100%);
  max-width: 313px;
  max-height: 48px;
  width:100%;
  height: 48px;
  border-radius: 3px 3px 0px 0px;
  padding: 16px;
  color: #FFFFFF;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.75px;
  letter-spacing: 0px;
  text-align: center;

`
const NotificationDisabledImg= styled(NotificationDisabled)`
  width: 100% !important;
  height: 100% !important;
`
const NotificationMenuImg= styled(NotificationMenu)`
  height: 100%;
`

export class PreviewList extends Component {

  state= {
    dateHour: ''
  }

  componentDidMount(){
    this.setState({dateHour:this.hourFormat()})
  }

  async componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.form.subject, this.props.form.subject) &&
      this.props.form.subject.length !== 0) {
      let date = new Date();
      this.setState({dateHour:this.hourFormat()})
      await this.props.changeDatePreview(date.toLocaleString("en-AU").replace(",", " - "))
    }
  }

  hourFormat = () =>{
    const time = new Date();
    return time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).toLowerCase()
  }

  render() {
    return (
      <>
        <div className='contents-previewList'>
          <HeaderWsp>
            Notificaciones
          </HeaderWsp>
          <div className='content-filters'>
            <AzIcon/>
            <FilterIcon/>
            <div className='content-input-search'>
              Buscar en mis notificaciones
              <SearchIcon/>
            </div>
          </div>
          <div className='content-notification-status'>
            <StatusNotificationIcon/>
          </div>
          <div className='svg-and-contents-read-confirmation-pro'>
            <div className='container-notification-message'>
              <div className="title-message-notification">
                {this.props.companyName}
              </div>
              <div className='hour-message-notification'>
                {this.state.dateHour}
              </div>
              <div className="subject-message-notification">
                {this.props.subject}
              </div>
              <div className='hola'>
              <div className="text-message-notification" 
                dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(this.props.text)
                }}/>
              </div>
            </div>
          </div>
          <div className='content-notification-disabled'>
            <NotificationDisabledImg/>
          </div>
          <div className='content-notification-menu'>
            <NotificationMenuImg/>
          </div>
        </div>
      </>
    )
  }
}

PreviewList.propTypes = {
  changeDatePreview: PropTypes.func,
  companyName: PropTypes.string,
  subject: PropTypes.string,
  text: PropTypes.string,
  form: PropTypes.object
}

const mapStateToProps = (state) => ({
  form: state.writerReducer.notificationForm,
  companyName: state.authReducer.user.companyID.name,
  subject: state.writerReducer.notificationForm.subject,
  text: state.writerReducer.notificationForm.textEditor,
})

const mapDispatchToProps = {
  changeDatePreview: changeDatePreview
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewList)