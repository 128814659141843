import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { DashBoardClient } from './DashBoardClient';
import { DashBoardOperator } from './DashBoardOperator';
import { DashBoardSupAdm } from './DashBoardSupAdm'
import { getDashboardInfoV2, haveAgreementsAction } from '../../actions/dashboardActions';

export const Dashboard = () => {
  const { role } = useSelector((state) => state.authReducer.user)
  const [result, setResult] = useState({})

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDashboardInfoV2())
  }, [dispatch, role])

  const dashboardInfo = useSelector(state => state.dashboardReducer.info)

  useEffect(() => {
    if (dashboardInfo) {
      if (dashboardInfo.success) {
        setResult(dashboardInfo.result)
      } else {
        dispatch(haveAgreementsAction(dashboardInfo.success))
        setResult(dashboardInfo)
      }
    }

  }, [dashboardInfo])


  const Dashboard = {
    '61b36e2fc34e22351bf8cfb4': <DashBoardSupAdm result={result} />,
    '61e59ac8c34e22351b614e41': <DashBoardOperator result={result} />,
    '62ab678573b800f3b2f0abee': <DashBoardClient result={result} />,
  }

  return (
    <> {Dashboard[role]} </>
  )
}

export default Dashboard