import React, { useEffect, useState} from 'react'
import {MainSegment} from '../../../components/Sengment/MainSegment'
import {useDispatch, useSelector} from 'react-redux'
import styled from 'styled-components'
import {ReactComponent as LeftArrowIcon} from '../../../assets/icons/sent/leftArrow.svg'
import {warningFormModal} from '../../../actions/modalActions'
import MessageModal from '../../../components/Modal/MessageModal'
import WarningModal from '../../../components/FormUser/Modal/WarningModal'
import {useHistory} from 'react-router-dom'
import EditForm from '../../../components/FormUser/EditForm'
import { getRolesUser } from '../../../actions/usersActions'

const Container = styled.div`
  margin: 30px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  @media only screen and (max-width: 1200px) {
    margin: 10px 10px 20px 10px;
  }
`

const Title = styled.p`
  color: #133F4F;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
  @media only screen and (max-width: 1024px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
`
const LeftArrow = styled.button`
  padding: 0;
  margin: 0 16px 0 0;
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  line-height: 0px;
`
const EditUsers = () => {

  const dispatch = useDispatch()
  const {activeForm} = useSelector(state => state.userReducer)
  const { namesUserEdit } = useSelector(state => state.userReducer)
  const history = useHistory()
  const [data, setData] = useState([])
  const [role, setRole] = useState([])

  const handleClick = () => {
    if (activeForm === true) {
      dispatch(warningFormModal({type: 'warningLeaveUpdate'}))
    } else {
      history.push('/users')
    }
  }

  useEffect(() => {
    dispatch(getRolesUser()).then(resul => {setData(resul)})
  }, [dispatch])

  useEffect(() => {
      const dataRole = data.map((data) => {
          return { key: data._id, text: data.name, value: data._id }
      })
      dataRole.unshift({ key: '', text: '', value: '' })
      setRole(dataRole)
  }, [data])

  return (
    <MainSegment>
      <Container>
        <LeftArrow onClick={handleClick}>
          <LeftArrowIcon/>
        </LeftArrow>
        <WarningModal type='warningLeaveUpdate'/>
        <Title>Editar usuario</Title>
      </Container>
      <EditForm role={role}/>
     
    </MainSegment>
  )
}
export default EditUsers