import {
  CHANGE_STATE_OPEN_DATE_MODAL,
  CHANGE_STATE_CLOSE_DATE_MODAL,
  CHANGE_STATE_OPEN_SEND_MASSIVE_MODAL,
  CHANGE_STATE_CLOSE_SEND_MASSIVE_MODAL,
  WRITE_NOTIFICATION_MODAL_OPEN,
  WRITE_NOTIFICATION_MODAL_CLOSE,
  LOADING_MODAL,
  CHANGE_STATE_MESSAGE_MODAL,
  LOGOUT,
  CHANGE_STATE_WARNING_FORM_MODAL,
  SHOW_STATE_ERROR_MESSAGE_SEND_MASSIVE_MODAL,
  REFRESH_STATE_ERROR_MESSAGE_SEND_MASSIVE_MODAL,
  LOADING_SEND_MASSIVE_MODAL,
  SHOW_RESULT_SEND_MASSIVE_MODAL,
  LOAD_RESULT_SEND_MASSIVE_MODAL,
  CHANGE_STATE_OPEN_PDF_MODAL,
  CHANGE_STATE_CLOSE_PDF_MODAL,
  CHANGE_STATE_REFRESH_MODULE,
  CHANGE_STATE_LOGIN_MODAL,
  CHANGE_STATE_REPORT_MODAL,
  CHANGE_STATE_LOADING_FILE_MASIVE,
  CHANGE_STATE_REPORTS_SEARCH_MODAL,
  CHANGE_STATE_REPORTS_LOADING_MODAL,
  CHANGE_STATE_WARNING_FORM_MODAL_DRAFTS,
  CLOSE_WARNING_FORM_MODAL_DRAFTS,
  CHANGE_STATE_MASSIVE_INCOMPLETE_MODAL,
  CHANGE_STATE_MESSAGE_MODAL_TWO,
  CHANGE_STATE_FORM_CANCEL_AGREEMENT,
  CHANGE_STATE_MODAL_REASON_CANCEL_AGREEMENT,
  CHANGE_STATE_WARNING_FORM_MODAL_WRITE,
  HIDE_MESSAGE_MODAL,
  CHANGE_ROLE_STATE_MODAL
} from '../actions/actionsUtilities/types.js'

const initialState = {
  dateModal: false,
  reportSearchModal: false,
  massiveIncompleteModal:{
    status: false,
    type: 'massiveIncomplete',
    urlFileError: ''
  },
  sendMassiveModal: {
    status: false,
    showErrorSendMassiveFile: 'hidden',
    messageErrorSendMassiveFile:'',
    loadingMassiveModal: false,
    resultsMassiveModal: {
      showResultsMassiveModal: false,
      correctData: 0,
      incorrectData: 0,
      modalRecipients: {},
      file: { base64ErrorFile: '', fileName: '' },
      totalData: 0
    },
  },
  pdfModal: false,
  writeNotification: false,
  loading: false,
  reportLoadingModal: {
    status: false,
    type: 'reportLoadingMessage',
    message: ''
  },
  messageModalTwo:{
    status: false,
    type: 'warningFiles',
    message: '',
    fileSerialID:'',
    urlFileError: ''
  },
  messageModal: {
    status: false,
    type: 'finish',
    message: '',
    quantyCitizen: 0
  },
  messageModalChangeRole: {
    status: false,
    type: 'changeRoleError',
    message: '',
  },
  messageLoginModal: {
    status: false,
    type: 'sendEmail',
    message: ''
  },
  warningModal: {
    status: false,
    type: 'warningLeave',
    message: ''
  },
  warningModalWrite: {
    status: false,
    type: 'warningSendEmail',
    message: ''
  },
  refreshModule: false,
  reportModal: {
    status: false,
    type: 'warningReport',
    message: ''
  },
  loginModalFileMasive: {
    status: false,
    type: 'progress',
    message: ''
  },
  massiveNotificationAlert: false,
  id: '',
  modalCancelAgreement: {
    status: false,
    type: 'warningCanceleAgreement',
    message: ''
  },
  modalReasonCancelAgreement: {
    status: false,
    type: 'reasonCanceleAgreement',
    message: ''
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT:
      return initialState
    case CHANGE_STATE_OPEN_DATE_MODAL:
      return {
        ...state,
        dateModal: true
      }
    case CHANGE_STATE_CLOSE_DATE_MODAL:
      return {
        ...state,
        dateModal: false
      }
    case CHANGE_STATE_OPEN_PDF_MODAL:
      return {
        ...state,
        pdfModal: true
      }
    case CHANGE_STATE_CLOSE_PDF_MODAL:
      return {
        ...state,
        pdfModal: false
      }
    case CHANGE_STATE_WARNING_FORM_MODAL:
      return {
        ...state,
        warningModal: {
          status: !state.warningModal.status,
          type: action.payload.type,
          message: action.payload.message
        }
      }
      case CHANGE_STATE_WARNING_FORM_MODAL_WRITE:
      return {
        ...state,
        warningModalWrite: {
          status: !state.warningModalWrite.status,
          type: action.payload.type,
          message: action.payload.message
        }
      }
    case CHANGE_STATE_WARNING_FORM_MODAL_DRAFTS:
      return {
        ...state,
        warningModal: {
          status: !state.warningModal.status,
          type: action.payload.type,
          message: action.payload.message
        },
        id: action.payload.id
      }
    case CLOSE_WARNING_FORM_MODAL_DRAFTS:
      return {
        ...state,
        warningModal: {
          ...initialState.warningModal
        },
        id: ''
      }
    case CHANGE_STATE_OPEN_SEND_MASSIVE_MODAL:
      return {
        ...state,
        sendMassiveModal: {
          ...state.sendMassiveModal,
          status: true
        }
      }
    case SHOW_STATE_ERROR_MESSAGE_SEND_MASSIVE_MODAL:
      return {
        ...state,
        sendMassiveModal: {
          ...state.sendMassiveModal,
          showErrorSendMassiveFile: action.payload.status,
          messageErrorSendMassiveFile: action.payload.message,
        }
      }
    case REFRESH_STATE_ERROR_MESSAGE_SEND_MASSIVE_MODAL:
      return {
        ...state,
        sendMassiveModal: {
          ...initialState.sendMassiveModal,
        }
      }
    case LOADING_SEND_MASSIVE_MODAL:
      return {
        ...state,
        sendMassiveModal: {
          ...state.sendMassiveModal,
          loadingMassiveModal: action.payload
        }
      }
    case LOAD_RESULT_SEND_MASSIVE_MODAL:
      return {
        ...state,
        sendMassiveModal: {
          ...state.sendMassiveModal,
          resultsMassiveModal: {
            ...state.sendMassiveModal.resultsMassiveModal,
            correctData: action.payload.correctData,
            incorrectData: action.payload.incorrectData,
            modalRecipients: action.payload.modalRecipients,
            totalData: action.payload.totalData,
            file: action.payload.file
          }
        }
      }
    case SHOW_RESULT_SEND_MASSIVE_MODAL:
      return {
        ...state,
        sendMassiveModal: {
          ...state.sendMassiveModal,
          resultsMassiveModal: {
            ...state.sendMassiveModal.resultsMassiveModal,
            showResultsMassiveModal: action.payload
          }
        }
      }
    case CHANGE_STATE_CLOSE_SEND_MASSIVE_MODAL:
      return {
        ...state,
        sendMassiveModal: initialState.sendMassiveModal
      }
    case WRITE_NOTIFICATION_MODAL_OPEN:
      return {
        ...state,
        writeNotification: true
      }
    case WRITE_NOTIFICATION_MODAL_CLOSE:
      return {
        ...state,
        writeNotification: false
      }
    case LOADING_MODAL:
      return {
        ...state,
        loading: action.payload,
      }
    case CHANGE_STATE_MESSAGE_MODAL:
      return {
        ...state,
        messageModal: {
          status: !!action.payload.modalStatus?  action.payload.modalStatus : !state.messageModal.status,
          type: action.payload.type,
          message: action.payload.message, 
          quantyCitizen: !!action.payload.quantyCitizen? action.payload.quantyCitizen: 0
        }
      }
      case CHANGE_ROLE_STATE_MODAL:
      return {
        ...state,
        messageModalChangeRole: {
          status: !!action.payload.modalStatus?  action.payload.modalStatus : !state.messageModalChangeRole.status,
          type: action.payload.type,
          message: action.payload.message, 
        }
      }
    case CHANGE_STATE_REPORTS_LOADING_MODAL:
      return {
        ...state,
        reportLoadingModal: {
          status: !!action.payload.hasOwnProperty('status') ? action.payload.status : !state.reportLoadingModal.status,
          type: action.payload.type,
          message: action.payload.message
        }
      }

    case CHANGE_STATE_REPORTS_SEARCH_MODAL:
      return {
        ...state,
        reportSearchModal:  !state.reportSearchModal
      }

    case CHANGE_STATE_LOGIN_MODAL:
      return {
        ...state,
        messageLoginModal: {
          status: !state.messageLoginModal.status,
          type: action.payload.type,
          message: action.payload.message
        }
      }
    case CHANGE_STATE_REFRESH_MODULE:
      return {
        ...state,
        refreshModule: action.payload,
      }
    case CHANGE_STATE_REPORT_MODAL:
      return {
        ...state,
        reportModal: {
          status: !state.reportModal.status,
          type: action.payload.type,
          message: action.payload.message
        }
      }
    case CHANGE_STATE_LOADING_FILE_MASIVE:
      return {
        ...state,
        loginModalFileMasive: {
          status: !state.loginModalFileMasive.status,
          type: action.payload.type,
          message: action.payload.message
        }
      }
      case CHANGE_STATE_MASSIVE_INCOMPLETE_MODAL:
        return {
          ...state,
          massiveIncompleteModal: {
            ...state.massiveIncompleteModal,
            urlFileError: action.payload,
            status : !state.massiveIncompleteModal.status}
        }
        case CHANGE_STATE_MESSAGE_MODAL_TWO:
          return {
            ...state,
            messageModalTwo: {
              status: !state.messageModalTwo.status,
              type: action.payload.type,
              message: action.payload.message,
              fileSerialID:action.payload.fileSerialID,
              urlFileError:action.payload.urlFileError 
            }
          }
      case CHANGE_STATE_FORM_CANCEL_AGREEMENT:
        return {
          ...state,
          modalCancelAgreement: {
            status: !state.modalCancelAgreement.status,
            type: action.payload.type,
            message: action.payload.message
          }
      }
      case CHANGE_STATE_MODAL_REASON_CANCEL_AGREEMENT:
        return {
          ...state,
          modalReasonCancelAgreement: {
            status: !state.modalReasonCancelAgreement.status,
            type: action.payload.type,
          }
      }
      case HIDE_MESSAGE_MODAL:
        return {
          ...state,
          messageModal: {
            status: false,
            type: 'finish',
            message: '',
            quantyCitizen: 0
          }
        }
      case LOGOUT:
        return initialState
    default:
      return state
  }
}