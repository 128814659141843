import axios from 'axios';
import {
  DETAIL_OF_ATTACHMENT,
  CHANGE_TYPE_LIST,
  CHANGE_DOCUMENT_CURRENT,
  VIEW_PDF,
  SET_DATA_PDF,
  VIEW_PAGINATION,
  SET_USER_CASE_NOT_FOUND,
  VIEW_PAGINATION_INTERNAL,
  DELETE_ALL_FROM_LIST_TO_DELETE_DOCUMENTS,
  DELETE_FROM_LIST_TO_DELETE_DOCUMENTS,
  UPDATE_LIST_TO_DELETE_DOCUMENTS,
} from './actionsUtilities/types.js'
import { API_ENDPOINT, API_ENDPOINT_NOTIFICATION_DEV } from "../config/config"
import { reasonDispatch } from "./DispatchGeneric";
import { GET_DETAIL_OF_ATTACHMENT, GET_DETAIL_OF_ATTACHMENT_V2, GET_PDF } from './actionsUtilities/actionsRoutes.js'
import { decrypt, encrypt } from '../helpers/cypher'
import { getHeaders } from '../helpers/globalHelpers.js';

export const updateList = (id) => (dispatch) => {
  dispatch({
      type: UPDATE_LIST_TO_DELETE_DOCUMENTS,
      payload: id
  })
}

export const removeFromList = (id) => (dispatch) => {
  dispatch({
      type: DELETE_FROM_LIST_TO_DELETE_DOCUMENTS,
      payload: id
  })
}

export const deleteAllFromList = () => (dispatch) => {
  dispatch({type: DELETE_ALL_FROM_LIST_TO_DELETE_DOCUMENTS})
}

export const detailAttachmentsV2 = (body) => async (dispatch) => {
  dispatch({
    type:SET_USER_CASE_NOT_FOUND,
    payload:""
  })
  const encryptedBody = await encrypt(body);
  await axios.post(`${API_ENDPOINT + GET_DETAIL_OF_ATTACHMENT_V2}`, { data: encryptedBody }, { headers: getHeaders() }).then(async (res) => {
    if (res.status === 200) {
      const decryptedData = await decrypt(res.data.result);
      dispatch({
        type: DETAIL_OF_ATTACHMENT,
        payload: decryptedData.resultFinal
      })
      if(decryptedData.success){
        dispatch({
          type: VIEW_PAGINATION_INTERNAL,
          payload: {
              count: decryptedData.resultFinal.elementsCountPage,
              total: decryptedData.resultFinal.elementsCount,
          }
        })
      }else{
        dispatch({
          type: VIEW_PAGINATION_INTERNAL,
          payload: {
              total: 0,
          }
        })
        dispatch({
          type:SET_USER_CASE_NOT_FOUND,
          payload:body.to
        })

        dispatch({
          type: VIEW_PAGINATION,
          payload: {
              total: undefined,
          }
        })
      }
    }
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
  })
}

export const getPDF = (body) => async (dispatch) => {
  const encryptedBody = await encrypt(body);
  await axios.post(`${API_ENDPOINT + GET_PDF}`, { data: encryptedBody }, { headers: getHeaders() }).then(async (res) => {
    if (res.status === 200) {
      const decryptedData = await decrypt(res.data.result);
    dispatch({type: SET_DATA_PDF, payload: decryptedData.base64})
  }
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
  })
}

export const changeTypeList = (data) => dispatch => {
  dispatch({type: CHANGE_TYPE_LIST, payload: data})
}

export const changeDocumentCurrent = (data) => dispatch => {
  dispatch({type: CHANGE_DOCUMENT_CURRENT, payload: data})
}

export const viewPDF = (data) => dispatch => {
  dispatch({type: VIEW_PDF, payload: data})
}