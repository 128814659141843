import React, { Component } from 'react'
import { connect } from 'react-redux'
import { SearchBarGenericAdvancedSearch } from './SearchBarGeneric'


class ContainerUserDocumentSearch extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchBarState: "",
      popUpOrderState: "",
    };
  }
  render() {
    return (
      <SearchBarGenericAdvancedSearch 
      viewGrid={this.props.viewGrid} 
      filterSmall={this.props.filterSmall}
      popUpOrderState={this.popUpOrderState} 
      textPlaceHolder={this.props.textPlaceHolder}  
      searchBarState={this.searchBarState} typeButton={'documents'} showAZ={this.props.showAZ}/>
    )
  }
}

const mapStateToProps = (state) => ({
  viewList: state.userDocumentReducer.viewList,
  viewGrid: state.userDocumentReducer.viewGrid
})
export default connect(mapStateToProps)(ContainerUserDocumentSearch)