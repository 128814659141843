import React, { useState } from 'react'
import { Table } from 'semantic-ui-react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { ReactComponent as EditImage } from "../../../../assets/icons/clients/edit-button.svg";
import { ReactComponent as Inactive } from '../../../../assets/icons/users/inactive.svg';
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ReactComponent as EnabledButton } from '../../../../assets/icons/clients/enabled-button.svg'
import { getEmployeeEdit } from '../../../../actions/clientActions';
import { Cell, CellOptions } from '../../../../components/Table/CellComponent';
import { UseWindowDimensions } from '../../../../utils/UseWindowsDimentions';
import { refreshStateModule, warningFormModal } from '../../../../actions/modalActions'
import { getUserEdit} from '../../../../actions/usersActions'

const SentCell = styled(Table.Cell)`
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  padding: 10px !important;
  margin-left: 1rem;
`

const Row = styled(Table.Row)`
  height: 50px !important;
  @media only screen and (max-width: 768px) {
    height: auto !important;
    display: flex !important;
    flex-wrap: wrap;
    border-bottom: 1px solid #979e9f !important;
    padding: 0.5em 0em !important; 
  }
`

const Enabled = styled(EnabledButton)`
   margin: 4px 0 0 0 !important;
   
 `

const Edit = styled(EditImage)`
  float: right !important;
  margin: 4px 25px 0 0 !important;
`

export const RowTableUsers = (props) => {
  const [isVisible, setVisible] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  
  const handleChangeActive = () => {
    sessionStorage.setItem("userSerialId", props.data.id)
    const idUser = sessionStorage.getItem("userSerialId") 
    dispatch(getUserEdit({"idUser":idUser}))
    dispatch(warningFormModal({ type: 'warningUpdateUserActiveProviderAndClient' }))
  }

  const handleChangeInactive = () => {
    sessionStorage.setItem("userSerialId", props.data.id)
    const idUser = sessionStorage.getItem("userSerialId")
    dispatch(getUserEdit({"idUser":idUser}))
    dispatch(warningFormModal({ type: 'warningUpdateUserInactiveProviderAndClient' }))
  }

  const handleClick = (e) => {
    e.stopPropagation()
    dispatch(refreshStateModule())
    sessionStorage.setItem("userSerialId", props.data.id)
    const idUser = sessionStorage.getItem("userSerialId")
    dispatch(getUserEdit({"idUser":idUser}))
    history.push('/editEmployee');
  }

  const windowDimensions = UseWindowDimensions();
  return (
    <Row
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      <Cell columnName={props.headerTable[0].name} width={3}  text={props.data.names + ' '+ props.data.surnames}  ></Cell>
      <Cell width={2} columnName={props.headerTable[1].name} text={props.data.document} >  </Cell>
      <Cell width={4} columnName={props.headerTable[2].name} text={props.data.email}></Cell>
      <Cell width={2} columnName={props.headerTable[3].name} text = {props.data.role}></Cell>
      <CellOptions isVisible={isVisible} columnName={props.headerTable[4].name} clickEdit={handleClick}
       active={props.data.status} handleChangeActive={handleChangeActive} handleChangeInactive={handleChangeInactive} width={2}/>
    </Row>
  )
}

RowTableUsers.propTypes = {
  data: PropTypes.object.isRequired
};

export default RowTableUsers