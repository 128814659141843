import React, { useState, useEffect } from 'react'
import { MainSegment } from '../../../../../components/Sengment/MainSegment'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { ReactComponent as LeftArrowIcon } from '../../../../../assets/icons/sent/leftArrow.svg'
import { warningFormModal } from '../../../../../actions/modalActions'
import MessageModal from '../../../../../components/Modal/MessageModal'
import WarningModal from '../../../../../components/FormClientsAndProviders/Modal/WarningModal'
import { useHistory } from 'react-router-dom'
import FormEditEmployee from '../../../../../components/FormClientsAndProviders/FormEmployee/FormEditEmployee'
import EditForm from '../../../../../components/FormClientsAndProviders/FormEmployee/EditForm'
import { getRolesClient } from '../../../../../actions/clientActions'
import { getRolesProvider } from '../../../../../actions/providerActions'

const Container = styled.div`
  margin: 30px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  
`
const Arrow = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
`
const TitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
`
const Title = styled.p`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #133F4F;

`
const LeftArrow = styled.button`
  padding: 0;
  margin: 0 16px 0 0;
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor: pointer;
`
const EditUserEmployee = () => {

  const dispatch = useDispatch()
  const roleActiveEdit = useSelector(state => state.userReducer.userActiveEdit.role)
  const activeFormClient  = useSelector(state => state.clientReducer.activeForm)
  const activeFormProvider  = useSelector(state => state.providerReducer.activeForm)
  const { employeeActiveEdit } = useSelector(state => state.clientReducer)
  const [roleEmployee, setRoleEmployee] = useState([])
  const [role, setRole] = useState([])
  const history = useHistory()

  const handleClick = () => {
    if (activeFormClient === true || activeFormProvider === true) {
      dispatch(warningFormModal({ type: 'warningLeaveUpdate' }))
    } else {
      history.goBack()
    }
  }

  useEffect(() => {
    if(roleActiveEdit === "Notificador"){
      dispatch(getRolesClient()).then(resul => { setRoleEmployee(resul) })
    }else{
      dispatch(getRolesProvider()).then(resul => { setRoleEmployee(resul) })
    }
  }, [roleActiveEdit, dispatch])

  useEffect(() => {
    const dataRole = roleEmployee.map((data) => {
      return { key: data._id, text: data.name, value: data._id }
    })
    dataRole.unshift({ key: '', text: '', value: '' })
    setRole(dataRole)
  }, [roleEmployee])

  return (
    <MainSegment>
      <Container>
        <Arrow>
          <LeftArrow
            onClick={handleClick}
          >
            <LeftArrowIcon />
            <WarningModal type='warningLeaveUpdate' />
          </LeftArrow>
        </Arrow>
        <TitleContainer>
          <Title>Editar usuario</Title>
        </TitleContainer>
      </Container>
      <EditForm role={role}/>
      
    </MainSegment>
  )
}
export default EditUserEmployee