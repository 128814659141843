import {
  GET_GEOGRAPHIC_LIST,
} from '../actions/actionsUtilities/types.js'

const initialState = {
  geographicList: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_GEOGRAPHIC_LIST:
      return {
        ...state,
        geographicList: action.payload
      }
    default:
      return state
  }
}
