import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { List, ListItem, Modal } from 'semantic-ui-react'
import styled, { css } from 'styled-components'

import { changeMessageModal, closewriteNotificationModal, hideMessageModal } from '../../actions/modalActions'
import PrimaryButton from '../PrimaryButton'
import { ReactComponent as ChangeRole } from '../../assets/icons/role/changeRole.svg'

import './messageModal.css'
import { clearEditorChange, cleanWriterFormOnly, chageStateLoadNotifications, chageStateLoadNotificationsList } from "../../actions/writerActions";
import { CHANGE_ROLE_STATE_MODAL } from '../../actions/actionsUtilities/types'

const ModalElement = styled(Modal)`
${props => !!props.modalState.buttonMessage ?
    css`width: 496px !important;`
    :
    css`width: 328px !important;`
  }
  padding: 40px !important;  
`
const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const ModalMessage = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin-top: 32px;

`
const Texto = styled.p`
  color: #133F4F;
  font-family: Roboto;
  font-weight: 900;
  font-size: 17px;
  font-style: normal;
  line-height: 35px;
  text-align: center;
  margin-bottom: 15px !important;
`
const ModalActions = styled.div`
  margin-top: 32px;
`

const MessageModalChangeRole = (props) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (props.modalState.status === false) {
      dispatch(clearEditorChange(false))
    }
    if (props.modalState.status === true && (props.modalState.type === 'clientEdit' || props.modalState.type === 'providerEdit' || props.modalState.type === 'agreementEdit')) {
      setTimeout(() => {
        dispatch(hideMessageModal())
      }, 2000);
    }
  }, [props.modalState.status, props.modalState.type, dispatch])

  useEffect(() => {
    props.actionsRunning?.length === 0 && props.modalState.status? dispatch({ type: CHANGE_ROLE_STATE_MODAL, payload: { type: props.modalState.type } }) : null
  },[props.actionsRunning, dispatch])

  const modalData = (type) => {
    const data = {
      changeRoleError: {
        icon: <ChangeRole />,
        InitialMessage: "¡Por ahora no puedes cambiar de rol!",
        message: `Para poder hacerlo, deberás esperar a que termine el proceso de 
        {{${props.actionsRunning?.length === 0 ? '' : options[props.actionsRunning]}}}`,
        buttonMessage: 'Entendido',
      },
      changeRoleErrorPlusTwoActions: {
        icon: <ChangeRole />,
        InitialMessage: "¡Por ahora no puedes cambiar de rol!",
        message: 'Para poder hacerlo, deberás esperar a que terminen los siguientes procesos en curso:',
        list: props.actionsRunning?.length === 0 ? [] :props.actionsRunning?.map(item => options[item]),
        buttonMessage: 'Entendido',
      },

    }
    return data[type]
  }
  const options = {
    SEND_NOTIFICATION: 'Envío de notificación',
    CREATE_REPORT: 'Crear reporte',
    CREATE_USER: 'Creación de usuario',
    EDIT_USER: 'Edición de usuario',
    CREATE_PROVIDER: 'Creación de operador',
    EDIT_PROVIDER: 'Edición de operador',
    EDIT_CLIENTS: 'Edición de cliente',
    CREATE_CLIENTS: 'Creación de cliente',
    CREATE_AGREEMENT: 'Creación de vinculación',
    EDIT_AGREEMENT: 'Edición de vinculación',
    CREATE_REPORT: 'Creación de reporte',
  }

  const onClose = () => {
    dispatch({ type: CHANGE_ROLE_STATE_MODAL, payload: { type: props.modalState.type } })
  }
  
  const data = modalData(props.modalState.type)
  return (
    <ModalElement
      open={props.modalState.status}
      // size={props.modalState.type === 'errorHyperlinks' || props.modalState.type === 'warningFiles' || props.modalState.type === 'errorBag'?'tiny':'mini'}
      closeOnDimmerClick={props.modalState.type === 'finish' ? false : true}
      // className= {props.modalState.type === 'noAgreementsActive' && 'modalNoAgreements'}
      modaltype={props.modalState.type}
      modalState={data}
    >
      <ModalContent>
      {!!data.icon && data.icon}
        {data.multipleMessages && !!props.modalState.message && !!props.modalState.message.length &&
          props.modalState.message.map((message) => (
            <>
              <ModalMessage>
                {message}
              </ModalMessage>
              <br />
            </>
          ))
        }
        {!data.multipleMessages &&
          <ModalMessage>
            <Texto>{data.InitialMessage}</Texto>
            {!props.modalState.message
              ? data.message
              : props.modalState.message}
          </ModalMessage>
        }
        {!!data.list &&
          <List bulleted>
            {data.list.map(name => <ListItem key={name}>{name}</ListItem>)}
          </List>
        }
        {
          props.modalState.type !== 'deleteDraft' && props.modalState.type !== 'deleteDraftList' && props.modalState.type !== 'finish' && props.modalState.type !== 'entityCreate' && props.modalState.type !== 'inactiveEntity' && props.modalState.type !== 'operatorCreate' && props.modalState.type !== 'entityEdit' && props.modalState.type !== 'clientEdit' && props.modalState.type !== 'employeeCreate' && props.modalState.type !== 'employeeEdit' && props.modalState.type !== 'providerEdit' && props.modalState.type !== 'agreementCreate' && props.modalState.type !== 'agreementEdit' && props.modalState.type !== 'userActive' && props.modalState.type !== 'userInactive' && props.modalState.type !== 'providerInactive' && props.modalState.type !== 'providerActive' && props.modalState.type !== 'clientInactive' && props.modalState.type !== 'clientActive' && props.modalState.type !== 'finishCommentAuditor' && props.modalState.type !== 'agreementCanceleSucces' &&
          <ModalActions>
            <PrimaryButton
              txtBtn={data.buttonMessage}
              onPress={onClose}
            />
          </ModalActions>
        }
      </ModalContent>
    </ModalElement>

  )
}
const mapStateToProps = (state) => ({
  modalState: state.modalReducer.messageModalChangeRole,
  hiredData: state.writerReducer.hiredData,
  actionsRunning: state.authReducer.actionsRunning,
})

const mapDispatchToProps = {
  changeMessageModal,
  clearEditorChange,
  closewriteNotificationModal,
  cleanWriterFormOnly,
  chageStateLoadNotifications,
  chageStateLoadNotificationsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageModalChangeRole)
