import React, { Component } from "react";
import { connect } from "react-redux";
import RowTableReports from "../RowTableReports";
import _ from "lodash";
import moment from 'moment'
import PropTypes from "prop-types";
import GeneralTable from "../../../components/Table/GeneralTable";
import {
  actionClearFilter,
  actionInitialPagination,
  activePagination
} from "../../../actions/filterActions";
import { getReports } from "../../../actions/reportsActions";
import ModalFilterReports from "../../../components/Search/SearchReports/ModalFilterReports";

class AllReports extends Component {
  static propTypes = {
    history: PropTypes.any.isRequired,
  };

  state = {
    loader: true,
    columnData: [
      { name: "Fecha generación", width: 3, arrow: false },
      { name: "Usuario que generó", width: 3, arrow: false },
      { name: "Tipo reporte", width: 3, arrow: false },
      { name: "Fecha inicio", width: 2, arrow: false },
      { name: "Fecha fin", width: 2, arrow: false },
      { name: "Estado", width: 3, arrow: false },
    ],
    init: true,
  };

  componentDidMount() {
    this.makeReportsRequest(this.props.filterReducer)
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.filterReducer, this.props.filterReducer) && !this.state.init) {
      this.makeReportsRequest(this.props.filterReducer);
      this.setState({init: true, loader: true })
    }
  }

  makeReportsRequest = (filterReducer) => {
    const body = {
      count: filterReducer.count,
      pag: filterReducer.pag,
      reportType: filterReducer.reportsFilter.reportStatus,
      sort: this.orderTranslator(filterReducer.sort),
      textToSearch: filterReducer.searchBar[0],
      initialDate: !!filterReducer.searchAdvanced.date.startDate
        ? moment(filterReducer.searchAdvanced.date.startDate).format('YYYY-MM-DD') : '',
      finalDate: !!filterReducer.searchAdvanced.date.endDate
        ? moment(filterReducer.searchAdvanced.date.endDate).format('YYYY-MM-DD') : '',
      reportType: filterReducer.searchAdvanced.reportType
                                                               
    };
    this.props.getReports(body).then(() =>{
      this.props.activePagination(false)
      this.setState({init: false, loader: false })
    })
  };

  orderTranslator = (order) => {

    const options = {
      "masReciente": { _id: -1 },
      "masAntiguo": { _id: 1 },
      "A-Z": { name: 1 },
      "Z-A": { name: -1 },
    };
    return options[order];
  };


  render() {
    return (
      <>
        <GeneralTable
          list={this.props.reportsListArray}
          typeList={this.props.filterReducer.isSearch ? "default" : "reports"}
          listHeight = {'reports'}
          headerTable={this.state.columnData}
          rowComponent={RowTableReports}
          history={this.props.history}
          loader={this.state.loader}
          heigthHeader={'45px'}
        />
        <ModalFilterReports />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  filterReducer: state.filterReducer,
  reportsListArray: state.reportsReducer.reportsList,
  elementsCount: state.reportsReducer.elementsCount,
});

const mapDispatchToProps = {
  actionClearFilter,
  actionInitialPagination,
  getReports,
  activePagination
};

export default connect(mapStateToProps, mapDispatchToProps)(AllReports);
