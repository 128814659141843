import axios from 'axios'

import {
  STATE_CHECKBOK_NOTIFICATION_TEXT_EDITOR,
  STATE_FORM_NOTIFICATION_TEXT_EDITOR,
  STATE_TEXT_EDITOR,
  STATE_FILES_TEXT_EDITOR,
  STATE_FILES_DOCUMENT_SIGNATURE_TEXT_EDITOR,
  CHANGE_WRITE_NOTIFICATION_FORM,
  CHANGE_HEADER_CHECKBOX,
  CHANGE_DATE_PREVIEW,
  CHANGE_WRITE_NOTIFICATION_FORM_ADD,
  SAVE_NOTIFICATION,
  CHANGE_ATTACHED_DOCUMENT_SIGNATURE,
  CHANGE_ATTACHED_DOCUMENT_SIGNATURE_SPECIFIC,
  CHANGE_ATT_BUTTON,
  CLEAN_WRITER_fORM,
  CLEAR_EDITOR_CHANGE,
  CHANGE_COLOR_TEXT_EDITOR,
  CHANGE_STATE_MESSAGE_MODAL,
  GET_RECIPIENT,
  HISTORY_RECIPIENT,
  ADD_MASSIVE_RECIPIENTS_LIST,
  SET_MASSIVE_RECIPIENTS_LIST_FILE_INFO,
  CLOSE_LOAD_MASSIVE_RECIPIENTS_LIST,
  UPDATE_RECIPIENT_DISPLAY,
  HISTORY_RECIPIENT_MASSIVE,
  LOADING_SEND_NOTIFICATION,
  ADD_MASSIVE_RECIPIENTS_FILE_INFO,
  FILTER_MASSIVE_RECIPIENTS,
  IS_MASSIVE_RECIPIENT,
  REPLACE_RECIPIENT_DISPLAY,
  LOADING_SEND_NOTIFICATION_MASSIVE,
  SAVE_NOTIFICATION_MASSIVE_SUCCESS,
  WRITE_NOTIFICATION_MODAL_CLOSE,
  SAVE_HIRED,
  CHANGE_TEXT_EDITOR,
  CHANGE_STATE_VIEW_WRITTER,
  INACTIVE_STATE_FORM_NOTIFICATION,
  ACTIVE_STATE_FORM_NOTIFICATION,
  SET_FILE_SERIAL_ID,
  GET_ATTRIBUTE_LIST,
  SET_FIELD_PARAMETRIZATION,
  SAVE_FILE_MASSIVE,
  STATE_NOTIFICATION_PROGRESS,
  CHANGE_STATE_LOAD_CITIZENS,
  CHANGE_STATE_LOAD_NOTIFICATIONS,
  CHANGE_STATE_LOAD_CITIZENS_LIST,
  CHANGE_STATE_LOAD_NOTIFICATIONS_LIST,
  CLEAN_WRITER_FORM_ONLY,
  DELETE_ITEM_FOR_MASSIVE,
  DELETE_LOAD_CITIZEN_ITEM,
  CLEAR_DATA_OF_DRAFT,
  CREATE_NEW_TAGS,
  UPDATE_FILE_SERIAL_ID,
  CHANGE_STATE_SEND_EMAIL,
  CHANGE_DISABLED_RECIPIENT,
  DELETE_RECIPIENTS_FROM_MASSIVE,
  DOWNLOADED_USERS_GUIDE,
} from "./actionsUtilities/types.js"
import { API_ENDPOINT } from "../config/config"
import {SEND_NOTIFICATION_MULTIPLE, SEND_NOTIFICATION_V2, CANCEL_MASSIVE_SEND_V3, 
  SEND_NOTIFICATION_MASSIVE_V4, GET_NOTIFICATIONS_PROGRESS_V2, GET_NOTIFICATIONS_AVAILABLE_FOR_USER_V2, 
  DELETE_MASSIVE_NOTIFICATION_RECORD_V2, SEND_FILE_MASSIVE_V2,GET_ATTRIBUTE_LIST_ROUTE_V2, GET_FIND_RECIPIENT_V2, SEND_NOTIFICATION, DOWNLOAD_USERS_GUIDE } from './actionsUtilities/actionsRoutes'
import { deleteFromListActions, reasonDispatch, updateListActions } from './DispatchGeneric.js'
import { changeMessageModalTwo } from './modalActions.js'
import { decrypt, encrypt } from '../helpers/cypher'
import { getHeaders } from '../helpers/globalHelpers.js'

export const saveNotification = (body) => async (dispatch) => {
  const encryptedBody = await encrypt(body);
  var saved = false;
  dispatch({
    type: LOADING_SEND_NOTIFICATION,
    payload: true
  })
  await axios.post(
    `${API_ENDPOINT + SEND_NOTIFICATION}`,
    {'data':encryptedBody}, { headers: getHeaders() }
  ).then(async res => {
    const decryptedData = await decrypt(res.data.result);
    dispatch({
      type: SAVE_NOTIFICATION,
      payload: decryptedData
    })
    clearEditorChange(true)
    dispatch({
      type: LOADING_SEND_NOTIFICATION,
      payload: false
    })
    dispatch({
      type: CHANGE_STATE_MESSAGE_MODAL,
      payload: { type: 'finish' }
    })
    saved = true;
  }).catch((err) => {
    console.error(err)
      dispatch({
        type: CHANGE_STATE_MESSAGE_MODAL,
        payload: { type: 'error' }
      })
    
    dispatch({ type: LOADING_SEND_NOTIFICATION, payload: false })
  })
  if (saved) {
    await timeout(2000);
    dispatch({
      type: CHANGE_STATE_MESSAGE_MODAL,
      payload: { type: 'finish' }
    })
    dispatch({ type: WRITE_NOTIFICATION_MODAL_CLOSE })
  }
  return saved;
}

export const saveNotificationOne = (body) => async (dispatch) => {
  dispatch(updateListActions('SEND_NOTIFICATION'))
  const encryptedBody = await encrypt(body);
  var saved = false;
  dispatch({
    type: LOADING_SEND_NOTIFICATION,
    payload: true
  })
  await axios.post(
    `${API_ENDPOINT + SEND_NOTIFICATION_V2}`,
    {'data': encryptedBody}, { headers: getHeaders() }
  ).then(async res => {
    const decryptedData = await decrypt(res.data.result);
    dispatch({
      type: SAVE_NOTIFICATION,
      payload: decryptedData
    })
    clearEditorChange(true)
    dispatch({
      type: LOADING_SEND_NOTIFICATION,
      payload: false
    })
    dispatch({
      type: CHANGE_STATE_MESSAGE_MODAL,
      payload: { type: 'finish' }
    })
    dispatch({
      type: CLEAR_DATA_OF_DRAFT,
    })
    dispatch(deleteFromListActions('SEND_NOTIFICATION'))
    saved = true;
  }).catch((err) => {
    console.error(err)
    dispatch({
      type: CHANGE_STATE_MESSAGE_MODAL,
      payload: { type: 'error' }
    })
    dispatch({
      type: LOADING_SEND_NOTIFICATION,
      payload: false
    })
    dispatch(deleteFromListActions('SEND_NOTIFICATION'))
  })
    if (saved) {
    await timeout(2000);
    dispatch({
      type: CHANGE_STATE_MESSAGE_MODAL,
      payload: { type: 'finish' }
    })
    dispatch({ type: WRITE_NOTIFICATION_MODAL_CLOSE })
  }
  return saved;
}

export const saveNotificationMultiple = (body) => async (dispatch) => {
  dispatch(updateListActions('SEND_NOTIFICATION'))
  const encryptedBody = await encrypt(body);
  var saved = false;
  dispatch({
    type: LOADING_SEND_NOTIFICATION,
    payload: true
  })
  await axios.post(
    `${API_ENDPOINT + SEND_NOTIFICATION_MULTIPLE}`, {data: encryptedBody}, { headers: getHeaders() }
  ).then(async res => {
    const decryptedData = await decrypt(res.data.result);
    dispatch({
      type: SAVE_NOTIFICATION,
      payload: decryptedData
    })
    clearEditorChange(true)
    dispatch({
      type: LOADING_SEND_NOTIFICATION,
      payload: false
    })
    dispatch({
      type: CHANGE_STATE_MESSAGE_MODAL,
      payload: { type: 'finish' }
    })
    dispatch({
      type: CLEAR_DATA_OF_DRAFT,
    })
    saved = true;
    dispatch(deleteFromListActions('SEND_NOTIFICATION'))
  }).catch((err) => {
    console.error(err)
    dispatch({
      type: CHANGE_STATE_MESSAGE_MODAL,
      payload: { type: 'error' }
    })
    dispatch({
      type: LOADING_SEND_NOTIFICATION,
      payload: false
    })
    dispatch(deleteFromListActions('SEND_NOTIFICATION'))
  })
  
  if (saved) {
    await timeout(2000);
    dispatch({
      type: CHANGE_STATE_MESSAGE_MODAL,
      payload: { type: 'finish' }
    })
    dispatch({ type: WRITE_NOTIFICATION_MODAL_CLOSE })
  }
  return saved;
}

function timeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const saveNotificationMassiveV2 = (body) => async (dispatch) => {
  const encryptedBody = await encrypt(body);
  await axios.post(
    `${API_ENDPOINT + SEND_NOTIFICATION_MASSIVE_V4}`, {data: encryptedBody}, { headers: getHeaders() }
  ).then(async res => {
    const decryptedData = await decrypt(res.data.result);
    dispatch({
      type: SAVE_NOTIFICATION_MASSIVE_SUCCESS,
      payload: decryptedData
    })
    dispatch({
      type: CLEAR_DATA_OF_DRAFT
    })
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
    updateSendNotificationMassiveLoadingModal('abortSend', true)
    await clearEditorChange(true)
    await cleanWriterForm()
  })
}

export const openSendNotificationMassive = () => async (dispatch) => {
  dispatch({
    type: LOADING_SEND_NOTIFICATION_MASSIVE,
    payload: { key: 'status', value: true }
  })
}

export const setFileSerialId = (id) => async (dispatch) => {
  dispatch({
    type: SET_FILE_SERIAL_ID,
    payload: id
  })
  dispatch({
    type: UPDATE_FILE_SERIAL_ID,
    payload: id
  })
}

export const updateSendNotificationMassiveLoadingModal = (key, value) => async (dispatch) => {
  dispatch({
    type: LOADING_SEND_NOTIFICATION_MASSIVE,
    payload: {
      key,
      value
    }
  })
} 

export const closeSendNotificationMassive = () => async (dispatch) => {
  dispatch({
    type: LOADING_SEND_NOTIFICATION_MASSIVE,
    payload: { key: 'status', value: false }
  })
}

export const getRecipientv2 = (body) => async (dispatch) => {
  const encryptedBody = await encrypt(body);
  dispatch({
    type: ACTIVE_STATE_FORM_NOTIFICATION
  })
  await axios.post(
    `${API_ENDPOINT + GET_FIND_RECIPIENT_V2}`,
    {'data': encryptedBody}, { headers: getHeaders() }
  ).then(async res => {
    const decryptedData = await decrypt(res.data.result);
    dispatch({
      type: GET_RECIPIENT,
      payload: decryptedData
    })
    dispatch({
      type: CHANGE_DISABLED_RECIPIENT,
      payload: false
    })
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
    dispatch({
      type: GET_RECIPIENT,
      payload: {}
    })
    dispatch({
      type: CHANGE_DISABLED_RECIPIENT,
      payload: false
    })
  })
}

export const changeWriteNotificationForm = (key, value) => async (dispatch) => {
  dispatch({
    type: CHANGE_WRITE_NOTIFICATION_FORM,
    payload: {
      key,
      value
    }
  })
}

export const checkboxNotificationHeader = (state) => async dispatch => {
  dispatch({
    type: STATE_CHECKBOK_NOTIFICATION_TEXT_EDITOR,
    payload: [
      state
    ]
  })
}

export const formActionNotification = (currentValues, matter) => async dispatch => {
  dispatch({
    type: STATE_FORM_NOTIFICATION_TEXT_EDITOR,
    payload: {
      currentValues,
      matter
    }
  })
}

export const textEditorAction = (state) => async dispatch => {
  dispatch({
    type: STATE_TEXT_EDITOR,
    payload: state
  })
}

export const fileTextEditor = (state) => async dispatch => {
  dispatch({
    type: STATE_FILES_TEXT_EDITOR,
    payload: state
  })
}

export const documentSignatureAction = (state) => async dispatch => {
  dispatch({
    type: STATE_FILES_DOCUMENT_SIGNATURE_TEXT_EDITOR,
    payload: state
  })
}


export const changeWriteNotificationFormAdd = (key, value) => async (dispatch) => {
  dispatch({
    type: CHANGE_WRITE_NOTIFICATION_FORM_ADD,
    payload: {
      key,
      value
    }
  })
}

export const changeHeaderCheckbox = (value) => async (dispatch) => {
  dispatch({
    type: CHANGE_HEADER_CHECKBOX,
    payload: value
  })
}

export const changeDatePreview = (value) => async (dispatch) => {
  dispatch({
    type: CHANGE_DATE_PREVIEW,
    payload: value
  })
}

export const changeAttachedDocSign = (key, value) => async (dispatch) => {
  dispatch({
    type: CHANGE_ATTACHED_DOCUMENT_SIGNATURE,
    payload: { key, value }
  })
}

export const changeAttachedDocSignSpecific = (key, value) => async (dispatch) => {
  dispatch({
    type: CHANGE_ATTACHED_DOCUMENT_SIGNATURE_SPECIFIC,
    payload: { key, value }
  })
}

export const changeAttButton = (value) => async (dispatch) => {
  dispatch({
    type: CHANGE_ATT_BUTTON,
    payload: value
  })
}

export const cleanWriterForm = () => async (dispatch) => {
  dispatch({
    type: CLEAN_WRITER_fORM
  })
}

export const clearEditorChange = (value) => async (dispatch) => {
  dispatch({
    type: CLEAR_EDITOR_CHANGE,
    payload: value
  })
}

export const changeColorTextEditor = (value) => async (dispatch) => {
  dispatch({
    type: CHANGE_COLOR_TEXT_EDITOR,
    payload: value
  })
}


export const warningModal = () => async (dispatch) => {
  dispatch({
    type: CHANGE_STATE_MESSAGE_MODAL,
    payload: { type: 'warning' }
  })
}

export const updateHistory = (value) => async (dispatch) => {
  dispatch({
    type: HISTORY_RECIPIENT,
    payload: value
  })
}

export const updateHistoryMassive = (value) => async (dispatch) => {
  dispatch({
    type: HISTORY_RECIPIENT_MASSIVE,
    payload: value
  })
}

export const addMassiveRecipientsList = (value) => async (dispatch) => {
  dispatch({
    type: ADD_MASSIVE_RECIPIENTS_LIST,
    payload: value
  })
}

export const addMassiveRecipientsFileInfo = (value) => async (dispatch) => {
  dispatch({
    type: ADD_MASSIVE_RECIPIENTS_FILE_INFO,
    payload: value
  })
}

export const setMassiveRecipientFileInfo = (value) => async (dispatch) => {
  dispatch({
    type: SET_MASSIVE_RECIPIENTS_LIST_FILE_INFO,
    payload: value,
  })
}

export const filterMassiveRecipients = (value) => async (dispatch) => {
  dispatch({
    type: FILTER_MASSIVE_RECIPIENTS,
    payload: value
  })
}

export const isMassiveRecipient = (value) => async (dispatch) => {
  dispatch({
    type: IS_MASSIVE_RECIPIENT,
    payload: value,
  })
}

export const updateRecipientDisplay = (value) => async (dispatch) => {
  dispatch({
    type: UPDATE_RECIPIENT_DISPLAY,
    payload: value
  })
}

export const replaceRecipientDisplay = (value) => async (dispatch) => {
  dispatch({
    type: REPLACE_RECIPIENT_DISPLAY,
    payload: value
  })
}

export const closeLoadMassiveRecipients = () => async (dispatch) => {
  dispatch({
    type: CLOSE_LOAD_MASSIVE_RECIPIENTS_LIST
  })
}

export const saveHiredV2 = () => async (dispatch) => {
  await axios.get(
    `${API_ENDPOINT + GET_NOTIFICATIONS_AVAILABLE_FOR_USER_V2}`, { headers: getHeaders() }
  ).then(async res => {
    const decryptedData = await decrypt(res.data.result);
    dispatch({
      type: SAVE_HIRED,
      payload: {hiredData:decryptedData, availableQuantity: decryptedData.availableQuantity}
    })
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
  })
}

export const changeTextEditor = (value) => async (dispatch) => {
  dispatch({
    type: CHANGE_TEXT_EDITOR,
    payload: value
  })
  dispatch({
    type: ACTIVE_STATE_FORM_NOTIFICATION
  })
}
export const noAgreementsActive = () => async (dispatch) => {
  dispatch({
    type: CHANGE_STATE_MESSAGE_MODAL,
    payload: { type: 'noAgreementsActive' }
  })
}

export const changeViewState = () => async (dispatch) =>{
  dispatch({
    type: CHANGE_STATE_VIEW_WRITTER
  })

}

export const activeFormProvider = () => async dispatch => {
  dispatch({
    type: ACTIVE_STATE_FORM_NOTIFICATION
  })
}

export const inactiveFormProvider = () => async dispatch => {
  dispatch({
    type: INACTIVE_STATE_FORM_NOTIFICATION
  })
}

export const getAttributeListV2 = () => async (dispatch) => {
  await axios.get(
    `${API_ENDPOINT+GET_ATTRIBUTE_LIST_ROUTE_V2}`, { headers: getHeaders() }
  ).then(async res => {
    const decryptedData = await decrypt(res.data.result);
    if (res.status === 200) {
      dispatch({
        type: GET_ATTRIBUTE_LIST,
        payload: decryptedData.tagUsageList
      })
    }
  }).catch((error) => {
    console.error(error)
  })
}

export const setFieldParametrization = (key,value, index) => async (dispatch) => {
  dispatch({
    type: SET_FIELD_PARAMETRIZATION,
    payload: { key, value, index }
  })
}

export const consultNotificationsProgressV2 = (body) => async dispatch =>{
  const encryptedBody = await encrypt(body);
  await axios.post(
    `${API_ENDPOINT + GET_NOTIFICATIONS_PROGRESS_V2}`, {data:encryptedBody}, { headers: getHeaders() }
  ).then(async res => {
    const decryptedData = await decrypt(res.data.result);
    dispatch({
      type: STATE_NOTIFICATION_PROGRESS,
      payload: decryptedData
    })
  }).catch(async (reason)=>{
    if(reason.response.status === 422){
      dispatch({
        type: STATE_NOTIFICATION_PROGRESS,
        payload: {progress:[]}
      })
    }
    await reasonDispatch(dispatch, reason, false)
  })
}

export const sendMassiveFileV2 = (body) => async (dispatch) => {
  const encryptedBody = await encrypt(body);
  return await axios.post(
    `${API_ENDPOINT + SEND_FILE_MASSIVE_V2}`, {'data': encryptedBody}, { headers: getHeaders() }
  ).then(async res => {
    const decryptedData = await decrypt(res.data.result);
    dispatch({
      type: SAVE_FILE_MASSIVE,
      payload: decryptedData
    })
    return decryptedData
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
    dispatch({
      type: SAVE_FILE_MASSIVE,
      payload: {}
    })
    const decryptedData = await decrypt(reason.response.data.result);
    return decryptedData
  })
} 


export const consultNotificationsProgress = (body) => async dispatch =>{
  const encryptedBody = await encrypt(body);
  await axios.post(
    `${API_ENDPOINT + GET_NOTIFICATIONS_PROGRESS_V2}`, {'data':encryptedBody}, { headers: getHeaders() }
  ).then(async res => {
    const decryptedData = await decrypt(res.data.result);
    dispatch({
      type: STATE_NOTIFICATION_PROGRESS,
      payload: decryptedData
    })

  }).catch(async (reason)=>{
    if(reason.response.status === 422){
      dispatch({
        type: STATE_NOTIFICATION_PROGRESS,
        payload: {progress:[]}
      })
    }
    await reasonDispatch(dispatch, reason, false)
  })
}

export const chageStateLoadCitizens = (status) => async dispatch =>{
  dispatch({type: CHANGE_STATE_LOAD_CITIZENS, payload: status})
}

export const chageStateLoadNotifications = (status) => async dispatch =>{
  dispatch({type: CHANGE_STATE_LOAD_NOTIFICATIONS, payload: status})
}

export const chageStateLoadCitizensList = (status) => async dispatch =>{
  dispatch({type: CHANGE_STATE_LOAD_CITIZENS_LIST, payload: status})
}

export const chageStateLoadNotificationsList = (status) => async dispatch =>{
  dispatch({type: CHANGE_STATE_LOAD_NOTIFICATIONS_LIST, payload: status})
}

export const cleanWriterFormOnly = () => async (dispatch) => {
  dispatch({
    type: CLEAN_WRITER_FORM_ONLY 

  })
}

export const deleteItemForMassive = () => async (dispatch) => {
  dispatch({
    type: DELETE_ITEM_FOR_MASSIVE
  })
}

export const cancelMassiveSend = (body) => async (dispatch) =>{
  const encryptedBody = await encrypt(body);
  await axios.post(
    `${API_ENDPOINT + CANCEL_MASSIVE_SEND_V3}`, {'data':encryptedBody}, { headers: getHeaders() }
  ).then(res => {
    dispatch(changeMessageModalTwo({type: 'massiveSendCanceled', message: '¡Envío cancelado!'}))
  }).catch(async reason =>{
    await reasonDispatch(dispatch, reason, false)
  })
}

export const cancelMassiveSendV2 = (body) => async (dispatch) =>{
  const encryptedBody = await encrypt(body);
  await axios.post(
    `${API_ENDPOINT + CANCEL_MASSIVE_SEND_V3}`, {'data': encryptedBody}, { headers: getHeaders() }
  ).then(async res => {
    dispatch(changeMessageModalTwo({
      type: 'massiveSendCanceled', 
      message: ['¡Envío cancelado!',`Eliminando el registro de la notificación masiva del componente "Enviando notificaciones`] }))
    setTimeout(() => {
      dispatch(changeMessageModalTwo({type: 'massiveSendCanceled'}))
      
    }, 2000)
  }).catch(async reason =>{
    await reasonDispatch(dispatch, reason, false)
  })
}

export const deleteLoadCitizenItem = (index) => async (dispatch) =>{
  dispatch({
    type: DELETE_LOAD_CITIZEN_ITEM,
    payload: index
  })

}

export const deleteLoadNotificationItemV2 = (body) => async (dispatch) =>{
  const encryptedBody = await encrypt(body);
  await axios.post(
    `${API_ENDPOINT + DELETE_MASSIVE_NOTIFICATION_RECORD_V2}`, {'data': encryptedBody}, { headers: getHeaders() }
  ).then(res => {
    
  }).catch(async reason =>{
    await reasonDispatch(dispatch, reason, false)
  })

}

export const createNewParametrizationTags = (body) => async (dispatch) => {
  dispatch({
    type: CREATE_NEW_TAGS,
    payload: body
  })
}

export const changeIsSendEmail = (status) => async dispatch =>{
  dispatch({type: CHANGE_STATE_SEND_EMAIL, payload: status})
}

export const changeDisabledGetRecipient = (status) => async dispatch =>{
  dispatch({type: CHANGE_DISABLED_RECIPIENT, payload: status})
}

export const deleteRecipientsFormMasive = () => async dispatch =>{
  dispatch({type: DELETE_RECIPIENTS_FROM_MASSIVE})
}

export const downloadUsersGuide = () => async (dispatch) => {
  const headers = getHeaders()
  try {
    const response = await axios.get(`${API_ENDPOINT + DOWNLOAD_USERS_GUIDE}`, { headers })
    if (response.status === 200) {
      dispatch({
        type: DOWNLOADED_USERS_GUIDE,
        payload: response.data.result.url
      })
    }
    return response.data.result.url
  } catch (reason) {
    reasonDispatch(dispatch, reason, false)
    return reason.response.status
  }

}