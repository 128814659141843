import cogoToast from "cogo-toast"

import { defaultResponses } from "../config/config"
import { DELETE_FROM_ACTION_LIST, FORM_ERROR, GET_ERRORS, UPDATE_ACTIONS_LIST } from "./actionsUtilities/types.js"
import { toastOptions, toastOptionsTime } from "../helpers/globalHelpers"
import { decrypt } from "../helpers/cypher"
//import { checkLoginStatus } from "./authActions.js"

export const reasonDispatch = async (dispatch, reason, toast = true) => {
  if (reason.response && reason.response.data && reason.response.data.result) {
    const res = reason.response
    const resultDecypher = await decrypt(reason.response.data.result)
    let result = !!resultDecypher ? resultDecypher : reason.response.data.result
    switch (res.status) {
      case 400:
        if (toast) cogoToast.info(result.name || (result.suppressedEmails && result.suppressedEmails.message) || (result.errors && result.errors.error) || result || 'Error', toastOptionsTime)
        if (!result) console.error(result)
        // if (!!result.internalCode && (result.internalCode === 'PIC')) {
        //   dispatch(checkLoginStatus())
        // }
        if (result) {
          dispatch({
            type: FORM_ERROR,
            payload: result
          })
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: !!res
              ? res.data.result
              : { message: defaultResponses.noResponseFromApi }
          })
        }
        break
      case 422:
        if (!!result.message && result.message === "El refreshToken es inválido o ya expiró") {
          dispatch({
            type: GET_ERRORS,
            payload: { statusCode: 600 }
          })
          return;
        }
        // if (!!result.internalCode && (result.internalCode === 'UPI' || result.internalCode === 'UNF')) {
        //   dispatch(checkLoginStatus())
        // }
        if (toast) cogoToast.error(result.name || (result.suppressedEmails && result.suppressedEmails.message) || (result.errors && result.errors.error) || 'Error en el formulario.', toastOptions)
        if (result) {
          dispatch({
            type: FORM_ERROR,
            payload: result
          })
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: !!res
              ? res.data
              : { message: defaultResponses.noResponseFromApi }
          })
        }
        break
      case 413:
        dispatch({
          type: FORM_ERROR,
          payload: { 'error': res.statusText }
        })
        break
      case 401:
        dispatch({
          type: GET_ERRORS,
          payload: { ...result, statusCode: result.codeValidate }
        })
        break
      case 404:
        if (res.data.result.internalCode === 'UNF') {
          dispatch({
            type: FORM_ERROR,
            payload: res.data.result
          })
        }else{
          dispatch({
            type: GET_ERRORS,
            payload: !!res
              ? result
              : { message: defaultResponses.noResponseFromApi }
          })
        }
        break
      default:
        dispatch({
          type: GET_ERRORS,
          payload: !!res
            ? result
            : { message: defaultResponses.noResponseFromApi }
        })
        break
      case 500:
        if (toast) cogoToast.info(result.name || (result.suppressedEmails && result.suppressedEmails.message) || (result.errors && result.errors.error) || result || 'Error', toastOptionsTime)
        if (!result) console.error(result)
        if (result.errors) {
          dispatch({
            type: FORM_ERROR,
            payload: result.errors
          })
        } else if (result.formErrors) {
          dispatch({
            type: FORM_ERROR,
            payload: result.formErrors
          })
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: !!res
              ? result
              : { message: defaultResponses.noResponseFromApi }
          })
        }
    }
  } else {
    if (reason.statusCode === 200 && reason.result.success === false) {
      dispatch({
        type: FORM_ERROR,
        payload: reason.result
      })
    }
    if (reason.statusCode === 500 && reason.result.success === false) {
      dispatch({
        type: FORM_ERROR,
        payload: reason.result
      })
    }
    if (toast) cogoToast.error('Error', toastOptions)
    console.error(reason)
  }
}

export const updateListActions = (action) => async (dispatch) => {
  dispatch({type:UPDATE_ACTIONS_LIST, payload:action})
}

export const deleteFromListActions = (action) => async (dispatch) => {
  dispatch({type:DELETE_FROM_ACTION_LIST, payload:action})
}
