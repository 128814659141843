import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import GeneralTable from '../../../../../components/Table/GeneralTable'
import RowTableImproperClientsDetail from '../RowTableImproperClientsDetail'
import {
  actionClearFilter,
  actionInitialPagination,
  actionFilterImproperClients,
} from "../../../../../actions/filterActions";
import { getImproperClientDetail } from '../../../../../actions/improperClientsActions'
import { activePagination } from '../../../../../actions/filterActions'

class AllImproperClientsDetail extends Component {


  state = {
    loader: true,
    columnData: [
      { name: "Cédula destinatario ", width: 3, arrow: false },
      { name: "Asunto", width: 5, arrow: false },
      { name: "Comentario del auditor", width: 5, arrow: false },
    ],initialState: true,
  }

  componentDidMount() {
    this.props.actionClearFilter()
    this.makeImproperClientsDetailRequest(this.props.filterReducer, this.state.initialState)
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.filterReducer, this.props.filterReducer)) {
      if(!this.state.initialState){
        this.makeImproperClientsDetailRequest(this.props.filterReducer)
        this.setState({init: true, loader: true })
      }
    }
  }

  makeImproperClientsDetailRequest = (filterReducer, initialState = false) => {
    const body = {
      count: filterReducer.count,
      pag: filterReducer.pag,
      sort: this.orderTranslator(filterReducer.sort),
      idClient: this.props.idClient,
      textToSearch: filterReducer.searchBar[0]
    }
    this.props.getImproperClientDetail(body).then(() => {
      this.props.activePagination(false)
      this.setState({ loader: false, initialState: false })
    })
  }

  orderTranslator = (order) => {
    const options = {
      'masReciente': { "_id": -1 },
      'masAntiguo': { "_id": 1 },
      'A-Z': { "firstName": 1 },
      'Z-A': { "firstName": -1 }
    }
    return options[order]
  }

  render() {
    return (
      <GeneralTable
        list={this.props.improperClientsDetailArray}
        typeList="improperClients"
        headerTable={this.state.columnData}
        rowComponent={RowTableImproperClientsDetail}
        history={this.props.history}
        loader={this.state.loader}
      />
    )
  }
}

AllImproperClientsDetail.propTypes = {
  getEmployees: PropTypes.func.isRequired,
  actionClearFilter: PropTypes.func,
  actionInitialPagination: PropTypes.func,
  employees: PropTypes.object.isRequired,
  employeesCount: PropTypes.object.isRequired,
  filterReducer: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  employees: state.clientReducer.employees,
  employeesCount: state.clientReducer.employeesCount,
  filterReducer: state.filterReducer,
  improperClientsDetailArray: state.improperClientsReducer.improperClientDetail.notifications,
})

const mapDispatchToProps = {
  actionClearFilter,
  actionInitialPagination,
  actionFilterImproperClients,
  getImproperClientDetail,
  activePagination,
}

export default connect(mapStateToProps, mapDispatchToProps)(AllImproperClientsDetail)