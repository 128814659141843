import React from 'react'
import PropTypes from 'prop-types'
import ContainerSearchClientsAndProviders from '../../../components/Search/SearchClientsAndProviders/ContainerSearchClientsAndProviders';
import AllClients from './AllClients'
import styled from 'styled-components';
import Paginacion from '../../../components/Search/Paginacion';
import WarningModal from '../../../components/FormClientsAndProviders/Modal/WarningModal'
import MessageModal from '../../../components/Modal/MessageModal';
import { useSelector } from 'react-redux';

const DivFooter = styled.div`
  display: none;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 2% 0;
  @media only screen and (max-width: 1024px) {
    display: flex;
  }
`
const SuperAdminClients = (props) => {
  const clients = useSelector(state => state.clientReducer.clients)
  return (
    <>
      <ContainerSearchClientsAndProviders 
        textPlaceHolder={'Buscar' } 
        buttonType= {'client'} 
        text={'Nuevo cliente'} 
        showAZ={true}
        showButton={true} 
        showFilters={true}
        showExportButton={true}
        disabledExportButton={!clients.length > 0} 
        reportType={"LIST_CLIENTS"} 
        providerType={"CLIENT"}
        showAdvancedSearch={false}
      />
      <WarningModal/>
      
      <AllClients
        history={props.history}
      />
    </>
  )
}

SuperAdminClients.propTypes = {
  history: PropTypes.any.isRequired,
};

export default SuperAdminClients