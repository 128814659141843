import React from 'react'
import { Table } from 'semantic-ui-react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import moment from "moment/moment";
import { Cell } from '../../../components/Table/CellComponent';

const Row = styled(Table.Row)`
  cursor: pointer !important;
  height: 50px !important;
  padding-right: 2px;
  @media only screenand (max-width: 1500px) {
      padding-right: 0px;
  }
  @media only screen and (max-width: 768px) {
    height: auto !important;
    display: flex !important;
    flex-direction: column;
    border-bottom: 1px solid #979E9F !important;
    padding: 0.5em 0em!important; 
    box-sizing: content-box;
  }
  ${props => props.rowcolor && css`
    color: ${props.rowcolor}
  `}
`

const RowTableAgreementsOperator = (props) => {

  const colorRow = (agreementStatus) => {
    if (agreementStatus === 'EXPIRED') {
      return `rgba(60, 60, 59, 0.3)`
    }
    return `#3C3C3B`
  }

  const redirectToAgreementDetail = () => {
    props.history.push({ pathname: '/agreementsDetail/' + props.data.id })
  }

  const formatDate = (userDocumentDate) => moment(userDocumentDate).format('DD/MM/YYYY')
  return (
    <Row rowcolor={colorRow(props.data.status)} onClick={redirectToAgreementDetail}>
      <Cell width={2} columnName={props.headerTable[0].name} text={props.data.agreementCode}></Cell>
      <Cell width={3} columnName={props.headerTable[1].name} text={props.data.nameEntity}></Cell>
      <Cell width={3} columnName={props.headerTable[2].name} text={formatDate(props.data.subscriptionDate)}></Cell>
      <Cell width={3} columnName={props.headerTable[3].name} text={formatDate(props.data.startDate)}></Cell>
      <Cell width={3} columnName={props.headerTable[4].name} text={formatDate(props.data.endDate)}></Cell>
      <Cell width={3} columnName={props.headerTable[5].name} text={props.data.statusName} fontWeight={props.data.statusName === 'En curso' ? '600' : '100'}></Cell>
    </Row>
  )
}

RowTableAgreementsOperator.propTypes = {
  data: PropTypes.object.isRequired
};

export default RowTableAgreementsOperator