import styled from "styled-components";
import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";
import React, { useState } from "react";
import moment from "moment/moment";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const SentCell = styled(Table.Cell)`
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  max-width: 1px !important;
  padding: 10px !important;
`;

const Row = styled(Table.Row)`
  cursor: pointer !important;
`;

const ButtonEdit = styled.button`
  background: #FFFFFF;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  padding: 8px 8px;
  margin: 0 4px  0 4px;
  height:  32px;
  
  @media only screen and (max-width: 1366px) and (min-width: 1025px)  {
    svg{
      width: 100 !important;
      height: 16;
      fill: none;
    }
    
    @media only screen and (min-width: 1800px)  {
      width: 83.18px;
}
`
const ContainerErrorReportsHigh = styled.div`
  width: max-content;
  padding: 0px 4px 0px 4px;
  border-radius: 4px;
  border: 1px solid #BA3555;
  color:  #BA3555;
  font-weight: 500;
  font-size: 12px;
`;

const ContainerErrorReports = styled.div`
  width: max-content;
  padding: 0px 4px 0px 4px;
  border-radius: 4px;
  border: 1px solid #B2B2B2;
  color:  #B2B2B2;
  font-weight: 500;
  font-size: 12px;
`;

const StylePorcent = styled.span`
  font-weight: 900;
`;

const ContainerAuditReview = styled.span`
`;

const StyleAuditReviewLink = styled.span`
  font-weight: 400;
  color: #22C1D0;
  text-decoration: underline;
`;

const RowTableAuditorImproperClients = (props) => {
  const dispatch = useDispatch();
  const formatDate = (dateToTransform ) => {
    return moment(dateToTransform).utc().format('DD/MM/YYYY')
  }

  const redirectToImproperClientsDetail = () => {
    sessionStorage.setItem('improperClientID', props.data.companySerialID )
    props.history.push({ pathname: '/improperClientsDetail/' + props.data._id})
  };

  return (
    <Row
      onClick={redirectToImproperClientsDetail}
    >
      <SentCell width={3}>{props.data.clientName}</SentCell>
      <SentCell width={2}>{props.data.numberDocumentCompany}</SentCell>
      <SentCell width={2}>{props.data.phone}</SentCell>
      <SentCell width={3}>{props.data.email}</SentCell>
      <SentCell width={3}>{props.data.managerName}</SentCell>
      <SentCell width={3}>
        {
        parseInt(props.data.realPercentage.substring(0,(props.data.realPercentage.search("%")))) >= 80 && !props.data.statusReviewAuditor?
        <ContainerErrorReportsHigh>
          <StylePorcent>{props.data.realPercentage}</StylePorcent>
          {props.data.calculatePercentage}
        </ContainerErrorReportsHigh>
      :
        <ContainerErrorReports>
          <StylePorcent>{props.data.realPercentage}</StylePorcent>
          {props.data.calculatePercentage}
        </ContainerErrorReports>
        }
        
      </SentCell>
    </Row>
  );
};

RowTableAuditorImproperClients.propTypes = {
  data: PropTypes.object.isRequired,
};

export default RowTableAuditorImproperClients;