import React from 'react'
import {connect} from 'react-redux'
import {Popup} from 'semantic-ui-react'
import styled from 'styled-components'

import {actionOrder} from '../../actions/filterActions'
import ButtonIcon from '../../components/Button/IconButton'
import {ReactComponent as OrderIcon} from '../../assets/icons/Order.svg'
import {ReactComponent as Reviewed} from '../../assets/icons/reviewed.svg'
import './main.css'
import PropTypes from "prop-types";

const Divider = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center
`
const Texto = styled.div`
  color: #7C7C74;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;

`
const PopupFilterOrder = styled(Popup)`
  background: #fff !important;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.25) !important;
  border-radius: 4px !important;
  padding: 0 !important;
  width: 190px !important;
  left: -1vw !important;
  border: none !important;

  &:before {
    display: none !important;
  }
`
const ButtonOption = styled.button`
  color: #7C7C74;
  border: 0;
  background: #fff;
  font-family: Roboto;
  font-weight: normal;
  font-size: 14px;
  height: 32px;
  text-align: left;
  width: 100%;
  border-radius: 4px;
`
const Text = styled.p`
  color: #7C7C74;
  font-family: Roboto;
  font-weight: normal;
  font-size: 14px;
  padding-left: 40px
`
const ContainerOption = styled.div`
  padding: 8px 0px !important;
`

class PopUpOrder extends React.Component {

  state = {
    show: false,
    state: this.props.order
  }

  componentDidUpdate(prevProps, prevState) {

    if (prevProps.order !== this.props.order) {
      this.setState({state: this.props.order})
    }

    if (this.state.state !== prevState.state) {
      this.props.actionOrder(this.state.state)
    }
  }

  toggle = () => {
    this.setState({show: !this.state.show})
  }

  render() {
    return (
      <Divider>
        <PopupFilterOrder
          position='bottom left'
          on='click'
          onClose={this.toggle}
          trigger={
            <ButtonIcon onClick={this.toggle}>
              <Texto>
                Ordenar
              </Texto>
              <OrderIcon/>
            </ButtonIcon>
          }
        >
          <ContainerOption>
            {!!this.props.hideOptions && !!this.props.hideOptions.find(hide => hide === 'masReciente') ? <></> :
              <ButtonOption
                className='buttonOptionHover'
                onClick={() => this.setState({state: 'masReciente'})}
                style={{"paddingTop":"14px", "paddingBottom":"22px"}}
              >
                {
                  this.state.state === 'masReciente'
                  && <Reviewed className='iconReReviewed'/>
                }
                {
                  this.state.state !== 'masReciente'
                    ? <Text>Más reciente</Text>
                    : 'Más reciente'
                }
              </ButtonOption>
            }
            {!!this.props.hideOptions && !!this.props.hideOptions.find(hide => hide === 'masAntiguo') ? <></> :
              <ButtonOption
                className='buttonOptionHover'
                onClick={() => this.setState({state: 'masAntiguo'})}
              >
                {
                  this.state.state === 'masAntiguo'
                  && <Reviewed className='iconReReviewed'/>
                }
                {
                  this.state.state !== 'masAntiguo'
                    ? <Text>Más antiguo</Text>
                    : 'Más antiguo'
                }
              </ButtonOption>
            }
            <ButtonOption
              className='buttonOptionHover'
              onClick={() => this.setState({state: 'A-Z'})}
            >
              {
                this.state.state === 'A-Z'
                && <Reviewed className='iconReReviewed'/>
              }
              {
                this.state.state !== 'A-Z'
                  ? <Text>A-Z</Text>
                  : 'A-Z'
              }
            </ButtonOption>
            <ButtonOption
              className='buttonOptionHover'
              onClick={() => this.setState({state: 'Z-A'})}
            >
              {
                this.state.state === 'Z-A'
                && <Reviewed className='iconReReviewed'/>
              }
              {
                this.state.state !== 'Z-A'
                  ? <Text>Z-A</Text>
                  : 'Z-A'
              }
            </ButtonOption>
          </ContainerOption>
        </PopupFilterOrder>
      </Divider>
    )
  }
}

PopUpOrder.propTypes = {
  hideOptions: PropTypes.array,
  order: PropTypes.string
}

const mapStateToProps = (state) => ({
  order: state.filterReducer.sort
})

const mapDispatchToProps = {
  actionOrder
}

export default connect(mapStateToProps, mapDispatchToProps)(PopUpOrder)
