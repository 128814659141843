import { useState } from 'react'
import SimpleReactValidator from 'simple-react-validator'

const useValidator = (reactValidatorOptions) => {
    const [show, setShow] = useState(false)
    const [fieldsToShow, setFieldsToShow] = useState([])
    const validator = new SimpleReactValidator(reactValidatorOptions)
    if (show) {
        if(fieldsToShow.length>0){
            setFieldsToShow([])
        }
        validator.showMessages()
    }

    if(fieldsToShow.length>0){
        for (let index = 0; index < fieldsToShow.length; index++) {
            validator.showMessageFor(fieldsToShow[index])
        }
    }

    const setFields = (field) => {
        if (!fieldsToShow.includes(field)) {
            setFieldsToShow([...fieldsToShow, field]);
        }
    }

    return [validator, setShow,setFields]
}

export default useValidator