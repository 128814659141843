import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import RowMassiveLoad from './RowMassiveLoad'
import GeneralTableTwo from '../../../../components/Table/GeneralTableTwo'
import {getAttributeListV2 } from '../../../../actions/writerActions'

class MassiveLoadContent extends Component {

  static propTypes = {
    history: PropTypes.any.isRequired,
  }

  state = {
    loader: true,
    columnData: [
      { name: "Encabezado de la columna", width: 3, arrow: false },
      { name: "Dato", width: 3, arrow: false },
      { name: "Atributo", width: 3, arrow: false },
    ],
  }

  componentDidMount() {
    this.setState({ loader: false })
    this.props.getAttributeListV2()
  }

  componentDidUpdate(prevProps, prevState) {
  }

  render() {
    return (
      <>
        <GeneralTableTwo
          list={this.props.data === undefined ? [] : this.props.data}
          typeList={'massiveLoad'}
          listHeight={'massiveLoad'}
          headerTable={this.state.columnData}
          rowComponent={RowMassiveLoad}
          history={this.props.history}
          loader={this.state.loader}
          striped={true}
          validator={this.props.validator}
          errors={this.props.errors}
          />
        </>
    )
  }
}
MassiveLoadContent.propTypes = {
  getAttributeListV2: PropTypes.func
}

const mapStateToProps = (state) => ({
  massiveLoad: state.writerReducer.resultValidateFileMassive
})

const mapDispatchToProps = {
  getAttributeListV2
}

export default connect(mapStateToProps, mapDispatchToProps)(MassiveLoadContent)