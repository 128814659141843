import React, { useRef } from 'react'
import styled from 'styled-components'
import { DetailClientYProvider } from '../../../components/DetailClientsYProvider/DetailClientYProvider'
import HeaderDetailClientYProvider from '../../../components/DetailClientsYProvider/HeaderDetailClientYProvider'

import TabDetailProviders from './TabDetailProviders'
import { UseWindowDimensions } from '../../../utils/UseWindowsDimentions'
import { useFilterPosition } from '../../../helpers/globalHelpers'

const Divider = styled.div`
  height: 89.3vh;
  overflow-y: scroll;
  overflow-x: hidden;
`

const viewProvidersDetail = () => {

    const windowDimensions = UseWindowDimensions();
    const filterRef = useRef(null);
    const containerRef = useRef(null);
    const filterPosition = useFilterPosition(filterRef, containerRef);

    return (
        windowDimensions.width > 767 ?
            <Divider ref={containerRef}>
                <HeaderDetailClientYProvider />
                <DetailClientYProvider />
                <div ref={filterRef}>
                    <TabDetailProviders isResponsive={false} filterPosition={filterPosition} />
                </div>
            </Divider>
            :
            <Divider ref={containerRef}>
                <HeaderDetailClientYProvider />
                <div ref={filterRef}>
                    <TabDetailProviders isResponsive={true} filterPosition={filterPosition} />
                </div>
            </Divider>
    )
}

export default viewProvidersDetail
