import React from 'react'
import GeneralTable from '../../../../components/Table/GeneralTable'
import RowTableSentMasive from './RowTableSentMasive'
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { removeFromListDownload, updateListDownload } from '../../../../actions/seeNotificationAction';


export const SentMasiveAll = ({notifications = [], loader, activeAll}) => {

  const history = useHistory()
  const dispatch = useDispatch()
  const { haveAttachments } = useSelector(state => state.sentReducer.detailMassiveNotification)

  const columnData = [
    { name: "", width: 1, arrow: false },
    { name: "No. documento ", width: 3, arrow: false },
    { name: "", width: 2, arrow: false },
    { name: "Última modificación", width: 4, arrow: false },
    { name: "", width: 3, arrow: false },
    { name: "", width: 3, arrow: false },
  ]

  const addCheckedRow = (id) => {
    dispatch(updateListDownload(id))
  }

  const removeCheckedRow = (id) => {
    dispatch(removeFromListDownload(id))
  }

  return (
    <GeneralTable
        list={notifications}
        typeList="masive"
        listHeight="masive"
        headerTable={columnData}
        rowComponent={RowTableSentMasive}
        addCheckedRow={addCheckedRow}
        removeCheckedRow={removeCheckedRow}
        history={history}
        loader={loader}
        activeAll={activeAll}
        heigthHeader={'45px'}
      />
  )
}