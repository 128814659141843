import axios from 'axios'
import {TOKEN_PREFIX} from '../config/config'

/**
 * setAuthToken constant function (verify if token exists and set this to axios Authorization header and sessionStorage Token,
 * else, remove axios authorization header and sessionStorage Token)
 *
 * @export constant
 * @param {*} token
 * @returns boolean
 */
const setAuthToken = (token, refreshToken) => {
  
  if (token) {
    axios.defaults.headers['Authorization'] = TOKEN_PREFIX + token
    sessionStorage.setItem('jwtToken', TOKEN_PREFIX + token)
    sessionStorage.setItem('refreshToken', refreshToken)
  } else {
    delete axios.defaults.headers['Authorization']
    sessionStorage.removeItem('jwtToken')
    sessionStorage.removeItem('refreshToken')
    sessionStorage.removeItem('idUser')
    sessionStorage.removeItem('massiveDataExtension')
    sessionStorage.removeItem('id')
    sessionStorage.removeItem('documentNumberUser')
    sessionStorage.removeItem('idAgreement')
    sessionStorage.removeItem('massiveData64')
    sessionStorage.removeItem('massiveDataName')
    sessionStorage.removeItem('publicKey')
    sessionStorage.removeItem('privateKey')
    sessionStorage.removeItem('pubKeyServer')
    sessionStorage.removeItem('secretKey')

  }
}

export default setAuthToken
