import React from 'react'
import { connect } from 'react-redux'
import { Popup } from 'semantic-ui-react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom';
import { actionFilter, actionStarredFilter } from '../../actions/filterDetailActions'
import ButtonIcon from '../../components/Button/IconButton'
import { ReactComponent as Filter } from '../../assets/icons/filter.svg'
import { ReactComponent as Reviewed } from '../../assets/icons/reviewed.svg'
import './main.css'
import {chageStateLoadNotificationsList, chageStateLoadCitizensList} from '../../actions/writerActions'

const Divider = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center
`
const Texto = styled.div`
  color: #7C7C74;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  @media only screen and (max-width: 750px) {
    display: none;
  }
`
const PopupFilter = styled(Popup)`
  background: #fff !important;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.25) !important;
  border-radius: 4px !important;
  padding: 0 !important;
  width: 190px !important;
  left: -1vw !important;
  border: none !important;

  &:before {
    display: none !important;
  }
`
const ButtonOption = styled.button`
  color: #7C7C74;
  border: 0;
  background: #fff;
  font-weight: normal;
  font-size: 14px;
  height: 32px;
  text-align: left;
  width: 100%;
`
const Text = styled.p`
  color: #7C7C74;
  font-weight: normal;
  font-size: 14px;
  padding-left: 40px
`

class PopUpFilterDetailUserDocument extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      notificationState: '',
      starred: false,
      pathname: ''
    }
    this.changeAdvanced = false
  }

  componentDidUpdate(prevProps, prevState) {
    if ((this.state.notificationState !== prevState.notificationState)
      && this.state.notificationState !== 'STARRED') {
      if (this.changeAdvanced !== true) {
        this.props.actionFilter(this.state.notificationState)
        this.setState({ show: !this.state.show })
      } else {
        this.changeAdvanced = false
      }
    }

    if (this.state.starred !== prevState.starred
      && this.state.notificationState === 'STARRED') {
      this.props.actionStarredFilter(this.state.starred)
      this.setState({ show: !this.state.show })
    }
  }

  toggle = () => {
    if (this.state.notificationState !== this.props.stateSearch) {
      this.changeAdvanced = true
    }
    if (this.state.notificationState === "STARRED" && this.props.stateSearch === '') {
      this.setState({ notificationState: "STARRED" })
      this.changeAdvanced = false
    } else {
      this.setState({ notificationState: this.props.stateSearch })
    }
    this.setState({ show: !this.state.show })
    if(this.props.statusLoadNotifications)
      this.props.chageStateLoadNotificationsList(false)
    else
      this.props.chageStateLoadCitizensList(false)
  }

  changeStatus = (status) => {
    if (status === 'STARRED') {
      this.setState({ notificationState: status, starred: true })
    } else {
      this.setState({ notificationState: status, starred: false })
    }
  }

  render() {
    const { location,filterSmall,noStarred = false } = this.props
    return (
      <Divider>
        <PopupFilter
          position='bottom left'
          on='click'
          pinned
          open={this.state.show}
          onClose={this.toggle}
          trigger={
            <ButtonIcon onClick={this.toggle}>
              <Texto>
                Filtrar
              </Texto>
              <Filter style={filterSmall ?{width:'20px'}:{}} />
            </ButtonIcon>
          }
        >
          {
            this.props.gender === 'Male'
            ?
            <>
              <ButtonOption
                className='buttonOptionHover'
                onClick={() => this.changeStatus('READ')}
                style={{"paddingTop":"14px", "paddingBottom":"22px"}}
              >
                {
                  this.state.notificationState === 'READ'
                  && <Reviewed className='iconReReviewed' />
                }
                {
                  this.state.notificationState !== 'READ'
                    ? <Text>Leído</Text>
                    : 'Leído'
                }
              </ButtonOption>
              <ButtonOption
                className='buttonOptionHover'
                onClick={() => this.changeStatus('UNREAD')}
                style={{"paddingTop":"14px", "paddingBottom":"22px"}}
              >
                {
                  this.state.notificationState === 'UNREAD'
                  && <Reviewed className='iconReReviewed' />
                }
                {
                  this.state.notificationState !== 'UNREAD'
                    ? <Text>No leído</Text>
                    : 'No leído'
                }
              </ButtonOption>
              <ButtonOption
                className='buttonOptionHover'
                onClick={() => this.changeStatus('PENDING')}
              >
                {
                  this.state.notificationState === 'PENDING'
                  && <Reviewed className='iconReReviewed' />
                }
                {
                  this.state.notificationState !== 'PENDING'
                    ? <Text>Pendiente</Text>
                    : 'Pendiente'
                }
              </ButtonOption>
              <ButtonOption
                className='buttonOptionHover'
                onClick={() => this.changeStatus('SIGNED')}
              >
                {
                  this.state.notificationState === 'SIGNED'
                  && <Reviewed className='iconReReviewed' />
                }
                {
                  this.state.notificationState !== 'SIGNED'
                    ? <Text>Completado</Text>
                    : 'Completado'
                }
              </ButtonOption>
              <ButtonOption
                className='buttonOptionHover'
                onClick={() => this.changeStatus('REJECTED')}
              >
                {
                  this.state.notificationState === 'REJECTED'
                  && <Reviewed className='iconReReviewed' />
                }
                {
                  this.state.notificationState !== 'REJECTED'
                    ? <Text>Rechazado</Text>
                    : 'Rechazado'
                }
              </ButtonOption>
              <ButtonOption
                className='buttonOptionHover'
                onClick={() => this.changeStatus('ERROR')}
              >
                {
                  this.state.notificationState === 'ERROR'
                  && <Reviewed className='iconReReviewed' />
                }
                {
                  this.state.notificationState !== 'ERROR'
                    ? <Text>Error</Text>
                    : 'Error'
                }
              </ButtonOption>
              <ButtonOption
                  className='buttonOptionHover'
                  onClick={() => this.changeStatus('STARRED')}
                >
                  {
                    this.state.notificationState === 'STARRED'
                    && <Reviewed className='iconReReviewed' />
                  }
                  {
                    this.state.notificationState !== 'STARRED'
                      ? <Text>Destacado</Text>
                      : 'Destacado'
                  }
            </ButtonOption>
            <ButtonOption
                  className='buttonOptionHover'
                  onClick={() => this.changeStatus('REQUIRED_SIGNATURE')}
                >
                  {
                    this.state.notificationState === 'REQUIRED_SIGNATURE'
                    && <Reviewed className='iconReReviewed' />
                  }
                  {
                    this.state.notificationState !== 'REQUIRED_SIGNATURE'
                      ? <Text>Con solicitud de firma</Text>
                      : 'Con solicitud de firma'
                  }
            </ButtonOption>
              <ButtonOption
                className='buttonOptionHover'
                onClick={() => this.changeStatus('')}
                style={{"paddingBottom":"14px", "paddingTop":"5px"}}
              >
                {
                  this.state.notificationState === ''
                  && <Reviewed className='iconReReviewed' />
                }
                {
                  this.state.notificationState !== ''
                    ? <Text>Limpiar filtro</Text>
                    : 'Limpiar filtro'
                }
              </ButtonOption>
          </>
          : 
          <>
            <ButtonOption
                className='buttonOptionHover'
                onClick={() => this.changeStatus('READ')}
                style={{"paddingTop":"14px", "paddingBottom":"22px"}}
              >
                {
                  this.state.notificationState === 'READ'
                  && <Reviewed className='iconReReviewed' />
                }
                {
                  this.state.notificationState !== 'READ'
                    ? <Text>Leído</Text>
                    : 'Leído'
                }
              </ButtonOption>
            <ButtonOption
                className='buttonOptionHover'
                onClick={() => this.changeStatus('UNREAD')}
                style={{"paddingTop":"14px", "paddingBottom":"22px"}}
              >
                {
                  this.state.notificationState === 'UNREAD'
                  && <Reviewed className='iconReReviewed' />
                }
                {
                  this.state.notificationState !== 'UNREAD'
                    ? <Text>No leído</Text>
                    : 'No leído'
                }
              </ButtonOption>
              <ButtonOption
                className='buttonOptionHover'
                onClick={() => this.changeStatus('PENDING')}
              >
                {
                  this.state.notificationState === 'PENDING'
                  && <Reviewed className='iconReReviewed' />
                }
                {
                  this.state.notificationState !== 'PENDING'
                    ? <Text>Pendiente</Text>
                    : 'Pendiente'
                }
              </ButtonOption>
              <ButtonOption
                className='buttonOptionHover'
                onClick={() => this.changeStatus('SIGNED')}
              >
                {
                  this.state.notificationState === 'SIGNED'
                  && <Reviewed className='iconReReviewed' />
                }
                {
                  this.state.notificationState !== 'SIGNED'
                    ? <Text>Completado</Text>
                    : 'Completado'
                }
              </ButtonOption>
            <ButtonOption
              className='buttonOptionHover'
              onClick={() => this.changeStatus('REJECTED')}
            >
              {
                this.state.notificationState === 'REJECTED'
                && <Reviewed className='iconReReviewed' />
              }
              {
                this.state.notificationState !== 'REJECTED'
                  ? <Text>Rechazado</Text>
                  : 'Rechazado'
              }
            </ButtonOption>
            <ButtonOption
              className='buttonOptionHover'
              onClick={() => this.changeStatus('ERROR')}
            >
              {
                this.state.notificationState === 'ERROR'
                && <Reviewed className='iconReReviewed' />
              }
              {
                this.state.notificationState !== 'ERROR'
                  ? <Text>Error</Text>
                  : 'Error'
              }
            </ButtonOption>
            <ButtonOption
                  className='buttonOptionHover'
                  onClick={() => this.changeStatus('STARRED')}
                >
                  {
                    this.state.notificationState === 'STARRED'
                    && <Reviewed className='iconReReviewed' />
                  }
                  {
                    this.state.notificationState !== 'STARRED'
                      ? <Text>Destacado</Text>
                      : 'Destacado'
                  }
            </ButtonOption>
            <ButtonOption
                  className='buttonOptionHover'
                  onClick={() => this.changeStatus('REQUIRED_SIGNATURE')}
                >
                  {
                    this.state.notificationState === 'REQUIRED_SIGNATURE'
                    && <Reviewed className='iconReReviewed' />
                  }
                  {
                    this.state.notificationState !== 'REQUIRED_SIGNATURE'
                      ? <Text>Con solicitud de firma</Text>
                      : 'Con solicitud de firma'
                  }
            </ButtonOption>
            <ButtonOption
                  className='buttonOptionHover'
                  onClick={() => this.changeStatus('NO_REQUIRED_SIGNATURE')}
                >
                  {
                    this.state.notificationState === 'NO_REQUIRED_SIGNATURE'
                    && <Reviewed className='iconReReviewed' />
                  }
                  {
                    this.state.notificationState !== 'NO_REQUIRED_SIGNATURE'
                      ? <Text>Sin solicitud de firma</Text>
                      : 'Sin solicitud de firma'
                  }
            </ButtonOption>
            <ButtonOption
              className='buttonOptionHover'
              onClick={() => this.changeStatus('')}
              style={{"paddingBottom":"14px", "paddingTop": "5px"}}
            >
              {
                this.state.notificationState === ''
                && <Reviewed className='iconReReviewed' />
              }
              {
                this.state.notificationState !== ''
                  ? <Text>Limpiar filtro</Text>
                  : 'Limpiar filtro'
              }
            </ButtonOption>
          </>
          }
        </PopupFilter>
      </Divider>
    )
  }
}

const mapStateToProps = (state) => ({
  filter: state.filterDetailReducer.filter,
  stateSearch: state.filterDetailReducer.searchAdvanced.notificationState,
  tabFilter: state.filterDetailReducer.tabFilter,
  statusLoadNotifications: state.writerReducer.loadNotifications
})

const mapDispatchToProps = {
  actionFilter,
  actionStarredFilter,
  chageStateLoadNotificationsList,
  chageStateLoadCitizensList,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PopUpFilterDetailUserDocument))
