import React, {useState, useEffect, useCallback} from 'react'
import {connect} from 'react-redux'

import styled, {css} from 'styled-components'
import AttachedFileCard from './AttachedFileCard'
import PropTypes from "prop-types";
import {CloseSvgIcon} from "../IconSVG/CloseSvgIcon";
import {usePrevious} from '../../helpers/globalHelpers';
import SpecificFileCard from './SpecificFileCard';
import ChargingFileCard from './ChargingFileCard';

const Container = styled.div`
  display: inline-block;
  width: 100%;
  ${props => props.fileheight &&
          css`
            height: ${props.fileheight};
          `}
  height: 40px;
`

const CloseIcon = styled(CloseSvgIcon)`
  width: 16px;
  height: 16px;
  color: #E35F00;
  padding-top: 3px;
  padding-left: 3px;
  ${props => props.visibility &&
          css`
            visibility: ${props.visibility};
          `}
`
const CloseButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  visibility: hidden;
  width: 16px;
  height: 16px;
  position: absolute;
  right: 8px;
`

const CustomDiv = styled.div`
  display: flex;
  padding: 5px;
  width: 100%;
  background: rgba(227, 95, 0, 0.1);
  border: 1px solid #E35F00;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 5px;

  @media only screen and (max-width: 790px)  {
    padding: 5px 20px;
    ${props => (!!props.headerDisabled && props.filesLength >= 5 ) &&
      css`
        height: auto;
      `}
  } 
  @media only screen and (max-width: 790px)  {
    padding: 5px 20px;
    ${props => (!!props.headerDisabled && props.filesSize >= 10.00 ) &&
      css`
        height: auto;
      `}
  } 
  @media only screen and (max-width: 665px)  {
    padding: 5px 20px;
    height: auto;
    ${props => (!!props.headerDisabled && props.filesLength >= 5 ) &&
      css`
        height: auto;
      `}
  }
  @media only screen and (max-width: 665px)  {
    padding: 5px 20px;
    height: auto;
    ${props => (!!props.headerDisabled && props.filesSize >= 10.00 ) &&
      css`
        height: auto;
      `}
  }
  @media only screen and (max-width: 324px)  {
    padding: 5px 20px;
    ${props => (!!props.headerDisabled && props.filesLength >= 5 ) &&
      css`
        height: auto;
      `}
  }
  @media only screen and (max-width: 324px)  {
    padding: 5px 20px;
    ${props => (!!props.headerDisabled && props.filesSize >= 10.00 ) &&
      css`
        height: auto;
      `}
  }
  &:hover ${CloseButton} {
    visibility: visible;
  }
  ${props => (!!props.headerDisabled && !!props.activeNotificationSignature && props.filesLength < 5 ) &&
    css`
      padding: 8px;
    `}
  ${props => (!!props.headerDisabled && props.filesLength >= 5 ) &&
    css`
      height: auto;
    `} 
  ${props => (!!props.headerDisabled && !!props.activeNotificationSignature && props.filesSize < 10.00 ) &&
    css`
      padding: 8px;
    `}
  ${props => (!!props.headerDisabled && !!props.activeNotificationSignature && props.filesSize >= 10.00 ) &&
      css`
        height: auto;
      `}
  ${props => props.visibility &&
          css`
            visibility: ${props.visibility};
          `}
`

const WarningList = styled.ul`
  margin: 0;
  padding-left: 3%;
  width: 90%;
`

const ListItem = styled.li`
  float: left;
  color: #E35F00;
  width: 100%;

  ${props => (!!props.headerDisabled && props.filesLength >= 5 ) &&
    css`
      margin-bottom:4px;
    `}
`

const Text = styled.div`
  float: left;
  width: 100%;
  color: #E35F00;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  white-space: normal;
  
  @media only screen and (max-width: 790px) and (min-width: 423px) {
    font-size: 14px;
    padding: 0px 0px;
    white-space: normal;
    
  }
  @media only screen and (max-width: 422px) and (min-width: 374px) {
    font-size: 12px;
    padding: 0px 0px;
    white-space: normal;
  }
  @media only screen and (max-width: 375px) {
    font-size: 10px;
    padding: 0px 0px;
    white-space: normal;
  }
`

const SpecificFileContainer = (props) => {
  const [warningStateFlag, setWarningStateFlag] = useState('hidden')
  const prevCheckboxHeaderDisabled = usePrevious(props.checkboxHeaderDisabled)
  const files = Object.keys(props.files)
  const specificFiles = Object.keys(props.specificFiles)
  const prevFileLength = usePrevious(files.length)
  const fileHeight = (listSize) => `${((listSize) * 6)}%`
  const fileHeightDivError = (listSize, numberWarnings) => (`${(100 / (listSize)) + (numberWarnings <= 6 ? 0 : numberWarnings)}%`)
  const numberOfWarnings = () => {
    let heightPercentage = 0
    if (!!props.checkboxHeaderDisabled) {
      heightPercentage += 6
    }
    if (files.length >= 5) {
      heightPercentage += 6
    }
    if (getSizeFiles(files) >= 10) {
      heightPercentage += 6
    }
    return heightPercentage
  }

  const getSizeFiles = (files) => {
    const totalSize = files.reduce(
      (prev, key) => {
        return prev + props.files[key].size
      },
      0
    )
    return (totalSize / (1024 * 1024)).toFixed(2)
  }

  const memoizedCallbackGetFiles = useCallback(
    (files) => {
      const totalSize = files.reduce(
        (prev, key) => {
          return prev + props.files[key].size
        },
        0
      )
      return (totalSize / (1024 * 1024)).toFixed(2)
    },
    [props.files],
  );

  useEffect(() => {
    if (!props.checkboxHeaderDisabled && files.length + specificFiles.length < 5){
      setWarningStateFlag('hidden')
    }
    if (props.checkboxHeaderDisabled !== prevCheckboxHeaderDisabled && !!props.checkboxHeaderDisabled && !!props.servicesList.notificationSignature) {
      setWarningStateFlag('visible')
    }
    if (files.length + specificFiles.length >= 5 && files.length !== prevFileLength) {
      setWarningStateFlag('visible')
    }
  },[props.checkboxHeaderDisabled, prevCheckboxHeaderDisabled, files, prevFileLength])

  useEffect(() => {
    if (memoizedCallbackGetFiles >= 10 || getSizeFiles(files) >= 10.00) {
      setWarningStateFlag('visible')
    }
  }, [memoizedCallbackGetFiles, getSizeFiles])

  const handleWarningsDiv = (e) => {
    e.preventDefault()
    setWarningStateFlag('hidden')
  }

  const handleBlurInput = () => {
  }

  return (
    <Container
      fileheight={fileHeight(specificFiles.length)}
    >
      <CustomDiv fileHeightDivError={fileHeightDivError(files.length, numberOfWarnings())}
                 visibility={warningStateFlag}
                 filesLength= {files.length}
                 headerDisabled= {props.checkboxHeaderDisabled}
                 onBlur={handleBlurInput}
                 filesSize={getSizeFiles(files)}
                 activeNotificationSignature= {props.servicesList.notificationSignature}
                >
        <WarningList>
          {files.length + specificFiles.length >= 5
            && <ListItem>
              <Text>Alcanzaste el máximo de 5 archivos adjuntos.</Text>
            </ListItem>
          } 
          {!!props.checkboxHeaderDisabled && !!props.servicesList.notificationSignature
            && <ListItem
                filesLength= {files.length}
                headerDisabled= {props.checkboxHeaderDisabled}>
                <Text>Al seleccionar la opción de Firma en documento se deshabilitará la Firma en notificación.</Text>
              </ListItem>
          }

          {getSizeFiles(files) >= 10.00
            && <ListItem>
              <Text>Alcanzaste el máximo de 10MB</Text>
            </ListItem>
          }
        </WarningList>
        <CloseButton

          onClick={(e) => handleWarningsDiv(e)}
        >
          <CloseIcon visibility={warningStateFlag}/>
        </CloseButton>
      </CustomDiv>
      {
        specificFiles.map((row, index) => (
          <SpecificFileCard data={props.specificFiles[row]} index={index} files={props.specificFiles}/>
        ))
      }
      {
        files.map((row, index) => (
          <AttachedFileCard data={props.files[row]} index={index} files={props.files} decrementNumberOfFiles ={props.decrementNumberOfFiles}/>
        ))
      }
      {
        props.filesCharging.map((file, index) => (
          <ChargingFileCard data={file} index={index} files={props.filesCharging} filesLoaded={props.filesLoaded} errorLoadingFiles={false} deleteChargingFile={props.deleteChargingFile} errorFiles={props.errorFiles}/>
        ))
      }
      {
        props.errorFiles.map((file, index) => (
        <ChargingFileCard data={file} index={index} files={props.errorFiles} filesLoaded={props.filesLoaded} errorLoadingFiles={true} deleteChargingFile={props.deleteChargingFile}/>
        ))
      }
    </Container>

  )
}

SpecificFileContainer.propTypes = {
  checkboxHeaderDisabled: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  files: state.writerReducer.notificationForm.files,
  specificFiles: state.writerReducer.listDocumentsSpecific,
  checkboxHeaderDisabled: state.writerReducer.checkboxHeaderDisabled,
  servicesList: state.writerReducer.hiredData.servicesList,
})

export default connect(mapStateToProps, null)(SpecificFileContainer)

