import React, { Component } from 'react'
import { MainSegment } from '../../components/Sengment/MainSegment'

class Agreements extends Component {
  render() {
    return (
      <MainSegment>
        Bolsas
      </MainSegment>
    )
  }
}

export default Agreements
