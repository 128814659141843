import {
  GET_CLIENTS,
  SAVE_CLIENT,
  CHANGE_STATE,
  INACTIVE_STATE_CLIENT_FORM,
  EDIT_CLIENT,
  GET_CLIENT_EDIT,
  DETAIL_CLIENT,
  CHANGE_EDIT_CLIENT,
  ACTIVE_FORM_EDIT,
  INACTIVE_FORM_EDIT,
  GET_EMPLOYEES,
  SAVE_EMPLOYEE,
  GET_EMPLOYEE_EDIT,
  CHANGE_EDIT_EMPLOYEE,
  SET_ORIGINAL_EMPLOYEES_COUNT,
  SET_LOGS_LIST_CLIENT
} from '../actions/actionsUtilities/types.js'

const initialState = {
  clients: [],
  savedClient: [],
  detailClient: {
    contactInformation:[]
  },
  clientActiveEdit: {},
  clientPut: [],
  activeForm: false,
  stateFormEdit: true,
  employees: [],
  savedEmployee: [],
  employeeActiveEdit: {},
  elementsCount: 0,
  employeesCount: 0,
  originalEmployeesCount:-1,
  logsList:[],
  logsCount:0,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CLIENTS:
      return {
        ...state,
        clients: action.payload.clients,
        elementsCount: action.payload.elementsCount,
      }
    case SAVE_CLIENT:
      return {
        ...state,
        savedClient: action.payload
      }
    case CHANGE_STATE:
      return {
        ...state,
        activeForm: true,
      }
    case INACTIVE_STATE_CLIENT_FORM:
      return {
        ...state,
        activeForm: false,
      }
    case DETAIL_CLIENT:
      return {
        ...state,
        detailClient: action.payload,
      }
    case GET_CLIENT_EDIT:
      return {
        ...state,
        clientActiveEdit: { ...action.payload }
      }
    case CHANGE_EDIT_CLIENT:
      return {
        ...state,
        clientActiveEdit: {
          ...state.clientActiveEdit,
          [action.payload.key]: action.payload.value
        }
      }
    case ACTIVE_FORM_EDIT:
      return {
        ...state,
        stateFormEdit: false,
      }
    case INACTIVE_FORM_EDIT:
      return {
        ...state,
        stateFormEdit: true,
      }
    case EDIT_CLIENT:
      return {
        ...state,
        clientPut: action.payload,
      }
    case GET_EMPLOYEES:
      return {
        ...state,
        employees: action.payload.employees,
        employeesCount: action.payload.employeesCount,
      }
    case SET_ORIGINAL_EMPLOYEES_COUNT:
      return {
        ...state,
        originalEmployeesCount: action.payload,
        employeesCount: action.payload,
      }
    case SAVE_EMPLOYEE:
      return {
        ...state,
        savedEmployee: action.payload
      }
    case GET_EMPLOYEE_EDIT:
      return {
        ...state,
        employeeActiveEdit: { ...action.payload }
      }
    case CHANGE_EDIT_EMPLOYEE:
      return {
        ...state,
        employeeActiveEdit: {
          ...state.employeeActiveEdit,
          [action.payload.key]: action.payload.value
        }
      }
      case SET_LOGS_LIST_CLIENT:
      return {
        ...state,
        logsList: action.payload.logsList,
        logsCount: action.payload.logsCount
      }
    default:
      return state
  }
}
