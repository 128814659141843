import React, { useState } from 'react'
import { Table } from 'semantic-ui-react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getProviderDetail, getProviderEditV2, getProvidersV2, putProviderEditV2, putStateActive, putStateInactive } from '../../actions/providerActions'
import { Cell, CellOptions } from '../../components/Table/CellComponent'
import { refreshStateModule, warningFormModal } from '../../actions/modalActions'
import { UseWindowDimensions } from '../../utils/UseWindowsDimentions'
import { CODE_COMPANY_TO_EDIT } from '../../actions/actionsUtilities/types'

const Row = styled(Table.Row)`
  cursor: pointer;
  height: 50px;
  @media only screen and (min-width: 100px) and (max-width: 768px) {
    height: unset;
    display: flex !important;
    flex-direction: column;
    border-bottom: 1px solid #B2B2B2 !important;
    padding: 0.5em 0em!important;
    width: 100%;
    box-sizing: content-box;
  }
`

const RowTableProviders = (props) => {

  const dispatch = useDispatch()
  const history = useHistory()
  const [isVisible, setIsVisible] = useState(false)
  const { count, pag, searchBar, sort } = useSelector(state => state.filterReducer)
  
  const orderTranslator = (order) => {
    const options = {
      'masReciente': {"_id": -1},
      'masAntiguo': {"_id": 1},
      'A-Z': {"name": 1},
      'Z-A': {"name": -1}
    }
    return options[order]
  }
  
  const body= {
    count: count,
    pag: pag,
    sort: orderTranslator(sort),
    textToSearch: searchBar[0]
  }
  const redirectToProviderDetail = () => {
    sessionStorage.setItem("id", props.data.companySerialID)
    dispatch(getProviderDetail(props.data.companySerialID))
    props.history.push({ pathname: '/providers-detail/' + props.data.companySerialID })
  }

  const handleClick = (e) => {
    e.stopPropagation()
    dispatch(refreshStateModule())
    sessionStorage.setItem("id", props.data.companySerialID)
    dispatch(getProviderEditV2(props.data.companySerialID))
    history.push('/editProviders')
  }
  const handleChangeActive = (e) => {
    sessionStorage.setItem("idProvider", props.data.companySerialID)
    dispatch(warningFormModal({ type: 'warningUpdateProviderActive' }))
    dispatch({ type: CODE_COMPANY_TO_EDIT, payload:  props.data.codeCompany})
  }
  const handleChangeInactive = (e) => {
    sessionStorage.setItem("idProvider", props.data.companySerialID)
    dispatch(warningFormModal({ type: 'warningUpdateProviderInactive' }))
    dispatch({ type: CODE_COMPANY_TO_EDIT, payload:  props.data.codeCompany})
  }

  const width = UseWindowDimensions().width
  
  return (
    <Row onMouseEnter={() => setIsVisible(true)} onMouseLeave={() => setIsVisible(false)} >
      <Cell width={3} text={props.data.name} columnName={props.headerTable[0].name} onClick={redirectToProviderDetail}/>
      <Cell width={2} text={props.data.numberDocumentCompany} columnName={props.headerTable[1].name} onClick={redirectToProviderDetail}/>
      <Cell width={2} text={props.data.phone} columnName={props.headerTable[2].name} onClick={redirectToProviderDetail}/>
      <Cell width={3} text={props.data.email} columnName={props.headerTable[3].name} onClick={redirectToProviderDetail}/>
      <Cell width={3} text={props.data.legalManagerName} columnName={props.headerTable[4].name} onClick={redirectToProviderDetail}/>
      <CellOptions isVisible={width >768 ? isVisible && !props.data.hasAgreementAsociate:!props.data.hasAgreementAsociate} columnName={props.headerTable[5].name} clickEdit={handleClick}
       active={props.data.status === 'ENABLE'?'ACTIVE': 'INACTIVE'} handleChangeActive={handleChangeActive} handleChangeInactive={handleChangeInactive} toggleDisabled={props.data.hasAgreementAsociate} customMargin={true}/>
    </Row>
  )
};

RowTableProviders.propTypes = {
  data: PropTypes.object.isRequired
};

export default RowTableProviders