import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { DetailClientYProvider } from '../../../components/DetailClientsYProvider/DetailClientYProvider'
import HeaderDetailClientYProvider from '../../../components/DetailClientsYProvider/HeaderDetailClientYProvider'
import TabDetailClient from './TabDetailClient'
import { useSelector } from 'react-redux'
import { useFilterPosition } from '../../../helpers/globalHelpers'

const Divider = styled.div`
  height: 89.3vh;
  overflow-y: scroll;
  overflow-x: hidden;
`

function getWindowDimensions() {
  const { innerWidth: width } = window;
  return {
    width
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions);

  
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const ViewClientsDetail = () => {
  const windowDimensions = useWindowDimensions();
  const userPermissions = useSelector(state => state.authReducer.user.userPermission)
  const userRole = useSelector(state => state.authReducer.user.roleName)
  const filterRef = useRef(null);
  const containerRef = useRef(null);
  const filterPosition = useFilterPosition(filterRef, containerRef);


  return (
    windowDimensions.width>767?
    <Divider ref={containerRef}>
      <HeaderDetailClientYProvider />
      <DetailClientYProvider />
      {userPermissions.includes("LIST_USERS_CLIENT")|| userRole ==='Operador'?
        <div ref={filterRef}>
          <TabDetailClient userRole={userRole} isResponsive={false} filterPosition={filterPosition}/>
        </div>
      :
      <></>
      }
    </Divider>
    :
    <Divider ref={containerRef}>
      <HeaderDetailClientYProvider />
      {userPermissions.includes("LIST_USERS_CLIENT")|| userRole ==='Operador'?
      <div ref={filterRef}>
        <TabDetailClient userRole={userRole} isResponsive={true} showUsers={true} filterPosition={filterPosition}/>
      </div>
      :
      <div ref={filterRef}>
        <TabDetailClient userRole={userRole} isResponsive={true} showUsers={false} filterPosition={filterPosition}/>
      </div>
    }
    </Divider>

  )
}

export default ViewClientsDetail