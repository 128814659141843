import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { API_ENDPOINT } from "../config/config"
import {
  SET_ROLES,
  CLEAR_LOGIN,
  SET_LOGIN_STATUS,
  CHANGE_STATE_LOGIN_MODAL,
  CLEAR_ERRORS,
  LOGOUT,
  SET_CURRENT_USER,
  STATE_CLEAR_FILTER,
  GET_LIST_CERTIFICATE,
  GET_LIST_CERTIFICATE_2,
  GET_QR,
  RESET_DASHBOARD,
  UPDATE_SESSION_ACTIVE,
  SET_LOGIN_TYPE,
  VALID_TOKEN,
  RESET_CAPTCHA,
  CHANGE_STATE_MESSAGE_MODAL,
  CHANGE_ROLE_STATE_MODAL,
  CLEAR_INTERVAL_TO_BE_NOTIFIED,
} from "./actionsUtilities/types.js"

import setAuthToken from "../utils/setAuthToken"
import { reasonDispatch } from "./DispatchGeneric"
import { getHeaders } from "../helpers/globalHelpers"
import {
  RECOVER_PASSWORD,
  LOGIN_LIST_CERTIFICATE,
  LOGIN_GET_QR,
  AUTENTICATION_WITH_QR,
  RESET_QR,
  LOGIN_VALIDATE_CERTIFICATE_V2,
  REFRESH_TOKEN_V3,
  SELECT_ROL,
  CHANGE_ROL,
  CHECK_LOGIN_STATUS,
  LOGIN_USER_ROUTEV2,
  VERIFY_CAPTCHA,
  VERIFY_CAPTCHA_V2
} from './actionsUtilities/actionsRoutes.js'
import { createSharedKey, decrypt, encrypt } from '../helpers/cypher'
import axiosRetry from 'axios-retry'
import { axiosConfig } from './actionsUtilities/retryRequest.js'
import { createCookie } from '../utils/cookies.js'
import { closewriteNotificationModal } from './modalActions.js'

axiosRetry(axios, axiosConfig);

/**
 * AuthAction loginUser ( set user credentials to server for login attempt )
 *
 * @param history
 * @param {*} userData
 */
export const loginUserCipher = (history, userData) => async (dispatch) => {
  const encryptedBody = await encrypt(userData);
  await axios.post(`${API_ENDPOINT + LOGIN_USER_ROUTEV2}`, { data: encryptedBody }, { headers: await getHeaders() })
    .then(async res => {
      const { token, changePassword, refreshToken, viewInfoAvatar } = await decrypt(res.data.result);
      if (history) {
        setAuthToken(token, refreshToken)
        const decoded = jwt_decode(token)
        dispatch(setCurrentUser(decoded))
        if (decoded) redirectAfterLogin(decoded.role, history)
        dispatch({ type: SET_LOGIN_TYPE, payload: 'withOutCertificate' })
      }
      dispatch({ type: CLEAR_ERRORS })
    }).catch(async reason => {
      await reasonDispatch(dispatch, reason, false)
      dispatch({type:RESET_CAPTCHA})
      dispatch({type: VALID_TOKEN, payload: {}})
    })
}

const redirectAfterLogin = (role, history) => {
  if (role === '64d3c96218d350a93692cd7c') {
    history.push({ pathname: '/improperClients' })
  } else {
    history.push({ pathname: '/dashboard' })
  }
}

export const getRefreshToken = (body) => async (dispatch) => {
  const encryptedBody = await encrypt(body);
  await axios.post(
    `${API_ENDPOINT + REFRESH_TOKEN_V3}`, { data: encryptedBody }, { headers: getHeaders() }
  ).then(async res => {
    const { token, changePassword, refreshToken } = await decrypt(res.data.result);
    setAuthToken(token.token, refreshToken)
    const decoded = jwt_decode(token.token)
    dispatch(setCurrentUser(decoded))
    dispatch({ type: CLEAR_ERRORS })
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
  })
}

export const changeStatusSession = (status) => async (dispatch) => {
  dispatch({
    type: UPDATE_SESSION_ACTIVE,
    payload: status
  })
}


export const getListCertificate = (history, userData) => async (dispatch) => {
  dispatch({
    type: CHANGE_STATE_LOGIN_MODAL,
    payload: { type: 'progress' }
  })
  await axios.post(`${API_ENDPOINT}${LOGIN_LIST_CERTIFICATE}?documentNumber=${userData}`
  ).then(async res => {
    const { certificates, pubKey } = res.data.result
    sessionStorage.setItem('pubKeyServer', pubKey)
    createSharedKey(pubKey)
    dispatch({
      type: CHANGE_STATE_LOGIN_MODAL,
      payload: { type: 'progress' }
    })
    dispatch({
      type: GET_LIST_CERTIFICATE_2,
      payload: certificates
    })
    if (history) {
      history.push({ pathname: `/login-certificate/${userData}` })
    }
    dispatch({ type: CLEAR_ERRORS })
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
    dispatch({
      type: CHANGE_STATE_LOGIN_MODAL,
      payload: { type: 'progress' }
    })
    dispatch({type:RESET_CAPTCHA})
    dispatch({type: VALID_TOKEN, payload: {}})
  })
}

export const listCertificate = (userData) => async (dispatch) => {
  dispatch({
    type: CHANGE_STATE_LOGIN_MODAL,
    payload: { type: 'progress' }
  })

  await axios.post(`${API_ENDPOINT}${LOGIN_LIST_CERTIFICATE}?userSerialID=${userData}`
  ).then(res => {
    dispatch({
      type: CHANGE_STATE_LOGIN_MODAL,
      payload: { type: 'progress' }
    })
    dispatch({
      type: GET_LIST_CERTIFICATE,
      payload: res.data.result.certificates
    })
    dispatch({ type: CLEAR_ERRORS })
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
    dispatch({
      type: CHANGE_STATE_LOGIN_MODAL,
      payload: { type: 'progress' }
    })
  })
}

export const validateCertificate = (history, body) => async (dispatch) => {
  dispatch({
    type: CHANGE_STATE_LOGIN_MODAL,
    payload: { type: 'progress' }
  })
  dispatch({ type: CLEAR_ERRORS })
  const encryptedBody = await encrypt(body);

  await axios.post(`${API_ENDPOINT}${LOGIN_VALIDATE_CERTIFICATE_V2}`, { 'data': encryptedBody }, { headers: getHeaders() })
    .then(async res => {
      const decryptedData = await decrypt(res.data.result);
      dispatch({
        type: CHANGE_STATE_LOGIN_MODAL,
        payload: { type: 'progress' }
      })
      if (decryptedData.OTP === false || body.isFirstLogin ) {
        dispatch({
          type: CHANGE_STATE_LOGIN_MODAL,
          payload: { type: 'sendEmail' }
        })
      } else {
        history.push(`/login-QR2/${body.userSerialIDs[0]}`)
      }
      dispatch({ type: CLEAR_ERRORS })
    }).catch(async reason => {
      await reasonDispatch(dispatch, reason, false)
      dispatch({
        type: CHANGE_STATE_LOGIN_MODAL,
        payload: { type: 'progress' }
      })
    })
}

export const getQr = (userData) => async (dispatch) => {

  dispatch({
    type: CHANGE_STATE_LOGIN_MODAL,
    payload: { type: 'progress' }
  })
  await axios.post(
    `${API_ENDPOINT}${LOGIN_GET_QR}?userSerialID=${userData}`
  ).then(res => {

    dispatch({
      type: CHANGE_STATE_LOGIN_MODAL,
      payload: { type: 'progress' }
    })
    dispatch({
      type: GET_QR,
      payload: res.data.result.response
    })
    dispatch({ type: CLEAR_ERRORS })
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
    dispatch({
      type: CHANGE_STATE_LOGIN_MODAL,
      payload: { type: 'progress' }
    })
  })
}

export const postLogin = (history, body, myCookie) => async (dispatch) => {
  const encryptedBody = await encrypt(body);
  dispatch({
    type: CHANGE_STATE_LOGIN_MODAL,
    payload: { type: 'progress' }
  })
  await axios.post(
    `${API_ENDPOINT}${AUTENTICATION_WITH_QR}`, { 'data': encryptedBody }, { headers: getHeaders() },
  ).then(async res => {
    const decryptedData = await decrypt(res.data.result);

    dispatch({
      type: CHANGE_STATE_LOGIN_MODAL,
      payload: { type: 'progress' }
    })
    const { rolesInfo, tokenResult, idCertificate, nameCertificate } = decryptedData
    if (!!tokenResult) {
      const { token, refreshToken } = tokenResult
      const pubKey= sessionStorage.getItem('pubKeyServer')
      createCookie({jwt:token,rfsht: refreshToken, pk: pubKey}, myCookie)
      setAuthToken(token, refreshToken)
      const decoded = jwt_decode(token)
      dispatch(setCurrentUser(decoded))
      if (decoded) redirectAfterLogin(decoded.role, history)
      dispatch({ type: SET_LOGIN_TYPE, payload: 'withCertificate' })
      dispatch({ type: CLEAR_ERRORS })
    } else {
      dispatch({
        type: SET_ROLES,
        payload: rolesInfo.roles
      })
    }
    dispatch({ type: SET_LOGIN_TYPE, payload: 'withCertificate' })
    dispatch({ type: CLEAR_ERRORS })
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
    dispatch({
      type: CHANGE_STATE_LOGIN_MODAL,
      payload: { type: 'progress' }
    })
  })
}

export const selectRole = (history, body) => async (dispatch) => {
  const encryptedBody = await encrypt(body);
  dispatch({
    type: CHANGE_STATE_LOGIN_MODAL,
    payload: { type: 'progress' }
  })
  await axios.post(
    `${API_ENDPOINT}${SELECT_ROL}`, { 'data': encryptedBody }, { headers: getHeaders() },
  ).then(async res => {
    const { tokenResult } = await decrypt(res.data.result);
    console.log(tokenResult);
    dispatch({
      type: CHANGE_STATE_LOGIN_MODAL,
      payload: { type: 'progress' }
    })

    const { token, refreshToken } = tokenResult
    setAuthToken(token, refreshToken)
    const decoded = jwt_decode(token)
    dispatch(setCurrentUser(decoded))
    if (decoded) redirectAfterLogin(decoded.role, history)
    dispatch({ type: SET_LOGIN_TYPE, payload: 'withCertificate' })
    dispatch({ type: CLEAR_ERRORS })
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
    dispatch({
      type: CHANGE_STATE_LOGIN_MODAL,
      payload: { type: 'progress' }
    })
  })
}

export const changeRole = (history, body) => async (dispatch) => {
  dispatch(closewriteNotificationModal())
  const encryptedBody = await encrypt(body);
  dispatch({
    type: CHANGE_STATE_LOGIN_MODAL,
    payload: { type: 'progress' }
  })
  await axios.post(
    `${API_ENDPOINT}${CHANGE_ROL}`, { 'data': encryptedBody }, { headers: getHeaders() },
  ).then(async res => {
    const { tokenResult } = await decrypt(res.data.result);
    dispatch({
      type: RESET_DASHBOARD
    })
    dispatch({
      type: CHANGE_STATE_LOGIN_MODAL,
      payload: { type: 'progress' }
    })

    const { token, refreshToken } = tokenResult
    setAuthToken(token, refreshToken)
    const decoded = jwt_decode(token)
    dispatch(setCurrentUser(decoded))
    if (decoded) redirectAfterLogin(decoded.role, history)
    dispatch({ type: SET_LOGIN_TYPE, payload: 'withCertificate' })
    dispatch({ type: CLEAR_ERRORS })
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
    dispatch({
      type: CHANGE_STATE_LOGIN_MODAL,
      payload: { type: 'progress' }
    })
  })
}

export const resetNewQr = (userData) => async (dispatch) => {
  dispatch({
    type: CHANGE_STATE_LOGIN_MODAL,
    payload: { type: 'progress' }
  })
  await axios.post(
    `${API_ENDPOINT}${RESET_QR}${userData}`
  ).then(res => {
    dispatch({
      type: CHANGE_STATE_LOGIN_MODAL,
      payload: { type: 'progress' }
    })
    if (res.data.statusCode === 200) {
      dispatch({
        type: CHANGE_STATE_LOGIN_MODAL,
        payload: { type: 'sendEmail' }
      })
    }
    dispatch({ type: CLEAR_ERRORS })
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
    dispatch({
      type: CHANGE_STATE_LOGIN_MODAL,
      payload: { type: 'progress' }
    })
  })
}

export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  }
}

/**
 * AuthAction logoutUser ( set token for close session, use history param for redirect to login page  )
 *
 * @param {history} history
 */
export const logoutUser = (history, myCookie) => async (dispatch, getState) => {
  for(const [key, val] of Object.entries(myCookie.getAll())){
    myCookie.remove(key)
  }
  var type = getState().authReducer.typeOfLogin
  setAuthToken(false)
  dispatch(setCurrentUser({}))
  dispatch({ type: RESET_DASHBOARD })
  if (!!history)
    if (type !== '' && type === 'withOutCertificate') {
      history.push({ pathname: '/login-user', state: { message: 'Sesión finalizada exitosamente' } })
    } else {
      history.push({ pathname: '/', state: { message: 'Sesión finalizada exitosamente' } })
    }
  dispatch({
    type: LOGOUT
  })
  dispatch({
    type: STATE_CLEAR_FILTER
  })
  dispatch({
    type: CLEAR_INTERVAL_TO_BE_NOTIFIED
  })
}

export const resetPassword = (userData) => async (dispatch) => {
  await axios.post(`${API_ENDPOINT + RECOVER_PASSWORD}`, userData).then(res => {
    dispatch({
      type: CHANGE_STATE_LOGIN_MODAL,
      payload: { type: 'sendEmailNewPassword' }
    })
    dispatch({ type: CLEAR_ERRORS })
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
  })
}

export const clearLogin = () => async (dispatch) => {
  dispatch({ type: CLEAR_LOGIN })
}

export const logout = () => async (dispatch) => {
  dispatch({ type: LOGOUT })
}

export const checkLoginStatus = () => async (dispatch, getState) => {
  await axios.post(`${API_ENDPOINT + CHECK_LOGIN_STATUS}`).then(async res => {
    const { pubKey, status, captcha, remainingTime, accessTime } = res.data.result
    if (!sessionStorage.getItem('pubKeyServer')) sessionStorage.setItem('pubKeyServer', pubKey)
    if (!sessionStorage.getItem('secretKey')) await createSharedKey(pubKey)
    // dispatch({type:RESET_CAPTCHA})
    // dispatch({type: VALID_TOKEN, payload: {}})
    // dispatch({ type: SET_LOGIN_STATUS, payload: { status: status ? status: 'ALLOWED', captcha: !!captcha, 
    // remainingTime: remainingTime? remainingTime : null, accessTime } })
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
  })
}

export const verifyCaptcha = (token) => async (dispatch, getState) => {
  await axios.post(`${API_ENDPOINT + VERIFY_CAPTCHA}`, {token: token}).then(async res => {
    dispatch({type: VALID_TOKEN, payload: res.data.result})
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
  })
}

export const verifyCaptchaV2 = (token) => async (dispatch, getState) => {
  await axios.post(`${API_ENDPOINT + VERIFY_CAPTCHA_V2}`, {token: token}).then(async res => {
    dispatch({type: VALID_TOKEN, payload: {valid: res.data.result.success}})
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
  })
}

export const showChangeRoleModal = (moreThanOne) => async (dispatch, getState) => {
  if (moreThanOne) dispatch({ type: CHANGE_ROLE_STATE_MODAL, payload: { type: 'changeRoleErrorPlusTwoActions' } })
  else dispatch({ type: CHANGE_ROLE_STATE_MODAL, payload: { type: 'changeRoleError' } })
}