import React, { Component } from 'react'
import { MainSegment } from '../../../components/Sengment/MainSegment'
import AllAuditorImproperClients from './allAuditorImproperClients/AllAuditorImproperClients'
import ContainerSearchImproperClients from '../../../components/Search/SearchImproperClients/ContainerSearchImproperClients'

export const AuditorImproperClients = (props)=> {
    return (
      <MainSegment>
        <ContainerSearchImproperClients showAdvancedSearch={true} showFilters={true} showOldestNewest={false} showAZ={true} showPopUpFilter={true}/>
        <AllAuditorImproperClients history={props.history}/>
      </MainSegment>
    )
}

export default AuditorImproperClients
