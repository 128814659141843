//set app name to all project
export const APP_NAME = process.env.name || 'Email service'
//token prefix eg. 'Bearer ' or 'Token '
export const TOKEN_PREFIX = 'Bearer '
export const API_ENDPOINT = process.env.NODE_ENV === 'production' ?
    'https://amis.registraduria.gov.co' :
    'https://amis.registraduria.gov.co'
export const API_FILE = process.env.NODE_ENV === 'production' ?
    'https://amis.registraduria.gov.co' :
    'https://amis.registraduria.gov.co'
export const defaultResponses = {
    noResponseFromApi: 'No hay respuesta por parte del servidor. Por favor intente de nuevo más tarde.'
}
export const configMoment = {
    months: 'enero_febrero_marzo_abril_mayo_junio_julio_agosto_septiembre_octubre_noviembre_diciembre'.split('_'),
    monthsShort: 'ene_feb_mar_abr_may_jun_jul_ago_sept_oct_nov_dec'.split('_'),
    weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'.split('_'),
    weekdaysShort: 'dom_lun_mar_mier_jue_vier_sab.'.split('_'),
    weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_')
}

export const RECAPTCHA_KEY = '6LcRZGApAAAAAHCV05jVL3jsVJCPoXyQcVaazF1L'

export const RECAPTCHA_KEY_V2 = '6LcklekpAAAAAMzZixJDBjXL2B6tlobj6Aa6yLIH'

export const HTTP_ONLY = process.env.NODE_ENV === 'production' ?
  true:
    true