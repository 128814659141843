import {
  GET_REPORTS_LIST,
  DETAIL_CSV_ERROR,
  REPORT_DETAIL,
  CHANGE_TYPE_REPORT_CURRENT,
  GET_CLIENTS_REPORT,
  GET_All_CLIENTS_REPORT,
  CHANGE_DATA_CREATE_REPORT,
  GET_RESPONSE_CREATE_REPORT,
  RESPONSE_BASE64_REPORT,
  SUCCESS_CREATE_REPORT,
  CHANGE_TO_BE_NOTIFIED,
  INTERVAL_TO_BE_NOTIFIED,
  CLEAR_INTERVAL_TO_BE_NOTIFIED
} from "../actions/actionsUtilities/types"

const initialState = {
  reportsList:[],
  elementsCount: 0,
  clientsList: [],
  typeReportCurrent: "STATUS",
  detailCsvError:{
    typeReport: '',
    initialDate: '',
    finalDate: '',
    documentNumber: '',
    subject:'',
    reportStatus: '',
    base64Report: '',
  },
  reportDetail: {
    reportSerialID:'',
    initialDate:'',
    finalDate:'',
    client:'',
    notificationStatus:[],
    documentRecipient:'',
    subject:'',
    reportStatus:'',
    base64:'',
    reportType:'',
  },
  dataCreateReport: {
    reportType:'',
    status:["ENABLE","DISABLE"],
    providerType:''
  },
  responseCreateReport: {},
  responseBase64Report: {},
  successCreateReport: false,
  toBeNotifiedList: [],
  intervalToBeNotified: null,
}
export default (state = initialState, action) => {
    switch (action.type) {
      case GET_REPORTS_LIST:
        return {
          ...state,
          reportsList: action.payload.reportsList,
          elementsCount: action.payload.elementsCount
        }
      case DETAIL_CSV_ERROR:
        return {
          ...state,
          detailCsvError: action.payload
        }
      case REPORT_DETAIL:
        return {
          ...state,
          reportDetail: action.payload
        }
      case CHANGE_TYPE_REPORT_CURRENT:
        return {
          ...state,
          typeReportCurrent: action.payload
        }
      case GET_CLIENTS_REPORT:
        return {
          ...state,
          clientsList: [{ key: 'empty', text: '', value: ''}, ...action.payload.map((client,index) => { 
          return {key:index, text:client.name, value:client.companySerialID}})]
        }
      case GET_All_CLIENTS_REPORT:
          return {
            ...state,
            clientsList: [{ key: 'empty', text: '', value: ''}, ...action.payload.map((client,index) => { 
              return {key:index, text:client.name, value:client.companySerialID}})]
          }
      case CHANGE_DATA_CREATE_REPORT:
        return {
          ...state,
          dataCreateReport: action.payload
        }
      case GET_RESPONSE_CREATE_REPORT:
        return {
          ...state,
          responseCreateReport: action.payload
        }
      case RESPONSE_BASE64_REPORT:
        return {
          ...state,
          responseBase64Report: action.payload
        }
      case SUCCESS_CREATE_REPORT:
        return {
          ...state,
          successCreateReport: action.payload
        }
      case CHANGE_TO_BE_NOTIFIED:
        return {
          ...state,
          successCreateReport: true,
          toBeNotifiedList: action.payload
        }
      case INTERVAL_TO_BE_NOTIFIED:
        return {
          ...state,
          intervalToBeNotified: action.payload
        }
      case CLEAR_INTERVAL_TO_BE_NOTIFIED:
        clearInterval(state.intervalToBeNotified)
        return {
          ...state,
          intervalToBeNotified: null
        }
      default:
        return state
    }
  }