import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { ReactComponent as LeftArrowIcon } from '../../../assets/icons/sent/leftArrow.svg'
import { ReactComponent as ExportIcon } from "../../../assets/icons/reports/exportIcon.svg";
import { clearActiveAgreementDetail } from '../../../actions/agreementActions'
import { createExportPDFService, createExportCSVService, getBase64ReportService } from '../../../interceptors/services/reports.service'
import { changeMessageModal } from '../../../actions/modalActions'
import { ExportButton } from '../../../components/Search/ExportButton'
import ExportButtons from './ExportButtons'
import { UseWindowDimensions } from '../../../utils/UseWindowsDimentions'

const Container = styled.div`
  margin: 30px 29px 7px 24px;
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 800px){
    flex-wrap: wrap;
  }
`
const ContainerArrowAndText= styled.div`
  display:flex;
  align-items: center;
  gap: 20px;
`

const Title = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #133F4F;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow-wrap: anywhere;
`
const LeftArrow = styled.button`
  padding: 0;
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor: pointer;
  line-height: 0;
`
const ContainerButtons = styled.div`
  display:flex;
  gap: 16px;
  @media only screen and (max-width: 800px){
    margin-top: 15px;
    margin-left: auto;
  }
`

const Button = styled.button`
  background: #FFFFFF;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  color: #22C1D0;
  width: max-content;
  height: 40px;
  font-size: 16px;
  font-family: Roboto;
  padding: 8px 16px 8px 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  &:disabled {
    opacity: 0.3;
    cursor: no-drop;
  }
  @media only screen and (max-width: 740px) {
    font-size: 25px;
  }
`

const ButtonText = styled.label`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
  letter-spacing: 0px;
  color: #22C1D0;
  padding-left: 8px;
  cursor: pointer;
`

const Header = (props) => {

  const { detailAgreement } = useSelector((state) => state.agreementReducer)
  const dispatch = useDispatch()
  const windowDimensions = UseWindowDimensions()
  // const [loaderPDF, setLoaderPDF] = useState(false)
  // const [loaderCSV, setLoaderCSV] = useState(false)
  // const [createPDF, setCreatePDF] = useState()
  // const [createCSV, setCreateCSV] = useState()
  // const [responseBase64Report, setResponseBase64Report] = useState()

  // const downloadPDF = (base64, fileName) => {
  //   const downloadLink = document.createElement("a");
  //   downloadLink.href = base64;
  //   downloadLink.download = fileName
  //   downloadLink.click();
  // }

  // const downloadCSV = (base64, fileName) => {
  //   const downloadLink = document.createElement("a");
  //   downloadLink.href = base64;
  //   downloadLink.download = fileName;
  //   downloadLink.click();
  // }

  // const fetchCreatePDF = async (param) => {
  //   try{
  //     const data = await createExportPDFService(param)
  //     setCreatePDF(data)
  //   }catch(error){
  //     setLoaderPDF(false)
  //     dispatch(changeMessageModal({ type: 'exportInterceptorError' }))
  //   }
    
  // }

  // const fetchCreateCSV = async (body) => {
  //   try{
  //     const data = await createExportCSVService(body)
  //     setCreateCSV(data)
  //   }catch(error){
  //     setLoaderCSV(false)
  //     dispatch(changeMessageModal({ type: 'exportInterceptorError' }))
  //   }
    
  // }

  // const fetchGetBase64Report= async (body) => {
  //   const data = await getBase64ReportService(body)
  //   setResponseBase64Report(data)
  // }

  const handleOnClick = () => {
    props.history.goBack()
    dispatch(clearActiveAgreementDetail())
  }

  // const onclickPDF = () => {
  //   setLoaderPDF(true)
  //   fetchCreatePDF(detailAgreement.id)
  // }

  // const onclickCSV = () => {
  //   setLoaderCSV(true)
  //   const body= {
  //     reportType: "DETAIL_AGREEMENT",
  //     agreementSerialID :detailAgreement.id
  // }
  //   fetchCreateCSV(body)
  // }

  // useEffect(()=>{
  //   if(!!loaderCSV){
  //     downloadCSV(responseBase64Report.base64, createCSV.fileName)
  //     setLoaderCSV(false)
  //   }
  // }, [responseBase64Report])

  // useEffect(() => {
    
  //   if(!!createPDF){
  //     downloadPDF(createPDF.data.base64, createPDF.data.fileName)
  //     setLoaderPDF(false)
  //   }else{

  //   }
  // }, [createPDF])

  // useEffect(() => {
    
  //   if(!!createCSV){
  //     const body = {
  //       reportSerialID: createCSV.reportSerialID
  //     }
  //     fetchGetBase64Report(body)
  //   }else{

  //   }
  // }, [createCSV])

  return (
    <Container>
      <ContainerArrowAndText>
        <LeftArrow
          onClick={handleOnClick}
          >
          <LeftArrowIcon />
        </LeftArrow>
        {
          <Title>
            {detailAgreement.providerData.name
              ? `Vinculación ${detailAgreement.providerData.name} y ${detailAgreement.clientData.name}`
              : "Detalle de la vinculación"}

          </Title>
          }
      </ContainerArrowAndText>
      {windowDimensions.width > 767 && <ExportButtons/>}
      
      {/* <ContainerButtons>
        <Button onClick={onclickPDF} disabled={loaderPDF}>
          {!!loaderPDF 
          ?
            <i class={"loading spinner icon"}></i>
          :
            <ExportIcon/>
          }
          <ButtonText>{"Exportar PDF"}</ButtonText>
        </Button>

        <Button onClick={onclickCSV} disabled={loaderCSV}>
          {!!loaderCSV 
          ?
            <i class={"loading spinner icon"}></i>
          :
            <ExportIcon/>
          }
          <ButtonText>{"Exportar CSV"}</ButtonText>
        </Button>
      </ContainerButtons> */}
    </Container>
  )
}

Header.propTypes = {
  history: PropTypes.any.isRequired,
}

export default Header
