import React from 'react'
import styled from 'styled-components'
import { ReactComponent as NotFoundNotification } from '../../../../../assets/icons/sent/not_found.svg'
import { Dimmer, Loader } from 'semantic-ui-react'
import { CustomComponent } from '../../../../../components/CustomComponent'

const NotFoundCell = styled.tr`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(54px * 10);

  &:hover {
    border: none !important;
    box-shadow: unset !important;
  }
`
const Message = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #133F4F;
  margin-bottom: 15px;
`
const ContainerScroll = styled.div`
  width: 100%;
  height: 67.5vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 18px;
  padding-left: 18px;

  ::-webkit-scrollbar-track {
    background: #CCCCCC;
  }

  ::-webkit-scrollbar-thumb {
    background: #9D9D9C !important;
  }

  ::-webkit-scrollbar:vertical {
    width: 5px;
  }
`
const CardAttachment = (props) => {

  const listData = () => {
    const { list, loader} = props
    if (!list.length && !loader) {
      switch (props.typeList) {
        default:
          return <NotFoundCell>
            <Message>¡No existen coincidencias para esta búsqueda!</Message>
            <NotFoundNotification />
          </NotFoundCell>
      }
    } else {
      let letterBefore=''
      let initial= false
      return list
        .map((v, index) =>{
          let letterCurrent= v.fileName.substr(0,1).toUpperCase()
          if(
            letterBefore!==letterCurrent){
            if(letterBefore=== ''){
              initial=true
            }else{
              initial=false
            }
            letterBefore= letterCurrent
            }else{
              letterCurrent= ''
            }
            return(
            <CustomComponent
              key={index}
              data={v}
              component={props.cardComponent} 
              letter={letterCurrent}  
              isInitial={initial}         
              {...props}
              />
            )
          }
          )
    }
  }
  return (
    <Dimmer.Dimmable active={props.loader.toString()}>
      <Dimmer active={props.loader} inverted>
        <Loader size='medium'>Cargando...</Loader>
      </Dimmer>
      <ContainerScroll>
        {listData()}
      </ContainerScroll>
    </Dimmer.Dimmable >
  )
}

export default CardAttachment