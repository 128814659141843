import React from 'react'
import {connect} from 'react-redux'
import EditorQuill from '../EditorQuill/EditorQuill'
import PropTypes from "prop-types";

const RichEditor = (props) => {
  return (
    <EditorQuill 
      history={props.history}
      stateFormValidator={props.stateFormValidator}
      validateSubject={props.validateSubject}
    />
    )
}

RichEditor.propTypes = {
}

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps, null)(RichEditor)