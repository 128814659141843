import React, {Component} from 'react'
import styled from 'styled-components'


const DivContainerLoading = styled.div`
  width: 100%;
  height: 50%;
  padding-top: 30%;
  text-align: center;
`
const DivContainerText = styled.div`
  width: 100%;
  height: 50%;
  padding-top: 15%;
`

const DivLoading = styled.div`
  width: 6.5rem;
  aspect-ratio: 1;
  display: inline-grid;
  vertical-align: middle;
  mask: conic-gradient(from 22deg, #0001, #000);
  animation: spin 1s steps(8) infinite;

  &,
  &:before {
    --gradient: linear-gradient(#2EC3D1 0 0);
    background: var(--gradient) 50%/34% 8% space no-repeat,
    var(--gradient) 50%/8% 34% no-repeat space;
  }

  &:before {
    content: "";
    transform: rotate(45deg);
  }

  @keyframes spin {
    to {
      transform: rotate(1turn);
    }
  }
`
const Text = styled.p`
  color: #133F4F;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
`

class LoadingModalDescription extends Component {
  render() {
    return (
      <>
        <DivContainerLoading>
          <DivLoading></DivLoading>
        </DivContainerLoading>
        <DivContainerText>
          <Text>Cargando registros</Text>
        </DivContainerText>
      </>
    )
  }
}

export default LoadingModalDescription