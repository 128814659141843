import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Modal } from 'semantic-ui-react'
import styled, { keyframes } from 'styled-components'

import { changeStateMassiveIncompleteModal,openwriteNotificationModal } from '../../actions/modalActions'
import PrimaryButton from '../../components/PrimaryButton'
import { ReactComponent as InfoIcon } from '../../assets/icons/write/info.svg'

import './MassiveLoadModal.css'
import { clearEditorChange } from "../../actions/writerActions";
import PropTypes from "prop-types";
import { API_ENDPOINT } from '../../config/config'

const ModalElement = styled(Modal)`
  padding: 40px !important;
  width: 496px !important;
`

const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const ModalMessage = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;


`
const ModalActions = styled.div`
  margin-top: 32px;
`
const Texto = styled.p`
  color: #133F4F;
  font-family: Roboto;
  font-weight: 900;
  font-size: 17px;
  font-style: normal;
  line-height: 35px;
  text-align: center;
  margin-bottom: 15px !important;
`

const DividerContent = styled.div`  
  
  position: relative;
  max-width: 104px;
  max-height:104px;
  justify-content:center;
  margin: 30px 0 30px 0;
`


const MessageModal = (props) => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (props.modalState.status === false) {
      dispatch(clearEditorChange(false))
    }
  }, [props.modalState.status, dispatch])

  const modalData = (type) => {
    const data = {
      massiveIncomplete:{
        icon: <InfoIcon />,
        InitialMessage: "",
        message: "No fue posible cargar el total de los ciudadanos, puedes descargar el archivo con errores, corregirlo y volverlo a cargar.",
        message2: "Podrás descargar este archivo en futuras ocasiones ingresando al detalle del envío desde la lista en la sección “Enviados”.",
        buttonMessage: 'Continuar',

      }
    }
    return data[type]
  }

  const onClose = () => {
    props.changeStateMassiveIncompleteModal()
    props.openwriteNotificationModal()

  }

  const data = modalData(props.modalState.type)
  return (
    <ModalElement
      open={props.modalState.status}
      onClose={onClose}
      closeOnDimmerClick = {props.modalState.type === 'finish' ? false : true}
      className= {props.modalState.type === 'noAgreementsActive' && 'modalNoAgreements'}
    >
      <ModalContent>
      {!!data.icon && data.icon}
          <ModalMessage>
            <Texto>{data.InitialMessage}</Texto>
            
            {!props.modalState.message
              ? data.message
              : props.modalState.message}
            <div className='second-message'>
                {data.message2}
            </div>

            <div className='second-message'>
                <a className='downloadAttachment' href={props.modalState.urlFileError}>Descargar archivo de ciudadanos no cargados</a>
            </div>
          </ModalMessage>

        <ModalActions>
            <PrimaryButton
              txtBtn={data.buttonMessage}
              onPress={onClose}
            />
          </ModalActions>
      </ModalContent>
    </ModalElement>
  )
}
MessageModal.propTypes = {
  modalState: PropTypes.object,
  changeStateMassiveIncompleteModal: PropTypes.func,
  openwriteNotificationModal:PropTypes.func,
  clearEditorChange: PropTypes.func,
}
const mapStateToProps = (state) => ({
  modalState: state.modalReducer.massiveIncompleteModal
})

const mapDispatchToProps = {
  changeStateMassiveIncompleteModal,
  openwriteNotificationModal,
  clearEditorChange
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageModal)
