import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Table } from 'semantic-ui-react'
import styled from 'styled-components'
import htmlToText from 'html-to-text'
import { useDispatch, useSelector } from 'react-redux'
import './styles.css'
import { Cell, CellAttatchedDraft, CellDateAndDeleteDraft, CellDelete, CellIcon, CellIconAttachment, CellIconSelect, CellReceipentDraft, CellSubjectDesc } from '../../../components/Table/CellComponent'
import { changeMessageModal, openwriteNotificationModal, warningFormModalDrafts } from '../../../actions/modalActions'
import { getDraft,getDraftV2, setDraftSerialID } from '../../../actions/draftsActions'
import { UseWindowDimensions } from '../../../utils/UseWindowsDimentions'
import { divide } from 'lodash'
import { CheckBoxGeneric } from '../../../components/CheckboxForm/CheckBoxGeneric'
import { ReactComponent as Delete } from '../../../assets/icons/delete.svg'
import { ReactComponent as Attached } from '../../../assets/icons/sent/attached.svg'

const Row = styled(Table.Row)`
  cursor: pointer;
  height: 50px !important;
  @media only screen and (min-width: 100px) and (max-width: 768px) {
    height: auto !important;
    display: flex !important;
    flex-direction: column;
    border-bottom: 1px solid #B2B2B2 !important;
    padding: 0.5em 0em!important;
    width: 100%;
    box-sizing: content-box;
  }
  @media only screen and (max-width: 768px) {
    display: flex!important;
    flex-direction: row !important;
    width: 100%;
    box-sizing: content-box;
  }
`

const Divider0 = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  `

const Divider1 = styled.div`
  display: flex;
  flex-direction: column;
  padding-inline:10px;
  width: 5%;
  `
  const Divider2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  max-width: 70%;
  overflow: hidden;
  
  text-overflow: ellipsis;
  white-space: nowrap;
  `

  const Divider3 = styled.div`
  display: flex;
  flex-direction: column;
  padding-inline:14px;
  width: 25%;
  justify-content: space-between;
  `

  const Divider4 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: #7C7C74;
  `

  const DraftPrincipalTitle = styled.p`
  color: #C3132E;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21.6px; 
  `
  const DraftTitle = styled.p`
  color:  #000;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;

  `

  const DraftText = styled.p`
  color:  #000;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  `
  const DeleteButton = styled.div`
  display: flex;
  cursor: pointer;
  font-size: 14px;
  color: #22C1D0;
  justify-content: flex-end;
`;

const Divider2Responsive = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  max-width: 70%;
  overflow: hidden;
  
  text-overflow: ellipsis;
  white-space: nowrap;
  display: grid;
  grid-template-columns:  1fr;
  `

const RowTableDrafts = (props) => {
  
  const windowDimensions = UseWindowDimensions();

  const dispatch = useDispatch()
  const formatDate = (notificationDate) => (moment(notificationDate).format('DD-MM-YYYY') === moment().format('DD-MM-YYYY'))
    ? moment(notificationDate).format('h:mm a') : moment(notificationDate).format('D MMM')
  const { hasAgreements } = useSelector((state) => state.dashboardReducer)

  const [selectDraft, setSelectDraft] = useState(false)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    setSelectDraft(props.activeAll)
  }, [props.activeAll])

  useEffect(() => {
    setSelectDraft(false)
  }, [props.data])

  const handleSelectDraft = (e) => {
    e.stopPropagation();
    setSelectDraft(!selectDraft)
    if (!selectDraft) {
      props.addCheckedRow(props.data.draftSerialID)
    } else {
      props.removeCheckedRow(props.data.draftSerialID)
    }
  }
  const handleClick = () => {
    if (hasAgreements == true || hasAgreements == undefined) {
      dispatch(setDraftSerialID(props.data.draftSerialID))
      dispatch(getDraftV2(props.data.draftSerialID))
      dispatch(openwriteNotificationModal())
    } else {
      dispatch(changeMessageModal({ type: 'noAgreementsActive' }))
    }
  }
  const handleClickDelete = () => {
    dispatch(warningFormModalDrafts({ type: 'warningDeleteDraft', id: props.data.draftSerialID }))
  }

  const getDescriptionText = (description) => {
    let text = htmlToText.fromString(description, { wordwrap: 130, ignoreImage: true, ignoreHref: true })
    text = text.split('\n')[0]
    return text
  }

  return (
    windowDimensions.width>768?
      <Row  key={props.data.draftSerialID} onMouseEnter={() => setIsVisible(true)} onMouseLeave={() => setIsVisible(false)} >
        
        <CellReceipentDraft  onChange={handleSelectDraft} checked={selectDraft}  data={props.data}  handleClick={handleClick} />

        <CellAttatchedDraft handleClick={handleClick} haveAttachments ={props.data.haveAttachments} />

        <CellSubjectDesc columnName={'Asunto'} width={7} onClick={handleClick}
          text={props.data.description == undefined || props.data.description == '' || props.data.description == ' ' ? 'Sin mensaje' : getDescriptionText(props.data.description)}
          subject={props.data.subject == undefined || props.data.subject == '' || props.data.subject == ' ' ? '(Sin asunto)' : props.data.subject} />

        <CellDateAndDeleteDraft onClick={handleClick} createdDate ={props.data.createdDate} formatDate={formatDate} haveAttachments={props.data.haveAttachments} handleSelectDraft={handleSelectDraft} selectDraft={selectDraft} isVisible={isVisible} handleClickDelete={handleClickDelete} status={props.data.status}>

        </CellDateAndDeleteDraft>
    </Row>
    : 
    <Row key={props.data.draftSerialID} onMouseEnter={() => setIsVisible(true)} onMouseLeave={() => setIsVisible(false)} >
      <Divider0>
        <Divider1>
            <CheckBoxGeneric onChange={handleSelectDraft} checked={selectDraft} />
        </Divider1>
        <Divider2Responsive onClick={handleClick}>
          <DraftPrincipalTitle>
            Borrador
          </DraftPrincipalTitle>
          <DraftTitle>
            {props.data.campaignName == undefined || props.data.campaignName == [] ? '(Sin destinatario)' : props.data.campaignName}
          </DraftTitle>
          <DraftTitle> 
            {props.data.subject == undefined || props.data.subject == '' || props.data.subject == ' ' ? '(Sin asunto)' : props.data.subject}
          </DraftTitle>
          <DraftText>
            {props.data.description == undefined || props.data.description == '' || props.data.description == ' ' ? 'Sin mensaje' : getDescriptionText(props.data.description)}
          </DraftText>
        </Divider2Responsive>
        
        <Divider3 >
          <Divider4>
            {formatDate(props.data.createdDate)}
            {props.data.haveAttachments &&
            <div >
              <Attached width={'15px'}/>
            </div>
}
          </Divider4>
          {
            !selectDraft &&
            <DeleteButton onClick={handleClickDelete}>
              <Delete style={{ margin: '2px 6px 0px 0px' }} />
              Eliminar
            </DeleteButton>
          }
        </Divider3>
      </Divider0>
    </Row>
  )
}


export default RowTableDrafts