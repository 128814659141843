import React, { useState } from 'react'
import { Table } from 'semantic-ui-react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { ReactComponent as EditImage } from '../../../../src/assets/icons/clients/edit-button.svg'
import { useDispatch } from 'react-redux'
import { getClientDetail, getClientEdit } from '../../../actions/clientActions'
import { useHistory } from 'react-router-dom'
import { Cell } from '../../../components/Table/CellComponent'

const SentCell = styled(Table.Cell)`
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  max-width: 1px !important;
  padding: 10px !important;
`

const Row = styled(Table.Row)`
  cursor: pointer !important;
  height:50px;
  @media only screen and (max-width: 768px) {
    height:unset;
    display: flex !important;
    flex-wrap: wrap;
    border-bottom: 1px solid #979e9f !important;
    padding: 0.5em 0em !important; 
`
const Edit = styled(EditImage)`
  float: right !important;
  margin: 4px 25px 0 0 !important;
`



const RowTableClientsOperator = (props) => {

  const dispatch = useDispatch()
  const [Isvisible, setIsVisible] = useState(false);
  const history = useHistory()

  const redirectToClientDetail = () => {
    sessionStorage.setItem("id", props.data.companySerialID)
    dispatch(getClientDetail(props.data.companySerialID))
    history.push({ pathname: '/clients-detail/' + props.data.companySerialID })
  }

  const handleClick = (e) => {
    e.stopPropagation()
    sessionStorage.setItem("id", props.data.companySerialID)
    dispatch(getClientEdit(props.data.companySerialID))
    history.push('/editClients')
  }

  return (
    <Row
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
      onClick={redirectToClientDetail}
    >
      <Cell columnName={props.headerTable[0].name} width={3}  text={props.data.name}  ></Cell>
      <Cell columnName={props.headerTable[1].name} width={3}  text={props.data.numberDocumentCompany}  ></Cell>
      <Cell columnName={props.headerTable[2].name} width={3}  text={props.data.phone}  ></Cell>
      <Cell columnName={props.headerTable[3].name} width={3}  text={props.data.email}  ></Cell>
    </Row>
  )
}

RowTableClientsOperator.propTypes = {
  data: PropTypes.object.isRequired
};

export default RowTableClientsOperator