import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from 'semantic-ui-react'

import { ReactComponent as Starred } from '../../assets/icons/sent/starred.svg'

const CustomButton = styled(Button)`
  svg path {
    ${props => props.starred === 'false' && 'fill: #fff;'}
    ${props => props.starred === 'false' && 'stroke: #979E9F;'}
  }
  margin: 0px ;
  padding: 0px 0 !important;
  border: none !important;
  background: none !important;
  box-shadow: none !important;
  display:flex !important;
  align-items:center !important;
  justify-content: center;
  width: 24px;
  height:24px;
  
 
  :hover {
    svg path {
      ${props => props.starred === 'false' && 'stroke: #000000;'}
    }
  }
`
export default class StarredButton extends Component {
  static propTypes = {
    onClick: PropTypes.func
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = prevState
    if (nextProps.active !== prevState.active)
      update.active = nextProps.active
    return Object.keys(update).length ? update : null
  }

  state = {
    active: false
  }

  handleChange = (e) => {
    if(!this.props.noChange){
    e.stopPropagation()
    this.setState(prevState => ({ active: !prevState.active }))
    this.props.onClick(e)
    }
  }

  render() {
    return (
      <CustomButton
        style={this.props.customStyles}
        onClick={this.handleChange}
        starred={this.state.active.toString()}
      >
        <Starred />
      </CustomButton>
    )
  }
}




