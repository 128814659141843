/*Errors*/
export const GET_ERRORS = 'GET_ERRORS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
export const FORM_ERROR = 'FORM_ERROR'

/*Auth*/
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const LOGOUT = 'LOGOUT'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RECOVER_PASSWORD = 'RECOVER_PASSWORD'
export const UPDATE_SESSION_ACTIVE = 'UPDATE_SESSION_ACTIVE'
export const CLEAR_LOGIN = 'CLEAR_LOGIN'
export const CLEAR_CERTIFICATES = 'CLEAR_CERTIFICATES'
export const CLEAR_ROLES = 'CLEAR_ROLES'
export const VALID_TOKEN = 'VALID_TOKEN'
export const UPDATE_ACTIONS_LIST = 'UPDATE_ACTIONS_LIST'
export const DELETE_FROM_ACTION_LIST = 'DELETE_FROM_ACTION_LIST'

/*Auht With Certificate*/
export const CHANGE_STATE_LOGIN_MODAL = 'CHANGE_STATE_LOGIN_MODAL'
export const GET_LIST_CERTIFICATE = 'GET_LIST_CERTIFICATE'
export const GET_LIST_CERTIFICATE_2 = 'GET_LIST_CERTIFICATE_2'
export const POST_VALIDATE_CERTIFICATE = 'POST_VALIDATE_CERTIFICATE'
export const GET_QR = 'GET_QR'
export const POST_LOGIN = 'POST_LOGIN'
export const SET_LOGIN_TYPE = 'SET_LOGIN_TYPE'
export const SET_ROLES = 'SET_ROLES'
export const SET_LOGIN_STATUS = 'SET_LOGIN_STATUS'
export const RESET_CAPTCHA = 'RESET_CAPTCHA'

/*Modal*/
export const CHANGE_STATE_OPEN_DATE_MODAL = ' CHANGE_STATE_OPEN_DATE_MODAL'
export const CHANGE_STATE_CLOSE_DATE_MODAL = 'CHANGE_STATE_CLOSE_DATE_MODAL'
export const CHANGE_STATE_OPEN_SEND_MASSIVE_MODAL = 'CHANGE_STATE_OPEN_SEND_MASSIVE_MODAL'
export const SHOW_STATE_ERROR_MESSAGE_SEND_MASSIVE_MODAL = 'SHOW_STATE_ERROR_MESSAGE_SEND_MASSIVE_MODAL'
export const REFRESH_STATE_ERROR_MESSAGE_SEND_MASSIVE_MODAL = 'REFRESH_STATE_ERROR_MESSAGE_SEND_MASSIVE_MODAL'
export const CHANGE_STATE_CLOSE_SEND_MASSIVE_MODAL = 'CHANGE_STATE_CLOSE_SEND_MASSIVE_MODAL'
export const CHANGE_STATE_EDITOR_TEXT_MODAL = 'CHANGE_STATE_EDITOR_TEXT_MODAL'
export const CHANGE_STYLES_MENU = 'CHANGE_STYLES_MENU'
export const CHANGE_SET_PATHNAME_CURRENT = 'CHANGE_SET_PATHNAME_CURRENT'
export const CHANGE_STATE_REFRESH_MODULE = 'CHANGE_STATE_REFRESH_MODULE'
export const CHANGE_STATE_CHANGE_PASSWORD = 'CHANGE_STATE_CHANGE_PASSWORD'
export const CHANGE_STATE_REPORT_MODAL = 'CHANGE_STATE_REPORT_MODAL'
export const CHANGE_STATE_LOADING_FILE_MASIVE = 'CHANGE_STATE_LOADING_FILE_MASIVE'
export const CHANGE_STATE_REPORTS_SEARCH_MODAL = "CHANGE_STATE_REPORTS_SEARCH_MODAL"
export const CHANGE_STATE_REPORTS_LOADING_MODAL = "CHANGE_STATE_REPORTS_LOADING_MODAL"
export const CHANGE_STATE_MASSIVE_INCOMPLETE_MODAL = "CHANGE_STATE_MASSIVE_INCOMPLETE_MODAL"
export const CHANGE_STATE_MESSAGE_MODAL_TWO = 'CHANGE_STATE_MESSAGE_MODAL_TWO'
export const CHANGE_STATE_FORM_CANCEL_AGREEMENT = 'CHANGE_STATE_FORM_CANCEL_AGREEMENT'
export const CHANGE_STATE_MODAL_REASON_CANCEL_AGREEMENT = 'CHANGE_STATE_MODAL_REASON_CANCEL_AGREEMENT'
export const HIDE_MESSAGE_MODAL = 'HIDE_MESSAGE_MODAL'

/*Users*/
export const GET_USERS_LIST = 'GET_USERS_LIST'
export const GET_COMPANY_USERS = 'GET_COMPANY_USERS'
export const GET_USER_EDIT = 'GET_USER_EDIT'
export const CHANGE_STATE_USER = 'CHANGE_STATE_USER'
export const SAVE_USER = 'SAVE_USER'
export const EDIT_USER = 'EDIT_USER'
export const INACTIVE_STATE_USER_FORM = 'INACTIVE_STATE_USER_FORM'
export const CHANGE_EDIT_USER = 'CHANGE_EDIT_USER'
export const ACTIVE_FORM_EDIT_USER = 'ACTIVE_FORM_EDIT_USER'
export const INACTIVE_FORM_EDIT_USER = 'INACTIVE_FORM_EDIT_USER'
export const SAVE_USER_CERTIFICATE = 'SAVE_USER_CERTIFICATE'
export const CHANGE_HAVE_CERTIFICATE = 'CHANGE_HAVE_CERTIFICATE'

/* Sent */
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS'
export const DETAIL_OF_NOTIFICATION = 'DETAIL_OF_NOTIFICATION'
export const DETAIL_OF_NOTIFICATION_MASSIVE = 'DETAIL_OF_NOTIFICATION_MASSIVE'
export const DOWNLOAD_REPORT = 'DOWNLOAD_REPORT'
export const DETAIL_OF_DATA_PDF = 'DETAIL_OF_DATA_PDF'
export const CHANGE_STATE_OPEN_PDF_MODAL = ' CHANGE_STATE_OPEN_PDF_MODAL'
export const CHANGE_STATE_CLOSE_PDF_MODAL = 'CHANGE_STATE_CLOSE_PDF_MODAL'
export const CHANGE_CHECKBOK_REPORT = 'CHANGE_CHECKBOK_REPORT'
export const DOWNLOAD_MASSIVE_REPORT = 'DOWNLOAD_MASSIVE_REPORT'
export const CLEAR_REPORT_MASSIVE_DATA = 'CLEAR_REPORT_MASSIVE_DATA'
export const CLEAR_DETAIL_NOTIFICATION_DATA = 'CLEAR_DETAIL_NOTIFICATION_DATA'
export const ON_LOADING_DOWNLOAD_REPORT='ON_LOADING_DOWNLOAD_REPORT'
export const HAVE_ATTACHMENTS_MASSIVE='HAVE_ATTACHMENTS_MASSIVE'
export const UPDATE_LIST_TO_DOWNLOAD = 'UPDATE_LIST_TO_DOWNLOAD'
export const DELETE_FROM_LIST_TO_DOWNLOAD = 'DELETE_FROM_LIST_TO_DOWNLOAD'
export const ADD_ALL_TO_LIST_TO_DOWNLOAD = 'ADD_ALL_TO_LIST_TO_DOWNLOAD'
export const DELETE_ALL_FROM_LIST_TO_DOWNLOAD = 'DELETE_ALL_FROM_LIST_TO_DOWNLOAD'
export const SET_DOWNLOAD_ATTACHMENTS_LINK = 'SET_DOWNLOAD_ATTACHMENTS_LINK'

/* State Filter */
export const STATE_FILTER_SEARCH_BAR = 'STATE_FILTER_SEARCH_BAR'
export const STATE_FILTER_POPUP_FILTER = 'STATE_FILTER_POPUP_FILTER'
export const STATE_FILTER_POPUP_ORDER = 'STATE_FILTER_POPUP_ORDER'
export const STATE_FILTER_PAGINACION = 'STATE_FILTER_PAGINACION'
export const STATE_FILTER_ADVANCED_SEARCH = 'STATE_FILTER_ADVANCED_SEARCH'
export const STATE_FILTER_ADVANCED_SEARCH_DATE = 'STATE_FILTER_ADVANCED_SEARCH_DATE'
export const STATE_DELETE_FILTER = 'STATE_DELETE_FILTER'
export const STATE_STARRED_FILTER = 'STATE_STARRED_FILTER'
export const STATE_TAB_FILTER = 'STATE_TAB_FILTER'
export const STATE_CLEAR_FILTER = 'STATE_CLEAR_FILTER'
export const STATE_INITIAL_PAGINATION = 'STATE_INITIAL_PAGINATION'
export const STATE_FILTER_POPUP_FILTER_AGREEMENTS = 'STATE_FILTER_POPUP_FILTER_AGREEMENTS'
export const STATE_FILTER_POPUP_FILTER_IMPROPER_CLIENTS = 'STATE_FILTER_POPUP_FILTER_IMPROPER_CLIENTS'
export const STATE_FILTER_POPUP_FILTER_DRAFTS = 'STATE_FILTER_POPUP_FILTER_DRAFTS'
export const STATE_FILTER_POPUP_FILTER_REPORTS = 'STATE_FILTER_POPUP_FILTER_REPORTS'
export const STATE_FILTER_POPUP_FILTER_CLIENTS_AND_PROVIDERS = 'STATE_FILTER_POPUP_FILTER_CLIENTS_AND_PROVIDERS'
export const VIEW_PAGINATION = 'VIEW_PAGINATION'
export const STATE_INITIAL_FILTER = 'STATE_INITIAL_FILTER'
export const ACTIVE_PAGINATION = 'ACTIVE_PAGINATION'
export const STATE_FILTER_MASSIVE_DETAIL = 'STATE_FILTER_MASSIVE_DETAIL'
export const STATE_TEMP_PAG = 'STATE_TEMP_PAG'
export const STATE_FILTER_REQUIRED_SIGNATURE = 'STATE_FILTER_REQUIRED_SIGNATURE'
export const BLOCK_CHANGE_TAP = 'BLOCK_CHANGE_TAP'


/* State Filter Internal */

export const STATE_FILTER_SEARCH_BAR_INTERNAL = 'STATE_FILTER_SEARCH_BAR_INTERNAL'
export const STATE_FILTER_POPUP_FILTER_INTERNAL = 'STATE_FILTER_POPUP_FILTER_INTERNAL'
export const STATE_FILTER_POPUP_ORDER_INTERNAL = 'STATE_FILTER_POPUP_ORDER_INTERNAL'
export const STATE_FILTER_PAGINACION_INTERNAL = 'STATE_FILTER_PAGINACION_INTERNAL'
export const STATE_FILTER_ADVANCED_SEARCH_INTERNAL = 'STATE_FILTER_ADVANCED_SEARCH_INTERNAL'
export const STATE_FILTER_ADVANCED_SEARCH_DATE_INTERNAL = 'STATE_FILTER_ADVANCED_SEARCH_DATE_INTERNAL'
export const STATE_DELETE_FILTER_INTERNAL = 'STATE_DELETE_FILTER_INTERNAL'
export const STATE_STARRED_FILTER_INTERNAL = 'STATE_STARRED_FILTER_INTERNAL'
export const STATE_TAB_FILTER_INTERNAL = 'STATE_TAB_FILTER_INTERNAL'
export const STATE_CLEAR_FILTER_INTERNAL = 'STATE_CLEAR_FILTER_INTERNAL'
export const STATE_INITIAL_PAGINATION_INTERNAL = 'STATE_INITIAL_PAGINATION_INTERNAL'
export const STATE_FILTER_POPUP_FILTER_AGREEMENTS_INTERNAL = 'STATE_FILTER_POPUP_FILTER_AGREEMENTS_INTERNAL'
export const STATE_FILTER_POPUP_FILTER_IMPROPER_CLIENTS_INTERNAL = 'STATE_FILTER_POPUP_FILTER_IMPROPER_CLIENTS_INTERNAL'
export const STATE_FILTER_POPUP_FILTER_DRAFTS_INTERNAL = 'STATE_FILTER_POPUP_FILTER_DRAFTS_INTERNAL'
export const STATE_FILTER_POPUP_FILTER_REPORTS_INTERNAL = 'STATE_FILTER_POPUP_FILTER_REPORTS_INTERNAL'
export const VIEW_PAGINATION_INTERNAL = 'VIEW_PAGINATION_INTERNAL'
export const STATE_INITIAL_FILTER_INTERNAL = 'STATE_INITIAL_FILTER_INTERNAL'
export const STATE_FILTER_MASSIVE_DETAIL_INTERNAL = 'STATE_FILTER_MASSIVE_DETAIL_INTERNAL'
export const STATE_TEMP_PAG_INTERNAL = 'STATE_TEMP_PAG_INTERNAL'
export const STATE_FILTER_POPUP_FILTER_LOGS_INTERNAL = 'STATE_FILTER_POPUP_FILTER_LOGS_INTERNAL'

/* State Writer */
export const STATE_CHECKBOK_NOTIFICATION_TEXT_EDITOR = 'STATE_CHECKBOK_NOTIFICATION_TEXT_EDITOR'
export const STATE_FORM_NOTIFICATION_TEXT_EDITOR = 'STATE_FORM_NOTIFICATION_TEXT_EDITOR'
export const STATE_TEXT_EDITOR = 'STATE_TEXT_EDITOR'
export const STATE_FILES_TEXT_EDITOR = 'STATE_FILES_TEXT_EDITOR'
export const STATE_FILES_DOCUMENT_SIGNATURE_TEXT_EDITOR = 'STATE_FILES_DOCUMENT_SIGNATURE_TEXT_EDITOR'
export const CHANGE_WRITE_NOTIFICATION_FORM = 'CHANGE_WRITE_NOTIFICATION_FORM'
export const CHANGE_WRITE_NOTIFICATION_FORM_ADD = 'CHANGE_WRITE_NOTIFICATION_FORM_ADD'
export const CHANGE_HEADER_CHECKBOX = 'CHANGE_HEADER_CHECKBOX'
export const CHANGE_DATE_PREVIEW = 'CHANGE_DATE_PREVIEW'
export const CHANGE_ATTACHED_DOCUMENT_SIGNATURE = 'CHANGE_ATTACHED_DOCUMENT_SIGNATURE'
export const CHANGE_ATTACHED_DOCUMENT_SIGNATURE_SPECIFIC = 'CHANGE_ATTACHED_DOCUMENT_SIGNATURE_SPECIFIC'
export const SAVE_NOTIFICATION = 'SAVE_NOTIFICATION'
export const CHANGE_ATT_BUTTON = 'CHANGE_ATT_BUTTON'
export const CLEAN_WRITER_fORM = 'CLEAN_WRITER_fORM'
export const CLEAR_EDITOR_CHANGE = 'CLEAR_EDITOR_CHANGE'
export const CHANGE_COLOR_TEXT_EDITOR = 'CHANGE_COLOR_TEXT_EDITOR'
export const CHANGE_STATE_MESSAGE_MODAL = 'CHANGE_STATE_MESSAGE_MODAL'
export const CHANGE_ROLE_STATE_MODAL = 'CHANGE_ROLE_STATE_MODAL'
export const LOADING_SEND_MASSIVE_MODAL = 'LOADING_SEND_MASSIVE_MODAL'
export const LOAD_RESULT_SEND_MASSIVE_MODAL = 'LOAD_RESULT_SEND_MASSIVE_MODAL'
export const SHOW_RESULT_SEND_MASSIVE_MODAL = 'SHOW_RESULT_SEND_MASSIVE_MODAL'
export const ADD_MASSIVE_RECIPIENTS_LIST = 'ADD_MASSIVE_RECIPIENTS_LIST'
export const ADD_MASSIVE_RECIPIENTS_FILE_INFO = 'ADD_MASSIVE_RECIPIENTS_FILE_INFO'
export const SET_MASSIVE_RECIPIENTS_LIST_FILE_INFO = 'SET_MASSIVE_RECIPIENTS_LIST_FILE_INFO'
export const FILTER_MASSIVE_RECIPIENTS = 'FILTER_MASSIVE_RECIPIENTS'
export const IS_MASSIVE_RECIPIENT = 'IS_MASSIVE_RECIPIENT'
export const UPDATE_RECIPIENT_DISPLAY = 'UPDATE_RECIPIENT_DISPLAY'
export const REPLACE_RECIPIENT_DISPLAY = 'REPLACE_RECIPIENT_DISPLAY'
export const CLOSE_LOAD_MASSIVE_RECIPIENTS_LIST = 'CLOSE_LOAD_MASSIVE_RECIPIENTS_LIST'
export const GET_RECIPIENT = 'GET_RECIPIENT'
export const HISTORY_RECIPIENT = 'HISTORY_RECIPIENT'
export const HISTORY_RECIPIENT_MASSIVE = 'HISTORY_RECIPIENT_MASSIVE'
export const LOADING_SEND_NOTIFICATION = 'LOADING_SEND_NOTIFICATION'
export const LOADING_SEND_NOTIFICATION_MASSIVE = 'LOADING_SEND_NOTIFICATION_MASSIVE'
export const SAVE_NOTIFICATION_MASSIVE_SUCCESS = 'SAVE_NOTIFICATION_MASSIVE_SUCCESS'
export const WRITE_NOTIFICATION_MODAL_OPEN = 'WRITE_NOTIFICATION_MODAL_OPEN'
export const WRITE_NOTIFICATION_MODAL_CLOSE = 'WRITE_NOTIFICATION_MODAL_CLOSE'
export const LOADING_MODAL = 'LOADING_MODAL'
export const SAVE_HIRED = 'SAVE_HIRED'
export const CHANGE_TEXT_EDITOR = 'CHANGE_TEXT_EDITOR'
export const STATE_VALIDATE_FILE_MASIVE = 'STATE_VALIDATE_FILE_MASIVE'
export const CHANGE_STATE_VIEW_WRITTER = 'CHANGE_STATE_VIEW_WRITTER'
export const INACTIVE_STATE_FORM_NOTIFICATION = 'INACTIVE_STATE_FORM_NOTIFICATION'
export const ACTIVE_STATE_FORM_NOTIFICATION = 'ACTIVE_STATE_FORM_NOTIFICATION'
export const GET_ATTRIBUTE_LIST = 'GET_ATTRIBUTE_LIST'
export const SET_FIELD_PARAMETRIZATION = 'SET_FIELD_PARAMETRIZATION'
export const SAVE_FILE_MASSIVE = 'SAVE_FILE_MASSIVE'
export const STATE_RECORDS_FILE_MASSIVE = 'STATE_RECORDS_FILE_MASSIVE'
export const STATE_RECORDS_FILE_MASSIVE_PARAMETRIZATION = 'STATE_RECORDS_FILE_MASSIVE_PARAMETRIZATION'
export const CLEAR_RECORDS_FILE_MASSIVE_PARAMETRIZATION = 'CLEAR_RECORDS_FILE_MASSIVE_PARAMETRIZATION'
export const STATE_NOTIFICATION_PROGRESS = 'STATE_NOTIFICATION_PROGRESS'
export const CHANGE_STATE_LOAD_CITIZENS = 'CHANGE_STATE_LOAD_CITIZENS'
export const CHANGE_STATE_LOAD_NOTIFICATIONS = 'CHANGE_STATE_LOAD_NOTIFICATIONS'
export const CHANGE_STATE_LOAD_CITIZENS_LIST = 'CHANGE_STATE_LOAD_CITIZENS_LIST'
export const CHANGE_STATE_LOAD_NOTIFICATIONS_LIST = 'CHANGE_STATE_LOAD_NOTIFICATIONS_LIST'
export const CLEAN_WRITER_FORM_ONLY = 'CLEAN_WRITER_FORM_ONLY'
export const DELETE_ITEM_FOR_MASSIVE = 'DELETE_ITEM_FOR_MASSIVE'
export const SET_DRAFT_SERIAL_ID = 'SET_DRAFT_SERIAL_ID'
export const DELETE_LOAD_CITIZEN_ITEM = 'DELETE_LOAD_CITIZEN_ITEM'
export const DELETE_LOAD_NOTIFICATION_ITEM = 'DELETE_LOAD_NOTIFICATION_ITEM'
export const CREATE_NEW_TAGS = 'CREATE_NEW_TAGS'
export const CHANGE_STATE_NOTIFICATIONS_CHARGING = 'CHANGE_STATE_NOTIFICATIONS_CHARGING'
export const CHANGE_STATE_CITIZENS_CHARGING = 'CHANGE_STATE_CITIZENS_CHARGING'
export const CHANGE_STATE_SEND_EMAIL = 'CHANGE_STATE_SEND_EMAIL'
export const SPECIFIC_DOCUMENTS = 'SPECIFIC_DOCUMENTS'
export const SET_LIST_TAGS = 'SET_LIST_TAGS'
export const CHANGE_DISABLED_RECIPIENT = 'CHANGE_DISABLED_RECIPIENT'
export const RESET_FIELD_PARAMETRIZATION = 'RESET_FIELD_PARAMETRIZATION'
export const FILE_MASSIVE_DATA='FILE_MASSIVE_DATA'
export const DELETE_RECIPIENTS_FROM_MASSIVE='DELETE_RECIPIENTS_FROM_MASSIVE'
export const DOWNLOADED_USERS_GUIDE = 'DOWNLOAD_USERS_GUIDE'

/* Starred */
export const POST_STARRED = 'POST_STARRED'
export const GET_NOTIFICATIONS_STARRED = 'GET_NOTIFICATIONS_STARRED'
export const CLEAR_NOTIFICATIONS_STARRED = 'CLEAR_NOTIFICATIONS_STARRED'

/* Clients */
export const GET_CLIENTS = 'GET_CLIENTS'
export const SAVE_CLIENT = 'SAVE_CLIENT'
export const CHANGE_STATE_WARNING_FORM_MODAL = 'CHANGE_STATE_WARNING_FORM_MODAL'
export const CHANGE_STATE_WARNING_FORM_MODAL_WRITE = 'CHANGE_STATE_WARNING_FORM_MODAL_WRITE'
export const CHANGE_STATE = 'CHANGE_STATE'
export const INACTIVE_STATE_CLIENT_FORM = 'INACTIVE_STATE_CLIENT_FORM'
export const GET_CLIENT_EDIT = 'GET_CLIENT_EDIT'
export const CHANGE_EDIT_CLIENT = 'CHANGE_EDIT_CLIENT'
export const ACTIVE_FORM_EDIT = 'ACTIVE_FORM_EDIT'
export const INACTIVE_FORM_EDIT = 'INACTIVE_FORM_EDIT'
export const GET_EMPLOYEES = 'GET_EMPLOYEES'
export const EDIT_CLIENT = 'EDIT_CLIENT'
export const DETAIL_CLIENT = 'DETAIL_CLIENT'
export const SAVE_EMPLOYEE = 'SAVE_EMPLOYEE'
export const GET_EMPLOYEE_EDIT = 'GET_EMPLOYEE_EDIT'
export const CHANGE_EDIT_EMPLOYEE = 'CHANGE_EDIT_EMPLOYEE'
export const EDIT_EMPLOYEE = 'EDIT_EMPLOYEE'
export const SET_ORIGINAL_EMPLOYEES_COUNT = 'SET_ORIGINAL_EMPLOYEES_COUNT'
export const SET_LOGS_LIST_CLIENT = 'SET_LOGS_LIST_CLIENT'

/* Providers */
export const GET_PROVIDERS = 'GET_PROVIDERS'
export const SAVE_PROVIDER = 'SAVE_PROVIDER'
export const CHANGE_STATE_PROVIDER = 'CHANGE_STATE'
export const INACTIVE_STATE_PROVIDER_FORM = 'INACTIVE_STATE_PROVIDER_FORM'
export const GET_PROVIDER_EDIT = 'GET_PROVIDER_EDIT'
export const CHANGE_EDIT_PROVIDER = 'CHANGE_EDIT_PROVIDER_PROVIDER'
export const ACTIVE_FORM_EDIT_PROVIDER = 'ACTIVE_FORM_EDIT'
export const INACTIVE_FORM_EDIT_PROVIDER = 'INACTIVE_FORM_EDIT_PROVIDER'
export const EDIT_PROVIDER = 'EDIT_PROVIDER'
export const DETAIL_PROVIDER = 'DETAIL_PROVIDER'
export const CODE_COMPANY_TO_EDIT = 'CODE_COMPANY_TO_EDIT'

/* User Documents */
export const GET_USER_DOCUMENTS = 'GET_USER_DOCUMENTS'
export const ACTIVE_VIEW_GRID = 'ACTIVE_VIEW_GRID'
export const ACTIVE_VIEW_LIST = 'ACTIVE_VIEW_LIST'
export const INACTIVE_VIEW_GRID = 'INACTIVE_VIEW_GRID'
export const INACTIVE_VIEW_LIST = 'INACTIVE_VIEW_LIST'
export const ACTIVE_TABLE_MIX = 'ACTIVE_TABLE_MIX'
export const INACTIVE_TABLE_MIX = 'INACTIVE_TABLE_MIX'
export const USER_DOCUMENT_STARRED = 'USER_DOCUMENT_STARRED'
export const SET_USER_CASE_NOT_FOUND = 'SET_USER_CASE_NOT_FOUND'
export const DELETE_FROM_LIST_TO_DELETE_DOCUMENTS = 'DELETE_FROM_LIST_TO_DELETE_DOCUMENTS'
export const UPDATE_LIST_TO_DELETE_DOCUMENTS = 'UPDATE_LIST_TO_DELETE_DOCUMENTS'
export const DELETE_ALL_FROM_LIST_TO_DELETE_DOCUMENTS = 'DELETE_ALL_FROM_LIST_TO_DELETE_DOCUMENTS'
export const ADD_ALL_TO_LIST_TO_DELETE_DOCUMENTS = 'ADD_ALL_TO_LIST_TO_DELETE_DOCUMENTS'
export const CLEAR_ATTACHMENT_DETAIL='CLEAR_ATTACHMENT_DETAIL'

/*Attachments*/
export const DETAIL_OF_ATTACHMENT = 'DETAIL_OF_ATTACHMENT'
export const CHANGE_TYPE_LIST = 'CHANGE_TYPE_LIST'
export const CHANGE_DOCUMENT_CURRENT = 'CHANGE_DOCUMENT_CURRENT'
export const VIEW_PDF = 'VIEW_PDF'
export const SET_DATA_PDF = 'SET_DATA_PDF'

/*Default lists*/
export const GET_GEOGRAPHIC_LIST = 'GET_GEOGRAPHIC_LIST'

/*agreements*/
export const AGREEMENT_LIST = 'AGREEMENT_LIST'
export const SAVE_AGREEMENT = 'SAVE_AGREEMENT'
export const ACTIVE_STATE_FORM_AGREEMENT = 'CHANGE_STATE'
export const INACTIVE_STATE_FORM_AGREEMENT = 'INACTIVE_STATE_FORM_AGREEMENT'
export const ACTIVE_FORM_EDIT_AGREEMENT = 'ACTIVE_FORM_EDIT_AGREEMENT'
export const INACTIVE_FORM_EDIT_AGREEMENT = 'INACTIVE_FORM_EDIT_AGREEMENT'
export const GET_PROVIDERS_LIST = 'GET_PROVIDERS_LIST'
export const GET_CLIENTS_LIST = 'GET_CLIENTS_LIST'
export const DETAIL_AGREEMENT = 'DETAIL_AGREEMENT'
export const CHANGE_EDIT_AGREEMENT = 'CHANGE_EDIT_AGREEMENT'
export const GET_AGREEMENT_EDIT = 'GET_AGREEMENT_EDIT'
export const EDIT_AGREEMENT = 'EDIT_AGREEMENT'
export const CLEAR_AGREEMENT_EDIT = 'CLEAR_AGREEMENT_EDIT'
export const CLEAR_AGREEMENT_DETAIL = 'CLEAR_AGREEMENT_DETAIL'
export const SET_LOGS_LIST_AGREEMENTS = 'SET_LOGS_LIST_AGREEMENTS'

/*Dashboard*/
export const GET_DASHBOARD_INFO = 'GET_DASHBOARD_INFO'
export const GET_DASHBOARD_AGREEMENT_INFO = 'GET_DASHBOARD_AGREEMENT_INFO'
export const SHOW_AGREEMENT_INFO = 'SHOW_AGREEMENT_INFO'
export const REMOVE_FILTER = 'REMOVE_FILTER'
export const HAS_AGREEMENTS = 'HAS_AGREEMENTS'
export const RESET_DASHBOARD = 'RESET_DASHBOARD'
export const CLEAR_DASHBOARD_SEARCH = 'CLEAR_DASHBOARD_SEARCH'
export const DASHBOARD_LIST_AGREEMENTS = 'DASHBOARD_LIST_AGREEMENTS'

/*Drafts*/
export const DRAFTS_LIST = 'DRAFTS_LIST'
export const UPDATE_LIST_TO_DELETE = 'UPDATE_LIST_TO_DELETE'
export const DELETE_FROM_LIST_TO_DELETE = 'DELETE_FROM_LIST_TO_DELETE'
export const DELETE_ALL_FROM_LIST_TO_DELETE = 'DELETE_ALL_FROM_LIST_TO_DELETE'
export const ADD_ALL_TO_LIST_TO_DELETE = 'ADD_ALL_TO_LIST_TO_DELETE'
export const CHANGE_STATE_WARNING_FORM_MODAL_DRAFTS = 'CHANGE_STATE_WARNING_FORM_MODAL_DRAFTS'
export const CLOSE_WARNING_FORM_MODAL_DRAFTS = 'CLOSE_WARNING_FORM_MODAL_DRAFTS'
export const SAVE_DATA_OF_DRAFT = 'SAVE_DATA_OF_DRAFT'
export const DELETE_DRAFT_RECIPIENT = 'DELETE_DRAFT_RECIPIENT'
export const CLEAR_DATA_OF_DRAFT = 'CLEAR_DATA_OF_DRAFT'
export const UPDATE_FILE_SERIAL_ID = 'UPDATE_FILE_SERIAL_ID'
export const DRAFT_TO_WRITE = 'DRAFT_TO_WRITE'
export const SET_FILE_SERIAL_ID = 'SET_FILE_SERIAL_ID'
export const SET_DRAFT_SERIAL_ID_DRAFTS = 'SET_DRAFT_SERIAL_ID_DRAFTS'
export const DELETE_DRAFT_TAGS_AND_SPECIFIC_FILES = 'DELETE_DRAFT_TAGS_AND_SPECIFIC_FILES'

/*Reports*/
export const GET_REPORTS_LIST = 'GET_REPORTS_LIST'
export const DETAIL_CSV_ERROR = 'DETAIL_CSV_ERROR'
export const REPORT_DETAIL = 'REPORT_DETAIL'
export const CHANGE_TYPE_REPORT_CURRENT = 'CHANGE_TYPE_REPORT_CURRENT'
export const GET_CLIENTS_REPORT = 'GET_CLIENTS_REPORT'
export const GET_All_CLIENTS_REPORT = 'GET_All_CLIENTS_REPORT'
export const CHANGE_DATA_CREATE_REPORT = 'CHANGE_DATA_CREATE_REPORT'
export const GET_RESPONSE_CREATE_REPORT = 'GET_RESPONSE_CREATE_REPORT'
export const RESPONSE_BASE64_REPORT = 'RESPONSE_BASE64_REPORT'
export const SUCCESS_CREATE_REPORT = 'SUCCESS_CREATE_REPORT'
export const CHANGE_TO_BE_NOTIFIED = 'CHANGE_TO_BE_NOTIFIED'
export const INTERVAL_TO_BE_NOTIFIED = 'INTERVAL_TO_BE_NOTIFIED'
export const CLEAR_INTERVAL_TO_BE_NOTIFIED = 'CLEAR_INTERVAL_TO_BE_NOTIFIED'

// Improper Clients

export const GET_IMPROPER_CLIENTS = 'GET_IMPROPER_CLIENTS'
export const IMPROPER_CLIENT_DETAIL = 'IMPROPER_CLIENT_DETAIL'
export const AUDITOR_COMMENT_NOTIFICATION = 'AUDITOR_COMMENT_NOTIFICATION'