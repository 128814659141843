import React, { Component } from 'react'
import { MainSegment } from '../../../components/Sengment/MainSegment'
import CreateUsers from './CreateUsers'

export class AddUsers extends Component {

  render() {
    return (
      <MainSegment>
        <CreateUsers />
      </MainSegment>
    )
  }
}
export default AddUsers