import React, { useState, useEffect } from 'react'
import { reactValidatorOptions } from '../../../helpers/simpleReactValidator'
import { Form, Dimmer, Loader } from 'semantic-ui-react'
import InputFormUser from './InputAnimated/InputFormUser'
import InputFormDropdown from './InputAnimated/InputFormDropdown'
import InputSwitch from './InputAnimated/InputSwitch'
import { activeFormUser, changeFormUser, getUserEdit, putUserEdit } from '../../../actions/usersActions'

import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import useValidator from '../../../helpers/simpleReactValidatorHook'
import { useHistory } from 'react-router-dom'
import { refreshStateModule, warningFormModal } from '../../../actions/modalActions'

const Divider = styled.div`
  width: 500px;
  margin: auto;

  @media only screen and (max-width: 992px) {
    width:90%;
  }
`
const CustomForm = styled(Form)`
  display: flex !important;
  flex-direction: column !important;
`
const ContainerButtons = styled.div`
  display: flex;
  margin-top: 15px;
  justify-content: flex-end;
  margin-bottom: 1rem;
  @media only screen and (max-width: 500px) {
    justify-content: center;
    width:100%;
  }
`
const ButtoCancel = styled.button`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #22C1D0;
  text-align: center;
  width: 128px;
  height: 48px;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  background: #FFFFFF;
  margin-right: 8px;
  cursor: pointer;
  @media only screen and (max-width: 500px) {
    width:100%;
  }
`
const ButtoSave = styled.button`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #FFFFFF;
  text-align: center;
  width: 128px;
  height: 48px;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  background: #22C1D0;
  margin-left: 8px;
  cursor: pointer;
  @media only screen and (max-width: 500px) {
    width:100%;
  }
`

const optionsDocumentType = [
  { key: '', text: '', value: '' },
  { key: 'C.C', text: 'Cédula de Ciudadania', value: 'C.C' },
]

const EditForm = (props) => {

  const history = useHistory()
  const dispatch = useDispatch()
  const [loader, setLoader] = useState(true)
  const { refreshModule, warningModal} = useSelector(state => state.modalReducer)
  const { activeForm, userActiveEdit, stateFormEdit} = useSelector(state => state.userReducer)

  const {
    documentType= '',
    documentNumber = '',
    firstName= '',
    lastName= '',
    address= '',
    email= '',
    company= '',
    roleID= '',
    status= '',
  } = userActiveEdit

  const [errors] = useState({})
  const [validator, showValidationMessage] = useValidator(reactValidatorOptions)

  useEffect(() => {
    if (activeForm === false && warningModal.status === true) {
      dispatch(activeFormUser())
    }

    if (refreshModule === true) {
      history.goBack()
      dispatch(refreshStateModule())
    }
  }, [activeForm, warningModal, refreshModule, dispatch, history])

  useEffect(() => {
    const idUser = sessionStorage.getItem("userSerialId")
    
    dispatch(getUserEdit({"idUser":idUser})).then(() => setLoader(false))
  }, [dispatch])

  const handleChangeActive = () => {
    dispatch(changeFormUser('status', status == 'ACTIVE' ? 'INACTIVE': 'ACTIVE'))
  }

  const handleOnChange = (event, { value }) => {
    const name = event.currentTarget.parentNode.parentNode.id
    dispatch(changeFormUser([name], value))
  }

  const onChange = e => {
    const name = e.target.id
    dispatch(changeFormUser([name], e.target.value))
  }

  const handleCancel = () => {
    dispatch(warningFormModal({ type: 'warningLeaveUpdate' }))
  }

  const makeSaveNotificationReq = () => {

    const body = {
      "company": company,
      "documentType": documentType,
      "documentNumber": documentNumber,
      "address": address,
      "email": email,
      "firstName": firstName,
      "lastName": lastName,
      "roleID": roleID,
      "status": status,
    }
    const idUser = sessionStorage.getItem("userSerialId")
    dispatch(putUserEdit(body, idUser))
  }

  const handleSend = (e) => {
    e.preventDefault()
    if (validator.allValid()) {
      makeSaveNotificationReq()
    } else {
      showValidationMessage(true);
    }
  }

  return (
          <>
            <Dimmer active={loader} inverted>
              <Loader size='medium'>Cargando...</Loader>
            </Dimmer>
            <Divider>
                <CustomForm>
                    <InputFormDropdown
                        options={optionsDocumentType}
                        placeholder={'Tipo de documento'}
                        value={documentType}
                        handleOnChange={handleOnChange}
                        id={'documentType'}
                        validator={validator}
                        validateOptions={'required'}
                        error={errors}
                        readOnly={true}
                        disabled={true}
                    />
                    <br />
                    <InputFormUser
                        id={'documentNumber'}
                        label={'No. documento'}
                        value={documentNumber}
                        validator={validator}
                        validateOptions={['required', 'numeric', { min: 4 }, { max: 15 }]}
                        error={errors}
                        readOnly={true}
                        disabled={true}
                    />
                    <InputFormUser
                        id={'firstName'}
                        label={'Nombres'}
                        type={'text'}
                        onChange={onChange}
                        value={firstName}
                        validator={validator}
                        validateOptions={['required', 'nameOfCompany', { min: 1 }, { max: 50 }]}
                        error={errors}
                        readOnly={true}
                        disabled={true}
                    />
                    <InputFormUser
                        id={'lastName'}
                        label={'Apellidos'}
                        type={'text'}
                        onChange={onChange}
                        value={lastName}
                        validator={validator}
                        validateOptions={['required', 'nameOfCompany', { min: 1 }, { max: 50 }]}
                        error={errors}
                        readOnly={true}
                        disabled={true}
                    />
                    <InputFormUser
                        id={'address'}
                        label={'Dirección'}
                        type={'text'}
                        onChange={onChange}
                        value={address}
                        validator={validator}
                        validateOptions={['required', 'address', { min: 1 }, { max: 200 }]}
                        error={errors}
                        readOnly={true}
                        disabled={true}
                    />
                    <InputFormUser
                        id={'email'}
                        label={'Email'}
                        type={'text'}
                        value={email}
                        validator={validator}
                        validateOptions={['required', 'email', { min: 6 }, { max: 200 }]}
                        error={errors}
                        readOnly={true}
                        disabled={true}
                    />
                    <InputFormDropdown
                        options={props.role}
                        placeholder={'Rol'}
                        value={roleID}
                        handleOnChange={handleOnChange}
                        id={'roleID'}
                        validator={validator}
                        validateOptions={'required'}
                        error={errors}
                    />
                    <InputSwitch
                      id={'status'}
                      checked={status === 'ACTIVE' ? true : false}
                      onChange={handleChangeActive }
                    />
                    <ContainerButtons>
                        <ButtoCancel
                            onClick={handleCancel}
                        >Cancelar
                        </ButtoCancel>
                        <ButtoSave
                            disabled={stateFormEdit}
                            className='putButton'
                            onClick={handleSend}
                        >Actualizar
                        </ButtoSave>
                    </ContainerButtons>
                </CustomForm>
            </Divider >
          </>
  )
}

export default EditForm