import React, { useState, useEffect } from 'react'
import { reactValidatorOptions } from '../../../helpers/simpleReactValidator'
import { Form, Progress, Dimmer, Loader } from 'semantic-ui-react'
import InputFormClient from '../InputAnimated/InputFormClient'
import InputFormDropdown from '../InputAnimated/InputFormDropdown'
import InputFormArea from '../InputAnimated/InputTextArea'
import InputCheckbokForm from '../InputAnimated/InputCheckboxForm'
import InputFormDate from '../InputAnimated/InputDate'

import styled from 'styled-components'
import '../index.css'
import { refreshStateModule, warningFormModal, warningModalCanceleAgreement } from '../../../actions/modalActions'
import { activeFormAgreement, getProviderList2V2, getClientList2, changeFormAgreement, getAgreementEdit, putAgreementEditV2 } from '../../../actions/agreementActions'
import InputAttachButton from '../InputAnimated/InputAttachButton'
import { fileToBase64Clean } from '../../../helpers/filesLib'
import { v4 as uuidv4 } from 'uuid'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import useValidator from '../../../helpers/simpleReactValidatorHook'
import InputSwitch from '../InputAnimated/InputSwitch'
import { ReactComponent as Ex } from '../../../assets/icons/ex.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/icons/agreement/delete.svg'
import WarningModalCancelAgreement from '../Modal/WarningModalCancelAgreement'
import ModalCancelAgreement from '../Modal/ModalCancelAgreement'
import InputSelect from '../InputAnimated/InputSelect'
import InputSelectAgreement from '../InputAnimated/InputSelectAgreement'

const Divider = styled.div`
  padding-left: 160px;
  padding-right: 160px;
  @media only screen and (max-width: 1200px) {
    width: 100%;
    min-width: 350px;
    height: 100vh;
  }
  @media only screen and (max-width: 700px) {
    width: 100%;
    min-width: 350px;
    height: 100vh;
    padding: 0px 15px;
  }
`
const CustomForm = styled(Form)`
  display: flex !important;
  flex-direction: column !important;
  width: 50%;
  margin: auto;
  @media only screen and (max-width: 1200px) {
    width: 100%;
    min-width: 350px;
    margin:0;
  }
`
const ContainerButtons = styled.div`
  display: flex;
  margin-top: 15px;
  justify-content: flex-end;
  margin-bottom: 1rem;
  @media only screen and (max-width: 1200px) {
    gap: 16px;
  }
`
const Divider3 = styled.div`
margin-bottom: 24px;
@media only screen and (max-height: 800px) {
  margin-bottom: 14px;
}
`
const Divider4 = styled.div`
margin-bottom: 24px;
@media only screen and (max-height: 800px) {
    margin-bottom: 14px;
}
`
const ContainerFormDate = styled.div`
  display: flex;
  justify-content: space-between !important;
  justify-content: space-between !important;
    @media only screen and (max-height: 800px) {
        margin-bottom: 13px;
    }
  @media only screen and (max-width: 700px) {
    display: block;
    margin-bottom: 23px;
  }
`
const ContainerPositionDateOne = styled.div`
  width: 48%;
  margin-bottom: 23px;
  @media only screen and (max-height: 800px) {
      margin-bottom: 13px;
  }
  @media only screen and (max-width: 700px) {
    width:100%;
    float:left;
  }
`
const ContainerPositionDateTwo = styled.div`
    width: 48%;
    @media only screen and (max-width: 700px) {
        width:100%;
        margin-top: 0px;
        float:right;
    }
`
const Container = styled.div`
  border: 1px solid #E35F00;
  background: rgba(227, 95, 0, 0.1);
  color: #E35F00;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 1rem;
  padding: 10px 28px;
  text-align: center;
`
const Title = styled.p`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #133F4F;
`
const Title2 = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #133F4F;
  margin: 24px 0px !important;
  @media only screen and (max-height: 800px) {
    margin-bottom: 14px !important;
  }
`
const TextNameFile = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #000000;
  opacity: 0.3;
`
const TextNameFile2 = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  line-height: 23px !important;
  color: #000000;
  font-size: 14px;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
   max-width: 200px;
   margin: 0 0 0.25rem !important;
`
const ButtoCancel = styled.button`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #22C1D0;
  text-align: center;
  width: 128px;
  cursor: pointer;
  height: 48px;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  background: #FFFFFF;
  margin-right: 8px;
  @media only screen and (max-width: 1200px) {
    float:left;
    width:49%;
    margin:0;
  }
`
const ButtoSave = styled.button`
  font-family: 'Roboto';
  font-style: normal;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #FFFFFF;
  text-align: center;
  width: 128px;
  height: 48px;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  background: #22C1D0;
  margin-left: 8px;
  @media only screen and (max-width: 1200px) {
    float:right;
    width:49%;
    margin:0;
  }
`
const ProgressBar = styled(Progress)`
  width: 100%;
  height: 3px;
  border-radius: 0px !important;
  margin: 0 0 0.5em !important;
  & > .bar {
    background-color: #009BDD !important;
  }
`
const DividerStatus = styled.div`
  display: flex;
  margin-top: 10px;
`
const TextStatus = styled.p`
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #000000;
  padding-right: 5px;
`
const PStatus = styled.p`
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #000000;
`
const ButtoCancelAgreement = styled.button`
  margin-top: 5px;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #22C1D0;
  width: 160px;
  cursor: pointer;
  background: #FFFFFF;
  border: transparent;
  display: flex;
  padding-left: 0px;
  justify-content: flex-start;
  @media only screen and (max-width: 1200px) {
    float:left;
    width:49%;
    margin:0;
  }
`
const Container2 = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 14px;
  color: #C3132E;
  padding: 20px;
  @media only screen and (max-width: 768px) {
    padding: 16px 0px;

  }
`
const DividerAttachments = styled.div`
    display: flex;
    width: 100% ;
    align-items: flex-start;
    @media only screen and (max-width: 768px) {
        flex-direction: column;
    }
`

const DivFilesNames = styled.div`
    display: flex;
    flex-direction: column;
    width: 48%;

    @media (max-width: 768px) {
        margin-top: 16px;
    }
`;

const EditFormAgreements = (props) => {

    const history = useHistory()
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(true)
    const { refreshModule } = useSelector(state => state.modalReducer)
    const { warningModal } = useSelector(state => state.modalReducer)
    const { activeForm } = useSelector(state => state.clientReducer)
    const { agreementActiveEdit } = useSelector(state => state.agreementReducer)
    const { stateFormEdit } = useSelector(state => state.agreementReducer)
    const { providerList } = useSelector(state => state.agreementReducer)
    const { clientList } = useSelector(state => state.agreementReducer)

    const agreementStatus = {
        ACTIVE: "En curso",
        PENDING: "Aprobado",
        INACTIVE: "Inactivo",
        EXPIRED: "Vencido",
    }

    const {
        id,
        companyProviderID,
        companyClientID,
        startDate,
        endDate,
        agreementCode,
        subscriptionDate,
        tradeNumber,
        tradeDate,
        tradeDetails,
        tradeSupport,
        agreementsFiles = [],
        bagName,
        quantity,
        sendNotification,
        pushNotification,
        notificationReading,
        notificationSignature,
        documentSignature,
        status
    } = agreementActiveEdit

    const [progress, setProgress] = useState([0, 0, 0,0, 0, 0,0, 0, 0,0])
    const [agreementsFilesState, setAgreementsFilesState] = useState([])
    const [providerOptions, setProviderOptions] = useState([])
    const [clientOptions, setClientOptions] = useState([])
    const [providerDefinition, setProviderDefinition] = useState('')
    const [clientDefinition, setClientDefinition] = useState('')
    const [errors] = useState({})
    const [isChangedState, setIsChangedState] = useState(false)
    const [validator, showValidationMessage,setFields] = useValidator(reactValidatorOptions)
    const [chargefile, setChargeFile] = useState(0)
    const [maxQuantityAchieved, setMaxQuantityAchieved] = useState(false)
    const [agreementsFilesList, setAgreementsFilesList] = useState([])
    const [isVisible, setIsVisible] = useState([])
    const [filesToDelete, setFilesToDelete] = useState([])
    const [errorProvider, setErrorProvider] = useState(false)
    const [errorQuantity, setErrorQuantity] = useState(false)
    const [errorClient, setErrorClient] = useState(false)
    const [errorFile, setErrorFile] = useState(false)
    const [fileTypeError, setFileTypeError] = useState(false)
    const [repeatedFileError, setRepeatedFileError] = useState(false)
    const [messagesShown, setMessageShown] = useState(false)

    useEffect(() => {
        if(!!messagesShown){
          validateErrors()
        }
      }, [messagesShown])

    useEffect(() => {
        const id = sessionStorage.getItem("idAgreement")
        dispatch(getAgreementEdit(id)).then(() => setLoader(false))
        dispatch(getProviderList2V2())
        dispatch(getClientList2())
    }, [])

    useEffect(() => {
        if (providerList && clientList) {
            const dataProvider = providerList.map((x) => {
                return { text: x.name, key: x.companySerialID, value: x.companySerialID }
            })
            setProviderOptions(dataProvider)

            const dataClient = clientList.map((x) => {
                return { text: x.name, key: x.companySerialID, value: x.companySerialID }
            })
            setClientOptions(dataClient)
        }
    }, [providerList, clientList])

    useEffect(() => {

        if (activeForm === false && warningModal.status === true) {
            dispatch(activeFormAgreement())
        }

        if (refreshModule === true) {
            history.goBack()
            dispatch(refreshStateModule())
        }
        if (providerList) {
            providerList.map((x) => {
                if (x.companySerialID === companyProviderID) {
                    setProviderDefinition(x.providerDefinition)
                }
            })
        }
        if (clientList) {
            clientList.map((x) => {
                if (x.companySerialID === companyClientID) {
                    setClientDefinition(x.providerDefinition)
                }
            })
        }

    }, [activeForm, warningModal, refreshModule, dispatch, history,companyProviderID, companyClientID, providerList])

    useEffect(() => {
        let progressAux = progress
        if (agreementsFiles) {
            setAgreementsFilesList(() => { return agreementsFiles })
            for (let i = 0; i < agreementsFiles.length; i++) {
                progressAux[i] = 100
            }
        }
        setProgress(progressAux)

    }, [agreementActiveEdit.id])


    const handleOnChangeProviders = (event, {value}) => {
        let dataValue= value
        dispatch(activeFormAgreement())
        if(event.currentTarget.hasOwnProperty('name')){
            dataValue= event.currentTarget.getAttribute('name')

        }else{
            if(value === undefined){
                dataValue= event.currentTarget.getAttribute('name')
            }   
        }

        dispatch(changeFormAgreement(['companyProviderID'], dataValue))
        if (clientDefinition === 'PRIVATE') dispatch(changeFormAgreement(['quantity'], ""))

        if (companyProviderID !== '') {
            setErrorProvider(false)
        }
    }

    const handleOnChangeClients = (event, {value}) => {
        let dataValue= value
        dispatch(activeFormAgreement())
        if(event.currentTarget.hasOwnProperty('name')){
            dataValue= event.currentTarget.getAttribute('name')

        }else{
            if(value === undefined){
                dataValue= event.currentTarget.getAttribute('name')
            }   
        }
        dispatch(changeFormAgreement(['companyClientID'] , dataValue))

        if (companyClientID !== '') {
            setErrorClient(false)
        }
    }

    const onChange = e => {
        const name = e.target.id
        const value = e.target.value
        const label = e.target.getAttribute('label')
        if (name ==='quantity') {
            setErrorQuantity(false)
        }
        dispatch(changeFormAgreement([name], e.target.value)).then(() => {
            isValidFieldName(label) 
                ?setFields(label) 
                :setFields(name)
        })
    }
    const isValidFieldName= (fieldName)=> {
        return validator.fields.hasOwnProperty(fieldName);
    }

    const onChangeDate = e => {
        const name = e.target.id
        dispatch(changeFormAgreement([name], e.target.value))
        setIsChangedState(true)
    }

    const handleCancel = () => {
        dispatch(warningFormModal({ type: 'warningLeaveAgreements' }))
    }

    const toggle = (e) => {
        let name = e.target.id;
        dispatch(changeFormAgreement(
            [name], !agreementActiveEdit[name]
        ))
    }

    const onChangeHandler = async (e) => {
        const name = e.target.id
        const file = e.target.files
        let numDuplicated = 0;
        let fileInfo = {}
        if (file.length + agreementsFilesList.length + agreementsFilesState.length <= 10) {
            if (file.length) {
                for (let i = 0; i < file.length; i++) {
                    const isDuplicateList = agreementsFilesList.some(existingFile => existingFile.originalFileName === file[i].name);
                    const isDuplicateState = agreementsFilesState.some(existingFile => existingFile.fileName === file[i].name);
                    if (!isDuplicateList && !isDuplicateState) {
                        fileInfo = {
                            fileId: uuidv4(),
                            base64: await fileToBase64Clean(file[i].name, file[i]),
                            fileName: file[i].name,
                            fileExtension: file[i].type.split("/").pop(),
                        }
                        if (fileInfo.fileExtension === "pdf") {
                            loadNotes(file[i].size / 2500, agreementsFilesList.length + i + agreementsFilesState.length - numDuplicated)
                            setAgreementsFilesState((prevState) => {
                                return [...prevState, {
                                    "fileName": fileInfo.fileName,
                                    "base64": fileInfo.base64
                                }]
                            })
                            setChargeFile(0)
                            dispatch(changeFormAgreement([name], agreementsFilesState))

                        } else {
                            setFileTypeError(true)
                            setTimeout(() => {
                                setFileTypeError(false)
                            }, 3000);
                        }
                    }else{
                        numDuplicated = numDuplicated + 1;
                        setRepeatedFileError(true)
                        setTimeout(() => {
                            setRepeatedFileError(false)
                        }, 3000);
                    }

                }
                setErrorFile(false)
            }
        } else {
            setMaxQuantityAchieved(true)
            setTimeout(() => {
                setMaxQuantityAchieved(false)
            }, 3000);
        }
    }

    const loadNotes = async (size, num) => {
        let progressAux
        for (let i = 10; i <= 100; i = i + 10) {
            await new Promise((resolve, reject) => {
                setTimeout(function () {
                    resolve();
                }, size);
            })
            progressAux = progress
            progressAux[num] = i
            setProgress(progressAux)
            setChargeFile(i)
        }
        setChargeFile(0)

    }

    const handleChange = (checked) => {
        if (checked === true) {
            dispatch(changeFormAgreement('status', 'PENDING'))
        } else {
            dispatch(changeFormAgreement('status', 'INACTIVE'))
        }
    }

    const makeSaveNotificationReq = () => {
        let files = []
        if (agreementsFilesState.length !== 0) {

            for (let i = 0; i < agreementsFilesState.length; i++) {
                files.push({
                    "fileName": agreementsFilesState[i].fileName,
                    "base64": agreementsFilesState[i].base64
                })
            }
        }

        const body = {
            "companyProviderID": companyProviderID,
            "companyClientID": companyClientID,
            "startDate": isChangedState === true && new Date(startDate).toISOString(),
            "endDate": isChangedState === true && new Date(endDate).toISOString(),
            "agreementCode": agreementCode,
            "subscriptionDate": new Date(subscriptionDate).toISOString(),
            "tradeNumber": tradeNumber,
            "tradeDate": new Date(tradeDate).toISOString(),
            "tradeDetails": tradeDetails,
            "tradeSupport": tradeSupport,
            "agreementsFiles": files,
            "agreementDeleteFiles": filesToDelete,
            "name": bagName,
            "quantity": clientDefinition === 'PRIVATE' ? quantity : null,
            "pushNotification": pushNotification,
            "notificationReading": notificationReading,
            "notificationSignature": notificationSignature,
            "documentSignature": documentSignature,
            "status": status
        }
        dispatch(putAgreementEditV2(body, id))
    }

    const handleClickCanceleAgreement = () => {
        dispatch(warningModalCanceleAgreement({ type: 'warningCanceleAgreement' }))
    }

    const validateQuantity = () => {
        if (clientDefinition === 'PRIVATE' ) {
            const NumberQuantity = Number(quantity);
            return NumberQuantity >0
        }else{
            return true
        }
    }

    const handleSend = (e) => {
        e.preventDefault()
        
       
        if (validator.allValid() && startDate < endDate && (agreementsFilesState.length > 0 || agreementsFilesList.length > 0) && validateQuantity()) {
            makeSaveNotificationReq()
        } else {
            showValidationMessage(true)
            if (agreementsFilesState.length === 0 && agreementsFilesList.length === 0) {
                setErrorFile(true)
            }
            if (companyProviderID === '') {
                setErrorProvider(true)
            }
            if (companyClientID === '') {
                setErrorProvider(true)
            }
            if (validateQuantity()) {
                setErrorQuantity(true)
            }
            setMessageShown(true)
        }
    }
    const handleRemove = async (e) => {
        const files = agreementsFilesState.map((file) => file)
        files.splice(e.currentTarget.id, 1)
        setAgreementsFilesState(files)
    }

    const handleRemovePrev = async (e) => {
        const files = agreementsFilesList.map((file) => file)
        const fileToDelete = files[e.currentTarget.id].fileName
        setFilesToDelete((prevState) => {
            return [...prevState, { "fileName": fileToDelete }]
        })
        files.splice(e.currentTarget.id, 1)
        setAgreementsFilesList(files)
        dispatch(changeFormAgreement('agreementDeleteFiles', filesToDelete))
    }

    const onBlurAgreementCode = () => {
        return checkAgreementFormat(agreementCode);
    }

    const checkAgreementFormat = (value) => {
        const pattern = /^[A-Za-z]-\d{2,13}$/; // Expresion regular para el formato
        return pattern.test(value);
    }

    const validateErrors = () => {
        const elementError= document.querySelector('.inputDropdownFormEntitiesError, .labelInputFormEntitiesError, .labelInputFormDateError, .supportFilesError')
        if (elementError !== null ) {
            if(elementError.className.includes('inputDropdownFormEntitiesError')){
                elementError.firstChild.focus()
            }else{
                elementError.focus()
            }
            setMessageShown(false)
        }  
    }

    return (
        <>
            <Dimmer active={loader} inverted>
                <Loader size='medium'>Cargando...</Loader>
            </Dimmer>
            <Divider>
                <CustomForm>
                    <Title>Datos de vinculación</Title>
                    <Divider3>
                        <InputSelectAgreement
                         data={providerOptions}
                         value={companyProviderID}
                         validator={validator}
                         errorProvider={errorProvider}
                         handleOnChange={handleOnChangeProviders}
                         idName='companyProviderID'
                         placeholder={'Operador'}
                         name={'providerSelect'}
                        />
                    </Divider3>
                    <Divider4>
                        <InputSelectAgreement
                        data={clientOptions}
                        value={companyClientID}
                        validator={validator}
                        errorClient={errorClient}
                        handleOnChange={handleOnChangeClients}
                        idName='companyClientID' 
                        placeholder={'Cliente'}
                        name={'clientSelect'}
                        />
                    </Divider4>
                    {
                        companyProviderID !== "" && companyClientID !== ""
                        && <Container>
                            <p>
                                La vinculación creada será <strong>entre el <br />operador y el cliente</strong> seleccionados.
                            </p>
                        </Container>
                    }
                    <ContainerFormDate>
                        <ContainerPositionDateOne>
                            <InputFormDate
                                type="date"
                                id="startDate"
                                label={'Fecha inicio'}
                                value={startDate}
                                endDate={endDate}
                                onChange={onChangeDate}
                                validator={validator}
                                validateOptions={isChangedState === true ? ['dateStart', 'required'] : 'dateEdit'}
                                error={errors}
                            />
                        </ContainerPositionDateOne>
                        <ContainerPositionDateTwo>
                            <InputFormDate
                                type="date"
                                id="endDate"
                                label={'Fecha vencimiento'}
                                value={endDate}
                                onChange={onChangeDate}
                                validator={validator}
                                validateOptions={isChangedState === true ? ['dateEnd', 'required'] : 'dateEdit'}
                                error={errors}
                            />
                        </ContainerPositionDateTwo>
                    </ContainerFormDate>
                    <InputFormClient
                        id={'quantity'}
                        label={'Cantidad envío de notificaciones'}
                        type={'text'}
                        onChange={onChange}
                        value={clientDefinition === 'PRIVATE' || clientDefinition === ''
                            ? !!quantity ? quantity + '' : '' : 'Ilimitadas'}
                        validator={validator}
                        disabled={clientDefinition === 'PUBLIC' ? true : false}
                        validateOptions={clientDefinition === 'PRIVATE' ? ['required', 'numeric','quantityAgreement', { min: 1 }] : []}
                        error={errors}
                        stylesAgreements
                        errorQuantity={errorQuantity}
                        setMessageShown = {setMessageShown}
                    />
                    <InputFormClient
                        id={'agreementCode'}
                        label={'Código de vinculación (ej: P-01010101)'}
                        type={'text'}
                        onChange={onChange}
                        value={agreementCode}
                        validator={validator}
                        validateOptions={['agreementCode', 'required']}
                        error={errors}
                        stylesAgreements
                    />
                    <InputFormDate
                        type="date"
                        id="subscriptionDate"
                        label={'Fecha de suscripción'}
                        value={subscriptionDate}
                        onChange={onChange}
                        validator={validator}
                        validateOptions={['dateSubscription', 'required']}
                        error={errors}
                        stylesAgreements
                    />
                    <InputFormClient
                        id={'tradeNumber'}
                        label={'Número de oficio'}
                        type={'text'}
                        onChange={onChange}
                        value={tradeNumber}
                        validator={validator}
                        validateOptions={['tradeNumber', 'required']}
                        error={errors}
                        stylesAgreements
                    />
                    <InputFormDate
                        type="date"
                        id="tradeDate"
                        label={'Fecha de oficio'}
                        value={tradeDate}
                        onChange={onChange}
                        validator={validator}
                        validateOptions={['dateSubscription', 'required']}
                        error={errors}
                        stylesAgreements
                    />
                    <InputFormArea
                        id={'tradeDetails'}
                        label={'Detalles vinculación'}
                        type={'text'}
                        onChange={onChange}
                        value={tradeDetails}
                        validator={validator}
                        validateOptions={['descriptionArea', 'required']}
                        error={errors}
                        min={1}
                        max={100}
                        heightText={'96px'}
                        stylesAgreements
                    />
                    <InputFormArea
                        id={'tradeSupport'}
                        label={'Soporte'}
                        type={'text'}
                        onChange={onChange}
                        value={tradeSupport}
                        validator={validator}
                        validateOptions={['required','supportDescription', { min: 1 }, { max: 100 }]}
                        error={errors}
                        heightText={'96px'}
                        stylesAgreements
                    />
                    <DividerAttachments >
                        <InputAttachButton
                            disabled={agreementsFilesState.length + agreementsFilesList.length >= 10 ? true : false}
                            onSelectFile={onChangeHandler}
                            value={agreementsFilesState}
                            accept='application/pdf'
                            className={!! errorFile ? 'supportFilesError': ''}
                            id='agreementsFiles'
                            validator={validator}
                            validateOptions={['required', { min: 1 },]}
                        />
                        {agreementsFilesList &&
                            <DivFilesNames>
                                {agreementsFilesList.map((file, i) => {
                                    return (
                                        <div className='file' key={i} style={{ display: 'flex', flexDirection: 'column' }} onMouseEnter={() => setIsVisible(true)} onMouseLeave={() => setIsVisible(false)}>
                                            {progress[i] === 100 ?
                                                <div style={{ display: 'flex' }} >
                                                    <TextNameFile2>
                                                    {file !== undefined && file.originalFileName !== undefined && file.originalFileName.slice(0, -4).length <= 20
                                                        ? file.originalFileName.slice(0, -4)
                                                        : file.originalFileName.slice(0, 17) + '...'}
                                                    </TextNameFile2>.pdf
                                                    <Ex className='hide' id={i} onClick={handleRemovePrev} style={{
                                                        minWidth: '12px',
                                                        minHeight: '12px', margin: '5px 0 0 10px', cursor: 'pointer'
                                                    }} />
                                                </div>
                                                :
                                                <>
                                                    <div style={{ display: 'flex', opacity: '0.3' }} >
                                                        <TextNameFile>{file.originalFileName.slice(0, -4).length <= 20 ? file.originalFileName.slice(0, -4) : file.originalFileName.slice(0, 17) + '...'}</TextNameFile>.pdf
                                                    </div>
                                                    <ProgressBar percent={progress[i]} active size='tiny' style={{ display: 'flex' }} />
                                                </>

                                            }
                                        </div>
                                    )
                                })
                                }

                                {agreementsFilesState &&
                                    agreementsFilesState.map((file, i) => {
                                        return (
                                            <div className='file' key={i} style={{ display: 'flex', flexDirection: 'column' }} onMouseEnter={() => setIsVisible(true)} onMouseLeave={() => setIsVisible(false)}>
                                                {progress[i] === 100 ?
                                                    <div style={{ display: 'flex' }} >
                                                        <TextNameFile2>{file.fileName.slice(0, -4).length <= 20 ? file.fileName.slice(0, -4) : file.fileName.slice(0, 17) + '...'}</TextNameFile2>.pdf
                                                        <Ex className='hide' id={i} onClick={handleRemove} style={{
                                                            minWidth: '12px',
                                                            minHeight: '12px', margin: '5px 0 0 10px', cursor: 'pointer'
                                                        }} />
                                                    </div>
                                                    :
                                                    <>
                                                        <div style={{ display: 'flex', opacity: '0.3' }} >
                                                            <TextNameFile>{file.fileName.slice(0, -4).length <= 20 ? file.fileName.slice(0, -4) : file.fileName.slice(0, 17) + '...'}</TextNameFile>.pdf
                                                        </div>
                                                        <ProgressBar percent={progress[i]} active size='tiny' style={{ display: 'flex' }} />
                                                    </>

                                                }
                                            </div>
                                        )
                                    })
                                }


                            </DivFilesNames>
                        }
                        {errorFile === true
                            && <Container2> Debes adjuntar la vinculación </Container2>
                        }
                    </DividerAttachments>
                    {maxQuantityAchieved === true
                        && <Container style={{ margin: '10px 0px' }}>
                            <p> Solo puedes adjuntar hasta 10 documentos.</p>
                        </Container>
                    }
                    {fileTypeError === true
                        && <Container style={{ margin: '10px 0px' }}>
                            <p> Solo puedes adjuntar documentos PDF.</p>
                        </Container>
                    }
                    {repeatedFileError === true
                        && <Container style={{ margin: '10px 0px' }}>
                            <p> No puedes adjuntar documentos con el mismo nombre</p>
                        </Container>
                    }

                    <Title2>Servicios de notificación</Title2>
                    <InputCheckbokForm
                        label='Solo envío'
                        value={sendNotification}
                        validator={validator}
                        validateOptions='required'
                        id='sendNotification'
                    />
                    <InputCheckbokForm
                        label='Envió con notificación push'
                        onChange={toggle}
                        value={pushNotification}
                        validator={validator}
                        validateOptions='required'
                        id='pushNotification'
                    />
                    <InputCheckbokForm
                        label='Envió con confirmación de lectura'
                        onChange={toggle}
                        value={notificationReading}
                        validator={validator}
                        validateOptions='required'
                        id='notificationReading'
                    />
                    <InputCheckbokForm
                        label='Envió con firma en notificación'
                        onChange={toggle}
                        value={notificationSignature}
                        validator={validator}
                        validateOptions='required'
                        id='notificationSignature'
                    />
                    <InputCheckbokForm
                        label='Envió con firma en documentos'
                        onChange={toggle}
                        value={documentSignature}
                        validator={validator}
                        validateOptions='required'
                        id='documentSignature'
                    />
                    {
                        pushNotification === true && notificationReading === true && notificationSignature === true && documentSignature === true
                        &&
                        <Container>
                            <p>Recuerda que, aunque selecciones todos los servicios, al momento de crear una notificación solo podrás solicitar <strong>firma en notificación o firma en documentos.</strong></p>
                        </Container>
                    }
                    <DividerStatus><TextStatus>Estado de vinculación:</TextStatus> <PStatus>{agreementStatus[status]}</PStatus></DividerStatus>
                    <ButtoCancelAgreement
                        onClick={handleClickCanceleAgreement}
                    >
                        <DeleteIcon style={{margin: '-2px 6px 0 0px'}}/>
                        Anular vinculación
                    </ButtoCancelAgreement>
                    <ContainerButtons>
                        <ButtoCancel
                            onClick={handleCancel}
                        >Cancelar
                        </ButtoCancel>
                        <ButtoSave
                            disabled={stateFormEdit}
                            className='putButton'
                            onClick={handleSend}
                        >Actualizar
                        </ButtoSave>
                    </ContainerButtons>
                </CustomForm>
                <WarningModalCancelAgreement />
                <ModalCancelAgreement />
            </Divider >
        </>
    )
}

export default EditFormAgreements