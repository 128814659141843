import React from 'react'
import {connect} from 'react-redux'
import {Popup} from 'semantic-ui-react'
import styled from 'styled-components'
import {withRouter} from 'react-router-dom';
import {actionFilterAgreements} from '../../../actions/filterActions'
import {changeDataCreateReport} from '../../../actions/reportsActions'
import ButtonIcon from '../../../components/Button/IconButton'
import {ReactComponent as Filter} from '../../../assets/icons/filter.svg'
import {ReactComponent as Reviewed} from '../../../assets/icons/reviewed.svg'
import '../main.css'
import PropTypes from "prop-types";

const Divider = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 1280px) {
    padding: 0px 0px 0px 0px;
  }
`
const Texto = styled.div`
  color: #7C7C74;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  @media only screen and (max-width: 750px) {
    display: none;
  }
`
const PopupFilter = styled(Popup)`
  background: #fff !important;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.25) !important;
  border-radius: 4px !important;
  padding: 0 !important;
  width: 190px !important;
  left: -1vw !important;
  border: none !important;

  &:before {
    display: none !important;
  }
`
const ButtonOption = styled.button`
  color: #7C7C74;
  border: 0;
  background: #fff;
  font-weight: normal;
  font-size: 14px;
  height: 32px;
  text-align: left;
  width: 100%;
  border-radius: 4px;
`
const Text = styled.p`
  color: #7C7C74;
  font-weight: normal;
  font-size: 14px;
  padding-left: 40px;
`

const ContainerOption = styled.div`
  padding: 8px 0px !important;
`

class PopUpFilterAgreements extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      agreementStatus: '',
      pathname: '',
      dataCreateReport:{
        reportType:this.props.reportType,
        status: ["ACTIVE","PENDING","INACTIVE","EXPIRED"],
        providerType:this.props.providerType
      }
    }
  }

  componentDidMount(){
    this.props.changeDataCreateReport(this.state.dataCreateReport)
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (this.state.agreementStatus !== prevState.agreementStatus) {
      this.props.actionFilterAgreements(this.state.agreementStatus)
    }

    if (this.state.dataCreateReport !== prevState.dataCreateReport) {
      this.props.changeDataCreateReport(this.state.dataCreateReport)
    }
  }

  toggle = () => {
    this.setState({agreementStatus: this.props.agreementStatusReducer})
    this.setState({show: !this.state.show})
  }

  toggleAfterSelect = () => {
    this.setState({show: !this.state.show})
  }

  changeStatus = (optionSelected) => {
    this.setState({
      agreementStatus: optionSelected,
      dataCreateReport:{
        ...this.state.dataCreateReport,
        status: optionSelected === '' ? ["ACTIVE","PENDING","INACTIVE","EXPIRED"] : optionSelected,
      }
    })
    this.toggleAfterSelect()
  }

  render() {
    return (
      <Divider>
        <PopupFilter
          position='bottom left'
          on='click'
          pinned
          open={this.state.show}
          onClose={this.toggle}
          trigger={
            <ButtonIcon onClick={this.toggle}>
              <Texto>
                Filtrar
              </Texto>
              <Filter/>
            </ButtonIcon>
          }
        >
          <ContainerOption>
            <ButtonOption
              className='buttonOptionHover'
              onClick={() => this.changeStatus('ACTIVE')}
            >
              {
                this.state.agreementStatus === 'ACTIVE'
                && <Reviewed className='iconReReviewed'/>
              }
              {
                this.state.agreementStatus !== 'ACTIVE'
                  ? <Text>En curso</Text>
                  : 'En curso'
              }
            </ButtonOption>
            <ButtonOption
              className='buttonOptionHover'
              onClick={() => this.changeStatus('PENDING')}
            >
              {
                this.state.agreementStatus === 'PENDING'
                && <Reviewed className='iconReReviewed'/>
              }
              {
                this.state.agreementStatus !== 'PENDING'
                  ? <Text>Aprobado</Text>
                  : 'Aprobado'
              }
            </ButtonOption>
            <ButtonOption
              className='buttonOptionHover'
              onClick={() => this.changeStatus('INACTIVE')}
            >
              {
                this.state.agreementStatus === 'INACTIVE'
                && <Reviewed className='iconReReviewed'/>
              }
              {
                this.state.agreementStatus !== 'INACTIVE'
                  ? <Text>Anulado</Text>
                  : 'Anulado'
              }
            </ButtonOption>
            <ButtonOption
              className='buttonOptionHover'
              onClick={() => this.changeStatus('EXPIRED')}
            >
              {
                this.state.agreementStatus === 'EXPIRED'
                && <Reviewed className='iconReReviewed'/>
              }
              {
                this.state.agreementStatus !== 'EXPIRED'
                  ? <Text>Vencido</Text>
                  : 'Vencido'
              }
            </ButtonOption>
            <ButtonOption
              className='buttonOptionHover'
              onClick={() => this.changeStatus('')}
            >
              {
                this.state.agreementStatus === ''
                && <Reviewed className='iconReReviewed'/>
              }
              {
                this.state.agreementStatus !== ''
                  ? <Text>Limpiar filtro</Text>
                  : 'Limpiar filtro'
              }
            </ButtonOption>
          </ContainerOption>
        </PopupFilter>
      </Divider>
    )
  }
}

PopUpFilterAgreements.propTypes = {
  agreementStatusReducer: PropTypes.string,
  actionFilterAgreements: PropTypes.func.isRequired,
  changeDataCreateReport: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  agreementStatusReducer: state.filterReducer.agreementFilter.agreementStatus
})

const mapDispatchToProps = {
  actionFilterAgreements,
  changeDataCreateReport
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PopUpFilterAgreements))
