import React from 'react'
import PropTypes from 'prop-types'
import ContainerSearchAgreementsOperator from '../../../components/Search/SearchAgreements/operatorAgreements/ContainerSearchAgreementsOperator'
import AllAgreements from "./allAgreements/AllAgreementsOperator";
import { useSelector } from 'react-redux';

const OperatorAgreements = (props) => {
  const agreements = useSelector(state => state.agreementReducer.agreementListArray )

  return (
    <>
      <ContainerSearchAgreementsOperator 
        showAZ={true}
        reportType={"LIST_AGREEMENTS"} 
        providerType={"AGREEMENT"}
        disabledExportButton={!agreements.length > 0}
        showAdvancedSearch={false}
      />
      <AllAgreements history={props.history} />
    </>
  )
}

OperatorAgreements.propTypes = {
  history: PropTypes.any.isRequired,
}
export default OperatorAgreements
