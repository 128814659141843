import React, { Component } from "react";
import { connect } from "react-redux";
import RowTableAuditorImproperClients from "../RowTableAuditorImproperClients";
import _ from "lodash";
import moment from 'moment'
import PropTypes from "prop-types";
import GeneralTable from "../../../../components/Table/GeneralTable";
import {
  actionClearFilter,
  actionInitialPagination,
  actionFilterImproperClients,
} from "../../../../actions/filterActions";
import ModalFilterReports from "../../../../components/Search/SearchReports/ModalFilterReports";
import { getImproperClientsList } from '../../../../actions/improperClientsActions'

class AllAuditorImproperClients extends Component {
  static propTypes = {
    history: PropTypes.any.isRequired,
  };

  state = {
    loader: true,
    columnData: [
      { name: "Nombre", width: 3, arrow: false },
      { name: "Documento", width: 2, arrow: false },
      { name: "Teléfono", width: 2, arrow: false },
      { name: "Email", width: 3, arrow: false },
      { name: "Representante legal", width: 3, arrow: false },
      { name: "Reportes erróneos", width: 3, arrow: false },
    ],
    stateInitial: true,
  };

  componentDidMount() {
    this.props.actionClearFilter();
    this.makeImproperClientsRequest(this.props.filterReducer, this.state.stateInitial);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.filterReducer, this.props.filterReducer)) {
      if(!this.state.stateInitial){
        this.makeImproperClientsRequest(this.props.filterReducer);
        this.setState({loader: true})
      }
    }
  }

  makeImproperClientsRequest = (filterReducer, stateInitial=false) => {   
    const body = {
      count: filterReducer.count,
      pag: filterReducer.pag,
      sort: this.orderTranslator(filterReducer.sort),
      textToSearch: filterReducer.searchBar[0],
      filterPorcentages:filterReducer.improperClientsFilter.filterOptions
    };
    
    this.props.getImproperClientsList(body, stateInitial).then(() =>{
      this.setState({stateInitial: false})
      this.setState({loader: false})
    })
  };

  orderTranslator = (order) => {

    const options = {
      "masReciente": { _id: -1 },
      "masAntiguo": { _id: 1 },
      "A-Z": { name: 1 },
      "Z-A": { name: -1 },
    };
    return options[order];
  };


  render() {
    return (
      <>
        <GeneralTable
          list={this.props.improperClientsArray}
          typeList={this.props.filterReducer.isSearch ? "default" : "default"}
          listHeight = {'improperClients'}
          headerTable={this.state.columnData}
          rowComponent={RowTableAuditorImproperClients}
          history={this.props.history}
          loader={this.state.loader}
        />

        <ModalFilterReports />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  filterReducer: state.filterReducer,
  reportsListArray: state.reportsReducer.reportsList,
  elementsCount: state.reportsReducer.elementsCount,
  improperClientsArray: state.improperClientsReducer.improperClients
});

const mapDispatchToProps = {
  actionClearFilter,
  actionInitialPagination,
  actionFilterImproperClients,
  getImproperClientsList,
};

export default connect(mapStateToProps, mapDispatchToProps)(AllAuditorImproperClients);
