import React,  { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Modal } from 'semantic-ui-react'
import styled, {css} from 'styled-components'
import PrimaryButton from '../PrimaryButton'
import './MassiveLoadModal.css'
import PropTypes from "prop-types";
import { useHistory } from 'react-router-dom'

const ModalElement = styled(Modal)`
  padding: 40px !important;
  max-width: 576px !important;
  max-height: 442px !important;
  height: auto;
  overflow-y: auto;
`

const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const ModalMessage = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
`
const ModalActions = styled.div`
  margin-top: 32px;
`
const Title = styled.p`
  color: #133F4F;
  font-family: Roboto;
  font-weight: 900;
  font-size: 30px;
  font-style: normal;
  line-height: 35px;
  text-align: center;
`
const Text = styled.p`
  color: #133F4F;
  font-family: Roboto;
  font-weight: 700;
  font-size: 20px;
  font-style: normal;
  line-height: 23px;
  text-align: center;
  margin-bottom: 27px !important;
`
const FinalText = styled.p`
  color: #133F4F;
  font-family: Roboto;
  font-weight: 500;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
  margin-bottom: 32px;
  text-align: center;
`
const ProgressBarContent = styled.div`
  height: 16px !important;
  border-radius: 4px !important;
  margin: 52px 0px !important;
`

const ProgressBar = styled.div`
  height: 16px !important;
  background:#22C1D0 !important;
  min-width: 0.5em !important;
  border-radius: 4px !important;
  ${props => props.changeLoadCitizens &&
    css`
      width ${props.changeLoadCitizens}% !important;
  `}
  transition: all 0.2s ease .2s !important;
`
const TotalCitizens = styled.div`
  position: absolute;
  right: 8px;
  top: 1px;
  z-index: 1;
  line-height: initial;
  font-weight: 400;
  font-size:14px;
  color: #939393;
`
const ProgressNumber = styled.div`
  line-height: initial;
  font-weight: 600;
  font-size:14px;
  color: #22C1D0;
  position: absolute;
  top: -18px;
  right: 0;
`

const MassiveLoadModal = (props) => {
  const history = useHistory()
  const [dataLoadCitizens, setDataLoadCitizens] = useState(0)

  useEffect(() => {
    setDataLoadCitizens(((props.resultRecord.totalRecordsVerified*100)/props.resultRecord.totalRecordsToVerify))
    if(dataLoadCitizens === 100){
      return () => {
        clearInterval(props.intervalId)};
    }
  }, [props.resultRecord])

  const modalData = (type) => {
    const data = {
      LoadingCitizens: {
        InitialMessage: "Cargando ciudadanos",
        progressBar: true,
        SecondMessage: "¡Esto puede tardar más de lo esperado!",
        message: 'Si lo deseas, minimiza este proceso y continua con tus actividades mientras se realiza la carga, podrás retomar este envío desde la ventana desplegable en la parte inferior derecha.',
        buttonMessage: 'Minimizar',
        buttonMessage2: 'Continuar',
      }
    }
    return data[type]
  }

  const data = modalData('LoadingCitizens')
  return (
    <ModalElement
      open={props.modalActive}
      onClose={props.onClose}
    >
      <ModalContent className={'progress'}>
          <ModalMessage>
            <Title>{data.InitialMessage}</Title>
            <ProgressBarContent className="ui small progress">
              <TotalCitizens>{props.resultRecord.totalRecordsToVerify}</TotalCitizens>
              <ProgressBar className='bar' changeLoadCitizens={dataLoadCitizens}>
                <ProgressNumber>{props.resultRecord.totalRecordsVerified}</ProgressNumber>
              </ProgressBar>
            </ProgressBarContent>
            <Text>¡Esto puede tardar más de lo esperado!</Text>
            <FinalText>{data.message}</FinalText>
            {
              dataLoadCitizens === 100
              ? 
                <PrimaryButton
                  txtBtn={data.buttonMessage2}
                  onPress={props.onClose2}
                />
              :
                <PrimaryButton
                  txtBtn={data.buttonMessage}
                  onPress={props.onClose}
                />
            }
            
          </ModalMessage>
      </ModalContent>
    </ModalElement>
  )
}
MassiveLoadModal.propTypes = {
  modalState: PropTypes.object,
}
const mapStateToProps = (state) => ({
  modalState: state.modalReducer.messageLoginModal
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(MassiveLoadModal)
