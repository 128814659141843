import React from 'react'
import styled from 'styled-components'
import IconSvg from './CustomSvg'

const Svg = styled(IconSvg)` 
  width: 24px; 
  height: 24px;
`

export const CloseSvgIcon = ({ className }) => (
  <Svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      d="M16 2.53916L14.3886 0.927734L8 7.31631L1.61143 0.927734L0 2.53916L6.38857 8.92773L0 15.3163L1.61143 16.9277L8 10.5392L14.3886 16.9277L16 15.3163L9.61143 8.92773L16 2.53916Z"
    />
  </Svg>
)