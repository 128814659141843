import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { actionUpdateTabFilter } from '../../../../actions/filterDetailActions'
import ContainerEmployeeSearch from '../../../../components/Search/SearchDetailClient/Containers/ContainerEmployeeSearch'
import WarningModal from '../../../../components/FormUser/Modal/WarningModal';

import Users from './Users'

const AllUsers = (props) => {

    const dispatch = useDispatch()
    const idClients = sessionStorage.getItem("id")

    useEffect(() => {
        dispatch(actionUpdateTabFilter('users'))
    }, [dispatch])

    return (
        <>
            <ContainerEmployeeSearch filterPosition={props.filterPosition}/>
            <WarningModal />
            <Users
                companySerialID={idClients}
            />
        </>
    )
}

AllUsers.propTypes = {
}

export default AllUsers 