import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Modal } from 'semantic-ui-react'
import styled from 'styled-components'
import '../../Modal/messageModal.css'
import PropTypes from "prop-types"
import InputFormArea from '../InputAnimated/InputTextArea'
import useValidator from '../../../helpers/simpleReactValidatorHook'
import { reactValidatorOptions } from '../../../helpers/simpleReactValidator'
import { warningModalReasonCanceleAgreement } from '../../../actions/modalActions'
import { canceleAgreementReason } from '../../../actions/agreementActions'

const ModalElement = styled(Modal)`
  height: 406px !important;
  padding: 40px !important;
  width: 496px !important;
`
const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const ModalMessage = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
`
const ModalActions = styled.div`
  margin-top: 10px;
`
const Texto = styled.p`
  color: #133F4F;
  font-family: Roboto;
  font-weight: 700;
  font-size: 20px;
  font-style: normal;
  line-height: 23px;
  text-align: center;
  margin-bottom: 15px !important;
`
const Button = styled.button`
  background: #FFFFFF;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  color: #22C1D0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  width: 128px;
  height: 42px;
  margin-Right: 16px;
  cursor:pointer;
`
const Button2 = styled.button`
  background: #22C1D0;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  color: #FFFFFF;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  width: 181px;
  height: 42px;
  margin-Right: 16px;
  cursor:pointer;
`
const DividerTextArea = styled.div`
  width: 413px;
`
const Text2 = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: right;
  color: #000000;
  margin-top: 10px;
`

const ModalCancelAgreement = (props) => {
    const modalData = (type) => {
        const data = {
            reasonCanceleAgreement: {
                InitialMessage: "Motivo de la anulación de la vinculación",
                buttonMessage: 'Cancelar',
                buttonMessage2: 'Anular vinculación',
            }
        }
        return data[type]
    }
    const dispatch = useDispatch()
    const [reasonCancele, setReasonCancele] = useState('')
    const [validator, showValidationMessage] = useValidator(reactValidatorOptions)
    const [errors, setErrors] = useState(false)
    const [stateActive, setStateActive] = useState(true)

    const onChange = e => {
        const name = e.target.id
        setReasonCancele(e.target.value)
        setStateActive(false)
    }

    const onClose = () => {
        dispatch(warningModalReasonCanceleAgreement({ type: 'reasonCanceleAgreement' }))
        setStateActive(true)
        setReasonCancele('')
    }

    const handleOnLoad = (e) => {
        e.preventDefault()
        if (validator.allValid()) {
            const id = sessionStorage.getItem("idAgreement")
            const body = {
                "agreementSerialID": id,
                "reason": reasonCancele
            }
            dispatch(canceleAgreementReason(body))
            dispatch(warningModalReasonCanceleAgreement({ type: 'reasonCanceleAgreement' }))
            setErrors(false)
        } else {
            showValidationMessage(true)
            setErrors(true)
        }
    }
    const data = modalData(props.modalState.type)
    return (
        <ModalElement
            open={props.modalState.status}
            onClose={onClose}
        >
            <ModalContent>
                {!data.multipleMessages &&
                    <ModalMessage>
                        <Texto>{data.InitialMessage}</Texto>
                        <br />
                        <DividerTextArea>
                            <InputFormArea
                                placeholder={'Min. 20 caracteres'}
                                id={'reasonCancele'}
                                label={'Motivo de anulación'}
                                type={'text'}
                                onChange={onChange}
                                value={reasonCancele}
                                validator={validator}
                                validateOptions={['descriptionCanceleAgreement']}
                                error={errors}
                                min={20}
                                max={1000}
                                lineHeight = {'21px'}
                                heightText={'168px'}
                                paddingText={'18px'}
                            />
                            <Text2>{reasonCancele.length}/1000</Text2>
                        </DividerTextArea>
                    </ModalMessage>
                }
                {
                    <ModalActions>
                        <Button
                            onClick={onClose}
                        >
                            {data.buttonMessage}
                        </Button>
                        <Button2
                            onClick={handleOnLoad}
                            disabled={stateActive}
                            className='putButton'
                        >
                            {data.buttonMessage2}
                        </Button2>
                    </ModalActions>
                }
            </ModalContent>
        </ModalElement>
    )
}
ModalCancelAgreement.propTypes = {
    modalState: PropTypes.object,
    warningModalReasonCanceleAgreement: PropTypes.func,
    activeForm: PropTypes.bool
}
const mapStateToProps = (state) => ({
    modalState: state.modalReducer.modalReasonCancelAgreement,
    activeForm: state.clientReducer.activeForm,
    activeFormProvider: state.providerReducer.activeForm,
    activeFormAgreements: state.agreementReducer.activeForm
})

const mapDispatchToProps = {
    warningModalReasonCanceleAgreement
}
const ShowTheLocationWithRouter = withRouter(ModalCancelAgreement);

export default connect(mapStateToProps, mapDispatchToProps)(ShowTheLocationWithRouter);
