import React from 'react'
import PropTypes from 'prop-types'

import ContainerGenericSearchBar from '../../components/Search/ContainerGenericSearchBar'
import AllUsers from './AllUsers'
import WarningModal from '../../components/FormUser/Modal/WarningModal'
import MessageModal from '../../components/Modal/MessageModal'

const Users = (props) => {

  return (
    <>
      <ContainerGenericSearchBar customWidthDiv2={'25%'} textPlaceHolder={'Nombre de usuario'} buttonType= {'user'} text={'Nuevo usuario'}/>
      <WarningModal />
      
      <AllUsers history={props.history} noHover={true}/>
    </>
  )
}

Users.propTypes = {
  history: PropTypes.any.isRequired,
}

export default Users