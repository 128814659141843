import React from "react"
import PropTypes from "prop-types"

import './styles.css'
import styled from "styled-components"
import {ValidateMessageErrors} from './ValidateMessageErrors'
import { useDispatch } from 'react-redux'

const Text = styled.div`

`
const Text2 = styled.span`
    
`
const InputFormValidate = ({ ...props }) => {

    const dispatch = useDispatch()
    const { placeholder, placeholderError, type, id, onChange, value, label, max, min, className, classNameError, onBlur, onFocus, validator, validateOptions, defaultValue, disabled, readOnly, messageErrors, viewMessage } = props

    let errorValidator = null
    let classNameCurrent= className
    let placeholderCurrent= placeholder
    let messageKey= ''
    if (validator && validateOptions) errorValidator = validator ? validator.message(label !== '' && label !== undefined ? label : placeholder, value, validateOptions) : ''

    if(errorValidator){
        messageKey =ValidateMessageErrors('write',errorValidator, value)
        if(messageErrors.hasOwnProperty(messageKey) && !!viewMessage){
            classNameCurrent= classNameError
        }
        if(value === ''){
            placeholderCurrent= placeholderError
            classNameCurrent= classNameError
        }
    }else{
        classNameCurrent= className
        placeholderCurrent= placeholder
    }
    return (
        <>
                    <input
                        id={id}
                        type={type}
                        value={value}
                        defaultValue={defaultValue}
                        placeholder={placeholderCurrent}
                        onChange={onChange}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        onKeyDown={(e) => e.keyCode === 13 && e.preventDefault()}
                        required
                        className={classNameCurrent}
                        disabled={disabled}
                        readOnly={readOnly}
                        autoComplete="off"
                        max={max}
                        min={min}
                        
                    />
                    {
                        !!errorValidator && messageErrors.hasOwnProperty(messageKey) && !!viewMessage?
                            <>
                                <text className={messageErrors[messageKey].className}>{messageErrors[messageKey].text}</text>
                            </>
                        :
                            ''
                    }


        </>
    )
}


InputFormValidate.propTypes = {
    labelProps: PropTypes.object,
    id: PropTypes.string,
}
export default InputFormValidate