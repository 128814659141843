import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {Label } from 'semantic-ui-react'

const CustomLabel = styled(Label)`
width: 100%;
height: 150px;
text-align: center;
box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.15);
border-radius: 4px;
`

const CustomNumber =styled.p`
font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 48px;
text-align: center;
color: #22C1D0;
@media only screen and (max-width: 1024px) {
    font-size: 40px;
}
`
const CustomP = styled.p`
font-family: 'Roboto';
font-style: normal;
font-weight: 600;
font-size: 18px;
text-align: center;
color: #133F4F;
@media only screen and (max-width: 1024px) {
font-size: 16px;
}
`



const DashBoardLabel = ({ text, number, maxW }) => {
    return (
        <CustomLabel align="center" style={{maxWidth: `${maxW && '410px'}`, backgroundColor: '#FFFFFF', padding:'2% 0%'}}>
            <CustomP style={{ backgroundColor: '#FFFFFF', margin : '0',padding: '5% 0px 0px'}}>{text}</CustomP>
            <CustomNumber>{number}</CustomNumber>
        </CustomLabel>
    )
}

DashBoardLabel.propTypes = {
    text: PropTypes.string.isRequired,
}

export default DashBoardLabel
