import axios from 'axios';
import {
  DETAIL_OF_NOTIFICATION,
  DOWNLOAD_REPORT,
  DETAIL_OF_DATA_PDF,
  DETAIL_OF_NOTIFICATION_MASSIVE,
  CHANGE_CHECKBOK_REPORT,
  DOWNLOAD_MASSIVE_REPORT,
  CLEAR_REPORT_MASSIVE_DATA,
  ON_LOADING_DOWNLOAD_REPORT,
  HAVE_ATTACHMENTS_MASSIVE,
  UPDATE_LIST_TO_DOWNLOAD,
  DELETE_FROM_LIST_TO_DOWNLOAD,
  VIEW_PAGINATION,
  ACTIVE_PAGINATION,
  SET_DOWNLOAD_ATTACHMENTS_LINK,
  VIEW_PAGINATION_INTERNAL,
} from './actionsUtilities/types.js'
import { API_ENDPOINT, API_FILE } from "../config/config"
import { reasonDispatch } from "./DispatchGeneric";
import { DOWNLOAD_CITIZEN_REPORT_MASSIVE, DOWNLOAD_CITIZEN_REPORT_MASSIVE_V2, DOWNLOAD_MASSIVE_ATTACHMENTS, DOWNLOAD_MASSIVE_ATTACHMENTS_V2, DOWNLOAD_REPORT_OF_EVENTS, DOWNLOAD_REPORT_OF_EVENTS_V2, ENCRYPT, GET_DETAIL_OF_MASSIVE_NOTIFICATION, GET_DETAIL_OF_NOTIFICATION, GET_DETAIL_OF_NOTIFICATION_V2 } from './actionsUtilities/actionsRoutes.js';
import { getDataCypher, getDataDecypher } from '../helpers/dataCypher.js';
import { decrypt, encrypt } from '../helpers/cypher.js';
import { getHeaders } from '../helpers/globalHelpers.js';

export const detailNotificationOneV2 = (body) => async (dispatch) => {
  dispatch({ type: ON_LOADING_DOWNLOAD_REPORT })
  const encryptedBody = await encrypt(body);
  await axios.post(`${API_ENDPOINT + GET_DETAIL_OF_NOTIFICATION_V2}`, {data: encryptedBody }, {headers: getHeaders()} ).then(async (res) => {
   
    const decryptedData = await decrypt(res.data.result);

    dispatch({
      type: DETAIL_OF_NOTIFICATION,
      payload: decryptedData
    })

  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
  })
}

export const getDetailMassiveNotification = (body) => async (dispatch) => {
  const headers = getHeaders()
  const encryptedBody = await encrypt(body);
  await axios.post(`${API_ENDPOINT + GET_DETAIL_OF_MASSIVE_NOTIFICATION}`, {data: encryptedBody}, { headers }).then(async (res) => {
    if (res.status === 200) {
      const decryptedData = await decrypt(res.data.result);
      dispatch({
        type: DETAIL_OF_NOTIFICATION_MASSIVE,
        payload: !Array.isArray(decryptedData) ? decryptedData : {notifications: []}
      })
      dispatch({
        type: VIEW_PAGINATION_INTERNAL,
        payload: {
          count: body.count,
          total: !!decryptedData.elementsCount ? decryptedData.elementsCount : 0,
        }
      })
    }
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)

  })
}

export const changeCheckbookReport = (key, value) => async (dispatch) => {
  dispatch({
    type: CHANGE_CHECKBOK_REPORT,
    payload: {
      key,
      value
    }
  })
}

export const downloadReportV2 = (addPrint, notificationID) => async (dispatch) => {
  dispatch({
    type: ON_LOADING_DOWNLOAD_REPORT
  })
  const headers = getHeaders()
  await axios.get(`${API_FILE + DOWNLOAD_REPORT_OF_EVENTS_V2}notificationID=${notificationID}&stamp=${addPrint}`, { headers }
  ).then(async (res) => {
    const decryptedData = await decrypt(res.data.result);
    dispatch({
      type: DOWNLOAD_REPORT,
      payload: decryptedData.result
    })
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
  })
}

export const downloadReportMassive = (body) => async (dispatch) => {
  const headers = getHeaders()
  const encryptedBody = await encrypt(body);
  try {
    const response = await axios.post(`${API_FILE + DOWNLOAD_CITIZEN_REPORT_MASSIVE_V2}`, {data:encryptedBody}, { headers })
    const decryptedData = await decrypt(response.data.result);
    if (response.status === 200) {
      dispatch({
        type: DOWNLOAD_MASSIVE_REPORT,
        payload: decryptedData.dataFinal
      })
    }
    return decryptedData.dataFinal.urlBouncedNotifications

  } catch (reason) {
    reasonDispatch(dispatch, reason, false)
    return reason.response.status
  }

}



export const upDatePDF = (body) => async (dispatch) => {
  dispatch({
    type: DETAIL_OF_DATA_PDF,
    payload: body
  })
}

export const clearReportMassiveData = () => dispatch => {
  dispatch({
    type: CLEAR_REPORT_MASSIVE_DATA,
  })
}

export const changeStateHaveAttachmentMassive = (haveAttachments) => dispatch => {
  dispatch({
    type: HAVE_ATTACHMENTS_MASSIVE,
    payload: haveAttachments
  })

}

export const updateListDownload = (id) => (dispatch) => {
  dispatch({
      type: UPDATE_LIST_TO_DOWNLOAD,
      payload: id
  })
}

export const removeFromListDownload = (id) => (dispatch) => {
  dispatch({
      type: DELETE_FROM_LIST_TO_DOWNLOAD,
      payload: id
  })
}

export const downloadMassiveAttachments = (body) => async (dispatch) => {
  const headers = getHeaders()
  const encryptedBody = await encrypt(body);
  await axios.post(`${API_FILE + DOWNLOAD_MASSIVE_ATTACHMENTS_V2}`, {data: encryptedBody}, { headers }
  ).then(async (res) => {
    const decryptedData = await decrypt(res.data.result);
    if (res.status === 200) {
      const link = document.createElement("a");
      link.href = decryptedData.url;
      link.setAttribute("download", "archivos_adjuntos.zip");
      document.body.appendChild(link);
      link.click();
      dispatch({
        type: SET_DOWNLOAD_ATTACHMENTS_LINK,
        payload: decryptedData.url,
      });
    }
  }).catch(async reason =>{
    await reasonDispatch(dispatch, reason, false)

  })

}
