import React, { useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { fileToBase64Clean } from '../../../helpers/filesLib'
import InputAttachButton from '../InputAnimated/InputAttachButton'
import { ReactComponent as Ex } from '../../../assets/icons/ex.svg'
import styled from "styled-components";
import {  Grid,Progress } from 'semantic-ui-react'
import InputFormArea from "../InputAnimated/InputTextArea";

const TextNameFile = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  line-height: 23px !important;
  color: #000000;
  font-size: 14px;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  margin: 0px !important;
`

const TextNameFile2 = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  line-height: 23px !important;
  color: #000000;
  font-size: 14px;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
   margin: 0 0 0.25rem !important;
`

const ProgressBar = styled(Progress)`
  width: 100%;
  height: 3px;
  border-radius: 0px !important;
  margin: 0 0 0.5em !important;
  & > .bar {
    background-color: #009BDD !important;
  }
`

const Container2 = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 14px;
  color: #C3132E;
  padding: 20px;
`
const Container = styled.div`
  border: 1px solid #E35F00;
  background: rgba(227, 95, 0, 0.1);
  color: #E35F00;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 1rem;
  padding: 10px 28px;
  text-align: center;
`

const DividerAttachments = styled.div`
    display: flex;
    width: 100% ;
    align-items: flex-start;
    padding-top: 20px;
    padding-bottom: 24px;

    @media only screen and (max-width: 768px) {
        flex-direction: column;
    }
`

const DivFilesNames = styled.div`
    display: flex;
    flex-direction: column;
    
    @media (max-width: 1200px) { 
        width: calc(100% - 180px);
    }

    @media (max-width: 768px) {
        margin-top: 16px;
        width: 100%;
    }
`;

export const AttachmentButtonForm = ({supportFiles,setSupportFiles,setErrorFile,formValidator,errorFile,supportDeleteFiles,setSupportDeleteFiles,supportDetail,onChange,validator,errors}) => {
    const [progress, setProgress] = useState([100,100,100,100,100,100,100,100,100,100])
    const [fileTypeError, setFileTypeError] = useState(false)
    const [maxQuantityAchieved, setMaxQuantityAchieved] = useState(false)
    const [repeatedFileError, setRepeatedFileError] = useState(false)
    const [isVisible, setIsVisible] = useState(false)
    
    

    const isFormBackEnd = (file) => ('originalFileName' in file);

    const existFileInArray = (file) => {
      const existingFiles = supportFiles
      for (let i = 0; i < existingFiles.length; i++) {
        if (isFormBackEnd(existingFiles[i])) {
          if(existingFiles[i].originalFileName === file.name){
            return true
          } 
        }else{
          if(file.name === existingFiles[i].fileName){
            return true
          }
        }
      }
      return false
    }
    
    

    const onChangeHandler = async (e) => {
      const files = e.target.files;
      let newFiles = [];
      let newProgress = [];
  
      if (files.length + supportFiles.length <= 10) {
          for (let i = 0; i < files.length; i++) {
              const fileName = files[i].name;
              let uniqueFileName = fileName;
              let counter = 1;

              while (existFileInArray({name:uniqueFileName})) {
                  const extensionIndex = fileName.lastIndexOf('.');
                  const name = fileName.substring(0, extensionIndex);
                  const extension = fileName.substring(extensionIndex);
                  uniqueFileName = `${name}(${counter})${extension}`;
                  counter++;
              }
  
              const fileInfo = {
                  fileId: uuidv4(),
                  base64: await fileToBase64Clean(uniqueFileName, files[i]),
                  fileName: uniqueFileName,
                  fileExtension: files[i].type.split("/").pop(),
              };
  
              if (fileInfo.fileExtension === "pdf") {
                  newFiles.push(fileInfo);
                  newProgress.push(0);
              } else {
                  setFileTypeError(true);
                  setTimeout(() => {
                      setFileTypeError(false);
                  }, 3000);
              }
          }
  
          setSupportFiles([...supportFiles, ...newFiles]);
  
          newFiles.forEach((file, index) => {
              loadNotes(supportFiles.length + index, files[index].size / 2500);
          });
  
          setErrorFile(false);
      } else {
          setMaxQuantityAchieved(true);
          setTimeout(() => {
              setMaxQuantityAchieved(false);
          }, 3000);
      }
  
      document.getElementById('supportFiles').value = '';
  };


    const loadNotes = async (number, size) => {
        for (let i = 10; i <= 100; i = i + 10) {
            await new Promise((resolve) => {
                setTimeout(function () { resolve(); }, size);
            });
    
            setProgress(prevProgress => {
                const newProgress = [...prevProgress];
                newProgress[number] = i;
                return newProgress;
            });
        }
    };

    const handleRemove = (e, index) => {
      e.stopPropagation();
      const updatedFiles = [...supportFiles];
      if ('originalFileName' in updatedFiles[index]) {
        setSupportDeleteFiles([...supportDeleteFiles,{"fileName":updatedFiles[index].fileName}])
      }
      updatedFiles.splice(index, 1);
      setSupportFiles(updatedFiles);
  };

  const truncateFileName = (fileName) => {
    const maxLength = 40;
    const fileNameWithoutExtension = fileName.replace(/\.[^/.]+$/, '');
    let truncatedLength = fileNameWithoutExtension.length;

    if (truncatedLength > maxLength) {
        truncatedLength -= 3;
    }

    let truncatedName = fileNameWithoutExtension.substring(0, maxLength);
    if (truncatedLength > maxLength) {
        const numberMatch = fileNameWithoutExtension.match(/\(\d+\)$/);
        if (numberMatch) {
            const numberLength = numberMatch[0].length;
            if (maxLength >= numberLength) {
                truncatedName = truncatedName.substring(0, maxLength) + '...' + numberMatch[0];
            } else {
                truncatedName += '...';
            }
        } else {
            truncatedName += '...';
        }
    }

    return truncatedName;
};

  return (
    <>
      <DividerAttachments>
        <InputAttachButton
          disabled={ supportFiles !== undefined && supportFiles.length > 9 ? true : false}
          onSelectFile={onChangeHandler}
          value={supportFiles}
          accept="application/pdf"
          className={!! errorFile ? 'supportFilesError': ''}
          id="supportFiles"
          validator={formValidator}
          validateOptions={["required", { min: 1 }]}
          text={"Adjuntar soporte de autorización"}
        />
        {supportFiles !== undefined && supportFiles.length !== 0 && (
          <DivFilesNames>
            {supportFiles.map(({ fileName,originalFileName }, i) => (
            
              <div
                className="file"
                key={i}
                style={{ display: "flex", flexDirection: "column" }}
                onMouseEnter={() => setIsVisible(true, i)}
                onMouseLeave={() => setIsVisible(false, i)}
              >
                {
                progress[i] === 100 ? (
                  <div style={{ display: "flex" }}>
                    <TextNameFile2>{originalFileName === undefined ? truncateFileName(fileName):truncateFileName(originalFileName)}</TextNameFile2>.pdf
                    <Ex
                      className="hide"
                      id={i}
                      onClick={(e) => handleRemove(e, i)}
                      style={{
                        minWidth: "12px",
                        minHeight: "12px",
                        margin: "5px 0 0 10px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                ) : (
                  <>
                    <div style={{ display: "flex", opacity: "0.3" }}>
                      <TextNameFile>{truncateFileName(fileName)}.pdf</TextNameFile>
                    </div>
                    <ProgressBar percent={progress[i]} active size="tiny" />
                  </>
                )}
              </div>
            ))}
          </DivFilesNames>
        )}
        {errorFile === true && (
          <Container2> Debes adjuntar el soporte de autorización</Container2>
        )}
      </DividerAttachments>
      {maxQuantityAchieved === true
        && <Container style={{ margin: '10px 0px' }}>
            <p> Solo puedes adjuntar hasta 10 documentos.</p>
        </Container>
      }
      {repeatedFileError === true
        && <Container style={{ margin: '10px 0px' }}>
            <p> No puedes adjuntar documentos con el mismo nombre</p>
        </Container>
      }
      {fileTypeError === true
          && <Container style={{ margin: '10px 0px' }}>
              <p> Solo puedes adjuntar documentos PDF.</p>
          </Container>
      }
      <InputFormArea
          id={'supportDescription'}
          label={'Detalle soporte de autorización'}
          type={'text'}
          onChange={onChange}
          value={supportDetail}
          validator={validator}
          validateOptions={['required', { min: 1 }, { max: 300 }]}
          error={errors}
          min={1}
          max={300}
          heightText={'96px'}
          stylesAgreements
      />
    </>
  );
};
