import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { configMenu } from '../../routes/menuRoutes'
import { errors } from '../../utils/errorsActions'
import { cleanErrorsAction } from '../../actions/errorsActions'
import { getRefreshToken, logoutUser } from '../../actions/authActions'

import TopMenuBar from './TopMenuBar'
import AppContent from './AppContent'
import { ReactComponent as GseIcon } from '../../assets/icons/Logo_registraduria.svg'
import { recordsMassiveFileV2 } from '../../actions/modalActions';
import { chageStateLoadCitizens, chageStateLoadNotifications, consultNotificationsProgressV2, saveHiredV2 } from '../../actions/writerActions';
import SendNotifications from '../SendNotifications/SendNotifications';
import SendCitizens from '../TemporalDashboard/SendCitizens';
import { LOGOUT, RESET_DASHBOARD, SET_LOGIN_TYPE, STATE_CLEAR_FILTER } from '../../actions/actionsUtilities/types';

const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const Footer = styled.footer`
  display: none;
  background: #F7F7F7;
  z-index: 2000;
  @media only screen and (max-width: 1500px)  {
    display: block;
    width:100%;
    text-align: center;
  }
`
const CourtainsContainer = styled.div`
  position:fixed;
  display: flex;
  flex-direction: row;
  justify-content: end;
  width: 100%;
  bottom 0;
  rigth: 16px;
  padding: 0 15% 0 0;
  z-index:2000;
  @media only screen and (max-height: 900px){
  } 
  @media only screen and (max-width: 1500px){
    padding: 0;
  }
  @media only screen and (max-width: 1124px){
    padding: 0;
    rigth: 0;
  }
  @media only screen and (max-width: 1024px){
    gap:8px;
    padding: 0 8px 0 8px;
  }
  @media only screen and (max-width: 768px){
    gap:63px;
    rigth: 0;
    flex-direction: column;
    padding:0;
    bottom:0;
    width: 100%;
  }
`
const Courtain = styled.div`
  margin: auto 10px 0 0;
  padding: 0;
  display: block;
  justify-content: end;
  width: 544px;
  position:relative;
  @media only screen and (max-width: 1280px){
    width:43%;
  }
  @media only screen and (max-width: 1024px){
    width:50%;
    margin: auto 0 0 0;
  }
  
  @media only screen and (max-width: 768px){
    margin: 0;
    width: 100%;
  }
`

const CourtainsBackground = styled.div`
    position: absolute;
    top:0;
    left:0;
    display: none;
    height: 100vh;
    width: 100vw;
    background: rgba(0,0,0,0.4);
    @media only screen and (max-width: 768px){
      display:block;
      z-index:2000;
    }
`

const AppContainer = (props) => {
  const { history, children, errorReducer } = props
  const { userPermission, role } = useSelector((state) => state.authReducer.user)
  const { typeOfLogin } = useSelector((state) => state.authReducer)

  errors(props, errorReducer)
  const companyID = useSelector(state => state.authReducer.user.companyID)
  const stateLoadingCitizens = useSelector(state => state.writerReducer.loadCitizens)
  const stateLoadNotifications = useSelector(state => state.writerReducer.loadNotifications)
  const { loadCitizensList, loadNotificationsList } = useSelector(state => state.writerReducer)
  const resultNotificationsProgress = useSelector(state => state.writerReducer.resultNotificationsProgress)
  const resultRecordFileMassive = useSelector(state => state.writerReducer.resultRecordFileMassive.listFileSearchMassive)
  const writeNotification = useSelector(state => state.modalReducer.writeNotification)
  const dispatch = useDispatch()

  useEffect(() => {
    if(role === '62ab678573b800f3b2f0abee') dispatch(saveHiredV2())
  }, [dispatch])

  useEffect(() => {
    if (userPermission) {
      if (companyID && userPermission.includes('RECORDS_FILE_MASSIVE') && userPermission.includes('PROCESS_FILE_MASSIVE') && !history.location.pathname.includes('/login')) {
        dispatch(recordsMassiveFileV2({ companyID: companyID }))
        dispatch(consultNotificationsProgressV2({}))
      }
    }
  }, [])

  useEffect(() => {
    if (userPermission) {
      if (userPermission.includes('RECORDS_FILE_MASSIVE') && userPermission.includes('PROCESS_FILE_MASSIVE') && !history.location.pathname.includes('/login')) {
        validateLoadCitizens();
        validateLoadNotifications();
      }
    }
  }, [resultRecordFileMassive.length !== 0, resultNotificationsProgress.progress.length !== 0])

  const validateLoadCitizens = () => {
    if (resultRecordFileMassive.length > 0) {
      dispatch(chageStateLoadCitizens(true));
    }
  }

  const validateLoadNotifications = () => {
    const statusList = ['En espera', 'En progreso']
    resultNotificationsProgress.progress.forEach((element) => {
      if (statusList.includes(element.state)) {
        dispatch(chageStateLoadNotifications(true));
      }
    });
  }

  useEffect(() => {
    if (!sessionStorage.getItem('secretKey') && userPermission) {
      dispatch({ type: LOGOUT })
      dispatch({ type: STATE_CLEAR_FILTER })
      dispatch({ type: RESET_DASHBOARD })
      if (!!history)
        if (typeOfLogin !== '' && typeOfLogin === 'withOutCertificate') {
          history.push({ pathname: '/login-user', state: { message: 'Sesión finalizada exitosamente' } })
        } else {
          history.push({ pathname: '/', state: { message: 'Sesión finalizada exitosamente' } })
        }
    }
  }, [])

  return (
    <>
      <VerticalContainer>
        <TopMenuBar menuRoutes={configMenu(userPermission)} history={history} />
        <AppContent menuRoutes={configMenu(userPermission)} history={history} children={children} />
        <Footer> <GseIcon /> </Footer>
        {(loadCitizensList || loadNotificationsList) && <CourtainsBackground />}
        {
          !!role ?
            <>
              {!!companyID && !writeNotification && !!userPermission && !history.location.pathname.includes('/login') && role === '62ab678573b800f3b2f0abee' &&

                <CourtainsContainer>
                  {stateLoadingCitizens && <Courtain ><SendCitizens /></Courtain>}
                  {stateLoadNotifications && <Courtain ><SendNotifications history={history} /></Courtain>}
                </CourtainsContainer>
              }
            </>
            :
            <>
            </>
        }
      </VerticalContainer>
    </>
  )
}

AppContainer.propTypes = {
  children: PropTypes.node,
}

const mapStateToProps = (state) => ({
  errorReducer: state.errorReducer,
  email: state.authReducer.user.email,
  id: state.authReducer.user.id,
})

export default connect(mapStateToProps, { cleanErrorsAction, logoutUser, getRefreshToken })(AppContainer)
