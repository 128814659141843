import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import QRCode from "react-qr-code"
import PrimaryButton from '../../components/PrimaryButton'
import BannerImage from '../../assets/icons/login/banner.svg'
import Butterflies from '../../assets/icons/login/butterflies.svg'
import { ReactComponent as Logo } from '../../assets/icons/login/logo.svg'
import { reactValidatorOptions } from '../../helpers/simpleReactValidator'
import useValidator from '../../helpers/simpleReactValidatorHook'
import { Form } from 'semantic-ui-react'
import { useHistory, useParams } from 'react-router-dom'
import { postLogin, resetNewQr, selectRole } from '../../actions/authActions'
import { Message } from 'semantic-ui-react'
import ModalGeneral from '../../components/ModalLogin/ModalGeneral'
import InputFormDropdownLogin from '../../components/InputLogin/InputFormDropdownLogin'
import InputForm from '../../components/InputLogin/InputForm';
import { LOGOUT } from '../../actions/actionsUtilities/types'
import { useCookies } from 'react-cookie';

const Banner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${BannerImage});
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100vh;

  @media only screen and (max-width: 992px)  {
    background-size: initial;
  }
`
const MainSegment = styled.div`
  width: 440px;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const LogoRow = styled.div`
  width: 100%;
  height: 13%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 30px;
`
const Content = styled.div`
  width: 100%;
  height: auto;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background: #FFFFFF;

  @media only screen and (max-width: 992px)  {
    width: 85%;
    margin: auto;
  }
`
const ContentButterflies = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${Butterflies});
  background-repeat: no-repeat;
  background-position-x: 100%;
  padding: 50px;

  @media only screen and (max-width: 992px)  {
    padding: 50px 20px;
  }
`
const Divider = styled.div`

`
const Text = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;  
  text-align: center;
  color: #000000;
`
const CustomForm = styled(Form)`
  display: flex !important;
  background-color: #fff;
  flex-direction: column !important;
  align-items: center !important;
  & > * {
    margin: 10px 0 !important;
  }
`
const CustomPrimaryButton = styled(PrimaryButton)`
  background: #22C1D0 !important;
  border-radius: 4px !important;
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  text-align: center !important;
  color: #FFFFFF !important;
  padding: 16px 32px !important;;
  width: 100% !important;
`
const CustomButton = styled(PrimaryButton)`
  background: #FFFFFF !important;
  border-radius: 4px !important;
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  text-align: center !important;
  border: 1px solid #22C1D0 !important;
  color: #22C1D0 !important;
  padding: 16px 10px !important;;
  width: 150px !important;
  @media only screen and (max-width: 460px){
  margin-right: 5px !important; 
  }
`
const CustomPrimaryButton2 = styled(PrimaryButton)`
  background: #22C1D0 !important;
  border-radius: 4px !important;
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  text-align: center !important;
  color: #FFFFFF !important;
  padding: 16px px !important;;
  width: 150px !important;
  @media only screen and (max-width: 460px){
  margin-left: 5px !important; 
  }
`
const Footer = styled.div`
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: flex-end
`
const ForgotPassword = styled.button`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  text-decoration-line: underline;
  color: #22C1D0;
  cursor: pointer;
  border: none;
  background: white;
`

const MessageLogin = styled(Message)`
  width: 100% !important;
  text-align: center !important;
  letter-spacing: 0 !important;
  background: rgba(186, 53, 85, 0.1) !important;
  border: 1px solid #BA3555 !important;
  box-sizing: border-box !important;
  border-radius: 4px;
  color: #BA3555 !important;
  margin: 10px !important;
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;
`
const ActionsConatainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  width: 100%;
  margin-top: 24px;
`
const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #133F4F;
  margin-bottom: 24px;
`

const QRView = () => {

  const dispatch = useDispatch()
  const { status, type } = useSelector((state) => state.modalReducer.messageLoginModal)
  const { errors, showError, errorsForm } = useSelector((state) => state.errorReducer)
  const customEqual = (oldValue, newValue) => oldValue === newValue
  const { numberLogin, roles } = useSelector(state => state.authReducer, customEqual)
  const [code, setCode] = useState('')
  const [error, setError] = useState('')
  const [validator, showValidationMessage] = useValidator(reactValidatorOptions)
  const { userId } = useParams()
  const history = useHistory()
  const [listRolesUser, setListRolesUser] = useState([])
  const [idUserCertificate, setIdUserCertificate] = useState('')
  const [role, setRole] = useState('')
  const [cookies, setCookie] = useCookies()
  const myCookie = { cookies, setCookie }

  const onChange = e => {
    setCode(e.target.value)
  }

  const onChangeReset = () => {
    dispatch(resetNewQr(userId))
  }

  useEffect(() => {
    if (showError === true) {
      setError(errorsForm)
    }
  }, [showError])

  useEffect(() => {
    if (!!roles.length) {
      if (numberLogin === 2 && roles.length > 1) {
        const rolesList = roles.map((x) => ({
          key: x.userSerialID,
          text: x.roleName,
          value: x.role + ',' + x.userSerialID
        }));
        rolesList.unshift({ key: '', text: '', value: '' });
        setListRolesUser(rolesList)
      }
    }
  }, [roles])

  const handleOnChange = (event, { value }) => {
    setRole(value)
    setIdUserCertificate(value.split(',')[1]);
  };

  const setLogin = (e) => {
    e.preventDefault()
    const body = {
      "userSerialID": userId,
      "OTPcode": code
    }
    dispatch(postLogin(history, body, myCookie))
  }

  const closeWindow = () => {
    window.top.close();
  }
  const handleLogin = (e) => {
    e.preventDefault()
    dispatch(selectRole(history, { userSerialID: idUserCertificate }))
  }
  const handleBack = () => {
    dispatch({ type: LOGOUT })
    history.push('/login');
  };

  return (
    <>
      <Banner>
        <MainSegment>
          <LogoRow>
            <Logo />
          </LogoRow>
          <Content>
            <ContentButterflies>
              {!listRolesUser.length &&
                <>
                  <Text>Ingresa el código generado desde la aplicación Authenticator.</Text>
                  <CustomForm className='ui form' onSubmit={setLogin}>
                    <InputForm
                      id={'code2'}
                      label={'Código'}
                      type={'text'}
                      onChange={onChange}
                      value={code}
                      validator={validator}
                      validateOptions={['required', { min: 4 }, { max: 15 }]}
                      autocomplete="code"
                    />
                    {error.message && <MessageLogin content={error.message} />}
                    <CustomPrimaryButton
                      fluid={true}
                      txtBtn='Ingresar'
                      login={true}
                    />
                  </CustomForm>
                  <Divider />
                  <Footer>
                    <ForgotPassword onClick={onChangeReset}> Perdí mi acceso al código </ForgotPassword>
                  </Footer>
                </>
              }
              {listRolesUser.length > 1 && (
                <>
                  <Title>Seleccionar rol</Title>

                  <InputFormDropdownLogin
                    options={listRolesUser}
                    placeholder={'Selecciona el rol'}
                    value={role}
                    handleOnChange={handleOnChange}
                    id={'role'}
                    validator={validator}
                    validateOptions={'required'}
                    error={errors}
                  />
                  <ActionsConatainer>
                    <CustomPrimaryButton2 fluid={true} txtBtn='Ingresar' login={true} onPress={handleLogin} />
                    <CustomButton fluid={true} txtBtn='Cancelar' onPress={handleBack} />
                  </ActionsConatainer>
                </>
              )}
            </ContentButterflies>
          </Content>
        </MainSegment>
        <ModalGeneral type='sendEmail' />
      </Banner>
    </>
  )
}
export default QRView