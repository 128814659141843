import React, { Component } from 'react'
import { SearchBarGenericCreate } from './SearchBarGeneric'

class ContainerGenericSearchBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchBarState: "",
      popUpOrderState: "",
    };
  }
  render() {
    return (
     <SearchBarGenericCreate customWidthDiv2={this.props.customWidthDiv2} textPlaceHolder={this.props.textPlaceHolder} searchBarState={this.searchBarState} 
                      buttonType= {this.props.buttonType} text={this.props.text} popUpOrderState={this.popUpOrderState}/>
    )
  }
}

export default ContainerGenericSearchBar