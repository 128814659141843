import React, {Component} from 'react'
import styled from 'styled-components'
import {connect} from 'react-redux'
import MenuButton from './MenuButton'
import WriteButton from './WriteButton'
import { ReactComponent as CloseX } from '../../../assets/icons/menu/close-icon.svg'

const ContentDataUserMenu = styled.div`
  width:100%;
  height: 80px;
  display:none;
  background: #133F4F;
  position:relative;
  @media only screen and (max-width: 1280px) {
    display: contents;
  }
`

const ContainerClose = styled.div`
  width:100vw;
  height: 50px;
  background: #133F4F;
  display: none;
  @media only screen and (max-width: 768px) {
    display: table-cell;
  }
`
const ContentOptions = styled.div`
`

const CloseXIcon = styled(CloseX)`
  width: 24px;
  height: 24px;
  margin: 15px;
  float: right;
  cursor: pointer;
`

class UsersMenu extends Component {
  validateRouteClidren = (route) =>{
    if(route.children){
      for (let i = 0; i < route.children.length; i++) {
        const child = route.children[i];
        if (child === this.props.url.split('/')[1])
        return true
      }
    }
    return false
  }
  render() {
    return (
      <>
        <ContentDataUserMenu>
          <ContainerClose onClick={this.props.changeView}>
            <CloseXIcon onClick={this.props.changeView}/>
          </ContainerClose>
          {this.props.dataUser}
        </ContentDataUserMenu>
        <ContentOptions onClick={this.props.changeView}>
          {
            this.props.menuRoutes &&
            this.props.menuRoutes.map((value, index) => {
                return (
                  <div key={index}>
                    {
                      index === 0 && value.name ==="write"
                        ? <WriteButton value={value}/>
                        :
                          <MenuButton
                            value={value}
                            active={this.props.url === value.component || this.validateRouteClidren(value) }
                            redirect={this.props.redirect}
                          /> 
                    }
                  </div>
                )
              })
          }
        </ContentOptions>
      </>                                                                                                                                                                                                                                      
    )
  }
}


const mapStateToProps = (state) => ({
  userRole: state.authReducer.user.role
})

export default connect(mapStateToProps, {})(UsersMenu)
