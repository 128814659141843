import React, { useEffect, useState } from 'react'
import HearderMasive from './HearderMasive'
import { SentMasiveAll } from './TableMasive/SentMasiveAll'
import { downloadMassiveAttachments, downloadReportMassive, getDetailMassiveNotification } from '../../../actions/seeNotificationAction'
import { useDispatch, useSelector } from 'react-redux'
import { Dimmer, Loader, Segment } from 'semantic-ui-react'
import { activePagination} from '../../../actions/filterDetailActions'
import ContainerSearchMassive from '../../../components/Search/ContainerSearchMassive'
import { CheckBoxGeneric } from '../../../components/CheckboxForm/CheckBoxGeneric'
import styled from 'styled-components'
import { ReactComponent as Download } from '../../../assets/icons/download-attachment.svg'
import { ADD_ALL_TO_LIST_TO_DOWNLOAD, DELETE_ALL_FROM_LIST_TO_DOWNLOAD } from '../../../actions/actionsUtilities/types'
import { CheckBoxGenericMinusIcon } from '../../../components/CheckboxForm/CheckBoxGenericMinusIcon'
import { UseWindowDimensions } from '../../../utils/UseWindowsDimentions'


const Divider = styled.div`
  display: flex;
  flex-direction: row;
  height: 30px;
  padding: 0 0 0.5rem 2.5rem;
  @media only screen and (max-width: 1024px) {
    padding: 0 0 0.5rem 10px !important;
  }
  @media only screen and (max-width: 768px) {
    padding: 0 0 0.5rem 1rem !important;
  }
  `

const orderTranslator = (order) => {
  const options = {
    'masReciente': { "notificationStatus.date": -1 },
    'masAntiguo': { "notificationStatus.date": 1 },
    'A-Z': { "recipient.fullName": 1 },
    'Z-A': { "recipient.fullName": -1 }
  }
  return options[order]
}

const SentMasive = (props) => {
  const dispatch = useDispatch()
  const { notificationID } = props.match.params
  const [loader, setLoader] = useState(true)
  const filterReducer = useSelector((state) => state.filterDetailReducer)
  const [isVisible, setIsVisible] = useState(false)
  const [activeAll, setActiveAll] = useState(false)
  const [selectCheckBox, setSelectCheckBox] = useState(false)
  const [init, setInit] = useState(true)
  const windowDimensions = UseWindowDimensions();
  const customEqual = (oldValue, newValue) => oldValue === newValue
  var listToDownload = useSelector((state) => state.sentReducer.listToDownload, customEqual)
  const { detailMassiveNotification, attachmentsDownloadLink } = useSelector((state) => state.sentReducer)
  const { isConfirmationRead, isSignNotification, bouncedCount, notifications, isPushNotification,
    nameCampaing, dateCampaing, elementsCount, elementsCountPage, haveAttachments, statusCount } = detailMassiveNotification

  useEffect(() => {
    makeNotificationsRequest(filterReducer)
  }, [notificationID, dispatch])

  useEffect(() => {
    if (detailMassiveNotification.fileSerialID) {
      dispatch(downloadReportMassive({ fileSerialID: detailMassiveNotification.fileSerialID })).then(() => {
        setLoader(false)
      })
    }
    dispatch({ type: DELETE_ALL_FROM_LIST_TO_DOWNLOAD })
  }, [detailMassiveNotification, dispatch])

  useEffect(() => {
    if (!init) {
      makeNotificationsRequest(filterReducer)
      setLoader(true)
      setInit(true)
    }
  }, [filterReducer])

  useEffect(() => {
    if (listToDownload.length > 0) {
      setIsVisible(true)
      setSelectCheckBox(true)
    } else {
      setActiveAll(false)
      setSelectCheckBox(false)
      setIsVisible(false)
    }
  }, [listToDownload])

  const makeNotificationsRequest = (filterReducer) => {
    const body = {
      massiveNotificationSerialID: notificationID,
      count: 10,
      pag: filterReducer.pag,
      textToSearch: filterReducer.searchBar[0],
      status: filterReducer.searchAdvanced.notificationState === "MASSIVE" ? '' : filterReducer.searchAdvanced.notificationState,
      sort: orderTranslator(filterReducer.sort),
    }
    dispatch(getDetailMassiveNotification(body)).then(() => {
      setLoader(false)
      setInit(false)
      dispatch(activePagination(false))
    })
  }

  const handleSelectAll = (e) => {
    e.preventDefault()
    setActiveAll(!activeAll)
    setIsVisible(!isVisible)
    setSelectCheckBox(!selectCheckBox)
    if (!activeAll) {
      dispatch({ type: ADD_ALL_TO_LIST_TO_DOWNLOAD })
    } else {
      dispatch({ type: DELETE_ALL_FROM_LIST_TO_DOWNLOAD })
    }
  }

  const handleClickDownload = async () => {
    dispatch(downloadMassiveAttachments({ notificationSerialIDList: listToDownload }))
  }

  return (
    <Segment style={{ margin: '0px', padding: '0px' }}>
      <Dimmer.Dimmable>
        <Dimmer active={loader} inverted page={false} >
          <Loader size='medium'>Cargando...</Loader>
        </Dimmer>
        <HearderMasive isPushNotification={isPushNotification} bouncedCount={bouncedCount} isConfirmationRead={isConfirmationRead} nameCampaing={nameCampaing}
          isSignNotification={isSignNotification} dateCampaing={dateCampaing} statusCount={statusCount} />
        <ContainerSearchMassive showAdvancedSearch={false} filterSmall={windowDimensions.width > 768 ? false : true} />
        {haveAttachments &&
          <Divider style={{ display: 'flex', padding: '0 0 0.5rem 2.2rem', height: '30px' }}>
            {listToDownload.length === elementsCountPage ?
              <CheckBoxGeneric onChange={handleSelectAll} checked={selectCheckBox} />
              :
              <CheckBoxGenericMinusIcon onChange={handleSelectAll} checked={selectCheckBox} />
            }
            {isVisible && haveAttachments && <div style={{ display: 'flex', color: '#22C1D0' }}>
              <Download style={{ cursor: 'pointer', margin: '2px 6px 0px 28.6px' }} onClick={handleClickDownload} />
              <div style={{ cursor: 'pointer' }} onClick={handleClickDownload}>Descargar adjuntos</div>
            </div>}
          </Divider>
        }
        <SentMasiveAll notifications={notifications} loader={false} activeAll={activeAll} />
      </Dimmer.Dimmable>
    </Segment>
  )
}

export default SentMasive