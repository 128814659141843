import React, { Component } from 'react'
import Paginacion from '../../Paginacion'
import SearchBar from '../../SearchBar'
import styled from 'styled-components'
import PopUpOrder from '../../PopUpOrder'
import ButtonNewEmployee from '../Buttons/ButtonNewEmployee'
import { SearchBarDetailGenericButton } from '../../SearchBarGeneric'
import { connect } from 'react-redux'

const Divider = styled.div`
`

class ContainerEmployeeSearch extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchBarState: "",
            popUpOrderState: "",
        };
    }
    render() {
        return (
            <Divider>
                <SearchBarDetailGenericButton buttonType={'employeeProvider'} text={'Nuevo usuario'} showButton={true} disabledButton={this.props.originalEmployeesCount !== 0? true:false }
                textPlaceHolder={'Buscar'} popUpOrderState={this.popUpOrderState} searchBarState={this.searchBarState}
                showFilters={false} paddingLeft={'20px'} filterPosition={this.props.filterPosition}/>
            </Divider>
        )
    }
}
const mapStateToProps = (state) => ({
    employees: state.clientReducer.employees,
    originalEmployeesCount: state.clientReducer.originalEmployeesCount
})

export default connect(mapStateToProps)(ContainerEmployeeSearch)