import React, { Component } from 'react'
import { SearchBarGenericReports } from '../SearchBarGeneric'

class ContainerSearchReports extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchBarState: "",
      popUpFilterState: "",
      popUpOrderState: "",
    };
  }
  render() {
    return (
      
      <SearchBarGenericReports textPlaceHolder={"Buscar"} searchBarState={this.searchBarState} popUpOrderState={this.popUpOrderState}
      popUpFilterState={this.popUpFilterState} showAdvancedSearch={this.props.showAdvancedSearch} buttonType= {'reports'} 
      text={'Generar reporte'} customWidthDiv2={this.props.customWidthDiv2}/>

    )
  }
}

export default ContainerSearchReports
