import React from 'react'
import {connect} from 'react-redux'
import './main.css'
import {actionSearchBar, inactiveViewMix, activeViewMix} from '../../actions/filterDetailActions'
import {ReactComponent as Close} from '../../assets/icons/write/close.svg'
import PropTypes from "prop-types";


class SearchBarDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchValue: this.props.searchBar[0],
      placeholder: props.textPlaceHolder
    }
    this.focus = false
  }

  handleOnChange = () => e => {
    this.setState({searchValue: e.target.value})
    this.setState({[e.target.id]: e.target.value})
    this.focus = false
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.actionSearchBar(this.state.searchValue)
    this.props.activeViewMix()
  }

  clearInput = () => {
    this.setState({searchValue: ''})
    this.props.actionSearchBar('', 'initialState')
    this.props.inactiveViewMix()
  }

  inputSpace = () => {
    if (this.state.searchValue !== this.props.searchBar[0]) {
      if (this.focus === false) {
        this.props.actionSearchBar(this.state.searchValue)
      }
    }
  }

  changeFocus = () => {
    this.focus = true
  }


  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="search-content">
          <input
            value={this.state.searchValue}
            onChange={this.handleOnChange()}
            onBlur={this.state.searchValue === '' && this.props.searchBar[0] === this.state.searchValue ? () => 
            this.setState({placeholder: 
              !this.state.placeholder ? 
              'Número de identificación del destinatario o asunto' : 
              this.props.textPlaceHolder
            })
            : this.inputSpace
          }
            onFocus={()=> this.setState({placeholder: this.props.textPlaceHolder})}
            placeholder={(!this.state.placeholder) ? '           Número de identificación del destinatario o asunto' : '           ' + this.state.placeholder}
            className='inputSearch'
            type='text'
            id='searchValue'
            autoComplete='off'
            maxLength="100"
            style={{textOverflow:'ellipsis'}}
          />
          <Close
            className={(this.state.searchValue === "" || this.state.searchValue === undefined) ? 'iconInput iconClose close' : 'iconInput iconClose'}
            onClick={this.clearInput}
            onMouseEnter={this.changeFocus}
          />
        </div>
      </form>
    )
  }
}

SearchBarDetail.propTypes = {
  actionSearchBar: PropTypes.func,
  inactiveViewMix: PropTypes.func,
  activeViewMix: PropTypes.func,
}

const mapStateToProps = (state) => ({
  searchBar: state.filterDetailReducer.searchBar
})

const mapDispatchToProps = {
  actionSearchBar,
  inactiveViewMix,
  activeViewMix
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBarDetail)