import React from 'react'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import SimpleReactValidator from 'simple-react-validator'

import CheckboxForm from '../CheckboxForm/CheckboxForm'
import { reactValidatorOptions } from '../../helpers/simpleReactValidator'
import { ReactComponent as Pdf } from '../../assets/icons/pdf.svg'
import { changeAttachedDocSign, changeWriteNotificationForm, changeHeaderCheckbox } from '../../actions/writerActions'
import { CloseSvgIcon } from "../IconSVG/CloseSvgIcon";
import _ from "lodash";
import PropTypes from "prop-types";
import '../CheckboxForm/signature-checkbox.css'
import { Progress } from 'semantic-ui-react'

const CloseIcon = styled(CloseSvgIcon)`
  width: 100%;
  height: 100%;
  color: #133F4F;
  padding-top: 3px;
  padding-left: 3px;
`
const CloseButton = styled.div`
  float: right;
  cursor: pointer;
  display: flex;
  align-items: center;
  visibility: hidden;
  width: 20px;
  height: 20px;

  @media screen and (max-width:1500px){
    visibility:visible;
}
`

const Divider = styled.div`
  background: #FFFFFF;
  border: 1px solid #B2B2B2;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 10px 12px;
  margin-bottom: 5px;

  &:hover ${CloseButton} {
    visibility: visible;
  }

  width: 100%;
  ${props => props.fileheight &&
    css`
            height: ${props.fileheight};
          `}
  height: 100%;
`
const Divider2 = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
`
const DividerSvg = styled.div`
  float: left;
  width: 15px;
  height: 15px;
`

const Text = styled.div`
  float: left;
  max-width: 15vw;
  width: 60%;
  color: #133F4F;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  padding: 0 0 1% 2%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 100%;
`
const TextMuted = styled.div`
  float: left;
  max-width: 15vw;
  width: 60%;
  color: #899FA7;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  padding: 0 0 1% 2%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 100%;
`
const Text2 = styled.div`
  float: left;
  height: 100%;
  width: 50%
  font-size: 14px;
  padding-left: 2%;
  overflow: hidden;
  color:#C3132E;
`
const ProgressBar = styled(Progress)`
  width: 20%;
  height: 3px;
  border-radius: 0px !important;
  margin: 0 0 0.5rem !important;
  & > .bar {
    background-color: #009BDD !important;
  }
`

class ChargingFileCard extends React.Component {

  constructor(props) {
    super(props)
    this.validator = new SimpleReactValidator(reactValidatorOptions)
  }

  componentDidMount() {
    this.loadNotes(this.props.data.size)
  }

  componentDidUpdate(prevProps, prevState) {
  }

  state = {
    progress: 0,
  }

  fileHeight = (listSize) => (`${(100 / (listSize))}%`)


  removeCustomFile = async (indexInfo) => {
    const realFiles = Object.keys(this.props.files).map((row) => {
      return this.props.files[row]
    })
    realFiles.splice(indexInfo, 1)
    let disable = false
    for (const file of realFiles) {
      if (file.sign) {
        disable = true
      }
    }
    if (this.props.checkboxHeaderDisabled !== disable)
      await this.props.changeHeaderCheckbox(disable)
    const descriptionFile = realFiles.map((file) => {
      return {
        base64: file.base64,
        type: file.type,
        name: file.name,
        sign: file.sign,
        size: file.size,
        id: file.id
      }
    }
    )
    const filesObject = _.keyBy(descriptionFile, 'id')
    await this.props.changeWriteNotificationForm('chargingFiles', filesObject)
  }

  loadNotes = async (size) => {
    var progress;
    for (let i = 20; i <= 100; i = i + 20) {
      await new Promise((resolve, reject) => {
        setTimeout(function () { resolve(); }, size * 0.005);
      });
      if (this.props.filesLoaded) {
        i = 100
      }
      progress = this.state.progress;
      progress = i;
      this.setState({ progress: progress })
    }
  }


  render() {
    return (
      <Divider
        fileheight={this.fileHeight(Object.keys(this.props.files).length)}
      >
        <Divider2>
          <DividerSvg>
            <Pdf width='16px' height='16px' />
          </DividerSvg>
          {!this.props.errorLoadingFiles ? <TextMuted>{this.props.data.name}</TextMuted> : <Text>{this.props.data.name}</Text>}
          {!this.props.errorLoadingFiles && <ProgressBar percent={this.state.progress} active size='tiny' />}
          {
              this.props.errorLoadingFiles  &&
              <Text2>Error al cargar este documento</Text2>
          }

        </Divider2>
        {this.state.progress === 100 && this.props.errorLoadingFiles &&
          <CloseButton
            onClick={() => this.props.deleteChargingFile(this.props.index)}
          >
            <CloseIcon />
          </CloseButton>
        }
      </Divider>
    )
  }
}

ChargingFileCard.propTypes = {
  checkboxHeaderDisabled: PropTypes.bool,
  changeHeaderCheckbox: PropTypes.func,
  updateForm: PropTypes.func,
  changeWriteNotificationForm: PropTypes.func,
  servicesList: PropTypes.object,
}

const mapStateToProps = state => ({
  checkboxHeaderDisabled: state.writerReducer.checkboxHeaderDisabled,
  servicesList: state.writerReducer.hiredData.servicesList,
  errors: state.errorReducer
})

const mapDispatchToProps = {
  updateForm: changeAttachedDocSign,
  changeWriteNotificationForm: changeWriteNotificationForm,
  changeHeaderCheckbox: changeHeaderCheckbox
}


export default connect(mapStateToProps, mapDispatchToProps)(ChargingFileCard)
