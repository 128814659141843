import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { actionInitialPagination, activePagination } from '../../../../../actions/filterDetailActions'
import { detailAttachmentsV2, viewPDF, updateList, removeFromList, deleteAllFromList } from '../../../../../actions/seeAttachmentAction'
import RowTableAttachmentDetail from './RowTableAttachmentDetail'
import GeneralTable from '../../../../../components/Table/GeneralTable'


class AllAttachmentDetailList extends Component {

  state = {
    loader: true,
    columnDataList: [
      { name: "", width: 1, arrow: false },
      { name: "", width: 1, arrow: false },
      { name: "Nombre documento", width: 5, arrow: false },
      { name: "Fecha de envío", width: 3, arrow: false },
      { name: "", width: 3, arrow: false },
      { name: "", width: 2, arrow: false },
      { name: "", width: 2, arrow: false },
    ],
    columnDataGrid: [
      { name: "Documentos", width: 16, arrow: false },
    ],
    init: true,
  }

  componentDidMount() {
    this.makeDetailAttachmentsRequest(this.props.filterReducer)
    this.props.viewPDF({ "viewPDF": false })
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.filterReducer, this.props.filterReducer) && !this.state.init
    ) {

      this.makeDetailAttachmentsRequest(this.props.filterReducer)
      this.setState({ init: true, loader: true })
    }

    if (prevProps.stateNotificationStarred !== this.props.stateNotificationStarred) {
      if(!!this.props.filterReducer.starred){
        this.makeDetailAttachmentsRequest(this.props.filterReducer)
      }
    }
  }

  makeDetailAttachmentsRequest = (filterReducer) => {
    this.props.deleteAllFromList()
    const body = {
      count: filterReducer.count,
      pag: filterReducer.pag,
      limit: 20,
      sort: this.orderTranslator(filterReducer.sort),
      status: this.validateStatus(filterReducer.searchAdvanced.notificationState),
      initDate: !!filterReducer.searchAdvanced.date.startDate
        ? moment(filterReducer.searchAdvanced.date.startDate).format('YYYY-MM-DD') : '',
      endDate: !!filterReducer.searchAdvanced.date.endDate
        ? moment(filterReducer.searchAdvanced.date.endDate).format('YYYY-MM-DD') : '',
      starred: filterReducer.starred,
      attachmentsName: filterReducer.searchBar[0],
      to: this.props.match.params.attachmentID,
    }

    if (filterReducer.searchAdvanced.notificationState === 'REQUIRED_SIGNATURE' || filterReducer.requiredSignature  === 'REQUIRED_SIGNATURE' ) {
      body.requiredSignature = true;
    }

    if (filterReducer.searchAdvanced.notificationState === 'NO_REQUIRED_SIGNATURE' || filterReducer.requiredSignature  === 'NO_REQUIRED_SIGNATURE') {
      body.requiredSignature = false;
    }

    if (body.initDate === "") {
      delete body.initDate
      delete body.endDate
    }

    this.props.detailAttachmentsV2(body)
      .then(() => {
        this.props.activePagination(false)
        this.setState({ init: false, loader: false })
      })
  }

  validateStatus = (status) =>{
    return status === 'REQUIRED_SIGNATURE'  || status === 'NO_REQUIRED_SIGNATURE' ? '' : status
  }

  orderTranslator = (order) => {
    const options = {
      'masReciente': { "_id": -1 },
      'masAntiguo': { "_id": 1 },
      'A-Z': { "fileOriginalName": 1 },
      'Z-A': { "fileOriginalName": -1 }
    }
    return options[order]
  }

  addCheckedRow = (id) => {
    this.props.updateList(id)
  }

  removeCheckedRow = (id) => {
    this.props.removeFromList(id)
  }

  render() {
    return (

      <GeneralTable
        list={this.props.listAttachments}
        typeList="listAttachments"
        listHeight={'listAttachments'}
        headerTable={(this.props.typeIcon === 1) ? this.state.columnDataList : this.state.columnDataGrid}
        rowComponent={RowTableAttachmentDetail}
        history={this.props.history}
        loader={this.state.loader}
        addCheckedRow={this.addCheckedRow}
        removeCheckedRow={this.removeCheckedRow}
        activeAll={this.props.activeAll} 
        to={this.props.match.params.attachmentID}/>
    )
  }
}

AllAttachmentDetailList.propTypes = {
  history: PropTypes.any.isRequired,
  detailAttachmentsV2: PropTypes.func.isRequired,
  actionClearFilter: PropTypes.func,
  actionInitialPagination: PropTypes.func,
  listAttachments: PropTypes.array,
  countAttachments: PropTypes.number,
  documentCurrent: PropTypes.string,
  userData: PropTypes.any,
  typeIcon: PropTypes.number,
  filterReducer: PropTypes.object.isRequired,
  stateUserDocumentStarred: PropTypes.object,
}

const mapStateToProps = (state) => ({
  listAttachments: state.userDocumentReducer.detailAttachment,
  countAttachments: state.userDocumentReducer.countAttachments,
  documentCurrent: state.userDocumentReducer.documentCurrent,
  viewPDF: state.userDocumentReducer.viewPDF,
  userData: state.userDocumentReducer.userData,
  typeIcon: state.userDocumentReducer.typeIcon,
  filterReducer: state.filterDetailReducer,
  stateNotificationStarred: state.starredReducer.stateNotificationStarred,
})

const mapDispatchToProps = {
  detailAttachmentsV2,
  actionInitialPagination,
  viewPDF,
  activePagination,
  removeFromList,
  updateList,
  deleteAllFromList
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AllAttachmentDetailList))