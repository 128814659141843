import React from 'react'
import { Checkbox } from 'semantic-ui-react'
import styled from 'styled-components'

const CustomCheckbox = styled(Checkbox)`
  & label, & + label {
    text-align: left !important;
    letter-spacing: 0 !important;
    color: #000000 !important;
    opacity: 1 !important;
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 18px !important;
    height: 18px !important;
    max-width: 18px !important;
    max-height: 18px !important;
  }
  input:checked ~ .box:after, input:checked ~ label:after {
    color: #FFFFFF !important;
    background: #22C1D0 0 0 no-repeat padding-box !important;
    box-shadow: 0 2px 4px #0000001A !important;
    border-radius: 2px !important;
    border: 1px solid #22C1D0 !important;
    width: 19px;
    height: 19px;
    content: ${({ checkedAll }) => (checkedAll ? "'\\e800'" : "'\\e801'")} !important;
    padding-top: 1px;
  }
  & label:before {
    border: 2px solid #133F4F !important;
    border-radius: 2px !important;
    width: 18px !important;
    height: 18px !important;
    max-width: 18px !important;
    max-height: 18px !important;
  }

  input:checked~label:before {
    background: #fff;
    border-color: white !important;
  }
  input[disabled] ~ label{
    opacity: 0.2 !important;
  }
  .ui.checkbox .box:after, .ui.checkbox label:after {
    size: 12px
    }
@media only screen and (max-width: 768px) {
    & label:before {
        max-width: 18px !important;
        max-height: 18px !important;
    }
}
    padding: 2px 0 0 0 !important;
`
export const CheckBoxGenericMinusIcon = ({ onChange, checked, checkedAll=false }) => {
    return (
        <CustomCheckbox onChange={onChange} checked={checked} checkedAll={checkedAll}  />
    )
}
