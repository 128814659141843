import React, { Component } from 'react'
import Paginacion from '../../Paginacion'
import SearchBar from '../../SearchBar'
import styled from 'styled-components'
import PopUpOrder from '../../PopUpOrder'
import ButtonNewEmployee from '../Buttons/ButtonNewEmployee'
import { connect } from 'react-redux'
import { SearchBarDetailGenericButton } from '../../SearchBarGeneric'

const Divider = styled.div`
`
class ContainerEmployeeSearch extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchBarState: "",
            popUpOrderState: "",
        };
    }

    render() {
        return (
            <Divider>
                <SearchBarDetailGenericButton buttonType={'employee'} text={'Nuevo usuario'} showButton={true} disabledButton={this.props.originalEmployeesCount !== 0? true:false }
                textPlaceHolder={'Buscar'} popUpOrderState={this.popUpOrderState} searchBarState={this.searchBarState}
                showFilters={false} paddingLeft={'20px'} shortPaddingTop={true} filterPosition={this.props.filterPosition}/>
            </Divider>
        )
    }
}
const mapStateToProps = (state) => ({
    role: state.authReducer.user.role,
    employees: state.clientReducer.employees,
    originalEmployeesCount: state.clientReducer.originalEmployeesCount
})
export default connect(mapStateToProps)(ContainerEmployeeSearch)