import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { ReactComponent as AllNotifications } from '../../assets/icons/sent/all.svg'
import { ReactComponent as ReadConfirmation } from '../../assets/icons/sent/readConfirmation.svg'
import { ReactComponent as NotificationSignature } from '../../assets/icons/sent/notificationSignature.svg'
import { ReactComponent as DocSignature } from '../../assets/icons/sent/docSignature.svg'
import { ReactComponent as PushIcon } from '../../assets/icons/sent/iconPushNotification.svg'

const TabIcon = styled.div`
  padding-right: 10px;
  @media only screen and (max-width: 1080px) {
    padding-right: 0px;
  }
`
const Divider = styled.div`
width:100%;
height: 100%;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
@media only screen and (max-width: 1080px) {
  flex-direction: column;
  justify-content: center;
}

&:hover{
  background: #0000000A;
}
`
const Text = styled.div`
display: flex;
font-weight: 700;
@media only screen and (max-width: 1080px) {
  font-weight: 500;
  text-align: center;
  font-size: 14px;
}
`

const MenuItemTab = (props) => {
  const getImage = (icon) => {
    const icons = {
      'all': <AllNotifications />,
      'readConfirmation': <ReadConfirmation />,
      'notificationSignature': <NotificationSignature />,
      'docSignature': <DocSignature />,
      'push': <PushIcon />,
      'default': ''
    }
    return !icons[icon] ? icons['default'] : icons[icon]
  }
  return (
      <Divider>
        <TabIcon>
          {getImage(props.icon)}
        </TabIcon>
        <Text>{props.title}</Text>
      </Divider>
  )
}

MenuItemTab.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default MenuItemTab
