import React, { useState, useEffect } from 'react'
import { reactValidatorOptions } from '../../../helpers/simpleReactValidator'
import { Form } from 'semantic-ui-react'
import InputFormClient from '../InputAnimated/InputFormClient'
import InputFormDropdown from '../InputAnimated/InputFormDropdown'
import { activeFormClient, changeFormEmployee, getEmployeeEdit, putEditEmployee } from '../../../actions/clientActions'

import styled from 'styled-components'
import '../index.css'
import { useDispatch, useSelector } from 'react-redux'
import useValidator from '../../../helpers/simpleReactValidatorHook'
import { warningFormModal, refreshStateModule } from '../../../actions/modalActions'
import { useHistory } from 'react-router-dom'
import InputSwitch from '../InputAnimated/InputSwitch'

const Divider = styled.div`
  padding-left: 160px;
  padding-right: 160px;
`
const CustomForm = styled(Form)`
  display: flex !important;
  flex-direction: column !important;
  width: 50%;
  margin: auto;
`
const Divider2 = styled.div`
    display: flex;
    margin-top: 15px;
    justify-content: flex-end;
`
const ButtoCancel = styled.button`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px; 
  color: #22C1D0;
  text-align: center;
  width: 128px;
  height: 48px;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  background: #FFFFFF;
  margin-right: 8px;
  cursor: pointer;
`
const ButtoSave = styled.button`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px; 
  color: #FFFFFF;
  text-align: center;
  width: 128px;
  height: 48px;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  background: #22C1D0;
  margin-left: 8px;
`
const optionsDocumentType = [
  { key: '', text: '', value: '' },
  { key: 'C.C', text: 'Cédula de Ciudadania', value: 'C.C' },
]

const FormEditEmployee = (props) => {

  const history = useHistory()
  const dispatch = useDispatch()
  const { employeeActiveEdit } = useSelector(state => state.clientReducer)
  const { stateFormEdit } = useSelector(state => state.clientReducer)
  const { activeForm } = useSelector(state => state.clientReducer)
  const { refreshModule } = useSelector(state => state.modalReducer)
  const { warningModal } = useSelector(state => state.modalReducer)

  const { documentType, documentNumber, firstName, lastName, email, roleID, id, status } = employeeActiveEdit
  const [errors] = useState({})
  const [validator, showValidationMessage] = useValidator(reactValidatorOptions)

  useEffect(() => {
    if (activeForm === false && warningModal.status === true) {
      dispatch(activeFormClient())
    }

    if (refreshModule === true) {
      history.goBack()
      dispatch(refreshStateModule())
    }

  }, [activeForm, warningModal, refreshModule, history, dispatch])

  useEffect(() => {
    const idUser = sessionStorage.getItem("userSerialId")
    dispatch(getEmployeeEdit(idUser))
  }, [dispatch])

  const handleOnChange = (event, { value }) => {
    const name = event.currentTarget.parentNode.parentNode.id
    dispatch(changeFormEmployee([name], value))
  }

  const onChange = e => {
    const name = e.target.id
    dispatch(changeFormEmployee([name], e.target.value))
  }

  const handleCancel = () => {
    dispatch(warningFormModal({ type: 'warningLeaveUpdate' }))
  }

  const handleChange = (checked) => {
    if (checked === true) {
      dispatch(changeFormEmployee('status', 'ACTIVE'))
    } else {
      dispatch(changeFormEmployee('status', 'INACTIVE'))
    }
  }

  const makeSaveNotificationReq = () => {

    const body = {
      "firstName": firstName,
      "lastName": lastName,
      "email": email,
      "role": roleID,
      "status": status
    }
    dispatch(putEditEmployee(body, id))
  }

  const handleSend = (e) => {
    e.preventDefault()
    if (validator.allValid()) {
      makeSaveNotificationReq()
    } else {
      showValidationMessage(true);
    }
  }

  return (
    <Divider>
      <CustomForm>
        <InputFormDropdown
          options={optionsDocumentType}
          placeholder={'Tipo de documento'}
          value={documentType}
          handleOnChange={handleOnChange}
          id={'documentType'}
          validator={validator}
          error={errors}
          disabled={true}
        />
        <br />
        <InputFormClient
          id={'documentNumber'}
          label={'No. documento'}
          onChange={onChange}
          value={documentNumber}
          validator={validator}
          validateOptions={['numeric', { min: 4 }, { max: 15 }]}
          error={errors}
          disabled={true}
        />
        <InputFormClient
          id={'firstName'}
          label={'Nombres'}
          type={'text'}
          onChange={onChange}
          value={firstName}
          validator={validator}
          validateOptions={['nameOfCompany', { min: 1 }, { max: 50 }]}
          error={errors}
        />
        <InputFormClient
          id={'lastName'}
          label={'Apellidos'}
          type={'text'}
          onChange={onChange}
          value={lastName}
          validator={validator}
          validateOptions={['nameOfCompany', { min: 1 }, { max: 50 }]}
          error={errors}
        />
        <InputFormClient
          id={'email'}
          label={'Email'}
          type={'text'}
          onChange={onChange}
          value={email}
          validator={validator}
          validateOptions={['email', { min: 6 }, { max: 200 }]}
          error={errors}
        />
        <InputFormDropdown
          options={props.role}
          placeholder={'Rol'}
          value={roleID}
          handleOnChange={handleOnChange}
          id={'rol'}
          validator={validator}
          error={errors}
        />
        <InputSwitch
          checked={status === 'ACTIVE' ? true : false}
          onChange={handleChange}
        />
        <Divider2>
          <ButtoCancel
            onClick={handleCancel}
          >Cancelar
          </ButtoCancel>
          <ButtoSave
            disabled={stateFormEdit}
            className='putButton'
            onClick={handleSend}
          >Actualizar
          </ButtoSave>
        </Divider2>
      </CustomForm>
    </Divider >
  )
}

export default FormEditEmployee



