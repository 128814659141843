import React from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Modal } from 'semantic-ui-react'
import {closePDFModal } from '../../actions/modalActions'
import './PDF.css'
import HeaderPDF from './HeaderPDF'


const ModalForPDF = styled(Modal)`
margin-top:0 !important;
width:100%;
`
const ContentPdf = styled.iframe`
width:70%;
 height:90vh;
 @media only screen and (max-width: 1450px)   {
    width: 70% !important;
  }
  @media only screen and (max-width: 1280px)   {
    width: 90% !important;
  }
  @media only screen and (max-width: 600px)   {
    width: 98% !important;
  }
`

export const PDFViewer = () => {

    const dispatch = useDispatch()
    const { viewPDF, base64 } = useSelector(state => state.userDocumentReducer.dataViewPdf)
    const handleClick = () => {
        dispatch(closePDFModal())
    }
    return (
        <>
            <ModalForPDF
                open={viewPDF}
                onClose={handleClick}
                className="pdf-modal"
            >
                <HeaderPDF/>
                <ModalForPDF.Description  >
                    <center >
                        <ContentPdf className={'pdfView'} src={!!base64 ?`${base64}#view=FitH&toolbar=0&navpanes=0&scrollbar=0"`:'about:blank'} frameborder="0" width="" height="940px" />
                    </center>
                </ModalForPDF.Description>
            </ModalForPDF>
        </>
    )
}