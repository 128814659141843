
import store from "../../store/store";
import { getRefreshToken } from "../authActions";

const { dispatch, getState } = store;

export const axiosConfig = {
    retries: 3,
    retryDelay: (retryCount) => {
        return retryCount * 2000;
    },
    retryCondition: (error) => {
        return error.response && error.response.status === 401 && !!sessionStorage.getItem("refreshToken");
    },
    onRetry: () => {
        const refreshToken = sessionStorage.getItem("refreshToken")
        if (refreshToken) {
            dispatch(
                getRefreshToken({
                    id: getState().authReducer.user.id,
                    refreshToken: refreshToken
                })
            )
        }
    }
}