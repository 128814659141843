import {
  GET_PROVIDERS,
  SAVE_PROVIDER,
  CHANGE_STATE_PROVIDER,
  INACTIVE_STATE_PROVIDER_FORM,
  EDIT_PROVIDER,
  GET_PROVIDER_EDIT,
  DETAIL_PROVIDER,
  CHANGE_EDIT_PROVIDER,
  ACTIVE_FORM_EDIT_PROVIDER,
  INACTIVE_FORM_EDIT_PROVIDER,
  CODE_COMPANY_TO_EDIT
} from '../actions/actionsUtilities/types.js'

const initialState = {
  providers: [],
  savedProvider: [],
  detailProvider: {
    contactInformation: []
  },
  providerActiveEdit: {},
  providerPut: [],
  activeForm: false,
  stateFormEditProvider: true,
  elementsCount: 0,
  codeCompanyToEdit: ''
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PROVIDERS:
      return {
        ...state,
        providers: action.payload.providers,
        elementsCount: action.payload.elementsCount,
      }
    case SAVE_PROVIDER:
      return {
        ...state,
        savedProvider: action.payload
      }
    case CHANGE_STATE_PROVIDER:
      return {
        ...state,
        activeForm: true,
      }
    case INACTIVE_STATE_PROVIDER_FORM:
      return {
        ...state,
        activeForm: false,
      }
    case DETAIL_PROVIDER:
      return {
        ...state,
        detailProvider: action.payload,
      }
    case GET_PROVIDER_EDIT:
      return {
        ...state,
        providerActiveEdit: { ...action.payload }
      }
    case CHANGE_EDIT_PROVIDER:
      return {
        ...state,
        providerActiveEdit: {
          ...state.providerActiveEdit,
          [action.payload.key]: action.payload.value
        }
      }
    case ACTIVE_FORM_EDIT_PROVIDER:
      return {
        ...state,
        stateFormEditProvider: false,
      }
    case INACTIVE_FORM_EDIT_PROVIDER:
      return {
        ...state,
        stateFormEditProvider: true,
      }
    case EDIT_PROVIDER:
      return {
        ...state,
        providerPut: action.payload,
      }
    case CODE_COMPANY_TO_EDIT:
      return {
        ...state,
        codeCompanyToEdit: action.payload,
      }
    default:
      return state
  }
}
