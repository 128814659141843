import {
  GET_NOTIFICATIONS,
  DETAIL_OF_NOTIFICATION,
  CHANGE_CHECKBOK_REPORT,
  DOWNLOAD_REPORT,
  LOGOUT,
  DETAIL_OF_DATA_PDF,
  DETAIL_OF_NOTIFICATION_MASSIVE,
  DOWNLOAD_MASSIVE_REPORT,
  CLEAR_REPORT_MASSIVE_DATA,
  ON_LOADING_DOWNLOAD_REPORT,
  HAVE_ATTACHMENTS_MASSIVE,
  UPDATE_LIST_TO_DOWNLOAD,
  DELETE_FROM_LIST_TO_DOWNLOAD,
  ADD_ALL_TO_LIST_TO_DOWNLOAD,
  DELETE_ALL_FROM_LIST_TO_DOWNLOAD,
  SET_DOWNLOAD_ATTACHMENTS_LINK,
  CLEAR_DETAIL_NOTIFICATION_DATA,
  BLOCK_CHANGE_TAP,
} from '../actions/actionsUtilities/types.js'

const initialState = {
  notifications: [],
  detailNotification: {},
  detailMassiveNotification: {
    haveAttachments: false,
    notifications: [],
    statusCount: []
  },
  reportData: {},
  dataViewPdf: {},
  elementsCount: 0,
  citizenReportData: {},
  addPrint: false,
  listToDownload: [],
  attachmentsDownloadLink: "",
  blockChangeTap: ''
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT:
      return initialState
    case GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload.notifications,
        elementsCount: action.payload.elementsCount
      }
    case DETAIL_OF_NOTIFICATION:
      return {
        ...state,
        detailNotification: action.payload
      }
    case DETAIL_OF_NOTIFICATION_MASSIVE:
      return {
        ...state,
        detailMassiveNotification: {
          ...state.detailMassiveNotification, ...action.payload
        }
      }
    case CHANGE_CHECKBOK_REPORT:
      return {
        ...state,
        addPrint: action.payload.value
      }
    case DOWNLOAD_REPORT:
      return {
        ...state,
        reportData: action.payload
      }
    case ON_LOADING_DOWNLOAD_REPORT:
      return {
        ...state,
        reportData: {
          ...state.reportData,
          loading: true
        }
      }
    case DETAIL_OF_DATA_PDF:
      return {
        ...state,
        dataViewPdf: action.payload
      }
    case DOWNLOAD_MASSIVE_REPORT:
      return {
        ...state,
        citizenReportData: action.payload
      }
    case CLEAR_REPORT_MASSIVE_DATA:
      return {
        ...state,
        citizenReportData: initialState.citizenReportData,
        detailMassiveNotification: initialState.detailMassiveNotification
      }
    case CLEAR_DETAIL_NOTIFICATION_DATA:
      return {
        ...state,
        detailNotification: initialState.detailNotification
      }
    case HAVE_ATTACHMENTS_MASSIVE:
      return {
        ...state,
        detailMassiveNotification: {
          ...state.detailMassiveNotification,
          haveAttachments: action.payload
        }
      }
    case UPDATE_LIST_TO_DOWNLOAD:
      return {
        ...state,
        listToDownload: [...state.listToDownload, action.payload]
      }
    case DELETE_FROM_LIST_TO_DOWNLOAD:
      return {
        ...state,
        listToDownload: state.listToDownload.filter(item => item !== action.payload)
      }
    case ADD_ALL_TO_LIST_TO_DOWNLOAD:
      return {
        ...state,
        listToDownload: state.detailMassiveNotification.notifications.map(item => item.notificationID)
      }
    case DELETE_ALL_FROM_LIST_TO_DOWNLOAD:
      return {
        ...state,
        listToDownload: []
      }
    case SET_DOWNLOAD_ATTACHMENTS_LINK:
      return {
        ...state,
        attachmentsDownloadLink: action.payload
      }
      case BLOCK_CHANGE_TAP:
        return {
          ...state,
          blockChangeTap: action.payload,
        }
        
    default:
      return state
  }
}
