import React from 'react'
import styled, {css} from 'styled-components'
import PropTypes from "prop-types";
import {connect} from "react-redux";

const CustomDiv = styled.div`
  width:98%;
  margin: auto;
  padding: 10px 28px 10px 28px;
  height: auto;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  display: none;
  justify-content: center;
  align-items: center;
  border: 1px solid #BA3555;
  border-radius: 4px;
  background: rgba(186, 53, 85, 0.1);
  color: #BA3555;
  ${props => props.visibility === 'visible' &&
  css`
    visibility: ${props.visibility};
    display:flex;
  `}
`

const ErrorMessageModalSendMassive = (props) => {
    return (
        <CustomDiv visibility={props.errorSendMassiveFile}>
          {props.messageSendMassiveFile}
                
        </CustomDiv>
    )
}

ErrorMessageModalSendMassive.propTypes = {
  errorSendMassiveFile: PropTypes.string,
}

const mapStateToProps = (state) => ({
  errorSendMassiveFile: state.modalReducer.sendMassiveModal.showErrorSendMassiveFile,
  messageSendMassiveFile: state.modalReducer.sendMassiveModal.messageErrorSendMassiveFile
})

export default connect(mapStateToProps, null)(ErrorMessageModalSendMassive)
