import React, { useEffect, useState } from 'react'
import { Grid, Dimmer, Loader } from 'semantic-ui-react'
import { ReactComponent as EditIcon } from '../../assets/icons/clients/EditIcon.svg'

import styled from 'styled-components'
import './index.css'
import { useDispatch, useSelector } from 'react-redux'
import { getClientDetail } from '../../actions/clientActions'
import { getProviderDetail } from '../../actions/providerActions'
import { useHistory } from 'react-router-dom'

const Container = styled.div`
  background: #FFFFFF;
  border: 1px solid #B2B2B2;
  border-radius: 4px;
  padding: 24px;
  margin: 16px;
`
const Title = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #3C3C3B;
  margin: 0px;

  @media only screen and (max-width: 1366px) and (min-width: 1025px)  {
  font-size: 12px;
}

`
const Title2 = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  color: #3C3C3B;
  margin: 0px;
  margin-left: 3px;

  @media only screen and (max-width: 1366px) and (min-width: 1025px)  {
  font-size: 12px;
}
`
const Title3 = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #133F4F;
  margin: 0px;

  @media only screen and (max-width: 1366px) and (min-width: 1025px)  {
  font-size: 16px;
}

`
const Divider = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding:0;
`
const DividerTitle = styled.div`
  display: flex;
  margin-botom: 54px;
`
const Box = styled.div`
  gap: 0;
`

const BoxFiles = styled.div`
  gap: 0;
  max-height: 53px !important;
  overflow-y: auto;
  overflow-x:hidden;
  ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;   
  -ms-hyphens: auto;          
  -moz-hyphens: auto;         
  -webkit-hyphens: auto;
  hyphens: auto;
`
const ButtonEdit = styled.button`
  background: #FFFFFF;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  padding: 8px 8px;
  cursor: pointer;

  @media only screen and (max-width: 1366px) and (min-width: 1025px)  {
    svg{
    width: 100 !important;
    height: 16;
    fill: none;
}
}
`
const TextTitleElement = styled.div`
  font-family: Roboto;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 10px;
  margin-left: 16px;
  margin-top: 30px;
`
const StyleDateAgreements = styled.text`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  padding-left:5px;
  color: #22C1D0;
  text-decoration-line: underline;
  cursor: pointer;
  
`
const StyledGridColumn = styled(Grid.Column)`
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow-wrap: anywhere;
`;




export const DetailClientYProvider = () => {


  const dispatch = useDispatch()
  const { detailClient } = useSelector(state => state.clientReducer)
  const { detailProvider } = useSelector(state => state.providerReducer)
  const { role } = useSelector(state => state.authReducer.user)
  const history = useHistory()
  const ruta = history.location.pathname
  const [loader, setLoader] = useState(true)

  useEffect(() => {
    const idClients = sessionStorage.getItem("id")
    if (ruta === `/clients-detail/${idClients}`) {
      dispatch(getClientDetail(idClients)).then(() => setLoader(false))
    } else {
      dispatch(getProviderDetail(idClients)).then(() => setLoader(false))
    }
  }, [dispatch, ruta])




  const handleEditProvider = () => {
    history.push('/editProviders')
  }

  const handleEditClient = () => {
    history.push('/editClients')
  }

  return (
    <Dimmer.Dimmable active={loader.toString()}>
      <Dimmer active={loader} inverted>
        <Loader size='medium'>Cargando...</Loader>
      </Dimmer>
      <Container>
        {
          ruta === `/clients-detail/${detailClient.companySerialID}`
            ? <>
              <Grid >
                <Grid.Column computer={18} tablet={18}>
                  <Grid stackable stretched>
                    <StyledGridColumn computer={5} tablet={6} >
                    <Box>
                      <Divider><Title>Código cliente:</Title><Title2>{detailClient.codeCompany}</Title2></Divider>
                      <Divider><Title>Tipo Empresa:</Title><Title2>{detailClient.definition === 'PRIVATE' ? 'Privada' : 'Pública'}</Title2></Divider>
                      <Divider><Title>Tipo persona:</Title><Title2>Jurídica</Title2></Divider>
                      <Divider><Title>Tipo documento:</Title><Title2>{detailClient.typeDocumentCompany}</Title2></Divider>
                      <Divider><Title>Documento:</Title><Title2>{detailClient.numberDocumentCompany}-{detailClient.DV}</Title2></Divider>
                      <Divider><Title>Teléfono:</Title><Title2>{!!detailClient.phone?detailClient.phone:'Dato no ingresado'}</Title2></Divider>
                    </Box>
                    </StyledGridColumn>
                    <StyledGridColumn computer={5} tablet={6} >
                    <Box>
                      <Divider><Title>Email:</Title><Title2>{detailClient.email}</Title2></Divider>
                      <Divider><Title>Dominio:</Title><Title2>{!!detailClient.domain?detailClient.domain:'Dato no ingresado'}</Title2></Divider>
                      <Divider><Title>Dirección:</Title><Title2>{!!detailClient.address?detailClient.address:'Dato no ingresado'}</Title2></Divider>
                      <Divider><Title>País:</Title><Title2>{detailClient.countryName}</Title2></Divider>
                      <Divider><Title>Departamento:</Title><Title2>{detailClient.departmentName}</Title2></Divider>
                    </Box>
                    </StyledGridColumn>
                    <StyledGridColumn computer={6} tablet={6}>
                    <Box>
                      <Divider><Title>Ciudad:</Title><Title2>{detailClient.cityName}</Title2></Divider>
                      <Divider><Title>Representante legal:</Title><Title2>{detailClient.legalManagerName} {detailClient.legalManagerLastName}</Title2></Divider>
                      <Divider><Title>Documento Repres. Legal:</Title><Title2>{detailClient.typeDocumentLegalManager} {detailClient.numberDocumentLegalManager}</Title2></Divider>
                      <Divider><Title>Habilitación de Empresa:</Title><Title2>{detailClient.status === 'ENABLE' ? 'Activa' : 'Inactiva'}</Title2></Divider>
                      <Divider><Title>{"Soporte de autorización: "}</Title>
                      </Divider>
                      </Box>
                      <BoxFiles>

                        {detailClient.supportFiles?.map((supportFile, i) =>
                          <>
                            <a href={supportFile.base64} download={supportFile.originalFileName} > <StyleDateAgreements key={supportFile.fileName}>{supportFile.originalFileName}</StyleDateAgreements></a>
                            {detailClient.supportFiles[i + 1] === undefined ? '' : <b key={i + 'supportFile'}>{' '}/</b>}
                          </>
                        )}
                      </BoxFiles>
                      <Divider><Title>Detalle soporte de autorización:</Title><Title2>{detailClient.supportDescription }</Title2></Divider>
                    </StyledGridColumn>



                  </Grid>
                </Grid.Column>

              </Grid>
              <Grid>
                  <Grid.Column computer={10} tablet={16}>
                    <Grid stackable stretched>
                        {detailClient.contactInformation?.map((person, index) => {
                        return (
                          <StyledGridColumn computer={8} tablet={16}>
                            <Divider><Title3>Datos contacto {index + 1}</Title3></Divider>
                            <Divider><Title>Nombres:</Title><Title2>{person.name}</Title2></Divider>
                            <Divider><Title>Apellidos:</Title><Title2>{person.surNames}</Title2></Divider>
                            <Divider><Title>Email:</Title><Title2>{person.email}</Title2></Divider>
                            <Divider><Title>Teléfono:</Title><Title2>{person.phoneContact}</Title2></Divider>
                            <Divider><Title>Cargo:</Title><Title2>{person.occupation}</Title2></Divider>

                          </StyledGridColumn>
                        )
                      })}
                    </Grid>
                  </Grid.Column>
              </Grid>
                {/* COLUMNA DEL BOTON */}
                <Grid.Column computer={16} tablet={16} >
                <div style={{display:'flex', height:'100%',alignItems:'end', justifyContent:'end'}}> 
                  {
                    role === '61b36e2fc34e22351bf8cfb4' && !detailClient.hasAgreementAsociate &&

                    <ButtonEdit onClick={handleEditClient} >
                      <EditIcon />
                    </ButtonEdit>
                  }
                   </div>
                </Grid.Column>
            </>
            : <>
              <Grid>
                <Grid.Column computer={18} tablet={18}>
                  <Grid stackable stretched>
                    <StyledGridColumn computer={5} tablet={6} >
                    <Box>
                      <Divider><Title>Código operador:</Title><Title2>{detailProvider.codeCompany}</Title2></Divider>
                      <Divider><Title>Tipo Empresa:</Title><Title2>{detailProvider.definition === 'PRIVATE' ? 'Privada' : 'Pública'}</Title2></Divider>
                      <Divider><Title>Tipo persona:</Title><Title2>Jurídica</Title2></Divider>
                      <Divider><Title>Tipo documento:</Title><Title2>{detailProvider.typeDocumentCompany}</Title2></Divider>
                      <Divider><Title>Documento:</Title><Title2>{detailProvider.numberDocumentCompany}-{detailProvider.DV}</Title2></Divider>
                      <Divider><Title>Teléfono:</Title><Title2>{!!detailProvider.phone?detailProvider.phone:'Dato no ingresado'}</Title2></Divider>
                    </Box>
                    </StyledGridColumn>
                    <StyledGridColumn computer={5} tablet={6}>
                    <Box>
                      <Divider><Title>Email:</Title><Title2>{detailProvider.email}</Title2></Divider>
                      <Divider><Title>Dominio:</Title><Title2>{!!detailProvider.domain?detailProvider.domain:'Dato no ingresado'}</Title2></Divider>
                      <Divider><Title>Dirección:</Title><Title2>{!!detailProvider.address?detailProvider.address:'Dato no ingresado'}</Title2></Divider>
                      <Divider><Title>País:</Title><Title2>{detailProvider.countryName}</Title2></Divider>
                      <Divider><Title>Departamento:</Title><Title2>{detailProvider.departmentName}</Title2></Divider>
                      <Divider><Title>Ciudad:</Title><Title2>{detailProvider.cityName}</Title2></Divider>
                    </Box>
                    </StyledGridColumn>
                    <StyledGridColumn computer={6} tablet={6}>
                    <Box>
                      <Divider><Title>Representante legal:</Title><Title2>{detailProvider.legalManagerName} {detailProvider.legalManagerLastName}</Title2></Divider>
                      <Divider><Title>Documento Repres. Legal:</Title><Title2>{detailProvider.typeDocumentLegalManager} {detailProvider.numberDocumentLegalManager}</Title2></Divider>
                      <Divider><Title>Habilitación de Empresa:</Title><Title2>{detailProvider.status === 'ENABLE' ? 'Activa' : 'Inactiva'}</Title2></Divider>
                      <Divider><Title>{"Soporte de autorización: "}</Title>
                      </Divider>
                      </Box>
                      <BoxFiles>

                        {detailProvider.supportFiles?.map((supportFile, i) =>
                          <>
                            <a href={supportFile.base64} download={supportFile.originalFileName} > <StyleDateAgreements key={supportFile.fileName}>{supportFile.originalFileName}</StyleDateAgreements></a>
                            {detailProvider.supportFiles[i + 1] === undefined ? '' : <b key={i + 'supportFile'}>{' '}/</b>}
                          </>
                        )}
                        </BoxFiles>
                        <Divider><Title>Detalle soporte de autorización:</Title><Title2>{detailProvider.supportDescription }</Title2></Divider>
                    </StyledGridColumn>
                  </Grid>
                </Grid.Column>

              </Grid>
              <Grid>
                <Grid.Column computer={10} tablet={16}>
                  <Grid stackable stretched>


                    {
                      detailProvider.contactInformation?.map((person, index) => {
                        return (

                          <StyledGridColumn computer={8} tablet={16}>
                            <DividerTitle><Title3>Datos contacto {index + 1}</Title3></DividerTitle>
                            <Divider><Title>Nombres:</Title><Title2>{person.name}</Title2></Divider>
                            <Divider><Title>Apellidos:</Title><Title2>{person.surNames}</Title2></Divider>
                            <Divider><Title>Email:</Title><Title2>{person.email}</Title2></Divider>
                            <Divider><Title>Teléfono:</Title><Title2>{person.phoneContact}</Title2></Divider>
                            <Divider><Title>Cargo:</Title><Title2>{person.occupation}</Title2></Divider>

                          </StyledGridColumn>
                        )
                      })


                    }
                    {/* COLUMNA DEL BOTON */}
                  </Grid>
                </Grid.Column>
                    <Grid.Column computer={16} tablet={16}  >
                      <div style={{display:'flex', height:'100%',alignItems:'end', justifyContent:'end'}}>
                      {!detailProvider.hasAgreementAsociate &&
                        <ButtonEdit onClick={handleEditProvider} >
                          <EditIcon />
                        </ButtonEdit>
                      }
                      </div>
                    </Grid.Column>

              </Grid>
            </>
        }
      </Container>
    </Dimmer.Dimmable>
  )
}
