import React, { useState, useEffect } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import '../main.css'
import {actionSearchBar, inactiveViewMix, activeViewMix} from '../../../actions/filterActions'
import {ReactComponent as Close} from '../../../assets/icons/write/close.svg'
import PropTypes from "prop-types";


const SearchBarReports = (props) => {

  const dispatch = useDispatch()
  const {searchBar} = useSelector(state => state.filterReducer)
  const [searchValue, setSearchValue] = useState(searchBar)
  const [placeholder, setPlaceholder]= useState(props.textPlaceHolder)
  const [focus, setFocus]= useState(false)

  const handleOnChange = () => e => {
    setSearchValue(e.target.value)
    setFocus(false)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(actionSearchBar(searchValue))
    dispatch(activeViewMix())
  }

  const clearInput = () => {
    setSearchValue("")
    dispatch(actionSearchBar('', 'initialState'))
    dispatch(inactiveViewMix())
  }

  const inputSpace = () => {
    if (searchValue !== searchBar[0]) {
      if (focus === false) {
        dispatch(actionSearchBar(searchValue))
      }
    }
  }

  const changeFocus = () => {
    setFocus(true)
  }

  useEffect(() => {
    if(searchBar === ""){
      setSearchValue("")

    }
  }, [searchBar])


  return (
    <form onSubmit={handleSubmit}>
      <div className="search-content">
        <input
          value={searchValue}
          onChange={handleOnChange()}
          onBlur={searchValue === '' ? () => 
          setPlaceholder( 
            !placeholder ? 
            'Nombre del destinatario o asunto' : 
            props.textPlaceHolder
          )
          : inputSpace
        }
          onFocus={()=> setPlaceholder(props.textPlaceHolder)}
          placeholder={(!placeholder) ? '           Nombre del destinatario o asunto' : '           ' + placeholder}
          className='inputSearchReports'
          type='text'
          id='searchValue'
          autoComplete='off'
          maxLength="100"
        />
        <Close
          className={(searchValue === "") ? 'iconInput iconClose close' : 'iconInput iconClose'}
          onClick={clearInput}
          onMouseEnter={changeFocus}
        />
      </div>
    </form>
  )
}

SearchBarReports.propTypes = {
  actionSearchBar: PropTypes.func,
  inactiveViewMix: PropTypes.func,
  activeViewMix: PropTypes.func,
}

export default SearchBarReports