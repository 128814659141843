//CONSTANTES
const VERSION1 = '/v1'
const VERSION2 = '/v2'
const VERSION3 = '/v3'
const VERSION5 = '/v5'

const PROVIDERS_ROUTE = '/companies'
const AUTH_ROUTE = '/authentication'
const CLIENTS_ROUTE = '/companies'
const NOTIFICATION_ROUTE = '/notification'
const ATTACHMENTS_ROUTE = '/attachments'
const USER_ROUTES = '/user'
const FILE_ROUTES = '/file'
const DRAFTS_ROUTES = '/draft'
const SDH_ROUTE = '/sdh'
const REPORT_ROUTE = '/report'
const CSV_ROUTE = '/csv'
const PDF_ROUTE = '/pdf'


//Agreements 
export const AGREEMENT_LIST_ROUTE_V2 = PROVIDERS_ROUTE+VERSION2+'/agreements/list'
export const GET_PROVIDER_LIST_ROUTE = PROVIDERS_ROUTE+VERSION1+'/providers/'
export const GET_PROVIDER_LIST_ROUTE_V2 = PROVIDERS_ROUTE+VERSION2+'/providers/'
export const GET_CLIENT_ROUTE = PROVIDERS_ROUTE+VERSION1+'/clients/'
export const GET_CLIENT_ROUTEV2 = PROVIDERS_ROUTE+VERSION2+'/clients/'
export const GET_AGREEMENT_DETAIL_ROUTE = PROVIDERS_ROUTE+VERSION2+'/agreements/'
export const POST_CREATE_AGREEMENT = PROVIDERS_ROUTE+VERSION1+'/agreements/' //
export const POST_CREATE_AGREEMENT_V2 = PROVIDERS_ROUTE+VERSION2+'/agreements/' 
export const PUT_AGREEMENT_DETAIL_ROUTE_V2 = PROVIDERS_ROUTE+VERSION2+'/agreements/' 
export const PUT_AGREEMENT_DETAIL_ROUTE = PROVIDERS_ROUTE+VERSION2+'/agreements/'
export const GET_LIST_CLIENTS = PROVIDERS_ROUTE+VERSION2+'/list/total/client'
export const GET_LIST_PROVIDERS = PROVIDERS_ROUTE+VERSION1+'/list/total/provider'
export const GET_LIST_PROVIDERS_V2 = PROVIDERS_ROUTE+VERSION2+'/list/total/provider'
export const CANCELE_AGREEMENT_ROUTE = CLIENTS_ROUTE+VERSION2+'/agreements/reason-inactive-agreement'
export const GET_CLIENT_AGREEMENT = GET_CLIENT_ROUTE+'agreements/'
export const GET_CLIENT_AGREEMENT_V2 = GET_CLIENT_ROUTEV2+'agreements/'
export const LOGS_LIST_AGREEMENTS = PROVIDERS_ROUTE+VERSION2+'/agreements/list-logs'

//AUTH 
export const LOGIN_USER_ROUTE = AUTH_ROUTE+VERSION1+'/login'
export const LOGIN_USER_ROUTEV2 = AUTH_ROUTE+VERSION5+'/login'
export const LOGIN_POST_CERTIFICATE_ROUTE = AUTH_ROUTE+VERSION1+'/user/certificates/validate'
export const LOGOUT_USER_ROUTE = AUTH_ROUTE+VERSION1+'/user/certificates/validate'
export const RECOVER_PASSWORD = AUTH_ROUTE+VERSION1+'/user/reset-password'
export const CHECK_LOGIN_STATUS = AUTH_ROUTE+VERSION1+'/check-login-status'
export const VERIFY_CAPTCHA = AUTH_ROUTE+VERSION1+'/verify-captcha'
export const VERIFY_CAPTCHA_V2 = AUTH_ROUTE+VERSION1+'/verify3'
export const LOGIN_LIST_CERTIFICATE = AUTH_ROUTE+VERSION1+'/list-certificate'
export const LOGIN_LIST_CERTIFICATE_V2 = AUTH_ROUTE+VERSION2+'/list-certificate'
export const LOGIN_VALIDATE_CERTIFICATE = AUTH_ROUTE+VERSION1+'/validateCertCredentials'
export const LOGIN_VALIDATE_CERTIFICATE_V2 = AUTH_ROUTE+VERSION2+'/validateCertCredentials'
export const LOGIN_GET_QR = AUTH_ROUTE+VERSION1+'/qr/generate'
export const AUTENTICATION_WITH_QR = AUTH_ROUTE+VERSION2+'/validateOTP'
export const SELECT_ROL = AUTH_ROUTE+VERSION2+'/select-role'
export const CHANGE_ROL = AUTH_ROUTE+VERSION2+'/change-role'
export const RESET_QR = AUTH_ROUTE+VERSION1+'/qr/reset?userSerialID='
export const REFRESH_TOKEN = AUTH_ROUTE+VERSION1+'/refresh-token'
export const REFRESH_TOKEN_V3 = AUTH_ROUTE+VERSION3+'/refresh-token'

//CLIENTS
export const GET_CLIENTS_ROUTE = CLIENTS_ROUTE+VERSION2+'/clients/'
export const POST_CREATE_ENTITIES_ROUTE = CLIENTS_ROUTE+VERSION2+ '/clients/add-client'
export const PUT_CLIENTS_ROUTE = CLIENTS_ROUTE+VERSION2+'/clients/'
export const PUT_STATE_CLIENT_ROUTE = CLIENTS_ROUTE+VERSION2+'/clients/inactive/'
export const TRACKING_LOGS_ROUTE = CLIENTS_ROUTE+VERSION2+'/list/list-logs'
export const GET_EMPLOYEES_ROUTE = AUTH_ROUTE+VERSION2+'/user/list-users-company-client'
export const POST_CREATE_EMPLOYEE_ROUTE = AUTH_ROUTE+VERSION3+'/user/store-employee'
export const GET_EMPLOYEE_ROUTE = AUTH_ROUTE+VERSION2+'/user/'
export const PUT_EMPLOYEE_ROUTE = AUTH_ROUTE+VERSION2+'/user/'
export const GET_ROLES_CLIENT_ROUTE = AUTH_ROUTE+VERSION2+'/role/client'

// Dashboard actions
export const GET_DASHBOARD_INFO_ROUTE = PROVIDERS_ROUTE+VERSION1+'/agreements/agreement-dashboard'
export const GET_DASHBOARD_INFO_ROUTE_V2 = PROVIDERS_ROUTE+VERSION2+'/agreements/agreement-dashboard'
export const GET_DASHBOARD_LIST_AGREEMENTS = PROVIDERS_ROUTE+VERSION2+'/agreements/list-agreement'
export const DASHBOARD_LIST = NOTIFICATION_ROUTE+VERSION1+'dashboard-list'
//Default list actions
export const GET_GEOGRAPHIC_LIST_ROUTE = AUTH_ROUTE+VERSION1+'/list/geographic/'
export const GET_GEOGRAPHIC_LIST_ROUTE_V2 = AUTH_ROUTE+VERSION2+'/list/geographic/'

//Drafts
export const GET_DRAFT_ROUTE = NOTIFICATION_ROUTE+VERSION1+DRAFTS_ROUTES
export const GET_DRAFT_ROUTE_V2 = NOTIFICATION_ROUTE+VERSION2+DRAFTS_ROUTES
export const GET_DRAFTS_LIST_ROUTE = NOTIFICATION_ROUTE+VERSION1+DRAFTS_ROUTES+ '/find-draft'
export const GET_DRAFTS_LIST_ROUTE_V2 = NOTIFICATION_ROUTE+VERSION2+DRAFTS_ROUTES+ '/find-draft'
export const DELTE_DRAFT_ROUTE = NOTIFICATION_ROUTE+VERSION1+DRAFTS_ROUTES+ '/delete-draft'
export const DELTE_DRAFT_LIST_ROUTE = NOTIFICATION_ROUTE+VERSION1+DRAFTS_ROUTES+ '/delete-list-draft'
export const DELTE_DRAFT_LIST_ROUTE_V2 = NOTIFICATION_ROUTE+VERSION2+DRAFTS_ROUTES+ '/delete-list-draft'
export const SAVE_DRAFT = NOTIFICATION_ROUTE+VERSION1+DRAFTS_ROUTES+'/create-draft'
export const SAVE_DRAFT_V2 = NOTIFICATION_ROUTE+VERSION2+DRAFTS_ROUTES+'/create-draft'


//Modal actions
export const MODAL_MASIVE = NOTIFICATION_ROUTE+VERSION1+'/file/validate-file-massive'
export const PROCESS_MASIVE = NOTIFICATION_ROUTE+VERSION1+'/file/process-file-massive'
export const RECORDS_FILE_MASIVE = NOTIFICATION_ROUTE+VERSION1+'/file/records-file-massive'
export const RECORDS_FILE_MASIVE_V2 = NOTIFICATION_ROUTE+VERSION2+'/file/records-file-massive'
export const MODAL_MASIVE_V2 = NOTIFICATION_ROUTE+VERSION2+'/file/validate-file-massive'

//Provider actions
export const POST_CREATE_PROVIDER_ROUTE = PROVIDERS_ROUTE+VERSION2+'/providers/add-provider'
export const GET_PROVIDER_DETAIL_ROUTE = PROVIDERS_ROUTE+VERSION1+'/providers/'
export const GET_PROVIDER_DETAIL_ROUTE_V2 = PROVIDERS_ROUTE+VERSION2+'/providers/'
export const PUT_PROVIDER_ROUTE_V2 = PROVIDERS_ROUTE+VERSION2+'/providers/'
export const PUT_STATE_PROVIDER_ROUTE = PROVIDERS_ROUTE+VERSION2+'/providers/inactive/'
export const GET_ROLES_PROVIDER_ROUTE =  AUTH_ROUTE+VERSION2+'/role/provider'

//SeeAttacments actions
export const GET_DETAIL_OF_ATTACHMENT_V2 = NOTIFICATION_ROUTE+VERSION2+ATTACHMENTS_ROUTE+'/get-attachments'
export const GET_PDF = NOTIFICATION_ROUTE+VERSION2+ATTACHMENTS_ROUTE+'/get-base64Attachment'

//SeeNotification actions
export const GET_DETAIL_OF_NOTIFICATION = NOTIFICATION_ROUTE+VERSION1+'/find-one'
export const GET_DETAIL_OF_NOTIFICATION_V2 = NOTIFICATION_ROUTE+VERSION2+'/find-one'
export const GET_DETAIL_OF_MASSIVE_NOTIFICATION = NOTIFICATION_ROUTE+VERSION3+'/detail-massive'
export const DOWNLOAD_REPORT_OF_EVENTS_V2 = REPORT_ROUTE+PDF_ROUTE+VERSION2+'/record?'
export const DOWNLOAD_CITIZEN_REPORT_MASSIVE = NOTIFICATION_ROUTE+VERSION1+'/file/find-report-file-massive'
export const DOWNLOAD_CITIZEN_REPORT_MASSIVE_V2 = NOTIFICATION_ROUTE+VERSION2+'/file/find-report-file-massive'
export const DOWNLOAD_MASSIVE_ATTACHMENTS = NOTIFICATION_ROUTE+VERSION1+'/get-attachments-massive'
export const DOWNLOAD_MASSIVE_ATTACHMENTS_V2 = NOTIFICATION_ROUTE+VERSION2+'/get-attachments-massive'

//Sent actions
export const GET_LIST_NOTIFICATIONS = NOTIFICATION_ROUTE+VERSION1+'/find-list'
export const GET_LIST_NOTIFICATIONSV2 = NOTIFICATION_ROUTE+VERSION2+'/find-list'

//Starred notifications actions
export const STARRED_NOTIFICATION = NOTIFICATION_ROUTE+VERSION1+'/change-starred'
export const STARRED_NOTIFICATION_V2 = NOTIFICATION_ROUTE+VERSION2+'/change-starred' 

//UserDocuments actions
export const GET_LIST_FILE_AND_ATTACHMENTS = NOTIFICATION_ROUTE+VERSION1+ATTACHMENTS_ROUTE+'/get-users-attachments'
export const GET_LIST_FILE_AND_ATTACHMENTS_V2 = NOTIFICATION_ROUTE+VERSION2+ATTACHMENTS_ROUTE+'/get-users-attachments'
export const STARRE_DOCUMENT = NOTIFICATION_ROUTE+VERSION2+ATTACHMENTS_ROUTE+ '/star-attachment'
export const EXPORT_USER_DOCUMENTS = NOTIFICATION_ROUTE+VERSION1+ '/get-attachments-user'

//User actions
export const GET_LIST_USERS =  AUTH_ROUTE+VERSION2+USER_ROUTES+'/list-users'
export const GET_ONE_USER =  AUTH_ROUTE+VERSION2+USER_ROUTES+'/find-user'
export const GET_ROLES_USER_ROUTE = AUTH_ROUTE+VERSION2+'/role/client'
export const GET_ROLES_USER_ROUTE_PROVIDER = AUTH_ROUTE+VERSION2+'/role/provider'
export const POST_CREATE_USERS_ROUTE = AUTH_ROUTE+VERSION2+USER_ROUTES+'/store-employee'
export const PUT_EDIT_USERS_ROUTE = AUTH_ROUTE+VERSION1+USER_ROUTES+'/'
export const PUT_EDIT_USERS_ROUTE_V2 = AUTH_ROUTE+VERSION3+USER_ROUTES+'/'
export const GET_CERTIFICATE_USER = AUTH_ROUTE+VERSION2+USER_ROUTES+'/pre-register-user'
export const CREATE_USERS_V3 = AUTH_ROUTE+VERSION3+USER_ROUTES+'/store-employee'
export const GET_CERTIFICATE_USER_V3 = AUTH_ROUTE+VERSION3+USER_ROUTES+'/pre-register-user'

//Writer actions
export const SEND_NOTIFICATION = NOTIFICATION_ROUTE+VERSION2+'/send'
export const GET_FIND_RECIPIENT= NOTIFICATION_ROUTE+VERSION1+'/find-recipient'
export const GET_FIND_RECIPIENT_V2= NOTIFICATION_ROUTE+VERSION2+'/find-recipient'
export const GET_NOTIFICATIONS_AVAILABLE_FOR_USER_V2 = CLIENTS_ROUTE+VERSION2+'/agreements/services/hired'
export const SEND_NOTIFICATION_V2 = NOTIFICATION_ROUTE+VERSION3+'/send-one'
export const SEND_NOTIFICATION_MULTIPLE = NOTIFICATION_ROUTE+VERSION3+'/send-multiple'
export const SEND_NOTIFICATION_MASSIVE_V2 = NOTIFICATION_ROUTE+VERSION2+'/send-massiveFile'
export const SEND_NOTIFICATION_MASSIVE_V3 = NOTIFICATION_ROUTE+VERSION1+'/file/send-massive'
export const SEND_NOTIFICATION_MASSIVE_V4 = NOTIFICATION_ROUTE+VERSION2+'/file/send-massive'
export const GET_ATTRIBUTE_LIST_ROUTE = NOTIFICATION_ROUTE+'/v1/file/list-tag'
export const SEND_FILE_MASSIVE = NOTIFICATION_ROUTE+'/v1/file/process-file-massive'
export const GET_NOTIFICATIONS_PROGRESS_V2 = NOTIFICATION_ROUTE+VERSION2+FILE_ROUTES+'/progress-massiveFile'
export const DELETE_MASSIVE_NOTIFICATION_RECORD_V2 = NOTIFICATION_ROUTE+VERSION2+'/file/set-visibility-massiveFile'
export const GET_NOTIFICATIONS_PROGRESS = NOTIFICATION_ROUTE+VERSION1+FILE_ROUTES+'/progress-massiveFile'
export const DELETE_MASSIVE_NOTIFICATION_RECORD = NOTIFICATION_ROUTE+VERSION1+'/file/set-visibility-massiveFile'
export const SEND_FILE_MASSIVE_V2 = NOTIFICATION_ROUTE+VERSION2+'/file/process-file-massive'
export const GET_ATTRIBUTE_LIST_ROUTE_V2 = NOTIFICATION_ROUTE+VERSION2+'/file/list-tag'
export const DOWNLOAD_USERS_GUIDE = FILE_ROUTES+VERSION1+'/generalFiles/handbook/notifier'

//Reports
export const GET_REPORTS = REPORT_ROUTE+CSV_ROUTE+VERSION2+'/list-report'
export const CANCEL_MASSIVE_SEND = NOTIFICATION_ROUTE+VERSION2+'/canceled-send-massiveFile'
export const CANCEL_MASSIVE_SEND_V3 = NOTIFICATION_ROUTE+VERSION3+'/canceled-send-massiveFile'
export const POST_CREATE_WRONG_REPORT_ROUTE = NOTIFICATION_ROUTE+VERSION2+'/report/csv-error'
export const GET_REPORT_DETAIL_ROUTE = REPORT_ROUTE+CSV_ROUTE+VERSION2+'/find-report-specific'
export const GET_REPORT_DETAIL_ROUTE_V2 = NOTIFICATION_ROUTE+VERSION2+'/report/find-status-report'
export const GENERATE_REPORT = REPORT_ROUTE+CSV_ROUTE+VERSION2+'/create'
export const GET_CLIENT_REPORT_SEARCH = CLIENTS_ROUTE+VERSION2+'/list/total/client?textToSearch='
export const REPORT_CREATE = REPORT_ROUTE+CSV_ROUTE+VERSION2+'/create'
export const GET_BASE64_REPORT = REPORT_ROUTE+CSV_ROUTE+VERSION2+'/getBase64csv'
export const CREATE_EXPORT_PDF = REPORT_ROUTE+PDF_ROUTE+VERSION2+'/agreement?agreementID='
export const GET_TO_BE_NOTIFIED = REPORT_ROUTE+CSV_ROUTE+VERSION1+'/toBeNotified'

//encryption 
export const CYPHER = SDH_ROUTE+VERSION3+'/send/'
export const DECYPHER = SDH_ROUTE+VERSION3+'/receive/'

//improper Clients

export const GET_IMPROPER_CLIENTS_LIST = NOTIFICATION_ROUTE+VERSION2+'/wrong/clients-wrong-use'
export const GET_IMPROPER_CLIENT_DETAIL = NOTIFICATION_ROUTE+VERSION2+'/wrong/list-use-improper-notifications'
export const COMMENT_IMPROPER_NOTIFICATION = NOTIFICATION_ROUTE+VERSION2+'/review-comment'
