import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { ReactComponent as LeftArrowIcon } from '../../../assets/icons/sent/leftArrow.svg'
import FormCreateClients from '../../../components/FormClientsAndProviders/FormCreateClients'
import { warningFormModal } from '../../../actions/modalActions'
import MessageModal from '../../../components/Modal/MessageModal'
import WarningModal from '../../../components/FormClientsAndProviders/Modal/WarningModal'
import { useHistory } from 'react-router-dom'


const Divider = styled.div`
  width: 100%;
  height: 89.3vh;
  overflow-y: scroll;

  ::-webkit-scrollbar-track {
    background: #CCCCCC;
  }

  ::-webkit-scrollbar-thumb {
    background: #9D9D9C !important;
  }

  ::-webkit-scrollbar:vertical {
    width: 5px;
  }
`
const Container = styled.div`
  margin: 30px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  @media only screen and (max-width: 1200px) {
    margin: 10px;
  }
`
const Arrow = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
`
const TitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
`
const Title = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #133F4F;

`
const LeftArrow = styled.button`
  padding: 0;
  margin: 0 16px 0 0;
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor: pointer;
`

const CreateClients = () => {

  const dispatch = useDispatch()
  const { activeForm } = useSelector(state => state.clientReducer)
  const history = useHistory()

  const handleClick = () => {
    if (activeForm === true) {
      dispatch(warningFormModal({ type: 'warningLeaveClient' }))
    } else {
      history.push('/clients')
    }
  }

  return (
    <Divider>
      <Container>
        <Arrow>
          <LeftArrow
            onClick={handleClick}
          >
            <LeftArrowIcon />
            <WarningModal />
          </LeftArrow>
        </Arrow>
        <TitleContainer>
          <Title>Nuevo cliente</Title>
        </TitleContainer>
      </Container>
      <FormCreateClients />
     
    </Divider>
  )
};

export default CreateClients
