
import axios from "axios"
import { API_ENDPOINT } from "../config/config.js"
import {
  ACTIVE_PAGINATION,
  AUDITOR_COMMENT_NOTIFICATION,
  CHANGE_STATE_MESSAGE_MODAL,
  CHANGE_STATE_REFRESH_MODULE,
  GET_IMPROPER_CLIENTS,
  IMPROPER_CLIENT_DETAIL,
  VIEW_PAGINATION,
} from "./actionsUtilities/types.js"
import { reasonDispatch } from "./DispatchGeneric.js"
import { COMMENT_IMPROPER_NOTIFICATION, GET_IMPROPER_CLIENTS_LIST, GET_IMPROPER_CLIENT_DETAIL } from "./actionsUtilities/actionsRoutes.js"
import { decrypt, encrypt } from '../helpers/cypher'
import { getHeaders } from "../helpers/globalHelpers.js"

export const createCommentAuditor = (body) => async (dispatch) => {
  const encryptedBody = await encrypt(body);
  await axios.post(
    `${API_ENDPOINT + COMMENT_IMPROPER_NOTIFICATION}`,
    { 'data': encryptedBody },
    { headers: getHeaders() }
  ).then(async (res) => {
    const decryptedData = await decrypt(res.data.result);
    console.log(decryptedData);
    dispatch({
      type: CHANGE_STATE_MESSAGE_MODAL,
      payload: { type: 'finishCommentAuditor' }
    })
    setTimeout(() => {
      dispatch({
        type: CHANGE_STATE_MESSAGE_MODAL,
        payload: { type: 'finishCommentAuditor' }
      })

      dispatch({
        type: CHANGE_STATE_REFRESH_MODULE,
        payload: true
      })
    }, 2000)

  })

}

export const getImproperClientsList = (body) => async (dispatch) => {
  const encryptedBody = await encrypt(body);
  await axios.post(
    `${API_ENDPOINT + GET_IMPROPER_CLIENTS_LIST}`, { data: encryptedBody }, { headers: getHeaders() }
  ).then(async (res) => {
    const decryptedData = await decrypt(res.data.result);
    dispatch({
      type: GET_IMPROPER_CLIENTS,
      payload: { improperClients: decryptedData.companies, elementsCount: decryptedData.elementsCountPage }
    })
    dispatch({
      type: VIEW_PAGINATION,
      payload: {
        total: decryptedData.count,
      }
    })


  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
  });
}

export const getImproperClientDetail = (body, stateInitial) => async (dispatch) => {
  const encryptedBody = await encrypt(body);
  await axios.post(
    `${API_ENDPOINT + GET_IMPROPER_CLIENT_DETAIL}`, { data: encryptedBody }, { headers: getHeaders() }
  ).then(async (res) => {
    const decryptedData = await decrypt(res.data.result);
    dispatch({
      type: IMPROPER_CLIENT_DETAIL,
      payload: { notifications: decryptedData.notifications ? decryptedData.notifications : [], elementsCount: decryptedData.elementsCountPage ? decryptedData.elementsCountPage : 0 }
    })
    dispatch({
      type: VIEW_PAGINATION,
      payload: {
        count: body.count,
        pag: !!stateInitial ? 1 : body.pag,
        total: decryptedData.count,
        stateInitial: stateInitial,
      }
    })


  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
  });
}

export const auditorComment = (payload) => (dispatch) =>{
  dispatch({
    type: AUDITOR_COMMENT_NOTIFICATION ,
    payload: payload
  })
}