import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { MainSegment } from '../../../../components/Sengment/MainSegment'
import Header from './Header'
import ContainerSearchImproperClients from '../../../../components/Search/SearchImproperClients/ContainerSearchImproperClients'
import AllImproperClientsDetail from './AllImproperClientsDetail/AllImproperClientsDetail'
import { getClientDetail } from '../../../../actions/clientActions'

const AuditorImproperClientsDetail = (props) => {

    const dispatch = useDispatch()
    const idClients = props.match.params.clientId
    const [loader, setloader] = useState(true)

    useEffect(() => {
        const companySerialID = sessionStorage.getItem('improperClientID')
        dispatch(getClientDetail(companySerialID)).then(() => {
            setloader(false)
        })
    }, [])


    return (
        <MainSegment>
            <Header
                history={props.history}
            />
            <ContainerSearchImproperClients showAdvancedSearch={true} showFilters={true} showOldestNewest={true} showAZ={false} showPopUpFilter={false}/>
            <AllImproperClientsDetail
                idClient={idClients}
                history={props.history}
            />
        </MainSegment>
    )
}

AuditorImproperClientsDetail.propTypes = {
}

export default AuditorImproperClientsDetail 