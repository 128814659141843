import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Popup } from 'semantic-ui-react'
import { ReactComponent as Arrow } from '../../assets/icons/sent/arrowWhite.svg'
import { ReactComponent as PrintIcon } from '../../assets/icons/sent/print.svg'
import { ReactComponent as OptionAdvance } from '../../assets/icons/sent/optionAdvance.svg'
import { ReactComponent as Download } from '../../assets/icons/sent/downloadDocument.svg'
import { AiOutlineMore } from "react-icons/ai"
import { AiOutlineVerticalAlignBottom } from "react-icons/ai"
import ButtonIcon from '../Button/IconButton'
import { changeMessageModal, closePDFModal } from '../../actions/modalActions'
import { viewPDF } from '../../actions/seeAttachmentAction'
import styled from 'styled-components'

const ContentHeader = styled.div`
top:0;
left:0;
right:0;
bottom:0;
width:100%;
height:50px;
z-index: 30;

`
const Divider = styled.div`
  padding:15px 10px;
  display: flex;
  flex-direction: row;
  z-index: 3;
`
const Divider2 = styled.div`
  padding:15px;
  padding-left:15px;
  z-index: 3;

`
const Divider3 = styled.div`
  cursor: pointer;
  padding:15px;
  padding-left:15px;
  z-index: 3;

`
const TextNamePDF = styled.p`
  font-family: 'Roboto';
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #FFFF;
  padding-left: 8px;
  z-index:3;
`
const PopupFilterOrder = styled(Popup)`
  top: -8vh !important;
  left: 14% !important;
  background: #fff !important;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25) !important;
  padding: 0 !important;
  width: 170px !important;
  border: none !important;
  &:before {
  display: none !important;
}
`
const ButtonOption = styled.button`
  color: #7C7C74;
  border: 0;
  background:  #fff;
  font-family: Roboto;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  width: 100%;
  border-radius: 4px;
  padding:8px
`

function HeaderPDF() {

  const dispatch = useDispatch()
  const pdfData = useSelector(state => state.userDocumentReducer.dataViewPdf)
  const pdfModal = pdfData.viewPDF
  const dataViewPdf = {
    fileURL: pdfData.urlPDF,
    documentName: pdfData.documentName,
    base64: pdfData.base64
  }

  const [show, setShow] = useState(false)

  const handleClick = () => {
    dispatch(viewPDF({ "viewPDF": false }))
  }

  const toggle = () => {
    setShow(!show)
  }

  const validateDownload = () => {
    if (dataViewPdf.base64 === undefined) {
      dispatch(closePDFModal())
    }
  }

  return (
    <ContentHeader style={{ display: !!pdfModal ? 'flex' : 'none', justifyContent: 'space-between' }}>
  
      <div style={{width: '75%'}}>
        {(pdfModal === true && dataViewPdf.length !== 0) &&
          <Divider>
            <Arrow onClick={() => { handleClick() }} />
            <TextNamePDF>{dataViewPdf.documentName}</TextNamePDF>
          </Divider>}
      </div>
      <div style={{ display: "flex" }}>
        {(pdfModal === true && dataViewPdf.length !== 0) &&
          <Divider3>
            <a href={dataViewPdf.base64} download={dataViewPdf.documentName} onClick={validateDownload}>
              <Download />
            </a>
          </Divider3>}
        {(pdfModal === true && dataViewPdf.length !== 0) &&
          <Divider2>
            <a href={dataViewPdf.fileURL} target="_blank" rel="noopener noreferrer" style={{ color: "#FFFFFF" }}>
              <PrintIcon />
            </a>
          </Divider2>}
      </div>
    </ContentHeader>
  )
}

export default HeaderPDF