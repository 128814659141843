import React, {useState} from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { Form} from 'semantic-ui-react'
import './attachment.css'
import InputFormArea from '../../../components/ImproperClientsNotificationDetail/InputAnimated/InputTextArea'
import PrimaryButton from '../../../components/PrimaryButton'
import { reactValidatorOptions } from '../../../helpers/simpleReactValidator'
import useValidator from '../../../helpers/simpleReactValidatorHook'
import {  warningFormModal } from '../../../actions/modalActions'
import { auditorComment } from '../../../actions/improperClientsActions'

const CustomForm = styled(Form)`
  font-family: 'Roboto';
  font-style: normal;
  width: 104%;
  display: flex;
  flex-wrap: wrap;

  @media only screen and (max-width: 1200px){
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}
`

const ContainerComments = styled.div`
  width: 100%;
`
const ContainerSubtitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #133F4F;
  margin: 30px 8px 10px 8px;
`
const CommentsContent = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #939393;
  margin: 0px 8px;
  padding-right: 20px;
`
const LabelContent = styled.div`
`

const TextAreaCountContent = styled.div`
  float: right;
  font-weight: 400;
  font-size: 14px;
  line-height:14.4px;
  color: #000000;
  margin: 10px 0px;
  cursor: default;
`

const ContainerButton = styled.div`
  width: 100%;
  margin: 10px 0px;
`
const CustomPrimaryButton = styled(PrimaryButton)`
  width: auto !important;
  float: right;
`

const CommentsUserAndAuditor = (props) => {

  const dispatch = useDispatch()
  const [value, setValue] = useState('')
  const [textAreaCount, setTextAreaCount] = useState(0)
  const [validator, showValidationMessage] = useValidator(reactValidatorOptions)
  const [errors] = useState({})
  const { detailNotification } = useSelector((state) => state.sentReducer)

  const onChange = e => {
    setTextAreaCount(e.target.value.length)
    setValue(e.target.value)
  }

  const handleSend = (e) => {
    e.preventDefault()
    if (validator.allValid()) {
        dispatch(warningFormModal({ type: 'warningSendCommentsAuditor', 
          } ))
          dispatch(auditorComment({notificationID: detailNotification.notificationID,comment: value}))
    } else {
        showValidationMessage(true)
    }
  }

  const comments= {
    value: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  }
 
  return (
    <CustomForm>
      <ContainerComments>
        <ContainerSubtitle>
          Comentarios del usuario
        </ContainerSubtitle>
        {detailNotification.currentStatus &&
        <CommentsContent>
          {detailNotification.currentStatus.observation !==null?detailNotification.currentStatus.observation: 'El usuario no incluyó comentarios.' }
        </CommentsContent>
        }
        
        
      </ContainerComments>
      <ContainerComments>
        <ContainerSubtitle>
          Comentarios del auditor
        </ContainerSubtitle>
        <LabelContent>
          <InputFormArea
            id={'tradeDetails'}
            type={'text'}
            placeholder={'(Min. 20 caracteres)'}
            onChange={onChange}
            value={value}
            validator={validator}
            validateOptions={['commentsUserAndAuditor']}
            error={errors}
          />
          <TextAreaCountContent>
            {textAreaCount}/1000
          </TextAreaCountContent>
        </LabelContent>
      </ContainerComments>
      <ContainerButton>
        <CustomPrimaryButton
          fluid={true}
          txtBtn='Enviar revisión'
          onPress={handleSend}
          />
      </ContainerButton>
    </CustomForm>
  )
}

export default CommentsUserAndAuditor