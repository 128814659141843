import React from 'react'
import styled from 'styled-components'

import BannerImage from '../../assets/icons/login/banner.svg'
import Butterflies from '../../assets/icons/login/butterflies.svg'
import { ReactComponent as Logo } from '../../assets/icons/login/logo.svg'

const Banner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${BannerImage});
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100vh;
  background-color: #F7F7F7;
  @media only screen and (max-width: 992px)  {
    background-size: initial;
  }
`
const MainSegment = styled.div`
  width: 448px;
  height: 500px;
  @media only screen and (max-width: 448px)  {
    width: 100%;
  }
`
const LogoRow = styled.div`
  width: 100%;
  height: 13%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 30px;
`
const Content = styled.div`
  width: 440px !important;
  height: auto;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background: #FFFFFF;

  @media only screen and (max-width: 992px)  {
    width: 87.47% !important;
    margin: auto;
  }
`
const ContentButterflies = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${Butterflies});
  background-repeat: no-repeat;
  background-position-x: 100%;
  padding: 50px;

  @media only screen and (max-width: 992px)  {
    padding: 50px 20px;
  }
`

export default (props) => {
  const {children} = props
  return (
    <Banner>
      <MainSegment>
        <LogoRow>
          <Logo/>
        </LogoRow>
        <Content>
          <ContentButterflies>
            {children}
          </ContentButterflies>
        </Content>
      </MainSegment>
    </Banner>
  )
}
