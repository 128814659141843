import React from 'react'
import {connect} from 'react-redux'
import styled, {css} from 'styled-components'
import SimpleReactValidator from 'simple-react-validator'

import CheckboxForm from '../CheckboxForm/CheckboxForm'
import {reactValidatorOptions} from '../../helpers/simpleReactValidator'
import {ReactComponent as Pdf} from '../../assets/icons/pdf.svg'
import {changeAttachedDocSignSpecific, changeWriteNotificationForm, changeHeaderCheckbox} from '../../actions/writerActions'
import {CloseSvgIcon} from "../IconSVG/CloseSvgIcon";
import _ from "lodash";
import PropTypes from "prop-types";
import '../CheckboxForm/signature-checkbox.css'

const CloseIcon = styled(CloseSvgIcon)`
  width: 100%;
  height: 100%;
  color: #133F4F;
  padding-top: 3px;
  padding-left: 3px;
`
const CloseButton = styled.div`
  float: right;
  cursor: pointer;
  display: flex;
  align-items: center;
  visibility: hidden;
  width: 20px;
  height: 20px;

  @media screen and (max-width:1500px){
    visibility:visible;
}
`

const Divider = styled.div`
  background: #FFFFFF;
  border: 1px solid #B2B2B2;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 10px 12px;
  margin-bottom: 5px;
  &:hover{
    box-shadow: rgba(0,0,0,0.35) 0px 0px 6px;
  }
  &:hover ${CloseButton} {
    visibility: visible;
  }

  width: 100%;
  ${props => props.fileheight &&
          css`
            height: ${props.fileheight};
          `}
  height: 100%;
`
const Divider2 = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`
const DividerSvg = styled.div`
  float: left;
  width: 15px;
  height: 15px;
`

const Text = styled.div`
  float: left;
  max-width: 15vw;
  width: max-content;
  color: #133F4F;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  padding: 0 0 1% 2%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 100%;
`
const TextSpace = styled.div `
  width: min-content;
  padding: 0px 5px;
`
const Text2 = styled.div`
  float: left;
  height: 100%;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #706F6F;
`
const TextLongName = styled.label`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #C3132E;
`

class SpecificFileCard extends React.Component {

  constructor(props) {
    super(props)
    this.validator = new SimpleReactValidator(reactValidatorOptions)
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(this.props.index, prevState.checkboxId))
      this.setState(() => ({checkboxId: this.props.index, [this.props.index]: this.props.data.isSignedFile}))
  }

  state = {
    checkboxId: this.props.index,
    [this.props.index]: this.props.data.isSignedFile,
  }

  fileHeight = (listSize) => (`${(100 / (listSize))}%`)

  toggle = async () => {
    this.setState((prevState) => ({[prevState.checkboxId]: !prevState[prevState.checkboxId]}), async () => {
      await this.props.updateForm(this.state.checkboxId, this.state[this.state.checkboxId])
      const realFiles = Object.keys(this.props.files).map((row) => {
        return this.props.files[row]
      })
      let disable = false
      for (const file of realFiles) {
        if (file.isSignedFile)
          disable = true
        
      }
      this.props.changeWriteNotificationForm("documentSignature", disable)
      if (this.props.checkboxHeaderDisabled !== disable)
      await this.props.changeHeaderCheckbox(disable)
    })
  }

  removeCustomFile = async (indexInfo) => {
    const realFiles = Object.keys(this.props.files).map((row) => {
      return this.props.files[row]
    })
    realFiles.splice(indexInfo, 1)
    let disable = false
    for (const file of realFiles) {
      if (file.isSignedFile) {
        disable = true
      }
    }
    if (this.props.checkboxHeaderDisabled !== disable)
      await this.props.changeHeaderCheckbox(disable)

    const descriptionFile = realFiles.map((file) => {
        return {
          base64: file.base64,
          type: file.type,
          name: file.name,
          isSignedFile: file.isSignedFile,
          size: file.size,
          id: file.id
        }
      }
    )
    const filesObject = _.keyBy(descriptionFile, 'id')
    await this.props.changeWriteNotificationForm('files', filesObject)

  }


  render() {
    return (
      <Divider
        fileheight={this.fileHeight(Object.keys(this.props.files).length)}
      >
        <Divider2>
          <DividerSvg>
            <Pdf width='16px' height='16px'/>
          </DividerSvg>
          <Text>{this.props.data.name}</Text>
          <TextSpace>-</TextSpace>
          <Text2>Adjunto desde carga masiva</Text2>
          {!!this.props.data.isLongName ?
            <TextLongName>Error al cargar, nombre del documento demasiado largo.</TextLongName>
          :
            <CheckboxForm
              classNameDiv='signature-checkbox-div'
              classNameCheck='signature-checkbox-check'
              label='Firma en documento'
              onChange={this.toggle}
              value={this.props.data.isSignedFile}
              validator={this.validator}
              validateOptions='required'
              id={this.state.checkboxId}
              maxWidth={'11vw'}
              width={'10%'}
              disabled= {(!this.props.servicesList.documentSignature)? 'disabled':''}
            />
          }
        </Divider2>
      </Divider>
    )
  }
}

SpecificFileCard.propTypes = {
  checkboxHeaderDisabled: PropTypes.bool,
  changeHeaderCheckbox: PropTypes.func,
  updateForm: PropTypes.func,
  changeWriteNotificationForm: PropTypes.func,
  servicesList: PropTypes.object,
}

const mapStateToProps = state => ({
  checkboxHeaderDisabled: state.writerReducer.checkboxHeaderDisabled,
  servicesList: state.writerReducer.hiredData.servicesList
})

const mapDispatchToProps = {
  updateForm: changeAttachedDocSignSpecific,
  changeWriteNotificationForm: changeWriteNotificationForm,
  changeHeaderCheckbox: changeHeaderCheckbox
}


export default connect(mapStateToProps, mapDispatchToProps)(SpecificFileCard)
