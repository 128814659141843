import React from "react"
import PropTypes from "prop-types"
import { Dropdown } from "semantic-ui-react"
import styled from 'styled-components'

import './inputs.css'
import { useDispatch } from "react-redux"

const Divider = styled.div`
  display: flex;
  background-color: #ffffff !important;
`
const InputFormDropdownLogin = ({ ...props }) => {

    const { placeholder, options, value, id, handleOnChange, validator, validateOptions, disabled } = props

    let errorValidator = null;
    if (validator && validateOptions) errorValidator = validator.message('', value, validateOptions);

    return (
        <Divider className={errorValidator ? 'divInputDropdownFormEntitiesError' : 'divInputDropdownFormEntities'} style={{ width: '100%' }}>
            <Dropdown
                upward={false}
                name={id}
                selection
                button
                options={options}
                className={'inputDropdownFormEntities inputLoginQr'}
                value={value}
                onChange={handleOnChange}
                id={id}
                autoComplete='off'
                style={{ paddingBottom: "29px" }}
                disabled={disabled}
            />
            {errorValidator ?
                <>
                    <label className={errorValidator && value === '' && 'spanLabelInputTopError'}>{placeholder}</label>
                    <label className={(errorValidator) && "input-error3"}>{errorValidator}</label>
                </>
                :
                <span className={value !== '' ? 'labelInputDropdownFormEntitiesTop' : 'labelInputDropdownFormEntities'}>{placeholder}</span>
            }
        </Divider>
    )
}


InputFormDropdownLogin.propTypes = {
    labelProps: PropTypes.object,
    id: PropTypes.string,
}
export default InputFormDropdownLogin