import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SimpleReactValidator from 'simple-react-validator'
import { reactValidatorOptions } from '../../../helpers/simpleReactValidator'
import { Form, Progress, Dropdown, DropdownMenu, DropdownDivider, Input, DropdownItem } from 'semantic-ui-react'
import InputFormClient from '../InputAnimated/InputFormClient'
import InputFormDropdown from '../InputAnimated/InputFormDropdown'
import InputFormArea from '../InputAnimated/InputTextArea'
import InputCheckbokForm from '../InputAnimated/InputCheckboxForm'
import InputFormDate from '../InputAnimated/InputDate'

import styled from 'styled-components'
import '../index.css'
import { withRouter } from 'react-router-dom'
import { refreshStateModule, warningFormModal } from '../../../actions/modalActions'
import { activeFormAgreement, postCreateAgreementV2, getProviderList2, getClientList2, getProviderList2V2} from '../../../actions/agreementActions'
import InputAttachButton from '../InputAnimated/InputAttachButton'
import { fileToBase64Clean } from '../../../helpers/filesLib'
import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as Ex } from '../../../assets/icons/ex.svg'
import InputSelectAgreement from '../InputAnimated/InputSelectAgreement'

const Divider = styled.div`
  max-width: 496px !important;
  margin: auto;
  @media only screen and (max-width: 1200px) {
    width: 100%;
    min-width: 350px;
    height: 100vh;
  }
  @media only screen and (max-width: 700px) {
    width: 100%;
    min-width: 350px;
    height: 100vh;
    padding: 0px 7px;
  }
`
const CustomForm = styled(Form)`
  display: flex !important;
  flex-direction: column !important;
  width: 100%;
  margin: auto;
  @media only screen and (max-width: 1200px) {
    width: 100%;
    min-width: 330px;
    margin:0;
  }
`
const ContainerButtons = styled.div`
  display: flex;
  margin-top: 15px;
  justify-content: flex-end;
  margin-bottom: 1rem;
  @media only screen and (max-width: 1200px) {
    gap: 16px;
  }
`
const Divider3 = styled.div`
  margin-bottom: 24px;
  @media only screen and (max-height: 800px) {
    margin-bottom: 14px;
  }
`
const Divider4 = styled.div`
    margin-bottom: 24px;
    @media only screen and (max-height: 800px) {
        margin-bottom: 14px;
    }
`
const ContainerFormDate = styled.div`
  display: flex;
  justify-content: space-between !important;
    @media only screen and (max-height: 800px) {
        margin-bottom: 13px;
    }
  @media only screen and (max-width: 700px) {
    display: block;
    margin-bottom: 23px;
  }
`
const ContainerPositionDateOne = styled.div`
  width: 48%;
  margin-bottom: 23px;
    @media only screen and (max-height: 800px) {
        margin-bottom: 13px;
    }
  @media only screen and (max-width: 700px) {
    width:100%;
    float:left;
  }
`
const ContainerPositionDateTwo = styled.div`
    width: 48%;
    @media only screen and (max-width: 700px) {
        width:100%;
        margin-top: 0px;
        float:right;
    }
`
const Container = styled.div`
  border: 1px solid #E35F00;
  background: rgba(227, 95, 0, 0.1);
  color: #E35F00;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 1rem;
  padding: 10px 28px;
  text-align: center;
`
const Title = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #133F4F;
`
const Title2 = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #133F4F;
  margin: 24px 0px !important;
  @media only screen and (max-height: 800px) {
    margin-bottom: 14px !important;
  }
`
const TextNameFile = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  line-height: 23px !important;
  color: #000000;
  font-size: 14px;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  margin: 0px !important;
`
const TextNameFile2 = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  line-height: 23px !important;
  color: #000000;
  font-size: 14px;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
   max-width: 200px;
   margin: 0 0 0.25rem !important;
`
const Container2 = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 14px;
  color: #C3132E;
  padding: 16px;
  @media only screen and (max-width: 768px) {
    padding: 16px 0px;

  }
`
const ButtoCancel = styled.button`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #22C1D0;
  text-align: center;
  width: 128px;
  height: 48px;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  background: #FFFFFF;
  margin-right: 8px;
  cursor: pointer;
  @media only screen and (max-width: 1200px) {
    float:left;
    width:49%;
    margin:0;
  }
`
const ButtoSave = styled.button`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #FFFFFF;
  text-align: center;
  width: 190px;
  height: 48px;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  background: #22C1D0;
  margin-left: 8px;
  cursor: pointer;
  @media only screen and (max-width: 1200px) {
    float:right;
    width:49%;
    margin:0;
  }
`
const ProgressBar = styled(Progress)`
  width: 100%;
  height: 3px;
  border-radius: 0px !important;
  margin: 0 0 0.5em !important;
  & > .bar {
    background-color: #009BDD !important;
  }
`
const DividerAttachments = styled.div`
    display: flex;
    width: 100% ;
    align-items: flex-start;
    @media only screen and (max-width: 768px) {
        flex-direction: column;
    }
`

const DivFilesNames = styled.div`
    display: flex;
    flex-direction: column;
    width: 48%;

    @media (max-width: 768px) {
        margin-top: 16px;
    }
`;
const initialState = {
    companyProviderID: '',
    companyClientID: '',
    startDate: '',
    endDate: '',
    agreementCode: '',
    subscriptionDate: '',
    tradeNumber: '',
    tradeDate: '',
    tradeDetails: '',
    tradeSupport: '',
    agreementsFiles: [],
    bagName: '',
    quantity: '',
    sendNotification: true,
    pushNotification: false,
    notificationReading: false,
    notificationSignature: false,
    documentSignature: false,
    providerList: [],
    clientList: [],
    progress: [0, 0, 0],
    providerDefinition: '',
    clientDefinition: '',
    isChangedState: false,
    errorFile: false,
    errorQuantity:false,
    maxQuantityAchieved: false,
    isVisible: false,
    errorProvider: false,
    errorClient: false,
    fileTypeError: false,
    repeatedFileError:false,
}

export class FormCreateAgreements extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...initialState,
            messagesShown: false,
            probandoProvider: '',
            tagOptions : [
                {
                  key: 'Important',
                  text: 'Important',
                  value: 'Important',
                  label: { color: 'red', empty: true, circular: true },
                },
                {
                  key: 'Announcement',
                  text: 'Announcement',
                  value: 'Announcement',
                  label: { color: 'blue', empty: true, circular: true },
                },
                {
                  key: 'Cannot Fix',
                  text: 'Cannot Fix',
                  value: 'Cannot Fix',
                  label: { color: 'black', empty: true, circular: true },
                },
                {
                  key: 'News',
                  text: 'News',
                  value: 'News',
                  label: { color: 'purple', empty: true, circular: true },
                },
                {
                  key: 'Enhancement',
                  text: 'Enhancement',
                  value: 'Enhancement',
                  label: { color: 'orange', empty: true, circular: true },
                },
                {
                  key: 'Change Declined',
                  text: 'Change Declined',
                  value: 'Change Declined',
                  label: { empty: true, circular: true },
                },
                {
                  key: 'Off Topic',
                  text: 'Off Topic',
                  value: 'Off Topic',
                  label: { color: 'yellow', empty: true, circular: true },
                },
                {
                  key: 'Interesting',
                  text: 'Interesting',
                  value: 'Interesting',
                  label: { color: 'pink', empty: true, circular: true },
                },
                {
                  key: 'Discussion',
                  text: 'Discussion',
                  value: 'Discussion',
                  label: { color: 'green', empty: true, circular: true },
                },
              ]
        }
        this.formValidator = new SimpleReactValidator(reactValidatorOptions)
    }

    async componentDidMount() {
        await this.props.getProviderList2V2().then(() => {
            const dataProvider = this.props.provider.map((x) => {
                return { text: x.name, key: x.companySerialID, value: x.companySerialID}
            })
            dataProvider.unshift({ text: '', key: '', value: '' })
            this.setState({ providerList: dataProvider })
        })

        await this.props.getClientList2().then(() => {
            const dataClient = this.props.client.map((x) => {
                return { text: x.name, key: x.companySerialID, value: x.companySerialID }
            })
            dataClient.unshift({ text: '', key: '', value: '' })
            this.setState({ clientList: dataClient })
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.activeForm === false && this.props.warningModal === true) {
            this.props.activeFormAgreement()
        }

        if (prevProps.moduleType === false && this.props.moduleType === true) {
            this.props.history.goBack()
            this.props.refreshStateModule()
        }
        if (prevState.companyProviderID !== this.state.companyProviderID) {
            this.props.activeFormAgreement()
            this.setState({errorProvider: false})
            this.props.provider.map((x) => {
                if (x.companySerialID === this.state.companyProviderID) {
                    this.setState({ providerDefinition: x.providerDefinition })
                }
            })
        }
        if (prevState.companyClientID !== this.state.companyClientID) {
            this.props.activeFormAgreement()
            this.setState({errorClient: false})
            this.props.client.map((x) => {
                if (x.companySerialID === this.state.companyClientID) {
                    this.setState({ clientDefinition: x.providerDefinition })
                }
            })
        }

        if(prevState.messagesShown !== this.state.messagesShown){
            if(!!this.state.messagesShown){
              this.validateErrors()
            }
          }
    }

    handleOnChangeProviders = (event, {value}) => {
        let dataValue= value
        if(event.currentTarget.hasOwnProperty('name')){
            dataValue= event.currentTarget.getAttribute('name')

        }else{
            if(value === undefined){
                dataValue= event.currentTarget.getAttribute('name')
            }   
        }

        this.setState({
            companyProviderID: dataValue,
        })
        

        if (this.state.companyProviderID !== ''){
            this.setState({errorProvider: false})
        } 
    }

    handleOnChangeClients = (event, {value}) => {
        let dataValue= value
        if(event.currentTarget.hasOwnProperty('name')){
            dataValue= event.currentTarget.getAttribute('name')

        }else{
            if(value === undefined){
                dataValue= event.currentTarget.getAttribute('name')
            }   
        }

        this.setState({
            companyClientID: dataValue,
        })
        
        if (this.state.companyClientID !== ''){
            this.setState({errorClient: false})
        }
    }

    onChange = (e) => {
        const { id } = e.target;
        this.setState({
          [id]: e.target.value,
          errorQuantity: id === 'quantity' ? false : this.state.errorQuantity, // Reset errorQuantity only for 'quantity' field
        });
        this.isValidFieldName(e.target.getAttribute('label')) ?
          this.formValidator.showMessageFor(e.target.getAttribute('label')) :
          this.formValidator.showMessageFor(id);
      }

    isValidFieldName(fieldName) {
        return this.formValidator.fields.hasOwnProperty(fieldName);
    }


    handleCancel = () => {
        this.formValidator.hideMessages()
        this.forceUpdate()
        this.props.warningFormModal({ type: 'warningLeaveAgreements' })
    }

    toggle = (e) => {
        let name = e.target.id;
        this.setState({
            [name]: !this.state[name]
        })
    }

    onChangeHandler = async (e) => {
        const file = e.target.files
        let fileInfo = {}
        if (file.length + this.state.agreementsFiles.length <= 10) {
                if (file.length) {
                    for (let i = 0; i < file.length; i++) {
                        const isDuplicate = this.state.agreementsFiles.some(existingFile => existingFile.fileName === file[i].name);
                        if (!isDuplicate) {
                            fileInfo = {
                                fileId: uuidv4(),
                                base64: await fileToBase64Clean(file[i].name, file[i]),
                                fileName: file[i].name,
                                fileExtension: file[i].type.split("/").pop(),
                            }
                            if(fileInfo.fileExtension === "pdf"){
                                this.setState({ agreementsFiles: [...this.state.agreementsFiles, fileInfo] })
                                this.loadNotes(this.state.agreementsFiles.length - 1, file[i].size / 2500);
    
                            }else{
                                this.setState({ fileTypeError: true })
                            setTimeout(() => {
                                this.setState({ fileTypeError: false })
                            }, 3000); 
                        }    
                    }else{
                        this.setState({ repeatedFileError: true });
                        setTimeout(() => {
                          this.setState({ repeatedFileError: false });
                        }, 3000); 
                    }
                    this.setState({ errorFile: false })
                }
            }
        } else {
            this.setState({ maxQuantityAchieved: true })
            setTimeout(() => {
                this.setState({ maxQuantityAchieved: false })
            }, 3000);
        }
    }

    validateFileType = files =>{
        files.find(v => v.type !== 'application/pdf')
    }

    handleRemove = async (e) => {
        const files = this.state.agreementsFiles
        files.splice(e.currentTarget.id, 1)
        this.setState({ agreementsFiles: files })
    }

    loadNotes = async (number, size) => {
        var progress;
        for (let i = 10; i <= 100; i = i + 10) {
            await new Promise((resolve, reject) => {
                setTimeout(function () { resolve(); }, size);
            });
            progress = [...this.state.progress];
            progress[number] = i;
            this.setState({ progress: progress })
        }
    }

    makeSaveNotificationReq = () => {

        const { companyProviderID, companyClientID, startDate, endDate, agreementCode, subscriptionDate, tradeNumber, tradeDate,
            tradeDetails, tradeSupport, agreementsFiles, bagName, quantity, sendNotification, pushNotification, notificationReading,
            notificationSignature, documentSignature } = this.state
        let files = []
        for (let i = 0; i < agreementsFiles.length; i++) {
            files.push({
                "fileName": agreementsFiles[i].fileName,
                "base64": agreementsFiles[i].base64
            })
        }
        const body = {
            "companyProviderID": companyProviderID,
            "companyClientID": companyClientID,
            "startDate": new Date(startDate).toISOString(),
            "endDate": new Date(endDate).toISOString(),
            "agreementCode": agreementCode,
            "subscriptionDate": new Date(subscriptionDate).toISOString(),
            "tradeNumber": tradeNumber,
            "tradeDate": new Date(tradeDate).toISOString(),
            "tradeDetails": tradeDetails,
            "tradeSupport": tradeSupport,
            "agreementsFiles": files,
            "bagName": bagName,
            "quantity": this.state.clientDefinition === 'PRIVATE' ? quantity : '',
            "sendNotification": sendNotification,
            "pushNotification": pushNotification,
            "notificationReading": notificationReading,
            "notificationSignature": notificationSignature,
            "documentSignature": documentSignature,
        }
        this.props.postCreateAgreementV2(body)
    }

    validateQuantity = () => {
        if (this.state.clientDefinition === 'PRIVATE') {
            const NumberQuantity = Number(this.state.quantity);
            return NumberQuantity >0
        }else{
            return true;
        }
    }

    setMessagesShown =(value) => {
        this.setState({messagesShown:value })
    }
 
    handleSend = (e) => {
        e.preventDefault()
        if (this.formValidator.allValid() && this.state.agreementsFiles.length !==0  && this.state.startDate < this.state.endDate && this.validateQuantity()) {
            this.makeSaveNotificationReq()
        } else {
            this.formValidator.showMessages()
            this.forceUpdate()
            if (this.state.agreementsFiles.length ===0) {
                this.setState({ errorFile: true })
            }
            if (this.state.companyProviderID === ''){
                this.setState({errorProvider: true})
            } 
            if (this.state.companyClientID === ''){
                this.setState({errorClient: true})
            }
            if (!this.validateQuantity()) {
                this.setState({errorQuantity: true})
            }
            this.setState({messagesShown: true})
        }
    }
    setIsVisible = (isVisible) => {
        this.setState({ isVisible: isVisible })
    }

    onBlurAgreementCode = () => {
        return this.checkAgreementFormat(this.state.agreementCode);
    }

   checkAgreementFormat = (value) => {
        const pattern = /^[A-Za-z]-\d{2,13}$/; // Expresion regular para el formato
        return pattern.test(value);
    }

    validateErrors = () => {
        const elementError= document.querySelector('.inputDropdownFormEntitiesError, .labelInputFormEntitiesError, .labelInputFormDateError, .supportFilesError')
        if (elementError!==null) {
            if(elementError.className.includes('inputDropdownFormEntitiesError')){
              elementError.firstChild.focus()
            }else{
              elementError.focus()
            }
            this.setState({messagesShown: false}) 
        }
    }

    render()  {
        return (
            <Divider>
                <CustomForm>
                    <Title>Datos de vinculación</Title>
                    <Divider3 >
                        <InputSelectAgreement
                         data={this.state.providerList}
                         value={this.state.companyProviderID}
                         validator={this.formValidator}
                         errorProvider={this.state.errorProvider}
                         handleOnChange={this.handleOnChangeProviders}
                         idName='companyProviderID'
                         placeholder={'Operador'}
                         name={'providerSelect'}
                        />
                    </Divider3>
                    <Divider4 > 
                        <InputSelectAgreement
                        data={this.state.clientList}
                        value={this.state.companyClientID}
                        validator={this.formValidator}
                        errorClient={this.state.errorClient}
                        handleOnChange={this.handleOnChangeClients}
                        idName='companyClientID' 
                        placeholder={'Cliente'}
                        name={'clientSelect'}
                        />
                    </Divider4>
                    {this.state.companyProviderID !== "" && this.state.companyClientID !== ""
                        && <Container>
                            <p>La vinculación creada será <strong>entre el <br />operador y el cliente</strong> seleccionados.</p>
                        </Container>
                    }
                    <ContainerFormDate>
                        <ContainerPositionDateOne>
                            <InputFormDate
                                type="date"
                                id="startDate"
                                label={'Fecha inicio'}
                                value={this.state.startDate}
                                endDate={this.state.endDate}
                                onChange={this.onChange}
                                validator={this.formValidator}
                                validateOptions={['required', 'dateStart']}
                                error={this.state.errors}
                            />
                        </ContainerPositionDateOne>
                        <ContainerPositionDateTwo>
                            <InputFormDate
                                type="date"
                                id="endDate"
                                label={'Fecha vencimiento'}
                                value={this.state.endDate}
                                onChange={this.onChange}
                                validator={this.formValidator}
                                validateOptions={['required', 'dateEnd']}
                                error={this.state.errors}
                            />
                        </ContainerPositionDateTwo>
                    </ContainerFormDate>
                    <InputFormClient
                            id={'quantity'}
                            label={'Cantidad envío de notificaciones'}
                            type={'text'}
                            onChange={this.onChange}
                            value={this.state.clientDefinition !== 'PUBLIC' ? this.state.quantity :'Ilimitadas'}
                            disabled={this.state.clientDefinition === 'PUBLIC' ? true : false}
                            validator={this.formValidator}
                            validateOptions={this.state.clientDefinition === 'PRIVATE' ? ['required', 'numeric','quantityAgreement', { min: 1 }] : []}
                            error={this.state.errors}
                            fixedMargin={true}
                            stylesAgreements
                            errorQuantity={this.state.errorQuantity}
                    />
                    <InputFormClient id={'agreementCode'}
                        label={'Código de vinculación (ej: P-01010101)'}
                        type={'text'}
                        onChange={this.onChange}
                        value={this.state.agreementCode}
                        validator={this.formValidator}
                        validateOptions={['agreementCode', 'required']}
                        error={this.state.errors}
                        fixedMargin={true}
                        stylesAgreements
                        setMessageShown= {this.setMessagesShown}
                    />
                    <InputFormDate
                        type="date"
                        id="subscriptionDate"
                        label={'Fecha de suscripción'}
                        value={this.state.subscriptionDate}
                        onChange={this.onChange}
                        validator={this.formValidator}
                        validateOptions={['required', 'dateSubscription']}
                        error={this.state.errors}
                        stylesAgreements
                    />
                    <InputFormClient
                        id={'tradeNumber'}
                        label={'Número de oficio'}
                        type={'text'}
                        onChange={this.onChange}
                        value={this.state.tradeNumber}
                        validator={this.formValidator}
                        validateOptions={['required', 'numeric', { min: 4 }, { max: 15 }]}
                        error={this.state.errors}
                        fixedMargin={true}
                        stylesAgreements
                    />
                    <InputFormDate
                        type="date"
                        id="tradeDate"
                        label={'Fecha de oficio'}
                        value={this.state.tradeDate}
                        onChange={this.onChange}
                        validator={this.formValidator}
                        validateOptions={['required', 'dateSubscription']}
                        error={this.state.errors}
                        stylesAgreements
                    />
                    <InputFormArea
                        id={'tradeDetails'}
                        label={'Detalle vinculación'}
                        type={'text'}
                        onChange={this.onChange}
                        value={this.state.tradeDetails}
                        validator={this.formValidator}
                        validateOptions={['required', { min: 1 }, { max: 100 }]}
                        error={this.state.errors}
                        min={1}
                        max={100}
                        heightText={'96px'}
                        stylesAgreements
                    />
                    <InputFormArea
                        id={'tradeSupport'}
                        label={'Soporte'}
                        type={'text'}
                        onChange={this.onChange}
                        value={this.state.tradeSupport}
                        validator={this.formValidator}
                        validateOptions={['required','supportDescription', { min: 1 }, { max: 100 }]}
                        error={this.state.errors}
                        min={1}
                        max={100}
                        heightText={'96px'}
                        stylesAgreements
                    />
                    <DividerAttachments>
                        <InputAttachButton
                            disabled={this.state.agreementsFiles !== undefined && this.state.agreementsFiles.length > 9 ? true : false}	
                            onSelectFile={this.onChangeHandler}
                            value={this.state.agreementsFiles}
                            accept='application/pdf'
                            className={!! this.state.errorFile ? 'supportFilesError': ''}
                            id='agreementsFiles'
                            validator={this.formValidator}
                            validateOptions={['required', { min: 1 },]}
                        />
                        {this.state.agreementsFiles.length !== 0
                            && <DivFilesNames>
                                {this.state.agreementsFiles.map(({ fileName }, i) =>
                                    <div className='file' key={i} style={{ display: 'flex', flexDirection: 'column' }}
                                        onMouseEnter={() => this.setIsVisible(true, i)} onMouseLeave={() => this.setIsVisible(false, i)}>
                                        {this.state.progress[i] === 100 ?
                                            <div style={{ display: 'flex' }} >
                                                <TextNameFile2>{fileName.slice(0, -4)}</TextNameFile2>.pdf
                                                <Ex className='hide' id={i} onClick={this.handleRemove} style={{
                                                    minWidth: '12px',
                                                    minHeight: '12px', margin: '5px 0 0 10px', cursor: 'pointer'
                                                }} />
                                            </div> : <>
                                                <div style={{ display: 'flex', opacity: '0.3' }} >
                                                    <TextNameFile>{fileName.slice(0, -4)}</TextNameFile>.pdf
                                                </div>
                                                <ProgressBar percent={this.state.progress[i]} active size='tiny' />
                                            </>}
                                    </div>)}
                            </DivFilesNames>
                        }
                        {this.state.errorFile === true
                            && <Container2> Debes adjuntar la vinculación </Container2>
                        }
                    </DividerAttachments>
                    {this.state.maxQuantityAchieved === true
                        && <Container style={{ margin: '10px 0px' }}>
                            <p> Solo puedes adjuntar hasta 10 documentos.</p>
                        </Container>
                    }
                    {this.state.fileTypeError === true
                        && <Container style={{ margin: '10px 0px' }}>
                            <p> Solo puedes adjuntar documentos PDF.</p>
                        </Container>
                    }
                    {this.state.repeatedFileError === true
                        && <Container style={{ margin: '10px 0px' }}>
                            <p> No puedes adjuntar documentos con el mismo nombre</p>
                        </Container>
                    }
                    <Title2>Servicios de notificación</Title2>
                    <InputCheckbokForm
                        label='Solo envío'
                        value={this.state.sendNotification}
                        validator={this.formValidator}
                        validateOptions='required'
                        id='sendNotification'
                    />
                    <InputCheckbokForm
                        label='Envió con notificación push'
                        onChange={this.toggle}
                        value={this.state.pushNotification}
                        validator={this.formValidator}
                        validateOptions='required'
                        id='pushNotification'
                    />
                    <InputCheckbokForm
                        label='Envió con confirmación de lectura'
                        onChange={this.toggle}
                        value={this.state.notificationReading}
                        validator={this.formValidator}
                        validateOptions='required'
                        id='notificationReading'
                    />
                    <InputCheckbokForm
                        label='Envió con firma en notificación'
                        onChange={this.toggle}
                        value={this.state.notificationSignature}
                        validator={this.formValidator}
                        validateOptions='required'
                        id='notificationSignature'
                    />
                    <InputCheckbokForm
                        label='Envió con firma en documentos'
                        onChange={this.toggle}
                        value={this.state.documentSignature}
                        validator={this.formValidator}
                        validateOptions='required'
                        id='documentSignature'
                    />
                    {
                        this.state.sendNotification === true && this.state.pushNotification === true && this.state.notificationReading === true
                        && this.state.notificationSignature === true && this.state.documentSignature === true
                        &&
                        <Container>
                            <p>Recuerda que, aunque selecciones todos los servicios, al momento de crear una notificación solo podrás solicitar
                                <strong>&nbsp;firma en notificación o firma en documentos.</strong></p>
                        </Container>
                    }
                    <ContainerButtons>
                        <ButtoCancel onClick={this.handleCancel}  >Cancelar</ButtoCancel>
                        <ButtoSave onClick={this.handleSend}>Crear vinculación</ButtoSave>
                    </ContainerButtons>
                </CustomForm>
            </Divider >
        )
    }
}

FormCreateAgreements.propTypes = {
    postCreateAgreementV2: PropTypes.func.isRequired,
    savedAgreement: PropTypes.array,
    activeFormAgreement: PropTypes.func.isRequired,
    activeForm: PropTypes.bool,
    warningModal: PropTypes.bool,
    warningFormModal: PropTypes.func.isRequired,
    refreshStateModule: PropTypes.func.isRequired,
    moduleType: PropTypes.bool,
}

const mapStateToProps = (state) => ({
    savedAgreement: state.agreementReducer.savedAgreement,
    activeForm: state.agreementReducer.activeForm,
    moduleType: state.modalReducer.refreshModule,
    warningModal: state.modalReducer.warningModal.status,
    provider: state.agreementReducer.providerList,
    client: state.agreementReducer.clientList,

})

const mapDispatchToProps = {
    postCreateAgreementV2,
    activeFormAgreement,
    warningFormModal,
    refreshStateModule,
    getProviderList2V2,
    getClientList2
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FormCreateAgreements))