import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';
import { saveHiredV2 } from '../../actions/writerActions';
import { DashboardAgreementLabel } from '../../components/Label/DashboardAgreementLabel';
import DashBoardLabelClient, { DashBoardLabel, DashBoardLabelDateClient, DashBoardLabelNotFoundAgremments } from '../../components/Label/DashBoardLabelClient';
import { ReactComponent as AlertIcon } from '../../assets/icons/write/warning-alet-icon.svg'
import { Dimmer, Loader, Segment } from 'semantic-ui-react'
import moment from 'moment/moment'

const CustomP = styled.p`
font-family: 'Roboto';
font-style: normal;
font-weight: 600;
font-size: 18px;
text-align: center;
color: #133F4F;
padding: 0% 0 1.2% 0;
@media only screen and (max-height: 900px) {
  padding: 0;
}
`

const CustomPTitle = styled.p`
font-family: 'Roboto';
font-style: normal;
font-weight: 600;
font-size: 24px;
text-align: left;
color: #133F4F;
padding: 2% 0 0 20px;
`
const Container = styled.div`
max-width: 939px;
max-height: 100%;
height: 88vh;
.row1{
  columns: 2;
  gap: 31px;
}
.column1{
  width: 434px !important;
}
@media only screen and (max-height: 900px) {
  .row1{
    padding-top: 0px !important;
  }
}
@media only screen and (max-width: 1280px){
  .row1{
    columns: 2;
    gap: 2%;
  }
  .column1{
    width: 49% !important;
  }
}
`
const ContainerScroll = styled.div`
  height: 35vh;
  // max-height: 38vh;
  overflow-y: scroll;
  .row2{
    width: 100% !important;
    margin: 0px;
  }
  ::-webkit-scrollbar-track {
    background: #CCCCCC;
  }
  ::-webkit-scrollbar-thumb {
    background: #9D9D9C !important;
  }
  ::-webkit-scrollbar:vertical {
    width: 5px;
  }
`
const DivHeaderAgreements = styled.div`
padding: 30px 0px 0px 0px;
@media only screen and (max-height: 850px){
  padding: 0px 0px 0px 0px;
}
`
const DivHeader = styled.div`
padding: 0px 0px 14px 0px;
@media only screen and (max-height: 850px){
  padding: 0px 0px 0px 0px;
}
`
const TextAlert = styled.p`
  color: #E35F00;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16.41px;
`
const CustomDiv = styled.div`
  display: flex;
  padding: 8px;
  width: 100%;
  background: rgba(227, 95, 0, 0.1);
  border: 1px solid #E35F00;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 0 0 18px 0;
  align-items:center;
  height:48px;
  min-height: 48px;
  font-weight: 400;
  font-size: 14px !important;
  line-heigt:16.41px;
  gap:10px;

`

export const DashBoardClient = ({ result = {} }) => {
  const dispatch = useDispatch();
  const { hiredData } = useSelector(state => state.writerReducer)

  useEffect(() => {
    dispatch(saveHiredV2())
  }, [dispatch])

  const { agreementNotifications = 0, agreementSentNotifications = 0, agreementsList = [], companyName = 'Nombre Compañia',
    companyDefinition = '', agreementExpDate, haveAgreements = true } = result

  const expDate = agreementExpDate ? moment(agreementExpDate).format('DD/MM/YYYY') : '';
  const [loader, setLoader] = useState(true);
  const { name } = useSelector((state) => state.authReducer.user.companyID)

  useEffect(() => {
    let timer1 = setTimeout(() => setLoader(false), 2 * 1000);

    if (Object.keys(result).length !== 0) {
      clearTimeout(timer1);
    }
  }, [result])

  return (


    <Segment style={{ padding: '0px', border: '0px', boxShadow: '0 0px 0px 0 rgba(34,36,38,.15)', }}>
      <Dimmer.Dimmable>
        <Dimmer active={loader} inverted page={false} >
          <Loader size='medium'>Cargando...</Loader>
        </Dimmer>
        <Container >
          <DivHeader>
            <CustomPTitle>{name}</CustomPTitle>
          </DivHeader>
          <>
            {!loader &&
              <>
                <Grid style={{ padding: '2%', width: '100%', margin: '0px' }} columns={2} >
                  {hiredData.closeToExpiration &&
                    <CustomDiv >
                      <AlertIcon style={{ width: '32px', height: '32px' }} /><TextAlert><strong>¡Vinculación próxima a vencer!</strong> Recuerda tener una vinculación aprobada para continuar con el envío de notificaciones</TextAlert>
                    </CustomDiv>
                  }
                  {haveAgreements ?
                    <Grid.Row className='row1'>
                      {companyDefinition === 'PRIVATE' ?
                        <>
                          <Grid.Column className='column1' style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                            <DashBoardLabelClient text='Total notificaciones vinculación en curso' number={agreementNotifications} total={true}
                            ></DashBoardLabelClient>
                          </Grid.Column>

                          <Grid.Column className='column1' style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                            <DashBoardLabelClient text='Notificaciones enviadas vinculación en curso' number={agreementSentNotifications} sent={true}
                              totalNotifications={agreementNotifications}></DashBoardLabelClient>
                          </Grid.Column>
                        </>
                        :
                        <>
                          <Grid.Column className='column1' style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                            <DashBoardLabelDateClient text='Fecha vencimiento de la vinculación en curso' date={expDate}  ></DashBoardLabelDateClient>
                          </Grid.Column>

                          <Grid.Column className='column1' style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                            <DashBoardLabel text='Notificaciones enviadas vinculación en curso'
                              sentNotifications={agreementSentNotifications}></DashBoardLabel>
                          </Grid.Column>
                        </>

                      }
                    </Grid.Row>
                    :
                    <DashBoardLabelNotFoundAgremments />
                  }
                </Grid>
                {!!agreementsList &&
                  <div style={{ padding: '0px 2% 0px' }}>
                    <div style={{ padding: '10px', boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.15)', borderRadius: '4px', maxHeight: '45vh' }}>
                      <DivHeaderAgreements >
                        <CustomP>Vinculaciones</CustomP>
                      </DivHeaderAgreements>
                      <ContainerScroll>
                        <Grid className='row2' columns={3} doubling stackable >
                          <Grid.Row  >
                            {agreementsList?.map(({ agreementCode, agreementStatus, expirationDate, notificationType, operatorName, aproveedNotifications, startDate = "2024-12-30T17:07:21.000Z" }, index) =>
                              <>
                                {(agreementStatus !== 'INACTIVE' && agreementStatus !== 'EXPIRED') &&
                                  <Grid.Column style={{ paddingBottom: '15px', paddingLeft: '0.5rem', paddingRight: '0.5rem' }} key={agreementCode + index}>
                                    <DashboardAgreementLabel
                                      agreementCode={agreementCode}
                                      agreementStatus={agreementStatus}
                                      startDate={startDate}
                                      expirationDate={expirationDate}
                                      notificationType={notificationType}
                                      operatorName={operatorName}
                                      aproveedNotifications={aproveedNotifications}
                                      companyDefinition={companyDefinition}
                                    />
                                  </Grid.Column>}
                              </>
                            )}
                          </Grid.Row>
                        </Grid>
                      </ContainerScroll>
                    </div>
                  </div>
                }
              </>}
          </>
          {/* <DashBoardLabelNotFoundAgremments /> */}
        </Container>

      </Dimmer.Dimmable>
    </Segment>
  )
}
