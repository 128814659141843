import React from 'react'
import styled from 'styled-components'

const Button = styled.button`
  background: #22C1D0;
  border-radius: 4px;
  border: 1px solid #22C1D0;
  color: #FFFFFF;
  margin-left: 10px;
  padding: 10px, 32px, 10px, 32px;
  width: 208px;
  height: 48px;
  font-family: Roboto;
  font-weight: Bold;
  font-size: 16px;
  cursor: pointer;
  @media only screen and (max-width: 1024px) {
    font-size: 16px;
    width: 100%;
    height: 35px;
    margin: 2% 0 0 0;
  }
`
export const ButtonSearchGeneric = ({title, onApply}) => {
  return (
    <Button onClick={onApply}>{title}</Button>
  )
}

