import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { actionUpdateTabFilter } from '../../../../actions/filterDetailActions'


import { SearchBarDetailGenericButton } from '../../../../components/Search/SearchBarGeneric'
import Logs from './Logs'

const AllConvetions = (props) => {

    const dispatch = useDispatch()

    const [searchBarState, setSearchBarState] = useState("");
    const [popUpOrderState, setPopUpOrderState] = useState("");

    useEffect(() => {
        dispatch(actionUpdateTabFilter('Logs'))
    }, [dispatch])
    return (
        <>
            <SearchBarDetailGenericButton showButton={false} textPlaceHolder={'Buscar usuario'} popUpOrderState={popUpOrderState}
            searchBarState={searchBarState} showAZ={false} showCleanFilter={true} options={'logs'} filterAgreements={false} filterPosition={props.filterPosition}/>
            <Logs />
        </>
    )
}

AllConvetions.propTypes = {
}

export default AllConvetions 