import axios from 'axios'
import {API_ENDPOINT} from "../config/config"
import {GET_GEOGRAPHIC_LIST} from "./actionsUtilities/types.js";
import {GET_GEOGRAPHIC_LIST_ROUTE, GET_GEOGRAPHIC_LIST_ROUTE_V2} from './actionsUtilities/actionsRoutes.js'
import { getHeaders } from '../helpers/globalHelpers.js';
import { decrypt } from '../helpers/cypher.js';

export const getGeographicList = () => async (dispatch) => {
  const headers = getHeaders();
  await axios.get(
    `${API_ENDPOINT+GET_GEOGRAPHIC_LIST_ROUTE_V2}`,
    {headers}
  ).then(async (res) => {
    const decryptedData = await decrypt(res.data.result);
    if (res.status === 200) {
      dispatch({
        type: GET_GEOGRAPHIC_LIST,
        payload: decryptedData
      })
    }
  }).catch((error) => {
    console.error(error)
  })
}