import React from "react"
import PropTypes from "prop-types"

import { useDispatch } from 'react-redux'
import './inputs.css'
import styled from "styled-components"
import { Form } from "semantic-ui-react"

const Text = styled.div`

`
const Text2 = styled.span`
    
`
const InputForm = ({ ...props }) => {

    const { placeholder, type, id, onChange, required, value, label, validator, validateOptions, defaultValue, 
        disabled, onBlur, min, autoComplete, customStyle } = props

    let errorValidator = null;
    if (validator && validateOptions) errorValidator = validator ? 
    validator.message(label !== '' && label !== undefined ? label : placeholder, value, validateOptions) : ''

    return (
        <Form.Field style={{ width: '100%' }}>
            <label className={errorValidator ? 'labelInputFormLoginError' : 'labelInputFormLogin'}>
                <input
                    id={id}
                    type={type}
                    value={value}
                    placeholder={placeholder}
                    onChange={onChange}
                    onBlur={onBlur}
                    min={min}
                    autoComplete={autoComplete}
                    required={!!required}
                    className={errorValidator ? 'inputFormLoginError' : 'inputFormLogin'}
                />
                {
                    errorValidator && value === ''
                    &&
                    <>
                        <Text2 className={errorValidator && value === '' && 'spanLabelInputTopError'}>{label}</Text2>
                        <Text style={customStyle} className={(errorValidator) && 'input-error3'}>{errorValidator}</Text>
                    </>
                }
                {
                    errorValidator === undefined && value !== ''
                    &&
                    <>
                        <span className={'spanLabelInputTop'}>{label}</span>
                    </>
                }
                {
                    errorValidator === undefined && value === ''
                    &&
                    <>
                        <span className={label === 'Código' && id === 'code' ? 'spanLoginCode' : label === 'Código' && id === 'code2' ? 'spanLoginCode2' : 'spanLogin'}>{label}</span>
                    </>
                }
                {
                    errorValidator && value !== ''
                    &&
                    <>
                        <Text2 className={'spanLabelInputTopError'}>{label}</Text2>
                        <Text className={'input-error2'}>{errorValidator}</Text>
                    </>
                }
            </label>
        </Form.Field>
    )
}


InputForm.propTypes = {
    labelProps: PropTypes.object,
    id: PropTypes.string,
}
export default InputForm