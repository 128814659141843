import React from 'react';
import { Provider } from 'react-redux';
import store from './store/store';
import './App.css';
import './assets/css/base.css';
import './assets/css/colors.css';
import 'semantic-ui-css/semantic.min.css'
import Routes from './routes/routes';
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/lib/integration/react';


export const persist = persistStore(store);

const App = () => (
  <Provider store={store}>
    <PersistGate persistor={persist}>
      <Routes />
    </PersistGate>
  </Provider>
);

export default App;
