import React, {Component} from 'react'
import {MainSegment} from '../../components/Sengment/MainSegment'

class Backup extends Component {
  render() {
    return (
      <MainSegment>
        Backup
      </MainSegment>
    )
  }
}

export default Backup
