import React, { useState } from 'react'
import { Table } from 'semantic-ui-react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { ReactComponent as EnabledButton } from '../../../../assets/icons/clients/enabled-button.svg'
import { ReactComponent as EditImage } from '../../../../assets/icons/clients/edit-button.svg'
import moment from "moment/moment";
import { Cell } from '../../../../components/Table/CellComponent'
import { useSelector } from 'react-redux'

const SentCell = styled(Table.Cell)`
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  max-width: 1px !important;
  padding: 10px !important;
`

const Row = styled(Table.Row)`

  height: 50px !important;
  @media only screen and (max-width: 768px) {
    height: auto !important;
  }
  ${props => props.rowcolor && css`
    color: ${props.rowcolor}
  `}
`

const Enabled = styled(EnabledButton)`
  margin: 4px 0 0 0 !important;
`

const Edit = styled(EditImage)`
  float: right !important;
  margin: 4px 25px 0 0 !important;
`
export const RowTableLogs = (props) => {

    const [Isvisible, setIsVisible] = useState(false);
    const userRole = useSelector(state => state.authReducer.user.roleName)

    const logsAction = {
      UPDATE: "Edición",
      CREATE: "Creación"
    }

    const formatDate = (userDocumentDate) => moment(userDocumentDate).utc().format('DD/MM/YYYY')

    
    return (
        <Row
            onMouseEnter={() => setIsVisible(true)}
            onMouseLeave={() => setIsVisible(false)}
            // rowcolor={colorRow(props.data.statusAgreement)}
        >
            <Cell width={2}  text={logsAction[props.data.action]} columnName={props.headerTable[0].name} />
            <Cell width={2}  text={props.data.userAction} columnName={props.headerTable[1].name} />
            <Cell width={2}  text={props.data.dateRecord} columnName={props.headerTable[2].name} />
        </Row>
    )
}

RowTableLogs.propTypes = {
    data: PropTypes.object.isRequired
};

export default RowTableLogs