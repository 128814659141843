import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import GeneralTable from '../../../components/Table/GeneralTable'
import RowTableSent from './RowTableSent'
import { getNotificationsStarred } from '../../../actions/starredAction'
import { actionUpdateTabFilter } from '../../../actions/filterActions'
import ContainerSearch from '../../../components/Search/ContainerSearch'



class AllNotificationsStarreds extends Component {
  static propTypes = {
    history: PropTypes.any.isRequired,
  }

  state = {
    loader: true
  }

  componentDidMount() {
    this.makeNotificationsRequest(this.props.filterReducer)
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.filterReducer, this.props.filterReducer)) {
      this.makeNotificationsRequest(this.props.filterReducer)
    }

    if (prevProps.tabFilter !== this.props.tabFilter) {
      this.props.updateTabFilter(this.props.tabFilter)
    }

    if (prevProps.stateNotificationStarred !== this.props.stateNotificationStarred) {
      this.makeNotificationsRequest(this.props.filterReducer)
    }

  }

  makeNotificationsRequest = (filterReducer) => {
    const body = {
      count: filterReducer.count,
      pag: filterReducer.pag,
      sort: this.orderTranslator(filterReducer.sort),
      status: filterReducer.searchAdvanced.notificationState,
      initDate: !!filterReducer.searchAdvanced.date.startDate
        ? moment(filterReducer.searchAdvanced.date.startDate).format('YYYY-MM-DD') : '',
      endDate: !!filterReducer.searchAdvanced.date.endDate
        ? moment(filterReducer.searchAdvanced.date.endDate).format('YYYY-MM-DD') : '',
      starred: true
    }

    this.props.getNotificationsStarred(body)
      .then(() => this.setState({ loader: false }))
  }

  orderTranslator = (order) => {
    const options = {
      'masReciente': { notificationDate: -1 },
      'masAntiguo': { notificationDate: 1 },
      'A-Z': { notificationDate: -1 },
      'Z-A': { notificationDate: 1 }
    }
    return options[order]
  }

  render() {
    return (
      <>
        <ContainerSearch />
        <GeneralTable
          list={this.props.notificationsStarreds}
          rowComponent={RowTableSent}
          history={this.props.history}
          loader={this.state.loader} />
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  notificationsStarreds: state.starredReducer.notificationsStarreds,
  filterReducer: state.filterReducer,
  stateNotificationStarred: state.starredReducer.stateNotificationStarred
})

const mapDispatchToProps = {
  getNotificationsStarred,
  updateTabFilter: actionUpdateTabFilter
}

export default connect(mapStateToProps, mapDispatchToProps)(AllNotificationsStarreds)
