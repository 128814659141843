import React from 'react'
import styled from 'styled-components'
import { ButtonSearchGeneric } from './ButtonSearchGeneric'
import { SearchDashboard } from './SearchDashboard'

const Divider = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding: 2% 2%;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    .inputSearch{
      margin: 0 0;
      width: 100%;
    }
    .div1 {
      width: 100%;
      padding: 1% 0;
    }
    .div2{
      width: 100%;
    }
  }
  `
const InsideDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;

`
const Button = styled.button`
cursor: pointer;
width: 50%;
height: 28px;
background-color #FFFFFF;
border-width: 0px;
border-radius: 0px;
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 16px;
text-decoration-line: underline;
color: #22C1D0;
@media only screen and (max-width: 1024px) {
  width: 100%;
}
`

export const ContainerSearchBar = ({handleClickRemove, setTextToSearch, setValueSearch, valueSearch,onApply, options, onChangeHandler, handleAutocompleteSearch, searchBarState = '', textPlaceHolder = '', buttonTitle = '', textDeleteButton = '', enableDeleteButton = false }) => {
  return (
    <Divider>
      <div className='div1'>
      <SearchDashboard textPlaceHolder={textPlaceHolder} searchBarState={searchBarState} onChangeHandler={onChangeHandler}
      handleAutocompleteSearch={handleAutocompleteSearch} options= {options} valueSearch={valueSearch} />
      </div>
      <div className='div2'>
          <InsideDiv>
          {enableDeleteButton ? <Button onClick={handleClickRemove}>{textDeleteButton}</Button> : null}
          <ButtonSearchGeneric onApply={onApply} title={buttonTitle} />
          </InsideDiv>
        </div>
    </Divider>
  )
}
