import React from 'react'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Divider } from 'semantic-ui-react'
import ToolBarContent from '../../../components/Toolbar/ToolBarContent'
import { Quill } from 'react-quill';
import IncludeTag from '../../../components/TextEditor/TagButton/IncludeTag'
import 'react-quill/dist/quill.snow.css';
import './style.css'
import './ToolBarEditor.css'
import {
  changeMessageModal, changeMassiveNotificationModal, closewriteNotificationModal, changeMessageModalTwo, recordsMassiveFileV2,
  warningFormModalWrite
} from '../../../actions/modalActions'
import _ from 'lodash'
import FileContainer from '../../../components/TextEditor/FileContainer'
import { LIMIT_FILE_SIZE } from '../../../helpers/globalHelpers'
import { fileToBase64Clean } from '../../../helpers/filesLib'
import {
  changeTextEditor,
  changeAttButton,
  changeWriteNotificationFormAdd,
  openSendNotificationMassive,
  saveNotification,
  saveNotificationMassiveV2,
  saveNotificationOne,
  saveNotificationMultiple,
  warningModal,
  updateSendNotificationMassiveLoadingModal,
  inactiveFormProvider,
  chageStateLoadNotifications,
  chageStateLoadCitizens,
  chageStateLoadNotificationsList,
  changeIsSendEmail,
} from '../../../actions/writerActions'
import { saveDraftData, saveDraftV2, validateHyperlinks } from '../../../actions/draftsActions';
import { cleanErrorsAction } from '../../../actions/errorsActions';
import BoldIcon from '../../../components/Toolbar/bold.svg'
import ItalicIcon from '../../../components/Toolbar/italic.svg'
import UnderlineIcon from '../../../components/Toolbar/underline.svg'
import AlignCenterIcon from '../../../components/Toolbar/align_center.svg'
import AlignJustifyIcon from '../../../components/Toolbar/align_justify.svg'
import AlignLeftIcon from '../../../components/Toolbar/align_left.svg'
import AlignRightIcon from '../../../components/Toolbar/align_right.svg'
import ColorIcon from '../../../components/Toolbar/color.svg'
import BulletIcon from '../../../components/Toolbar/bullet.svg'
import NumberIcon from '../../../components/Toolbar/number.svg'
import IndentSumIcon from '../../../components/Toolbar/indent1.svg'
import IndentResIcon from '../../../components/Toolbar/indent_1.svg'
import PrimaryButton from '../../../components/PrimaryButton'
import { CHANGE_STATE_MESSAGE_MODAL } from '../../../actions/actionsUtilities/types';
import SpecificFileContainer from '../../../components/TextEditor/SpecificFileContainer';
import { actionClearFilter } from '../../../actions/filterActions';
import { actionSearchBar, inactiveViewMix} from '../../../actions/filterActions'

class WriteTest extends React.Component {

  state = {
    quill: Object,
    bold: false,
    italic: false,
    underline: false,
    align: '',
    header: '',
    color: '',
    list: '',
    indentUL: 1,
    indentOL: 1,
    sending: false,
    fileList: [],
    errorFiles:[],
    filesLoaded: false,
    errorLoadingFiles: false,
    filesLoading: false,
    numberOfFiles: 0,
  }
  quill = null
  componentDidMount = () => {
    const Parchment = Quill.import('parchment')
    const validAlign = {
      right: "end",
      center: "center",
      justify: "auto",
      left: 'start',
    }

    class IndentAttributor extends Parchment.Attributor.Style {
      add(node, value) {
        if (value === 0) {
          this.remove(node)
          return true
        } else {
          if (node.tagName === 'LI') {
            if (value === 1) {
              if (node.parentNode.dataValue < 9) {
                node.parentNode.dataValue++
              }
            } else {
              if (node.parentNode.dataValue > 1) {
                node.parentNode.dataValue--
              }
            }
            node.parentNode.style.paddingLeft = `${node.parentNode.dataValue}em`
          } else {
            return super.add(node, `${value}em`)
          }
        }
      }
    }

    class AlignAttributor extends Parchment.Attributor.Style {
      add(node, value) {
        if (value === 0) {
          this.remove(node)
          return true
        } else {
          node.style.alignSelf = validAlign[value]
          return super.add(node, `${value}`)
        }
      }
    }

    class SizeAttributor extends Parchment.Attributor.Style {
      add(node, value) {
        if (value === 0) {
          this.remove(node)
          return true
        } else {
          return super.add(node, `${value}`)
        }
      }
    }

    let IndentStyle = new IndentAttributor('indent', 'padding-left', {
      scope: Parchment.Scope.BLOCK,
      whitelist: ['1em', '2em', '3em', '4em', '5em', '6em', '7em', '8em', '9em']
    })

    let AlignStyle = new AlignAttributor('align', 'text-align', {
      scope: Parchment.Scope.LIST,
      whitelist: ['center', 'justify', 'left', 'right']
    })

    let SizeStyle = new SizeAttributor('size', 'font-size', {
      scope: Parchment.Scope.INLINE,
      whitelist: ['10px', '16px', '22px', '28px']
    })

    Quill.register(IndentStyle, true)
    Quill.register(AlignStyle, true)
    Quill.register(SizeStyle, true)

    var icons = Quill.import('ui/icons');

    icons['bold'] = `<img src="${BoldIcon}">`
    icons['italic'] = `<img src="${ItalicIcon}">`
    icons['underline'] = `<img src="${UnderlineIcon}">`
    icons['align']['center'] = `<img src="${AlignCenterIcon}">`
    icons['align']['justify'] = `<img src="${AlignJustifyIcon}">`
    icons['align']['left'] = `<img src="${AlignLeftIcon}">`
    icons['align']['right'] = `<img src="${AlignRightIcon}">`
    icons['color'] = `<img src="${ColorIcon}">`
    icons['list']['bullet'] = `<img src="${BulletIcon}">`
    icons['list']['ordered'] = `<img src="${NumberIcon}">`
    icons['indent']['-1'] = `<img src="${IndentResIcon}">`
    icons['indent']['+1'] = `<img src="${IndentSumIcon}">`

    this.quill = new Quill('#editor-container', {
      modules: {
        toolbar: {
          container: '#toolbar',  // Selector for toolbar container
        }
      },
      placeholder: 'Escribe tu mensaje',
      theme: 'snow'
    });

    this.quill.on('text-change', (e) => {
      if (this.quill.root.innerText === "\n") {
        this.props.changeTextEditor("")
      } else {
        this.props.changeTextEditor(this.quill.root.innerHTML)
      }

    });

    this.quill.on('selection-change', (range, oldRange, source) => {
      if (range === null && oldRange !== null) {
        if (!!this.quill.root.innerHTML) {
          this.props.saveDraftData({ description: this.quill.root.innerHTML })
          this.props.saveDraftV2(this.props.draftData)
        }
      }
    });

    this.setState({ quill: this.quill })
    if (!!this.props.textEditor) this.quill.root.innerHTML = this.props.textEditor
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.textEditorFromDraft, this.props.textEditorFromDraft)) {
      this.quill.root.innerHTML = this.props.textEditorFromDraft
    }


    if (!_.isEqual(prevProps.form.files, this.props.form.files) &&
      Object.keys(this.props.form.files).length !== 0) {
      this.props.changeAttButton(this.validateFileSizeTotalFiles(this.props.form.files))
      this.setState({ numberOfFiles:  Object.keys(this.props.form.files).length});
    }

    if (!_.isEqual(prevProps.textEditor, this.props.textEditor)) {
      const editor = document.getElementById("editor-container")
      if (!!editor.querySelectorAll('p')) {
        const p = editor.querySelectorAll('p')
        for (var i = 0; i < p.length; i++) {
          if (p[i].style.fontFamily === "") {
            p[i].style.fontFamily = `Roboto`
          }
        }
      }
      if (!!editor.querySelectorAll('ul')) {
        const ul = editor.querySelectorAll('ul')
        for (var i = 0; i < ul.length; i++) {
          if (ul[i].style.paddingLeft === "") {
            ul[i].style.paddingLeft = `${this.state.indentUL}em`
            ul[i].dataValue = this.state.indentUL
          }

          this.setState({ indentUL: ul[i].dataValue })
          ul[i].style.display = 'flex';
          ul[i].style.flexDirection = 'column'
        }
      }

      if (!!editor.querySelectorAll('ol')) {
        const ol = editor.querySelectorAll('ol')
        for (var i = 0; i < ol.length; i++) {
          if (ol[i].style.paddingLeft === "") {
            ol[i].style.paddingLeft = `${this.state.indentOL}em`
            ol[i].dataValue = this.state.indentOL
          }

          this.setState({ indentOL: ol[i].dataValue })
          ol[i].style.display = 'flex';
          ol[i].style.flexDirection = 'column'
        }
      }

      if (!!editor.querySelectorAll('li')) {
        const li = editor.querySelectorAll('li')
        for (var i = 0; i < li.length; i++) {
          li[i].style.listStyleType = 'inherit'
          if (li[i].style.textAlign === '') {
            li[i].style.alignSelf = 'start'
            this.props.changeTextEditor(this.state.quill.root.innerHTML)
          }
        }
      }
    }

    if (!_.isEqual(prevProps.IsSendEmail, this.props.IsSendEmail)) {
      if (!!this.props.IsSendEmail) {
        this.handleSendEmail()
      }
    }
  }

  onChangeHandlerAttachment = async (e) => {
    const myFiles = e.target.files,
      filesTemp = Array.prototype.slice.call(myFiles, 0),
      validateFileSize = this.validateFileSize(filesTemp),
      validateFileType = this.validateFileType(filesTemp),
      validateFileName = this.validateFileName(this.props.form.files, filesTemp)

    
    if (!validateFileSize) {
      if (!validateFileType) {
        await this.updateFileState(filesTemp, this.props.form.files, validateFileName.isLongName)
      } else {
        this.props.changeMessageModal({
          type: 'warning',
          message: 'Solo son permitidos documentos PDF.'
        })
      }
    } else {
      this.props.changeMessageModal({
        type: 'warningFiles',
        message: [`El archivo supera el tamaño máximo permitido`, `Ten presente que el peso total de los adjuntos no puede superar los 10 MB`]
      })
    }
  }

  validateFileSize = files =>
    files.find(v => v.size === 0 || v.size > LIMIT_FILE_SIZE)

  validateFileSizeTotalFiles = files => {
    let sizeTotal = 0
    if (Object.keys(files).length >= 5)
      return true
    Object.keys(files).map((row) => (
      sizeTotal += files[row].size
    ))
    return sizeTotal > LIMIT_FILE_SIZE
  }

  numberOfPropFiles = files => {
    return Object.keys(files).length
  }

  getFileSizePropFiles = files => {
    let sizeTotal = 0
    Object.keys(files).map((row) => (
      sizeTotal += files[row].size
    ))
    return sizeTotal
  }

  validateFileName = (files, newFile) => {
    let isFileNameRepeated = false
    let nameRepeated = ''
    let isLongName = false
    if (newFile[0].name.length > 120) {
      isLongName = true
    }
    Object.keys(files).map((row) => {
      newFile.forEach(file => {
        if (files[row].name === file.name) {
          nameRepeated = file.name
          isFileNameRepeated = true
        }
      })
    })
    return { isFileNameRepeated, nameRepeated, isLongName }
  }

  validateFileType = files =>
    files.find(v => v.type !== 'application/pdf')


  updateFileState = async (files, propFiles, isLongName) => {
    const descriptionFile = []
    let i = 0,
      totalSizeNewFiles = 0,
      finalSize,
      numberOfFiles
    if (this.state.numberOfFiles + files.length <= 5) {
        for (let file of files) {
            let uniqueFileName = file.name;
            let counter = 1;
            
            
            while (this.validateFileName(this.props.form.files, [{ name: uniqueFileName }]).isFileNameRepeated) {
                const extensionIndex = file.name.lastIndexOf('.');
                const name = file.name.substring(0, extensionIndex);
                const extension = file.name.substring(extensionIndex);
                uniqueFileName = `${name}(${counter})${extension}`;
                counter++;
            }

            descriptionFile.push({
                base64: await fileToBase64Clean(file.name, file),
                type: file.type,
                name: uniqueFileName,
                sign: false,
                size: file.size,
                id: `documentSignature-${i}-${Date.now()}`,
                loaded: false,
                isLongName: isLongName
            });
            i++;
            totalSizeNewFiles += file.size;
        }

        finalSize = totalSizeNewFiles + this.getFileSizePropFiles(propFiles);
        numberOfFiles = i + this.numberOfPropFiles(propFiles) + this.props.specificFiles.length;
        this.setState({ numberOfFiles });
    } else {
      this.setState({ errorLoadingFiles: true })
      this.props.changeMessageModal({
        type: 'warning',
        message: `El número de archivos supera el máximo permitido`
      })
    }
    if (numberOfFiles <= 5) {
      if (finalSize < LIMIT_FILE_SIZE) {
        this.setState({ errorLoadingFiles: false })
        this.setState(prevstate =>({ fileList: [...prevstate.fileList, ...descriptionFile] }));
        this.validatePDFHyperlinks(descriptionFile)
      } else {
        this.setState({ errorLoadingFiles: true })
        this.props.changeMessageModal({
          type: 'warningFiles',
          message: [`El archivo supera el tamaño máximo permitido`, `Ten presente que el peso total de los adjuntos no puede superar los 10 MB`]
        })
      }
    }
  }

  deleteChargingFile = (index) => {
    let list = this.state.errorFiles.map(file => file)
    list.splice(index, 1)
    this.setState({ errorFiles: list })
    this.props.saveDraftData({ attachments: this.requestsPropFiles() })
  }

  validatePDFHyperlinks = (files) => {
    this.props.cleanErrorsAction()
    this.props.saveDraftData({
      attachments: [...Object.keys(files)?.map(key => ({
        "fileName": files[key].name,
        "fileOriginalName": files[key].name,
        "mimeType": files[key].type,
        "fileExtension": "pdf",
        "size": files[key].size,
        "requiredSignature": files[key].sign,
        "base64": files[key].base64
      })), ...this.requestsPropFiles()]
    })
    const { draftData } = this.props;


    this.props.validateHyperlinks(draftData).then(() => {
      if (!!this.props.formError.message) {
        if (this.props.formError.message.includes('hipervinculos')) {
          this.props.changeMessageModalTwo({
            type: 'warningFiles',
            message: ['Por seguridad, no es posible adjuntar archivos que contengan hipervínculos', 'Elimina todos los hipervínculos de los archivos y vuelve a intentarlo.', 'Para mayor información comunícate con tu administrador.']
          })
          if (this.props.formError.linkFiles) {
            this.setState({ filesLoaded: true })
            setTimeout(async () => {
              const errorFiles = files.filter(file => this.props.formError.linkFiles.includes(file.name) )
              this.setState(prevState => ({errorFiles: [...prevState.errorFiles,...errorFiles.filter(file => !prevState.errorFiles.some(errorFile => errorFile.name === file.name))]})) 
              this.setState({fileList: []})
              const filesCharged = files.filter(file => !this.props.formError.linkFiles.includes(file.name))
              const numberOfFiles = this.state.numberOfFiles - filesCharged.length
              this.setState({ numberOfFiles:this.state.numberOfFiles - errorFiles.length });
              const filesObject = _.keyBy(filesCharged, 'id')
              await this.props.changeWriteNotificationFormAdd('files', filesObject)
            }, 1000)
          }
          this.setState({ errorLoadingFiles: true })
        }
      } else {
        this.setState({ filesLoaded: true })
        setTimeout(async () => {
          this.setState({ fileList: [] })
          const filesObject = _.keyBy(files, 'id')
          await this.props.changeWriteNotificationFormAdd('files', filesObject)
        }, 1000)
      }
    })

  }
  requestsPropFiles = () => {
    const { form } = this.props
    return Object.keys(form.files)?.map(key => ({
      "fileName": form.files[key].name,
      "fileOriginalName": form.files[key].name,
      "mimeType": form.files[key].type,
      "fileExtension": "pdf",
      "size": form.files[key].size,
      "requiredSignature": form.files[key].sign,
      "base64": form.files[key].base64,
      "fileUrl": !form.files[key].fileUrl ? null : form.files[key].fileUrl
    }))
  }

  validateChecksNotificationType = () => {
    const { notificationSignature, readReceipt, pushNotification, documentSignature } = this.props.form
    if (!!this.props.servicesList.notificationSignature || !!this.props.servicesList.notificationReading || this.props.servicesList.pushNotification || (this.props.servicesList.documentSignature && !_.isEmpty(this.props.files))) {
      if (!notificationSignature && !readReceipt && !pushNotification && !documentSignature && !this.props.checkboxHeaderDisabled) {
        this.props.warningFormModalWrite({ type: 'warningSendEmail' })
      } else {
        this.props.changeIsSendEmail(true)
      }
    } else {
      this.props.changeIsSendEmail(true)
    }
  }

  handleValidateSendEmail = () => {
    const { form } = this.props
    this.props.validateSubject()
    if (form.recipient.length === 0 || form.subject === "" || form.textEditor === "" ) {
      this.props.warningModal()
    } else if(this.props.stateFormValidator){   
      this.validateChecksNotificationType()
    }
  }

  handleSendEmail = async () => {
    if (this.props.isMassiveRecipientValue) {
      if ((this.props.form.notificationCount <= this.props.hiredData.availableQuantity) || this.props.hiredData.availableQuantity === null) {
        this.props.changeMassiveNotificationModal().then(() => {
          this.props.inactiveFormProvider()
          this.props.chageStateLoadNotifications(true)
          this.props.chageStateLoadNotificationsList(true)
          for (let i = 0; i < this.props.resultRecordsFileMassive.length; i++) {
            const element = this.props.resultRecordsFileMassive[i];
            if (element.status === 'En progreso' || element.status === 'En espera') {
              this.props.recordsMassiveFileV2({ companyID: this.props.companyID })
              this.props.chageStateLoadCitizens(true)
              break
            }
          }
        })
        await this.handleSendEmailMassive()
      } else {

      }
    } else {
      if (this.props.listIdsSendMassive.backendRequest.length > 1) {
        const body = this.makeSaveMultipleNotificationReq()
        this.setState({ sending: true })
        this.props.saveNotificationMultiple(body).then(() => {
          this.props.history.push("/sent")
          this.setState({ sending: false })
        })
      } else {
        const body = this.makeSaveNotificationReq()
        this.setState({ sending: true })
        this.props.saveNotificationOne(body).then(() => {
          this.props.history.push("/sent")
          this.setState({ sending: false })
        })
      }
    }
    this.props.actionClearFilter()
  }

  makeSaveNotificationReq = () => {
    const { form } = this.props
    const attachedFiles = this.requestsPropFiles()
    return {
      requiredSignature: form.notificationSignature,
      requiredConfirmationRead: form.readReceipt,
      isPushNotification: form.pushNotification,
      subject: form.subject,
      description: form.textEditor,
      documentNumberRecipient: this.props.listIdsSendMassive.ids,
      attachments: attachedFiles,
      draftSerialID: this.props.draftSerialID !== "" && this.props.draftSerialID
    }
  }
  makeSaveMultipleNotificationReq = () => {
    const { form } = this.props
    const attachedFiles = this.requestsPropFiles()
    return {
      requiredSignature: form.notificationSignature,
      requiredConfirmationRead: form.readReceipt,
      isPushNotification: form.pushNotification,
      subject: form.subject,
      description: form.textEditor,
      attachments: attachedFiles,
      campaignName: ' ',
      arrayDocumentNumbers: this.props.listIdsSendMassive.ids,
      draftSerialID: this.props.draftSerialID !== "" && this.props.draftSerialID
    }
  }

  decrementNumberOfFiles = () => {
    // this.setState(prevState => ({
    //   numberOfFiles: prevState.numberOfFiles - 1,
    // }));
  };

  makeSaveNotificationReqMassive = () => {
    const { form } = this.props
    const attachedFiles = this.requestsPropFiles()
    return {
      requiredSignature: form.notificationSignature,
      requiredConfirmationRead: form.readReceipt,
      isPushNotification: form.pushNotification,
      subject: form.subject,
      description: form.textEditor,
      fileSerialID: this.props.fileSerialID,
      attachments: attachedFiles,
      draftSerialID: this.props.draftSerialID !== "" && this.props.draftSerialID
    }
  }

  calculateNotificationsSent = (notificationsSent, totalNotifications) => {
    return (notificationsSent / totalNotifications) * 100
  }

  handleSendEmailMassive = async () => {
    const body = this.makeSaveNotificationReqMassive()
    await this.props.openSendNotificationMassive()
    if (this.props.loadingSendNotificationMassive.abortSend) {
      this.props.changeMessageModal({ type: 'error' })
    } else {
      await this.props.saveNotificationMassiveV2(body).then(() => {
        this.props.history.push("/sent")
        setTimeout(() => {
          this.props.chageStateLoadNotifications(true)
          this.props.chageStateLoadNotificationsList(true)
        }, 2000)
      })
    }
  }



  render() {
    return (
      <>
        <IncludeTag quill={this.state.quill} />
        <div id="editor-container">
        </div>
        <div id="container-files">
        <SpecificFileContainer filesCharging={this.state.fileList} errorFiles={this.state.errorFiles} filesLoaded={this.state.filesLoaded} errorLoadingFiles={this.state.errorLoadingFiles} deleteChargingFile={this.deleteChargingFile} decrementNumberOfFiles={this.decrementNumberOfFiles} />
        </div>
        <div class="contentToolbarAndButton">
          <ToolBarContent
            disableAtt={this.state.numberOfFiles >= 5 || Object.keys(this.props.files).length + Object.keys(this.props.specificFiles).length >= 5 ? true : this.props.disableAtt}
            onChangeHandlerAttachment={this.onChangeHandlerAttachment}
          />
          <Divider />
          <div className='divCustomPrimaryButton'>
            <PrimaryButton
              loading={this.props.loadingSendNotification}
              className='customPrimaryButton'
              txtBtn={this.state.sending ? 'Enviando' : 'Enviar'}
              onPress={this.handleValidateSendEmail}
              disable={this.state.sending || (this.state.fileList.length > 0)}
              sending={this.state.sending}
            />

          </div>
        </div>
      </>
    )
  }
}

WriteTest.propsTypes = {
  changeTextEditor: PropTypes.func,
  disableAtt: PropTypes.bool,
  changeAttButton: PropTypes.func,
  changeWriteNotificationFormAdd: PropTypes.func,
  changeMessageModal: PropTypes.func,
  isMassiveRecipientValue: PropTypes.bool,
  openSendNotificationMassive: PropTypes.func,
  updateSendNotificationMassiveLoadingModal: PropTypes.func,
  loadingSendNotificationMassive: PropTypes.object,
  listIdsSendMassive: PropTypes.object,
  saveNotificationMassiveV2: PropTypes.func,
  saveNotification: PropTypes.func,
  closewriteNotificationModal: PropTypes.func,
  inactiveFormProvider: PropTypes.func,
  changeMessageModalTwo: PropTypes.func,
  chageStateLoadNotifications: PropTypes.func,
  chageStateLoadCitizens: PropTypes.func,
  chageStateLoadNotificationsList: PropTypes.func,
  recordsMassiveFileV2: PropTypes.func,
  warningFormModalWrite: PropTypes.func,
  changeIsSendEmail: PropTypes.func,
}
const mapDispatchToProps = {
  changeTextEditor,
  actionClearFilter,
  changeAttButton: changeAttButton,
  changeWriteNotificationFormAdd: changeWriteNotificationFormAdd,
  changeMessageModal,
  changeMassiveNotificationModal,
  openSendNotificationMassive,
  updateSendNotificationMassiveLoadingModal,
  warningModal,
  saveNotificationMassiveV2,
  saveNotification,
  saveNotificationOne,
  saveNotificationMultiple,
  closewriteNotificationModal,
  inactiveFormProvider,
  saveDraftData,
  saveDraftV2,
  changeMessageModalTwo,
  chageStateLoadNotifications,
  chageStateLoadCitizens,
  chageStateLoadNotificationsList,
  recordsMassiveFileV2,
  warningFormModalWrite,
  changeIsSendEmail,
  cleanErrorsAction,
  validateHyperlinks
}

const mapStateToProps = state => ({
  disableAtt: state.writerReducer.disableAtt,
  form: state.writerReducer.notificationForm,
  files: state.writerReducer.notificationForm.files,
  specificFiles: state.writerReducer.listDocumentsSpecific,
  isMassiveRecipientValue: state.writerReducer.isMassiveRecipient,
  loadingSendNotificationMassive: state.writerReducer.loadingSendNotificationMassive,
  listIdsSendMassive: state.writerReducer.listIdsSendMassive,
  textEditor: state.writerReducer.notificationForm.textEditor,
  textEditorFromDraft: state.writerReducer.notificationForm.textEditorFromDraft,
  fileSerialID: state.writerReducer.fileSerialID,
  draftData: state.draftReducer.toSaveDraft,
  draftSerialID: state.writerReducer.draftSerialID,
  resultRecordsFileMassive: state.writerReducer.resultRecordFileMassive.listFileSearchMassive,
  companyID: state.authReducer.user.companyID._id,
  IsSendEmail: state.writerReducer.IsSendEmail,
  checkboxHeaderDisabled: state.writerReducer.checkboxHeaderDisabled,
  formError: state.errorReducer.errorsForm,
  servicesList: state.writerReducer.hiredData.servicesList,
  hiredData: state.writerReducer.hiredData,
})

export default connect(mapStateToProps, mapDispatchToProps)(WriteTest)
