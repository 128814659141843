import React, { useState } from "react"
import PropTypes from "prop-types"
import { Dropdown, Form } from "semantic-ui-react"
import styled from 'styled-components'

import { useDispatch } from "react-redux"
import { activeFormClient } from "../../../actions/clientActions"

const Divider = styled.div`
  display: flex;
`
const FormCustom = styled(Form.Select)`
  heigth: 47px !important;
  border:none !important;
  .dropdown.icon{
    display: none !important;
  }
`

const InputFormDropdown = ({ ...props }) => {

    const dispatch = useDispatch()
    const { placeholder, options, value, id, handleOnChange, validator, validateOptions, disabled =false , searchQuery,onFocus } = props
    const [selected, setSelected] = useState(false);

    let errorValidator = null;
    if (validator && validateOptions) errorValidator = validator.message('', value, validateOptions);

    const validator2 = (value) => {
        if (value !== "") {
            dispatch(activeFormClient())
        }
    }
    return (
        <div className="container-dropdown">
            <Dropdown
                name={id}
                selection
                search
                fluid
                options={options}
                className={'inputDropDownFormEntities'}
                value={value}
                onChange={handleOnChange}
                id={id}
                icon={false}
                autoComplete='off'
                style={{border:'none'}}
                disabled={disabled}
                noResultsMessage={'No se encuentran resultados'}
                onFocus={()=>{
                                setSelected(true)
                                onFocus()}
                            }
                onBlur={()=>setSelected(false)}
                
            />
            <label className={errorValidator ? 'labelInputFormEntitiesError' : 'labelDropDownFormEntities'} >
                {errorValidator && searchQuery === '' ?
                    <>
                        <label className={errorValidator && value === '' && 'spanLabelInputTopError'}>{placeholder}</label>
                        <label className={(errorValidator) &&"input-error3"}>{errorValidator}</label>
                    </>
                    :
                    <span className={searchQuery !== '' || value !== '' || selected ? 'labelInputDropdownFormEntitiesTop' : 'labelInputDropdownFormEntities'} style={{ display: 'flex', alignItems: 'center', gap:'8px',zIndex:'5'}}>{searchQuery === '' && value === ''}{placeholder}</span>
                }
            </label>
        </div>
    )
}


InputFormDropdown.propTypes = {
    labelProps: PropTypes.object,
    id: PropTypes.string,
}
export default InputFormDropdown