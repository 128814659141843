import React, { useEffect, useRef, useState } from "react"
import { Element, scroller } from "react-scroll";
import { animateScroll as scroll} from "react-scroll";
import PropTypes from "prop-types"
import styled from 'styled-components'

import './input-from-agreements.css'
import './inputSelect.css'
import { useDispatch } from "react-redux"
import { activeFormAgreement } from "../../../actions/agreementActions"
import { ReactComponent as ArrowIcon } from '../../../assets/icons/agreement/arrow.svg'
import { Dropdown, options } from "semantic-ui-react";

const Divider = styled.div`
  display: flex;
`
const Text = styled.p`
  padding-top: 15px;
  padding-left: 15px;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #000000;
`
const ButtonIcon = styled.button`
  position: absolute; 
  left: 90%; 
  top: 10px;
  background: #FFFF;
  border: none;
  cursor:pointer;
`
const List = styled.div`
  max-height: 200px;
`
const ContainerTextList = styled.div`
  cursor: pointer;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;

  &:hover {
    background: #E4E4E4
  }
`
const TextListContainer = styled.div`
  cursor: pointer;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;

  &:hover {
    background: #E4E4E4
  }
`
const ContainerDropDown = styled(Dropdown.Menu)`
  padding: 0px 10px !important;
`
const TextList = styled(Dropdown.Item)`
  cursor: pointer;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  padding:10px;
  &:hover {
    background: #E4E4E4
  }
`
const Divider2 = styled.div`
  display: block;
  margin-top: -10px;
  padding-bottom: 8px;
  padding-top: 8px;
  margin: auto;
  background: transparent;
  width: 95%;
  z-index: 30;
`
const InputSelect = ({ ...props }) => {

    const dispatch = useDispatch()
    const myRef=  useRef(null)
    const inputRef=  useRef(null)
    const menuList = useRef(null)
    const [stateList, setStateList] = useState(false)
    const [newData, setNewData] = useState('')
    const [searchProviderAndClient, setSearchProviderAndClient] = useState('')
    const [name, setName] = useState('')
    const [open, setOpen] = useState(false)
    const { placeholder, options, value, idName, data, handleOnChange, validator, validateOptions, disabled, label, errorClient, errorProvider } = props
    let errorValidator = null;
    if (validator && validateOptions) errorValidator = validator.message('', value, validateOptions);
    const validator2 = (value) => {
        if (value !== "") {
            dispatch(activeFormAgreement())
        }
    }

    const clickList = () => {
        setStateList(!stateList)
    }
    const onChange = e => {
        setSearchProviderAndClient(e.target.value)
        validator2(value)
    }

    const onKeyDown = e => {
      switch(e.key){
        case 'Enter': setOpen(false)
        break;
        case 'Space':
          setSearchProviderAndClient(searchProviderAndClient+e.key)
          validator2(value)
        case 'Tab': 
        break;
        case 'ArrowDown': 
        break;
        case 'ArrowUp': 
        break;
        default: e.stopPropagation()
      }
    }

    const handleOnclickSearch = (e) => {
      e.stopPropagation()
    }

    const handleOnClickList = (e) => {
      e.stopPropagation()
      setOpen(false)
      
    }

    const handleOnClickDropDown = (e) => {
      setOpen(!open)
    }


    const handleOnFocus= (e) => {
      setOpen(true)
    }

    const handleOnBlur= (e) => {
        setOpen(false)
        
    }

    const handleInputOnFocus = (e) =>{
      e.stopPropagation()
      setOpen(true)
    }

    const handleKeyDownList= (e) => {
      switch(e.key){
        case 'Enter': setOpen(false)
        break;
        case 'Tab': 
        break;
        case 'ArrowDown': 
        break;
        case 'ArrowUp': 
        break;
        default: e.preventDefault()
      }
    }

    useEffect(() => {
        if(data){
            data.map((x) => {
            if (x.id === value) {
                setName(x.text)
            }})
        }
    }, [value, data])

    useEffect(() => {
        if(searchProviderAndClient !== ''){
        const text =  searchProviderAndClient.toLocaleLowerCase()
        let dataFilter = []
        for(let nameProviderAndClient of data) {
            let name = nameProviderAndClient.text.toLocaleLowerCase()
            if(name.indexOf(text) !== -1){
              dataFilter.push({text:nameProviderAndClient.text, id:nameProviderAndClient.key, value: nameProviderAndClient.value })
            }
        }
        setNewData(dataFilter)
    }
    }, [searchProviderAndClient])

    useEffect(() => {
      if(open){
        setTimeout(() => {
          inputRef.current.focus();
          setOpen(true)
        }, 70);
      }
      
  }, [open]);

    return (
        <>
        <Divider  onClick={clickList} className={ errorClient === true || errorProvider === true ? 'divInputDropdownSelectFormEntitiesError' : 'divInputDropdownFormEntities'}>
            { errorClient === true || errorProvider === true  ?
                <>
                    <label className={ (errorClient === true || errorProvider === true)  && value === '' && (!!open) ? 'spanLabelDropDownSelectOpenTopError' :'spanLabelDropDownSelectTopError'}>{placeholder}</label>
                    <label onClick={handleOnClickDropDown} className={( errorClient === true || errorProvider === true ) && "input-error3"}>Campo obligatorio</label>
                </>
                :
                <span onClick={handleOnClickDropDown} className={value !== '' ? 'labelInputDropdownFormEntitiesTop' : 'labelInputDropdownFormEntities'} >{placeholder}</span>
            }
            
            { searchProviderAndClient !== '' && newData ?
              <Dropdown
              upward={false}
              selection
              options={newData}
              className={errorClient === true || errorProvider === true ? 'inputDropdownFormEntitiesError':'inputDropdownFormEntities agreementList'}
              value={value}
              id={idName}
              onChange={handleOnChange}
              onFocus={handleOnFocus}
              onClick={handleOnClickDropDown}
              onBlur={e => handleOnBlur(e)}
              onKeyDown={handleKeyDownList}
              autoComplete='off'
              ref={myRef}
              search={true}
              open={open}
              style={{ paddingBottom: "29px"}}
              >
                <Dropdown.Menu >
                  <Divider2
                  >
                    <input
                      id='searchProviderAndClient'
                      className='inputListSearch'
                      type='text'
                      value={searchProviderAndClient}
                      onChange={onChange}
                      onKeyDown= {onKeyDown}
                      onClick={handleOnclickSearch}
                      autoComplete='off'
                      name={props.name}
                      ref={inputRef}
                    />
                  </Divider2>
                  <List ref={menuList}>
                    <ContainerDropDown scrolling>
                        {newData.map((option, index) => (
                          <TextListContainer onClick={handleOnClickList}>
                            <TextList onClick={handleOnChange} key={index} name={option.value} text={option.text}/>
                          </TextListContainer>
                        ))}
                    </ContainerDropDown>
                  </List>
                </Dropdown.Menu>
              </Dropdown>
            :<Dropdown
                upward={false}
                selection
                options={data}
                className={errorClient === true || errorProvider === true ? 'inputDropdownFormEntitiesError':'inputDropdownFormEntities agreementList'}
                value={value}
                id={idName}
                onChange={handleOnChange}
                onFocus={handleOnFocus}
                onClick={handleOnClickDropDown}
                onBlur={e => handleOnBlur(e)}
                onKeyDown={handleKeyDownList}
                autoComplete='on'
                ref={myRef}
                search={true}
                open={open}
                style={{ paddingBottom: "29px" }} 
                >
                  <Dropdown.Menu style={{overflowY: 'hidden'}}>
                    <Divider2 
                    >
                      <input
                        id='searchProviderAndClient'
                        className='inputListSearch'
                        type='text'
                        value={searchProviderAndClient}
                        onChange={onChange}
                        onKeyDown= {onKeyDown}
                        onClick={handleOnclickSearch}
                        autoComplete='off'
                        name={props.name}
                        ref={inputRef}
                      />
                    </Divider2>
                    <List ref={menuList}>
                      <ContainerDropDown scrolling>
                          {data.map((option, index) => (
                            <TextListContainer onClick={handleOnClickList}>
                              <TextList onClick={handleOnChange} key={index} name={option.value} text={option.text}/>
                            </TextListContainer>
                          ))}
                      </ContainerDropDown>
                    </List>
                  </Dropdown.Menu>
                </Dropdown>
              }
        </Divider>
        </>
    )
}


InputSelect.propTypes = {
    labelProps: PropTypes.object,
    id: PropTypes.string,
}
export default InputSelect