import axios from 'axios'
import {
  GET_USER_DOCUMENTS,
  ACTIVE_VIEW_GRID,
  ACTIVE_VIEW_LIST,
  INACTIVE_VIEW_GRID,
  INACTIVE_VIEW_LIST,
  POST_STARRED,
  VIEW_PAGINATION,
  DETAIL_OF_ATTACHMENT,
  SET_USER_CASE_NOT_FOUND,
  VIEW_PAGINATION_INTERNAL,
} from './actionsUtilities/types.js'
import { API_ENDPOINT, API_ENDPOINT_NOTIFICATION_DEV } from "../config/config"
import { EXPORT_USER_DOCUMENTS, GET_DETAIL_OF_ATTACHMENT_V2, GET_LIST_FILE_AND_ATTACHMENTS, GET_LIST_FILE_AND_ATTACHMENTS_V2, STARRE_DOCUMENT } from './actionsUtilities/actionsRoutes.js'
import { reasonDispatch } from './DispatchGeneric.js'
import { getDataCypher, getDataDecypher } from '../helpers/dataCypher.js'
import { detailAttachmentsV2 } from './seeAttachmentAction.js'
import { decrypt, encrypt } from '../helpers/cypher.js'
import { getHeaders } from '../helpers/globalHelpers.js'


export const getUserDocumentsV2 = (body, stateInitial) => async (dispatch) => {
  const encryptedBody = await encrypt(body);
  await axios.post(
    `${API_ENDPOINT + GET_LIST_FILE_AND_ATTACHMENTS_V2}`,
    { data: encryptedBody},
    { headers: getHeaders() }
  ).then(async res => {
    const decryptedData = await decrypt(res.data.result);
    dispatch({
      type: GET_USER_DOCUMENTS,
      payload: decryptedData
    })
    dispatch({
      type: VIEW_PAGINATION,
      payload: {
        total: decryptedData.elementsCount,
      }
    })
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
  })
}

export const exportDocumentsToPDF = (body) => async (dispatch) => {
  const headers = { 'Authorization': sessionStorage.getItem('jwtToken') }
  await axios.post(
    `${API_ENDPOINT + EXPORT_USER_DOCUMENTS}`,
    body,
    { headers }
  ).then(res => {
    const { result } = res.data;

    const link = document.createElement("a");
        link.href = result.url;
        link.setAttribute("download", "archivos_adjuntos.zip");
        document.body.appendChild(link);
        link.click();
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
  })
}

export const activeViewGrid = () => async (dispatch) => {
  dispatch({
    type: ACTIVE_VIEW_GRID,
  })
}

export const activeViewList = () => async (dispatch) => {
  dispatch({
    type: ACTIVE_VIEW_LIST,
  })
}

export const inactiveViewGrid = () => async (dispatch) => {
  dispatch({
    type: INACTIVE_VIEW_GRID,
  })
}

export const inactiveViewList = () => async (dispatch) => {
  dispatch({
    type: INACTIVE_VIEW_LIST,
  })
}

export const userDocumentStarred = (body) => async (dispatch) => {
 
  const encryptedBody = await encrypt(body);
  await axios.post(
      `${API_ENDPOINT + STARRE_DOCUMENT}`,
      { data:encryptedBody}, { headers: getHeaders()}
  ).then(async res => {
    const decryptedData = await decrypt(res.data.result);
      dispatch({
          type: POST_STARRED,
          payload: decryptedData
      })
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
  })
}


export const reloadDocumentStarred = (body) => async (dispatch) =>{
  dispatch({
    type:SET_USER_CASE_NOT_FOUND,
    payload:body.to
  })
  const encryptedBody = await encrypt(body);
  await axios.post(`${API_ENDPOINT + GET_DETAIL_OF_ATTACHMENT_V2}`,  { data:encryptedBody}, { headers: getHeaders()}).then(async (res) => {
    if (res.status === 200) {
      const decryptedData = await decrypt(res.data.result);
      dispatch({
        type: DETAIL_OF_ATTACHMENT,
        payload: decryptedData.resultFinal
      })
      if(decryptedData.success){
        dispatch({
          type: VIEW_PAGINATION_INTERNAL,
          payload: {
              count: decryptedData.resultFinal.elementsCountPage,
              total: decryptedData.resultFinal.elementsCount,
          }
        })
      }else{
        dispatch({
          type: VIEW_PAGINATION_INTERNAL,
          payload: {
              total: 0,
          }
        })
        console.log("no resultados")
        dispatch({
          type:SET_USER_CASE_NOT_FOUND,
          payload:body.to
        })

        dispatch({
          type: VIEW_PAGINATION,
          payload: {
              total: undefined,
          }
        })
      }
    }
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
  })
}