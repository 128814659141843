import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import _ from 'lodash'

import {actionClearFilter, actionInitialPagination, activePagination} from '../../../../actions/filterActions'
import GeneralTable from '../../../../components/Table/GeneralTable'
import RowTableAgreementsClient from "../RowTableAgreementsClient";
import {agreementListV2} from "../../../../actions/agreementActions";

class AllAgreementsClient extends Component {

  state = {
    loader: true,
    columnData: [
      {name: "Código", width: 2, arrow: false},
      {name: "Operador", width: 3, arrow: false},
      {name: "Fecha suscripción", width: 3, arrow: false},
      {name: "Fecha inicio", width: 3, arrow: false},
      {name: "Fecha fin", width: 3, arrow: false},
      {name: "Estado", width: 3, arrow: false},
    ],
    init: true,
  };

  componentDidMount() {
    this.makeAgreementRequest(this.props.filterReducer)
  };

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.filterReducer, this.props.filterReducer)) {
      this.makeAgreementRequest(this.props.filterReducer)
      this.setState({init: true, loader: true })
    }
  };

  makeAgreementRequest = (filterReducer) => {
    const body = {
      count: filterReducer.count,
      pag:  filterReducer.pag,
      status: filterReducer.agreementFilter.agreementStatus,
      sort: this.orderTranslator(filterReducer.sort),
      textToSearch: filterReducer.searchBar[0]
    }

    this.props.agreementListV2(body).then(() => {
      this.props.activePagination(false)
      this.setState({init: false, loader: false })
    })
  }

  orderTranslator = (order) => {
    const options = {
      'masReciente': {"_id": -1},
      'masAntiguo': {"_id": 1},
      'A-Z': {"agreementCode": 1},
      'Z-A': {"agreementCode": -1}
    }
    return options[order]
  };

  render() {
    return (
      <GeneralTable
        list={this.props.agreementListArray}
        typeList={this.props.filterReducer.isSearch === 0 ? 'default' : 'convetions'}
        listHeight={'agreements'}
        headerTable={this.state.columnData}
        rowComponent={RowTableAgreementsClient}
        history={this.props.history}
        loader={this.state.loader}/>
    )
  }
}

AllAgreementsClient.propTypes = {
  agreementListV2: PropTypes.func.isRequired,
  agreementListArray: PropTypes.array.isRequired,
  history: PropTypes.any.isRequired,
  actionClearFilter: PropTypes.func,
  actionInitialPagination: PropTypes.func,
  elementsCount: PropTypes.any,
  filterReducer: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  agreementListArray: state.agreementReducer.agreementListArray,
  elementsCount: state.agreementReducer.elementsCount,
  filterReducer: state.filterReducer,
});

const mapDispatchToProps = {
  agreementListV2,
  actionClearFilter,
  actionInitialPagination,
  activePagination
};

export default connect(mapStateToProps, mapDispatchToProps)(AllAgreementsClient)