import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { Form, Message } from 'semantic-ui-react';
import styled from 'styled-components';
import './loginCertificate.css';

import { listCertificate, validateCertificate } from '../../actions/authActions';
import { reactValidatorOptions } from '../../helpers/simpleReactValidator';
import ImageSegment from '../../components/Sengment/ImageSegment';
import PrimaryButton from '../../components/PrimaryButton';
import InputFormDropdownLogin from '../../components/InputLogin/InputFormDropdownLogin';
import InputForm from '../../components/InputLogin/InputForm';
import ModalGeneral from '../../components/ModalLogin/ModalGeneral';
import ModalLoader from '../../components/ModalLogin/ModalLoader';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { createSharedKey, generateKeyTosessionStorage } from '../../helpers/cypher';
import { hexToBase64 } from '../../helpers/globalHelpers';
import { CLEAR_ROLES } from '../../actions/actionsUtilities/types';

const CustomButton = styled(PrimaryButton)`
  background: #FFFFFF !important;
  border-radius: 4px !important;
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  text-align: center !important;
  border: 1px solid #22C1D0 !important;
  color: #22C1D0 !important;
  padding: 16px 10px !important;;
  width: 150px !important;
  @media only screen and (max-width: 460px){
  margin-right: 5px !important; 
  }
`
const CustomPrimaryButton = styled(PrimaryButton)`
  background: #22C1D0 !important;
  border-radius: 4px !important;
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  text-align: center !important;
  color: #FFFFFF !important;
  padding: 16px px !important;;
  width: 150px !important;
  @media only screen and (max-width: 460px){
  margin-left: 5px !important; 
  }
`
const LoginForm = styled.div`
  height: 80%;
`
const CustomForm = styled(Form)`
  display: flex !important;
  flex-direction: column !important;
  background-color: #fff;
  align-items: center !important;
  & > * {
    margin: 10px 0 !important;
  }
`
const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #133F4F;
`
const MessageLogin = styled(Message)`
  width: 100% !important;
  text-align: center !important;
  letter-spacing: 0 !important;
  background: rgba(186, 53, 85, 0.1) !important;
  border: 1px solid #BA3555 !important;
  box-sizing: border-box !important;
  border-radius: 4px;
  color: #BA3555 !important;
  margin: 10px !important;
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;
`
const NameCertificate = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  width: 361px;
  height: 18px;
  overflow: hidden;
  margin: 16px 0;
  @media only screen and (max-width: 482px ){
    width: 100%;
  }
`

const ActionsConatainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  width: 100%;
`

const LoginWithCertificate = () => {

  const [state, setState] = useState({
    listCertificatesUser: [],
    listRolesUser: [],
    certificate: '',
    idUserCertificate: '',
    password: '',
    rol: '',
    errors: {
      error: '',
      message: '',
      username: '',
      password: ''
    }
  });
  const { userId, key } = useParams()
  const customEqual = (oldValue, newValue) => oldValue === newValue
  const { certificates, numberLogin } = useSelector(state => state.authReducer, customEqual)
  var errorReducer = useSelector(state => state.errorReducer, customEqual)
  const loginValidator = new SimpleReactValidator(reactValidatorOptions);
  const history = useHistory()
  const dispatch = useDispatch()
  const [isFirstLogin, setIsFirstLogin] = useState(false)
  
  // useEffect(() => {
  //   dispatch(checkLoginStatus())
  // }, [])

  useEffect(() => {
    if (!!key) {
      (async () => {
        const keyBase64 = hexToBase64(key)
        await generateKeyTosessionStorage().then(async () => {
          sessionStorage.setItem('pubKeyServer', keyBase64)
          await createSharedKey(keyBase64)
        })
        setIsFirstLogin(true)
      })();
    }
  }, [key])

  useEffect(() => {
    if (certificates.length === 0) {
      if (numberLogin !== 2) {
        dispatch(listCertificate(userId)).then(() => {
          if (certificates.length >= 2) {
            const certificatesList = certificates.map((x) => ({
              key: x.userSerialID,
              text: x.nameCertificate,
              value: x.idCertificate + ',' + x.userSerialID
            }));
            certificatesList.unshift({ key: '', text: '', value: '' });
            setState({ ...state, listCertificatesUser: certificatesList });
          }
        });
      }
    }

  }, [userId]);

  useEffect(() => {
    if (numberLogin === 2 && certificates.length >= 2) {
      const certificatesList = certificates.map((x) => ({
        key: x.userSerialID,
        text: x.nameCertificate,
        value: x.idCertificate + ',' + x.userSerialID
      }));
      certificatesList.unshift({ key: '', text: '', value: '' });
      setState({ ...state, listCertificatesUser: certificatesList });
    }
  }, [])

  const handleOnChange = (event, { value }) => {
    const name = event.currentTarget.parentNode.parentNode.id;
    const list = value.split(',')
    setState({ ...state, [name]: value, idUserCertificate: list[1] });
  };

  const onChange = (e) => {
    setState({ ...state, [e.target.id]: e.target.value });
  };

  const handleBack = () => {
    history.push('/login');
  };

  const setLogin = async (e) => {
    e.preventDefault();
    dispatch({ type: CLEAR_ROLES })
    if (loginValidator.allValid()) {
      const { password, certificate, idUserCertificate } = state;
      var bodyToSend = null;
      if (certificates > 1) {
        let body = {
          userSerialIDs: idUserCertificate,
          idCertificate: certificate,
          passwordCertificate: password,
          isFirstLogin
        };
        bodyToSend = body
      } else {
        let body = {
          userSerialIDs: certificates[0].userSerialIDs,
          idCertificate: certificates[0].idCertificate,
          passwordCertificate: password,
          isFirstLogin
        };
        bodyToSend = body

      }
      await dispatch(validateCertificate(history, bodyToSend));
      if (errorReducer.showError) {
        setState({ ...state, errors: errorReducer.errors });
      }
    } else {
      loginValidator.showMessages();
    }
  };

  return (
    <>
   <ImageSegment>
        <LoginForm>
          <Title>Certificado</Title>
          <CustomForm className='ui form' onSubmit={setLogin}>
            {certificates.length >= 2 && (
              <>
                <InputFormDropdownLogin
                  options={state.listCertificatesUser}
                  placeholder={'Selecciona el certificado'}
                  value={state.certificate}
                  handleOnChange={handleOnChange}
                  id={'certificate'}
                  validator={loginValidator}
                  validateOptions={'required'}
                  error={state.errors}
                />
                {state.certificate !== '' && (
                  <InputForm
                    id={'password'}
                    label={'Contraseña del certificado'}
                    type={'password'}
                    onChange={onChange}
                    value={state.password}
                    validator={loginValidator}
                    validateOptions={'required'}
                    autocomplete='password'
                    error={state.errors}
                  />
                )}
              </>
            )}
            {certificates.length === 1 && (
              <div style={{ width: '100%' }}>
                <NameCertificate>
                  {certificates[0].nameCertificate}
                </NameCertificate>
                <InputForm id={'password'} label={'Contraseña del certificado'} type={'password'} onChange={onChange} value={state.password}
                  validator={loginValidator} validateOptions={'required'} autocomplete='password' error={state.errors} />
              </div>
            )}
            {errorReducer.showError && ( <MessageLogin content={errorReducer.errorsForm.message} /> )}

            <ActionsConatainer>
              <CustomPrimaryButton
                fluid={true}
                txtBtn='Continuar'
                login={true}
              />
              <CustomButton
                fluid={true}
                txtBtn='Regresar'
                onPress={handleBack}
              />

            </ActionsConatainer>
          </CustomForm>
        </LoginForm>
      </ImageSegment>
  
      <ModalGeneral type='sendEmail' />
      <ModalLoader />
    </>
  )
}

export default LoginWithCertificate
