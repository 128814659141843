import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import UserDocumentsGrid from './viewGrid/AllUserDocuments/UserDocumentsGrid'
import UserDocumentsList from './viewList/AllUserDocuments/UserDocumentsList'
import ContainerUserDocumentSearch from '../../components/Search/ContainerUserDocumentSearch';
import { PDFViewer } from '../../components/viewPdf/PDFViewer';
import { CLEAR_ATTACHMENT_DETAIL } from '../../actions/actionsUtilities/types';
import { UseWindowDimensions } from '../../utils/UseWindowsDimentions'
const UserDocuments = (props) => {

  const { viewList } = useSelector(state => state.userDocumentReducer)
  const windowDimensions = UseWindowDimensions();
  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch({type:CLEAR_ATTACHMENT_DETAIL})
  },[])
  return (
    <>
      <ContainerUserDocumentSearch showAZ={false} filterSmall={windowDimensions.width > 768 ? false : true} textPlaceHolder={'Número de identificación del destinatario o nombre de documento '} />
      <PDFViewer/>
      {viewList === true ? <UserDocumentsList history= {props.history}/> : <UserDocumentsGrid history= {props.history}/> }
    </>
  )
}

export default UserDocuments
