import React from 'react'
import PropTypes from 'prop-types'

import ContainerSearch from '../../components/Search/SearchClients/ContainerClientSearch'
import AllClients from './AllClients'
import styled from 'styled-components';
import Paginacion from '../../components/Search/Paginacion';

const DivFooter = styled.div`
  display: none;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 2% 0;
  @media only screen and (max-width: 1024px) {
    display: flex;
  }
`
const Clients = (props) => {
  return (
    <>
      <ContainerSearch />
      <AllClients
        history={props.history}
      />
      <DivFooter>
        <Paginacion />
      </DivFooter>
    </>
  )
}

Clients.propTypes = {
  history: PropTypes.any.isRequired,
};

export default Clients