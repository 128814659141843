import React from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Modal } from 'semantic-ui-react'
import HeaderPdf from './HeaderPdf'
import {closePDFModal } from '../../../actions/modalActions'
import './PDF.css'

const ModalForPDF = styled(Modal)`
margin-top:130px !important;
width:100%;
`
const ContentPdf = styled.iframe`
width:70%;
 height:100vh;
 @media only screen and (max-width: 1450px)   {
    width: 70% !important;
  }
  @media only screen and (max-width: 1280px)   {
    width: 90% !important;
  }
  @media only screen and (max-width: 600px)   {
    width: 98% !important;
  }
`

export const PDF = () => {

    const dispatch = useDispatch()
    const { pdfModal } = useSelector(state => state.modalReducer)
    const { dataViewPdf } = useSelector(state => state.sentReducer)
    
    const handleClick = () => {
        dispatch(closePDFModal())
    }
    return (
        <>
            <ModalForPDF
                open={pdfModal}
                onClose={handleClick}
                className="pdf-modal"
            >
                <HeaderPdf/>
                <ModalForPDF.Description  >
                    <center >
                        <ContentPdf className={'pdfView'} src={!!dataViewPdf.fileDecodedBase64 ?`${dataViewPdf.fileDecodedBase64}#view=FitH&toolbar=0&navpanes=0&scrollbar=0"`:'about:blank'} frameborder="0" width="" height="940px" />
                    </center>
                </ModalForPDF.Description>
            </ModalForPDF>
        </>
    )
}