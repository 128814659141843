import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { createReportService, getBase64ReportService } from '../../interceptors/services/reports.service'
import { ReactComponent as ExportIcon } from "../../assets/icons/reports/exportIcon.svg";

const Button = styled.button`
  background: #FFFFFF;
  border: 0px solid #FFFFFF;
  color: #22C1D0;
  width: max-content;
  height: 40px;
  font-size: 16px;
  font-family: Roboto;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  &:disabled {
    opacity: 0.3;
    cursor: no-drop;
  }
  @media only screen and (max-width: 740px) {
    font-size: 25px;
  }
`

const ExportText = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
  color: #22C1D0;
  text-decoration: underline #22C1D0;
  text-underline-offset: 2px;
  padding-left: 8px;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
  @media only screen and (max-width: 450px) {
    font-size: 14px;
  }
`

export const ExportButton = ({  disabledButton }) => {

  const exportRef = useRef(null)
  const [loader, setLoader] = useState(false)
  const {dataCreateReport} = useSelector(state => state.reportsReducer)
  const [bodyCreateReport, setBodyCreateReport] = useState(dataCreateReport)
  const [createReport, setCreateReport] = useState()
  const [responseBase64Report, setResponseBase64Report] = useState()

  const fetchCreateReport = async (body) => {
    const data = await createReportService(body)
     setCreateReport(data)
  }

  const fetchGetBase64Report= async (body) => {
    const data = await getBase64ReportService(body)

    setResponseBase64Report(data)
  }

  const handleClick = () => {
    setLoader(true)
    fetchCreateReport(bodyCreateReport)
  }

  const downloadReport = (base64, fileName) => {
    const downloadLink = document.createElement("a");
    downloadLink.href = base64;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  useEffect(()=>{
    setBodyCreateReport(dataCreateReport)
  }, [dataCreateReport])

  useEffect(() => {
    
    if(!!createReport){
      const body = {
        reportSerialID: createReport.reportSerialID
      }
      fetchGetBase64Report(body)
    }else{

    }
  }, [createReport])

  useEffect(()=>{
    if(!!loader){
      downloadReport(responseBase64Report.base64, createReport.fileName)
      setLoader(false)
    }
    
    
  }, [responseBase64Report])

  return (
    <Button onClick={handleClick} disabled={loader || disabledButton} ref={exportRef}>
      {!!loader 
      ?
        <i class={"loading spinner icon"}></i>
      :
        <ExportIcon/>
      }
      <ExportText>{"Exportar tabla"}</ExportText>
    </Button>
  )
}

