import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { Modal } from 'semantic-ui-react'
import styled from 'styled-components'

import { closeWarningModal, warningFormModal } from '../../../actions/modalActions'
import { ReactComponent as LeaveIcon } from '../../../assets/icons/leaveIcon.svg'
import '../../Modal/messageModal.css'
import PropTypes from "prop-types"
import { createCommentAuditor } from '../../../actions/improperClientsActions'

const ModalElement = styled(Modal)`
  height: 446px !important;
  padding: 40px !important;
  width: 496px !important;
`
const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const ModalMessage = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin-top: 32px;
`
const ModalActions = styled.div`
  margin-top: 32px;
`
const Texto = styled.p`
  color: #133F4F;
  font-family: Roboto;
  font-weight: 700;
  font-size: 20px;
  font-style: normal;
  line-height: 23px;
  text-align: center;
  margin-bottom: 15px !important;
`
const Button = styled.button`
  background: #FFFFFF;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  color: #22C1D0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  width: 71px;
  height: 42px;
  margin-Right: 16px;
  cursor: pointer;
`
const Button2 = styled.button`
  background: #22C1D0;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  color: #FFFFFF;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  width: 71px;
  height: 42px;
  margin-Right: 16px;
  cursor: pointer;
`

const WarningModal = (props) => {
    const modalData = (type) => {
        const data = {
            warningLeave: {
                icon: <LeaveIcon />,
                InitialMessage: "¡Vas a abandonar!",
                message: "Si lo haces, esta nueva entidad no quedará guardada",
                message2: '¿Realmente deseas hacerlo?',
                buttonMessage: 'Si',
                buttonMessage2: 'No',
            },
            warningSendCommentsAuditor: {
                icon: <LeaveIcon />,
                InitialMessage: "¡Enviar revisión!",
                message: "Estas a punto de enviar la revisión de auditoría, recuerda que una vez enviada no podrás hacer modificaciones.",
                message2: '¿Realmente deseas hacerlo?',
                buttonMessage: 'Si',
                buttonMessage2: 'No',
            }
        }
        return data[type]
    }
    const dispatch = useDispatch()
    const onClose = () => {
        if (props.activeForm === false || props.activeFormProvider === false) {
            dispatch(closeWarningModal({ type: 'warningSendCommentsAuditor'}))
        }
    }

    const handleOnLoad = () => {
        if (props.activeForm === false || props.activeFormProvider === false) {
            dispatch(closeWarningModal({ type: 'warningSendCommentsAuditor'}))
            dispatch(createCommentAuditor(props.auditorComment))
        }
    }


    const data = modalData(props.modalState.type)
    return (
        <ModalElement
            open={props.modalState.status}
            onClose={onClose}
        >
            <ModalContent>
                {!!data.icon
                    && data.icon}
                {data.multipleMessages && !!props.modalState.message && !!props.modalState.message.length &&
                    props.modalState.message.map((message) => (
                        <>
                            <ModalMessage>
                                {message}
                            </ModalMessage>
                            <br />
                        </>
                    ))
                }
                {!data.multipleMessages &&
                    <ModalMessage>
                        <Texto>{data.InitialMessage}</Texto>
                        {!props.modalState.message
                            ? data.message
                            : props.modalState.message}
                        <br />
                        <br />
                        {!props.modalState.message
                            ? data.message2
                            : props.modalState.message}
                    </ModalMessage>
                }
                {
                    <ModalActions>
                        <Button
                            onClick={handleOnLoad}
                        >
                            {data.buttonMessage}
                        </Button>
                        <Button2
                            onClick={onClose}
                        >
                            {data.buttonMessage2}
                        </Button2>
                    </ModalActions>
                }
            </ModalContent>
        </ModalElement>
    )
}
WarningModal.propTypes = {
    modalState: PropTypes.object,
    warningFormModal: PropTypes.func,
    activeForm: PropTypes.bool
}
const mapStateToProps = (state) => ({
    modalState: state.modalReducer.warningModal,
    activeForm: state.clientReducer.activeForm,
    activeFormProvider: state.providerReducer.activeForm,
    activeFormAgreements: state.agreementReducer.activeForm,
    auditorComment: state.improperClientsReducer.auditorComment
})

const mapDispatchToProps = {
    warningFormModal
}
const ShowTheLocationWithRouter = withRouter(WarningModal);

export default connect(mapStateToProps, mapDispatchToProps)(ShowTheLocationWithRouter);
