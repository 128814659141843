import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import cogoToast from 'cogo-toast'
import { Cookies } from 'react-cookie';

import {logoutUser} from "../../actions/authActions"
import {toastOptions} from "../../helpers/globalHelpers"

/**
 * Logout Component (calls to server to request logout,
 * after this, set tokens, user data, and headers to null,
 * finally, redirect to login page)
 *
 * @export Class Component
 * @class Logout
 * @extends {Component}
 * @return Redux connect
 */
export class Logout extends Component {

  constructor(props) {
    super(props);

    this.setLogout()
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (!!nextProps.errorReducer && !!nextProps.errorReducer.message) {
      cogoToast.error(nextProps.errorReducer.message, toastOptions);
    }
    if (nextProps.authReducer.isAuthenticated === false) {
      cogoToast.info('Inicia tu sesión', toastOptions);
    }
  }

  setLogout = () => {
    const myCookie= new Cookies()

    this.props.logoutUser(this.props.history, myCookie);
  };

  render() {
    return (
      <div>
        Signing out ...
      </div>
    )
  }
}

Logout.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  authReducer: PropTypes.object.isRequired,
  errorReducer: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer
})

export default connect(mapStateToProps, {logoutUser})(Logout)
