import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Modal } from 'semantic-ui-react'
import styled from 'styled-components'

import { warningFormModal, warningFormModalWrite  } from '../../../actions/modalActions'
import {changeIsSendEmail, deleteItemForMassive, deleteRecipientsFormMasive} from "../../../actions/writerActions";
import {saveDraftV2, deleteDraftRecipients, deleteDraftTagsAndSpecificFile} from '../../../actions/draftsActions';
import { ReactComponent as LeaveIcon } from '../../../assets/icons/leaveIcon.svg'
import '../../Modal/messageModal.css'
import PropTypes from "prop-types"

const ModalElement = styled(Modal)`
  padding: 40px !important;
  width: 496px !important;
`
const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const ModalMessage = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin-top: 32px;
`
const ModalActions = styled.div`
  margin-top: 32px;
`
const Texto = styled.p`
  color: #133F4F;
  font-family: Roboto;
  font-weight: 700;
  font-size: 20px;
  font-style: normal;
  line-height: 23px;
  text-align: center;
  margin-bottom: 15px !important;
`
const Muted = styled.p`
  color: #7C7C74;
  font-family: Roboto;
  font-weight: 400;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
 
`
const Button = styled.button`
  background: #FFFFFF;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  color: #22C1D0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  width: auto;
  height: 42px;
  margin-Right: 16px;
  padding: 0px 15px;
`
const Button2 = styled.button`
  background: #22C1D0;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  color: #FFFFFF;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  width: auto;
  height: 42px;
  margin-Right: 16px;
  padding: 0px 15px;
`

const WarningModalWrite = (props) => {
    const {toSaveDraft :draftData} = useSelector(state => state.draftReducer)
    useEffect(() => {
        if(draftData.fileSerialID == null && props.modalState.status){
            dispatch(saveDraftV2(draftData))
        }
    }, [draftData]);

    const modalData = (type) => {
        const data = {
            warningSendEmail: {
                icon: <LeaveIcon />,
                InitialMessage: "¡No has seleccionado un tipo de servicio!",
                message: '(Firma en notificación, Firma en documento, Confirmación de lectura y/o Notificación push)',
                buttonMessage: 'Cancelar',
                buttonMessage2: 'Enviar de todos modos'
            },
            warningDeleteMassiveFor: {
                icon: <LeaveIcon />,
                InitialMessage: "¡Vas a eliminar los registros cargados !",
                message: 'Ten en cuenta que todas las etiquetas y archivos adjuntos ligados a estos registros se eliminarán.',
                message2: '¿Realmente deseas hacerlo?',
                buttonMessage: 'Si',
                buttonMessage2: 'No'
            },
        }
        return data[type]
    }
    const dispatch = useDispatch()

    const onClose = () => {
        dispatch(warningFormModalWrite({ type: props.modalState.type }))
    }


    // dispatch(saveDraftV2({
                //     ...props.draftData,

                //     listDocumentsSpecific:[],
                //     listTagsChargeMassive:[],
                //     fileSerialID:null}));
   
    const handleOnLoad =  () => {
        switch (props.modalState.type) {
            case 'warningDeleteMassiveFor':
                dispatch(deleteItemForMassive());
                dispatch(deleteRecipientsFormMasive());
                dispatch(deleteDraftTagsAndSpecificFile())
                dispatch(deleteDraftRecipients())
                
                dispatch(warningFormModalWrite({ type: 'warningDeleteMassiveFor' }));
                break;
            case 'warningSendEmail':
                dispatch(changeIsSendEmail(true));
                dispatch(warningFormModalWrite({ type: 'warningSendEmail' }));
                break;
        }
    };

    const data = modalData(props.modalState.type)
    return (
        <ModalElement
            open={props.modalState.status}
            onClose={onClose}
        >
            <ModalContent>
                
                {!!data.icon
                    && data.icon}
                {data.multipleMessages && !!props.modalState.message && !!props.modalState.message.length &&
                    props.modalState.message.map((message) => (
                        <>
                            <ModalMessage>
                                {message}
                            </ModalMessage>
                            <br />
                        </>
                    ))
                }
                {!data.multipleMessages &&
                    <ModalMessage>
                        <Texto>{data.InitialMessage}</Texto>
                        {!props.modalState.message
                            ? props.modalState.type !== 'warningSendEmail'? data.message : <Muted>{data.message}</Muted>
                            : props.modalState.message}
                       {data.message2 ?  
                       <>
                       <br />
                       <br />
                       </>
                       :<></>} 
                        {!props.modalState.message
                            ? data.message2
                            : props.modalState.message}
                    </ModalMessage>
                }
                {
                    <ModalActions>
                        <Button
                            onClick={props.modalState.type !== 'warningSendEmail' ? handleOnLoad: onClose}
                        >
                            {data.buttonMessage}
                        </Button>
                        <Button2
                            onClick={props.modalState.type !== 'warningSendEmail' ?onClose: handleOnLoad}
                        >
                            {data.buttonMessage2}
                        </Button2>
                    </ModalActions>
                }
            </ModalContent>
        </ModalElement>
    )
}
WarningModalWrite.propTypes = {
    modalState: PropTypes.object,
    warningFormModal: PropTypes.func,
    activeForm: PropTypes.bool,
}
const mapStateToProps = (state) => ({
    modalState: state.modalReducer.warningModalWrite,
    activeForm: state.clientReducer.activeForm,
})

const mapDispatchToProps = {
    warningFormModal,
    changeIsSendEmail,
    deleteItemForMassive,
    deleteRecipientsFormMasive,
    deleteDraftTagsAndSpecificFile,
    saveDraftV2,
}
const ShowTheLocationWithRouter = withRouter(WarningModalWrite);

export default connect(mapStateToProps, mapDispatchToProps)(ShowTheLocationWithRouter);
