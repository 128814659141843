import React, { useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import styled, { css, keyframes } from 'styled-components'
import PrimaryButton from '../../components/PrimaryButton'
import { ReactComponent as InfoIcon } from '../../assets/icons/write/info.svg'
import { ReactComponent as InfoIconFiles } from '../../assets/icons/write/infoFiles.svg'
import { ReactComponent as FailSentIcon } from '../../assets/icons/write/failSent.svg'
import { ReactComponent as NotFoundIcon } from '../../assets/icons/sent/not_found.svg'
import { ReactComponent as RecipientNotFoundIcon } from '../../assets/icons/inactiveEntity.svg'
import { ReactComponent as InternalErrorIcon } from '../../assets/icons/reports/internal-error.svg'
import { ReactComponent as DeleteCitizenInfo } from '../../assets/icons/write/delete-citizen-load.svg'
import { clearEditorChange,cancelMassiveSendV2, deleteLoadNotificationItemV2} from "../../actions/writerActions";
import PropTypes from "prop-types";
import { changeMessageModalTwo } from '../../actions/modalActions'
import {Button} from "semantic-ui-react"
import { API_ENDPOINT } from '../../config/config'

const Overlay = styled.div`
  width: 100vw !important;
  heigth: 100vh !important;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left:0;
  z-index:5000;
  background: rgba(0, 0, 0, 0.5);
`
const ModalContainer = styled.div`
  max-height: 100vh;
  min-height: 100vh;
  height: 100vh;
  position: relative;
  background: trasparent;
  display: flex;
  align-items:center
`

const ModalElement = styled.div`
  width: 496px !important;
  height: auto !important;
  padding: 40px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  display:flex;

`

const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width:100%;
  gap: 0px;
`
const ModalMessage = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  padding: 0 20px 0 20px;
`
const ModalActions = styled.div`
display: flex;
align-items: flex-start;
gap: 32px;
`
const Texto = styled.p`
  color: #133F4F;
  font-family: Roboto;
  font-weight: 900;
  font-size: 20px;
  font-style: normal;
  line-height: 23.44px;
  text-align: center;
  margin-top:32px;
`

const SecondButton = styled(Button)`
  background: none !important;
  border: 1px solid #22C1D0 !important;
  border-radius: 4px !important;
  color: #22C1D0 !important;
  height: 41.14px !important;
  font-family: Roboto !important;
  font-weight: Bold !important;
  font-size: 16px !important;
  cursor: pointer !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 0 0 0 16px !important;
`

const MessageModal = (props) => {
    const dispatch = useDispatch()
    useEffect(() => {
        if (props.modalState.status === false) {
            dispatch(clearEditorChange(false))
        }
    }, [props.modalState.status, dispatch])

    const modalData = (type) => {
        const data = {
            warningFiles: {
                icon: <InfoIconFiles />,
                message: '',
                multipleMessages: true,
                buttonMessage: 'Aceptar',
            },
            cancelMassiveSend:{
                icon: <InfoIcon />,
                message: '',
                multipleMessages: true,
                buttonMessage: 'No',
                buttonMessageTwo: 'Si'
            },
            massiveSendCanceled: {
                icon: <FailSentIcon />,
                multipleMessages: true,
                message: '',
            },
            deleteNotificationItem:{
                icon: <InfoIcon />,
                message: '',
                multipleMessages: true,
                buttonMessage: 'Eliminar registro',
                buttonMessageTwo: 'Cancelar'
            },
            reportDataNotFound:{
                icon: <NotFoundIcon />,
                message: '',
                multipleMessages: true,
                buttonMessage: 'Aceptar',
            },
            reportRecipientNotFound:{
                icon: <RecipientNotFoundIcon />,
                message: '',
                multipleMessages: true,
                buttonMessage: 'Aceptar',
            },
            reportInternalError:{
                icon: <InternalErrorIcon />,
                message: '',
                multipleMessages: true,
                buttonMessage: 'Aceptar',
            },
            deleteCitizenItem:{
                icon: <DeleteCitizenInfo />,
                message: '',
                multipleMessages: true,
                buttonMessage: 'Eliminar registro',
                buttonMessageTwo: 'Cancelar'
            },
        }
        return data[type]
    }


    const onClose = () => {
        props.changeMessageModalTwo({ type: props.modalState.type })

    }
    const handleOnClick =() =>{
        if(props.modalState.type === 'cancelMassiveSend'){
            props.cancelMassiveSendV2({fileSerialID:props.modalState.fileSerialID})
        }else if(props.modalState.type === 'deleteNotificationItem' || props.modalState.type === 'deleteCitizenItem'){
            props.deleteLoadNotificationItemV2({fileSerialID:props.modalState.fileSerialID,  newVisibility: false})
        }
        props.changeMessageModalTwo({ type: props.modalState.type })

    }

    const data = modalData(props.modalState.type)
    return (
        <>
            {props.modalState.status &&
                <Overlay onClick={onclose}>
                    <ModalContainer>
                        <ModalElement modalType={props.modalState.type}>
                            <ModalContent >
                                {!!data.icon && data.icon}
                                {data.multipleMessages && !!props.modalState.message && !!props.modalState.message.length &&
                                    props.modalState.message.map((message, key) => (
                                        <>
                                            {key === 0 ?

                                                <Texto >
                                                    {message}
                                                </Texto>
                                                :
                                                <ModalMessage>
                                                    {message}
                                                </ModalMessage>
                                            }
                                            <br />
                                        </>
                                    ))
                                }
                                {props.modalState.type === 'deleteCitizenItem' && 
                             <div style={{padding:'0 0 31px 0'}}>

                                        <a className='downloadAttachment' href={props.modalState.urlFileError}>Descargar archivo de ciudadanos no cargados</a>        
                                        <br />
                             </div>
                    
                                }
                                {!data.multipleMessages &&
                                    
                                        <Texto>
                                        {!props.modalState.message
                                            ? data.message
                                            : props.modalState.message}
                                        </Texto>
                                    
                                }
                                {data.buttonMessage &&
                                <ModalActions>
                                    {data.buttonMessageTwo &&
                                        <SecondButton onClick={props.modalState.type === 'deleteNotificationItem' || props.modalState.type === 'deleteCitizenItem'? onClose:handleOnClick}>{data.buttonMessageTwo}</SecondButton>
                                    }
                                    <PrimaryButton txtBtn={data.buttonMessage} onPress={props.modalState.type=== 'deleteNotificationItem' || props.modalState.type === 'deleteCitizenItem'? handleOnClick:onClose}/>
                                </ModalActions>
                                }

                            </ModalContent>
                        </ModalElement>
                    </ModalContainer>
                </Overlay>
            }
        </>
    )
}
MessageModal.propTypes = {
    modalState: PropTypes.object,
    changeMessageModalTwo: PropTypes.func,
    clearEditorChange: PropTypes.func,
    cancelMassiveSendV2: PropTypes.func,
    deleteLoadNotificationItemV2: PropTypes.func,
}
const mapStateToProps = (state) => ({
    modalState: state.modalReducer.messageModalTwo
})

const mapDispatchToProps = {
    changeMessageModalTwo,
    clearEditorChange,
    cancelMassiveSendV2,
    deleteLoadNotificationItemV2,
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageModal)
