import React from "react"
import PropTypes from "prop-types"

import { useDispatch } from 'react-redux'
import './input-from-users.css'
import styled from "styled-components"
import { Form } from "semantic-ui-react"
import { activeFormUser } from "../../../actions/usersActions"

const Text = styled.div`

`
const Text2 = styled.span`
    
`
const InputFormUser = ({ ...props }) => {

    const dispatch = useDispatch()
    const { placeholder, type, id, onChange, value, label, validator, validateOptions, defaultValue, disabled, readOnly } = props

    let errorValidator = null;
    if (validator && validateOptions) errorValidator = validator ? validator.message(label !== '' && label !== undefined ? label : placeholder, value, validateOptions) : ''

    const validator2 = (value) => {
        if (value !== "") {
            dispatch(activeFormUser())
        }
    }

    return (
        <Form.Field>
            <div>
                <label className={errorValidator ? 'labelInputFormUsersError' : 'labelInputFormUsers'}>
                    <input
                        id={id}
                        type={type}
                        value={value}
                        defaultValue={defaultValue}
                        placeholder={placeholder}
                        onChange={onChange}
                        onBlur={() => validator2(value)}
                        onKeyDown={(e) => e.keyCode === 13 && e.preventDefault()}
                        className={'inputFormUsers'}
                        disabled={disabled}
                        readOnly={readOnly}
                        autoComplete="off"
                    />
                    {
                        errorValidator && value === ''
                        &&
                        <>
                            <Text2 className={errorValidator && value === '' && 'spanLabelInputTopError'}>{label}</Text2>
                            <Text className={(errorValidator) && 'input-error3'}>{errorValidator}</Text>
                        </>
                    }
                    {
                        errorValidator === undefined && value !== ''
                        &&
                        <>
                            <span className={'spanLabelInputTop'}>{label}</span>
                        </>
                    }
                    {
                        errorValidator === undefined && value === ''
                        &&
                        <>
                            <span className={'spanLabelInput'}>{label}</span>
                        </>
                    }
                    {
                        errorValidator && value !== ''
                        &&
                        <>
                            <Text2 className={'spanLabelInputTopError'}>{label}</Text2>
                            <Text className={'input-error2'}>{errorValidator}</Text>
                        </>
                    }
                </label>
            </div>

        </Form.Field>
    )
}


InputFormUser.propTypes = {
    labelProps: PropTypes.object,
    id: PropTypes.string,
}
export default InputFormUser