import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const CustomSegment = styled.div`
  background: #FFFFFF;
`

export const MainSegment = props => {
  return (
    <CustomSegment>
      {props.children}
    </CustomSegment>
  )
}

MainSegment.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node
}
