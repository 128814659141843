import React from 'react'
import styled from 'styled-components'
import _ from 'lodash'

const ContainerLetter = styled.div`
  width:auto;
  margin-left: 10px;
  text-align: left;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #22C1D0;
  display: flex;
`

const LineBorder = styled.hr`
  width:auto;
  margin-left: 10px;
  margin-bottom: 20px;
  height:0px;
  background-color: #B2B2B2;
`
const Container = styled.div`
  width:100%;
  display: inline-block;
`

export const OrderInitialLetter = (props) => { 
  if(props.letter!==''){
    return (
      <Container>
        {props.initial !== true ? <LineBorder/> : ''}
      <ContainerLetter>
        {props.letter}
      </ContainerLetter>
      </Container>
    )
  }else{
    return ''
  }
}

export default OrderInitialLetter
