import React, { Component } from 'react'
import { SearchBarGenericInitial } from '../SearchBarGeneric'
import PopUpFilterImproperClients from '../../Search/SearchImproperClients/PopUpFilterImproperClients';

class ContainerSearchImproperClients extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchBarState: "",
      popUpFilterState: "",
      popUpOrderState: "",
    };
  }
  render() {
    return (
      
      <SearchBarGenericInitial showButton={false} showFilters= {this.props.showFilters} showAZ={this.props.showAZ} showOldestNewest={this.props.showOldestNewest} textPlaceHolder={'Buscar'} popUpOrderState={this.popUpOrderState}
      searchBarState={this.searchBarState} popUpFilter={<PopUpFilterImproperClients/>} showPopUpFilter={this.props.showPopUpFilter}/>

    )
  }
}

export default ContainerSearchImproperClients
